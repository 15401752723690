import { BsCode, BsPlayFill, BsQuestionLg, BsStopFill } from 'react-icons/bs';
import { BusinessNodeEnum } from '../types';
import { TbArrowsSplit, TbVariable, TbWorld } from 'react-icons/tb';

import { BiCodeCurly } from 'react-icons/bi';
import { IoMdTime } from 'react-icons/io';
import { RiDatabase2Fill, RiShareForwardBoxLine } from 'react-icons/ri';
import { TiFlowParallel, TiSortAlphabetically } from 'react-icons/ti';
import { MdLoop, MdStorage } from 'react-icons/md';
import { FaAws } from 'react-icons/fa';

export const getNodeDisplayData = (type: BusinessNodeEnum) => {
  switch (type) {
    case BusinessNodeEnum.HTTP_TRIGGER: {
      return {
        Icon: BiCodeCurly,
        subtitle: 'Trigger',
        color: 'GREEN',
      };
    }
    case BusinessNodeEnum.START: {
      return {
        Icon: BsPlayFill,
        subtitle: 'Start',
        color: 'GREEN',
      };
    }
    case BusinessNodeEnum.STOP:
    case BusinessNodeEnum.FUNCTION_RESPONSE:
    case BusinessNodeEnum.HTTP_RESPONSE: {
      return {
        Icon: BsStopFill,
        subtitle: 'Response',
        color: 'GREEN',
      };
    }
    case BusinessNodeEnum.ACTION: {
      return {
        Icon: BsCode,
        subtitle: 'Action',
        color: 'GREEN',
      };
    }
    case BusinessNodeEnum.API_CALL: {
      return {
        Icon: TbWorld,
        subtitle: 'Action',
        color: 'BLUE',
      };
    }
    case BusinessNodeEnum.SES_SEND_EMAIL:
    case BusinessNodeEnum.SNS_SEND_SMS:
    case BusinessNodeEnum.SNS_SEND_PUSH_NOTIFICATION:
    case BusinessNodeEnum.SQS_FIRE_EVENTS: {
      return {
        Icon: FaAws,
        subtitle: 'Action',
        color: 'BLUE',
      };
    }
    case BusinessNodeEnum.DB:
    case BusinessNodeEnum.CUSTOM_QUERY_RECORDS:
    case BusinessNodeEnum.DELETE_MULTIPLE_RECORDS:
    case BusinessNodeEnum.DELETE_RECORD:
    case BusinessNodeEnum.FETCH_RECORD:
    case BusinessNodeEnum.FIND_MANY:
    case BusinessNodeEnum.FIND_ONE:
    case BusinessNodeEnum.INSERT_MULTIPLE_RECORDS:
    case BusinessNodeEnum.INSERT_RECORD:
    case BusinessNodeEnum.LIST_RECORDS:
    case BusinessNodeEnum.UPDATE_MULTIPLE_RECORDS:
    case BusinessNodeEnum.UPDATE_RECORD:
    case BusinessNodeEnum.EXISTS_NODE:
    case BusinessNodeEnum.UPDATE_ONE_RECORD: {
      return {
        Icon: RiDatabase2Fill,
        subtitle: 'Database',
        color: 'YELLOW',
      };
    }

    case BusinessNodeEnum.IF_ELSE:
    case BusinessNodeEnum.IF_ELSE_V2: {
      return {
        Icon: TbArrowsSplit,
        subtitle: 'Conditional',
        color: 'RED',
      };
    }

    case BusinessNodeEnum.WHILE_LOOP:
    case BusinessNodeEnum.FOR_LOOP: {
      return {
        Icon: MdLoop,
        subtitle: 'Native',
        color: 'BLUE',
      };
    }

    case BusinessNodeEnum.ADD_VARIABLE:
    case BusinessNodeEnum.BUILD_JSON:
    case BusinessNodeEnum.VALIDATE_MODEL:
    case BusinessNodeEnum.BUILD_MAP:
    case BusinessNodeEnum.APPEND_TO_ARRAY:
    case BusinessNodeEnum.COMPLEX_MATHS_EXPR:
    case BusinessNodeEnum.CONTAINS:
    case BusinessNodeEnum.MULTIPLY_VARIABLE:
    case BusinessNodeEnum.REVERSE_ARRAY:
    case BusinessNodeEnum.SUBTRACT_VARIABLE:
    case BusinessNodeEnum.SET_VARIABLE:
    case BusinessNodeEnum.INCREMENT_VARIABLE:
    case BusinessNodeEnum.MERGE_JSON:
    case BusinessNodeEnum.EMPTY_ARRAY:
    case BusinessNodeEnum.LENGTH_ARRAY:
    case BusinessNodeEnum.ARRAY_ITEM_AT:
    case BusinessNodeEnum.ARRAY_EXTEND:
    case BusinessNodeEnum.DIVIDE_VARIABLE:
    case BusinessNodeEnum.CONVERT_TO_STRING:
    case BusinessNodeEnum.LENGTH_STRING:
    case BusinessNodeEnum.SPLIT_STRING:
    case BusinessNodeEnum.STRING_OPERATIONS:
    case BusinessNodeEnum.ARRAY_SORT:
    case BusinessNodeEnum.OBJECT_TO_STRING:
    case BusinessNodeEnum.DEBUG:
    case BusinessNodeEnum.DECREMENT_VARIABLE: {
      return {
        Icon: TbVariable,
        subtitle: 'Variable',
        color: 'RED',
      };
    }

    case BusinessNodeEnum.EXECUTE_FUNCTION: {
      return {
        Icon: RiShareForwardBoxLine,
        subtitle: 'Function',
        color: 'BLUE',
      };
    }

    case BusinessNodeEnum.APPEND_TO_STRING:
    case BusinessNodeEnum.CONCAT_STRINGS:
    case BusinessNodeEnum.SLICE_STRING:
    case BusinessNodeEnum.TO_LOWER:
    case BusinessNodeEnum.TO_UPPER:
    case BusinessNodeEnum.TRIM_STRING: {
      return {
        Icon: TiSortAlphabetically,
        subtitle: `String`,
        color: 'RED',
      };
    }

    case BusinessNodeEnum.CURRENT_DATETIME: {
      return {
        Icon: IoMdTime,
        subtitle: 'Native',
        color: 'RED',
      };
    }

    case BusinessNodeEnum.SWITCH_CASE:
    case BusinessNodeEnum.SWITCH_CASE_V2: {
      return {
        Icon: TiFlowParallel,
        subtitle: 'Native',
        color: 'theme.palette.green.light',
      };
    }

    case BusinessNodeEnum.GET_PRESIGNED_URL:
    case BusinessNodeEnum.POST_PRESIGNED_URL: {
      return {
        Icon: MdStorage,
        subtitle: 'Object storage',
        color: 'YELLOW',
      };
    }

    default: {
      return {
        Icon: BsQuestionLg,
        subtitle: 'Native',
        color: 'GREEN',
      };
    }
  }
};
