import {
  Button,
  Flex,
  FormError,
  Input,
  Label,
  Select,
  Show,
  Text,
} from '@comet/blocks';
import { styled } from '@comet/styled';
import { ChangeEvent, useState } from 'react';
import FlexItem from 'src/blocks/FlexItem';
import { UpsertUserAccessData } from '../types';

interface IMemberEmailInviteProps {
  roles: {
    label: string;
    value: number;
  }[];
  description?: string;
  onInvite: (data: UpsertUserAccessData) => void;
  userLimitReached: boolean;
  hasEditAccess?: boolean;
}

const StyledText = styled(Text)`
  color: #424450;
`;

const validateEmails = (emails: string[]) => {
  let check = true;
  emails.forEach((email) => {
    if (!email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}/)) {
      check = false;
      return;
    }
  });

  return check;
};

export const MemberEmailInvite = ({
  roles,
  description,
  onInvite,
  hasEditAccess,
  userLimitReached = false,
}: IMemberEmailInviteProps) => {
  const [selectedPermission, setSelectedPermission] = useState(roles[0].value);
  const [selectedEmails, setSelectedEmails] = useState('');
  const [error, setError] = useState('');

  const onEmailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setError('');
    setSelectedEmails(e.target.value);
  };

  const onSendInvite = () => {
    const emails = selectedEmails.split(',');

    if (!validateEmails(emails)) {
      setError('Please enter emails in correct format');
      return;
    }

    const orgAccessInviteData: UpsertUserAccessData = {
      users: [],
      invites: {
        emails,
        role: selectedPermission,
      },
    };

    setSelectedEmails('');

    onInvite(orgAccessInviteData);
  };

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={10}>
        <Text appearance="heading.paragraph">Invite new members via email</Text>
        <Show if={!!description}>
          <StyledText appearance="caption.regular">{description}</StyledText>
        </Show>
      </Flex>
      <FlexItem>
        <Label required>Email address</Label>
      </FlexItem>
      <Flex gap={8}>
        <FlexItem flex direction="column" grow={1}>
          <Input.TextArea
            placeholder="Enter multiple emails by separating them with a comma"
            onChange={onEmailChange}
            value={selectedEmails}
            autoSize
          />
          <Show if={!!error}>
            <FormError>{error}</FormError>
          </Show>
        </FlexItem>
        <FlexItem shrink={0}>
          <Select
            style={{ width: 120 }}
            onChange={setSelectedPermission}
            defaultValue={roles[0].value}
            options={roles}
          />
        </FlexItem>
        <Button
          appearance="primary"
          onClick={onSendInvite}
          disabled={!hasEditAccess || userLimitReached}
        >
          Send Invite
        </Button>
      </Flex>
    </Flex>
  );
};
