import 'reactflow/dist/style.css';

import { ActionModal, NodeExplorer } from './Components';
import { NodeRaw, NodeTypeEnum } from './types';
import {
  RFStateType,
  useActionModalStore,
  useFlowEngineStore,
  useNodeExplorerStore,
} from './store';
import ReactFlow, { Background, Controls, Edge, Panel } from 'reactflow';
import {
  edgesTypeComponentMapping,
  nodesTypeComponentsMapping,
} from './constant';

import { ExtendedNode } from '@comet/pages/Api/Types';
import { FlowEngineEditor } from '@comet/components/FlowEngine/FlowEngine.Editor';
import { getLayoutedElements } from './utils';
import { shallow } from 'zustand/shallow';
import { useEffect, useRef, useState } from 'react';
import { useParams } from '@comet/router';

const selector = (state: RFStateType) => ({
  nodes: state.nodes,
  edges: state.edges,
  rootNode: state.rootNode,
  setAllNodes: state.setNodes,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  goBackToPrevRootNode: state.goBackToPrevRootNode,
  currentRoot: state.rootStack.length === 0 ? null : state.rootStack[0],
});

interface FlowEngineProps {
  nodes: ExtendedNode[];
  edges: Edge[];
  hasEditAccess: boolean;
  flowId: string;
  rootNode: string;
}

const convertNodetoV2 = (node: ExtendedNode) => {
  const parentId = node.parentId || node.parentNode || 'root';
  let type = node.type;
  if (
    node.data.type === NodeTypeEnum.IfElse ||
    node.data.type === NodeTypeEnum.IfElseV2
  )
    type = node.data.type;
  const object = {
    ...node,
    type,
    parentId,
  };
  return object;
};

export default function App(props: FlowEngineProps) {
  const [flowId, setFlowID] = useState('');

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    goBackToPrevRootNode,
    currentRoot,
  } = useFlowEngineStore(selector);

  /**
   * This function will handle React Flow auto layout.
   */
  function autoLayout() {
    const { nodes: oldNodes, edges: oldEdges } = useFlowEngineStore.getState();
    if (oldNodes.length === 0) return;
    const { nodes: updatedNodes, edges: updatedEdges } = getLayoutedElements(
      oldNodes,
      oldEdges
    );
    useFlowEngineStore.getState().setNodesAndEdges(updatedNodes, updatedEdges);
  }

  /**
   * This useEffect will run only on component load time, when a new Flow is being rendered.
   * This will work only when flowId changes.
   */
  useEffect(() => {
    if (flowId === props.flowId) {
      return;
    }
    setFlowID(props.flowId);
    const updatedPropsNodes = props.nodes.map(convertNodetoV2);
    useFlowEngineStore
      .getState()
      .initialize(updatedPropsNodes as NodeRaw[], props.edges, props.rootNode);
    autoLayout();
  }, [props.flowId]);

  /**
   * Only for auto-layout when nodes/edges change except first time render.
   */
  useEffect(() => {
    if (flowId === props.flowId) {
      autoLayout();
    }
  }, [nodes, edges]);

  // Node Explorer
  const isNodeExplorerOpen = useNodeExplorerStore(
    (s) => s.isNodeExplorerOpen,
    shallow
  );

  const { apiId } = useParams();

  const { props: actionModalProps } = useActionModalStore();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isNodeExplorerOpen && <NodeExplorer />}
      {actionModalProps.open && <ActionModal {...actionModalProps} />}

      <div style={{ width: '100%', height: '100%' }}>
        <ReactFlow
          nodes={[...nodes]}
          edges={[...edges]}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodesTypeComponentsMapping}
          edgeTypes={edgesTypeComponentMapping}
          onConnect={onConnect}
          fitView={true}
          panOnScroll={true}
          zoomOnScroll={false}
          minZoom={0.2}
          maxZoom={1}
          key={apiId}
        >
          <Panel
            position="top-left"
            style={
              currentRoot === 'root'
                ? { display: 'none' }
                : { display: 'block' }
            }
          >
            <button onClick={goBackToPrevRootNode}>{'<--'}</button>
          </Panel>
          <Background key={apiId} gap={28} size={1} />
          <Controls />
        </ReactFlow>
      </div>
      <FlowEngineEditor />
    </div>
  );
}
