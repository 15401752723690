import { ReactNode } from 'react';

import { styled } from '@comet/styled';
import { Flex, Label } from '@comet/blocks';

const RowContainer = styled(Flex)<{
  hasBorder: boolean;
}>`
  flex-direction: column;
  gap: 8px;
  ${({ hasBorder }) => (hasBorder ? 'border-bottom: 1px solid #eeeeee;' : '')}
`;

interface IProps {
  label: string;
  required?: boolean;
  children: ReactNode;
  hasBorder?: boolean;
}

export const FormRow = (props: IProps) => {
  const { label, required, children, hasBorder } = props;

  return (
    <RowContainer alignItems="flex-start" hasBorder={!!hasBorder}>
      <Label required={required}>{label}</Label>
      {children}
    </RowContainer>
  );
};
