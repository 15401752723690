import { Flex, FormItem, Input, Select, Show, Text } from '@comet/blocks';
import { useState } from 'react';
import { DatabaseMode, DatabaseModeOption } from '../../../constants';
import { useOrgQuery } from '@comet/pages/Organisation/service';
import { useParams } from '@comet/router';
import { ExternalLink } from '@comet/components/ExternalLink';
import { MdOpenInNew } from 'react-icons/md';
import { Space } from 'antd';

export const ConfigureDatabase = () => {
  const [dbMode, setDbMode] = useState(DatabaseMode.MONGODB_ATLAS);

  const { organisationId } = useParams();
  const { data: orgData } = useOrgQuery(organisationId);
  const onFreeTier = orgData?.planDetails.planType == 'FREE';

  return (
    <div>
      <Flex direction="column">
        <ExternalLink
          href="https://docs.cosmocloud.io/getting-started/3.-connect-your-database"
          appearance="caption.semibold"
          style={{ marginBottom: 15 }}
        >
          <MdOpenInNew />
          Learn how to connect your database
        </ExternalLink>
        <FormItem
          label={<Text appearance="label.long.semiBold">Database name</Text>}
          colon={false}
          marginBottom={0}
          required
        >
          <Text
            appearance="caption.longText"
            color="neutral.dark"
            style={{ marginBottom: 15 }}
          >
            The logical Database name where your Collections and data reside.
          </Text>
        </FormItem>
        <FormItem
          name={['data', 'name']}
          required
          rules={[
            {
              required: true,
              message: 'Database Name is required!',
            },
            {
              pattern: /^[a-zA-Z0-9_-]+$/,
              message: 'Whitespace not allowed in Database name!',
            },
          ]}
          marginBottom={15}
        >
          <Input placeholder="Enter database name..." />
        </FormItem>
      </Flex>
      <FormItem
        name={['data', 'mode']}
        label={<Text appearance="label.long.semiBold">Mode</Text>}
        required
        rules={[{ required: true, message: 'Secret type is required' }]}
        initialValue={dbMode}
        marginBottom={15}
      >
        <Select
          style={{ width: 200 }}
          options={
            onFreeTier
              ? [
                  {
                    label: 'MongoDB Atlas',
                    value: DatabaseMode.MONGODB_ATLAS,
                  },
                ]
              : DatabaseModeOption
          }
          onChange={(e) => setDbMode(e)}
        />
      </FormItem>
      <Show if={dbMode === DatabaseMode.DATABASE_ONLY}>
        <Flex direction="column">
          <FormItem
            label={
              <Text appearance="label.long.semiBold">MongoDB Atlas URI</Text>
            }
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 15 }}
            >
              The connection string for your MongoDB Atlas cluster with username
              and password
            </Text>
          </FormItem>
          <FormItem
            name={['data', 'mongodbURI']}
            rules={[
              {
                message: 'mongoDB URI is required!',
              },
            ]}
            marginBottom={15}
          >
            <Input placeholder="mongodb+srv://<user>.<password>..." />
          </FormItem>
        </Flex>
      </Show>
      <Show if={dbMode === DatabaseMode.MONGODB_ATLAS}>
        <Flex direction="column">
          <FormItem
            label={
              <Text appearance="label.long.semiBold">
                MongoDB Atlas Public Key
              </Text>
            }
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 10 }}
            >
              Make sure your have 'Project Owner' permission attached to the API
              Key.
            </Text>
          </FormItem>
          <FormItem
            name={['data', 'publicKey']}
            required
            rules={[
              {
                message: 'Public Key is required!',
              },
            ]}
          >
            <Input placeholder="Enter mongodb public key" />
          </FormItem>
        </Flex>
        <FormItem
          name={['data', 'privateKey']}
          label={
            <Text appearance="label.long.semiBold">
              MongoDB Atlas Private key
            </Text>
          }
          required
          rules={[
            {
              message: 'Private Key is required!',
            },
          ]}
        >
          <Input placeholder="Enter private key" />
        </FormItem>
        <Flex direction="column">
          <FormItem
            label={<Text appearance="label.long.semiBold">Project ID</Text>}
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 10 }}
            >
              The ID of your MongoDB Project containing your Database clusters
            </Text>
          </FormItem>
          <FormItem
            name={['data', 'projectId']}
            required
            rules={[
              {
                message: 'ProjectId is required!',
              },
            ]}
          >
            <Input placeholder="Enter project ID" />
          </FormItem>
        </Flex>

        <Flex direction="column">
          <FormItem
            label={<Text appearance="label.long.semiBold">Cluster name</Text>}
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 10 }}
            >
              The Cluster name where your Collections and data reside.
            </Text>
          </FormItem>
          <FormItem
            name={['data', 'clusterName']}
            required
            rules={[
              {
                message: 'Cluster name is required!',
              },
            ]}
          >
            <Input placeholder="Enter cluster name" />
          </FormItem>
        </Flex>
      </Show>
    </div>
  );
};
