import {
  Avatar as AntdAvatar,
  type AvatarProps as AntdAvatarProps,
} from 'antd';
import { styled, css } from '@comet/styled';

export type AppearanceType =
  | 'info'
  | 'success'
  | 'danger'
  | 'warning'
  | 'default';
export interface AvatarProps extends Omit<AntdAvatarProps, 'children'> {
  appearance?: AppearanceType;
}

const getAppearnceStyles = (appearance: AppearanceType) => {
  switch (appearance) {
    case 'danger':
      return css`
        background: #e78d8b;
        svg {
          fill: #601917;
        }
      `;
    case 'info':
      return css`
        background: #a8b5e1;
        svg {
          fill: #3c4977;
        }
      `;
    case 'warning':
      return css`
        background: #eee48c;
        svg {
          fill: #601917;
        }
      `;
    case 'success':
      return css`
        background: #92d1b2;
        svg {
          fill: #38634e;
        }
      `;
    default:
      return css``;
  }
};

const StyledAvatar = styled(AntdAvatar)<{
  appearance: AppearanceType;
}>`
  align-items: center;
  justify-content: center;
  display: flex;

  ${({ appearance }) => getAppearnceStyles(appearance)}
`;

export const Avatar = ({ appearance = 'default', ...props }: AvatarProps) => {
  return <StyledAvatar appearance={appearance} {...props} />;
};
