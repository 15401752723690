import { TextProps } from '@comet/blocks';
import { styled, font } from '@comet/styled';

const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  display: flex;
  align-items: center;
  gap: 4px;
`;

type ExternalLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  appearance: TextProps['appearance'];
  href: string;
};

// tracking will be added here
export const ExternalLink = ({
  appearance,
  style: propStyle,
  ...props
}: ExternalLinkProps) => {
  const { style } = font[appearance];
  return (
    <StyledLink style={{ ...propStyle, ...style }} {...props} target="_blank" />
  );
};
