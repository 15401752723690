import { RuleRender } from 'antd/es/form';
import { useCallback } from 'react';

export const useFilterFieldValidator = (
  toggleNextStepEnable: (enabled: boolean) => void,
  fieldName = 'fields',
  callback?: (field: unknown, value: unknown) => boolean
) => {
  const validatorRule: RuleRender = useCallback(
    ({ getFieldValue }) => ({
      validator(_, value) {
        const myfields = getFieldValue(fieldName);
        if (
          !value ||
          myfields.filter(
            callback
              ? (field: any) => callback(field, value)
              : (f: { fieldName: string }) => f.fieldName === value
          ).length <= 1
        ) {
          return Promise.resolve();
        }

        toggleNextStepEnable(false);
        return Promise.reject(new Error('Duplicate fields not allowed!'));
      },
      message: 'Duplicate fields not allowed!',
    }),
    [fieldName, toggleNextStepEnable, callback]
  );

  return validatorRule;
};
