import { User } from '@comet/pages/Project/types/Projects.types';
import { Field } from './ModelSchema.types';

export enum ModelTypesEnum {
  REQUEST_BODY = 'REQUEST_BODY',
  QUERY_PARAMS = 'QUERY_PARAMS',
  DATABASE_COLLECTION = 'DATABASE_COLLECTION',
}

export interface Model {
  active: boolean;
  name: string;
  modelType: ModelTypesEnum;
  fields?: Field[];
  description?: string;
  id: string;
  modelId: string;
  projectId: string;
  updatedOn: number;
  updatedBy: User;
  version: string;
  draft: boolean;
}

export type RawModel = Partial<Model> & Pick<Model, 'name' | 'description'>;
