import { getNodeDisplayData } from './utils';
import { TruncatedText } from '../Components/TruncatedText';
import { businessNodeEnumMapping } from '../types';
import { styled, ThemeType } from '@comet/styled';
import { Tooltip } from 'antd';
import { NodeSkeletonPropsType } from 'src/FlowEngine2/nodes/types';

const NodePanel = styled.div`
  display: none;
  height: 90%;
  padding: 8px 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;

  cursor: pointer;

  border-radius: 4px;
  background: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

const NodeContainer = styled.div`
  display: flex;
  width: 220px;
  padding: 10px 12px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;

  position: relative;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.darker}`};
  background: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

const DisplayedNode = styled.div`
  display: flex;
  width: 266px;
  height: 96px;
  flex-shrink: 0;
  gap: 4px;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 0;
    box-shadow: none;
    padding: 0;
    border-radius: 4px;
    ${NodePanel} {
      display: flex;
      border: 1px solid ${({ theme }) => `${theme.palette.neutral.darker}`};
    }
    ${NodeContainer} {
      border: 1px solid ${({ theme }) => `${theme.palette.neutral.darker}`};
      box-shadow: none;
    }
  }
`;

const IconContainer = styled.div<{ color: string }>`
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  cursor: pointer;
  ${({ color }) => color === 'GREEN' && `background:  #34d399;`}
  ${({ color }) => color === 'BLUE' && `background:  #93c5fd;`}
  ${({ color }) => color === 'RED' && `background:  #fca5a5;`}
  ${({ color }) => color === 'YELLOW' && `background:  #fde68a;`}
`;
const NodeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
`;

const SubtitleText = styled.span`
  align-self: stretch;
  color: ${({ theme }) => `${theme.palette.neutral.darker}`};

  display: flex;
  align-items: end;
  gap: 4px;

  /* Caption/All Caps */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.72px;
`;

const NodeTypeText = styled.span`
  align-self: stretch;
  color: ${({ theme }) => `${theme.palette.neutral.darkest}`};

  /* Heading/Paragraph */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

const NodeNameText = styled.span`
  align-self: stretch;
  color: ${({ theme }) => `${theme.palette.neutral.darker}`};

  /* Label/Short/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
`;

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
`;

export function NodeSkeleton({
  displayName,
  nodeName,
  businessNodeName,
  canDelete,
  isEmbeddedFlow,
  handleDelete,
  handleEmbeddedFlow,
}: NodeSkeletonPropsType) {
  const { Icon, subtitle, color } = getNodeDisplayData(businessNodeName);
  return (
    <DisplayedNode>
      <NodeContainer>
        <IconContainer color={color}>
          <Icon width={18} height={18} />
        </IconContainer>
        <NodeInfoContainer>
          <SubtitleText>
            <TruncatedText maxLength={14}>{subtitle}</TruncatedText>
          </SubtitleText>
          <NodeTypeText>
            <TruncatedText maxLength={20}>{displayName}</TruncatedText>
          </NodeTypeText>
          <NodeNameText>
            <TruncatedText maxLength={14}>{nodeName}</TruncatedText>
          </NodeNameText>
        </NodeInfoContainer>
        <ActionGroup>
          {isEmbeddedFlow && (
            <Tooltip title="Get inside" placement="top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                onClick={handleEmbeddedFlow}
              >
                <path
                  d="M13.7533 4.21875H3.0658C2.75466 4.21875 2.5033 4.47012 2.5033 4.78125V15.4688C2.5033 15.7799 2.75466 16.0312 3.0658 16.0312H13.7533C14.0644 16.0312 14.3158 15.7799 14.3158 15.4688V4.78125C14.3158 4.47012 14.0644 4.21875 13.7533 4.21875ZM13.0502 14.7656H3.76892V5.48438H13.0502V14.7656ZM16.0033 1.96875H5.17517C5.09783 1.96875 5.03455 2.03203 5.03455 2.10938V3.09375C5.03455 3.17109 5.09783 3.23438 5.17517 3.23438H15.3002V13.3594C15.3002 13.4367 15.3635 13.5 15.4408 13.5H16.4252C16.5025 13.5 16.5658 13.4367 16.5658 13.3594V2.53125C16.5658 2.22012 16.3144 1.96875 16.0033 1.96875ZM5.80798 9.66797H11.0111V10.793H5.80798V9.66797Z"
                  fill="#8F8F8F"
                />
              </svg>
            </Tooltip>
          )}
          {canDelete && (
            <Tooltip title="Delete node" placement="top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
              >
                <path
                  d="M6.82696 3.1575H6.68446C6.76283 3.1575 6.82696 3.09337 6.82696 3.015V3.1575H12.242V3.015C12.242 3.09337 12.3061 3.1575 12.3845 3.1575H12.242V4.44H13.5245V3.015C13.5245 2.38622 13.0132 1.875 12.3845 1.875H6.68446C6.05568 1.875 5.54446 2.38622 5.54446 3.015V4.44H6.82696V3.1575ZM15.8045 4.44H3.26446C2.94918 4.44 2.69446 4.69472 2.69446 5.01V5.58C2.69446 5.65838 2.75858 5.7225 2.83696 5.7225H3.91283L4.3528 15.0384C4.3813 15.6458 4.88361 16.125 5.49102 16.125H13.5779C14.1871 16.125 14.6876 15.6476 14.7161 15.0384L15.1561 5.7225H16.232C16.3103 5.7225 16.3745 5.65838 16.3745 5.58V5.01C16.3745 4.69472 16.1197 4.44 15.8045 4.44ZM13.4407 14.8425H5.62818L5.19711 5.7225H13.8718L13.4407 14.8425Z"
                  fill="#8F8F8F"
                />
              </svg>
            </Tooltip>
          )}
        </ActionGroup>
      </NodeContainer>
    </DisplayedNode>
  );
}
