import { styled, useTheme } from '@comet/styled';
import { TbShieldLockFilled } from 'react-icons/tb';
import { Flex, Typography } from '@comet/blocks';

const Background = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ErrorPage = () => {
  const { theme } = useTheme();
  return (
    <Background>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <TbShieldLockFilled
          color={theme.palette.accent.red.default}
          size={64}
        />
        <Typography.Title
          style={{ marginTop: '12px', color: theme.palette.accent.red.default }}
          level={3}
        >
          Hmm, looks like you are accessing a Forbidden resource.
        </Typography.Title>
        <Typography.Text style={{ textAlign: 'center' }}>
          Please check if you have enough permissions to access this page.
        </Typography.Text>
      </Flex>
    </Background>
  );
};
