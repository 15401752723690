import { styled } from '@comet/styled';
import { BusinessNodeProps } from '.';
import { BusinessNodeInfo } from './BusinessNodeHeader';
import { BusinessNodeIcon } from './BusinessNodeIcon';

const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.white};
  width: 220px;
`;

const InfoContainer = styled.div`
  padding: 10px;
  padding-top: 6px;
`;

export const BusinessNode = (props: BusinessNodeProps) => {
  return (
    <Container>
      <BusinessNodeIcon {...props} />
      <InfoContainer style={{ marginBottom: 10 }}>
        <BusinessNodeInfo {...props} />
      </InfoContainer>
    </Container>
  );
};
