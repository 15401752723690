import {
  Button,
  ButtonContainer,
  Flex,
  FormItem,
  Input,
  Select,
} from '@comet/blocks';
import { FooterDivider } from '@comet/pages/Project/Environments/Environment.styled';
import { SecretTypes, SecretTypesOption } from '../../constants';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { FormInstance } from 'antd';
import { useGetEnvironmentsOptions } from '@comet/pages/Project/Environments/utils';

interface SelectStepeProps {
  next: () => void;
  onCancel: () => void;
  setSecretType: Dispatch<SetStateAction<SecretTypes>>;
  form: FormInstance;
}

export const SelectStep = ({
  next,
  onCancel,
  setSecretType,
  form,
}: SelectStepeProps) => {
  const [nextStepEnabled, setNextStepEnabled] = useState(true);
  const [isNameDisabled, setIsNameDisabled] = useState(true);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) setNextStepEnabled(false);
    else setNextStepEnabled(true);
  };
  const { environmentsOptions } = useGetEnvironmentsOptions();

  const onChange = (value: SecretTypes) => {
    if (
      [SecretTypes.DATABASE_SECRET, SecretTypes.AUTHENTICATION_SECRET].includes(
        value
      )
    ) {
      form.setFieldValue('name', value);
      setNextStepEnabled(true);
      setIsNameDisabled(true);
    } else {
      form.setFieldValue('name', '');
      setIsNameDisabled(false);
      setNextStepEnabled(false);
    }

    setSecretType(value);
  };

  return (
    <Flex direction="column" width={'100%'}>
      <FormItem
        name="environmentId"
        label="Secret environment"
        required
        rules={[{ required: true, message: 'Environment is required!' }]}
        marginBottom={20}
      >
        <Select
          placeholder="Select environment"
          options={environmentsOptions}
        />
      </FormItem>
      <FormItem
        name="name"
        label="Secret name"
        required
        rules={[{ required: true, message: 'Secret Name is required!' }]}
        marginBottom={20}
        initialValue={SecretTypes.DATABASE_SECRET}
      >
        <Input
          placeholder="Enter secret name..."
          disabled={isNameDisabled}
          onChange={onNameChange}
        />
      </FormItem>

      <FormItem
        name="type"
        label="Type"
        required
        rules={[{ required: true, message: 'Secret type is required' }]}
        initialValue={SecretTypes.DATABASE_SECRET}
      >
        <Select
          style={{ width: 200 }}
          options={SecretTypesOption}
          onChange={onChange}
        />
      </FormItem>

      <FooterDivider />
      <ButtonContainer>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={next} disabled={!nextStepEnabled}>
          Next
        </Button>
      </ButtonContainer>
    </Flex>
  );
};
