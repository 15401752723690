import {
  ApiDetails,
  ApiDetailsRaw,
  GetApiDetailsParams,
  GetApiDetailsResponse,
  GetApiListParams,
  GetApisResponse,
} from '../Types';

import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';

export const createApi = (projectId: string, ApiData: ApiDetailsRaw) => {
  const url = `resources/${projectId}/flows`;
  return getAxios()
    .post(url, ApiData)
    .then(({ data }) => data);
};

export const useApiQuery = (
  projectId: string,
  apiId: string,
  dirtyParams?: GetApiDetailsParams,
  enabled?: boolean
) => {
  const url = `/resources/${projectId}/flows/${apiId}`;

  const params: GetApiDetailsParams = {};
  if (dirtyParams?.version === 'draft' || !dirtyParams?.version) {
    params.draft = true;
  } else {
    params.version = dirtyParams?.version;
  }

  return useQuery({
    queryKey: ['useApiQuery', projectId, apiId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetApiDetailsResponse>(url, {
        params,
      });
      return data;
    },
    enabled: enabled,
  });
};

export const updateApi = (
  projectId: string,
  apiId: string,
  apiDetails: Partial<ApiDetails>
) => {
  const url = `/resources/${projectId}/flows/${apiId}`;

  // Remove category from nodes before updating
  apiDetails.nodes?.map((node) => {
    delete node.data.category;
  });

  return getAxios()
    .patch(url, apiDetails)
    .then(({ data }) => data);
};

export const deleteApi = (projectId: string, apiId: string) => {
  const url = `/resources/${projectId}/flows/${apiId}`;

  return getAxios()
    .delete(url)
    .then(({ data }) => data);
};

export const useApisQuery = (
  projectId: string,
  params: GetApiListParams,
  isEnabled?: boolean
) => {
  const url = `/resources/${projectId}/flows`;
  const enabled = isEnabled === undefined ? true : !!isEnabled;
  return useQuery({
    queryKey: ['useApisQuery', projectId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetApisResponse>(url, {
        params,
      });
      return data;
    },
    enabled,
    cacheTime: 0, // disabled caching
  });
};
