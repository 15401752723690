import { Button, Flex, Modal, TableHeader, Text } from '@comet/blocks';
import { format } from '@comet/date';
import { RequestMethodComponent } from '@comet/pages/Api/utils';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { ILog } from '../types';
import { Table } from 'antd';
import { UrlContainer } from '@comet/components/UrlContainer';

export const ResponseInfo = ({ data }: { data: any }) => {
  return (
    <Flex gap={16} direction="column">
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Endpoint</Text>
        <Text appearance="label.short.regular">{data.endpoint}</Text>
      </Flex>
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Method</Text>
        <RequestMethodComponent requestMethod={data.method}>
          {data.method}
        </RequestMethodComponent>
      </Flex>
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Response</Text>
        <pre>{JSON.stringify(data.body, null, 2)}</pre>
      </Flex>
    </Flex>
  );
};

export const RequestInfo = ({ data }: { data: any }) => {
  return (
    <Flex gap={16} direction="column">
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Endpoint</Text>
        <Text appearance="label.short.regular">{data.endpoint}</Text>
      </Flex>
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Method</Text>
        <RequestMethodComponent requestMethod={data.method}>
          {data.method}
        </RequestMethodComponent>
      </Flex>
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Headers</Text>
        <pre>{JSON.stringify(data.headers, null, 2)}</pre>
      </Flex>
      {!!data.queryParams && (
        <Flex gap={10} direction="column">
          <Text appearance="heading.paragraph">Query params</Text>
          <pre>{JSON.stringify(data.queryParams, null, 2)}</pre>
        </Flex>
      )}
      {!!data.requestBody && (
        <Flex gap={10} direction="column">
          <Text appearance="heading.paragraph">Request body</Text>
          <pre>{JSON.stringify(data.requestBody, null, 2)}</pre>
        </Flex>
      )}
    </Flex>
  );
};

export const ErrorInfo = ({ data }: { data: any }) => {
  return (
    <Flex gap={16} direction="column">
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Stack Trace</Text>
        <pre>{data.stackTrace}</pre>
      </Flex>
    </Flex>
  );
};

export const DebugInfo = ({ data }: { data: any }) => {
  return (
    <Flex gap={16} direction="column">
      <Flex gap={10} direction="column">
        <Text appearance="heading.paragraph">Debug Values</Text>
        <Table
          columns={[
            {
              title: 'Value',
              dataIndex: 'value',
              key: 'value',
              render: (text: any) => <pre>{JSON.stringify(text, null, 2)}</pre>,
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
            },
          ]}
          dataSource={data.body.map(({ value, type }: any) => ({
            value,
            type,
          }))}
          pagination={false}
        />
      </Flex>
    </Flex>
  );
};

export const LogsModal = ({ logData }: { logData: any }) => {
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setShow((show) => !show);
  };

  return (
    <>
      <Button type="link" onClick={toggleModal}>
        View
      </Button>
      <Modal
        width={720}
        onCancel={toggleModal}
        title={null}
        open={show}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          {logData.type === 'RESPONSE' && <ResponseInfo data={logData} />}
          {logData.type === 'REQUEST' && <RequestInfo data={logData} />}
          {logData.type === 'ERROR' && <ErrorInfo data={logData} />}
          {logData.type === 'DEBUG' && <DebugInfo data={logData} />}
        </Flex>
      </Modal>
    </>
  );
};

export const useLogListingData = (logsData: ILog[]) => {
  console.table(logsData);
  const tableData = useMemo(() => {
    if (!logsData) return [];
    return logsData.map((item) => {
      if (item.type === 'ERROR') {
        return {
          id: item.id,
          type: item.type,
          time: format(item.timestamp, 'yyyy-MM-dd HH:mm:ss'),
          logLevel: item.logLevel,
          data: <LogsModal logData={item} />,
          endpoint: '-',
          method: '-',
        };
      }
      if (item.type === 'DEBUG') {
        return {
          id: item.id,
          type: item.type,
          time: format(item.timestamp, 'yyyy-MM-dd HH:mm:ss'),
          logLevel: item.logLevel,
          data: <LogsModal logData={item} />,
          endpoint: '-',
          method: '-',
        };
      }
      return {
        id: item.id,
        type: item.type,
        time: format(item.timestamp, 'yyyy-MM-dd HH:mm:ss'),
        logLevel: item.logLevel,
        flowName: item.flowName,
        endpoint: item.endpoint,
        method: (
          <RequestMethodComponent requestMethod={item.method}>
            {item.method}
          </RequestMethodComponent>
        ),
        data: <LogsModal logData={item} />,
      };
    });
  }, [logsData]);

  const tableColumns = useMemo((): Column<any>[] => {
    return [
      {
        Header: <TableHeader text="Timestamp" sortable />,
        accessor: 'time',
      },
      {
        Header: <TableHeader text="ID" sortable />,
        accessor: 'id',
      },
      {
        Header: <TableHeader text="Type" sortable />,
        accessor: 'type',
      },
      {
        Header: <TableHeader text="Log Level" sortable />,
        accessor: 'logLevel',
      },
      {
        Header: <TableHeader text="Endpoint" sortable />,
        accessor: 'endpoint',
      },
      {
        Header: <TableHeader text="Method" sortable />,
        accessor: 'method',
      },
      {
        Header: <TableHeader text="Log Data" />,
        accessor: 'data',
      },
    ];
  }, []);

  return { tableColumns, tableData };
};
