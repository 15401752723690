import { useCallback, useEffect } from 'react';
import { SUBSCRIPTION_STATUS } from '../types';
import { notification } from 'antd';
import { useGetSubscriptionStatus } from '../service';
import { getAlertType } from './subscriptions.utils';

type useSubscriptionStatusPollingParams = {
  isSubscriptionUpdateSuccess: boolean;
  refetchEnvironments: () => void;
  refetchStorageAccount: () => void;
};

export const useSubscriptionStatusPolling = ({
  isSubscriptionUpdateSuccess,
  refetchEnvironments,
  refetchStorageAccount,
}: useSubscriptionStatusPollingParams) => {
  const { data, refetch: subscriptionStatusRefetch } =
    useGetSubscriptionStatus();

  const POLLING_INTERVAL = 2000;

  const onSubscriptionUpdateSuccess = useCallback(() => {
    if (
      isSubscriptionUpdateSuccess &&
      data?.status === SUBSCRIPTION_STATUS.ACTIVE
    ) {
      notification.success({
        message: 'Success',
        description: 'Subscription updated successfully',
      });
      refetchEnvironments();
      refetchStorageAccount();
    }
  }, [
    data,
    isSubscriptionUpdateSuccess,
    refetchEnvironments,
    refetchStorageAccount,
  ]);

  useEffect(() => {
    const pollRefetch = () => {
      if (data?.status === SUBSCRIPTION_STATUS.UPDATING) {
        subscriptionStatusRefetch();
      } else {
        return;
      }
    };

    onSubscriptionUpdateSuccess();

    const timerId = setInterval(pollRefetch, POLLING_INTERVAL);

    return () => {
      clearInterval(timerId);
    };
  }, [onSubscriptionUpdateSuccess, data, subscriptionStatusRefetch]);

  const isCreateSubscriptionBanner =
    data?.status === SUBSCRIPTION_STATUS.NEW_PROJECT;
  const isDraftSubscriptionBanner =
    data?.status === SUBSCRIPTION_STATUS.NEW_DRAFTS;
  const isUnpaidSubscriptionBanner =
    data?.status === SUBSCRIPTION_STATUS.UNPAID;
  const isUpdatingSubscriptionBanner =
    data?.status === SUBSCRIPTION_STATUS.UPDATING;
  const alertType = getAlertType(data?.status);

  const showBanner =
    isCreateSubscriptionBanner ||
    isDraftSubscriptionBanner ||
    isUnpaidSubscriptionBanner ||
    isUpdatingSubscriptionBanner;

  return {
    alertType,
    isCreateSubscriptionBanner,
    isDraftSubscriptionBanner,
    isUnpaidSubscriptionBanner,
    isUpdatingSubscriptionBanner,
    showBanner,
  };
};
