import { Button, ButtonContainer, Flex, Show } from '@comet/blocks';
import { FooterDivider } from '@comet/pages/Project/Environments/Environment.styled';
import { SecretTypes } from '../../constants';
import { ConfigureAuthentication } from './ConfigureComponents/ConfigureAuthentication';
import { ConfigureDatabase } from './ConfigureComponents/ConfigureDatabase';
import { ConfigureCustom } from './ConfigureComponents/ConfigureCustom';

interface ConfigureStepProps {
  prev: () => void;
  secretType: string;
  loading: boolean;
}

export const ConfigureStep = ({
  prev,
  secretType,
  loading,
}: ConfigureStepProps) => {
  return (
    <Flex direction="column" width={'100%'}>
      <Show if={secretType === SecretTypes.AUTHENTICATION_SECRET}>
        <ConfigureAuthentication />
      </Show>

      <Show if={secretType === SecretTypes.DATABASE_SECRET}>
        <ConfigureDatabase />
      </Show>

      <Show if={secretType === SecretTypes.CUSTOM_SECRET}>
        <ConfigureCustom />
      </Show>
      <FooterDivider />
      <ButtonContainer>
        <Button onClick={prev}>Prev</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={secretType === SecretTypes.DATABASE_SECRET && loading}
        >
          Create
        </Button>
      </ButtonContainer>
    </Flex>
  );
};
