export const font = {
  'caption.regular': {
    tag: 'p',
    style: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '1%',
    },
  },
  'label.small': {
    tag: 'p',
    style: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '16px',
      letterSpacing: '1%',
    },
  },
  'caption.semibold': {
    tag: 'p',
    style: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '1%',
    },
  },
  'caption.allCaps': {
    tag: 'p',
    style: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '6%',
    },
  },
  'caption.longText': {
    tag: 'p',
    style: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 500,
      letterSpacing: '1%',
    },
  },
  'label.long.regular': {
    tag: 'p',
    style: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
      letterSpacing: '0.8%',
    },
  },
  'label.long.semiBold': {
    tag: 'p',
    style: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '0.8%',
    },
  },

  'label.short.regular': {
    tag: 'p',
    style: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
    },
  },
  'label.short.semiBold': {
    tag: 'p',
    style: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 600,
    },
  },
  'body.regular': {
    tag: 'p',
    style: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
  },
  'body.semiBold': {
    tag: 'p',
    style: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
    },
  },
  'heading.large': {
    tag: 'h1',
    style: {
      fontSize: '32px',
      lineHeight: '44px',
      fontWeight: 600,
    },
  },
  'heading.page': {
    tag: 'h2',
    style: {
      fontSize: '22px',
      lineHeight: '28px',
      fontWeight: 700,
    },
  },
  'heading.card': {
    tag: 'h3',
    style: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 600,
    },
  },
  'heading.paragraph': {
    tag: 'h4',
    style: {
      fontSize: '15px',
      lineHeight: '24px',
      fontWeight: 600,
    },
  },
} as const;

export const constants = {
  font,
  borderRadius: {
    md: '4px',
    lg: '6px',
    xlg: '8px',
  },
  paperSize: {
    small: '480px',
    medium: '720px',
    large: '100%',
  },
};

export const lightTheme = {
  palette: {
    neutral: {
      darkest: '#212121',
      darker: '#6A6A6A',
      dark: '#8F8F8F',
      light: '#D4D1CE',
      lighter: '#EEEEEE',
      lightest: '#FAFAFA',
      white: '#FFFFFF',
    },
    primary: '#5869D6',
    danger: '#CB1A4A',
    link: '#2361BE',
    accent: {
      yellow: { light: '#EEE48C', dark: '#424450', lighter: '#6A6A6A' },
      red: {
        lightest: '#ffb9c5',
        light: '#E78D8B',
        lighter: '#FF4D4F',
        dark: '#601917',
        darker: '#850015',
        default: '#C51640',
      },
      green: { light: '#92D1B2', dark: '#38634E', darker: '#25A244' },
      blue: { light: '#6AD1B2', darker: '#4259BD' },
    },
    success: {
      lightest: '#ABE7D5',
      light: '#6AD1B2',
      dark: '#005D45',
      default: '#0B856A',
    },
  },
};

export const textColor = {
  'neutral.darkest': {
    color: lightTheme.palette.neutral.darkest,
  },
  'neutral.darker': {
    color: lightTheme.palette.neutral.darker,
  },
  'neutral.dark': {
    color: lightTheme.palette.neutral.dark,
  },
  'neutral.light': {
    color: lightTheme.palette.neutral.light,
  },
  'neutral.lighter': {
    color: lightTheme.palette.neutral.lighter,
  },
  'neutral.lightest': {
    color: lightTheme.palette.neutral.lightest,
  },
  'neutral.white': {
    color: lightTheme.palette.neutral.white,
  },
  primary: {
    color: lightTheme.palette.primary,
  },
};

const devices = {
  desktop: '1024px',
  laptop: '992px',
  tablet: '768px',
  mobile: '480px',
};

export const darkTheme = lightTheme;

export const defaultTheme = { constants, devices, ...lightTheme };
