import { styled } from '@comet/styled';
import { Show } from '../Show';
import { Text, TextProps } from '../Text/Text';

export interface LabelProps extends Omit<TextProps, 'appearance'> {
  required?: boolean;
}

const Wrapper = styled.div<{ required?: boolean }>`
  display: flex;
  position: relative;
  ${({ required }) => (required ? 'padding-right: 6px' : '')}
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
  right: -4px;
  ${({ theme }) => `background: ${theme.palette.danger}`}
`;

export const Label = ({ required, ...props }: LabelProps) => {
  return (
    <Wrapper required={required}>
      <Text appearance="label.short.semiBold" {...props} />
      <Show if={!!required}>
        <Dot />
      </Show>
    </Wrapper>
  );
};
