import './style.css';

import { Position, useNodeId } from 'reactflow';
import {
  getNodeById,
  useFlowEngineStore as useFlowEngineStoreV2,
} from '../store';

import { BusinessNodeEnum } from '../types';
import { LogicNode } from './LogicNode';
import { StyledHandle } from './StyledHandle';
import { useCallback } from 'react';

export function SwitchNode({
  data: { displayName, nodeName, businessNodeName },
}: {
  data: {
    displayName: string;
    nodeName: string;
    businessNodeName: BusinessNodeEnum;
  };
}) {
  const { deleteNode } = useFlowEngineStoreV2(
    useCallback((s) => ({ deleteNode: s.onDeleteNode }), [])
  );

  const nodeId = useNodeId();
  const node = getNodeById(nodeId);

  if (!nodeId || !node) return null;

  const logicNodeProps = {
    node: node,
    nodeSkeletonProp: {
      displayName,
      nodeName,
      businessNodeName,
      canDelete: true,
      isEmbeddedFlow: false,
      handleDelete: () => {
        deleteNode(nodeId);
      },
      handleEmbeddedFlow: () => {},
    },
  };

  return (
    <>
      <StyledHandle type="target" position={Position.Top} />
      <LogicNode {...logicNodeProps} />
      <StyledHandle type="source" position={Position.Bottom} />
    </>
  );
}
