import { ActionModalPropsType } from './types';
import { IconContext } from 'react-icons';
import Modal from '../Modal/Modal';
import { PropsWithChildren } from 'react';
import { getIcon } from './utils';
import styled from 'styled-components';

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: 'center';
`;

export function ActionModal(props: PropsWithChildren<ActionModalPropsType>) {
  const { type, title: propsTitle, children, ...restProps } = props;
  const { icon: Icon, iconColor } = getIcon(type);

  const titleText = propsTitle ? propsTitle : <>Modal</>;
  const title = (
    <StyledTitle>
      <IconContext.Provider
        value={{ style: { fontSize: '24px', color: iconColor } }}
      >
        <Icon />
      </IconContext.Provider>
      {titleText}
    </StyledTitle>
  );

  return (
    <Modal {...restProps} title={title}>
      {children}
    </Modal>
  );
}
