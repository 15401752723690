import { BrowserRouter } from '@comet/router';
import { IconContext } from 'react-icons';
import { ThemeProvider } from '@comet/styled';
import { AuthProvider } from './components/auth/AuthProvider';
import { BaseProvider } from '@comet/blocks';
import { queryClient, QueryClientProvider } from '@comet/query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Pages from './pages';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { gb } from './service/growthbook';
import ReactGA from 'react-ga4';

const ICON_CONFIG = {
  size: '16px',
};

gb.init({
  // Optional, enable streaming updates
  streaming: true,
});

function App() {
  if (process.env.REACT_APP_GA4_ID != undefined) {
    ReactGA.initialize(process.env.REACT_APP_GA4_ID || '');
  }

  return (
    <GrowthBookProvider growthbook={gb}>
      <BaseProvider>
        <ThemeProvider>
          <IconContext.Provider value={ICON_CONFIG}>
            <AuthProvider>
              <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                  <Pages />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </BrowserRouter>
            </AuthProvider>
          </IconContext.Provider>
        </ThemeProvider>
      </BaseProvider>
    </GrowthBookProvider>
  );
}

export default App;
