import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  FormItem,
  Input,
  Select,
  Switch,
  Text,
} from '@comet/blocks';
import { FormInstance } from 'antd';
import { memo, useCallback, useState } from 'react';
import {
  DynamicTokenFilterInputMapping,
  TokenFilterTypesOptions,
} from '../CustomTokenizerInput';
import {
  TokenFilterFormData,
  TokenFilterInputFieldType,
  TokenFilterTypes,
} from '../types';
import { mergeFormItemName } from '../utils';

interface ITokenFilterInputPorps {
  form: FormInstance;
}
const TokenFilterInput = memo(({ form }: ITokenFilterInputPorps) => {
  const [tokenFilterFields, setTokenFilterFields] = useState<
    Array<TokenFilterFormData | { type: '' }>
  >([]);
  const handleSelectFilterType = useCallback(
    (name: number, value: TokenFilterTypes, index: number) => {
      setTokenFilterFields((fields) => {
        return fields.map((field, i) => {
          return i === index ? { ...field, type: value } : { ...field };
        }) as TokenFilterFormData[];
      });
    },
    []
  );
  const addTokenFilter = useCallback(() => {
    setTokenFilterFields((fields) => [
      ...fields,
      {
        type: '',
      },
    ]);
  }, []);
  const removeTokenFilter = useCallback((index: number) => {
    setTokenFilterFields((fields) => fields.filter((_, i) => i !== index));
  }, []);
  const renderInputField = useCallback(
    (
      inputField: TokenFilterInputFieldType,
      formName?: number | string | Array<string | number>
    ): React.ReactNode => {
      const inputFieldName = mergeFormItemName(formName, inputField.name);
      if (inputField.type === 'number' || inputField.type === 'text') {
        return (
          <FormItem
            name={inputFieldName}
            label={inputField.name}
            key={inputField.name}
            style={{ marginBottom: 0 }}
            rules={[
              {
                required: inputField.required,
                message: 'This field is required',
              },
            ]}
          >
            <Input
              type={inputField.type}
              placeholder={inputField.name}
              style={{ width: 200 }}
            />
          </FormItem>
        );
      }
      if (inputField.type === 'object') {
        if (inputField.fields?.length) {
          const objectOptionsParentName = mergeFormItemName(
            formName,
            inputField.name
          );
          return (
            <Flex direction="column" key={inputField.name} gap={10}>
              <Text appearance="label.long.semiBold">
                {inputField.name}
                {inputField.required ? '*' : ''}
              </Text>
              {inputField.fields?.map((field) =>
                renderInputField(field, objectOptionsParentName)
              )}
            </Flex>
          );
        }
        return null;
      }
      if (inputField.type === 'stringarray') {
        return (
          <Flex direction="column" key={inputField.name} gap={10}>
            <Text appearance="label.long.semiBold">
              {inputField.name}
              {inputField.required ? '*' : ''}
            </Text>
            <Form.List name={inputFieldName}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restFields }, fieldIndex) => (
                    <Flex
                      key={key}
                      alignItems="center"
                      style={{ marginBottom: 10 }}
                      gap={10}
                    >
                      <FormItem
                        name={[name]}
                        key={key}
                        style={{ marginBottom: 0 }}
                        rules={[
                          {
                            required: true,
                            message: 'This field is required',
                          },
                        ]}
                      >
                        <Input type="text" />
                      </FormItem>
                      {fieldIndex === fields.length - 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(fieldIndex)}
                        />
                      )}
                    </Flex>
                  ))}
                  <Button
                    type="dashed"
                    block
                    style={{ width: 200 }}
                    icon={<PlusOutlined />}
                    onClick={() => add()}
                  >
                    Add
                  </Button>
                </>
              )}
            </Form.List>
          </Flex>
        );
      }
      if (inputField.type === 'boolean') {
        return (
          <FormItem
            label={inputField.name}
            style={{ marginBottom: 0, flex: 1 }}
            key={inputField.name}
            name={inputFieldName}
            rules={[
              {
                required: inputField.required,
                message: 'This field is required',
              },
            ]}
          >
            <Switch title={inputField.name} />
          </FormItem>
        );
      }
      if (inputField.type === 'dropdown') {
        return (
          <FormItem
            key={inputField.name}
            label={inputField.name}
            style={{ marginBottom: 0 }}
            name={inputFieldName}
            rules={[
              {
                required: inputField.required,
                message: 'This field is required',
              },
            ]}
          >
            <Select options={inputField.options} style={{ width: 200 }} />
          </FormItem>
        );
      }
      return null;
    },
    []
  );
  const renderTokenFilterFields = useCallback(
    (
      type: TokenFilterTypes | '',
      formName: number | string | Array<string | number>
    ) => {
      if (!type || type.length === 0) {
        return null;
      }
      const inputFields = DynamicTokenFilterInputMapping[type];
      if (inputFields?.length === 0) {
        return null;
      }
      return (
        <Flex style={{ width: '100%' }} direction="column" gap={10}>
          {inputFields.map((field, index) => (
            <Flex direction="column" key={index}>
              {renderInputField(field, formName)}
            </Flex>
          ))}
        </Flex>
      );
    },
    []
  );
  return (
    <Flex direction="column" gap={16} style={{ marginTop: 16 }}>
      <Flex direction="column" gap={10}>
        <Text appearance="label.long.semiBold">Token Filters</Text>
        <Form.List name="tokenFilters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restFields }, index) => (
                <Flex
                  key={key}
                  direction="column"
                  style={{
                    width: '100%',
                    paddingBottom: 8,
                    borderBottom: '1px solid #f0f0f0',
                  }}
                >
                  <Flex
                    direction="row"
                    style={{ marginBottom: 8 }}
                    gap={8}
                    alignItems="flex-start"
                  >
                    <FormItem
                      name={[name, 'type']}
                      style={{ marginBottom: 0 }}
                      label="Token Filter Type"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Token Filter Type',
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 200 }}
                        options={TokenFilterTypesOptions}
                        placeholder="Token Filter Type"
                        onChange={(value) => {
                          handleSelectFilterType(name, value, index);
                        }}
                      />
                    </FormItem>
                    {index === fields.length - 1 && (
                      <MinusCircleOutlined
                        style={{ marginTop: 4 }}
                        onClick={() => {
                          remove(name);
                          removeTokenFilter(index);
                        }}
                      />
                    )}
                  </Flex>
                  {renderTokenFilterFields(
                    tokenFilterFields[index]?.type,
                    name
                  )}
                </Flex>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                    addTokenFilter();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Token Filter
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Flex>
    </Flex>
  );
});

export default TokenFilterInput;
