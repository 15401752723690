import { getAxios } from '@comet/axios';
import { useQuery, useMutation } from '@comet/query';
import { Project } from '../../Project.types';

const getCloudRegions = async () => {
  const url = 'metadata/providers';
  const { data } = await getAxios().get(url);

  return data.data;
};

export const useGetCloudRegions = () => {
  return useQuery(['cloud-regions'], getCloudRegions);
};

export const createProjectAPI = (orgId: string, projectData: Project) => {
  const url = `/organisations/${orgId}/projects`;
  return getAxios()
    .post<{ id: string }>(url, projectData)
    .then(({ data }) => data);
};

export const useProjectMutation = (organisationId: string) =>
  useMutation({
    mutationFn: (project: Project) => createProjectAPI(organisationId, project),
  });

const checkProject = (url: string, projectName: string) => {
  return getAxios()
    .get<{ exists: boolean }>(url, {
      params: { name: projectName },
    })
    .then(({ data }) => data);
};

export const useProjectExistsQuery = (
  organisationId: string,
  projectName: string
) => {
  const url = `/organisations/${organisationId}/projects/exists`;

  return useQuery({
    enabled: false,
    queryKey: ['useProjectExistsQuery', projectName],
    queryFn: async () => {
      return await checkProject(url, projectName);
    },
  });
};
