import { useCallback, useEffect, useRef } from 'react';
import { useSearchIndexExistsQuery } from '../service';
import {
  Flex,
  Input,
  FormItem,
  InputHelpText,
  Select,
  SelectOption,
} from '@comet/blocks';

import debounce from 'lodash.debounce';
import { useGetDatabseCollectionOptions } from '../utils';
import { FooterDivider } from '../SearchIndex.styled';
import { useGetEnvironmentsOptions } from '@comet/pages/Project/Environments/utils';

interface SearchIndexDetailsProps {
  environmentId: string;
  name: string;
  setName: (value: string) => void;
  selectedModel: string;
  setSelectedModel: (value: string) => void;
  nameError: string;
  setNameError: (value: string) => void;
}

export const SearchIndexDetails = ({
  environmentId,
  name,
  setName,
  setSelectedModel,
  nameError,
  setNameError,
}: SearchIndexDetailsProps) => {
  const DatabaseCollectionOptions = useGetDatabseCollectionOptions();

  const { environmentsOptions } = useGetEnvironmentsOptions();

  // Search Index exists check logic start
  const searchIndexExistsQuery = useSearchIndexExistsQuery(
    name || '',
    environmentId || ''
  );

  useEffect(() => {
    setNameError(
      searchIndexExistsQuery.data?.exists ? 'Search Index already exists' : ''
    );
  }, [searchIndexExistsQuery.data, setNameError]);

  const debouncedSearchIndexNameCheck = useRef(
    debounce(searchIndexExistsQuery.refetch, 500)
  );
  // Search Index exists check logic end

  const onModelSelect = useCallback(
    (value: string, option: SelectOption) => {
      setSelectedModel(value);

      if ('label' in option) {
        setName(option.label);
      }
    },
    [setSelectedModel, setName]
  );

  return (
    <Flex direction="column" gap={10} width={'100%'}>
      <FormItem
        name="environmentId"
        label="Secret environment"
        required
        rules={[{ required: true, message: 'Environment is required!' }]}
        marginBottom={8}
      >
        <Select
          placeholder="Select environment"
          options={environmentsOptions}
        />
      </FormItem>
      <FormItem
        name="databaseCollectionModel"
        label="Choose Database Collection Model"
        rules={[
          { required: true, message: 'Database Collection Model is required' },
        ]}
        required
        style={{ width: 256 }}
        marginBottom={8}
      >
        <Select
          placeholder="Select Database Collection"
          onChange={(value, option) => {
            onModelSelect(value, option);
            if (value) {
              debouncedSearchIndexNameCheck.current();
            }
          }}
          options={DatabaseCollectionOptions}
        />
      </FormItem>
      <Flex direction="column" gap={16} width={256}>
        <Flex direction="column">
          <FormItem label={'Search Index Name'} marginBottom={0}>
            <Input
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                if (event.target.value) {
                  debouncedSearchIndexNameCheck.current();
                }
              }}
            />
          </FormItem>
          <InputHelpText
            error={nameError}
            success={
              !!name &&
              searchIndexExistsQuery.data?.exists === false &&
              !searchIndexExistsQuery.isFetching &&
              'Search Index Name is available.'
            }
          >
            {searchIndexExistsQuery.isFetching &&
              'Checking if Search Index exists...'}
          </InputHelpText>
        </Flex>
      </Flex>
      <FooterDivider />
    </Flex>
  );
};
