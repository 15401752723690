export const StorageAccountDraftStateMapping = {
  CREATED: 'Created',
  UPDATED_STORAGE: 'Updated Storage',
  DELETED: 'Deleted',
};

export const StorageAccountStateMapping: { [key: string]: [string, string] } = {
  CREATED: ['Created', 'palette.neutral.dark'],
  ACTIVE: ['Active', 'palette.accent.green.light'],
  DRAFT: ['Saved as draft', '#D16E4E'],
  DELETED: ['Deleted', 'palette.accent.blue.light'],
};

export const GB_TO_BYTES = 1073741824;
export const PAGE_SIZE = 10;
