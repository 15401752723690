import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { NodeRaw } from '../Nodes';

export const useNodeQuery = () => {
  const url = '/metadata/nodes';

  return useQuery({
    queryKey: ['useNodeQuery'],
    queryFn: async () => {
      const { data } = await getAxios().get<{ data: NodeRaw[] }>(url, {
        params: { limit: 10000 },
      });

      const blocks = data.data.map((block) => {
        if (block.data.type === 'IF_ELSE') {
          return { type: 'IF_NODE', data: block.data };
        }
        if (!block.data.values) {
          block.data.values = {};
        }
        return block;
      });

      return blocks as NodeRaw[];
    },
  });
};
