import {
  Input as AntInput,
  type InputProps as AntInputProps,
  InputNumber,
  type InputNumberProps,
} from 'antd';

interface InputProps extends AntInputProps {
  fullWidth?: boolean;
}

const { Search } = AntInput;
const Input = AntInput;

export { Input, InputNumber, Search };
export type { InputProps, InputNumberProps };

export { InputUrl } from './InputUrl';
