import {
  Button,
  ButtonContainer,
  Flex,
  Form,
  FormError,
  FormItem,
  Input,
  InputHelpText,
  Text,
} from '@comet/blocks';
import { useEffect, useRef, useState } from 'react';
import { useGoBack, useNavigate, useParams } from '@comet/router';
import { queryClient, useMutation } from '@comet/query';
import { EnvironmentTypesEnum } from '../../Environments/types';
import { FooterDivider } from '../../Environments/Environment.styled';
import { useGetEnvironmentsOptions } from '../../Environments/utils';
import { createDatabaseInstance, useDbInstanceExistsQuery } from '../service';
import debounce from 'lodash.debounce';
import { getError } from '@comet/axios';

interface CreateSecretTypes {
  environmentId: string;
}

export const CreateDatabaseForm = ({ environmentId }: CreateSecretTypes) => {
  const [form] = Form.useForm();

  const { projectId } = useParams();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const [instanceName, setInstanceName] = useState('');
  const [instanceExistsError, setInstanceExistsError] = useState('');

  const onSuccess = (id: string) => {
    queryClient.refetchQueries({
      queryKey: ['useGetDatabasesQuery', projectId, environmentId],
    });
    navigate('organisations.organisationId.projects.projectId.databases', {
      projectId,
    });
  };
  const instanceExistsQuery = useDbInstanceExistsQuery(
    instanceName || '',
    environmentId
  );

  useEffect(() => {
    setInstanceExistsError(
      instanceExistsQuery.data?.exists ? 'DB Instance already exists' : ''
    );
  }, [instanceExistsQuery.data]);

  const debouncedDbInstanceNameCheck = useRef(
    debounce(instanceExistsQuery.refetch, 500)
  );

  const createDbInstanceMutation = useMutation({
    mutationFn: (data: { name: string; connectionString: string }) => {
      return createDatabaseInstance(projectId, environmentId, data);
    },
    onSuccess: ({ id }) => onSuccess(id),
  });
  const error = getError(createDbInstanceMutation.error);

  const handleFinish = (data: { name: string; connectionString: string }) => {
    createDbInstanceMutation.mutate(data);
    return;
  };

  return (
    <Flex direction="column" gap={16}>
      <Text appearance="heading.card">Create a new Database instance</Text>
      <Form
        autoComplete="off"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
      >
        <Flex direction="column">
          <FormItem
            name="name"
            label="Database instance name"
            required
            rules={[{ required: true, message: 'Instance name is required' }]}
            marginBottom={0}
          >
            <Input
              placeholder="Enter instance name..."
              value={instanceName}
              onChange={(event) => {
                setInstanceName(event.target.value);
                if (event.target.value) {
                  debouncedDbInstanceNameCheck.current();
                }
              }}
            />
          </FormItem>
          <InputHelpText
            error={instanceExistsError}
            success={
              !!instanceName &&
              instanceExistsQuery.data?.exists === false &&
              !instanceExistsQuery.isFetching &&
              'DB Instance Name is available.'
            }
          >
            {!instanceExistsQuery.isFetching &&
              'Checking if Instance exists...'}
          </InputHelpText>
        </Flex>

        <FormItem
          name="connectionString"
          label="Connection string"
          required
          rules={[
            {
              required: true,
              message: 'MongoDB connection string is required',
            },
          ]}
        >
          <Input placeholder="Enter MongoDB connection string..." />
        </FormItem>

        <FormError>{error}</FormError>
        <FooterDivider />
        <ButtonContainer>
          <Button onClick={goBack} appearance="transparent">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </ButtonContainer>
      </Form>
    </Flex>
  );
};
