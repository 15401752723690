import { CoordinateExtent, XYPosition } from 'reactflow';

// Node Type coming from backend
export enum NodeTypeEnum {
  RootNode = 'ROOT_NODE',
  AddNode = 'ADD_NODE',
  IfElse = 'IF_ELSE',
  IfElseV2 = 'IF_ELSE_V2',
  SwitchNode = 'SWITCH_NODE',
  SwitchV2Node = 'SWITCH_V2_NODE',
  GroupNode = 'GROUP_NODE',
  ConcurrentNode = 'CONCURRENT_NODE',
  BaseNode = 'BASE_NODE',
  ExecutorNode = 'EXECUTOR_NODE',
  LoopNode = 'LOOP_NODE',
  AddCaseNode = 'ADD_CASE_NODE',
}

export enum FieldTypeEnum {
  BOOLEAN = 'BOOLEAN',
  INPUT = 'INPUT',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_URL = 'INPUT_URL',
  SELECT = 'SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  MULTI_SELECT_SEARCHABLE = 'MULTI_SELECT_SEARCHABLE',
  SELECT_SEARCHABLE = 'SELECT_SEARCHABLE',
  STATE_VALUE = 'STATE_VALUE',
  LIST_INPUT = 'LIST_INPUT',
  LIST_INPUT_INPUT = 'LIST_INPUT_INPUT',
  LIST_INPUT_SELECT = 'LIST_INPUT_SELECT',
  LIST_INPUT_SELECT_SEARCHABLE = 'LIST_INPUT_SELECT_SEARCHABLE',
  JSON_CODE_SNIPPET = 'JSON_CODE_SNIPPET',
  QUERY_MODEL = 'QUERY_MODEL',
  LIST_STATE_VALUE = 'LIST_STATE_VALUE',
  DYNAMIC_FIELDS = 'DYNAMIC_FIELDS',
}

export enum BusinessNodeEnum {
  'API_CALL' = 'API_CALL',
  'ROOT_NODE' = 'ROOT_NODE',
  'ADD_CASE_NODE' = 'ADD_CASE_NODE',
  'HTTP_TRIGGER' = 'HTTP_TRIGGER',
  'HTTP_RESPONSE' = 'HTTP_RESPONSE',
  'FUNCTION_RESPONSE' = 'FUNCTION_RESPONSE',
  'ACTION' = 'ACTION',
  'DB' = 'DB',
  'DEBUG' = 'DEBUG',
  'START' = 'START',
  'STOP' = 'STOP',
  'CUSTOM_QUERY_RECORDS' = 'CUSTOM_QUERY_RECORDS',
  'DELETE_MULTIPLE_RECORDS' = 'DELETE_MULTIPLE_RECORDS',
  'DELETE_RECORD' = 'DELETE_RECORD',
  'FETCH_RECORD' = 'FETCH_RECORD',
  'FIND_MANY' = 'FIND_MANY',
  'FIND_ONE' = 'FIND_ONE',
  'INSERT_MULTIPLE_RECORDS' = 'INSERT_MULTIPLE_RECORDS',
  'INSERT_RECORD' = 'INSERT_RECORD',
  'LIST_RECORDS' = 'LIST_RECORDS',
  'UPDATE_MULTIPLE_RECORDS' = 'UPDATE_MULTIPLE_RECORDS',
  'UPDATE_RECORD' = 'UPDATE_RECORD',
  'UPDATE_ONE_RECORD' = 'UPDATE_ONE_RECORD',
  'SET_VARIABLE' = 'SET_VARIABLE',
  'BUILD_JSON' = 'BUILD_JSON',
  'BUILD_MAP' = 'BUILD_MAP',
  'VALIDATE_MODEL' = 'VALIDATE_MODEL',
  'SWITCH_CASE' = 'SWITCH_CASE',
  'IF_ELSE' = 'IF_ELSE',
  'EXECUTOR_NODE' = 'EXECUTOR_NODE',
  'ADD_NODE' = 'ADD_NODE',
  'ADD_VARIABLE' = 'ADD_VARIABLE',
  'APPEND_TO_STRING' = 'APPEND_TO_STRING',
  'APPEND_TO_ARRAY' = 'APPEND_TO_ARRAY',
  'COMPLEX_MATHS_EXPR' = 'COMPLEX_MATHS_EXPR',
  'CONCAT_STRINGS' = 'CONCAT_STRINGS',
  'CONTAINS' = 'CONTAINS',
  'DECREMENT_VARIABLE' = 'DECREMENT_VARIABLE',
  'DIVIDE_VARIABLE' = 'DIVIDE_VARIABLE',
  'EMPTY_ARRAY' = 'EMPTY_ARRAY',
  'FOR_LOOP' = 'FOR_LOOP',
  'INCREMENT_VARIABLE' = 'INCREMENT_VARIABLE',
  'LENGTH_ARRAY' = 'LENGTH_ARRAY',
  'MERGE_JSON' = 'MERGE_JSON',
  'MULTIPLY_VARIABLE' = 'MULTIPLY_VARIABLE',
  'REVERSE_ARRAY' = 'REVERSE_ARRAY',
  'SLICE_STRING' = 'SLICE_STRING',
  'SUBTRACT_VARIABLE' = 'SUBTRACT_VARIABLE',
  'TO_LOWER' = 'TO_LOWER',
  'TO_UPPER' = 'TO_UPPER',
  'TRIM_STRING' = 'TRIM_STRING',
  'IF_ELSE_V2' = 'IF_ELSE_V2',
  'SWITCH_CASE_V2' = 'SWITCH_CASE_V2',
  'WHILE_LOOP' = 'WHILE_LOOP',
  'ARRAY_ITEM_AT' = 'ARRAY_ITEM_AT',
  'EXISTS_NODE' = 'EXISTS_NODE',
  'CURRENT_DATETIME' = 'CURRENT_DATETIME',
  'GET_PRESIGNED_URL' = 'GET_PRESIGNED_URL',
  'POST_PRESIGNED_URL' = 'POST_PRESIGNED_URL',
  'STRING_OPERATIONS' = 'STRING_OPERATIONS',
  'SPLIT_STRING' = 'SPLIT_STRING',
  'OBJECT_TO_STRING' = 'OBJECT_TO_STRING',
  'LENGTH_STRING' = 'LENGTH_STRING',
  'CONVERT_TO_STRING' = 'CONVERT_TO_STRING',
  'EXECUTE_FUNCTION' = 'EXECUTE_FUNCTION',
  'ARRAY_SORT' = 'ARRAY_SORT',
  'SQS_FIRE_EVENTS' = 'SQS_FIRE_EVENTS',
  'SES_SEND_EMAIL' = 'SES_SEND_EMAIL',
  'SNS_SEND_SMS' = 'SNS_SEND_SMS',
  'SNS_SEND_PUSH_NOTIFICATION' = 'SNS_SEND_PUSH_NOTIFICATION',
  'ARRAY_EXTEND' = 'ARRAY_EXTEND',
  'DELETE_STORAGE_OBJECTS' = 'DELETE_STORAGE_OBJECTS',
  'HASH' = 'HASH',
  'BASE64_ENCODE' = 'BASE64_ENCODE',
  'BASE64_DECODE' = 'BASE64_DECODE',
  'STRING_TO_OBJECT' = 'STRING_TO_OBJECT',
}

export const businessNodeEnumMapping: Record<BusinessNodeEnum, string> = {
  [BusinessNodeEnum.ROOT_NODE]: 'Root node',
  [BusinessNodeEnum.ADD_CASE_NODE]: 'Add case node',
  [BusinessNodeEnum.HTTP_TRIGGER]: 'HTTP trigger',
  [BusinessNodeEnum.HTTP_RESPONSE]: 'HTTP response',
  [BusinessNodeEnum.FUNCTION_RESPONSE]: 'SubFlow response',
  [BusinessNodeEnum.ACTION]: 'Action',
  [BusinessNodeEnum.DB]: 'DB',
  [BusinessNodeEnum.DEBUG]: 'Debug',
  [BusinessNodeEnum.START]: 'Start',
  [BusinessNodeEnum.STOP]: 'Stop',
  [BusinessNodeEnum.CUSTOM_QUERY_RECORDS]: 'Run Aggregation Pipeline',
  [BusinessNodeEnum.DELETE_MULTIPLE_RECORDS]: 'Delete Many',
  [BusinessNodeEnum.DELETE_RECORD]: 'Delete One',
  [BusinessNodeEnum.FETCH_RECORD]: 'Fetch by ID',
  [BusinessNodeEnum.FIND_MANY]: 'Find many',
  [BusinessNodeEnum.FIND_ONE]: 'Find one',
  [BusinessNodeEnum.INSERT_MULTIPLE_RECORDS]: 'Insert Many',
  [BusinessNodeEnum.INSERT_RECORD]: 'Insert One',
  [BusinessNodeEnum.LIST_RECORDS]: 'List records',
  [BusinessNodeEnum.UPDATE_MULTIPLE_RECORDS]: 'Update Many',
  [BusinessNodeEnum.UPDATE_ONE_RECORD]: 'Update One',
  [BusinessNodeEnum.UPDATE_RECORD]: 'Update by ID',
  [BusinessNodeEnum.SET_VARIABLE]: 'Set variable',
  [BusinessNodeEnum.BUILD_JSON]: 'Build JSON',
  [BusinessNodeEnum.BUILD_MAP]: 'Build MAP',
  [BusinessNodeEnum.SWITCH_CASE]: 'Switch case',
  [BusinessNodeEnum.IF_ELSE]: 'If else condition',
  [BusinessNodeEnum.EXECUTOR_NODE]: 'Executor node',
  [BusinessNodeEnum.ADD_NODE]: 'Add node',
  [BusinessNodeEnum.ADD_VARIABLE]: 'Add to variable',
  [BusinessNodeEnum.APPEND_TO_STRING]: 'Append to string',
  [BusinessNodeEnum.APPEND_TO_ARRAY]: 'Append to array',
  [BusinessNodeEnum.COMPLEX_MATHS_EXPR]: 'Complex maths expression',
  [BusinessNodeEnum.CONCAT_STRINGS]: 'Concatenate strings',
  [BusinessNodeEnum.CONTAINS]: 'Contains',
  [BusinessNodeEnum.DECREMENT_VARIABLE]: 'Decrement variable',
  [BusinessNodeEnum.DIVIDE_VARIABLE]: 'Divide variable',
  [BusinessNodeEnum.EMPTY_ARRAY]: 'Empty array',
  [BusinessNodeEnum.FOR_LOOP]: 'For loop',
  [BusinessNodeEnum.INCREMENT_VARIABLE]: 'Increment variable',
  [BusinessNodeEnum.LENGTH_ARRAY]: 'Length of array',
  [BusinessNodeEnum.MERGE_JSON]: 'Merge JSON',
  [BusinessNodeEnum.MULTIPLY_VARIABLE]: 'Multiply variable',
  [BusinessNodeEnum.REVERSE_ARRAY]: 'Reverse array',
  [BusinessNodeEnum.SLICE_STRING]: 'Slice string',
  [BusinessNodeEnum.SUBTRACT_VARIABLE]: 'Subtract variable',
  [BusinessNodeEnum.TO_LOWER]: 'Convert to lowercase',
  [BusinessNodeEnum.TO_UPPER]: 'Convert to uppercase',
  [BusinessNodeEnum.TRIM_STRING]: 'Trim string',
  [BusinessNodeEnum.IF_ELSE_V2]: '[v2] If else condition',
  [BusinessNodeEnum.SWITCH_CASE_V2]: '[v2] Switch case',
  [BusinessNodeEnum.WHILE_LOOP]: 'While loop',
  [BusinessNodeEnum.ARRAY_ITEM_AT]: 'Array item at',
  [BusinessNodeEnum.ARRAY_EXTEND]: 'Extend array',
  [BusinessNodeEnum.EXISTS_NODE]: 'Exists node',
  [BusinessNodeEnum.CURRENT_DATETIME]: 'Current datetime',
  [BusinessNodeEnum.API_CALL]: 'API call',
  [BusinessNodeEnum.GET_PRESIGNED_URL]: 'Get presigned URL',
  [BusinessNodeEnum.POST_PRESIGNED_URL]: 'Post presigned URL',
  [BusinessNodeEnum.STRING_OPERATIONS]: 'String operations',
  [BusinessNodeEnum.SPLIT_STRING]: 'Split string',
  [BusinessNodeEnum.OBJECT_TO_STRING]: 'Object to string',
  [BusinessNodeEnum.LENGTH_STRING]: 'Length of string',
  [BusinessNodeEnum.CONVERT_TO_STRING]: 'Convert to string',
  [BusinessNodeEnum.EXECUTE_FUNCTION]: 'Execute SubFlow',
  [BusinessNodeEnum.ARRAY_SORT]: 'Sort array',
  [BusinessNodeEnum.SQS_FIRE_EVENTS]: 'Fire events (SQS)',
  [BusinessNodeEnum.SES_SEND_EMAIL]: 'Send EMAIL (SES)',
  [BusinessNodeEnum.SNS_SEND_SMS]: 'Send SMS (SNS)',
  [BusinessNodeEnum.SNS_SEND_PUSH_NOTIFICATION]: 'Send Push Notification (SNS)',
  [BusinessNodeEnum.DELETE_STORAGE_OBJECTS]: 'Delete storage objects',
  [BusinessNodeEnum.HASH]: 'Hash',
  [BusinessNodeEnum.BASE64_ENCODE]: 'Base64 Encode',
  [BusinessNodeEnum.BASE64_DECODE]: 'Base64 Decode',
  [BusinessNodeEnum.STRING_TO_OBJECT]: 'String to Object',
  [BusinessNodeEnum.VALIDATE_MODEL]: 'Validate Model',
};

export type Schema = Array<{
  displayName: string;
  fields: Array<{
    key: string;
    displayName: string;
    immutable: boolean;
    values?: string[];
    resolver?: string;
    type: FieldTypeEnum;
  }>;
}>;

export interface BusinessNodeProps {
  type: BusinessNodeEnum;
  displayName: string;
  schema: Schema;
  values: Values;
  nodeName: string;
  metadata: any;
  returns: Array<{
    key: string;
    displayName: string;
    type: 'OBJECT' | 'MODEL_ID' | 'STRING' | 'NUMBER' | 'URL_PARAMS';
  }>;
}

export type Values = Record<string, any>;

export interface NodeRaw {
  id: string;
  type: NodeTypeEnum;
  data: {
    displayName: string;
    schema?: Schema;
    type: BusinessNodeProps['type'];
    values?: BusinessNodeProps['values'];
    metadata?: any;
    nodeName?: string;
    category?: string;
    returns?: BusinessNodeProps['returns'];
  };
  parentId: string; // new addition to support embedded node feature
  parentNode?: string; //optional for backword compatibility with flowEngineV1
  extent?: 'parent' | CoordinateExtent; // optional
  position?: XYPosition;
}

export interface BackendNode {
  type: BackendNodeTypeEnum;
  data: {
    displayName: string;
    schema: Schema;
    type: BusinessNodeProps['type'];
    values?: BusinessNodeProps['values'];
    metadata?: any;
    nodeName: string;
    category: string;
  };
}

export type switchNodeMetadataType = {
  key: string;
  nodeId: string;
  edgeId: string;
};

export enum BackendNodeTypeEnum {
  RootNode = 'ROOT_NODE',
  AddCaseNode = 'ADD_CASE_NODE',
  AddNode = 'ADD_NODE',
  IfNode = 'IF_NODE',
  SwitchNode = 'SWITCH_NODE',
  GroupNode = 'GROUP_NODE',
  ConcurrentNode = 'CONCURRENT_NODE',
  LoopNode = 'LOOP_NODE',
  BaseNode = 'BASE_NODE',
  ExecutorNode = 'EXECUTOR_NODE',
}

export interface TabsGroup {
  [key: string]: any;
}
