import { Paper } from '@comet/blocks';
import { useGoBack, useLocation, useNavigate, useParams } from '@comet/router';
import { CreateModelForm } from './CreateModelForm';
import { queryClient } from '@comet/query';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';

export const CreateModal = () => {
  const { projectId, organisationId } = useParams();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const { pathname } = useLocation();

  const databaseModels = pathname.includes('dbModels');

  const { hasEditModelAccess } = useProjectPermissions();

  if (!hasEditModelAccess) {
    return <ErrorPage />;
  }

  const onSuccess = (id: string) => {
    queryClient.invalidateQueries({
      queryKey: ['useDeploymentsQuery', projectId],
    });
    queryClient.invalidateQueries({
      queryKey: ['useGetProjectQuery', organisationId, projectId],
    });
    navigate(
      `organisations.organisationId.projects.projectId.${
        databaseModels ? 'dbModels' : 'models'
      }.modelId.details`,
      { projectId, organisationId, modelId: id }
    );
  };

  return (
    <Paper size="medium">
      <CreateModelForm
        onCancel={goBack}
        onSuccess={onSuccess}
        projectId={projectId}
        databaseModels={databaseModels}
      />
    </Paper>
  );
};
