import { Modal, Select, Form, FormItem } from '@comet/blocks';
import { useGetEnvironmentsQuery } from '../../Environments';
import { useParams } from '@comet/router';
import { formatReleaseOptionData } from '../../Environments/utils';
import { BiChevronDown } from 'react-icons/bi';
import { usePromoteEnvironment } from '../Deployments.service';

interface Props {
  deployedEnvironmentIds: string[];
  version: string;
  isConfirm: boolean;
  setIsConfirm: (isConfirm: boolean) => void;
  onComplete: () => void;
}

export const PromoteModal = ({
  deployedEnvironmentIds,
  version,
  onComplete,
  isConfirm,
  setIsConfirm,
}: Props) => {
  const [form] = Form.useForm();

  const { projectId } = useParams();

  const { data } = useGetEnvironmentsQuery(projectId);
  const releaseOption = formatReleaseOptionData(data?.data);

  const { mutateAsync } = usePromoteEnvironment();

  const handleFinish = async (values: { environmentId: string }) => {
    await mutateAsync({ environmentId: values.environmentId, version });
    setIsConfirm(false);
    onComplete();
  };

  return (
    <>
      <Modal
        open={isConfirm}
        title={'Promote to Environment'}
        onCancel={() => setIsConfirm(false)}
        onOk={() => handleFinish(form.getFieldsValue())}
        okButtonProps={{ htmlType: 'submit' }}
        okText={'Update'}
      >
        <Form
          autoComplete="off"
          onFinish={handleFinish}
          layout="vertical"
          form={form}
        >
          <FormItem
            name="environmentId"
            label="Environment"
            required
            style={{ marginTop: 20 }}
            rules={[{ required: true, message: 'Environments is required!' }]}
          >
            <Select
              options={releaseOption.data}
              placeholder="Select environments"
              style={{ width: '200px' }}
              suffixIcon={<BiChevronDown />}
              showArrow
              allowClear
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};
