import { Result, Empty } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Spin, Tabs, Flex, Tooltip, FullCenter } from '@comet/blocks';

import {
  StyledNodeCard,
  StyledNodeName,
  ScrollbarHiddenFlex,
} from './NodeExplorer.styled';

import debounce from 'lodash/debounce';
// import { useGetNodes } from './services';
import { queryClient } from '@comet/query';
import { useParams, useSearchParams } from '@comet/router';
import { NodeRaw, TabsGroup } from '../../types';
import { useNodeExplorerStore } from '../../store';
import { getNodeDisplayData } from 'src/FlowEngine2/nodes/utils';
import { NodeExplorerSearch, toSentenceCase } from './NodeExplorer.util';
import {
  useGetFlowQuery,
  useGetNodes,
} from 'src/FlowEngine2/Components/NodeExplorer/services';
import { useGetProjectQuery } from '@comet/pages/Project/service';

export function NodeOptions() {
  // These states are for storing/handling meta data from api call

  const { organisationId, projectId, apiId, subflowId } = useParams();
  const flowId = apiId || subflowId;

  const [searchParams] = useSearchParams();
  const [version] = useState(searchParams.get('version') || null);
  const [draft] = useState(searchParams.get('draft') === 'true' || false);

  const { data: flowData } = useGetFlowQuery(projectId, flowId, {
    version: version === 'null' ? null : version,
    draft,
  });

  const { data: projectData } = useGetProjectQuery(organisationId, projectId);

  const { data, error, status } = useGetNodes(
    projectData?.deployment.cloudProvider,
    flowData?.flowType
  );

  // This state is to keep filtered result as per user input in search field
  const [filteredBlocks, setFilteredBlocks] = React.useState<NodeRaw[]>([]);
  const [categoryNodes, setCategoryNodes] = React.useState<TabsGroup>({});
  const [selectedTabKey, setSelectedTabKey] = React.useState<string>('ALL');
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [tabItems, setTabItems] = React.useState<
    { key: string; label: string }[]
  >([]);

  //tabs items
  const categories: TabsGroup = {};
  const tabOptions: { key: string; label: string }[] = [
    { label: 'All', key: 'ALL' },
  ];

  const setSelectedBusinessNode = useNodeExplorerStore(
    (s) => s.setSelectedBusinessNode
  );

  // This state is internal to this component only.
  // It is needed to maintain the knowledge of currently selected node
  // needed in order to make border around selected node
  const [nodeName, setNodeName] = useState('');

  // Whenever data from api will change filtered blocks will change
  useEffect(() => {
    if (!data) return;
    setFilteredBlocks(data);

    //create node-category map
    data.forEach((node) => {
      if (node.data.category) {
        if (!categories[node.data.category]) {
          categories[node.data.category] = [];
        }
        categories[node.data.category].push(node);
      }
    });
    setCategoryNodes(categories);

    //create tab options
    Object.keys(categories).forEach((category) => {
      tabOptions.push({
        label: toSentenceCase(category),
        key: category,
      });
    });
    setTabItems(tabOptions);
  }, [data]);

  const onTabChange = (key: string) => {
    if (!data) return;
    if (key !== 'ALL') {
      setFilteredBlocks(categoryNodes[key]);
    } else {
      setFilteredBlocks(data);
    }
    setSelectedTabKey(key);
  };

  useEffect(() => {
    handleSearch();
  }, [searchValue, selectedTabKey]);

  // This is debounced filter fn , will get called after 300ms delay
  const debouncedFilter = useCallback(
    debounce((value: NodeRaw[]) => {
      setFilteredBlocks(value);
    }, 300),
    []
  );

  // handle when any block/node is selected
  function handleClick(node: NodeRaw) {
    setNodeName(node.data.displayName);
    setSelectedBusinessNode(node);
  }

  // function onChane search text
  function handleOnChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  // handle when user changes search value in field
  function handleSearch() {
    if (!data) return;

    //check if nodes are already filter based on tab selection
    const filterFrom =
      selectedTabKey === 'ALL' ? data : categoryNodes[selectedTabKey];

    //filter nodes based on search string
    const filteredRes = filterFrom.filter(
      ({ data: { displayName } }: NodeRaw) =>
        displayName.toLowerCase().includes(searchValue.toLowerCase())
    );
    debouncedFilter(filteredRes);
  }

  // Handles when node data is loading
  if (status === 'loading') {
    return <Spin />;
  }

  // Handles if in case api calls result in an error
  if (status === 'error') {
    return (
      <Result
        status="500"
        title="500"
        subTitle={`Sorry, something went wrong. ${error}`}
        extra={
          <Button
            type="primary"
            onClick={() => queryClient.refetchQueries(['nodes'])}
          >
            Back Home
          </Button>
        }
      />
    );
  }

  // Handles when data is loaded
  return (
    <Flex direction="column">
      <NodeExplorerSearch handleSearch={handleOnChangeSearch} />
      <Tabs
        defaultActiveKey="ALL"
        items={tabItems}
        onChange={onTabChange}
        style={{ marginTop: 12 }}
        size="small"
      />
      <Flex
        direction="column"
        style={{
          width: '100%',
          height: '388px',
          background: '#fafafa',
          border: '1px solid #D9D9D9',
          borderRadius: '4px',
        }}
      >
        {/* empty state  */}
        {filteredBlocks.length === 0 && (
          <FullCenter>
            <Empty />
          </FullCenter>
        )}
        {/* show results  */}
        {searchValue !== '' && filteredBlocks.length > 0 && (
          <Flex
            style={{
              paddingTop: 12,
              paddingLeft: 14,
              fontWeight: 400,
              fontSize: 12,
            }}
          >
            Showing {filteredBlocks.length} results
          </Flex>
        )}
        {/* nodes list  */}

        <ScrollbarHiddenFlex gap={12}>
          {filteredBlocks?.map((node) => {
            const { Icon } = getNodeDisplayData(node.data.type);
            const isSelected = nodeName === node.data.displayName;
            return (
              <StyledNodeCard
                onClick={(event: any) => {
                  event.preventDefault();
                  handleClick(node);
                  event.stopPropagation();
                }}
                isSelected={isSelected}
                justifyContent="center"
                alignItems="center"
                direction="column"
              >
                <Icon
                  color={isSelected ? '#6C3DD1' : '#000000'}
                  opacity={isSelected ? '100%' : '45%'}
                />
                <StyledNodeName>
                  <Tooltip title={node.data.displayName}>
                    {node.data.displayName}
                  </Tooltip>
                </StyledNodeName>
              </StyledNodeCard>
            );
          })}
        </ScrollbarHiddenFlex>
      </Flex>
    </Flex>
  );
}
