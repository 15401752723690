import { Edge } from 'reactflow';
import { ExtendedNode } from '../Types';
import FlowEngine from '@comet/components/FlowEngine';
import FlowEngineV2 from 'src/FlowEngine2/main';

interface ApiFlowProps {
  nodes: ExtendedNode[];
  edges: Edge[];
  hasEditAccess: boolean;
  flowId: string;
  rootNode: string;
}

export const APIDetailsFlow = (props: ApiFlowProps) => {
  return <FlowEngineV2 {...props} />;
};
