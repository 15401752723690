import {
  SidebarMenuItemKey,
  SidebarMenuItemGroupWithoutKey,
  SidebarMenuItemGroup,
} from './types';

export const generateKeys = (
  parentKey: string,
  menuItems?: SidebarMenuItemGroupWithoutKey[]
): SidebarMenuItemKey[] => {
  const keys: SidebarMenuItemKey[] = [];
  if (!menuItems) return keys;

  menuItems.forEach((menuItem, idx) => {
    const currentKey = parentKey.concat(String(idx));
    let childrenKeys: SidebarMenuItemKey[] = [];

    if (menuItem.children) {
      childrenKeys = generateKeys(
        currentKey.concat('.children.'),
        menuItem.children
      );
    }

    keys.push({
      key: currentKey,
      childrenKeys,
    });
  });

  return keys;
};

export const attachKeys = (
  menuItems?: SidebarMenuItemGroupWithoutKey[],
  keys?: SidebarMenuItemKey[]
): SidebarMenuItemGroup[] => {
  const menuItemsWithKey: SidebarMenuItemGroup[] = [];

  if (!menuItems || !keys) return menuItemsWithKey;

  menuItems.forEach((menuItem, idx) => {
    let updatedMenuItem: SidebarMenuItemGroup;
    let childrenWithKey = [];

    if (menuItem.children) {
      childrenWithKey = attachKeys(menuItem.children, keys[idx].childrenKeys);
      updatedMenuItem = {
        ...menuItem,
        children: childrenWithKey,
        key: keys[idx].key,
      };
    } else {
      updatedMenuItem = {
        ...menuItems[idx],
        key: keys[idx].key,
        children: [],
      };
      delete updatedMenuItem.children;
    }

    menuItemsWithKey.push(updatedMenuItem);
  });

  return menuItemsWithKey;
};
