import {
  Button,
  Center,
  CustomSelect,
  Flex,
  FullCenter,
  Search,
  Show,
  Spin,
  Table,
  Text,
  usePagination,
} from '@comet/blocks';
import { Empty, Result, Tooltip } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { useGetProjectsQuery } from '../service';
import { useNavigate, useParams } from '@comet/router';
import { TableContainer } from '../Project.styled';
import debounce from 'lodash.debounce';
import {
  CloudProviders,
  PAGE_SIZE,
  SortByOptions,
} from './ProjectList.constants';
import _ from 'lodash';
import { useProjectListingData } from '../utils';
import { useOrgMembersOptions } from '@comet/pages/Organisation/utils';
import EmptyImage from '@comet/assets/png/empty_list.png';
import {
  useGetOrgSubscriptionQuery,
  useOrgQuery,
} from '@comet/pages/Organisation/service';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';

export const ProjectList = () => {
  const { organisationId } = useParams();
  const navigate = useNavigate();

  const { hasViewProjectAccess, hasEditProjectAccess } = useOrgPermissions();

  const initialValues = {
    offset: 0,
    query: '',
    cloud_provider: CloudProviders[0].value,
    created_by: '',
    sort_by: SortByOptions[0].value,
  };

  const { pageOffset, currentPageIndex, fetchData } = usePagination();
  const [sortBy, setSortBy] = useState(initialValues.sort_by);
  const [updatedBy, setUpdatedBy] = useState(initialValues.created_by);
  const [cloudProvider, setCloudProvider] = useState(
    initialValues.cloud_provider
  );
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState(initialValues.query);

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const currentValues = {
    offset: pageOffset,
    query,
    cloud_provider: cloudProvider,
    created_by: updatedBy,
    sort_by: sortBy,
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const {
    data: projectsData,
    isLoading,
    error,
    refetch,
  } = useGetProjectsQuery(organisationId, {
    offset: pageOffset,
    query,
    cloud_provider: cloudProvider || null,
    created_by: updatedBy || null,
    sort_by: sortBy,
  });

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);

  const orgMembersOptions = useOrgMembersOptions(organisationId);

  const { tableColumns, tableData } = useProjectListingData(projectsData);

  const isCreateProjectDisabled =
    (orgSubscriptionData?.resourceLimits?.maxProjects || 0) <=
    (projectsData?.page.total || 0);

  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !projectsData;
  const showList = !isLoading && !isError && projectsData.data.length > 0;

  useEffect(() => {
    if (projectsData?.data.length === 0 && !isFilterChanged) {
      navigate('organisations.organisationId.projects.new');
    }
  }, [isFilterChanged, navigate, projectsData?.data.length]);

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!hasViewProjectAccess) {
    return <ErrorPage />;
  }

  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={4} alignItems="center">
          <Text appearance="heading.card">Projects</Text>
        </Flex>
        <Tooltip
          title={isCreateProjectDisabled ? 'Update plan to create more' : ''}
        >
          <Button
            type="primary"
            onClick={() =>
              navigate('organisations.organisationId.projects.new', {
                organisationId,
              })
            }
            disabled={isCreateProjectDisabled || !hasEditProjectAccess}
          >
            Create Project
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Flex alignItems="center" padding={10} gap={10}>
          <Flex>
            <Search
              placeholder="Search by name"
              value={searchValue}
              onChange={onSearch}
            />
          </Flex>
          <CustomSelect
            label="Cloud provider"
            options={CloudProviders}
            value={cloudProvider}
            onChange={setCloudProvider}
          />
          <CustomSelect
            label="Created by"
            showSearch
            options={orgMembersOptions}
            value={updatedBy}
            onChange={setUpdatedBy}
          />
          <CustomSelect
            label="Sort by"
            options={SortByOptions}
            value={sortBy}
            onChange={setSortBy}
          />
        </Flex>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && projectsData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Projects found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="projects"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil(
              (projectsData?.page.total || 0) / PAGE_SIZE
            )}
            totalRows={projectsData?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
