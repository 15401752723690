import { Text, Typography } from '@comet/blocks';
import { styled } from '@comet/styled';

export const AuthPageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    grid-template-columns: 1fr 1fr;
  }`}
`;

export const AuthSection = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

export const FormBox = styled.div`
  width: 80%;
  height: 100%;
  margin-top: 20px;
  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    margin-top: 80px;
    width: 368px;
  }`}
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 40px;
  background-color: ${({ theme }) => theme.palette.neutral.lightest};
  height: 60px;

  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
      display: none;
  }`}

  & > svg {
    width: 100px;
    height: 100%;
  }
`;

export const LinkText = styled(Text)`
  appearance: 'caption.semibold';
  color: ${({ theme }) => theme.palette.primary};
  display: inline-block;
  margin-bottom: 0 !important;
`;

export const StyledText = styled(Typography.Text)`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

export const ToS = () => {
  return (
    <StyledText style={{ marginBottom: 20, display: 'inline-block' }}>
      By creating an account, you confirm to adhere to Cosmocloud’s{' '}
      <a
        href="https://cosmocloud.io/terms-of-service"
        target="_blank"
        rel="noreferrer"
      >
        <LinkText appearance="caption.semibold">Terms of Service</LinkText>
      </a>{' '}
      and{' '}
      <a
        href="https://cosmocloud.io/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        <LinkText appearance="caption.semibold">Privacy Policy</LinkText>
      </a>
      .
    </StyledText>
  );
};
