import {
  ActionModalPropsType,
  ActionModalTypesEnum,
} from '../../Components/ActionModal/types';
import {
  BackendNodeTypeEnum,
  BusinessNodeEnum,
  NodeRaw,
  NodeTypeEnum,
} from '../../types';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  XYPosition,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
} from 'reactflow';
import { NODE_HEIGHT, NODE_WIDTH } from 'src/FlowEngine2/constant';
import { RFStateType, useActionModalStore } from '..';
import React, { PropsWithChildren } from 'react';
import {
  addBaseNode,
  addIfElseNode,
  addIfElseV2Node,
  addLoopNode,
  addSwitchNode,
  addSwitchV2Node,
} from '../../Operations/AddOperations';
import {
  clickAddCaseNode,
  clickAddNode,
  clickExecutorNode,
  clickLoopNode,
  clickRootNode,
} from '../../Operations/clickOperatons';
import {
  createStateValueData,
  getCurrentRoot,
  getEdgeById,
  getEdgeId,
  getNodeById,
  getReactFlowEdge,
  getdeleteNodeFunction,
  removeEdgesOfNodes,
} from './utils';

import { create } from 'zustand';

// this is our useStore hook that we can use in our components to get parts of the store and call actions
export const useFlowEngineStore = create<RFStateType>((set, get) => ({
  // Graph
  allNodes: [],
  allEdges: [],
  rootStack: ['root'],
  nodes: [],
  edges: [],
  rootNode: '1',
  latestId: 0,
  latestEdgeId: 0,
  allNodesMap: new Map(),
  allEdgesMap: new Map(),
  edgeIdMap: new Map(),
  nodeStateValueObjMap: new Map(),

  resetNodesAndEdges: () => {
    set({ allNodes: [], allEdges: [], nodes: [], edges: [], rootNode: null });
  },

  setMainRootNode: (rootNode: string) => {
    set({ rootNode: rootNode });
  },

  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },

  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });
  },

  initialize: (nodes: NodeRaw[], edges: Edge[], rootNode: string) => {
    let latestEdgeId = 0;
    let latestNodeId = 0;

    get().allNodesMap = new Map();
    // Setting default values to cleanup node and egse structures, if not present from backend.
    nodes.forEach((node) => {
      if (!node.data.metadata) {
        node.data.metadata = {};
      }
      get().allNodesMap.set(node.id, node);
      latestNodeId = Math.max(parseInt(node.id), latestNodeId);
    });

    get().allEdgesMap = new Map();
    edges.forEach((edge) => {
      const edgeSearchKey = `${edge.source}-${edge.target}`;
      get().allEdgesMap.set(edge.id, edge);
      get().edgeIdMap.set(edgeSearchKey, edge.id);
      latestEdgeId = Math.max(parseInt(edge.id), latestEdgeId);
    });

    createStateValueData();

    // Initializing allNodes and allEdges at Flow Load.
    set({
      latestId: latestNodeId,
      latestEdgeId,
      allNodes: nodes,
      allEdges: edges,
      rootNode: rootNode,
    });

    get().switchToStackTop();
  },

  setNodes: (updatedNodes: Node[] | null) => {
    if (!updatedNodes) return;
    set({ nodes: updatedNodes });
  },

  setEdges: (updatedEdges: Edge[] | null) => {
    if (!updatedEdges) return;
    set({ edges: updatedEdges });
  },

  setNodesAndEdges: (
    updatedNodes: Node[] | null,
    updatedEdges: Edge[] | null
  ) => {
    if (!updatedNodes || !updatedEdges) return;
    set({ nodes: updatedNodes, edges: updatedEdges });
  },

  clickNode: (nodeId: string | null) => {
    if (!nodeId) return null;
    const node = getNodeById(nodeId);

    switch (node!.type) {
      case NodeTypeEnum.RootNode: {
        clickRootNode(nodeId);
        break;
      }

      case NodeTypeEnum.AddNode: {
        /* Open NodeExplorer */
        clickAddNode(nodeId);
        break;
      }

      case NodeTypeEnum.AddCaseNode: {
        clickAddCaseNode(nodeId);
        break;
      }

      case NodeTypeEnum.ExecutorNode: {
        clickExecutorNode(nodeId);
        break;
      }

      case NodeTypeEnum.LoopNode: {
        clickLoopNode(nodeId);
        break;
      }

      default: {
        break;
      }
    }
  },

  createNode: (
    nodeToAdd:
      | NodeRaw
      | null
      | 'ROOT_NODE'
      | 'ADD_NODE'
      | 'EXECUTOR_NODE'
      | 'ADD_CASE_NODE',
    other: object | undefined = {} // for metadata
  ) => {
    if (!nodeToAdd) return null;
    let id = (get().latestId + 1).toString();

    let node: NodeRaw;
    const position: XYPosition = { x: 0, y: 0 };

    if (nodeToAdd === 'ROOT_NODE') {
      node = {
        id,
        type: NodeTypeEnum.RootNode,
        data: {
          displayName: 'Root node',
          nodeName: `node_${id}`,
          values: {},
          type: BusinessNodeEnum.ROOT_NODE,
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else if (nodeToAdd === 'ADD_NODE') {
      node = {
        id,
        type: NodeTypeEnum.AddNode,
        data: {
          displayName: 'Add node',
          nodeName: `node_${id}`,
          values: {},
          metadata: {},
          type: BusinessNodeEnum.ADD_NODE,
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else if (nodeToAdd === 'ADD_CASE_NODE') {
      node = {
        id,
        type: NodeTypeEnum.AddCaseNode,
        data: {
          displayName: 'Add Case +',
          nodeName: `node_${id}`,
          metadata: {
            switchNodeId:
              other && 'switchNodeId' in other && other.switchNodeId,
            // when node to add is add_case , also give switch node id
          },
          values: {},
          type: BusinessNodeEnum.ADD_CASE_NODE,
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else if (nodeToAdd === 'EXECUTOR_NODE') {
      const rootNodeId = get().createNode('ROOT_NODE');
      id = (get().latestId + 1).toString();
      getNodeById(rootNodeId)!.parentId = id;

      node = {
        id,
        type: NodeTypeEnum.ExecutorNode,
        data: {
          type: BusinessNodeEnum.EXECUTOR_NODE,
          displayName: 'Executor',
          metadata: {
            rootNode: rootNodeId, // This node is the first node of the flow inside executor node
          },
          values: {},
          nodeName: `node_${id}`,
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else if (nodeToAdd.type === NodeTypeEnum.LoopNode) {
      const rootNodeId = get().createNode('ROOT_NODE');
      id = (get().latestId + 1).toString();
      getNodeById(rootNodeId)!.parentId = id;

      node = {
        id,
        type: NodeTypeEnum.LoopNode,
        data: {
          ...nodeToAdd.data,
          metadata: {
            rootNode: rootNodeId, // This node is the first node of the flow inside executor node
          },
          values: {},
          nodeName: `node_${id}`,
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else if (
      nodeToAdd.type === NodeTypeEnum.IfElseV2 ||
      nodeToAdd.type === NodeTypeEnum.IfElse
    ) {
      node = {
        ...nodeToAdd,
        id,
        data: {
          ...nodeToAdd.data,
          metadata: {
            ...other,
          },
          nodeName: `node_${id}`,
          values: {},
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else if (nodeToAdd.type === NodeTypeEnum.SwitchNode) {
      node = {
        ...nodeToAdd,
        id,
        data: {
          ...nodeToAdd.data,
          metadata: {
            cases: ('cases' in other && other.cases) || [],
          },
          nodeName: `node_${id}`,
          values: {},
        },
        parentId: getCurrentRoot(),
        position,
      };
    } else {
      node = {
        ...nodeToAdd,
        id,
        parentId: getCurrentRoot(),
        data: {
          ...nodeToAdd.data,
          metadata: {},
          nodeName: `node_${id}`,
          values: {},
        },
        position,
      };
    }

    const updatedAllNodesMap = new Map(get().allNodesMap).set(id, node);

    set({
      allNodes: [...get().allNodes, node],
      latestId: get().latestId + 1,
      allNodesMap: updatedAllNodesMap,
    });

    return id;
  },

  addNode: (type: 'EDGE' | 'ADD_NODE', id: string, node: NodeRaw) => {
    switch (node.type) {
      // User can only add nodes of following type from NodeExplorer

      case NodeTypeEnum.BaseNode: {
        addBaseNode(type, id, node);
        break;
      }

      case NodeTypeEnum.IfElse: {
        switch (type) {
          case 'ADD_NODE': {
            addIfElseNode(type, id, node);
            break;
          }
          case 'EDGE': {
            const { setProps, onClose } = useActionModalStore.getState();
            const props: PropsWithChildren<ActionModalPropsType> = {
              open: true,
              type: ActionModalTypesEnum.Question,
              title: 'Add If Node',
              canClose: false,
              children: React.createElement(
                'div',
                null,
                'Which branch you wanted to connect to bottom node?'
              ),
              primaryButtonText: 'True Branch',
              secondaryButtonText: 'False Branch',
              onClickPrimaryButton: () => {
                addIfElseNode(type, id, node, 'TRUE_BRANCH');
                onClose();
              },
              onClickSecondaryButton: () => {
                addIfElseNode(type, id, node, 'FALSE_BRANCH');
                onClose();
              },
              onClose: onClose,
            };
            setProps(props);

            break;
          }
        }
        break;
      }

      case NodeTypeEnum.IfElseV2: {
        addIfElseV2Node(type, id, node);
        break;
      }

      case NodeTypeEnum.LoopNode: {
        addLoopNode(type, id, node);
        break;
      }

      case NodeTypeEnum.SwitchNode: {
        if (node.data.type === 'SWITCH_CASE') {
          switch (type) {
            case 'ADD_NODE': {
              addSwitchNode(type, id, node);
              break;
            }
            case 'EDGE': {
              const { setProps, onClose } = useActionModalStore.getState();
              const props: PropsWithChildren<ActionModalPropsType> = {
                open: true,
                type: ActionModalTypesEnum.Question,
                title: 'Add Switch Node',
                canClose: false,
                children: React.createElement(
                  'div',
                  null,
                  'Which branch you wanted to connect to bottom node?'
                ),
                primaryButtonText: 'Default Branch',
                secondaryButtonText: 'New Branch',
                onClickPrimaryButton: () => {
                  addSwitchNode(type, id, node, 'Default');
                  onClose();
                },
                onClickSecondaryButton: () => {
                  addSwitchNode(type, id, node, 'Other');
                  onClose();
                },
                onClose: onClose,
              };
              setProps(props);

              break;
            }
          }
        } else if (node.data.type === 'SWITCH_CASE_V2') {
          switch (type) {
            case 'ADD_NODE': {
              addSwitchV2Node(type, id, node);
              break;
            }
            case 'EDGE': {
              addSwitchV2Node(type, id, node);
            }
          }
        }
        break;
      }
      default:
    }

    get().switchToStackTop();
  },

  addEdge: (sourceNodeId: string | null, targetNodeId: string | null) => {
    const newEdgeId = (get().latestEdgeId + 1).toString();
    const edgeSearchKey = `${sourceNodeId}-${targetNodeId}`;

    const newEdge: Edge = {
      id: newEdgeId,
      source: sourceNodeId!,
      target: targetNodeId!,
      type: 'custom',
    };

    // Managing case for if else and if else v2
    // True should be before False edge

    const sourceNode = getNodeById(sourceNodeId)!;

    let updatedAllEdges;

    if (
      sourceNode.type === NodeTypeEnum.IfElse &&
      sourceNode.data.metadata.true === targetNodeId
    ) {
      const falseNodeId = sourceNode.data.metadata.false;
      const falseEdgeIndex = get().allEdges.findIndex(
        (edge) => edge.id === getEdgeId(sourceNodeId, falseNodeId)
      );

      if (falseEdgeIndex >= 0) {
        updatedAllEdges = [
          ...get().allEdges.slice(0, falseEdgeIndex),
          newEdge,
          ...get().allEdges.slice(falseEdgeIndex),
        ];
      } else {
        updatedAllEdges = [...get().allEdges, newEdge];
      }
    } else if (
      sourceNode.type === NodeTypeEnum.IfElse &&
      sourceNode.data.metadata.false === targetNodeId
    ) {
      const trueNodeId = sourceNode.data.metadata.true;
      const trueEdgeIndex = get().allEdges.findIndex(
        (edge) => edge.id === getEdgeId(sourceNodeId, trueNodeId)
      );

      if (trueEdgeIndex >= 0 && trueEdgeIndex !== get().allEdges.length - 1) {
        updatedAllEdges = [
          ...get().allEdges.slice(0, trueEdgeIndex + 1),
          newEdge,
          ...get().allEdges.slice(trueEdgeIndex + 1),
        ];
      } else {
        updatedAllEdges = [...get().allEdges, newEdge];
      }
    } else {
      updatedAllEdges = [...get().allEdges, newEdge];
    }

    // Managing Map
    const updatedAllEdgesMap = new Map(get().allEdgesMap);
    const updatedEdgeIdMap = new Map(get().edgeIdMap);
    updatedAllEdgesMap.set(newEdgeId, newEdge);
    updatedEdgeIdMap.set(edgeSearchKey, newEdgeId);

    set({
      allEdges: updatedAllEdges,
      latestEdgeId: get().latestEdgeId + 1,
      allEdgesMap: updatedAllEdgesMap,
      edgeIdMap: updatedEdgeIdMap,
    });
  },

  removeEdge: (edgeId: string | null) => {
    if (!edgeId) return;
    const edge = getEdgeById(edgeId)!;
    const edgeSearchKey = `${edge.source}-${edge.target}`;

    const updatedAllEdgesMap = new Map(get().allEdgesMap);
    const updatedEdgeIdMap = new Map(get().edgeIdMap);

    updatedEdgeIdMap.delete(edgeSearchKey);
    updatedAllEdgesMap.delete(edgeId);

    const updatedAllEdges = get().allEdges.filter((edge) => edge.id !== edgeId);

    set({
      allEdges: updatedAllEdges,
      edgeIdMap: updatedEdgeIdMap,
      allEdgesMap: updatedAllEdgesMap,
    });
  },

  removeEdges: (edgeIds: string[] | null) => {
    if (!edgeIds) return;

    const updatedAllEdgesMap = new Map(get().allEdgesMap);
    const updatedEdgeIdMap = new Map(get().edgeIdMap);

    edgeIds.forEach((edgeId) => {
      const edge = getEdgeById(edgeId)!;
      const edgeSearchKey = `${edge.source}-${edge.target}`;
      updatedEdgeIdMap.delete(edgeSearchKey);
      updatedAllEdgesMap.delete(edgeId);
    });

    const updatedAllEdges = get().allEdges.filter(
      (edge) => !edgeIds.includes(edge.id)
    );

    set({
      allEdges: updatedAllEdges,
      edgeIdMap: updatedEdgeIdMap,
      allEdgesMap: updatedAllEdgesMap,
    });
  },

  removeNode: (nodeId: string | null) => {
    if (!nodeId) return;
    const updatedAllNodesMap = new Map(get().allNodesMap);
    updatedAllNodesMap.delete(nodeId);

    set({
      allNodes: get().allNodes.filter((node) => node.id !== nodeId),
      allNodesMap: updatedAllNodesMap,
    });

    removeEdgesOfNodes([nodeId]);
  },

  removeNodes: (nodeIds: string[] | null) => {
    if (!nodeIds) return;
    const updatedAllNodes = get().allNodes.filter(
      (node) => !nodeIds.includes(node.id)
    );
    const updatedAllNodesMap = new Map(get().allNodesMap);
    nodeIds.forEach((nodeId) => updatedAllNodesMap.delete(nodeId));
    set({ allNodes: updatedAllNodes, allNodesMap: updatedAllNodesMap });

    removeEdgesOfNodes(nodeIds);
  },

  // source-edge1-NODE_TO_DELETE-edge2-target
  onDeleteNode: (nodeId: string | null) => {
    if (!nodeId) return;
    const node = getNodeById(nodeId);
    if (!node) return;

    const deleteNode = getdeleteNodeFunction(node.type);
    if (!deleteNode) return;

    const { setProps, onClose } = useActionModalStore.getState();
    const props: PropsWithChildren<ActionModalPropsType> = {
      open: true,
      type: ActionModalTypesEnum.Warning,
      title: 'Deleting Node',
      canClose: true,
      children: React.createElement(
        'div',
        null,
        node.type === NodeTypeEnum.IfElse ||
          node.type === NodeTypeEnum.ExecutorNode
          ? 'Deleting this node will delete all branches and the corresponding flow below this node. Do you confirm?'
          : 'Do you really want to delete this node?'
      ),
      primaryButtonText: 'Yes, Delete',
      secondaryButtonText: 'No, Do not delete',
      onClickPrimaryButton: () => {
        deleteNode(nodeId);
        get().switchToStackTop();
        onClose();
      },
      onClickSecondaryButton: onClose,
      onClose: onClose,
    };

    setProps(props);
  },

  switchToStackTop: () => {
    const root = getCurrentRoot();
    const reactFlowNodes = get()
      .allNodes.filter((node) => node.parentId === root)
      .map((node) => ({
        id: node.id,
        position: { x: 0, y: 0 },
        type: node.type,
        data: {
          displayName: node.data.displayName,
          businessNodeName: node.data.type,
          nodeName: node.data.nodeName,
        },
        height: NODE_HEIGHT,
        width: NODE_WIDTH,
      }));

    const reactNodeSet = new Set(
      reactFlowNodes.map((reactFlowNode) => reactFlowNode.id)
    );

    const reactFlowEdges = get()
      .allEdges.filter(
        (edge) => reactNodeSet.has(edge.source) && reactNodeSet.has(edge.target)
      )
      .map((edge) => getReactFlowEdge(edge)!);

    set({ nodes: reactFlowNodes, edges: reactFlowEdges });
    createStateValueData();
  },

  goBackToPrevRootNode: () => {
    const currentRoot = getCurrentRoot();
    if (currentRoot === 'root') return;

    set({ rootStack: get().rootStack.slice(1) });
    get().switchToStackTop();
  },

  addToStackTop: (nodeId: string | null) => {
    if (!nodeId) return;
    set({ rootStack: [nodeId, ...get().rootStack] });
    get().switchToStackTop();
  },
}));
