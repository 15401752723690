type RoutesMeta = {
  path: string;
  type: 'DEVELOPMENT' | 'PRODUCTION';
};

const testRoutes = {
  flowEngineTest: {
    path: 'flow-engine-test',
    type: 'DEVELOPMENT',
  },
};

const prodRoutes = {
  '*': {
    path: '*',
    type: 'PRODUCTION',
  },
  home: {
    path: '/',
    type: 'PRODUCTION',
  },
  signIn: {
    path: '/sign-in',
    type: 'PRODUCTION',
  },
  signUp: {
    type: 'PRODUCTION',
    path: '/sign-up',
  },
  confirmUser: {
    type: 'PRODUCTION',
    path: '/confirm-user',
  },
  forgotPassword: {
    type: 'PRODUCTION',
    path: '/forgot-password',
  },
  organisations: {
    type: 'PRODUCTION',
    path: '/organisations',
  },
  welcome: {
    type: 'PRODUCTION',
    path: '/welcome',
  },
  'organisations.new': {
    type: 'PRODUCTION',
    path: '/organisations/new',
  },
  'organisations.organisationId': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId',
  },
  'organisations.organisationId.payments': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/payments',
  },
  'organisations.organisationId.database': {
    path: '/organisations/:organisationId/database',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.users': {
    path: '/organisations/:organisationId/users',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.settings': {
    path: '/organisations/:organisationId/settings',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.storage': {
    path: '/organisations/:organisationId/storage',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.storage.create': {
    path: '/organisations/:organisationId/storage/create',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.new': {
    path: '/organisations/:organisationId/projects/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects': {
    path: '/organisations/:organisationId/projects',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.dashboard': {
    path: '/organisations/:organisationId/projects/:projectId/dashboard',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.models.new': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/models/new',
  },
  'organisations.organisationId.projects.projectId.models': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/models',
  },
  'organisations.organisationId.projects.projectId.models.modelId.details': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/models/:modelId/details',
  },
  'organisations.organisationId.projects.projectId.models.modelId.schema': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/models/:modelId/schema',
  },
  'organisations.organisationId.projects.projectId.dbModels.new': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/dbModels/new',
  },
  'organisations.organisationId.projects.projectId.dbModels': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/dbModels',
  },
  'organisations.organisationId.projects.projectId.dbModels.modelId.details': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/dbModels/:modelId/details',
  },
  'organisations.organisationId.projects.projectId.dbModels.modelId.schema': {
    type: 'PRODUCTION',
    path: '/organisations/:organisationId/projects/:projectId/dbModels/:modelId/schema',
  },
  'organisations.organisationId.projects.projectId.api': {
    path: '/organisations/:organisationId/projects/:projectId/api',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.api.new': {
    path: '/organisations/:organisationId/projects/:projectId/api/new',
  },
  'organisations.organisationId.projects.projectId.api.templates': {
    path: '/organisations/:organisationId/projects/:projectId/api/templates',
  },
  'organisations.organisationId.projects.projectId.api.apiId.details': {
    path: '/organisations/:organisationId/projects/:projectId/api/:apiId/details',
  },
  'organisations.organisationId.projects.projectId.releases': {
    path: '/organisations/:organisationId/projects/:projectId/releases',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.logs': {
    path: '/organisations/:organisationId/projects/:projectId/logs',
    type: 'PRODUCTION',
  },
  // Configs Deprecated
  // 'organisations.organisationId.projects.projectId.config': {
  //   path: '/organisations/:organisationId/projects/:projectId/config',
  //   type: 'PRODUCTION',
  // },
  'organisations.organisationId.projects.projectId.api.flow': {
    path: '/organisations/:organisationId/projects/:projectId/api/:apiId/flow',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.users': {
    path: '/organisations/:organisationId/projects/:projectId/users',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.upgrade': {
    path: '/organisations/:organisationId/projects/:projectId/upgrade',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.settings': {
    path: '/organisations/:organisationId/projects/:projectId/settings',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.analyzers': {
    path: '/organisations/:organisationId/projects/:projectId/analyzers',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.analyzers.new': {
    path: '/organisations/:organisationId/projects/:projectId/analyzers/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.analyzers.analyzerId': {
    path: '/organisations/:organisationId/projects/:projectId/analyzers/:analyzerId',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.search-indexes': {
    path: '/organisations/:organisationId/projects/:projectId/search-indexes',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.search-indexes.new': {
    path: '/organisations/:organisationId/projects/:projectId/search-indexes/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.search-indexes.searchIndexId':
    {
      path: '/organisations/:organisationId/projects/:projectId/search-indexes/:searchIndexId',
      type: 'PRODUCTION',
    },
  'organisations.organisationId.projects.projectId.vector-search-indexes': {
    path: '/organisations/:organisationId/projects/:projectId/vector-search-indexes',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.vector-search-indexes.new': {
    path: '/organisations/:organisationId/projects/:projectId/vector-search-indexes/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.vector-search-indexes.vectorSearchIndexId':
    {
      path: '/organisations/:organisationId/projects/:projectId/vector-search-indexes/:vectorSearchIndexId',
      type: 'PRODUCTION',
    },
  'projects.projectId.docs.version': {
    path: '/projects/:projectId/docs/:version',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.environments': {
    path: '/organisations/:organisationId/projects/:projectId/environments',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.environments.new': {
    path: '/organisations/:organisationId/projects/:projectId/environments/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.environments.environmentId.details':
    {
      path: '/organisations/:organisationId/projects/:projectId/environments/:environmentId/details',
      type: 'PRODUCTION',
    },
  'organisations.organisationId.projects.projectId.secrets': {
    path: '/organisations/:organisationId/projects/:projectId/secrets',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.secrets.new': {
    path: '/organisations/:organisationId/projects/:projectId/secrets/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.secrets.secretId.details': {
    path: '/organisations/:organisationId/projects/:projectId/secrets/:secretId/details',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.storageAccounts': {
    path: '/organisations/:organisationId/projects/:projectId/storageAccounts',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.storageAccounts.new': {
    path: '/organisations/:organisationId/projects/:projectId/storageAccounts/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.storageAccounts.storageAccountId.details':
    {
      path: '/organisations/:organisationId/projects/:projectId/storageAccounts/:storageAccountId/details',
      type: 'PRODUCTION',
    },
  'organisations.organisationId.projects.projectId.billing.status': {
    path: '/organisations/:organisationId/projects/:projectId/billing/status',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.databases': {
    path: '/organisations/:organisationId/projects/:projectId/databases',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.databases.new': {
    path: '/organisations/:organisationId/projects/:projectId/databases/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.databases.instanceId': {
    path: '/organisations/:organisationId/projects/:projectId/databases/:instanceId',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.subflows': {
    path: '/organisations/:organisationId/projects/:projectId/subflows',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.subflows.new': {
    path: '/organisations/:organisationId/projects/:projectId/subflows/new',
    type: 'PRODUCTION',
  },
  'organisations.organisationId.projects.projectId.subflows.subflowId.details':
    {
      path: '/organisations/:organisationId/projects/:projectId/subflows/:subflowId/details',
      type: 'PRODUCTION',
    },
  '#': {
    path: '#',
    type: 'PRODUCTIOn',
  },
};

const routes = {
  ...testRoutes,
  ...prodRoutes,
};

export type RoutesIdType = keyof typeof routes;

export default routes as Record<RoutesIdType, RoutesMeta>;
