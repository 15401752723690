import React, { useState } from 'react';
import styled from 'styled-components';
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
import { Steps as AntDSteps } from 'antd';
import { Text } from '../Text/Text';
import { Show } from '../Show';
import { RoutesIdType } from 'src/service/router/routes';
import { useNavigate } from '@comet/router';
import { Flex } from '../Flex';

export { Steps as AntDSteps } from 'antd';

const StyledStepsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
`;

const StyledStep = styled.div<{
  width: number;
}>`
  position: relative;
  width: ${(props) => props.width};
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  cursor: pointer;
`;

const TextContainer = styled(Flex)`
  flex-direction: column;
  width: 90%;
  justify-content: flex-start;
`;

const Circle = styled.div<{
  active: boolean;
  finish: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: ${({ active, theme }) =>
    active ? theme.palette.primary : theme.palette.neutral.white};
  border: ${({ active, theme }) =>
    active ? 'none' : `1px solid ${theme.palette.neutral.light}`};
  color: ${({ active, theme }) =>
    active ? theme.palette.neutral.white : theme.palette.accent.yellow.dark};
  ${({ finish, theme }) =>
    finish ? `background-color: ${theme.palette.success.default}` : ''};
  ${({ finish, theme }) =>
    finish ? `color: ${theme.palette.neutral.white}` : ''};
  border-radius: 28px;
  z-index: 2;
`;

const Line = styled.div`
  width: 2px;
  flex-grow: 1;
  height: 60%;
  grid-row: 1 / span 2;
  grid-column: 1/2;
  justify-self: center;
  align-self: center;
  background-color: ${({ theme }) => theme.palette.neutral.lighter};
`;

const TitleContainer = styled.h3<{
  active: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DescriptionContainer = styled.p<{
  active: boolean;
}>`
  color: ${(props) => props.theme.palette.accent.yellow.dark};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
`;

const NextIcon = styled(RightOutlined)`
  grid-column: 15 / 16;
  grid-row: 1 / span 2;
  align-self: center;
  color: ${(props) => props.theme.palette.accent.yellow.dark};
  cursor: pointer;
`;

const CircleWrapper = styled(Flex)`
  flex-direction: column;
  min-height: 4rem;
`;

export interface Step {
  title: string;
  description?: string;
  finish?: boolean;
  active?: boolean;
  route?: RoutesIdType;
  redirectUrl?: string;
}

interface IProps {
  steps: Step[];
  activeIndex?: number;
  showDescription: boolean;
}

export const VerticalSteps = ({
  activeIndex,
  steps,
  showDescription,
}: IProps) => {
  const navigate = useNavigate();

  const onClick = (step: Step) => {
    if (step.route) {
      navigate(step.route);
    } else {
      window.open(step.redirectUrl, '_blank');
    }
  };

  return (
    <StyledStepsContainer>
      {steps.map((step: Step, index: number) => {
        const active = activeIndex === index;

        const onStepClick = () => {
          onClick(step);
        };

        return (
          <StyledStep
            key={index}
            width={100 / steps.length}
            onClick={onStepClick}
          >
            <CircleWrapper>
              <Circle finish={!!step.finish} active={active}>
                <Show if={!!step.finish}>
                  <CheckOutlined style={{ height: 12, width: 12 }} />
                </Show>
                <Show if={!step.finish && active}>
                  <Text appearance="caption.semibold" color="neutral.white">
                    {index + 1}
                  </Text>
                </Show>
                <Show if={!step.finish && !active}>
                  <Text appearance="caption.semibold" color="neutral.darkest">
                    {index + 1}
                  </Text>
                </Show>
              </Circle>
              {index !== steps.length - 1 && <Line />}
            </CircleWrapper>
            <TextContainer>
              <TitleContainer active={active}>
                <Text
                  appearance="body.semiBold"
                  color={active ? 'neutral.darkest' : 'neutral.darker'}
                >
                  {step.title}
                </Text>
              </TitleContainer>
              <Show if={showDescription}>
                <DescriptionContainer active={active}>
                  <Text
                    appearance="body.regular"
                    color={active ? 'neutral.darkest' : 'neutral.darker'}
                  >
                    {step.description}
                  </Text>

                  <NextIcon />
                </DescriptionContainer>
              </Show>
            </TextContainer>
          </StyledStep>
        );
      })}
    </StyledStepsContainer>
  );
};

// When using StyledSteps, put prefixCls = 'step'
export const StyledSteps = styled(AntDSteps)`
  .step-item-process .step-item-icon {
    background-color: #d0e6fb !important;
  }
  .step-item-process .step-item-icon > span {
    color: ${({ theme }) => theme.palette.neutral.darkest} !important;
  }
  .step-item-TitleContainer::after {
    background-color: ${({ theme }) => theme.palette.neutral.light} !important;
  }
  .step-item-finish .step-item-icon {
    background-color: ${({ theme }) =>
      theme.palette.accent.green.darker} !important;
  }
  .step-item-finish .step-item-icon > span {
    color: white !important;
  }
`;
