import { useCallback } from 'react';
import { getConnectedEdges, Node, useReactFlow } from 'reactflow';

import { NodeTypeEnum } from '@comet/components/FlowEngine/Nodes';
import { BusinessNodeEnum } from '@comet/components/FlowEngine/Nodes/BusinessNode';

export const useDeleteIfNode = () => {
  const { getEdges, getNode, deleteElements, addEdges } = useReactFlow();
  return useCallback(
    (node: Node) => {
      const edges = getEdges();

      if (!node) return;

      // Get All the connected Edges for the Deleting IF Node
      const connectingChildNodeConnections = getConnectedEdges([node], edges);
      // Find the first node (True or False) of IF Node
      const connectingChildNodeEdge = connectingChildNodeConnections.find(
        (edge) => edge.source === node.id
      );
      // Get the Connecting Child Node (EXECUTOR_NODE)
      const connectingChildNode = getNode(
        connectingChildNodeEdge?.target ?? ''
      );

      // Get the EDGES for the connecting Node to GroupNodes
      const childNodeConnections = getConnectedEdges(
        connectingChildNode ? [connectingChildNode] : [],
        edges
      );

      // Edges which are removed after deleting IF_NODE, referencing for calculations
      const deletingNodeConnections = getConnectedEdges([node], edges);

      deleteElements({ nodes: [node] });

      if (connectingChildNode?.data.type === BusinessNodeEnum.EXECUTOR_NODE) {
        // Get Parent node of IF_NODE_V2 node.
        const deleteNodeSource = deletingNodeConnections.find(
          (edge) => edge.target === node.id
        );
        // Get Connecting Node of True and False conditional Nodes.
        // True and False are connected to only one node in IF_NODE_V2
        const deletedChildNodeTarget = childNodeConnections.find(
          (edge) => edge.source === connectingChildNode.id
        );

        if (!deletedChildNodeTarget) return;

        // Get the Connecting Node of Conditional Nodes.
        const attachingNode = getNode(deletedChildNodeTarget.target);
        // If it's an empty Node delete it, why need? an empty node.
        if (attachingNode?.type === NodeTypeEnum.AddNode) {
          deleteElements({ nodes: [attachingNode] });
          return;
        }

        if (!deleteNodeSource) return;

        // Attach Parent Node and Child Node of Conditional Nodes
        addEdges({
          id: deletedChildNodeTarget?.id,
          source: deleteNodeSource.source,
          target: deletedChildNodeTarget.target,
        });
      }
    },
    [addEdges, deleteElements, getEdges, getNode]
  );
};
