import { Edge } from 'reactflow';
import FlowEngine from '@comet/components/FlowEngine';
import FlowEngineV2 from 'src/FlowEngine2/main';
import { ExtendedNode } from '@comet/pages/Api/Types';

interface ApiFlowProps {
  nodes: ExtendedNode[];
  edges: Edge[];
  hasEditAccess: boolean;
  flowId: string;
  rootNode: string;
}

export const SubflowDetailsFlow = (props: ApiFlowProps) => {
  return <FlowEngineV2 {...props} />;
};
