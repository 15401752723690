import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';

export const TableContainer = styled(Flex)`
  flex-direction: column;
  justify-items: center;
  background: ${(props) => props.theme.palette.neutral.white};
  border: 1px solid ${(props) => props.theme.palette.neutral.light};
  border-radius: 6px;
`;
