import { AUTHENTICATION_DATA, AuthTypesEnum } from '../../Types';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { AuthTypeOptions, SchemaTypeOption } from '../../constants';
import {
  Button,
  Flex,
  Form,
  FormItem,
  Input,
  Label,
  Select,
  Show,
  Switch,
  Text,
} from '@comet/blocks';

import { DetailsTag } from '@comet/components/common';
import { EditableText } from '@comet/components/Editable/Text';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useState } from 'react';

export const UpdateAuthentication = ({
  data,
}: {
  data: AUTHENTICATION_DATA;
}) => {
  const [authEnabled, setAuthEnabled] = useState(data.enabled);
  const [jwks, setJwks] = useState(data.jwksURI);
  const [audience, setAudience] = useState(data.audience);
  const [userKey, setUserKey] = useState(data.userKey);
  const [tokenParams, setTokenParams] = useState(data.tokenData);
  const [accessKeySecret, setAccessKeySecret] = useState(data.accessKeySecret);
  const [authType, setAuthType] = useState(
    data.authType ? data.authType : AuthTypesEnum.JWKS_URI
  );
  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  return (
    <>
      <Flex alignItems="center" justifyContent="start" gap={6}>
        <Label required={true}>Enabled</Label>
        <FormItem
          name={['data', 'enabled']}
          marginBottom={0}
          initialValue={false}
        >
          <Switch
            onChange={setAuthEnabled}
            size="small"
            checked={authEnabled}
            disabled={!hasManageProjectSettingsAccess}
          />
        </FormItem>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Auth type</Label>
        <FormItem
          name={['data', 'authType']}
          required
          rules={[{ required: true, message: 'Auth type is required' }]}
          marginBottom={0}
        >
          <Select
            placeholder="Select Auth Types"
            onSelect={(e) => setAuthType(e)}
            options={AuthTypeOptions}
            value={authType}
            style={{ width: '40%' }}
          />
        </FormItem>
      </Flex>
      <Show if={AuthTypesEnum.ACCESS_KEY_SECRET === authType}>
        <Flex direction="column" gap={5}>
          <Label required={true}>Access Secret key</Label>
          <EditableText
            name={['data', 'accessKeySecret']}
            type="text"
            value={accessKeySecret || ''}
            onSave={setAccessKeySecret}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
      </Show>
      <Show if={AuthTypesEnum.JWKS_URI === authType}>
        <Flex direction="column" gap={5}>
          <Label required={true}>Jwks URI</Label>
          <EditableText
            name={['data', 'jwksURI']}
            type="text"
            value={jwks || ''}
            onSave={setJwks}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>

        <Flex direction="column" gap={5}>
          <Label required={true}>Audience</Label>
          <EditableText
            name={['data', 'audience']}
            type="text"
            value={audience || ''}
            onSave={setAudience}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
      </Show>
      <Flex direction="column" gap={5}>
        <Label required={true}>UserId Key</Label>
        <EditableText
          name={['data', 'userKey']}
          type="text"
          value={userKey}
          onSave={setUserKey}
          disabled={!hasManageProjectSettingsAccess}
        />
      </Flex>
      <Flex>
        <Label style={{ marginBottom: 10 }}>Token params</Label>
      </Flex>
      <Form.List name={'tokenData'} initialValue={tokenParams}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flex key={field.key} justifyContent="space-between">
                <Form.Item
                  name={[field.name, 'key']}
                  style={{ width: '45%', marginBottom: '0px' }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input key or delete this field.',
                    },
                  ]}
                >
                  <Input placeholder="Enter key" />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'tokenType']}
                  style={{ width: '45%', marginBottom: '0px' }}
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: 'Please input value or delete this field.',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select param type"
                    options={SchemaTypeOption}
                  />
                </Form.Item>
                <Flex justifyContent="center">
                  <Button
                    icon={<AiOutlineMinusCircle />}
                    onClick={() => remove(field.name)}
                  />
                </Flex>
              </Flex>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={add} style={{ width: '100%' }}>
                <AiOutlinePlusCircle /> Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
