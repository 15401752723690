import { ChangeEvent } from 'react';
import { Input } from '@comet/blocks';
import { AiOutlineSearch } from 'react-icons/ai';

export const NodeExplorerSearch = ({
  handleSearch,
}: {
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Input
      placeholder="Search node"
      onChange={handleSearch}
      suffix={
        <button
          style={{
            background: 'transparent',
            display: 'grid',
            placeItems: 'center',
            padding: 7,
            outline: 'none',
            border: 'none',
            borderLeft: '1px solid #d9d9d9',
          }}
        >
          <AiOutlineSearch color="#000000" opacity={'45%'} />
        </button>
      }
      style={{
        padding: '0px 0px 0px 10px',
        width: 264,
        borderRadius: 2,
        border: '1px solid #d9d9d9',
        marginTop: 12,
      }}
      autoFocus={true}
    />
  );
};

export function toSentenceCase(str: string) {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}
