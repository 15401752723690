import { Button, Dropdown, Flex, Text } from '@comet/blocks';
import { BiChevronDown } from 'react-icons/bi';

interface SelectCurrencyProps {
  currency: string;
  setCurrency: (currency: any) => void;
  totalPrice: number;
}

export const Pricing = ({
  currency,
  setCurrency,
  totalPrice,
}: SelectCurrencyProps) => {
  const locale = currency === 'USD' ? 'en-US' : 'en-IN';
  return (
    <Flex direction="column" gap={10}>
      <Flex alignItems="center" gap={10}>
        <Dropdown
          overlayStyle={{ marginLeft: 10 }}
          arrow
          menu={{
            items: [
              {
                key: '1',
                label: <div>INR</div>,
                onClick: () => setCurrency('INR'),
              },
              { key: '2', label: 'USD', onClick: () => setCurrency('USD') },
            ],
          }}
          trigger={['click']}
        >
          <Flex alignItems="center">
            <Button
              appearance="transparent"
              icon={
                <img
                  alt="India"
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${currency.substring(
                    0,
                    2
                  )}.svg`}
                  style={{ marginRight: 4, width: 16, height: 20 }}
                />
              }
              style={{ width: 100, padding: 0 }}
            >
              {currency} <BiChevronDown />
            </Button>
          </Flex>
        </Dropdown>
        <Text appearance="label.short.semiBold">Total</Text>
        <Text appearance="caption.regular">
          {Intl.NumberFormat(locale, {
            maximumFractionDigits: 4,
            minimumFractionDigits: 2,
            style: 'currency',
            currency: currency,
          }).format(totalPrice)}
          / per month
        </Text>
      </Flex>
      <Text appearance="label.small" color="neutral.darker">
        *exclusive of tax
      </Text>
    </Flex>
  );
};
