import React, { useEffect, useRef } from 'react';
import { Input, Show, Skeleton, Text } from '@comet/blocks';
import { InputRef } from 'antd';
import { styled } from '@comet/styled';
import { AiOutlineSearch } from 'react-icons/ai';
import debounce from 'lodash.debounce';

import { NodeRaw } from '../Nodes';
import { useNodeQuery } from '../services/FlowEngine.API.service';

import styles from './_.module.css';
import { getIcon } from '../Nodes/BusinessNode/BusinessNodeIcon';
import { BusinessNodeEnum } from '../Nodes/BusinessNode';

const Block = styled.div`
  .react-flow__handle {
    display: none;
  }
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
  padding: 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1%;
`;

interface IProps {
  onClick: (newNode: NodeRaw) => void;
}

export const NodeExplorer = ({ onClick }: IProps) => {
  const { data, isLoading } = useNodeQuery();
  const blocks = useRef<NodeRaw[]>([]);
  const [filteredBlocks, setFilteredBlocks] = React.useState<NodeRaw[]>([]);
  const nodeExplorerRef = useRef<HTMLDivElement>(null);

  const inpurRef = useRef<InputRef>(null);

  useEffect(() => {
    if (data) {
      blocks.current = data;
      setFilteredBlocks(data);
    }
  }, [data]);

  useEffect(() => {
    const popover = document.querySelector('.node-explorer-popup');
    if (popover) {
      popover.addEventListener('click', (event) => {
        if (
          !(event.target as HTMLElement).classList.contains(
            'node-explorer-block'
          )
        ) {
          event.stopPropagation();
        }
      });
    }
  }, []);

  useEffect(() => {
    inpurRef.current?.focus({ cursor: 'start' });
  }, [inpurRef]);

  const debounced = React.useRef(
    debounce((value: string) => {
      setFilteredBlocks(
        blocks.current.filter(({ data: { displayName } }) =>
          displayName.toLowerCase().includes(value.toLowerCase())
        )
      );
    }, 300)
  );

  return (
    <div ref={nodeExplorerRef} className={styles.popup}>
      <Text appearance="body.semiBold" style={{ marginBottom: 5 }}>
        Add a Node
      </Text>
      <Show if={isLoading}>
        <Skeleton.Input />
        <Skeleton.Input />
      </Show>
      <Show if={!!data}>
        <Input
          placeholder="Search block"
          onChange={(e) => debounced.current(e.target.value)}
          prefix={<AiOutlineSearch />}
          ref={inpurRef}
        />
        <div className={styles.blocksConatiner}>
          {filteredBlocks.map((rawNode) => {
            const Icon = getIcon(
              rawNode.data.type ?? BusinessNodeEnum.ADD_NODE
            );
            return (
              <Block
                key={`${rawNode.type}-${rawNode.data?.type}`}
                className={`dndnode ${rawNode.type} node-explorer-block ${styles.block}`}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onClick(rawNode);
                }}
              >
                <span>
                  <Icon />
                </span>
                {rawNode.data.displayName}
              </Block>
            );
          })}
        </div>
      </Show>
    </div>
  );
};

export default NodeExplorer;
