import { EnvironmentChangeType } from './Environment.types';

export enum EnvironmentTypesEnum {
  STAGING = 'STAGING',
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  CUSTOM = 'CUSTOM',
}

export enum EnvironmentTierEnum {
  MICRO_0 = 'MICRO_0',
  MICRO_1 = 'MICRO_1',
  MINI_0 = 'MINI_0',
  MINI_1 = 'MINI_1',
  SMALL_0 = 'SMALL_0',
  SMALL_1 = 'SMALL_1',
  MEDIUM_0 = 'MEDIUM_0',
  MEDIUM_1 = 'MEDIUM_1',
  ENTERPRISE = 'ENTERPRISE',
}
export enum ResourceTypeEnum {
  SHARED = 'SHARED',
  DEDICATED = 'DEDICATED',
}

export interface EnvironmentMetadata {
  isShared: boolean;
  sharedEnvId: string | null;
  tier: EnvironmentTierEnum | null;
}

export interface EnvironmentRawModel {
  name: string;
  type: string;
  metadata: EnvironmentMetadata;
}
export interface EnvironmentUpdateRawData {
  type: string;
  metadata: EnvironmentMetadata;
  updateEventType: EnvironmentChangeType;
}

export type EnvironmentUpdateFormData = Omit<
  EnvironmentUpdateRawData,
  'updateEventType'
>;
