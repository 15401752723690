import { ModelTypesEnum } from './types';

export const modelTypeOptions = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: 'Request Body',
    value: ModelTypesEnum.REQUEST_BODY,
  },
  {
    label: 'Query Params',
    value: ModelTypesEnum.QUERY_PARAMS,
  },
  {
    label: 'Database Collection',
    value: ModelTypesEnum.DATABASE_COLLECTION,
  },
];

export const REQUEST_MODELS_PARAM = 'QUERY_PARAMS_AND_REQUEST_BODY';

export const MODEL_NAME_REGEX = /^[a-zA-Z_][a-zA-Z0-9_]*$/;

export const ModelStateMapping = {
  DRAFT_INACTIVE: ['Draft as Inactive', 'palette.neutral.light'],
  DRAFT: ['Saved as draft', 'palette.neutral.light'],
  DEPLOYED: ['Deployed', 'palette.accent.blue.light'],
  INACTIVE: ['Inactive', '#D16E4E'],
};
