import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from 'reactflow';

import { AiFillPlusCircle } from 'react-icons/ai';
import { ReactNode } from 'react';
import { useNodeExplorerStore } from '../store';

// this is a little helper component to render the actual edge label
function EdgeLabel({
  transform,
  label,
  pointerEvents,
}: {
  transform: string;
  label: string | ReactNode;
  pointerEvents: 'all' | 'none';
}) {
  return (
    <div
      style={{
        position: 'absolute',
        transform: transform,
        background: '#ffcc00',
        borderRadius: 5,
        fontSize: 12,
        fontWeight: 700,
        pointerEvents: pointerEvents,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

export function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
}: EdgeProps) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  //const [edgePath,labelX,labelY] = getSmoothStepPath({sourceX,sourceY,sourcePosition,targetX,targetY,targetPosition});

  const setClickedAddEdge = useNodeExplorerStore((s) => s.setClickedAddEdge);
  const openNodeExplorer = useNodeExplorerStore((s) => s.openNodeExplorer);

  const transformForAddBtn = data.label
    ? `translate(-50%, -100%) translate(${targetX}px,${targetY - 5}px)`
    : `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`;

  // if target is in left of the

  return (
    <>
      <BaseEdge path={edgePath} />
      <EdgeLabelRenderer>
        {data.label && (
          <EdgeLabel
            transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
            label={data.label}
            pointerEvents="none"
          />
        )}

        {data.isAdd && (
          <AiFillPlusCircle
            style={{
              position: 'absolute',
              transform: transformForAddBtn,
              color: 'grey',
              // background: '#ffcc00',
              // padding: 2,
              // borderRadius: 5,
              // fontSize: 12,
              // fontWeight: 700,
              pointerEvents: 'all',
            }}
            className="nodrag"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setClickedAddEdge(id);
              openNodeExplorer(true);
            }}
          />
        )}
      </EdgeLabelRenderer>
    </>
  );
}
