/**
 * This a test file/folder and doesn't follow best practices, please keep that in mind
 * before ref. to this.
 */
import styled from 'styled-components';
import FlowEngine from '@comet/components/FlowEngine/index';
import { useEffect, useState } from 'react';
import { getAxios } from '@comet/axios';
import { Graph } from 'src/types/common';

const PlaygroundWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const FlowEngineTest = () => {
  const [data, setData] = useState<Graph>();
  useEffect(() => {
    getAxios(true)
      .get('/flow-engine-mock')
      .then(({ data }) => {
        setData(data);
      });
  }, []);
  if (!data) {
    return <p>Loading</p>;
  }
  return (
    <PlaygroundWrapper>
      {/* <FlowEngine {...data} hasEditAccess={true} /> */}
    </PlaygroundWrapper>
  );
};

export default FlowEngineTest;
