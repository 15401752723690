import { Tooltip } from '@comet/blocks';
import styled from 'styled-components';

const TruncatedDiv = styled.div`
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function TruncatedText({
  maxLength,
  children,
}: {
  maxLength: number;
  children: string;
}) {
  if (children.length <= maxLength) {
    return <div>{children}</div>;
  }

  return (
    <Tooltip title={children}>
      <TruncatedDiv>{children}</TruncatedDiv>
    </Tooltip>
  );
}
