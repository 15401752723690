import { TableHeader, Text } from '@comet/blocks';
import { Link, useParams } from '@comet/router';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { GetProjectsResponse } from '../types/Projects.types';
import { FiMoreHorizontal } from 'react-icons/fi';
import { OrgMember } from '@comet/pages/Organisation/Organisation.types';
import { useGetProjectQuery, useProjectAccessQuery } from '../service';
import { capitalize } from 'lodash';
import { notification } from 'antd';
import { UrlContainer } from '@comet/components/UrlContainer';

export const getDisplayName = (str: string) => {
  return str
    .split('_')
    .map((s) => capitalize(s))
    .join(' ');
};

export const useProjectListingData = (
  projectsData: GetProjectsResponse | undefined
) => {
  const { organisationId } = useParams();

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        accessor: 'name',
        sortType: 'basic',
        Cell: (data) => {
          const { name, id } = data.row.original;

          return (
            <Link
              routeId="organisations.organisationId.projects.projectId.dashboard"
              pathParam={{ organisationId, projectId: id }}
            >
              {name}
            </Link>
          );
        },
      },
      {
        Header: <TableHeader text="Project ID" />,
        accessor: 'projectId',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Cloud Provider" />,
        accessor: 'cloudProvider',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Region" />,
        accessor: 'region',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Created by" sortable />,
        accessor: 'createdBy',
        sortType: 'basic',
      },
    ];
  }, [organisationId]);

  const tableData = useMemo(() => {
    if (!projectsData) return [];

    return projectsData.data.map((item) => {
      const onProjectIdCopy = () => {
        navigator.clipboard.writeText(item.id);
        notification.success({
          message: 'Success',
          description: 'Project ID copied successfully',
        });
      };
      return {
        name: item.name,
        projectId: (
          <UrlContainer width="240px" onClick={onProjectIdCopy}>
            <Text appearance="caption.regular">{item.id}</Text>
          </UrlContainer>
        ),
        projectType: item.type,
        cloudProvider: item.deployment.cloudProvider,
        region: item.deployment.region,
        createdBy: item.createdBy?.name,
        id: item.id,
        action: <FiMoreHorizontal />,
      };
    });
  }, [projectsData]);

  return { tableColumns, tableData };
};

export const useProjectMembersOptions = (
  organisationId: string,
  projectId: string
) => {
  const { data: projectMembers } = useProjectAccessQuery(
    organisationId,
    projectId
  );

  const formattedProjectMembers = useMemo(
    () =>
      (projectMembers?.data || []).map((member: OrgMember) => {
        return {
          label: member.name,
          value: member.id,
          role: member.role,
        };
      }),
    [projectMembers?.data]
  );

  const projectMembersOptions = useMemo(
    () => [
      {
        label: 'Any',
        value: '',
        role: '',
      },
      ...formattedProjectMembers,
    ],
    [formattedProjectMembers]
  );

  return projectMembersOptions;
};

export const useGetApiBaseUrl = () => {
  const { organisationId, projectId } = useParams();

  const { data, isLoading, isError, refetch } = useGetProjectQuery(
    organisationId,
    projectId
  );

  const region = data?.deployment.region;
  const url = `https://free-${region}.cosmocloud.io`;

  return {
    url,
    isLoading,
    isError,
    refetch,
  };
};
