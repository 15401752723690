import { useCallback, useMemo, useState } from 'react';
import { GetSecretsDetailsResponse } from '../Types';
import { Column } from 'react-table';
import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
  Tooltip,
} from '@comet/blocks';
import { Link, useParams } from '@comet/router';
import { format } from '@comet/date';
import { User } from '@comet/components/User';
import { deleteSecret } from '../services/Secrets.service';
import { FiMoreHorizontal } from 'react-icons/fi';
import { styled } from '@comet/styled';
import { RiAlertLine } from 'react-icons/ri';

const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

export const DeleteSecrets = ({
  environmentId,
  secretId,
  refetch,
}: {
  environmentId: string;
  secretId: string;
  refetch: () => void;
}) => {
  const { projectId } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await deleteSecret(projectId, environmentId, secretId);
    setIsConfirm(false);
    refetch();
  }, [environmentId, secretId, projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Delete',
              key: 'delete',
              label: <Tooltip title={'delete secret'}>Delete</Tooltip>,
              onClick: toggleModal,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this secret?{' '}
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this Secret.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useSecretsListingData = ({
  secretsData,
  refetch,
}: {
  secretsData?: GetSecretsDetailsResponse;
  refetch: () => void;
}) => {
  const { projectId, organisationId } = useParams();
  const tableData = useMemo(() => {
    if (!secretsData) return [];

    const secretsList = secretsData.data;

    return secretsList.map((secret) => {
      return {
        name: secret.name,
        id: secret.id,
        type: secret.type.toLowerCase(),
        updatedOn: formatTime(secret.updatedOn),
        updatedBy: <User {...(secret.updatedBy || {})} />,
        action: (
          <DeleteSecrets
            environmentId={secret.environmentId}
            secretId={secret.id}
            refetch={refetch}
          />
        ),
        environmentId: secret.environmentId,
      };
    });
  }, [secretsData, refetch]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        Cell: (data) => {
          const currentRow = data.row.original;
          return (
            <Link
              routeId="organisations.organisationId.projects.projectId.secrets.secretId.details"
              pathParam={{
                secretId: currentRow.id,
                organisationId,
                projectId,
              }}
              state={{ envId: currentRow.environmentId }}
            >
              {currentRow.name}
            </Link>
          );
        },
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Type" />,
        accessor: 'type',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated on" />,
        accessor: 'updatedOn',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated by" sortable />,
        accessor: 'updatedBy',
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
      },
    ];
  }, [organisationId, projectId]);

  return { tableColumns, tableData };
};
