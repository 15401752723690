import { Button, Form, FormItem, Input, Typography, Show } from '@comet/blocks';
import { confirmForgotPassword, initiateForgotPassword } from '@comet/amplify';
import { AuthSection, Header } from './common';
import { useMemo, useState } from 'react';
import { Navigate } from '@comet/router';

import { ReactComponent as CosmoCloud } from '@comet/assets/svg/cosmocloud_logo.svg';
import { styled } from '@comet/styled';

enum Stage {
  Initiate = 'Initiate',
  Confirm = 'Confirm',
  Complete = 'Complete',
}

export const FormBox = styled.div`
  width: 80%;
  height: 100%;
  margin-top: 20px;
  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    margin-top: 200px;
    width: 368px;
  }`}
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

const ForgotPassword = () => {
  const [stage, setStage] = useState<Stage>(Stage.Initiate);
  const [error, setError] = useState<any>(null);
  const [username, setUsername] = useState('');

  const [form] = Form.useForm();

  const handleUsernameSubmit = async (formValues: { username: string }) => {
    const { username } = formValues;

    try {
      await initiateForgotPassword(username);

      setStage(Stage.Confirm);
      setUsername(username);
    } catch (error) {
      setError(error);
    }
  };

  const handleNewPasswordSubmit = async (formValues: {
    code: string;
    newPassword: string;
  }) => {
    const { code, newPassword } = formValues;

    try {
      await confirmForgotPassword(username, code, newPassword);

      setStage(Stage.Complete);
    } catch (error: any) {
      setError(error);
      form.setFields([{ name: 'code', errors: [error.message] }]);
    }
  };

  const status = useMemo(() => {
    if (error) return 'error';
  }, [error]);

  if (stage === Stage.Complete) {
    return <Navigate routeId="signIn" />;
  }

  return (
    <>
      <Header>
        <CosmoCloud />
      </Header>
      <AuthSection style={{ marginTop: 50 }}>
        <FormBox>
          <Typography.Title level={2}>Forgot Password</Typography.Title>

          <Show if={stage === Stage.Initiate}>
            <Typography.Text>Enter your username</Typography.Text>
            <Form form={form} onFinish={handleUsernameSubmit}>
              <FormItem name="username">
                <Input placeholder="Username" />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Get Code
                </Button>
              </FormItem>
            </Form>
          </Show>

          <Show if={stage === Stage.Confirm}>
            <Typography.Text>
              Enter the code received on email and new password
            </Typography.Text>
            <Form form={form} onFinish={handleNewPasswordSubmit}>
              <FormItem name="code">
                <Input placeholder="Code" status={status} />
              </FormItem>
              <FormItem name="newPassword">
                <Input
                  type="password"
                  placeholder="New Password"
                  status={status}
                />
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Change Password
                </Button>
              </FormItem>
            </Form>
          </Show>
          {/* <Show if={!!error}>Something went wrong</Show> */}
        </FormBox>
      </AuthSection>
    </>
  );
};

export default ForgotPassword;
