import { BusinessNodeEnum, NodeRaw, NodeTypeEnum } from 'src/FlowEngine2/types';
import {
  getEdgeId,
  getNodesAttachedWithTargetHandle,
  setRootNodeOfFlow,
  updateMetaDataOfIfNode,
  updateMetadataOfSwitchNode,
} from 'src/FlowEngine2/store/flow-engine-store/utils';
import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

export function addNodeFromAddNode(
  id: string | null, // id can be of add node or rootNode
  node: NodeRaw,
  extraNodes: NodeRaw[]
) {
  const { createNode, removeEdge, addEdge, removeNode } =
    useFlowEngineStore.getState();
  const node1Ids = getNodesAttachedWithTargetHandle(id);
  if (!node1Ids) {
    // CASE 1
    const rootNodeId = id;
    removeNode(rootNodeId);

    if (
      node.data.type !== BusinessNodeEnum.HTTP_RESPONSE &&
      node.data.type !== BusinessNodeEnum.FUNCTION_RESPONSE &&
      node.type !== NodeTypeEnum.IfElse &&
      node.type !== NodeTypeEnum.SwitchNode
    ) {
      const addNodeId = createNode('ADD_NODE');
      extraNodes.forEach((extraNode) => addEdge(extraNode.id, addNodeId));
    }
    setRootNodeOfFlow(node.id);
  } else {
    // CASE 2

    const addNodeId = id;

    node1Ids.forEach((node1Id) => {
      const node1 = getNodeById(node1Id);
      const edge1Id = getEdgeId(node1Id, addNodeId);

      if (!node1 || !edge1Id) {
        return new Error(
          `Either node or edge connected with add node id-${addNodeId} is not present`
        );
      }

      addEdge(node1Id, node.id);
      switch (node1.type) {
        case NodeTypeEnum.IfElse: {
          updateMetaDataOfIfNode(node1.id, addNodeId, node.id);
          break;
        }
        case NodeTypeEnum.SwitchNode: {
          updateMetadataOfSwitchNode(node1.id, addNodeId, node.id);
          break;
        }
      }
      removeEdge(edge1Id);
    });

    extraNodes.forEach((extraNode) => {
      if (
        ![
          BusinessNodeEnum.HTTP_RESPONSE,
          BusinessNodeEnum.FUNCTION_RESPONSE,
        ].includes(node.data.type)
      ) {
        addEdge(extraNode.id, addNodeId);
      }
    });

    if (
      node.type === NodeTypeEnum.IfElse ||
      node.type === NodeTypeEnum.SwitchNode ||
      node.data.type === BusinessNodeEnum.HTTP_RESPONSE ||
      node.data.type === BusinessNodeEnum.FUNCTION_RESPONSE
    ) {
      removeNode(addNodeId);
    }
  }
}
