import { AuthTypesEnum } from '../Types';

export enum SecretTypes {
  AUTHENTICATION_SECRET = 'AUTHENTICATION_SECRET',
  DATABASE_SECRET = 'DATABASE_SECRET',
  CUSTOM_SECRET = 'CUSTOM_SECRET',
}

export enum DatabaseMode {
  MONGODB_ATLAS = 'MONGODB_ATLAS',
  DATABASE_ONLY = 'DATABASE_ONLY',
}

export const SecretTypesOption = [
  { label: 'Database', value: SecretTypes.DATABASE_SECRET },
  {
    label: 'Authenication',
    value: SecretTypes.AUTHENTICATION_SECRET,
  },
  { label: 'Custom', value: SecretTypes.CUSTOM_SECRET },
];

export const DatabaseModeOption = [
  { label: 'MongoDB Atlas', value: DatabaseMode.MONGODB_ATLAS },
  { label: 'Database Only', value: DatabaseMode.DATABASE_ONLY },
];

export const StepsArray = [
  {
    title: 'Select',
  },
  {
    title: 'Configure',
  },
];

export enum TokenTypesEnum {
  OBJECT_ID = 'OBJECT_ID',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'NUMBER',
  FLOAT = 'FLOAT',
}

export const SchemaTypeOption = [
  { label: 'ObjectId', value: TokenTypesEnum.OBJECT_ID },
  { label: 'Boolean', value: TokenTypesEnum.BOOLEAN },
  { label: 'String', value: TokenTypesEnum.STRING },
  { label: 'Integer', value: TokenTypesEnum.INTEGER },
  { label: 'Float', value: TokenTypesEnum.FLOAT },
];

export const AuthTypeOptions = [
  {
    label: 'JWKS URI',
    value: AuthTypesEnum.JWKS_URI,
  },
  {
    label: 'Access key Secret',
    value: AuthTypesEnum.ACCESS_KEY_SECRET,
  },
];
