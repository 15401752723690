import { Flex, Modal, Show, Text } from '@comet/blocks';
import { useGetProjectQuery } from '@comet/pages/Project/service';
import { BsStars } from 'react-icons/bs';
import { IoWarningOutline } from 'react-icons/io5';
import { MdFolderOpen } from 'react-icons/md';
import { ErrorIcon, Shimmer, StyledLink } from './Header.common';
import { StyledTag } from './Header.styled';
import { FaRegCalendarCheck } from 'react-icons/fa';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

interface Props {
  organisationId: string;
  projectId: string;
}

export const HeaderBreadcrumbProject = ({
  organisationId,
  projectId,
}: Props) => {
  const { data, isLoading, isError } = useGetProjectQuery(
    organisationId,
    projectId
  );

  const { data: orgPlanData } = useGetOrgSubscriptionQuery(organisationId);
  const isOnFreeTier = orgPlanData?.planCode === 'FREE';

  const infoModal = () => {
    Modal.info({
      title: <Text appearance="heading.card">Want to upgrade?</Text>,
      content: (
        <Flex direction="column" style={{ fontSize: 16 }}>
          <p>
            To offer you the best pricing plans, help us understand your
            requirements and scale better by scheduling a call with our team.
          </p>
        </Flex>
      ),
      closable: true,
      okText: (
        <Flex alignItems="center" gap={10}>
          <FaRegCalendarCheck style={{ fontSize: '18px' }} />
          <span style={{ fontWeight: 600 }}>Book a call</span>
        </Flex>
      ),
      width: 500,
      onOk() {
        window.open(
          'https://cal.com/shreybatra/upgrading-cosmocloud',
          '_blank'
        );
      },
    });
  };

  return (
    <>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={4}>
          <Show if={isLoading}>
            <Shimmer />
          </Show>
          <Show if={!!data}>
            <Flex alignItems="center" gap={10}>
              <StyledLink
                routeId="organisations.organisationId.projects.projectId.dashboard"
                pathParam={{ organisationId, projectId }}
              >
                <Flex alignItems="center" gap={4}>
                  <MdFolderOpen />
                  <Text appearance="label.short.regular">{data?.name} </Text>
                </Flex>
              </StyledLink>

              {isOnFreeTier && (
                <StyledTag color="volcano" onClick={infoModal}>
                  <Flex alignItems="center" gap={3}>
                    <IoWarningOutline />
                    <Text appearance="label.short.regular">Upgrade</Text>
                  </Flex>
                </StyledTag>
              )}
              {!isOnFreeTier && (
                <StyledTag color="purple">
                  <Flex alignItems="center" gap={3}>
                    <BsStars />
                    <Text appearance="label.short.regular">PRO</Text>
                  </Flex>
                </StyledTag>
              )}
            </Flex>
          </Show>
        </Flex>
        <Show if={isError}>
          <Flex alignItems="center" gap={4}>
            <ErrorIcon />
            <Text appearance="label.short.regular">Can't fetch Project</Text>
          </Flex>
        </Show>
      </Flex>
    </>
  );
};
