import { useGoBack, useNavigate, useParams } from '@comet/router';

import { CreateStorageAccountForm } from './CreateStorageAccount.Form';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { Paper } from '@comet/blocks';
import { queryClient } from '@comet/query';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';

export const CreateProjectStorageAccount = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const goBack = useGoBack();

  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: ['useGetStorageAccountsQuery', projectId],
    });
    navigate('organisations.organisationId.projects.projectId.storageAccounts');
  };
  const { hasManageProjectSettingsAccess } = useProjectPermissions();

  if (!hasManageProjectSettingsAccess) {
    return <ErrorPage />;
  }

  return (
    <Paper size="medium">
      <CreateStorageAccountForm
        onCancel={goBack}
        onSuccess={onSuccess}
        projectId={projectId}
      />
    </Paper>
  );
};
