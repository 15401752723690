import { NEStoreStateType } from './types';
import { NodeRaw } from '../../types';
import { create } from 'zustand';

export const useNodeExplorerStore = create<NEStoreStateType>((set) => ({
  // Modal
  isNodeExplorerOpen: false,
  openNodeExplorer: (open: boolean) => {
    set({ isNodeExplorerOpen: open });
  },
  selectedBusinessNode: null,
  setSelectedBusinessNode: (businessNode: NodeRaw | null) => {
    set({
      selectedBusinessNode: businessNode,
    });
  },
  clickedAddNode: null,
  setClickedAddNode: (nodeId: string | null) => {
    set({ clickedAddNode: nodeId });
  },
  clickedAddEdge: null,
  setClickedAddEdge: (edgeId: string | null) => {
    set({ clickedAddEdge: edgeId });
  },
}));
