import { Center, FullCenter, Button } from '@comet/blocks';
import { useNavigate } from '@comet/router';
import { Result } from 'antd';

export const PlanExpired = () => {
  const navigate = useNavigate();

  return (
    <FullCenter>
      <Result
        // icon={<BiSad size={40} />}
        status="error"
        title="Your trial period has ended."
        subTitle="Please upgrade your plan to make sure your services run seamlessly."
        extra={
          <Center>
            <Button style={{ marginRight: 10 }}>
              <a
                href="https://cosmocloud.io/contact"
                target="_blank"
                rel="noreferrer"
              >
                Contact US
              </a>
            </Button>
            <Button
              appearance="primary"
              onClick={() =>
                navigate(
                  'organisations.organisationId.projects.projectId.upgrade'
                )
              }
            >
              Upgrade
            </Button>
          </Center>
        }
      />
    </FullCenter>
  );
};
