import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { useParams } from '@comet/router';
import { Analyzer, AnalyzerRequestData, GetAnalyzersResponse } from '../types';

export const useGetAnalyzersQuery = () => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/custom-analyzers`;

  return useQuery({
    queryKey: ['useGetAnalyzersQuery', projectId],
    queryFn: async () => {
      const { data } = await getAxios().get<GetAnalyzersResponse>(url);

      return data;
    },
  });
};

export const useGetAnalyzerQuery = (id: string) => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/custom-analyzers/${id}`;

  return useQuery({
    queryKey: ['useGetAnalyzerQuery', projectId, id],
    queryFn: async () => {
      const { data } = await getAxios().get<Analyzer>(url);

      return data;
    },
  });
};

export const createAnalyzer = (
  projectId: string,
  analyzerRequestData: AnalyzerRequestData
) => {
  const url = `/resources/${projectId}/custom-analyzers`;

  return getAxios()
    .post<{ id: string }>(url, analyzerRequestData)
    .then(({ data }) => data);
};

export const updateAnalyzer = (
  projectId: string,
  analyzerId: string,
  analyzerRequestData: AnalyzerRequestData
) => {
  const url = `/resources/${projectId}/custom-analyzers/${analyzerId}`;

  return getAxios()
    .put<{ id: string }>(url, analyzerRequestData)
    .then(({ data }) => data);
};

export const deleteAnalyzer = (projectId: string, analyzerId: string) => {
  const url = `/resources/${projectId}/custom-analyzers/${analyzerId}`;

  return getAxios().delete(url);
};

export const useAnalyzerExistsQuery = (analyzerName: string) => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/custom-analyzers/exists`;

  return useQuery({
    enabled: false,
    queryKey: ['useAnalyzerExistsQuery', analyzerName, projectId],
    queryFn: async () => {
      const { data } = await getAxios().get(url, {
        params: { name: analyzerName },
      });

      return data;
    },
  });
};
