import { AddButton, Field, QueryFields } from './components';
import { Button, Modal, Select } from '@comet/blocks';
import { BOOLEAN_TYPES, CONDITIONS, OPERANDS, TYPES } from './constants';
import { Popover } from 'antd';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { Container } from '../QuickStartGuide';
import { GrAdd } from 'react-icons/gr';
import StateValue2 from 'src/FlowEngine2/Components/StateValue2';
import get from 'lodash.get';
import remove from 'lodash/remove';
import set from 'lodash.set';
import styles from './_.module.css';
import { sendErrorNotification } from 'src/blocks/Notification';
import { Editor } from '@monaco-editor/react';

const FieldContainer = ({
  fields,
  onClickAdd,
  index,
  setCondition,
  condition = '$and',
}: {
  fields: React.ReactNode[];
  onClickAdd: (nested: boolean, index?: string) => void;
  index?: string;
  condition?: '$and' | '$or';
  setCondition: (value: '$and' | '$or') => void;
}) => {
  return (
    <QueryFields>
      <Select
        className={styles.select}
        options={[
          { label: 'And', value: '$and' },
          { label: 'Or', value: '$or' },
        ]}
        value={condition}
        onChange={(value) => setCondition(value)}
      />
      {fields}
      <Popover
        content={
          <div style={{ width: '100%' }}>
            <Button
              style={{ width: '100%' }}
              onClick={() => onClickAdd(true, index)}
              disabled={index ? index?.split('.').length >= 5 : false}
            >
              Nested Condition
            </Button>
            <Button
              onClick={() => onClickAdd(false, index)}
              style={{ width: '100%' }}
            >
              Single Condition
            </Button>
          </div>
        }
        showArrow={false}
      >
        <div className="addNew">
          <AddButton>
            <GrAdd />
          </AddButton>
        </div>
      </Popover>
    </QueryFields>
  );
};

const getFields = ({
  fields,
  onClickAdd,
  onDelete,
  currentIndex,
  onChange,
  updateCondition,
}: {
  fields: QueryItem;
  onClickAdd: (s: boolean, y?: string) => void;
  onDelete: (id: number | string, index?: string) => void;
  currentIndex?: string;
  onChange?: (
    id: string | number,
    index: string,
    key: OPERANDS,
    value: QueryValue
  ) => void;
  updateCondition: (index: string, value: '$and' | '$or') => void;
}) => {
  const child: React.ReactNode[] = [];
  fields.items?.forEach((field, _index) => {
    let index: string;
    if (!currentIndex) {
      index = 'items.' + _index.toString();
    } else {
      index = `${currentIndex}.items.${_index}`;
    }
    if (Object.hasOwn(field, 'condition')) {
      // Means this is a nested Item
      child.push(
        <div style={{ marginLeft: 20, width: '100%' }} key={index}>
          <FieldContainer
            fields={getFields({
              fields: field as QueryItem,
              onClickAdd,
              onDelete,
              currentIndex: index,
              onChange,
              updateCondition,
            })}
            condition={(field as QueryItem).condition}
            onClickAdd={onClickAdd}
            index={index}
            setCondition={(value) => {
              updateCondition(index, value);
            }}
          />
        </div>
      );
    } else {
      child.push(
        <Field key={(field as Item).id}>
          <div className="fields">
            <AiOutlineClose
              className={'icon'}
              onClick={() => {
                onDelete((field as Item).id, index);
              }}
              size={20}
            />

            <StateValue2
              onChange={(value) => {
                onChange?.(
                  (field as Item).id,
                  index,
                  OPERANDS.LEFT,
                  value as Item['0']
                );
              }}
              value={(field as Item)[0] ?? ''}
            />
            <Select
              className="select"
              size="middle"
              placeholder="Choose value"
              options={CONDITIONS}
              dropdownMatchSelectWidth={false}
              onChange={(value) =>
                onChange?.((field as Item).id, index, OPERANDS.CONDITION, value)
              }
              value={(field as Item)[1]}
            />
            <Select
              className="select"
              size="middle"
              placeholder="Choose Type"
              options={TYPES}
              dropdownMatchSelectWidth={false}
              onChange={(value) => {
                const currentValue = (field as Item)[2] ?? '';
                let parsedValue;
                if (value === 'number') {
                  if (currentValue.toString().includes('.')) {
                    parsedValue = parseInt(currentValue.toString()) || 0;
                  } else {
                    parsedValue = parseInt(currentValue.toString()) || 0;
                  }
                } else if (value === 'boolean') {
                  parsedValue = !!currentValue;
                } else if (value === 'string') {
                  parsedValue = currentValue.toString();
                } else {
                  parsedValue = currentValue;
                }
                onChange?.(
                  (field as Item).id,
                  index,
                  OPERANDS.RIGHT,
                  parsedValue
                );
              }}
              value={
                !isNaN(parseFloat(((field as Item)[2] ?? '')?.toString())) &&
                ((field as Item)[2] ?? '')?.toString().slice(-1) !== '.'
                  ? 'number'
                  : typeof (field as Item)[2] === 'boolean'
                  ? 'boolean'
                  : 'string'
              }
            />
            {typeof (field as Item)[2] === 'boolean' ? (
              <Select
                className="select"
                options={BOOLEAN_TYPES}
                size="middle"
                dropdownMatchSelectWidth={false}
                onChange={(value) =>
                  onChange?.((field as Item).id, index, OPERANDS.RIGHT, value)
                }
                value={
                  (field as Item)[2]?.toString().toLowerCase() === 'true'
                    ? true
                    : false
                }
              />
            ) : (
              <StateValue2
                onChange={(value) => {
                  let newValue;
                  if (value.length === 0) {
                    newValue = '';
                  } else if (!isNaN(value) && value[value.length - 1] !== '.') {
                    newValue = parseFloat(value);
                  } else if (
                    (value as string).toString().toLowerCase() === 'true'
                  ) {
                    newValue = true;
                  } else if (
                    (value as string).toString().toLowerCase() === 'false'
                  ) {
                    newValue = false;
                  } else {
                    newValue = value;
                  }
                  onChange?.(
                    (field as Item).id,
                    index,
                    OPERANDS.RIGHT,
                    newValue
                  );
                }}
                value={((field as Item)[2] ?? '').toString()}
              />
            )}
          </div>
        </Field>
      );
    }
  });

  return child;
};

interface Props {
  value: Language;
  onComplete: (value: Language) => void;
}

export const ConditionQuery = ({ onComplete, value }: Props) => {
  const [modal, setModal] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState(value);

  // Restore the old nodeID and start from there in next open
  const nodeId = useRef(0);
  const [fields, setFields] = useState<QueryItem>({
    condition: '$or',
    items: [{ id: 0 }],
  });

  const createNestedSchema = useCallback((item: Language) => {
    const schema = {} as QueryItem;
    Object.entries(item).forEach(([key, value]) => {
      schema.condition = key as ICondition;
      value.forEach((item) => {
        if (Object.hasOwn(item, '$or') || Object.hasOwn(item, '$and')) {
          const _schema = createNestedSchema(item as Language);
          if (schema.items) {
            schema.items.push(_schema);
          } else {
            schema.items = [_schema];
          }
        } else {
          const _item = { ...item } as Query;
          const left_operand = Object.keys(_item)?.[0];
          const condition = Object.keys(_item?.[left_operand])?.[0];
          const right_operand = _item?.[left_operand]?.[condition];
          nodeId.current++;
          if (schema.items) {
            schema.items.push({
              id: nodeId.current++,
              '0': left_operand,
              '1': condition,
              '2': right_operand,
            });
          } else {
            schema.items = [
              {
                id: nodeId.current++,
                '0': left_operand,
                '1': condition,
                '2': right_operand,
              },
            ];
          }
        }
      });
    });

    return schema;
  }, []);

  useEffect(() => {
    if (!value) return;

    const schema = {} as QueryItem;
    Object.entries(value).forEach(([key, value], index) => {
      schema['condition'] = key as ICondition;
      value.forEach((item) => {
        if (Object.hasOwn(item, '$or') || Object.hasOwn(item, '$and')) {
          const _schema = createNestedSchema(item as Language);
          if (schema.items) {
            schema.items.push(_schema);
          } else {
            schema.items = [_schema];
          }
        } else {
          const _item = { ...item } as Query;
          const left_operand = Object.keys(_item)?.[0];
          const condition = Object.keys(_item?.[left_operand])?.[0];
          const right_operand = _item?.[left_operand]?.[condition];
          nodeId.current++;
          if (schema.items) {
            schema.items.push({
              id: nodeId.current++,
              '0': left_operand,
              '1': condition,
              '2': right_operand,
            });
          } else {
            schema.items = [
              {
                id: nodeId.current++,
                '0': left_operand,
                '1': condition,
                '2': right_operand,
              },
            ];
          }
        }
      });
    });

    setFields(schema);
  }, [createNestedSchema, value]);

  const onChange = useCallback(
    (id: string | number, index: string, key: OPERANDS, value: QueryValue) => {
      const copy = { ...fields };
      const itemToUpdate = get(copy, index);
      const keyToUpdate =
        key === OPERANDS.LEFT ? '0' : key === OPERANDS.CONDITION ? '1' : '2'; // Indexs
      itemToUpdate[keyToUpdate] = value; // Update particular value

      setFields(copy);
    },
    [fields]
  );

  const updateCondition = useCallback(
    (index: string, value: '$and' | '$or') => {
      const copy = { ...fields };
      set(copy, `${index}.condition`, value);
      setFields(copy); // Directly update the condition for particular item
    },
    [fields]
  );

  const onClickAdd = useCallback(
    (isNested: boolean, index?: string) => {
      const copy = { ...fields };
      let fieldToPush;
      if (!index) {
        fieldToPush = copy;
      } else {
        fieldToPush = get(copy, index);
      }
      nodeId.current++; // Update index
      if (isNested) {
        fieldToPush?.items?.push({
          // if It's a nested Item add the whole tree into the array.
          condition: '$or',
          items: [{ id: nodeId.current, '1': '$eq' }],
        });
      } else {
        fieldToPush?.items?.push({ id: nodeId.current, '1': '$eq' }); // Random Default base condition
      }

      setFields(copy);
    },
    [fields]
  );

  const onDelete = useCallback(
    (id: string | number, index?: string) => {
      const copy = { ...fields };
      if (index) {
        const _index = index.split('.');
        _index.pop();
        // Pop the last item, which is the index of deleting node, and delete based on ID in next steps
        // _index will be array of fields which is delete item is part of.
        const items = get(copy, _index); // Get nested or direct fields based on generated index
        set(
          copy,
          _index,
          remove(items, (item) => (item as Item)?.id !== id) // Remove the item based on ID
        );
      }
      setFields(copy);
    },
    [fields]
  );

  const createNestedLanguage = (item: QueryItem) => {
    // Recusrive function to generate language for nested fields
    const language: Language = { [item.condition]: [] };
    item?.items?.forEach((conditon) => {
      if (Object.hasOwn(conditon, 'condition')) {
        const _item = { ...conditon } as QueryItem;
        const _lang = createNestedLanguage(_item);
        language[item.condition].push(_lang);
        return;
      }
      if (Object.hasOwn(conditon, 'id')) {
        const _item = { ...conditon } as Item;
        if (!_item['0'] || !_item['1'] || !_item['2']) {
          sendErrorNotification({
            message: 'Error',
            description: 'Not a valid schema, check again',
          });
          return;
        }
        const conditionLang = {
          [_item['0']]: { [_item['1']]: _item[2] },
        };
        language[item.condition].push(conditionLang);
      }
    });

    return language;
  };

  const createLanguage = () => {
    // Create the language for Query Fields
    const condition = fields.condition;
    const language: Language = { [condition]: [] };
    fields?.items?.forEach((item) => {
      if (Object.hasOwn(item, 'condition')) {
        // This is a nested condition!
        const _item = { ...item } as QueryItem;
        const nestedLanguage = createNestedLanguage(_item); // Loop thorugh nested conditions
        language[condition].push(nestedLanguage);
        return;
      }
      if (Object.hasOwn(item, 'id')) {
        // This is a direct node (condition)
        const _item = { ...item } as Item;
        const conditionLang = {
          [_item['0'] ?? '']: { [_item['1'] ?? '']: _item[2] ?? '' },
        }; // Based Language Schema
        language[condition].push(conditionLang);
      }
    });
    onComplete?.(language); // Save the total language into Node's Data field
    setModal(false);
  };

  const fieldNodes = useCallback(() => {
    return getFields({
      fields,
      onClickAdd,
      onDelete,
      onChange,
      updateCondition,
    });
  }, [fields, onChange, onClickAdd, onDelete, updateCondition]);

  return (
    <>
      <Button onClick={() => setModal(true)} appearance="transparent">
        Edit Condition
      </Button>
      <Modal
        title="Edit Condition"
        open={modal}
        onCancel={() => setModal(false)}
        width={800}
        onOk={createLanguage}
        style={{ height: 400 }}
      >
        <Container>
          <FieldContainer
            fields={fieldNodes()}
            onClickAdd={onClickAdd}
            setCondition={(value) => {
              let copy = { ...fields };
              copy = {
                condition: value,
                items: copy?.items ?? [],
              };
              setFields(copy);
            }}
            condition={fields?.condition}
          />
        </Container>
      </Modal>
    </>
  );
};

const defaultConditionValue = {
  $or: [],
};

export const ConditionQueryEditor = memo(({ onComplete, value }: Props) => {
  const [modal, setModal] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    setInputValue(JSON.stringify(value, null, '\t'));
  }, [value]);

  const onSave = () => {
    if (typeof inputValue === 'string') {
      try {
        const parsedValue = JSON.parse(inputValue);
        if (parsedValue) {
          onComplete?.(parsedValue);
          setModal(false);
        }
      } catch (e) {
        sendErrorNotification({
          message: 'Error',
          description: 'Not a valid schema, check again',
        });
      }
      return;
    }
  };
  return (
    <>
      <Button onClick={() => setModal(true)} appearance="transparent">
        Edit Condition
      </Button>
      <Modal
        title="Edit Condition"
        open={modal}
        onCancel={() => setModal(false)}
        width={800}
        onOk={onSave}
        style={{ height: 400 }}
      >
        <Container>
          <Editor
            defaultLanguage="json"
            language="json"
            defaultValue={'{}'}
            className="json_editor"
            onChange={(newValue) => {
              if (newValue) {
                setInputValue(newValue);
              }
            }}
            options={{
              hideCursorInOverviewRuler: false,
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 16,
            }}
            value={inputValue}
            height={'40vh'}
          />
        </Container>
      </Modal>
    </>
  );
});
