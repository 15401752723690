import { Button, Center, FullCenter, Show, Spin } from '@comet/blocks';
import WelcomePage from '@comet/pages/Organisation/WelcomePage';
import { useMemo } from 'react';
import { ProjectDetails } from './ProjectDetails';
import { useGetProjectQuery } from '../service';
import { useParams } from '@comet/router';
import { Result } from 'antd';

export const Dashboard = () => {
  const { organisationId, projectId } = useParams();
  const { data, isLoading, isError, refetch } = useGetProjectQuery(
    organisationId,
    projectId
  );

  const stepsStatus = Object.values(data?.onboarding || {});

  const isAllStepsComplete = useMemo(
    () => stepsStatus.every((step) => step) && stepsStatus.length === 4,
    [stepsStatus]
  );

  if (isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <>
      <Show if={!isAllStepsComplete}>
        <WelcomePage stepsStatus={stepsStatus} />
      </Show>
      <Show if={isAllStepsComplete}>
        <ProjectDetails stepsStatus={stepsStatus} data={data} />
      </Show>
    </>
  );
};
