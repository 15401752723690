import { RuleRender } from 'antd/es/form';
import { useCallback } from 'react';

export const useEnvironmentFieldsValidator = () => {
  const environmentTypeFieldValidator: RuleRender = useCallback(
    ({ getFieldValue }) => ({
      validator(_, value) {
        const myfields = getFieldValue('fields');
        if (
          !value ||
          myfields.filter(
            (f: { type: string }) =>
              f.type === value && ['DEVELOPMENT', 'STAGING'].includes(f.type)
          ).length <= 1
        ) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error(
            `Multiple ${value.toLowerCase()} type environment cannot be created!`
          )
        );
      },
    }),
    []
  );

  const environmentNameFieldValidator: RuleRender = useCallback(
    ({ getFieldValue }) => ({
      validator(_, value) {
        const myfields = getFieldValue('fields');
        if (
          !value ||
          myfields.filter((f: { name: string }) => f?.name === value).length <=
            1
        ) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Duplicate names are not allowed!'));
      },
    }),
    []
  );

  const sharedEnvironmentFieldValidator: RuleRender = useCallback(
    ({ getFieldValue }) => ({
      validator(_, value) {
        const myfields = getFieldValue('fields');
        if (
          !value ||
          myfields.filter((f: { name: string }) => f.name === value).length > 0
        ) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('This environment name is not present in the list!')
        );
      },
    }),
    []
  );

  return {
    environmentTypeFieldValidator,
    environmentNameFieldValidator,
    sharedEnvironmentFieldValidator,
  };
};
