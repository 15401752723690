export const StatusOptions = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: 'DRAFT',
    value: 'DRAFT',
  },
  {
    label: 'INACTIVE',
    value: 'INACTIVE',
  },
  {
    label: 'DEPLOYED',
    value: 'ACTIVE',
  },
];

export const MethodOptions = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
  {
    label: 'PATCH',
    value: 'PATCH',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
  },
];

export const SortByOptions = [
  {
    label: 'Updated On',
    value: 'updatedOn',
  },
  {
    label: 'Updated By',
    value: 'updatedBy',
  },
];

export const APIListingStateMapping = {
  DRAFT_INACTIVE: ['Draft as Inactive', 'palette.neutral.light'],
  DRAFT: ['Saved as draft', 'palette.neutral.light'],
  DEPLOYED: ['Deployed', 'palette.accent.blue.light'],
  INACTIVE: ['Inactive', '#D16E4E'],
};
