import { css, styled } from '@comet/styled';
import { AiFillApple } from 'react-icons/ai';
import { TiFlowMerge } from 'react-icons/ti';
import { CometNodeBaseProps } from '../../Nodes';
import { MdLoop } from 'react-icons/md';

const getNodeStyles = (type: CometNodeBaseProps['type']) => {
  switch (type) {
    case 'LOOP_NODE':
    case 'SWITCH_NODE':
    case 'IF_NODE': {
      return css`
        background: #f3ecab;
      `;
    }
    default: {
      return css`
        background: ${({ theme }) => theme.palette.accent.green.light};
      `;
    }
  }
};

const IconContainer = styled.div<{
  type: CometNodeBaseProps['type'];
}>`
  ${({ type }) => getNodeStyles(type)}
  border-radius: 4px;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  inset: 0;
  top: -16px;
  z-index: 1;
`;

const getIcon = (type: CometNodeBaseProps['type']) => {
  switch (type) {
    case 'IF_NODE':
    case 'SWITCH_NODE':
    case 'IF_NODE_V2': {
      return TiFlowMerge;
    }
    case 'LOOP_NODE': {
      return MdLoop;
    }
    default: {
      return AiFillApple;
    }
  }
};

export const ActionNodeIcon = ({
  type,
}: {
  type: CometNodeBaseProps['type'];
}) => {
  const Icon = getIcon(type);

  return (
    <IconWrapper>
      <IconContainer type={type}>
        <Icon />
      </IconContainer>
    </IconWrapper>
  );
};
