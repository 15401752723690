import {
  AddCaseNode,
  AddNode,
  BaseNode,
  ExecutorNode,
  IfNode,
  IfV2Node,
  RootNode,
  SwitchNode,
  SwitchV2Node,
} from './nodes';

import { CustomEdge } from './edges';
import { LoopNode } from './nodes/LoopNode';
import { NodeTypeEnum } from './types';

export const NODE_WIDTH = 200;
export const NODE_HEIGHT = 100;

export const ROOT_ID = '1'; // Always 1, HTTP Trigger

export const nodesTypeComponentsMapping = {
  [NodeTypeEnum.AddNode]: AddNode,
  [NodeTypeEnum.RootNode]: RootNode,
  [NodeTypeEnum.BaseNode]: BaseNode,
  [NodeTypeEnum.IfElse]: IfNode,
  [NodeTypeEnum.IfElseV2]: IfV2Node,
  [NodeTypeEnum.SwitchNode]: SwitchNode,
  [NodeTypeEnum.SwitchV2Node]: SwitchV2Node,
  [NodeTypeEnum.LoopNode]: LoopNode,
  [NodeTypeEnum.ExecutorNode]: ExecutorNode,
  [NodeTypeEnum.AddCaseNode]: AddCaseNode,
};

export const edgesTypeComponentMapping = {
  custom: CustomEdge,
};
