import {
  GetStorageAccountParams,
  GetStorageAccountsResponse,
  StorageAccountUpdateFormData,
  StorageRawModel,
} from '../types';
import { queryClient, useMutation, useQuery } from '@comet/query';

import { GB_TO_BYTES } from '../constants';
import { getAxios } from '@comet/axios';
import { notification } from 'antd';
import { useNavigate } from '@comet/router';
import { useParams } from 'react-router-dom';
import { sendErrorNotification } from 'src/blocks/Notification';
import { AnalyzerForm } from '@comet/pages/FullTextSearch/Analyzer/CreateAnalyzerForm';
import { VerifyOTPResponse } from '@comet/pages/Project/Project.types';

export const useGetStorageAccountsQuery = (
  projectId: string,
  params?: GetStorageAccountParams
) => {
  return useQuery({
    queryKey: ['useGetStorageAccountsQuery', projectId, params],
    queryFn: async () => {
      const data = await getStorageAccounts(projectId, params);
      return data;
    },
  });
};

export const getStorageAccounts = async (
  projectId: string,
  params?: GetStorageAccountParams
) => {
  const url = `resources/${projectId}/storage-accounts`;
  const { data } = await getAxios().get<GetStorageAccountsResponse>(url, {
    params,
  });
  return data;
};

export const deleteStorageAccount = async (
  projectId: string | undefined,
  storageAccountId: string | undefined
) => {
  const url = `/resources/${projectId}/storage-accounts/${storageAccountId}`;
  const response = await getAxios().delete(url);
  queryClient.refetchQueries(['useGetStorageAccountsQuery', projectId]);
  return response;
};

export const useCreateStorageMutation = () => {
  const { organisationId, projectId } = useParams();
  const navigate = useNavigate();
  const url = `resources/${projectId}/storage-accounts`;
  return useMutation({
    mutationFn: async (storageData: StorageRawModel) => {
      const spaceInGb = storageData.spaceInBytes;
      const { data } = await getAxios().post(url, {
        ...storageData,
        spaceInBytes: spaceInGb * GB_TO_BYTES,
        organisationId: organisationId,
      });
      return data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['useGetStorageAccountsQuery', projectId]);
      navigate(
        'organisations.organisationId.projects.projectId.storageAccounts'
      );
      notification.success({
        message: 'Success',
        description: 'Bucket Created Successfully',
      });
    },
    onError: (e: any) => {
      sendErrorNotification({
        message: 'Error',
        description: e.response.data.message || 'Unable to create Bucket',
        reportBug: true,
      });
    },
  });
};

const checkStorageAcountName = (
  url: string,
  environmentId: string | undefined,
  name: string
) => {
  return getAxios()
    .get<{ exists: boolean }>(url, {
      params: { name, environment_id: environmentId },
    })
    .then(({ data }) => data);
};

export const useStorageAccountExistsQuery = (
  name: string,
  environmentId: string | undefined
) => {
  const { projectId } = useParams();
  const url = `resources/${projectId}/storage-accounts/exists`;
  return useQuery({
    enabled: false,
    queryKey: ['useStorageAccountExistsQuery', environmentId, name, projectId],
    queryFn: async () => {
      return await checkStorageAcountName(url, environmentId, name);
    },
  });
};

export const useStorageAccountQuery = (
  projectId: string,
  storageAccountId: string
) => {
  return useQuery({
    queryKey: ['useStorageAccountQuery', projectId, storageAccountId],
    queryFn: async () => {
      return getStorageAccount(projectId, storageAccountId);
    },
  });
};

export const getStorageAccount = (
  projectId: string,
  storageAccountId: string
) => {
  const url = `/resources/${projectId}/storage-accounts/${storageAccountId}`;
  return getAxios()
    .get<StorageAccount>(url)
    .then(({ data }) => data);
};

export const useStorageAccountMutation = () => {
  const { projectId, storageAccountId } = useParams();
  const navigate = useNavigate();
  const url = `resources/${projectId}/storage-accounts/${storageAccountId}`;
  return useMutation({
    mutationFn: async (storageData: StorageAccountUpdateFormData) => {
      const spaceInGb = storageData.spaceInBytes;
      const { data } = await getAxios().patch(url, {
        spaceInBytes: spaceInGb * GB_TO_BYTES,
      });

      return data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['useGetStorageAccountsQuery', projectId]);
      navigate(
        'organisations.organisationId.projects.projectId.storageAccounts'
      );
      notification.success({
        message: 'Success',
        description: 'Bucket Updated Successfully',
      });
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to update Bucket',
        reportBug: true,
      });
    },
  });
};

export const validateStorageAccountSize = (
  rule: any,
  value: number
): Promise<void> => {
  if (value < 0 || value > 1024) {
    return Promise.reject('The Space should be between 0 and 1024');
  }
  return Promise.resolve();
};

export const sendOTPToDeleteSA = async (projectId: string, saId: string) => {
  const url = `/resources/${projectId}/storage-accounts/${saId}/delete/send-otp`;

  return getAxios().get(url);
};

export const resendOTPToDeleteSA = async (projectId: string, saId: string) => {
  const url = `/resources/${projectId}/storage-accounts/${saId}/delete/resend-otp`;

  return getAxios().get(url);
};

export const verifyOTPToDeleteSA = async (
  projectId: string,
  saId: string,
  otp: string
) => {
  const url = `/resources/${projectId}/storage-accounts/${saId}/delete/verify-otp?otp=${otp}`;

  return getAxios().get<VerifyOTPResponse>(url);
};
