interface OptionsLabelType {
  [key: string]: string;
}

export const OptionsLabel: OptionsLabelType = {
  // datatype
  STRING: 'String',
  INTEGER: 'Integer',
  FLOAT: 'Float',
  BOOLEAN: 'Boolean',
  OBJECT: 'Object',
  ARRAY: 'Array',
  OBJECT_ID: 'Object ID',

  //string
  TO_TITLE: 'To Title Case',
  TO_UPPER: 'To Upper Case',
  TO_LOWER: 'To Lower Case',

  //datetime
  ISO_DATETIME: 'ISO Datetime',
  EPOCH_SECOND: 'Epoch Seconds',
  EPOCH_MILLISECOND: 'Epoch Milliseconds',

  //sort order
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending',

  //api request type
  GET: 'Get',
  POST: 'Post',
  PUT: 'Put',
  DELETE: 'Delete',
  PATCH: 'Patch',

  //api auth type
  API_KEY: 'API Key',
  No_AUTH: 'No Auth',
  BASIC_AUTH: 'Basic Auth',
  DIGEST_AUTH: 'Digest Auth',
  BEARER_TOKEN: 'Bearer Token',
  NO_AUTH: 'No Auth',

  //aggregation response types
  GET_ARRAY: 'Get response as array',
  GET_OBJECT: 'Get response as object',

  //vetor models
  GEMINI_AI: 'Gemini - text-embedding-004',

  //status codes
  200: '200 - OK',
  201: '201 - Created',
  202: '202 - Accepted',
  204: '204 - No Content',
  400: '400 - Bad Request',
  401: '401 - Unauthorized',
  403: '403 - Forbidden',
  404: '404 - Not Found',
  409: '409 - Conflict',
  500: '500 - Internal Server Error',
};
