import { Flex, Form } from '@comet/blocks';
import { Steps, notification } from 'antd';
import { useEffect, useState } from 'react';
import { SecretTypes, StepsArray } from '../constants';
import { queryClient, useMutation } from '@comet/query';
import { SecretsRawData } from '../Types';
import { createSecretQuery, getSecret } from '../services/Secrets.service';
import { SelectStep } from './CreateSecretSteps/SelectStep';
import { ConfigureStep } from './CreateSecretSteps/ConfigureStep';
import { useGoBack, useNavigate, useParams } from '@comet/router';
import { sendErrorNotification } from 'src/blocks/Notification';

interface CreateSecretTypes {
  environmentId: string;
}

export const CreateSecretForm = ({ environmentId }: CreateSecretTypes) => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [secretType, setSecretType] = useState(SecretTypes.DATABASE_SECRET);
  const next = () => {
    setStep(step + 1);
  };
  const prev = () => {
    setStep(step - 1);
  };

  const { projectId } = useParams();
  const goBack = useGoBack();
  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldValue('environmentId', environmentId);
  }, [environmentId, form]);

  const checkStatus = (id: string) => {
    const interval = setInterval(async () => {
      const { status } = await getSecret(projectId, 'env', id);

      if (status === 'ACTIVE') {
        setLoading(false);
        clearInterval(interval);
        notification.success({
          message: 'Success',
          description: 'Successfully connected to MongoDB',
          duration: 10,
        });

        queryClient.refetchQueries({
          queryKey: ['useGetSecretsQuery', projectId, environmentId],
        });

        navigate(
          'organisations.organisationId.projects.projectId.secrets.secretId.details',
          {
            projectId,
            secretId: id,
            environmentId,
          }
        );
      } else if (status === 'FAILED' || !status) {
        clearInterval(interval);
        setLoading(false);
        sendErrorNotification({
          message: 'Error',
          description:
            'Could not connect to MongoDB. Please check your input values.',
          redirectUrl:
            'https://docs.cosmocloud.io/cosmocloud-documentation/setup/4.-connect-your-database',
        });
      }
    }, 2500);
  };

  const onSuccess = (id: string, status: string) => {
    if (status === 'ACTIVE') {
      notification.success({
        message: 'Success',
        description: 'Secret created successfully',
        duration: 10,
      });
      queryClient.refetchQueries({
        queryKey: ['useGetSecretsQuery', projectId, environmentId],
      });

      navigate(
        'organisations.organisationId.projects.projectId.secrets.secretId.details',
        {
          projectId,
          secretId: id,
          environmentId,
        }
      );
      return;
    }
    setLoading(true);
    notification.info({
      message: 'Message',
      description:
        'Attempting to connect to MongoDB for setup. This may take about a minute. Please hold on...',
      duration: 45,
      closeIcon: false,
    });

    checkStatus(id);
  };

  const createSecretMutation = useMutation({
    mutationFn: (modelData: SecretsRawData) => {
      const environmentId = form.getFieldValue('environmentId');
      return createSecretQuery(projectId, environmentId, modelData);
    },
    onSuccess: ({ id, status }) => onSuccess(id, status),
    onError: () =>
      sendErrorNotification({
        message: 'Error',
        description:
          'Could not connect to MongoDB. Please check your input values.',
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/setup/4.-connect-your-database',
      }),
  });

  const handleFinish = (data: SecretsRawData) => {
    let createSecretData;

    if (data.type === SecretTypes.DATABASE_SECRET) {
      createSecretData = {
        name: data.name,
        type: data.type,
        data: data.data,
      };
    } else if (data.type === SecretTypes.AUTHENTICATION_SECRET) {
      const tokenArray = (form.getFieldValue('tokenData') || []).map(
        (item: { key: string; value: string }) => {
          return { key: item.key, tokenType: item.value };
        }
      );
      createSecretData = {
        name: data.name,
        type: data.type,
        data: { ...data.data, tokenData: tokenArray },
      };
    } else {
      // Custom Secret
      const secretObject = form
        .getFieldValue('secretData')
        .reduce(
          (
            result: Record<string, string>,
            item: { key: string; value: string }
          ) => {
            result[item.key] = item.value;
            return result;
          },
          {} as Record<string, string>
        );

      createSecretData = {
        name: data.name,
        type: data.type,
        data: secretObject,
      };
    }

    createSecretMutation.mutate(createSecretData);
    return;
  };

  return (
    <Flex
      style={{
        flexFlow: 'row wrap',
      }}
    >
      <Steps
        size="small"
        current={step}
        items={StepsArray}
        style={{ marginBottom: 20 }}
      />
      <Form
        layout="vertical"
        style={{ width: '100%' }}
        autoComplete="false"
        onFinish={handleFinish}
        form={form}
      >
        <Flex direction={'column'} hidden={step !== 0}>
          <SelectStep
            next={next}
            onCancel={goBack}
            setSecretType={setSecretType}
            form={form}
          />
        </Flex>
        <Flex direction={'column'} hidden={step !== 1}>
          <ConfigureStep
            prev={prev}
            secretType={secretType}
            loading={loading}
          />
        </Flex>
      </Form>
    </Flex>
  );
};
