import {
  Alert,
  Button,
  Center,
  Flex,
  FullCenter,
  Show,
  Spin,
  Table,
  Text,
  usePagination,
} from '@comet/blocks';
import { Empty, Result, Tooltip, notification } from 'antd';
import {
  useGetEnvironmentsQuery,
  useUpdateSubscriptionMutation,
} from '../service';
import { useNavigate, useParams } from '@comet/router';

import EmptyImage from '@comet/assets/png/empty_list.png';
import { ExternalLink } from '@comet/components/ExternalLink';
import { GetEnvironmentsParams } from '../types';
import { PAGE_SIZE } from '../constants';
import { TableContainer } from '../../Project.styled';
import { useEnvironmentsListingData } from './EnvironmentList.util';
import { useGetStorageAccountsQuery } from '../../StorageAccounts/service';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useSubscriptionStatusPolling } from '../utils/useSubscriptionStatusPolling';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

export const Environments = () => {
  const { pageOffset, currentPageIndex, fetchData } = usePagination();
  const { organisationId, projectId } = useParams();
  const params: GetEnvironmentsParams = {
    offset: pageOffset,
  };

  const navigate = useNavigate();
  const {
    data: environmentsData,
    isLoading,
    error,
    refetch: refetchEnvironments,
  } = useGetEnvironmentsQuery(projectId, params);

  const { tableColumns, tableData } = useEnvironmentsListingData(
    environmentsData,
    refetchEnvironments
  );

  const updateSubscriptionMutation = useUpdateSubscriptionMutation();
  const isError = error || !environmentsData;
  const showList = !isLoading && !isError && environmentsData.data.length > 0;
  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const { refetch: refetchStorageAccount } =
    useGetStorageAccountsQuery(projectId);

  const {
    alertType,
    isCreateSubscriptionBanner,
    isDraftSubscriptionBanner,
    isUnpaidSubscriptionBanner,
    isUpdatingSubscriptionBanner,
    showBanner,
  } = useSubscriptionStatusPolling({
    isSubscriptionUpdateSuccess: updateSubscriptionMutation.isSuccess,
    refetchEnvironments: refetchEnvironments,
    refetchStorageAccount: refetchStorageAccount,
  });

  const { data: subscriptionData } = useGetOrgSubscriptionQuery(organisationId);

  if (isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetchEnvironments()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={14}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Show if={showBanner}>
        <Alert
          message={
            <Text
              appearance="label.long.regular"
              color="neutral.darkest"
              style={{ padding: '4px 12px' }}
            >
              {isCreateSubscriptionBanner &&
                'You can have only 1 environment on Free Tier'}
              {isDraftSubscriptionBanner &&
                'You have draft changes in your project environments, please update your subscription details to activate changes.'}

              {isUnpaidSubscriptionBanner && (
                <Flex gap={4}>
                  You have unpaid subscription in your project enviornments,
                  please{' '}
                  <ExternalLink
                    href={process.env.REACT_APP_ZOHO_CUSTOMER_PORTAL_URL || ''}
                    appearance="caption.semibold"
                  >
                    complete your payment
                  </ExternalLink>
                  to activate changes.
                </Flex>
              )}

              {isUpdatingSubscriptionBanner &&
                'Your subscription is updating, please wait. Do not refresh your screen.'}
            </Text>
          }
          type={alertType}
          showIcon={false}
        />
      </Show>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">Environments</Text>
        </Flex>
        <Tooltip
          title={
            hasManageProjectSettingsAccess
              ? ''
              : 'Please check if you have enough permissions to create environment.'
          }
        >
          <Button
            type="primary"
            onClick={() =>
              navigate(
                'organisations.organisationId.projects.projectId.environments.new'
              )
            }
            disabled={
              subscriptionData?.planCode === 'FREE' || !subscriptionData?.active
            }
          >
            Create Environment
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Show if={isLoading}>
          <FullCenter>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && environmentsData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Environments found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="environments"
            currentPageIndex={currentPageIndex}
            totalRows={environmentsData?.page.total || 0}
            totalPageCount={Math.ceil(
              (environmentsData?.page.total || 0) / PAGE_SIZE
            )}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
