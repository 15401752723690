import { styled, font, textColor } from '@comet/styled';
import { ComponentPropsWithoutRef } from 'react';

const TextBase = styled.p`
  margin: 0;
`;

export interface TextProps extends ComponentPropsWithoutRef<'p'> {
  appearance: keyof typeof font;
  color?: keyof typeof textColor;
}

export const Text = ({
  appearance,
  style: propStyle,
  color,
  ...props
}: TextProps) => {
  const { style, tag } = font[appearance];
  const colorStyle = color ? textColor[color] : {};

  return (
    <TextBase
      as={tag}
      style={{ ...propStyle, ...style, ...colorStyle }}
      {...props}
    />
  );
};
