const add_to_properties = (
  properties: any[],
  name: string,
  type: string,
  subType: string | null = null,
  children: any[] | null = []
) => {
  if (type === 'LIST' && subType !== 'NESTED') {
    children = null;
  }
  properties.push({
    id: crypto.randomUUID(),
    name: name,
    type: type,
    subType: subType,
    properties: children,
    required: false,
    default: null,
    defaultFunction: null,
  });
  return properties;
};

const validateListSubType = (
  arr: any[]
): { elemType: string | null; valid: boolean } => {
  const elemType = typeof arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (typeof arr[i] !== elemType) {
      return { elemType: null, valid: false };
    }
  }
  return { elemType, valid: true };
};

const checkInteger = (num: number) => {
  return Number.isInteger(num) ? 'NUMBER' : 'FLOAT';
};

export const transformJSONtoCosmocloudModel = (
  obj: any,
  updateError: (error: string) => void
): any[] => {
  let properties: any[] = [];

  Object.keys(obj).forEach((key) => {
    switch (typeof obj[key]) {
      case 'string':
        properties = add_to_properties(properties, key, 'STRING');
        break;
      case 'number':
        properties = add_to_properties(properties, key, checkInteger(obj[key]));
        break;
      case 'boolean':
        properties = add_to_properties(properties, key, 'BOOLEAN');
        break;
      case 'object':
        if (Array.isArray(obj[key])) {
          // Handling Arrays
          let { elemType } = validateListSubType(obj[key]);
          const { valid } = validateListSubType(obj[key]);
          if (!valid) {
            updateError(obj[key] + ' Array contains items of different types');
            throw new Error('Invalid types in array');
          } else {
            let children = [];
            let subType = 'Not Available';
            //Nested elements
            if (elemType === 'object' && !Array.isArray(obj[key][0])) {
              elemType = 'LIST';
              subType = 'NESTED';
              children = transformJSONtoCosmocloudModel(
                obj[key][0],
                updateError
              );
            } else {
              elemType = 'LIST';
              subType = Number.isNaN(obj[key][0])
                ? typeof obj[key][0]
                : Number(obj[key][0]) === obj[key][0] && obj[key][0] % 1 !== 0
                ? 'FLOAT'
                : 'NUMBER';
            }

            if (subType !== 'Not Available') {
              properties = add_to_properties(
                properties,
                key,
                elemType,
                subType.toUpperCase(),
                children
              );
            } else {
              properties = add_to_properties(
                properties,
                key,
                elemType,
                null,
                children
              );
            }
          }
        } else {
          // Handling Dicts
          const children = transformJSONtoCosmocloudModel(
            obj[key],
            updateError
          );
          properties = add_to_properties(
            properties,
            key,
            'NESTED',
            null,
            children
          );
        }
        break;
    }
  });
  return properties;
};

export const getJson = (fields: any) => {
  return (
    fields?.reduce((result: any, currField: any) => {
      if (currField?.typeList) {
        currField['type'] = currField?.typeList[0];
        if (currField.typeList.length > 1) {
          currField['subType'] = currField?.typeList[1];
        } else {
          currField['subType'] = null;
        }
      }
      if (currField?.type === 'NESTED') {
        result[currField?.name || ''] = getJson(currField?.properties || []);
      } else if (currField?.subType === 'NESTED') {
        result[currField?.name || ''] = getJson(currField?.properties || []);
      } else {
        result[currField?.name || ''] = currField?.type || '';
      }

      return result;
    }, {}) || {}
  );
};
