import { Button, Flex, Paper, Text } from '@comet/blocks';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { useParams } from '@comet/router';
import { useState } from 'react';
import { sendErrorNotification } from 'src/blocks/Notification';
import { checkDeletePossible, useGetProjectQuery } from '../service';
import DeleteProjectModal from './DeleteProjectModal';

export const Settings = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const { projectId, organisationId } = useParams();
  const { data: projectData } = useGetProjectQuery(organisationId, projectId);

  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const { hasViewProjectAccess } = useOrgPermissions();

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  if (!hasViewProjectAccess) {
    return <ErrorPage />;
  }

  const handleOnClick = async () => {
    const canBeDeleted = await checkDeletePossible(projectId, organisationId);
    if (canBeDeleted) {
      toggleModal();
    } else {
      sendErrorNotification({
        message: 'Pending bills',
        description:
          'You have active subscription, contact support to delete the project',
        redirectUrl: 'https://cal.com/shreybatra/upgrading-cosmocloud',
        duration: 7,
      });
    }
  };

  return (
    <>
      <Paper size="medium">
        <Flex direction="column" gap={20}>
          <Flex gap={10} direction="column">
            <Text appearance="heading.card">Delete Project </Text>
            <Text appearance="label.short.regular">
              Remove this project from your account in a single click - no
              hassle, no fuss.
            </Text>
          </Flex>
          <Flex justifyContent="end">
            <Button
              onClick={handleOnClick}
              appearance="alert"
              disabled={!hasManageProjectSettingsAccess}
            >
              {' '}
              Delete
            </Button>
          </Flex>
        </Flex>
      </Paper>
      {isConfirm && !!projectData && (
        <DeleteProjectModal
          projectData={projectData}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};
