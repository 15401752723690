export const rolesOptions = [
  {
    label: 'Business Executive (CEO, COO, CMO, etc.)',
    value: 'BUSINESS_EXECUTIVE',
  },
  {
    label: 'IT Executive (CTO, CIO, VP Engineering, etc.)',
    value: 'IT_EXECUTIVE',
  },
  {
    label: 'Director of Engineering',
    value: 'DIRECTOR_DEVELOPMENT_MANAGER',
  },
  { label: 'Software Architect', value: 'SOFTWARE_ARCHITECT' },
  { label: 'Product Manager', value: 'PRODUCT_PROGRAM_MANAGER' },

  { label: 'Database Administrator (DBA)', value: 'DBA' },
  { label: 'Software Developer', value: 'SOFTWARE_DEVELOPER' },
  { label: 'Data Scientist', value: 'DATA_SCIENTIST' },
  { label: 'Business Analyst', value: 'BUSINESS_ANALYST' },
  { label: 'Student', value: 'STUDENT' },
  { label: 'Other', value: 'OTHER' },
];
