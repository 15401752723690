import { FC } from 'react';
import { Link, useNavigate } from '@comet/router';
import { Button, Form, FormItem, Input, Typography } from '@comet/blocks';
import { signInWithGoogle, signUp } from '@comet/amplify';
import { FcGoogle } from 'react-icons/fc';
import { TryProductBanner } from '@comet/components/TryProductBanner/TryProductBanner';
import {
  AuthPageContainer,
  AuthSection,
  ToS,
  StyledText,
  Header,
} from './common';
import { Flex } from 'src/blocks/Flex';
import { styled, useTheme } from '@comet/styled';
import { emailValidator, usernameValidator } from './Auth.utils';
import { ReactComponent as CosmoCloud } from '@comet/assets/svg/cosmocloud_logo.svg';

interface IFormValues {
  username: string;
  email: string;
  password: string;
  name: string;
}

const StyledTitle = styled(Typography.Title)`
  padding-bottom: 24px;
`;

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

export const FormBox = styled.div`
  width: 80%;
  height: 100%;
  margin-top: 20px;
  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    margin-top: 60px;
    width: 368px;
  }`}
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

const SignUp: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    theme: { palette },
  } = useTheme();

  const handleOnSubmit = async (formValues: IFormValues) => {
    const { username, email, password, name } = formValues;
    form.setFields([
      {
        name: 'password',
        errors: [],
      },
      {
        name: 'username',
        errors: [],
      },
    ]);

    try {
      await signUp(username, email, password, name);

      // Navigate to login page to initiate login flow
      navigate('signIn');
    } catch (error: any) {
      switch (error.code) {
        case 'InvalidPasswordException': {
          form.setFields([
            {
              name: 'password',
              errors: [
                'Add a strong password with a atleast 1 uppercase, 1 lowercase, 1 digit and 1 special char. Min 8 character length.',
              ],
            },
          ]);
          break;
        }
        case 'InvalidParameterException': {
          form.setFields([
            {
              name: 'username',
              errors: ['Username cannot be of email format'],
            },
          ]);
          break;
        }
        case 'UsernameExistsException': {
          form.setFields([
            {
              name: 'username',
              errors: ['Username already exists'],
            },
          ]);
          break;
        }

        default:
          break;
      }
    }
  };

  return (
    <AuthPageContainer>
      <Header>
        <CosmoCloud />
      </Header>
      <AuthSection>
        <FormBox>
          <Flex direction="column" justifyContent="center" width="100%">
            <StyledTitle level={1}>Sign up</StyledTitle>

            <Form form={form} layout="vertical" onFinish={handleOnSubmit}>
              <FormItem name="name" label="Name" marginBottom={24}>
                <Input placeholder="Name" />
              </FormItem>
              <FormItem
                name="username"
                label="Username"
                marginBottom={24}
                rules={[
                  {
                    validator: usernameValidator,
                  },
                ]}
              >
                <Input placeholder="Username" />
              </FormItem>
              <FormItem
                name="email"
                label="Email"
                marginBottom={24}
                rules={[
                  {
                    validator: emailValidator,
                  },
                ]}
              >
                <Input type="email" placeholder="Email" />
              </FormItem>
              <FormItem name="password" label="Password" marginBottom={48}>
                <Input type="password" placeholder="Password" />
              </FormItem>
              <FormItem marginBottom={16}>
                <Button type="primary" htmlType="submit" block>
                  Create account
                </Button>
              </FormItem>
            </Form>
            <Button
              icon={
                <FcGoogle
                  style={{ marginRight: '8px' }}
                  onClick={signInWithGoogle}
                />
              }
              hidden
            >
              Sign up with Google
            </Button>
            <StyledText style={{ marginTop: '32px' }}>
              Already have an account?
              <StyledLink routeId="signIn">
                <Typography.Text strong style={{ color: palette.primary }}>
                  Log in
                </Typography.Text>
              </StyledLink>
            </StyledText>
            <ToS />
          </Flex>
        </FormBox>
      </AuthSection>
      <TryProductBanner />
    </AuthPageContainer>
  );
};

export default SignUp;
