import { styled } from '@comet/styled';
import { Flex } from '@comet/blocks';

export const Container = styled(Flex)`
  flex-direction: column;
  background: ${(props) => props.theme.palette.neutral.white};
  border: 1px solid ${(props) => props.theme.palette.neutral.light};
  border-radius: ${(props) => props.theme.constants.borderRadius.lg};
`;

export const VersionContainer = styled.div`
  color: #4259bd;
  font-size: 14px;
  line-height: 16px;
  display: flex;
`;
