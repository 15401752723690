import React, { useState } from 'react';
import { css, styled } from '@comet/styled';
import { Flex, Show, Switch } from '@comet/blocks';
import { useSchemaStore } from '@comet/pages/Model/ModelSchema/ModelSchemaStore';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { BsPlus } from 'react-icons/bs';
import { SchemaKeySubType, SchemaKeyType } from './SchemaTree.types';
import KeyTypeDropdown from '@comet/pages/Model/ModelSchema/KeyTypeDropdown';

interface IProps {
  name: string;
  type: SchemaKeyType;
  level: number;
  path: string;
  subType?: SchemaKeySubType;
  required?: boolean;
  disabled?: boolean;
  showBasic?: boolean;
}

const RowContainer = styled(Flex)<{ level: number; disabled?: boolean }>`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  width: 100%;
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.lighter};
  }
`;

const RowText = styled.div`
  color: ${({ theme }) => theme.palette.neutral.darkest};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const RowName = styled(RowText)<{
  required?: boolean;
}>`
  margin-right: 8px;
  position: relative;
  display: flex;

  ${({ required }) =>
    required &&
    css`
      &::after {
        content: '•' !important;
        display: block;
        top: -4px;
        left: 4px;
        margin-inline-end: 0px !important;
        position: relative;
        color: ${({ theme }) => theme.palette.danger} !important;
      }
    `}
`;

const RowInput = styled.input<{ error: boolean }>`
  border: none;
  outline: none;
  width: 100px;
  border-radius: 4px;
  margin-right: 8px;
  padding-left: 8px;

  ${({ error, theme }) =>
    error &&
    `
      border: 1px solid ${theme.palette.danger};
    `}
`;

const AddIcon = styled(BsPlus)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightest};
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
  margin-left: 12px;
`;

const RemoveIcon = styled(RiDeleteBin6Line)`
  cursor: pointer;
  margin-left: 12px;
`;

const SwitchButtonLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
`;

const SwitchButton = styled(Switch)`
  margin-left: 8px;
`;

export default function SchemaRow(props: IProps) {
  const { name, type, level, path, subType, required, disabled, showBasic } =
    props;
  const [isEditing, setIsEditing] = useState(!name);
  const [isErrored, setIsErrored] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const validKeyRegex = /^[a-zA-Z0-9_-]+$/;

  const {
    addProperty,
    removeProperty,
    updatePropertyName,
    updatePropertyRequire,
  } = useSchemaStore(
    ({
      addProperty,
      removeProperty,
      updatePropertyName,
      updatePropertyRequire,
    }) => ({
      addProperty,
      removeProperty,
      updatePropertyName,
      updatePropertyRequire,
    })
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      event.preventDefault();
      setIsEditing(false);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!event.target.value) {
        setIsErrored(true);
        setIsEditing(true);
      }
    }
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    setIsErrored(false);
    if (validKeyRegex.test(value)) {
      updatePropertyName(path, value);
    }
  };

  const handleAddKey = () => {
    addProperty(path);
  };

  const handleRemoveKey = () => {
    removeProperty(path);
  };

  const handleSwitchChange = (isRequired: boolean) => {
    updatePropertyRequire(path, isRequired);
  };

  const handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);

    if (!event.target.value) {
      setIsErrored(true);
      setIsEditing(true);
    }
  };

  return (
    <RowContainer
      level={level}
      alignItems="center"
      justifyContent={'space-between'}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
    >
      <Flex alignItems={'center'}>
        <Show if={isEditing}>
          <RowInput
            value={name}
            onKeyDown={handleKeyDown}
            onChange={handleInput}
            onBlur={handleInputBlur}
            placeholder="Field name"
            error={isErrored}
            autoFocus
          />
        </Show>
        <Show if={!isEditing}>
          <RowName onClick={() => setIsEditing(true)} required={!!required}>
            {name}
          </RowName>
        </Show>
        <KeyTypeDropdown
          type={type}
          path={path}
          subType={subType}
          disabled={disabled}
          showBasic={showBasic}
        />
      </Flex>
      <Flex>
        <Show if={!disabled && isHovered}>
          <SwitchButtonLabel>Required</SwitchButtonLabel>
          <SwitchButton
            size="small"
            checked={required}
            onChange={handleSwitchChange}
          />
        </Show>
        <Show
          if={
            !disabled &&
            isHovered &&
            (type === SchemaKeyType.NESTED ||
              (type === SchemaKeyType.ARRAY &&
                subType === SchemaKeySubType.NESTED))
          }
        >
          <AddIcon onClick={handleAddKey} />
        </Show>
        <Show if={!disabled && isHovered}>
          <RemoveIcon onClick={handleRemoveKey} />
        </Show>
      </Flex>
    </RowContainer>
  );
}
