import {
  BusinessNodeEnum,
  BusinessNodeProps,
} from '@comet/components/FlowEngine/Nodes/BusinessNode';
import {
  FlowEngineState,
  useFlowEngineStore,
} from '@comet/components/FlowEngine/FlowEngine.store';
import { Input, Popover } from 'antd';
import {
  clickAddCaseNode,
  clickDeleteCase,
  clickUpdateCase,
} from 'src/FlowEngine2/Operations/clickOperatons';
import { useEffect, useState } from 'react';
import {
  useOnClickAdd,
  useOnremove,
  useUpdateEdges,
  useUpdateMetadata,
} from './switchNode.utils';

import { AiFillMinusCircle } from 'react-icons/ai';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { shallow } from 'zustand/shallow';
import styled from 'styled-components';

const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  & > * {
    margin-bottom: 10px;
  }
`;

interface InputListProps {
  metadata: any;
  node?: { id: string; data: BusinessNodeProps } | null;
  onChange: (values: any[]) => void;
  state: any[];
}

const selector = (state: FlowEngineState) => ({
  onComplete: state.setGraph,
});

const SWITCH_NODES = [
  BusinessNodeEnum.SWITCH_CASE,
  BusinessNodeEnum.SWITCH_CASE_V2,
];

export const InputList = ({
  metadata,
  onChange,
  state,
  node,
}: InputListProps) => {
  const [inputs, setInputs] = useState(state ?? [{ value: '' }]);
  const { onComplete } = useFlowEngineStore(selector, shallow);

  /**
   * Utilitis(hooks) for switchnode operations
   */
  const onInputAdd = useOnClickAdd(node);
  const onInputRemove = useOnremove(inputs, node);
  const updateMeta = useUpdateMetadata(node);
  const updateEdge = useUpdateEdges(metadata);

  const onAdd = () => {
    if (!node?.data) return;
    if (SWITCH_NODES.includes(node?.data.type)) {
      clickAddCaseNode(node.id);
    }
  };

  const onRemove = (index: number) => {
    if (!node?.data) return;
    if (SWITCH_NODES.includes(node.data.type)) {
      clickDeleteCase(node.id, index);
    }
  };

  const onInput = (value: string, index: number) => {
    if (!node?.data) return;
    if (SWITCH_NODES.includes(node.data.type)) {
      clickUpdateCase(node.id, index, value);
    }
  };

  const onBlur = (value: string, index: number) => {
    if (!node?.data) return;
    if (SWITCH_NODES.includes(node.data.type)) {
      // if (process.env.REACT_APP_FLOW_ENGINE_VERSION === '1') {
      //   updateEdge(inputs, index, value);
      // }
    }
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_FLOW_ENGINE_VERSION === '1' &&
      metadata.length !== inputs.length
    ) {
      if (Array.isArray(metadata)) {
        metadata.forEach((item, index) => {
          const input = inputs[index];

          if (!input) {
            setInputs((inputs) => {
              inputs.push({ key: item.nodeId, value: '' });
              return inputs;
            });
          }
        });
      }
    }
    onChange(inputs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, metadata]);

  return (
    <ListContainer>
      {inputs.map((input, index) => {
        const _meta = metadata?.[index];
        const disabled = !!_meta?.deleted;
        return (
          <Flex width={'100%'} key={index}>
            <Popover open={disabled} title={'Please delete this input'}>
              <Input
                placeholder={`Value ${index + 1}`}
                value={input.value}
                onChange={(e) => {
                  if (process.env.REACT_APP_FLOW_ENGINE_VERSION === '1') {
                    setInputs((inputs) => {
                      inputs[index].value = e.target.value;
                      inputs[index].key = _meta?.nodeId;
                      return inputs;
                    });
                  }
                  onInput(e.target.value, index);
                  onChange(inputs);
                }}
                onBlur={(e) => onBlur(e.target.value, index)}
                disabled={disabled || input.value === 'default'}
              />
            </Popover>
            <Button
              icon={<AiFillMinusCircle size={12} />}
              style={{ marginLeft: 5 }}
              onClick={() => {
                onRemove(index);
                if (process.env.REACT_APP_FLOW_ENGINE_VERSION === '1') {
                  setInputs((inputs) => {
                    inputs.splice(index, 1);
                    return inputs;
                  });
                }
                onChange(inputs);
              }}
              disabled={input.value === 'default'}
            />
          </Flex>
        );
      })}
      <Button
        onClick={() => {
          if (process.env.REACT_APP_FLOW_ENGINE_VERSION === '1') {
            setInputs((inputs) => {
              inputs.push({ value: '' });
              return inputs;
            });
          }
          onAdd();
          onChange(inputs);
        }}
      >
        + Add Value
      </Button>
    </ListContainer>
  );
};
