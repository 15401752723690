import { Flex, Label, Show, Text } from '@comet/blocks';
import {
  AUTHENTICATION_DATA,
  DATABASE_DATA,
  SecretsFormDataType,
} from '../Types';
import { SecretTypes } from '../constants';
import { EditableText } from '@comet/components/Editable/Text';
import { useState } from 'react';
import {
  UpdateAuthentication,
  UpdateCustom,
  UpdateDatabase,
} from './components';
import { DetailsTag } from '@comet/components/common';
import { ScrollDiv } from '../secrets.styled';

interface SecretDetailsProps {
  name: string;
  type: string;
  data: SecretsFormDataType;
  envName: string;
}
export const UpdateSecretForm = ({
  name,
  type,
  data,
  envName,
}: SecretDetailsProps) => {
  const [secretName, setSecretName] = useState(name);
  const [secretType, setSecretType] = useState(type);
  return (
    <ScrollDiv>
      <Flex direction="column" gap={16}>
        <Flex direction="column" gap={10}>
          <Label required={true}>Secret name</Label>
          <EditableText
            name="name"
            type="text"
            value={secretName}
            onSave={setSecretName}
            disabled={true}
          />
        </Flex>
        <Flex direction="column" gap={10}>
          <Label required={true}>Environment</Label>
          <EditableText
            name="envName"
            type="text"
            value={envName}
            disabled={true}
          />
        </Flex>

        <Flex direction="column" gap={10}>
          <Label required={true}>Secret type</Label>
          <Flex hidden={true}>
            <EditableText
              name="type"
              type="text"
              value={secretType}
              onSave={setSecretType}
              disabled={true}
            />
          </Flex>

          <Flex>
            <DetailsTag>
              <Text appearance="caption.allCaps">{type}</Text>
            </DetailsTag>
          </Flex>
        </Flex>

        <Show if={type === SecretTypes.AUTHENTICATION_SECRET}>
          <UpdateAuthentication data={data as AUTHENTICATION_DATA} />
        </Show>

        <Show if={type === SecretTypes.DATABASE_SECRET}>
          <UpdateDatabase data={data as DATABASE_DATA} />
        </Show>
        <Show if={type === SecretTypes.CUSTOM_SECRET}>
          <UpdateCustom data={data as Record<string, string>} />
        </Show>
      </Flex>
    </ScrollDiv>
  );
};
