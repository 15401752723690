import {
  Button,
  ButtonContainer,
  Flex,
  Form,
  FormError,
  FormItem,
  Input,
  InputHelpText,
  Label,
  Select,
  Show,
  Text,
  Spin,
} from '@comet/blocks';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@comet/query';
import { createEnvironment, useEnvironmentExistsQuery } from '../service';
import { getError } from '@comet/axios';
import debounce from 'lodash.debounce';
import { EnvironmentRawModel, EnvironmentTypesEnum } from '../types';
import { FooterDivider } from '../Environment.styled';
import { EnvironmentTypesOptions, ResourceTypesOptions } from '../constants';
import {
  useEnvironmentNameValidator,
  useGetEnvironmentsOptions,
  useGetTiersOptions,
  useGetTiersPricing,
} from '../utils';
import { useNavigate, useParams } from '@comet/router';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import {
  useGetOrgSubscriptionQuery,
  useOrgQuery,
} from '@comet/pages/Organisation/service';
import { useGetProjectQuery } from '../../service';
import { sendErrorNotification } from 'src/blocks/Notification';
import { Pricing } from '@comet/pages/UpgradePlan/AddBulkEnvironment/AddBulkEnvironment.Pricing';

interface CreateEnvironmentFormProps {
  projectId: string;
  onSuccess: (id: string) => void;
  onCancel: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  initialValues?: EnvironmentRawModel;
}

enum CurrencyTypes {
  INR = 'INR',
  USD = 'USD',
}

export const CreateEnvironmentForm = ({
  onSuccess,
  onCancel,
  projectId,
  initialValues,
}: CreateEnvironmentFormProps) => {
  const tiersPricing = useGetTiersPricing();
  const [currency, setCurrency] = useState<CurrencyTypes>(CurrencyTypes.INR);
  const [envType, setEnvType] = useState<string>(EnvironmentTypesEnum.STAGING);
  const [calculatedPrice, setCalculatedPrice] = useState<number>(0);

  useEffect(() => {
    if (tiersPricing['SHARED_0']) {
      setCalculatedPrice(tiersPricing['SHARED_0'][currency]);
    }
  }, [tiersPricing]);

  const locale = currency === 'USD' ? 'en-US' : 'en-IN';
  const displayPrice = Intl.NumberFormat(locale, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 2,
    style: 'currency',
    currency: currency,
  }).format(calculatedPrice);

  const handleChange = (value: string) => {
    setEnvType(value);

    if ([EnvironmentTypesEnum.CUSTOM].includes(value as EnvironmentTypesEnum)) {
      form.setFieldValue('name', '');
    } else {
      form.setFieldValue('name', value.toLowerCase());
    }
  };

  const { organisationId } = useParams();

  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const { data: orgData } = useOrgQuery(organisationId);
  const navigate = useNavigate();

  const { data: subscriptionData } = useGetOrgSubscriptionQuery(organisationId);
  const isFree = subscriptionData?.planCode === 'FREE';

  useEffect(() => {
    if (!hasManageProjectSettingsAccess || isFree)
      navigate('organisations.organisationId.projects.projectId.environments');
  }, [hasManageProjectSettingsAccess, navigate, orgData]);

  const [form] = Form.useForm();
  const tierOptions = useGetTiersOptions();

  const handleFinish = (data: EnvironmentRawModel) => {
    createEnvironmentMutation.mutate(data);
  };

  const createEnvironmentMutation = useMutation({
    mutationFn: (modelData: EnvironmentRawModel) =>
      createEnvironment(projectId, modelData),
    onSuccess: ({ id }) => onSuccess(id),
  });

  const [sharing, setSharing] = useState<boolean>(false);

  // Environment exists check logic start
  const [environmentName, setEnvironmentName] = useState('');
  const [environmentExistsError, setEnvironmentExistsError] = useState('');
  const environmentExistsQuery = useEnvironmentExistsQuery(
    environmentName || '',
    envType
  );

  useEffect(() => {
    setEnvironmentExistsError(
      environmentExistsQuery.data?.exists ? 'Environment already exists' : ''
    );
  }, [environmentExistsQuery.data]);

  const debouncedEnvironmentNameCheck = useRef(
    debounce(environmentExistsQuery.refetch, 500)
  );

  const { environmentsOptions, selectedEnvironment } =
    useGetEnvironmentsOptions();
  const environmentNameValidator = useEnvironmentNameValidator();

  if (!tiersPricing) {
    return <Spin></Spin>;
  }

  return (
    <Flex direction="column" gap={16}>
      <Text appearance="heading.card">Create a new environment</Text>
      <Form
        autoComplete="off"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
      >
        <FormItem
          name="type"
          label="Type"
          required
          rules={[{ required: true, message: 'Environment type is required' }]}
          initialValue={EnvironmentTypesEnum.PRODUCTION}
        >
          <Select
            onChange={handleChange}
            style={{ width: 200 }}
            value={envType}
            options={EnvironmentTypesOptions}
          />
        </FormItem>

        <Flex
          hidden={envType !== EnvironmentTypesEnum.CUSTOM}
          direction="column"
        >
          <FormItem
            name="name"
            label="Environment Name"
            initialValue={'production'}
            required
            rules={[
              {
                required: envType === EnvironmentTypesEnum.CUSTOM,
                message: 'Environment Name is required!',
              },
              environmentNameValidator,
              {
                min: 3,
                max: 16,
                validateTrigger: 'onSubmit',
              },
            ]}
            marginBottom={10}
          >
            <Input
              placeholder="Enter environment name..."
              value={environmentName}
              onChange={(event) => {
                setEnvironmentName(event.target.value);
                if (event.target.value) {
                  debouncedEnvironmentNameCheck.current();
                }
              }}
            />
          </FormItem>
          <InputHelpText
            error={environmentExistsError}
            success={
              !!environmentName &&
              environmentExistsQuery.data?.exists === false &&
              !environmentExistsQuery.isFetching &&
              'Environment Name is available.'
            }
          >
            {environmentExistsQuery.isFetching &&
              'Checking if Environment exists...'}
          </InputHelpText>
        </Flex>

        <Flex>
          <FormItem
            name={['metadata', 'isShared']}
            label="Resource Type"
            required
            initialValue={false}
            rules={[{ required: true, message: 'Resource type is required' }]}
          >
            <Select
              onChange={() =>
                setSharing(form.getFieldValue(['metadata', 'isShared']))
              }
              style={{ width: 200 }}
              value={false}
              options={ResourceTypesOptions}
              disabled
            />
          </FormItem>
        </Flex>

        {/* Resource Sharing  */}
        <Show if={sharing && envType !== EnvironmentTypesEnum.STAGING}>
          <FormItem
            name={['metadata', 'sharedEnvId']}
            label="Share resources with"
            required
            initialValue={selectedEnvironment}
            rules={[
              { required: true, message: 'Resource sharing type is required' },
            ]}
          >
            <Select style={{ width: 200 }} options={environmentsOptions} />
          </FormItem>
        </Show>

        <Show if={!sharing}>
          <FormItem
            name={['metadata', 'tier']}
            label="Select Tier"
            required
            initialValue={'SHARED_0'}
            rules={[{ required: true, message: 'Tier is required' }]}
          >
            <Select
              onChange={(e) => setCalculatedPrice(tiersPricing[e][currency])}
              style={{ width: 200 }}
              options={tierOptions}
            />
          </FormItem>
          <Label>
            <Pricing
              currency={currency}
              setCurrency={setCurrency}
              totalPrice={calculatedPrice}
            />
          </Label>
        </Show>
        <FooterDivider />
        <ButtonContainer>
          <Button onClick={onCancel} appearance="transparent">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </ButtonContainer>
      </Form>
    </Flex>
  );
};
