import { styled } from '@comet/styled';

export interface IFlexProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: React.CSSProperties['flexDirection'];
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  style?: React.CSSProperties;
  children?: React.ReactNode | undefined;
  gap?: number;
  padding?: React.CSSProperties['padding'];
}

const Div = styled.div``;

export const Flex = ({
  direction,
  justifyContent,
  alignItems,
  width,
  height,
  style,
  gap,
  padding,
  ...props
}: IFlexProps) => {
  return (
    <Div
      {...props}
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent,
        alignItems,
        width,
        height,
        gap,
        padding,
        ...style,
      }}
    />
  );
};

type CenterProps = Omit<IFlexProps, 'justifyContent' | 'alignItems'>;
export const Center = (props: CenterProps) => {
  return <Flex {...props} justifyContent="center" alignItems="center" />;
};

type FullCenterProps = Omit<CenterProps, 'width' | 'height'>;
export const FullCenter = (props: FullCenterProps) => {
  return <Center {...props} height="100%" width="100%" />;
};
