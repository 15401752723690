import { Input, Tabs } from '@comet/blocks';
import styled from 'styled-components';

export const CloudProviderRadioWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 110px;
  height: 40px;
  border: ${({ selected, theme }) =>
    selected
      ? `2px solid ${theme.palette.primary}`
      : `2px solid ${theme.palette.neutral.light}`};
  border-radius: 4px;
  cursor: pointer;
  .ant-radio {
    visibility: hidden;
    width: 0;
  }
  span,
  label {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
`;

export const RegionsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
`;

export const NameInput = styled(Input)`
  width: 340px;
`;

export const NameInfo = styled.div`
  color: ${({ theme }) => `${theme.palette.neutral.darker}`};
  font-weight: 500;
  font-size: 12px;
`;

export const RegionTabs = styled(Tabs)`
  width: 100%;
  height: 175px;
  margin-top: -4px;
`;

export const RegionCode = styled.div`
  margin-left: 4px;
  color: ${({ theme }) => `${theme.palette.neutral.darker}`};
`;

export const StyledError = styled.div`
  color: ${({ theme }) => theme.palette.danger};
  font-size: 12px;
  margin-top: 4px;
`;
