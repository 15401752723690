import { useOrgQuery } from '@comet/pages/Organisation/service';
import { useGetProjectQuery } from '@comet/pages/Project/service';
import { useParams, useNavigate } from '@comet/router';

export const useOrgAndProjectExists = () => {
  const navigate = useNavigate();

  const { organisationId, projectId } = useParams();

  const {
    data: orgData,
    error: orgError,
    status: orgLoading,
  } = useOrgQuery(organisationId);

  const {
    data: projectData,
    error: projectError,
    status: projectLoading,
  } = useGetProjectQuery(organisationId, projectId);

  if (!organisationId) return;

  if (orgLoading === 'loading') return true;

  if (!orgData || orgError) {
    navigate('organisations');
    return;
  }

  if (!projectId) return;

  if (projectLoading === 'loading') return true;

  if (!projectData || projectError) {
    navigate('organisations.organisationId.projects');
    return;
  }

  return false;
};
