import { FC } from 'react';
import { Link, Navigate, RRDUseNavigate } from '@comet/router';
import { Button, Form, Input, Typography, FormItem, Flex } from '@comet/blocks';
import { useAuth } from '@comet/components/auth/AuthProvider';
import { TryProductBanner } from '@comet/components/TryProductBanner/TryProductBanner';
import { AuthPageContainer, AuthSection, StyledText, Header } from './common';
import { styled, useTheme } from '@comet/styled';
import { usernameValidator } from './Auth.utils';
import { getLastVisitedPage } from '../User/User.service';

import { ReactComponent as CosmoCloud } from '@comet/assets/svg/cosmocloud_logo.svg';

interface IFormValues {
  username: string;
  password: string;
}

const StyledTitle = styled(Typography.Title)`
  padding-bottom: 24px;
`;

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

export const FormBox = styled.div`
  width: 80%;
  height: 100%;
  margin-top: 20px;
  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    margin-top: 200px;
    width: 368px;
  }`}
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
`;

const Login: FC = () => {
  const [form] = Form.useForm();
  const { login, user, error: authError } = useAuth();
  const {
    theme: { palette },
  } = useTheme();
  const navigate = RRDUseNavigate();

  const handleOnSubmit = async (formValues: IFormValues) => {
    const { username, password } = formValues;

    login(username, password);
  };

  if (user) {
    // Navigate to dashboard page if user is logged in
    const page = getLastVisitedPage();
    if (page) {
      navigate(page);
      return null;
    }
    return <Navigate routeId="organisations" />;
  }

  if (authError) {
    const username = form.getFieldValue('username');
    const password = form.getFieldValue('password');
    switch (authError.code) {
      // If user has still not confirmed email navigate to confirm user screen
      case 'UserNotConfirmedException':
        return (
          <Navigate routeId="confirmUser" state={{ username, password }} />
        );
      case 'NotAuthorizedException': {
        form.setFields([
          { name: 'username', errors: ['Incorrect username or password.'] },
        ]);
        break;
      }

      default:
        break;
    }
  }

  return (
    <AuthPageContainer>
      <Header>
        <CosmoCloud />
      </Header>
      <AuthSection>
        <FormBox>
          <Flex direction="column" justifyContent="center" width="100%">
            <StyledTitle level={1}>Sign in</StyledTitle>
            <Form form={form} layout="vertical" onFinish={handleOnSubmit}>
              <FormItem
                name="username"
                label="Username"
                marginBottom={24}
                rules={[
                  {
                    validator: usernameValidator,
                  },
                ]}
              >
                <Input placeholder="Username" name="username" required />
              </FormItem>
              <FormItem name="password" label="Password" marginBottom={8}>
                <Input type="password" placeholder="Password" name="password" />
              </FormItem>
              <Flex
                justifyContent="flex-end"
                width="100%"
                style={{ marginBottom: '24px' }}
              >
                <Link routeId="forgotPassword">
                  <Typography.Text strong style={{ color: palette.primary }}>
                    Forgot password?
                  </Typography.Text>
                </Link>
              </Flex>
              <FormItem marginBottom={16}>
                <Button type="primary" htmlType="submit" block>
                  Sign in
                </Button>
              </FormItem>
            </Form>
            {/* <Button
              icon={<FcGoogle style={{ marginRight: '8px' }} />}
              onClick={signInWithGoogle}
            >
              Sign in with Google
            </Button> */}
            <StyledText style={{ marginTop: '32px' }}>
              Don't have an account?
              <StyledLink routeId="signUp">
                <Typography.Text strong style={{ color: palette.primary }}>
                  Sign up for free
                </Typography.Text>
              </StyledLink>
            </StyledText>
            {/* <ToS /> */}
          </Flex>
        </FormBox>
      </AuthSection>
      <TryProductBanner />
    </AuthPageContainer>
  );
};

export default Login;
