import { forwardRef } from 'react';
import { styled } from '@comet/styled';
import { IFlexProps } from '@comet/blocks';

interface IFlexItemProps extends IFlexProps {
  flex?: boolean;
  grow?: React.CSSProperties['flexGrow'];
  shrink?: React.CSSProperties['flexShrink'];
}

const Div = styled.div``;

const FlexItem = forwardRef(
  (
    {
      direction,
      justifyContent,
      alignItems,
      width,
      height,
      style,
      flex,
      shrink,
      grow,
      ...props
    }: IFlexItemProps,
    ref
  ) => {
    return (
      <Div
        {...props}
        style={{
          display: flex ? 'flex' : 'unset',
          flexDirection: direction,
          justifyContent,
          alignItems,
          width,
          height,
          flexGrow: grow,
          flexShrink: shrink,
          ...style,
        }}
      />
    );
  }
);

export default FlexItem;
