import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Form,
  FormItem,
  Input,
  Select,
  Text,
} from '@comet/blocks';
import { FormInstance } from 'antd';
import { memo, useCallback, useState } from 'react';
import { CharacterFilterTypesOptions } from '../CustomTokenizerInput';
import { CharacterFilterTypes } from '../types';

const CharacterFiltersInput = memo(({ form }: { form: FormInstance }) => {
  const [characterFilterFields, setCharacterFilterFields] = useState<
    {
      type: CharacterFilterTypes | '';
      mappings?: Record<string, string>;
      ignoredTags?: string[];
    }[]
  >([]);
  const handleSelectFilterType = useCallback(
    (name: number, value: CharacterFilterTypes, index: number) => {
      setCharacterFilterFields((fields) => {
        return fields.map((field, i) => {
          return i === index ? { ...field, type: value } : { ...field };
        });
      });
    },
    []
  );
  const addCharacterFilter = useCallback(() => {
    setCharacterFilterFields((fields) => [
      ...fields,
      {
        type: '',
      },
    ]);
  }, []);
  const removeCharacterFilter = useCallback((index: number) => {
    setCharacterFilterFields((fields) => fields.filter((_, i) => i !== index));
  }, []);

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={10}>
        <Text appearance="label.long.semiBold">Character Filters</Text>
        <Form.List name="charFilters">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Flex
                  key={key}
                  direction="column"
                  style={{
                    width: '100%',
                    paddingBottom: 8,
                    borderBottom: '1px solid #f0f0f0',
                  }}
                >
                  <Flex
                    direction="row"
                    style={{ marginBottom: 8 }}
                    gap={8}
                    alignItems="flex-start"
                  >
                    <FormItem
                      name={[name, 'type']}
                      style={{ marginBottom: 0 }}
                      label="Character Filter Type"
                      rules={[
                        {
                          required: true,
                          message: 'Missing Character Filter Type',
                        },
                      ]}
                    >
                      <Select
                        options={CharacterFilterTypesOptions}
                        placeholder="Character Filter Type"
                        onChange={(value) => {
                          handleSelectFilterType(name, value, index);
                        }}
                      />
                    </FormItem>
                    {index === fields.length - 1 && (
                      <MinusCircleOutlined
                        style={{ marginTop: 4 }}
                        onClick={() => {
                          remove(name);
                          removeCharacterFilter(index);
                        }}
                      />
                    )}
                  </Flex>
                  {characterFilterFields[index]?.type === 'htmlStrip' ? (
                    <>
                      <Text
                        appearance="label.long.regular"
                        style={{ marginBottom: 10 }}
                      >
                        Ignored Tags
                      </Text>
                      <Form.List name={[name, 'ignoredTags']}>
                        {(ignoredTags, { add: addTag, remove: removeTag }) => (
                          <Flex direction="column">
                            <Flex
                              direction="column"
                              style={{ flexWrap: 'wrap', marginBottom: 10 }}
                              gap={10}
                            >
                              {ignoredTags.map(
                                (
                                  { key: tagKey, name: tagName, ...restFields },
                                  tagIndex
                                ) => (
                                  <Flex
                                    gap={10}
                                    alignItems="center"
                                    key={tagKey}
                                  >
                                    <Form.Item
                                      style={{ marginBottom: 0 }}
                                      key={tagKey}
                                      name={[tagName]}
                                    >
                                      <Input type="text" />
                                    </Form.Item>
                                    {tagIndex === ignoredTags.length - 1 && (
                                      <MinusCircleOutlined
                                        onClick={() => {
                                          removeTag(tagIndex);
                                        }}
                                      />
                                    )}
                                  </Flex>
                                )
                              )}
                            </Flex>
                            <Button
                              style={{ width: 200 }}
                              block
                              type="dashed"
                              icon={<PlusOutlined />}
                              onClick={() => {
                                addTag();
                              }}
                            >
                              Add Tag
                            </Button>
                          </Flex>
                        )}
                      </Form.List>
                    </>
                  ) : characterFilterFields[index]?.type === 'mapping' ? (
                    <>
                      <Text
                        appearance="label.long.regular"
                        style={{ marginBottom: 10 }}
                      >
                        Mappings
                      </Text>
                      <Form.List name={[name, 'mappings']}>
                        {(
                          mappings,
                          { add: addMapping, remove: removeMapping }
                        ) => (
                          <Flex direction="column" gap={10}>
                            {mappings.map(
                              (
                                {
                                  key: mappingKey,
                                  name: mappingName,
                                  ...restMappingFields
                                },
                                mappingIndex
                              ) => (
                                <Flex
                                  direction="row"
                                  alignItems="center"
                                  gap={10}
                                  key={mappingKey}
                                >
                                  <FormItem
                                    name={[mappingName, 0]}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Input type="text" />
                                  </FormItem>
                                  <Text
                                    appearance="label.long.semiBold"
                                    style={{ marginBottom: 0 }}
                                  >
                                    :
                                  </Text>
                                  <FormItem
                                    name={[mappingName, 1]}
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Input type="text" />
                                  </FormItem>
                                  {mappingIndex === mappings.length - 1 && (
                                    <MinusCircleOutlined
                                      onClick={() => {
                                        removeMapping(mappingIndex);
                                      }}
                                    />
                                  )}
                                </Flex>
                              )
                            )}
                            <Button
                              type="dashed"
                              icon={<PlusOutlined />}
                              style={{ width: 200 }}
                              block
                              onClick={() => {
                                addMapping();
                              }}
                            >
                              Add Mapping
                            </Button>
                          </Flex>
                        )}
                      </Form.List>
                    </>
                  ) : null}
                </Flex>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                    addCharacterFilter();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Character Filter
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Flex>
    </Flex>
  );
});

export default CharacterFiltersInput;
