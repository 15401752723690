import { lightTheme, styled } from '@comet/styled';
import { FC } from 'react';

export { Form, type FormProps } from 'antd';
export { type RuleObject } from 'antd/es/form';

type ColorType = 'ERROR' | 'SUCCESS' | 'NEUTRAL';
const getColor = (type: ColorType, theme: typeof lightTheme) => {
  switch (type) {
    case 'ERROR':
      return theme.palette.danger;
    case 'SUCCESS':
      return theme.palette.success.dark;
    default:
      return theme.palette.neutral.darker;
  }
};

const StyledText = styled.p<{ type: ColorType }>`
  color: ${({ theme, type }) => getColor(type, theme)};
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
  height: 14px;
`;
interface InputHelpTextProps {
  error?: React.ReactNode;
  help?: React.ReactNode;
  success?: React.ReactNode;
  children?: React.ReactNode;
}
export const InputHelpText = ({
  error,
  help,
  success,
  children,
}: InputHelpTextProps) => {
  if (error) {
    return <StyledText type="ERROR">{error}</StyledText>;
  }
  if (success) {
    return <StyledText type="SUCCESS">{success}</StyledText>;
  }
  return <StyledText type="NEUTRAL">{help || children}</StyledText>;
};

export * from './FormRow';

export const FormError = ({ children }: { children: React.ReactNode }) => (
  <StyledText type="ERROR">{children}</StyledText>
);
