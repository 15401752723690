import { styled } from '@comet/styled';
import { CSSProperties } from 'react';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { Button } from '../Button';
import { Flex } from '../Flex';
import FlexItem from '../FlexItem';
import { Spin } from '../Loader';

const TableContainer = styled(Flex)`
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
  padding: 16px;
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.lighter}`};
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-top: 8px;
  color: ${({ theme }) => `${theme.palette.neutral.darkest}`};
`;

const THead = styled.thead`
  font-weight: 600;
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.lighter}`};
  border-left: none;
  border-right: none;
`;

const Th = styled.th`
  padding: 8px;
  text-align: left;
`;

const TBody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.lighter}`};
  border-left: none;
  border-right: none;
  height: 40px;

  &:hover {
    background-color: #fafbfd;
    transition: 0.25s ease-out;
  }
`;

const Td = styled.td`
  padding: 10px 8px;
`;

interface TableProps<T extends object> {
  tableColumns: Column<T>[];
  tableData: Array<T>;
  onFetchMore: () => void;
  moreDataAvailable: boolean;
  fetching?: boolean;
  rowType?: string;
  style?: CSSProperties;
}

export const InfiniteScrollTable = <T extends object>(props: TableProps<T>) => {
  const {
    tableColumns,
    tableData,
    rowType,
    style,
    onFetchMore,
    moreDataAvailable,
    fetching,
  } = props;

  const tableInstance = useTable<T>(
    {
      columns: tableColumns,
      data: tableData,
      manualPagination: true,
    },
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <TableContainer
      direction="column"
      justifyContent="space-between"
      id="table-container"
      style={style}
    >
      <FlexItem grow={2}>
        <div>
          Showing {tableData.length} {rowType}
        </div>
        <StyledTable {...(getTableProps() ?? {})}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Th>
                ))}
              </tr>
            ))}
          </THead>
          <TBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </TBody>
        </StyledTable>
      </FlexItem>
      {moreDataAvailable && (
        <Flex width={200} style={{ marginTop: 20, alignSelf: 'center' }}>
          {fetching ? (
            <Spin />
          ) : (
            <Button onClick={onFetchMore}>Load More</Button>
          )}
        </Flex>
      )}
    </TableContainer>
  );
};
