import { useState } from 'react';
import { FormInstance } from 'antd';
import { Flex, Paper, Label } from '@comet/blocks';
import { GetSubflowDetailsResponse } from '../types';
import { EditableText } from '@comet/components/Editable/Text';
import { EditableTextArea } from '@comet/components/Editable/TextArea';
import UpdateArguments from '@comet/pages/Project/Subflows/SubflowDetails/UpdateArguments';
import UpdateReturnType from '@comet/pages/Project/Subflows/SubflowDetails/UpdateReturnType';

export const SubflowDetailsForm = ({
  data,
  form,
  hasEditAccess,
}: {
  data: GetSubflowDetailsResponse | undefined;
  form: FormInstance;
  hasEditAccess: boolean;
}) => {
  const [name, setName] = useState(data?.name || '');
  const [description, setDescription] = useState(data?.description || '');
  const initialArgData = (data?.flowMetadata?.functionArguments || []).map(
    (arg) => {
      return {
        key: arg.key,
        keyType: arg.type,
        default: arg.default,
        required: arg.required,
      };
    }
  );
  const initialReturnData = (
    data?.flowMetadata?.functionReturnTypeFields || []
  ).map((arg) => {
    return {
      key: arg.key,
      keyType: arg.type,
      displayName: arg.displayName,
    };
  });

  return (
    <Paper size="medium">
      <Flex direction="column" gap={16}>
        <Flex direction="column" gap={10}>
          <Label required={true}>Subflow name</Label>
          <EditableText
            type="text"
            name="name"
            value={name}
            onSave={setName}
            disabled={!hasEditAccess}
          />
        </Flex>
        <Flex direction="column" gap={10}>
          <Label>Description</Label>
          <EditableTextArea
            name="description"
            value={description}
            onSave={setDescription}
            disabled={!hasEditAccess}
          />
        </Flex>
        <UpdateArguments form={form} initialArgData={initialArgData} />
        {/* <UpdateReturnType initialReturnData={initialReturnData} /> */}
      </Flex>
    </Paper>
  );
};
