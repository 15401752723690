import { getEdgeId, getNodesUnderNode } from '../store/flow-engine-store/utils';
import { getNodeById, useFlowEngineStore } from '../store';

import { useNodeExplorerStore } from '../store/node-explorer-modal-store/store';

export function clickRootNode(nodeId: string | null) {
  if (!nodeId) return;

  const { setClickedAddNode, openNodeExplorer } =
    useNodeExplorerStore.getState();

  setClickedAddNode(nodeId);
  openNodeExplorer(true);
}

export function clickAddNode(nodeId: string | null) {
  if (!nodeId) return;
  const { setClickedAddNode, openNodeExplorer } =
    useNodeExplorerStore.getState();
  setClickedAddNode(nodeId);
  openNodeExplorer(true);
}

export function clickAddCaseNode(switchNodeId: string | null) {
  if (!switchNodeId) return;

  const { createNode, addEdge, switchToStackTop } =
    useFlowEngineStore.getState();

  const switchNode = getNodeById(switchNodeId);

  // for switch case v1
  if (switchNode?.data.type === 'SWITCH_CASE') {
    const addNodeId = createNode('ADD_NODE');
    if (!switchNode) return;
    addEdge(switchNodeId, addNodeId);
    switchNode.data.metadata.cases!.push({
      key: 'label',
      nodeId: addNodeId,
      edgeId: getEdgeId(switchNodeId, addNodeId),
    });
    switchNode.data.values!.cases.push({ value: 'label' });
  }

  //for switch case v2
  if (switchNode?.data.type === 'SWITCH_CASE_V2') {
    // in switch v2 we have executor node for each case
    const executorNodeId = createNode('EXECUTOR_NODE');
    if (!switchNode) return;
    addEdge(switchNodeId, executorNodeId);

    // get the default executor node to find node below it
    const defaultExecutorNodeId = switchNode.data.metadata.cases[0].nodeId;
    const nextNode = getNodesUnderNode(defaultExecutorNodeId)[1];
    switchNode.data.metadata.cases!.push({
      key: 'label',
      nodeId: executorNodeId,
      edgeId: getEdgeId(switchNodeId, executorNodeId),
    });
    switchNode.data.values!.cases.push({ value: 'label' });
    addEdge(executorNodeId, nextNode);
  }
  switchToStackTop();
}

export function clickDeleteCase(
  switchNodeId: string | null,
  index: number | null
) {
  if (!switchNodeId || !index) return;

  //get node data to check if switch case v1 or v2
  const switchNode = getNodeById(switchNodeId);
  if (!switchNode) return;

  if (index < 1 || index >= switchNode.data.metadata.cases.length) return;
  const [{ nodeId }] = switchNode.data.metadata.cases.splice(index, 1);

  switchNode.data.values!.cases.splice(index, 1);

  const { removeNodes, switchToStackTop } = useFlowEngineStore.getState();

  if (switchNode?.data.type === 'SWITCH_CASE') {
    const nodesToDelete = getNodesUnderNode(nodeId);
    removeNodes(nodesToDelete);
  } else if (switchNode?.data.type === 'SWITCH_CASE_V2') {
    const nodesToDelete = [nodeId];
    removeNodes(nodesToDelete);
  }

  switchToStackTop();
}

export function clickUpdateCase(
  switchNodeId: string | null,
  index: number | null,
  key: string
) {
  if (!switchNodeId || !index) return;
  if (!switchNodeId || !index) return;

  const switchNode = getNodeById(switchNodeId);
  if (!switchNode) return;
  if (index < 1 || index >= switchNode.data.metadata.cases.length) return;

  const { switchToStackTop } = useFlowEngineStore.getState();
  switchNode.data.metadata.cases[index].key = key;
  switchNode.data.values!.cases[index].value = key;
  switchToStackTop();
}

export function clickLoopNode(nodeId: string | null) {
  if (!nodeId) return;
  // To be implemented
}

export function clickExecutorNode(nodeId: string | null) {
  if (!nodeId) return;
}
