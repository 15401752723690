export const CONDITIONS = [
  { label: '(==) is equal to', value: '$eq' },
  { label: '(>) is greater than', value: '$gt' },
  { label: '(>=) is greater than / equal to', value: '$gte' },
  { label: '(<) is less than', value: '$lt' },
  { label: '(<=) is less than / equal to', value: '$lte' },
  { label: '(!=) is not equal to', value: '$ne' },
  { label: 'includes', value: '$includes' },
];

export const TYPES = [
  { label: 'String', value: 'string' },
  { label: 'Number', value: 'number' },
  { label: 'Boolean', value: 'boolean' },
];

export const BOOLEAN_TYPES = [
  { label: 'true', value: true },
  { label: 'false', value: false },
];

export enum OPERANDS {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CONDITION = 'CONDITION',
}
