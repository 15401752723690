import { Empty, Tooltip } from 'antd';
import { ChangeEvent, useState } from 'react';
import debounce from 'lodash.debounce';

import {
  Button,
  CustomSelect,
  Flex,
  FullCenter,
  Search,
  Show,
  Spin,
  Text,
  usePagination,
  Table,
} from '@comet/blocks';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { useNavigate, useParams } from '@comet/router';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { TableContainer } from '@comet/pages/Project/Project.styled';

import { useOrgMembersOptions } from '../utils';

import {
  useDeleteStorageAccounts,
  useListStorageAccounts,
} from './storage.service';
import { useStorageTableData } from './table';

const PAGE_SIZE = 10;

export const Storage = () => {
  const { organisationId } = useParams();
  const navigate = useNavigate();

  const { hasManageOrgSettingsAccess } = useOrgPermissions();

  const [searchValue, setSearchValue] = useState('');
  const [updatedBy, setUpdatedBy] = useState('');

  const [query, setQuery] = useState<string>('');

  const { currentPageIndex, fetchData, pageOffset } = usePagination();

  const {
    data: accounts,
    isLoading,
    error,
    isError,
    refetch,
  } = useListStorageAccounts(organisationId, {
    query: query || null,
    offset: pageOffset || null,
    created_by: updatedBy || null,
  });

  const onRefetch = async () => {
    await refetch();
    setQuery('');
  };

  const orgMembers = useOrgMembersOptions(organisationId);

  const { mutateAsync: deleteStorageAccount } =
    useDeleteStorageAccounts(organisationId);

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const onDisable = async (storageAccountId: string) => {
    await deleteStorageAccount({ storageAccountID: storageAccountId });
    await onRefetch();
  };

  const { columns, tableData } = useStorageTableData(
    accounts?.data ?? [],
    onDisable
  );

  const showList = !isLoading && !isError && accounts.data.length > 0;

  if (isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!hasManageOrgSettingsAccess) {
    return <ErrorPage />;
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={4} alignItems="center">
          <Text appearance="heading.card">Buckets</Text>
        </Flex>
        <Button
          type="primary"
          onClick={() => {
            navigate('organisations.organisationId.storage.create', {
              organisationId,
            });
          }}
          disabled={!hasManageOrgSettingsAccess}
        >
          Create new Storage
        </Button>
      </Flex>
      <TableContainer>
        <Flex alignItems="center" padding={10} gap={10}>
          <Flex direction="column">
            <Tooltip
              title="Minimum 3 characters"
              open={searchValue.length < 3 ? undefined : false}
            >
              <Search
                placeholder="Search by name"
                value={searchValue}
                onChange={onSearch}
              />
            </Tooltip>
          </Flex>
          <CustomSelect
            label="Created by"
            showSearch
            options={orgMembers}
            value={updatedBy}
            onChange={setUpdatedBy}
          />
        </Flex>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && accounts?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Buckets found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={columns}
            tableData={tableData}
            rowType="projects"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil((accounts?.page.total || 0) / PAGE_SIZE)}
            totalRows={accounts?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
