import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import {
  GetProjectAccessResponse,
  GetProjectResponse,
  GetProjectRolesResponse,
  GetProjectsResponse,
} from '../types/Projects.types';
import {
  RemoveUserAccessData,
  UpsertUserAccessData,
} from '@comet/components/Members/types';
import { VerifyOTPResponse } from '../Project.types';

const getProjects = async (organisationId: string, params: object) => {
  const url = `/organisations/${organisationId}/projects`;
  const { data } = await getAxios().get<GetProjectsResponse>(url, {
    params,
  });

  return data;
};

interface GetProjectsParams {
  offset?: number;
  limit?: number;
  query?: string;
  cloud_provider?: string | null;
  type?: string;
  created_by?: string | null;
  sort_by?: string;
  sort_order?: string;
}

interface CheckDeleteResponse {
  canBeDeleted: boolean;
}

export const useGetProjectsQuery = (
  organisationId: string,
  params: GetProjectsParams
) => {
  return useQuery({
    queryKey: ['useGetProjectsQuery', organisationId, params],
    queryFn: async () => await getProjects(organisationId, params),
  });
};

interface useProjectAccessQueryParams {
  user_name_query?: string | null;
}

export const useProjectAccessQuery = (
  organisationId: string,
  projectId: string,
  params: useProjectAccessQueryParams = {}
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/access`;

  return useQuery({
    queryKey: ['useProjectAccessQuery', organisationId, projectId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetProjectAccessResponse>(url, {
        params,
      });

      return data;
    },
  });
};

export const useProjectRolesQuery = () => {
  const url = `/metadata/roles`;

  return useQuery({
    queryKey: ['useProjectRolesQuery'],
    queryFn: async () => {
      const { data } = await getAxios().get<GetProjectRolesResponse>(url, {
        params: {
          role_type: 'PROJECT',
        },
      });

      return data;
    },
  });
};

export const UpsertUserProjectAccess = async (
  organisationId: string,
  projectId: string,
  projectAccessData: UpsertUserAccessData
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/access`;

  return getAxios()
    .patch(url, projectAccessData)
    .then((data) => data);
};

export const deleteProject = async (
  organisationId: string,
  projectId: string
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}`;

  return getAxios().delete(url);
};

export const sendOTPToDeleteProject = async (
  organisationId: string,
  projectId: string
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/delete/send_otp`;

  return getAxios().get(url);
};

export const resendOTPToDeleteProject = async (
  organisationId: string,
  projectId: string
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/delete/resend_otp`;

  return getAxios().get(url);
};

export const verifyOTPToDeleteProject = async (
  organisationId: string,
  projectId: string,
  otp: string
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/delete/verify_otp?otp=${otp}`;

  return getAxios().get<VerifyOTPResponse>(url);
};

export const checkDeletePossible = async (
  projectId: string,
  organisationId: string
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/delete-check`;
  const { data } = await getAxios().get<CheckDeleteResponse>(url);
  return data.canBeDeleted;
};

export const useGetProjectQuery = (
  organisationId: string,
  projectId: string
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}`;
  return useQuery({
    queryKey: ['useGetProjectQuery', organisationId, projectId],
    queryFn: async () => {
      const { data } = await getAxios().get<GetProjectResponse>(url);

      return data;
    },
    enabled: !!organisationId && !!projectId,
  });
};

export const removeProjectAccess = async (
  organisationId: string,
  projectId: string,
  removeOrgAccessData: RemoveUserAccessData
) => {
  const url = `/organisations/${organisationId}/projects/${projectId}/access`;

  return getAxios()
    .delete(url, { data: removeOrgAccessData })
    .then((data) => data);
};
