import { useNavigate, useParams } from '@comet/router';
import {
  getSecret,
  updateSecret,
  useGetSecretQuery,
} from '../services/Secrets.service';
import { SecretsRawData } from '../Types';
import {
  Center,
  Flex,
  FullCenter,
  Spin,
  Form,
  Button,
  PageHeader,
  Paper,
  Show,
} from '@comet/blocks';
import { Result, notification } from 'antd';
import { UpdateSecretForm } from './UpdateSecretForm';
import { SecretTypes } from '../constants';
import { queryClient, useMutation } from '@comet/query';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { sendErrorNotification } from 'src/blocks/Notification';

export const SecretDetails = () => {
  const { environmentId, secretId, projectId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  // Todo remove environment Id from secrets because secrets are getting updated and fetched for
  // undefined environmentId

  const {
    data: secret,
    isLoading: isSecretLoading,
    isError,
    refetch: refetchSecret,
  } = useGetSecretQuery(environmentId, secretId);

  const checkStatus = (id: string) => {
    const interval = setInterval(async () => {
      const { status } = await getSecret(projectId, 'env', id);
      if (status === 'ACTIVE') {
        clearInterval(interval);
        notification.success({
          message: 'Success',
          description: 'Successfully connected to MongoDB',
          duration: 10,
        });

        queryClient.refetchQueries({
          queryKey: ['useGetSecretsQuery', projectId, environmentId],
        });

        navigate(
          'organisations.organisationId.projects.projectId.secrets.secretId.details',
          {
            projectId,
            secretId: id,
            environmentId,
          }
        );
      } else if (status === 'FAILED') {
        clearInterval(interval);
        sendErrorNotification({
          message: 'Error',
          description:
            'Could not connect to MongoDB. Please check your input values.',
          redirectUrl:
            'https://docs.cosmocloud.io/cosmocloud-documentation/setup/4.-connect-your-database',
        });
      }
    }, 2500);
  };

  const onSuccess = (id: string, status: string) => {
    if (status === 'ACTIVE') {
      notification.success({
        message: 'Success',
        description: 'Secret created successfully',
        duration: 10,
      });
      queryClient.refetchQueries({
        queryKey: ['useGetSecretsQuery', projectId, environmentId],
      });

      navigate(
        'organisations.organisationId.projects.projectId.secrets.secretId.details',
        {
          projectId,
          secretId: id,
          environmentId,
        }
      );
      return;
    }
    notification.info({
      message: 'Message',
      description:
        'Attempting to connect to MongoDB for setup. This may take about a minute. Please hold on...',
      duration: 45,
      closeIcon: false,
    });

    checkStatus(id);
  };

  const updateSecretMutation = useMutation({
    mutationFn: (modelData: SecretsRawData) => {
      const environmentId = form.getFieldValue('environmentId');
      return updateSecret(projectId, environmentId, modelData, secretId);
    },
    onSuccess: ({ id, status }) => onSuccess(id, status),
    onError: () =>
      sendErrorNotification({
        message: 'Error',
        description:
          'Could not connect to MongoDB. Please check your input values.',
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/setup/4.-connect-your-database',
      }),
  });

  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const handleSave = (data: SecretsRawData) => {
    if (data.type === SecretTypes.DATABASE_SECRET) {
      updateSecretMutation.mutate(data);
      return;
    }
    if (data.type === SecretTypes.AUTHENTICATION_SECRET) {
      const tokenArray = (form.getFieldValue('tokenData') || []).map(
        (item: any) => {
          return { key: item.key, tokenType: item.tokenType };
        }
      );
      const updateSecretData = {
        name: data.name,
        type: data.type,
        data: { ...data.data, tokenData: tokenArray },
      };
      updateSecretMutation.mutate(updateSecretData);
      return;
    }
    const secretObject = form
      .getFieldValue('secretData')
      .reduce(
        (
          result: Record<string, string>,
          item: { key: string; value: string }
        ) => {
          result[item.key] = item.value;
          return result;
        },
        {} as Record<string, string>
      );

    const SecretsRawData = {
      name: data.name,
      type: data.type,
      data: secretObject,
    };
    updateSecretMutation.mutate(SecretsRawData);
  };

  if (isSecretLoading) {
    return (
      <FullCenter>
        <Flex direction="column">
          <Spin size="large" />
        </Flex>
      </FullCenter>
    );
  }

  if (isError) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchSecret();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex direction="column">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={secret}
      >
        <PageHeader
          title="Secret Details"
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              onClick={() => {
                handleSave(form.getFieldsValue());
              }}
              disabled={!hasManageProjectSettingsAccess}
            >
              Save
            </Button>
          }
        />
        <Paper size="medium">
          <Show if={!!secret && !isError}>
            <UpdateSecretForm
              name={secret?.name || ''}
              type={secret?.type || ''}
              data={secret?.data || ''}
              envName={secret?.environment?.name || ''}
            />
          </Show>
        </Paper>
      </Form>
    </Flex>
  );
};
