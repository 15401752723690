import { getAxios } from '@comet/axios';
import { useMutation, useQuery } from '@comet/query';

export const useListStorageAccounts = (
  orgId: string,
  params?: { [key: string]: unknown }
) => {
  return useQuery({
    queryKey: ['/storage-accounts', orgId, 'list', params],
    queryFn: async () => {
      const request = await getAxios().get<{
        data: StorageAccount[];
        page: {
          total: number;
        };
      }>(`/organisations/${orgId}/storage-accounts`, { params });
      return request.data;
    },
    staleTime: 5000,
  });
};

export const useCreateStorageAccount = (orgId: string) => {
  return useMutation({
    mutationKey: ['/storage-accounts', orgId, 'create'],
    mutationFn: async ({ name }: { name: string }) => {
      const request = await getAxios().post<{
        data: { name: string; id: string };
        page: {
          total: number;
        };
      }>(`/organisations/${orgId}/storage-accounts`, { name });
      return request.data;
    },
  });
};

export const useDeleteStorageAccounts = (orgId: string) => {
  return useMutation({
    mutationKey: ['/storage-accounts', orgId, 'delete'],
    mutationFn: async ({ storageAccountID }: { storageAccountID: string }) => {
      const request = await getAxios().delete<{
        data: { name: string; id: string };
        page: {
          total: number;
        };
      }>(`/organisations/${orgId}/storage-accounts/${storageAccountID}`);
      return request.data;
    },
  });
};
