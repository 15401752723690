import { useFlowEngineStore } from '@comet/components/FlowEngine/FlowEngine.store';
import { getGraph } from '@comet/components/FlowEngine/services/tree';
import { useCallback } from 'react';

export const useUpdateGraph = () => {
  const store = useFlowEngineStore();
  return useCallback(() => {
    const [nodes, edges] = [
      useFlowEngineStore.getState().nodes,
      useFlowEngineStore.getState().edges,
    ];
    const graph = getGraph(nodes, edges);
    store.setGraph(() => graph);
  }, [store]);
};
