export const PAGE_SIZE = 10;
export const SortByOptions = [
  {
    label: 'Updated On',
    value: 'updatedOn',
  },
  {
    label: 'Updated By',
    value: 'updatedBy',
  },
];

export const SubscriptionPlans = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: 'Free',
    value: 'FREE',
  },
  {
    label: 'Personal',
    value: 'PERSONAL',
  },
  {
    label: 'Standard',
    value: 'STANDARD',
  },
  {
    label: 'Starter',
    value: 'STARTER',
  },
];
