import { getAxios } from '@comet/axios';
import { useQuery } from '@tanstack/react-query';

export const usePlanMetadata = () => {
  return useQuery({
    queryKey: ['/plans', '/metadata'],
    queryFn: async () => {
      const req = await getAxios().get<Plans>('/metadata/payment-plans');

      return req.data;
    },
  });
};
