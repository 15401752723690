import { useCallback } from 'react';
import { getConnectedEdges, useReactFlow } from 'reactflow';

export const useUpdateEdgeBeforeDelete = () => {
  const { getNodes, getEdges, getNode, getEdge, setEdges } = useReactFlow();
  return useCallback(
    async (id: string) => {
      const connections = getConnectedEdges(getNodes(), getEdges()); // Get Connections
      const sourceNodeId = connections.find(
        (connection) => connection.target === id
      );
      const targetNodeId = connections.find(
        (connection) => connection.source === id
      ); // Find source and target edges for the deleting node
      const sourceNode = getNode(sourceNodeId?.source ?? '');
      if (sourceNode?.type === 'SWITCH_NODE') {
        const incomingEdge = getEdge(sourceNodeId?.id ?? '');
        const outgoingEdge = getEdge(targetNodeId?.id ?? '');

        if (!incomingEdge || !outgoingEdge) return;

        setEdges((edges) => {
          edges.forEach((edge, index) => {
            if (edge.id === incomingEdge.id) {
              edge.target = outgoingEdge.target; // Update the incoming edge target
            }
            if (edge.id === outgoingEdge.id) {
              edges.splice(index, 1); // Delete the edge
            }
          });
          return edges;
        });
        await new Promise((r) => setTimeout(r, 100)); // Sleep to update the graph which then continues to delete the node
      }
    },
    [getEdge, getEdges, getNode, getNodes, setEdges]
  );
};
