import { Flex } from '@comet/blocks';
import { ReviewApis } from './ReviewApis';
import { ReviewModels } from './ReviewModels';

interface Props {
  flows: ITemplateFlow[];
  models: ITemplateModel[];
  onChange: (item: string, type: 'flow' | 'model') => void;
  values: {
    modelItemTypes: string[];
    apiItemTypes: string[];
  };
}

export const ReviewApisAndModels = ({
  flows,
  models,
  values,
  onChange,
}: Props) => {
  return (
    <Flex direction="column">
      <ReviewApis flows={flows} values={values} onChange={onChange} />
      <ReviewModels models={models} values={values} onChange={onChange} />
    </Flex>
  );
};
