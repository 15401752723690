import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { useParams } from '@comet/router';

interface GetUserPermissionResponse {
  role: {
    name: string;
    value: number;
  };
}

export const useGetUserProjectPermissionsQuery = () => {
  const { organisationId, projectId } = useParams();
  const url = `organisations/${organisationId}/projects/${projectId}/access/me`;

  return useQuery({
    queryKey: ['useGetUserProjectPermissionsQuery', organisationId, projectId],
    queryFn: async () => {
      const { data } = await getAxios().get<GetUserPermissionResponse>(url);

      return data;
    },
    enabled: !!projectId,
  });
};
