import {
  Button,
  Center,
  Flex,
  FullCenter,
  Show,
  Spin,
  Table,
  Text,
  usePagination,
} from '@comet/blocks';
import { Empty, Result, Select, Tooltip } from 'antd';
import { useNavigate, useParams } from '@comet/router';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import _ from 'lodash';
import { queryClient } from '@comet/query';
import { useGetdatabasesQuery } from '../service';
import { GetDatabasesParams } from '../types';
import { useDatabasesListingData } from './DatabaseList.util';
import { PAGE_SIZE } from '../../Environments/constants';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useGetEnvironmentsOptions } from '../../Environments/utils';
import { useOrgQuery } from '@comet/pages/Organisation/service';

export const DatabasesList = () => {
  const navigate = useNavigate();
  const { organisationId, projectId } = useParams();
  const { pageOffset, currentPageIndex, fetchData } = usePagination();
  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const { environmentsOptions, selectedEnvironment, setSelectedEnvironment } =
    useGetEnvironmentsOptions();

  const params: GetDatabasesParams = {
    offset: pageOffset,
  };

  const {
    data: databasesData,
    isLoading,
    error,
    refetch: refetchEnvironments,
  } = useGetdatabasesQuery(projectId, selectedEnvironment, params);

  const { tableColumns, tableData } = useDatabasesListingData(
    databasesData,
    refetchEnvironments
  );

  const { data: orgData } = useOrgQuery(organisationId);
  const isOnFreeTier = orgData?.planDetails.planType == 'FREE';

  if (isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() =>
                  queryClient.refetchQueries([
                    'useGetStorageAccountsQuery',
                    projectId,
                  ])
                }
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: 10 }}
      >
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">Database Instances</Text>
          <Select
            options={environmentsOptions}
            defaultValue={selectedEnvironment}
            style={{ width: '200px' }}
            onSelect={setSelectedEnvironment}
          />
        </Flex>
        <Tooltip
          title={
            hasManageProjectSettingsAccess
              ? ''
              : 'Please check if you have enough permissions to access this page.'
          }
        >
          <Button
            type="primary"
            onClick={() => {
              navigate(
                'organisations.organisationId.projects.projectId.databases.new',
                {},
                { state: { envId: selectedEnvironment } }
              );
            }}
            disabled={!hasManageProjectSettingsAccess || isOnFreeTier}
          >
            Create Database Instance
          </Button>
        </Tooltip>
      </Flex>

      <TableContainer>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!error && !isLoading && !databasesData?.data.length}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Databases found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && databasesData?.data?.length !== 0}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="storageAccounts"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil(
              (databasesData?.page.total || 0) / PAGE_SIZE
            )}
            totalRows={databasesData?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};

export default DatabasesList;
