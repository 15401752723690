import { CSSProperties, useCallback, useEffect, useState } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { styled } from '@comet/styled';

const getGroupedUrl = (s: string) => {
  const words: Array<{ isParam?: boolean; word: string }> = [];
  let currentWord = '';
  for (let i = 0; i < s.length; i++) {
    if (s[i] === '}') {
      words.push({ word: currentWord + '}', isParam: true });
      currentWord = '';
      continue;
    } else if (s[i] === '{') {
      words.push({ word: currentWord });
      currentWord = '';
    }

    currentWord += s[i];
  }

  if (currentWord) {
    words.push({ word: currentWord });
  }
  return words;
};

const Container = styled(ContentEditable)`
  .param-tag {
    color: ${({ theme }) => theme.palette.primary};
  }
  height: 32px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  border-radius: 6px;
  padding: 4px 11px;
  display: flex;
  align-items: center;
  outline: none;
  white-space: nowrap;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ${({ disabled, theme }) =>
    disabled
      ? `background: ${theme.palette.neutral.lightest};
      color: ${theme.palette.neutral.darker};`
      : ''}

  &:focus,
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.palette.primary};
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

interface InputUrlProps {
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
  required?: boolean;
  style?: CSSProperties;
}

export const InputUrl = ({
  value = '',
  onChange,
  disabled,
  style,
}: InputUrlProps) => {
  const [state, setState] = useState(value);

  const html = getGroupedUrl(state)
    .map(({ word, isParam }) => {
      return isParam ? `<span class="param-tag">${word}</span>` : word;
    })
    .join('');

  const onContentChange = useCallback((evt: ContentEditableEvent) => {
    setState(evt.currentTarget.textContent);
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [state]);

  return (
    <Container
      disabled={disabled}
      onChange={onContentChange}
      html={html}
      style={style}
    />
  );
};
