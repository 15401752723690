import {
  Button,
  Flex,
  Form,
  Show,
  Text,
  Select,
  Card,
  FormItem,
} from '@comet/blocks';
import { notification, Input, FormInstance, InputNumber } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { StyledFieldType } from '../SearchIndex.styled';
import { SearchIndex } from '../types';
import { sendErrorNotification } from 'src/blocks/Notification';
import { useGetFieldsOptions } from '../utils';
import { useEffect, useState } from 'react';
import { CustomCard } from './CustomCard';

interface DynamicFieldsProps {
  searchIndexData?: SearchIndex;
  form: FormInstance;
  modelId: string;
}

export const DynamicFields = ({
  searchIndexData,
  form,
  modelId,
}: DynamicFieldsProps) => {
  const { fieldMappings, fieldOptionsVector, fieldOptionsFilter } =
    useGetFieldsOptions(modelId);
  const [numberOfFields, setNumberOfFields] = useState({
    initial: true,
    value: 0,
  });

  useEffect(() => {
    if (
      searchIndexData &&
      numberOfFields.initial &&
      fieldOptionsVector.length &&
      fieldOptionsFilter.length
    ) {
      setNumberOfFields({
        initial: false,
        value:
          fieldOptionsVector.length +
          fieldOptionsFilter.length -
          searchIndexData.mappings.fields.length -
          1,
      });
    } else if (
      numberOfFields.initial &&
      fieldOptionsVector.length &&
      fieldOptionsFilter.length
    )
      setNumberOfFields({
        initial: false,
        value: fieldOptionsFilter.length + fieldOptionsVector.length - 1,
      });
  }, [fieldOptionsVector, fieldOptionsFilter]);

  return (
    <>
      <Card title={null}>
        <>
          <Flex gap={8} alignItems="center" style={{ marginTop: 10 }}>
            <Flex>
              <FormItem
                initialValue={'vector'}
                label="Type"
                name={['defaultFields', 'searchType']}
                rules={[{ required: true, message: 'Missing search type' }]}
              >
                <Input
                  defaultValue="vector"
                  value="vector"
                  readOnly
                  required
                  style={{ width: 250 }}
                />
              </FormItem>
            </Flex>
            <Flex gap={8}>
              <FormItem
                label="Field Name"
                name={['defaultFields', 'path']}
                initialValue={searchIndexData?.mappings?.defaultFields?.path}
                rules={[
                  { required: true, message: 'Missing field name' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const myfields = getFieldValue('fields');

                      //checking if the fields is undefined
                      if (!myfields) {
                        return Promise.resolve();
                      }

                      if (
                        myfields &&
                        myfields.filter(
                          (f: { path: string }) => f?.path === value
                        ).length < 1
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Duplicate fields not allowed!')
                      );
                    },
                    message: 'Duplicate fields not allowed!',
                  }),
                ]}
              >
                <Select
                  options={fieldOptionsVector}
                  placeholder="Select vector embeddings field"
                  style={{ width: 250 }}
                />
              </FormItem>
            </Flex>
          </Flex>
          <Flex gap={8} alignItems="center" style={{ marginTop: 10 }}>
            <Flex>
              <FormItem
                initialValue={
                  searchIndexData?.mappings?.defaultFields?.noOfDimensions
                }
                rules={[
                  { required: true, message: 'Missing field name' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value > 0 && value < 4097) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Value should be between 1 and 4096')
                      );
                    },
                  }),
                ]}
                label="Number Of Dimensions"
                name={['defaultFields', 'noOfDimensions']}
              >
                <InputNumber
                  placeholder="Number of dimensions"
                  style={{ width: 250 }}
                  required
                />
              </FormItem>
            </Flex>
            <Flex>
              <FormItem
                label="Similarity Function"
                name={['defaultFields', 'similarityFunction']}
                initialValue={
                  searchIndexData?.mappings?.defaultFields?.similarityFunction
                }
                rules={[{ required: true, message: 'Missing field name' }]}
              >
                <Select
                  placeholder="Select function type"
                  options={[
                    { label: 'euclidean', value: 'euclidean' },
                    { label: 'cosine', value: 'cosine' },
                    { label: 'dotProduct', value: 'dotProduct' },
                  ]}
                  style={{ width: 250 }}
                />
              </FormItem>
            </Flex>
          </Flex>
        </>
      </Card>

      <Form.List name="fields" initialValue={searchIndexData?.mappings.fields}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <CustomCard
                key={key}
                name={name}
                restField={restField}
                fieldOptions={{ fieldOptionsVector, fieldOptionsFilter }}
                form={form}
                remove={remove}
                setNumberOfFields={setNumberOfFields}
              />
            ))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="dashed"
                onClick={() => {
                  if (!numberOfFields.value) {
                    sendErrorNotification({
                      message: 'Error',
                      description:
                        'You have reached maximum number of fields for this vector search index.',
                    });
                    return;
                  }
                  add();
                  setNumberOfFields((prev) => {
                    return {
                      initial: false,
                      value: prev.value - 1,
                    };
                  });
                }}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
};
