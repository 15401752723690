import {
  getEdgeId,
  getNodeById,
  getNodesAttachedWithSourceHandle,
  getNodesAttachedWithTargetHandle,
  getNodesUnderNode,
  setRootNodeOfFlow,
  updateMetaDataOfIfNode,
  updateMetadataOfSwitchNode,
} from '../store/flow-engine-store/utils';

import { NodeTypeEnum } from '../types';
import { useFlowEngineStore } from '../store';
import { add } from 'lodash';

function deleteNode(
  nodeToDeleteId: string | null,
  connectedNodes: string[] | null,
  nextNode: string | null,
  finalLayerNodeIds: string[] | null
) {
  if (
    !nodeToDeleteId ||
    !connectedNodes ||
    !nextNode ||
    connectedNodes.length === 0 ||
    !finalLayerNodeIds
  )
    return;

  const { removeEdge, addEdge } = useFlowEngineStore.getState();

  connectedNodes.forEach((connectedNode) => {
    const node1 = getNodeById(connectedNode);
    if (!node1) return;

    switch (node1.type) {
      case NodeTypeEnum.IfElse: {
        // Have to handle metadata of if-else node
        updateMetaDataOfIfNode(node1.id, nodeToDeleteId, nextNode);
        break;
      }
      case NodeTypeEnum.SwitchNode: {
        if (node1.data.type === 'SWITCH_CASE') {
          updateMetadataOfSwitchNode(node1.id, nodeToDeleteId, nextNode);
        } else {
          // updateMetadataOfSwitchNode(node1.id, nodeToDeleteId, nextNode);
        }
        break;
      }
    }

    // deleting edge between node and node above it
    const edge1Id = getEdgeId(connectedNode, nodeToDeleteId);
    removeEdge(edge1Id);

    // adding edge between node above the BASE_NODE and node below the BASE_NODE
    addEdge(connectedNode, nextNode);
  });

  finalLayerNodeIds.forEach((finalLayerNodeId) => {
    const edgeId = getEdgeId(finalLayerNodeId, nextNode);
    if (!edgeId) return;
    removeEdge(edgeId);
  });
}

export function deleteBaseNode(nodeId: string | null) {
  const { createNode, removeNode } = useFlowEngineStore.getState();
  if (nodeId === null) return;

  const node1Ids = getNodesAttachedWithTargetHandle(nodeId);
  const node2Ids = getNodesAttachedWithSourceHandle(nodeId);

  if (!node2Ids) {
    // Special case for HTTP Response node
    const addNodeId = createNode('ADD_NODE');
    deleteNode(nodeId, node1Ids, addNodeId, [nodeId]);
    removeNode(nodeId);
    return;
  }

  let node2Id = node2Ids![0];
  const node2 = getNodeById(node2Id);

  if (!node1Ids) {
    // In case when base node is connected with addNode, we need to remove addNode and
    // add RootNode
    if (node2?.type === NodeTypeEnum.AddNode) {
      const rootNodeId = createNode('ROOT_NODE');
      removeNode(node2Id); // removing addNode
      node2Id = rootNodeId!; // so that we can make this node as our rootnode/first node of the flow
    }
    setRootNodeOfFlow(node2Id);
  } else {
    deleteNode(nodeId, node1Ids, node2Id, [nodeId]);
  }
  // Removing base node
  removeNode(nodeId);
}

export function deleteIfNode(nodeId: string | null) {
  if (!nodeId) return;

  /*
    Before -> node1--edge1--IF_NODE -- IF_NODE_FLOW // there can be multiple pairs of node1-edge1
    After -> node1--edge2--addNode
  */

  const { removeNodes, createNode } = useFlowEngineStore.getState();

  const node1Ids = getNodesAttachedWithTargetHandle(nodeId);
  if (!node1Ids) {
    const rootNodeId = createNode('ROOT_NODE');
    setRootNodeOfFlow(rootNodeId);
  } else {
    // Adding Edge between addNode and node1s
    const addNodeId = createNode('ADD_NODE');
    deleteNode(nodeId, node1Ids, addNodeId, []);
  }

  // Deleting the flow below ifv1 node
  const nodesUnderIfV1 = getNodesUnderNode(nodeId);
  removeNodes(nodesUnderIfV1);
}

export function deleteIfV2Node(nodeId: string | null) {
  if (!nodeId) return;

  const { removeNodes, createNode } = useFlowEngineStore.getState();
  // Logic to delete Nodes inside executor nodes under IfV2

  const ifV2Node = getNodeById(nodeId);
  if (!ifV2Node) return;
  const trueExecutorNode = getNodeById(ifV2Node.data.metadata.true);
  const falseExecutorNode = getNodeById(ifV2Node.data.metadata.false);

  if (!trueExecutorNode || !falseExecutorNode) return;

  const TERootNode = trueExecutorNode.data.metadata.rootNode; // TrueExecutorRootNode
  const FERootNode = falseExecutorNode.data.metadata.rootNode; //FalseExecutorRootNode

  const nodesUnderTERootNode = getNodesUnderNode(TERootNode);
  const nodesUnderFERootNode = getNodesUnderNode(FERootNode);

  removeNodes(nodesUnderFERootNode);
  removeNodes(nodesUnderTERootNode);

  // Connecting nodes above IfV2Node and node below it

  const node1Ids = getNodesAttachedWithTargetHandle(nodeId);
  const node2Id = getNodesAttachedWithSourceHandle(trueExecutorNode.id)![0];

  if (!node1Ids) {
    // Deleting IfV2, TrueExecutor, FalseExecutor, node2 here addNode
    removeNodes([node2Id]);
    const rootNodeId = createNode('ROOT_NODE');
    setRootNodeOfFlow(rootNodeId);
  } else {
    deleteNode(nodeId, node1Ids, node2Id, [
      trueExecutorNode.id,
      falseExecutorNode.id,
    ]);
    // Deleting IfV2, TrueExecutor, FalseExecutor
  }

  removeNodes([ifV2Node.id, trueExecutorNode.id, falseExecutorNode.id]);
}

export const getChildNodes = (nodeId: string) => {
  const { allEdges } = useFlowEngineStore.getState();
  const childNodes = allEdges
    .filter((edge) => edge.source === nodeId)
    .map((edge) => edge.target);
  return childNodes;
};

export const getNodesToRemove = (childNodes: string[]) => {
  const nodesToRemove: string[] = [];

  childNodes.forEach((node) => {
    const nodeData = getNodeById(node);
    if (
      nodeData?.type === NodeTypeEnum.ExecutorNode ||
      nodeData?.type === NodeTypeEnum.LoopNode
    ) {
      const nodesUnder = getNodesUnderNode(nodeData?.data.metadata.rootNode);
      nodesToRemove.push(...nodesUnder);
    }
  });
  return nodesToRemove;
};

export const deleteSwitchV2Node = (
  nodeId: string,
  connectedNodesIds: string[],
  nextNode: string
) => {
  const { addEdge, removeEdge, createNode } = useFlowEngineStore.getState();
  if (!connectedNodesIds) {
    const rootNodeId = createNode('ROOT_NODE');
    setRootNodeOfFlow(rootNodeId);
  } else {
    // break connection between switchv2 node and its parent nodes
    connectedNodesIds.map((connectedNode) => {
      const edgeToRemoveId = getEdgeId(connectedNode, nodeId);
      removeEdge(edgeToRemoveId);
    });

    //break connection between switch v2 node child and its next node
    const connectedWithNextNode = getNodesAttachedWithTargetHandle(nextNode);
    if (!connectedWithNextNode) return;

    connectedWithNextNode.forEach((node) => {
      const toRemoveEdgeId = getEdgeId(node, nextNode);
      removeEdge(toRemoveEdgeId);
    });

    //make connection between parent nodes and next node
    connectedNodesIds.map((connectedNode) => {
      addEdge(connectedNode, nextNode);
    });
  }
};

export function deleteSwitchNode(nodeId: string | null) {
  if (!nodeId) return;

  /*
    Before -> node1--edge1--SWITCH_NODE --  SWITCH_NODE_FLOW // there can be multiple pairs of node1-edge1
    After -> node1--edge2--addNode
  */

  const { removeNodes, createNode } = useFlowEngineStore.getState();

  const nodeData = getNodeById(nodeId);
  const node1Ids = getNodesAttachedWithTargetHandle(nodeId); // node1Ids are the nodes above switch node which are directly connected to switch node

  if (!node1Ids) {
    //Switch_NODE is the only node, then we need to add root node again
    const rootNodeId = createNode('ROOT_NODE');
    setRootNodeOfFlow(rootNodeId);
    return;
  }

  if (nodeData?.data.type === 'SWITCH_CASE') {
    // Adding Edge between addNode and node1s
    const addNodeId = createNode('ADD_NODE');
    deleteNode(nodeId, node1Ids, addNodeId, []);
    // Deleting the flow below ifv1 node
    const nodesUnderSwitch = getNodesUnderNode(nodeId);
    removeNodes(nodesUnderSwitch);
  }

  if (nodeData?.data.type === 'SWITCH_CASE_V2') {
    const nextNode = getNodesUnderNode(
      nodeData?.data.metadata.cases[0].nodeId
    )[1];
    deleteSwitchV2Node(nodeId, node1Ids, nextNode);
    const childNodes = getChildNodes(nodeId);
    const nodesToRemove = getNodesToRemove(childNodes);

    // nodeId : main switch node that need to be deletd
    // childNodes : all the executors nodes of different cases + add case node as well
    // nodesToRemove : all the nodes within the executors nodes (each executor node will have nodes within it)

    removeNodes([nodeId, ...childNodes, ...nodesToRemove]);
  }
}

export function deleteLoopNode(nodeId: string | null) {
  if (!nodeId) return;

  const { removeNodes, createNode, removeNode } = useFlowEngineStore.getState();

  // Logic to delete Nodes inside executor nodes under forNode
  const forNode = getNodeById(nodeId);
  if (!forNode) return;

  const ForNodeRootNode = forNode.data.metadata.rootNode; // ForNodeRootNode
  const nodesUnderForNodeRootNode = getNodesUnderNode(ForNodeRootNode);

  removeNodes(nodesUnderForNodeRootNode);

  const node1Ids = getNodesAttachedWithTargetHandle(nodeId);
  const node2Id = getNodesAttachedWithSourceHandle(nodeId)![0];
  const node2 = getNodeById(node2Id)!;

  if (!node1Ids) {
    // For Node is the topmost node in the flow

    let executorRootNodeId;
    // We need to create rootNode , when node2.type === ADD_NODE
    if (node2.type === NodeTypeEnum.AddNode) {
      const rootNodeId = createNode('ROOT_NODE');
      executorRootNodeId = rootNodeId;
      removeNodes([node2Id, nodeId]); //node2Id is addNode and nodeId is forNode
    } else {
      executorRootNodeId = node2Id;
      removeNodes([nodeId]);
    }

    setRootNodeOfFlow(executorRootNodeId);
  } else {
    deleteNode(nodeId, node1Ids, node2Id, [nodeId]);
    removeNode(nodeId);
  }
}

export function deleteExecutoNode(nodeId: string | null) {
  if (!nodeId) return;
}
