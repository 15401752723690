import {
  Button,
  Flex,
  Modal,
  Text,
  Dropdown,
  TableHeader,
  Tooltip,
} from '@comet/blocks';
import { useCallback, useMemo, useState } from 'react';
import { styled, useTheme } from '@comet/styled';
import { Column } from 'react-table';
import { GetSearchIndexesResponse, IAnalyzer, ICustomAnalyzer } from '../types';
import { Link, useParams } from '@comet/router';
import { FiMoreHorizontal } from 'react-icons/fi';
import { deleteSearchIndex } from '../service';
import { RiAlertLine } from 'react-icons/ri';
import { format } from 'date-fns';
import { useModelQuery, useModelsQuery } from '@comet/pages/Model/service';
import { Field } from '@comet/pages/Model/types';
import { useGetAnalyzersQuery } from '../../Analyzer/service';
import {
  InbuiltAnalyzers,
  SearchIndexStatusMapping,
} from '../SearchIndex.constants';
import { useConfigQuery } from '@comet/pages/Project/Config/service';
import { DatabaseConfigData } from '@comet/pages/Project/Config/types';
import { StatusComponent } from '@comet/components/TableComponents';
import _ from 'lodash';
import set from 'lodash.set';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

export const DeleteSearchIndex = ({
  id,
  refetch,
  disabled,
}: {
  id: string;
  disabled: boolean;
  refetch: () => void;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await deleteSearchIndex(projectId, id);
    refetch();
    toggleModal();
  }, [id, projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip
                  title={disabled ? 'This search index is getting used' : ''}
                >
                  Delete
                </Tooltip>
              ),
              onClick: toggleModal,
              disabled,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this search index?{' '}
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this search index.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useSearchIndexListingData = (
  searchIndexData: GetSearchIndexesResponse | undefined,
  refetch: () => void
) => {
  const { organisationId, projectId } = useParams();
  const { theme } = useTheme();

  const tableData = useMemo(() => {
    if (!searchIndexData) return [];

    return searchIndexData.data.map((item) => {
      return {
        name: (
          <Link
            routeId="organisations.organisationId.projects.projectId.vector-search-indexes.vectorSearchIndexId"
            pathParam={{
              organisationId,
              projectId,
              vectorSearchIndexId: item.id,
            }}
          >
            {item.name}
          </Link>
        ),
        dependentCollection: item.modelData.name,
        updatedBy: item.updatedBy.name,
        updatedOn: formatTime(item.updatedOn),
        id: (
          <DeleteSearchIndex id={item.id} disabled={false} refetch={refetch} />
        ),
      };
    });
  }, [searchIndexData, organisationId, projectId, refetch, theme]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" />,
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Dependent collection" />,
        accessor: 'dependentCollection',
        disableSortBy: true,
      },

      {
        Header: <TableHeader text="Updated by" />,
        accessor: 'updatedBy',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated on" />,
        accessor: 'updatedOn',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
      },
    ];
  }, []);

  return {
    tableData,
    tableColumns,
  };
};

export const useGetDatabseCollectionOptions = () => {
  const { projectId } = useParams();

  const { data } = useModelsQuery(projectId, {
    version: null,
    model_type: 'DATABASE_COLLECTION',
  });

  return useMemo(() => {
    if (!data) return [];

    return data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [data]);
};

function extractFieldInfo(field: Field, prefix = '', subType = false) {
  const fieldName = prefix + field.name;
  const fieldType = field.type;
  const fieldSubtype = field.subType as string;
  //adding list check because the field option vector can only be applied on the list
  return {
    name: fieldName,
    type: !subType && !fieldSubtype ? fieldType : fieldSubtype,
    list: field.type === 'LIST',
  };
}

function extractFields(fields: Field[], prefix = '') {
  let fieldInfo: { name: string; type: string; list: boolean }[] = [];
  for (const field of fields) {
    if (
      (field.type === 'NESTED' || field.type === 'LIST') &&
      !!field.properties
    ) {
      const nestedFields = field.properties;
      const nestedPrefix = prefix + field.name + '.';
      fieldInfo = fieldInfo.concat(extractFields(nestedFields, nestedPrefix));
    } else if (field.type === 'LIST' && !field.properties) {
      fieldInfo.push(extractFieldInfo(field, prefix, true));
    } else {
      fieldInfo.push(extractFieldInfo(field, prefix));
    }
  }
  return fieldInfo;
}

export const getFieldOptions = (fields: Field[] = []) => {
  const extractedFields = extractFields(fields);
  const fieldOptionsVector: { label: string; value: string }[] = [];
  const fieldOptionsFilter: { label: string; value: string }[] = [];
  extractedFields.forEach((field) => {
    if (field.list && field.type === 'FLOAT') {
      fieldOptionsVector.push({
        label: field.name,
        value: field.name,
      });
    } else {
      fieldOptionsFilter.push({
        label: field.name,
        value: field.name,
      });
    }
  });

  const fieldMappings: Record<string, string> = extractedFields.reduce(
    (acc, field) => {
      acc[field.name] = field.type;
      return acc;
    },
    {} as Record<string, string>
  );

  return { fieldMappings, fieldOptionsVector, fieldOptionsFilter };
};

export const useGetFieldsOptions = (modelId: string) => {
  const { projectId } = useParams();

  const { data } = useModelQuery(
    projectId,
    modelId,
    {
      version: null,
    },
    !!modelId
  );

  return useMemo(() => {
    if (!data)
      return {
        fieldOptionsVector: [],
        fieldOptionsFilter: [],
        fieldMappings: {} as Record<string, string>,
      };

    return getFieldOptions(data.fields || []);
  }, [data]);
};

// Todo remove this if not used
export const useShowSplashScreen = () => {
  const { projectId } = useParams();

  const { data, isLoading, isError } = useConfigQuery(
    projectId,
    'DATABASE_CONFIG'
  );

  return {
    isLoading,
    showSplashScreen:
      isError || !data || (data.data as DatabaseConfigData).mode === 'db_only',
  };
};

export const MODEL_TO_MONGODB_ATLAS_MAP: Record<string, string> = {
  OBJECT_ID: 'objectId',
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  FLOAT: 'number',
};
