import { useFlowEngineStore, useNodeExplorerStore } from '../../store';

import { Modal } from 'antd';
import { NodeOptions } from './NodesOptions';
import React from 'react';

export function NodeExplorer() {
  const { selectedBusinessNode, setSelectedBusinessNode } =
    useNodeExplorerStore((s) => ({
      selectedBusinessNode: s.selectedBusinessNode,
      setSelectedBusinessNode: s.setSelectedBusinessNode,
    }));

  const {
    isNodeExplorerOpen,
    openNodeExplorer,
    setClickedAddNode,
    clickedAddEdge,
    clickedAddNode,
    setClickedAddEdge,
  } = useNodeExplorerStore((s) => ({
    isNodeExplorerOpen: s.isNodeExplorerOpen,
    openNodeExplorer: s.openNodeExplorer,
    setClickedAddNode: s.setClickedAddNode,
    clickedAddNode: s.clickedAddNode,
    clickedAddEdge: s.clickedAddEdge,
    setClickedAddEdge: s.setClickedAddEdge,
  }));

  const addNode = useFlowEngineStore((s) => s.addNode);

  const handleOkModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    // Code to add node

    if (clickedAddNode && selectedBusinessNode) {
      addNode('ADD_NODE', clickedAddNode, selectedBusinessNode);
    }

    if (clickedAddEdge && selectedBusinessNode) {
      addNode('EDGE', clickedAddEdge, selectedBusinessNode);
    }

    openNodeExplorer(false);
    setClickedAddNode(null);
    setSelectedBusinessNode(null);
    setClickedAddEdge(null);
    event.stopPropagation();
  };

  const handleCancelModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    openNodeExplorer(false);
    setSelectedBusinessNode(null);
    setClickedAddNode(null);
    setClickedAddEdge(null);
    event.stopPropagation();
  };

  return (
    <Modal
      width={788}
      title="Select new node"
      open={isNodeExplorerOpen}
      onOk={handleOkModal}
      onCancel={handleCancelModal}
      okButtonProps={{ disabled: !selectedBusinessNode }}
      okText="Add Node"
      centered
    >
      <NodeOptions />
    </Modal>
  );
}
