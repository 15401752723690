import { useParams } from '@comet/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Center,
  Flex,
  FullCenter,
  Label,
  Paper,
  Spin,
  Form,
  PageHeader,
  Show,
  Text,
  FormItem,
  Tabs,
} from '@comet/blocks';
import { Result, Switch, notification } from 'antd';
import { EditableText } from '@comet/components/Editable/Text';
import { queryClient, useMutation } from '@comet/query';
import { updateSearchIndex, useGetSearchIndexQuery } from './service';
import { CreateSearchIndexFormData, SearchIndexRequestData } from './types';

import { StyledFieldType } from './SearchIndex.styled';
import { DynamicFields } from './DynamicFields';
import { sendErrorNotification } from 'src/blocks/Notification';
import { Editor, EditorProps } from '@monaco-editor/react';
import {
  formatFieldMappingsAndAnalyzers,
  parseFieldMappingAndAnalyzers,
} from './utils';
import { useGetAnalyzersQuery } from '../Analyzer/service';

export const SearchIndexDetails = () => {
  const { projectId, searchIndexId } = useParams();

  const {
    data: searchIndexData,
    isLoading: isSearchIndexLoading,
    isError: isSearchIndexError,
    refetch: refetchSearchIndex,
  } = useGetSearchIndexQuery();
  const [name, setName] = useState(searchIndexData?.name || '');
  const [formattedFieldMapping, setFormattedFieldMapping] = useState({});
  const { data: analyzersData } = useGetAnalyzersQuery();
  const editorRef = useRef<any>(null);
  const [analyzersLength, setAnalyzersLength] = useState(1);

  const [form] = Form.useForm();
  // const analyzers = analyzersData?.data;

  const analyzers = useMemo(() => {
    if (!analyzersData?.data) {
      return [];
    }
    const analyzersArray = analyzersData?.data.map((analyzer) => ({
      name: analyzer.name,
      charFilters: analyzer['charFilters'] ?? [],
      tokenizer: analyzer['tokenizer'],
      tokenFilters: analyzer['tokenFilters'] ?? [],
    }));
    setAnalyzersLength(
      JSON.stringify(analyzersArray, null, '\t').split('\n').length
    );
    return analyzersArray;
  }, [analyzersData?.data]);

  const formatFieldMapping = () => {
    if (!analyzersData?.data) {
      return undefined;
    }
    const formattedMappingAndAnalyzers = formatFieldMappingsAndAnalyzers(
      form.getFieldsValue(),
      analyzersData?.data
    );
    if (formattedMappingAndAnalyzers) {
      const { mappings } = formattedMappingAndAnalyzers;
      setFormattedFieldMapping({ analyzers, ...mappings });
    }
  };
  const parseFieldMapping = () => {
    if (formattedFieldMapping) {
      const parsedMapping = parseFieldMappingAndAnalyzers(
        formattedFieldMapping
      );
      // form.setFieldsValue({ ...formData, ...parsedMapping });
      setTimeout(() => {
        form.resetFields();
      }, 100);
    }
  };

  const handleEditorDidMount: EditorProps['onMount'] = (editor, monaco) => {
    editorRef.current = editor;
    editor.createDecorationsCollection([
      {
        range: new monaco.Range(1, 0, analyzersLength, 0), // Block analyzers array lines
        options: {
          isWholeLine: true,
          className: 'editor-line-readonly',
        },
      },
    ]);
    // Disable line editing (handled from the CSS side but here as well to be safe)
    editor.onKeyDown((e) => {
      const isInBlockedRange =
        editor
          .getSelections()
          ?.findIndex((range) =>
            new monaco.Range(1, 0, analyzersLength + 1, 0).intersectRanges(
              range
            )
          ) !== -1; // Block analyzers array lines
      if (isInBlockedRange) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
  };

  const updateSearchIndexMutation = useMutation({
    mutationFn: (analyzerData: SearchIndexRequestData) =>
      updateSearchIndex(projectId, searchIndexId, analyzerData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useGetSearchIndexQuery', projectId, searchIndexId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetSearchIndexesQuery', projectId],
      });

      notification.success({
        message: 'Success',
        description: 'Search index updated successfully',
      });
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'there was a problem in updating the search index',
        reportBug: true,
      });
    },
  });

  const [dynamic, setDynamic] = useState(
    searchIndexData?.mappings.dynamic || false
  );

  useEffect(() => {
    if (searchIndexData) {
      setName(searchIndexData.name);
      setDynamic(searchIndexData.mappings.dynamic);
    }
  }, [searchIndexData]);

  const onToggelDynamic = () => {
    setDynamic((isDyanmic) => !isDyanmic);
  };

  const handleSave = (formData: CreateSearchIndexFormData) => {
    const { fields } = formData;

    if (!searchIndexData) return;

    const data = {
      name,
      modelId: searchIndexData.modelId,
      environmentId: searchIndexData.environmentId,
      mappings: {
        type: 'search',
        dynamic: dynamic,
        fields:
          fields?.map((field) => ({
            ...field,
            multi: field.multi?.map((multi) => ({
              ...multi,
              type: field.type,
            })),
          })) || [],
      },
    };

    updateSearchIndexMutation.mutate(data);
  };

  const error = isSearchIndexError || !searchIndexData;

  if (isSearchIndexLoading) {
    return (
      <Flex direction="column">
        <Spin />
      </Flex>
    );
  }

  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchSearchIndex();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={handleSave}
      initialValues={searchIndexData}
    >
      <Flex direction="column">
        <PageHeader
          title="Search Index details"
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              loading={updateSearchIndexMutation.isLoading}
            >
              Save
            </Button>
          }
          tabs={[
            {
              title: 'Details',
              active: true,
              id: 'details',
              onClick: () => {
                // handleSave(form.getFieldsValue());
              },
            },
          ]}
        />

        <Paper size="medium">
          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={10}>
              <Label required={true}>Search Index name</Label>
              <EditableText
                type="text"
                name="name"
                {...{ value: name, onSave: setName }}
                disabled={true}
              />
            </Flex>
            <Flex direction="column" gap={10}>
              <Label required={true}>Model</Label>
              <Flex>
                <StyledFieldType>
                  <Text appearance="caption.semibold">
                    {searchIndexData?.modelData.name}
                  </Text>
                </StyledFieldType>
              </Flex>
            </Flex>
            <Flex direction="column" gap={10}>
              <Label required={true}>Dynamic</Label>
              <Flex alignItems="center" gap={6}>
                <FormItem name="dynamic" marginBottom={0}>
                  <Switch onChange={onToggelDynamic} checked={dynamic} />
                </FormItem>
              </Flex>
            </Flex>
            <Show if={!dynamic}>
              <Flex direction="column" gap={10}>
                <Text appearance="label.short.regular">Field Mappings</Text>
              </Flex>
              <DynamicFields
                searchIndexData={searchIndexData}
                modelId={searchIndexData?.modelId}
                form={form}
              />
              <Tabs
                hidden // will implement it later
                onChange={(activeKey) => {
                  if (activeKey === 'json') {
                    formatFieldMapping();
                  } else {
                    parseFieldMapping();
                  }
                }}
                defaultActiveKey="visual"
                centered
                items={[
                  {
                    label: `Visual Editor`,
                    key: 'visual',
                    children: (
                      <>
                        <Flex direction="column" gap={10}>
                          <Text appearance="label.short.regular">
                            Field mappings
                          </Text>
                        </Flex>
                        <DynamicFields
                          searchIndexData={searchIndexData}
                          modelId={searchIndexData?.modelId}
                          form={form}
                        />
                      </>
                    ),
                  },

                  {
                    label: `JSON Editor`,
                    key: 'json',
                    children: (
                      <Flex direction="column" gap={10}>
                        <Editor
                          defaultLanguage="json"
                          language="json"
                          defaultValue={`{}`}
                          className="json_editor"
                          onChange={(value) => {
                            if (value) {
                              setFormattedFieldMapping(JSON.parse(value));
                            }
                          }}
                          options={{
                            hideCursorInOverviewRuler: false,
                            minimap: { enabled: false },
                            scrollBeyondLastLine: false,
                            fontSize: 16,
                          }}
                          value={JSON.stringify(
                            formattedFieldMapping,
                            null,
                            '\t'
                          )}
                          height={'60vh'}
                          onMount={handleEditorDidMount}
                        />
                      </Flex>
                    ),
                  },
                ]}
              />
            </Show>
          </Flex>
        </Paper>
      </Flex>
    </Form>
  );
};
