import { styled } from '@comet/styled';
import { Skeleton } from '@comet/blocks';
import { MdError } from 'react-icons/md';
import { Link } from '@comet/router';

export const ErrorIcon = styled(MdError)`
  color: ${({ theme }) => theme.palette.danger};
`;

export const Shimmer = styled(Skeleton.Input).attrs({
  active: true,
  size: 'small',
})`
  height: 14px;
  span {
    height: 14px !important;
  }
`;

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.neutral.darkest};
`;
