import { Button, Flex, Paper, Show, Text } from '@comet/blocks';
import { CardContainer } from '../styled';
import { Spin, Steps } from 'antd';
import { BsCheckCircleFill } from 'react-icons/bs';
import { NodeFormField } from '@comet/components/NodeEditor/NodeFormField';
import { ReviewApisAndModels } from './ReviewApisAndModels';
import { useApiTemplates } from './useApiTemplates';
import { STEPS } from '../Types';
import { getIcon } from './TemplateApis.utils';
import { IconBase } from './TemplateApis.styled';
import { useState } from 'react';
import { ExternalLink } from '@comet/components/ExternalLink';
import { MdOpenInNew } from 'react-icons/md';

export const ApiTemplates = () => {
  const {
    apiCreateData,
    data,
    step,
    selectedTemplate,
    setSelectedTemplate,
    template,
    templates,
    loading,
    onNext,
    onNodeFormFieldChange,
    onCancel,
    onCheckItem,
    values,
  } = useApiTemplates();

  const [isNextStepEnabled, setIsNextStepEnabled] = useState(true);
  return (
    <Paper size="medium">
      <Steps
        size="small"
        current={step}
        items={[
          {
            title: 'Select',
          },
          {
            title: 'Configure',
          },
          {
            title: 'Review',
          },
        ]}
        style={{ marginBottom: 20 }}
      />
      <ExternalLink
        href="https://docs.cosmocloud.io/cosmocloud-documentation/setup/6.-create-apis"
        appearance="caption.semibold"
        style={{ marginBottom: 10 }}
      >
        <MdOpenInNew />
        Learn More
      </ExternalLink>
      <Flex
        style={{
          flexFlow: 'row wrap',
        }}
      >
        <Show if={step === STEPS.SELECT}>
          {templates?.map((template) => {
            const isSelected = selectedTemplate === template.type;
            return (
              <CardContainer
                key={template.type}
                selected={isSelected}
                height={100}
                width={'45%'}
                onClick={() =>
                  template.active && setSelectedTemplate(template.type)
                }
                style={{
                  padding: '10px',
                  marginBottom: 10,
                  alignItems: 'flex-start',
                }}
                disabled={!template.active}
              >
                <IconBase>{getIcon(template.type)}</IconBase>
                {isSelected && (
                  <BsCheckCircleFill className="selected" size={16} />
                )}
                <Text style={{ marginTop: 10 }} appearance="body.regular">
                  {template.displayName}
                  {!template.active && (
                    <Text
                      style={{ textAlign: 'left' }}
                      appearance="caption.semibold"
                    >
                      Coming soon!
                    </Text>
                  )}
                </Text>
              </CardContainer>
            );
          })}
        </Show>
        <Show if={step === STEPS.CONFIGURE && !!template}>
          <Flex direction="column" width={'50%'}>
            <Text appearance="heading.card">{template?.displayName}</Text>
            {template?.schema[0]?.fields.map((item) => {
              return (
                <Flex
                  direction="column"
                  gap={10}
                  style={{ margin: '10px 0' }}
                  key={item.key}
                >
                  <Text appearance="body.regular">{item.displayName}</Text>
                  <NodeFormField
                    disabled={item.immutable}
                    type={item.type}
                    value={values[item.key]}
                    placeholder={item.placeholder || 'None'}
                    values={values}
                    options={item.values}
                    onChange={onNodeFormFieldChange}
                    fieldKey={item.key}
                    resolver={item.resolver}
                    setNextStepEnabled={setIsNextStepEnabled}
                  />
                </Flex>
              );
            })}
          </Flex>
        </Show>
        <Show if={step === STEPS.REVIEW && !!data}>
          <ReviewApisAndModels
            onChange={onCheckItem}
            values={apiCreateData}
            flows={data?.flows || []}
            models={data?.models || []}
          />
        </Show>
      </Flex>
      <Show if={loading}>
        <Spin size="small" />
      </Show>
      <Show if={!loading}>
        <Flex justifyContent="space-between" style={{ marginTop: 10 }}>
          <Button onClick={onCancel} appearance="transparent">
            {step === STEPS.SELECT ? 'Cancel' : 'Back'}
          </Button>
          <Button
            onClick={onNext}
            appearance="primary"
            disabled={!isNextStepEnabled}
          >
            {step === STEPS.REVIEW ? 'Finish' : 'Next'}
          </Button>
        </Flex>
      </Show>
    </Paper>
  );
};
