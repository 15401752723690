import { Button, ButtonContainer } from '@comet/blocks';

interface FormButtonsProps {
  onCancel: () => void;
  onNext: () => void;
  disabled: boolean;
  cancelButtonText: string;
  nextButtonText: string;
  isLoading?: boolean;
}

export const FormButtons = ({
  onNext,
  onCancel,
  disabled,
  cancelButtonText,
  nextButtonText,
  isLoading,
}: FormButtonsProps) => {
  return (
    <ButtonContainer>
      <Button onClick={onCancel} appearance="transparent">
        {cancelButtonText}
      </Button>
      <Button
        type="primary"
        onClick={onNext}
        disabled={disabled}
        loading={isLoading}
      >
        {nextButtonText}
      </Button>
    </ButtonContainer>
  );
};
