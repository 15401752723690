import { User } from '@comet/pages/Project/types/Projects.types';

export interface IAnalyzer {
  type: 'CUSTOM' | 'BUILT_IN' | '';
  value: string;
}

export interface ICustomAnalyzer {
  name: string;
  charFilters: string[];
  tokenizer: string;
  tokenFilters: string;
}
export interface Field {
  name: string;
  type: string;
  searchType: string;
  similarityFunction: string;
  noOfDimensions: number;
}

export interface FieldResponseData {
  searchType: string;
  similarityFunction: string;
  noOfDimensions: number;
  type?: string;
  similarity?: string;
  numDimensions?: number;
}

export interface FieldRequestData {
  name: string;
  searchType?: string;
  similarity: string;
  numDimensions: number;
  type: string;
  similarityFunction?: string;
  noOfDimensions?: number;
}

export interface SearchIndexMapping {
  defaultFields: any;
  fields: any;
}

export interface SearchIndex {
  name: string;
  modelId: string;
  environmentId: string;
  projectId: string;
  createdOn: number;
  updatedOn: number;
  createdBy: User;
  updatedBy: User;
  mappings: SearchIndexMapping;
  id: string;
  modelData: {
    name: string;
  };
}

export interface SearchIndexResponse extends Omit<SearchIndex, 'mappings'> {
  dynamic: boolean;
}

export interface GetSearchIndexesResponse {
  data: SearchIndexResponse[];
  page: {
    offset: number;
    limit: number;
    total: number;
  };
}

export interface CreateSearchIndexFormData {
  environmentId: string;
  fields: Field[];
  defaultFields: Field;
}

export interface SearchIndexRequestData {
  name: string;
  modelId: string;
  environmentId: string;
  mappings: {
    fields: FieldRequestData[];
  };
}

export interface FieldCheckObject {
  value: number;
  intital: boolean;
}

export enum STEPS {
  'DETAILS' = 0,
  'MAPPINGS' = 1,
}
