import {
  Flex,
  Text,
  Select,
  Avatar,
  Show,
  FullCenter,
  Spin,
} from '@comet/blocks';
import { Empty } from 'antd';
import EmptyImage from '@comet/assets/png/empty.png';
import { BsPersonFill } from 'react-icons/bs';
import { Member, RemoveUserAccessData, UpsertUserAccessData } from '../types';
import { useEffect, useState } from 'react';
import { getRandomAppearance } from './MemberList.utils';
import {
  MemberComponent,
  MembersContainer,
  StyledText,
} from './MemberList.styled';
import { useAuth } from '@comet/components/auth/AuthProvider';
import DeleteMember from './Member.Delete';

interface IMemberListProps {
  type: 'organisation' | 'project';
  members: Member[];
  roles: {
    label: string;
    value: number;
  }[];
  total: number;
  showEmptyScreen: boolean;
  isLoading: boolean;
  showMemberList: boolean;
  onUpdate: (data: UpsertUserAccessData) => void;
  onDelete: (data: RemoveUserAccessData) => void;
  hasEditAccess?: boolean;
}

export const MemberList = ({
  members,
  roles,
  total,
  isLoading,
  showEmptyScreen,
  onUpdate,
  onDelete,
  hasEditAccess,
  type,
}: IMemberListProps) => {
  const { user: currentUser } = useAuth();

  const [membersPermission, setMembersPermission] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const initialMembersPermission = members.reduce(
      (acc: { [key: string]: number }, curr: Member) => {
        acc[curr.id] = curr.role;

        return acc;
      },
      {}
    );

    setMembersPermission(initialMembersPermission);
  }, [members]);

  const onPermissionChange = (id: string, role: number) => {
    setMembersPermission((membersPermission) => ({
      ...membersPermission,
      [id]: role,
    }));
    const updatedUserAccessData: UpsertUserAccessData = {
      users: [
        {
          id,
          role,
        },
      ],
      invites: {
        emails: [],
        role: null,
      },
    };

    onUpdate(updatedUserAccessData);
  };

  return (
    <Flex direction="column" gap={12} padding="0 6px">
      <Flex justifyContent="space-between" padding="0 30px 0 0">
        <StyledText appearance="body.regular">{total} members</StyledText>
        <StyledText appearance="body.regular">Permission</StyledText>
      </Flex>
      <Show if={isLoading}>
        <FullCenter>
          <Spin size="large" />
        </FullCenter>
      </Show>
      <Show if={showEmptyScreen}>
        <Empty
          image={EmptyImage}
          imageStyle={{ height: 133 }}
          description={<Text appearance="body.regular">No users found</Text>}
        />
      </Show>
      <MembersContainer direction="column">
        <Show if={!isLoading}>
          {members?.map((member) => {
            const isCurrentUser = currentUser?.id === member.id;
            const removeAccessData: RemoveUserAccessData = {
              userIds: [member.id],
              emails: [],
            };

            return (
              <MemberComponent key={member.id}>
                <Flex gap={10} alignItems="center">
                  <Avatar
                    appearance={getRandomAppearance()}
                    shape="square"
                    icon={<BsPersonFill />}
                  />
                  <Flex direction="column" gap={2}>
                    <Text appearance="label.long.regular">{member.name}</Text>
                    <StyledText appearance="caption.regular">
                      {member.email}
                    </StyledText>
                  </Flex>
                </Flex>
                <Flex gap={10} alignItems="center">
                  <Select
                    options={roles}
                    value={membersPermission[member.id]}
                    style={{ width: 120 }}
                    onChange={(value: number) =>
                      onPermissionChange(member.id, value)
                    }
                    disabled={!hasEditAccess || isCurrentUser}
                  />
                  <DeleteMember
                    type={type}
                    onDelete={() => onDelete(removeAccessData)}
                    visible={hasEditAccess && !isCurrentUser}
                  />
                </Flex>
              </MemberComponent>
            );
          })}
        </Show>
      </MembersContainer>
    </Flex>
  );
};
