import { Button, Flex, Input, Modal, Text } from '@comet/blocks';
import { useNavigate, useParams } from '@comet/router';
import { styled } from '@comet/styled';
import { useQuery } from '@tanstack/react-query';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RiAlertLine } from 'react-icons/ri';
import { sendErrorNotification } from 'src/blocks/Notification';
import {
  resendOTPToDeleteProject,
  sendOTPToDeleteProject,
  verifyOTPToDeleteProject,
} from '../service';
import { GetProjectResponse } from '../types/Projects.types';
import './styles.css';
import { notification } from 'antd';
import { queryClient } from '@comet/query';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

interface IDeleteProjectModalProps {
  projectData: GetProjectResponse;
  toggleModal: () => void;
}

const DeleteProjectModal = memo(
  ({ projectData, toggleModal }: IDeleteProjectModalProps) => {
    const navigate = useNavigate();
    const { projectId, organisationId } = useParams();
    const [otpSent, setOTPSent] = useState(false);
    const [otpError, setOTPError] = useState('');
    const [otpInput, setotpInput] = useState('');
    const [disableOTPTimer, setDisableOTPTimer] = useState(0);
    const timerRef = useRef<NodeJS.Timer>();
    const onOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newOtp = e.target.value;
      if (newOtp.length > 0 && (isNaN(parseInt(newOtp)) || newOtp.length > 6)) {
        return;
      }
      setotpInput(newOtp);
      setOTPError('');
    };

    const onSendOTP = useCallback(() => {
      setOTPSent(true);
      setDisableOTPTimer(60);
      timerRef.current = setInterval(() => {
        setDisableOTPTimer((prev) => prev - 1);
      }, 1000);
    }, []);

    useEffect(() => {
      if (disableOTPTimer <= 0) {
        clearInterval(timerRef.current);
      }
    }, [disableOTPTimer]);

    const { refetch: sendOtp } = useQuery({
      queryKey: ['delete-project-send-otp'],
      queryFn: () => sendOTPToDeleteProject(organisationId, projectId),
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: onSendOTP,
      onError: (error: any) => {
        sendErrorNotification({
          message: 'Error',
          description: error.response.data.message,
          reportBug: true,
        });
      },
    });

    const { refetch: resendOtp } = useQuery({
      queryKey: ['delete-project-resend-otp'],
      queryFn: () => resendOTPToDeleteProject(organisationId, projectId),
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: onSendOTP,
      onError: (error: any) => {
        sendErrorNotification({
          message: 'Error',
          description: error.response.data.message,
          reportBug: true,
        });
      },
    });

    const { refetch: verifyOtp } = useQuery({
      queryKey: ['delete-project-verify-otp'],
      queryFn: () =>
        verifyOTPToDeleteProject(organisationId, projectId, otpInput),
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        setOTPError(error.response.data.message);
      },
      onSuccess: (data) => {
        if (data.data.verified) {
          queryClient.invalidateQueries([
            'useGetProjectsQuery',
            'useGetOrgSubscription',
          ]);
          notification.success({
            message: 'Success',
            description: 'Project Deleted',
          });
          toggleModal();
          navigate('organisations.organisationId.projects', { organisationId });
        } else {
          setOTPError('Invalid OTP');
        }
      },
    });

    const onDelete = async () => {
      if (!otpSent) {
        sendOtp();
        return;
      }
      verifyOtp();
    };
    const isValidOTPInput = useMemo(() => {
      if (!otpSent) {
        return true;
      }
      return otpInput.length === 6 && !isNaN(parseInt(otpInput));
    }, [otpSent, otpInput]);
    if (projectData.type === 'PRO') {
      return (
        <Modal
          className="deletion-modal"
          width={560}
          title={null}
          open
          closable={false}
          destroyOnClose={true}
          footer={null}
          okButtonProps={{
            type: 'primary',
          }}
        >
          <Flex
            direction="row"
            gap={16}
            padding={20}
            style={{
              borderRadius: 8,
            }}
          >
            <Flex>
              <StyledAlertIcon
                style={{ height: 20, width: 20, color: 'rgba(181, 45, 67, 1)' }}
              />
            </Flex>
            <Flex gap={10} direction="column" style={{ flex: 1 }}>
              <Text appearance="body.semiBold">Delete Project</Text>
              <Text appearance="body.semiBold">
                Please contact Cosmocloud support to delete your project
              </Text>
              <Flex gap={8} justifyContent="end" style={{ marginTop: 20 }}>
                <Button
                  onClick={toggleModal}
                  appearance="transparent"
                  htmlType="button"
                >
                  Okay
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      );
    }
    return (
      <Modal
        className="deletion-modal"
        width={560}
        title={null}
        open
        closable={false}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex
            direction="row"
            gap={16}
            padding={12}
            style={{
              backgroundColor: 'rgba(181, 45, 67, 1)',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <Flex>
              <StyledAlertIcon
                style={{ height: 20, width: 20, color: '#fff' }}
              />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="body.semiBold" color="neutral.white">
                Delete Project
              </Text>
              <Text appearance="body.regular" color="neutral.white">
                Are you sure you want to delete this project?{' '}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" style={{ paddingInline: 20 }}>
            <Text appearance="body.semiBold" style={{ marginTop: 12 }}>
              When you delete a project, this immediately happens:
            </Text>
            <ul>
              <li>All billing and traffic serving stops.</li>
              <li>
                You lose access to your entire project, including your APIs,
                Models and releases.
              </li>
              <li>
                Project owners will be notified and can restore the deleted
                project within 30 days from Cosmocloud platform.
              </li>
            </ul>
            <Flex style={{ marginTop: 12, width: '100%' }} direction="column">
              <Text appearance="label.short.semiBold">
                Name of your project
              </Text>
              <Input
                disabled
                style={{
                  marginTop: 8,
                  width: '100%',
                  borderRadius: 8,
                  padding: 8,
                  borderWidth: 1,
                  fontWeight: 'bold',
                }}
                value={projectData?.name}
              />
            </Flex>
            {otpSent && (
              <Flex style={{ marginTop: 12, width: '100%' }} direction="column">
                <Text appearance="label.short.semiBold">
                  Enter OTP to continue
                </Text>
                <Input
                  style={{
                    marginTop: 8,
                    width: '100%',
                    borderRadius: 8,
                    padding: 8,
                    borderWidth: 1,
                    borderColor: otpError.length
                      ? 'rgba(181, 45, 67, 1)'
                      : undefined,
                    color: otpError.length ? 'rgba(181, 45, 67, 1)' : undefined,
                    fontWeight: 'bold',
                  }}
                  value={otpInput}
                  onChange={onOtpChange}
                />
                {otpError.length > 0 && (
                  <Text
                    appearance="label.short.regular"
                    style={{ color: 'rgba(181, 45, 67, 1)', marginTop: 4 }}
                  >
                    {otpError}
                  </Text>
                )}
              </Flex>
            )}
            {otpSent && (
              <div
                onClick={() => {
                  if (!disableOTPTimer) {
                    resendOtp();
                  }
                }}
                style={{
                  cursor: disableOTPTimer ? 'not-allowed' : 'pointer',
                  alignSelf: 'flex-end',
                  marginTop: 4,
                  color: disableOTPTimer ? '#9a9a9a' : undefined,
                }}
              >
                Resend OTP{' '}
                {disableOTPTimer
                  ? `after ${disableOTPTimer} second${
                      disableOTPTimer > 1 ? 's' : ''
                    }`
                  : ''}
              </div>
            )}
            <Flex gap={8} justifyContent="end" style={{ marginBlock: 20 }}>
              <Button
                onClick={toggleModal}
                appearance="transparent"
                htmlType="button"
              >
                Cancel
              </Button>
              <Button
                onClick={onDelete}
                appearance="alert"
                htmlType="button"
                disabled={!isValidOTPInput || !!otpError}
              >
                {otpSent ? 'Delete Project' : 'Send OTP'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    );
  }
);

export default DeleteProjectModal;
