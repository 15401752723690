import { ConfigProvider } from 'antd';
import { ThemeConfig } from 'antd/es/config-provider/context';
import enUS from 'antd/locale/en_US';
import { useTheme } from '@comet/styled';
import 'antd/dist/reset.css';

interface IProps {
  children: React.ReactNode;
}

export const BaseProvider = ({ children }: IProps) => {
  const { theme } = useTheme();
  const themeConfig: ThemeConfig = {
    token: {
      colorPrimary: theme.palette.primary,
      colorInfo: theme.palette.primary,
      colorSuccess: theme.palette.success.dark,
      colorError: theme.palette.danger,
    },
  };
  return (
    <ConfigProvider locale={enUS} theme={themeConfig}>
      {children}
    </ConfigProvider>
  );
};
