import { useState } from 'react';
import ReleaseElement from './Release.Element';
import { FormButtonContainer } from './Release.Styled';
import { Button, FormItem } from '@comet/blocks';
import { useParams } from '@comet/router';
import { useModelsQuery } from '@comet/pages/Model/service';
import { useApisQuery } from '@comet/pages/Api/Service';
import { useGetSubflowsQuery } from '@comet/pages/Project/Subflows/service';

type SelectedValues = {
  [key: string]: boolean;
};

interface ReleaseSelectionProps {
  onCancel: () => void;
  onNext: (apiIds: string[], modelIds: string[], subflowIds: string[]) => void;
}
export const ReleaseSelection = ({
  onCancel,
  onNext,
}: ReleaseSelectionProps) => {
  const { projectId } = useParams();
  const [selectedApiIds, setSelectedApiIds] = useState<SelectedValues>({});
  const [selectedModelIds, setSelectedModelIds] = useState<SelectedValues>({});
  const [selectedSubflowIds, setSelectedSubflowIds] = useState<SelectedValues>(
    {}
  );

  const apisQueryResult = useApisQuery(projectId, {
    flow_type: 'API',
    version: null,
    draft: true,
    limit: 1000,
    offset: 0,
  });

  const subflowQueryResult = useGetSubflowsQuery(projectId, {
    flow_type: 'FUNCTION',
    version: null,
    draft: true,
    limit: 1000,
    offset: 0,
  });

  const modelsQueryResult = useModelsQuery(projectId, {
    model_type: null,
    version: null,
    draft: true,
    limit: 1000,
    offset: 0,
  });

  const handleNextClick = () => {
    const apiIds = Object.keys(selectedApiIds);
    const modelIds = Object.keys(selectedModelIds);
    const subflowIds = Object.keys(selectedSubflowIds);

    onNext(apiIds, modelIds, subflowIds);
  };

  const isApisPanelActive = (apisQueryResult.data?.data?.length || 0) > 0;
  const isModelsPanelActive =
    !isApisPanelActive && (modelsQueryResult.data?.data?.length || 0) > 0;
  const isSubflowsPanelActive =
    !isApisPanelActive && (subflowQueryResult.data?.data?.length || 0) > 0;

  const isNextDisabled =
    Object.keys(selectedApiIds).length === 0 &&
    Object.keys(selectedModelIds).length === 0 &&
    Object.keys(selectedSubflowIds).length === 0;

  return (
    <>
      <ReleaseElement
        selectedValues={selectedApiIds}
        setSelectedValues={setSelectedApiIds}
        elementName="APIs"
        isActive={isApisPanelActive}
        draftsQueryResult={apisQueryResult}
      />
      <ReleaseElement
        selectedValues={selectedModelIds}
        setSelectedValues={setSelectedModelIds}
        elementName="Models"
        isActive={isModelsPanelActive}
        draftsQueryResult={modelsQueryResult}
      />
      <ReleaseElement
        selectedValues={selectedSubflowIds}
        setSelectedValues={setSelectedSubflowIds}
        elementName="SubFlows"
        isActive={isSubflowsPanelActive}
        draftsQueryResult={subflowQueryResult}
      />
      <FormButtonContainer>
        <FormItem marginBottom={'0px'}>
          <Button onClick={onCancel} appearance="transparent" htmlType="button">
            Cancel
          </Button>
        </FormItem>
        <FormItem marginBottom={'0px'}>
          <Button
            onClick={handleNextClick}
            appearance="primary"
            htmlType="button"
            disabled={isNextDisabled}
          >
            Next
          </Button>
        </FormItem>
      </FormButtonContainer>
    </>
  );
};
