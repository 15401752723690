import { BusinessNodeEnum, NodeRaw } from 'src/FlowEngine2/types';
import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

import { addNodeFromAddNode } from './addNodeFromNode';
import { addNodeFromEdge } from './addNodeFromEdge';
import { notification } from 'antd';

export function addBaseNode(
  type: 'ADD_NODE' | 'EDGE', // A Base Node can be added either from add_node or edge
  id: string | null, // id can be of node or edge
  nodeToAdd: NodeRaw | null
) {
  if (!id || !nodeToAdd) return;
  const { createNode } = useFlowEngineStore.getState();

  switch (type) {
    case 'ADD_NODE': {
      if (nodeToAdd.data.type === BusinessNodeEnum.HTTP_TRIGGER) {
        notification.error({ message: 'Error', description: 'Not allowed' });
        return;
      }

      const baseNode = getNodeById(createNode(nodeToAdd));
      if (!baseNode) {
        return new Error('Error in creating base node!');
      }

      addNodeFromAddNode(id, baseNode, [baseNode]);
      break;
    }
    case 'EDGE': {
      if (
        [
          BusinessNodeEnum.HTTP_TRIGGER,
          BusinessNodeEnum.HTTP_RESPONSE,
          BusinessNodeEnum.FUNCTION_RESPONSE,
        ].includes(nodeToAdd.data.type)
      ) {
        notification.error({
          message: 'Error',
          description:
            'It is not possible to add this node from between the edge',
        });
        return;
      }

      const baseNode = getNodeById(createNode(nodeToAdd));
      if (!baseNode) return new Error(`Erron in creating baseNode`);

      addNodeFromEdge(id, baseNode, [baseNode]);
      break;
    }
    default:
  }
}
