import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { styled, useTheme } from '@comet/styled';
import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
  Tooltip,
} from '@comet/blocks';
import { Link, useParams } from '@comet/router';
import { GetSubflowsResponse } from '@comet/pages/Project/Subflows/types';
import { FiMoreHorizontal } from 'react-icons/fi';
import { RiAlertLine } from 'react-icons/ri';
import {
  deleteSubflow,
  updateSubflow,
} from '@comet/pages/Project/Subflows/service';
import { notification } from 'antd';
import { StatusComponent } from '@comet/components/TableComponents';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

export const SubflowActions = ({
  id,
  refetch,
  active,
}: {
  id: string;
  refetch: () => void;
  active: boolean;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onToggleStatus = useCallback(
    async (subflowId: string, currentStatus: boolean) => {
      await updateSubflow(projectId, subflowId, {
        flowType: 'FUNCTION',
        active: !currentStatus,
      });
      refetch();
    },
    [projectId, refetch]
  );

  const handleApiDelete = useCallback(async () => {
    await deleteSubflow(projectId, id);
    refetch();
    notification.success({
      message: 'Success',
      description: 'SubFlow deleted successfully',
    });
    toggleModal();
  }, [projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              key: 'statusEnable',
              label: active ? 'Disable' : 'Enable',
              onClick: () => onToggleStatus(id, active),
            },
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip title={'This will delete the SubFlow'}>Delete</Tooltip>
              ),
              onClick: toggleModal,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this SubFlow ?
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this SubFlow.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button
              onClick={handleApiDelete}
              appearance="primary"
              htmlType="button"
            >
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useSubflowListingData = (
  subflowsData: GetSubflowsResponse | undefined,
  version: string | null,
  latest: string | null,
  refetchSubflows: () => void
) => {
  const { theme } = useTheme();
  const { organisationId, projectId } = useParams();

  const tableData = useMemo(() => {
    if (!subflowsData) return [];

    const subflowsList = subflowsData.data;
    return subflowsList.map((subflow) => {
      let flowVersion = null;

      if (subflow.draft) flowVersion = null;
      else if (version) flowVersion = version;
      else flowVersion = latest;

      const statusText = getStatus({
        draft: subflow.draft,
        active: subflow.active,
      });
      const status = SubflowListingStateMapping[statusText]['title'];
      const statusColor = SubflowListingStateMapping[statusText]['color'];

      return {
        id: subflow.id,
        name: (
          <Link
            routeId="organisations.organisationId.projects.projectId.subflows.subflowId.details"
            pathParam={{
              organisationId,
              projectId,
              subflowId: subflow.id,
            }}
            state={{ version: flowVersion, draft: subflow.draft }}
          >
            {subflow.name}
          </Link>
        ),
        description: subflow.description.slice(0, 50),
        arguments: subflow?.flowMetadata?.functionArguments
          ? subflow.flowMetadata.functionArguments.length
          : 0,
        returnTypes: subflow?.flowMetadata?.functionReturnTypeFields
          ? subflow.flowMetadata.functionReturnTypeFields.length
          : 0,
        deployedVersion: subflow.version || '_',
        status: <StatusComponent color={statusColor}>{status}</StatusComponent>,
        action: (
          <SubflowActions
            id={subflow.id}
            active={subflow.active}
            refetch={refetchSubflows}
          />
        ),
      };
    });
  }, [subflowsData, theme]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Description" sortable />,
        accessor: 'description',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Status" sortable />,
        accessor: 'status',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
      },
    ];
  }, []);

  return { tableColumns, tableData };
};

export const getStatus = (item: { draft: boolean; active: boolean }) => {
  if (item.draft && !item.active) {
    return 'DRAFT_INACTIVE';
  }
  if (item.draft && item.active) {
    return 'DRAFT';
  }

  if (item.active) {
    return 'DEPLOYED';
  }

  return 'INACTIVE';
};

export const SubflowListingStateMapping: any = {
  DRAFT_INACTIVE: {
    title: 'Draft as Inactive',
    color: '#D4D1CE',
  },
  DRAFT: { title: 'Saved as draft', color: '#D4D1CE' },
  DEPLOYED: { title: 'Deployed', color: '#6AD1B2' },
  INACTIVE: { title: 'Inactive', color: '#D16E4E' },
};
