import {
  Button,
  ButtonContainer,
  Divider,
  Flex,
  Form,
  FormItem,
  Input,
  Text,
  TextArea,
} from '@comet/blocks';
import Arguments from './Arguments';
import ReturnType from './ReturnType';
import { MdOpenInNew } from 'react-icons/md';
import { FormContainer } from '../subflows.styled';
import { useGoBack, useNavigate, useParams } from '@comet/router';
import { queryClient, useMutation } from '@comet/query';
import { createSubflow } from '../service/subflows.service';
import { ExternalLink } from '@comet/components/ExternalLink';
import { SubflowDetailsRaw } from '@comet/pages/Project/Subflows/types';
import { sendErrorNotification } from 'src/blocks/Notification';
import { notification } from 'antd';

const CreateSubflow = () => {
  const goBack = useGoBack();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { organisationId, projectId } = useParams();

  const cancelSubflowCreation = () => {
    goBack();
  };

  const createSubflowMutation = useMutation({
    mutationFn: (subflowData: SubflowDetailsRaw) =>
      createSubflow(projectId, subflowData),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: ['useGetSubflowsQuery', projectId, {}],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      notification.success({
        message: 'Subflow created',
        description: 'Subflow has been created successfully',
      });
      navigate(
        'organisations.organisationId.projects.projectId.subflows.subflowId.details',
        {
          organisationId,
          projectId,
          subflowId: id,
          version: 'draft',
        },
        {
          state: {
            draft: true,
            version: null,
          },
        }
      );
    },
    onError: (error) => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to create subflow',
      });
    },
  });

  const onCreateSubflow = () => {
    const formData = form.getFieldsValue();
    let metadata = {};
    if (formData.functionArguments) {
      metadata = {
        ...metadata,
        functionArguments: formData.functionArguments.map((item: any) => {
          return {
            key: item.key,
            type: item.keyType,
            required: item.required,
            default: item.required ? null : item.default || null,
          };
        }),
      };
    }
    if (formData.functionReturnTypeFields) {
      metadata = {
        ...metadata,
        functionReturnTypeFields: formData.functionReturnTypeFields.map(
          (item: any) => {
            return {
              key: item.key,
              type: item.keyType,
              displayName: item.displayName,
            };
          }
        ),
      };
    }

    const modifiedData = {
      name: formData.name,
      description: formData.description,
      flowType: 'FUNCTION',
      flowMetadata: metadata,
    };
    createSubflowMutation.mutate(modifiedData);
  };

  return (
    <FormContainer size="medium">
      <Form form={form} layout="vertical" onFinish={onCreateSubflow}>
        <Flex alignItems="baseline" gap={15} justifyContent="space-between">
          <Text style={{ marginBottom: 24 }} appearance="heading.card">
            Create a new Subflow
          </Text>
          <ExternalLink
            href="https://docs.cosmocloud.io/cosmocloud-documentation/setup/6.-create-apis"
            appearance="caption.semibold"
          >
            <MdOpenInNew />
            Learn More
          </ExternalLink>
        </Flex>
        <FormItem
          name="name"
          label="Subflow name"
          required
          rules={[
            {
              required: true,
              whitespace: false,
              message: 'Subflow name is required.',
            },
          ]}
        >
          <Input placeholder="Enter subflow name..." />
        </FormItem>
        <FormItem
          name="description"
          label="Description"
          marginBottom={0}
          required
          rules={[
            {
              required: true,
              whitespace: false,
              message: 'Description is required.',
            },
          ]}
        >
          <TextArea
            placeholder="Add a description to make it easier for your teammates to discover and use the right version of your Subflow..."
            maxLength={240}
            rows={4}
            showCount
          />
        </FormItem>
        <FormItem
          label={<Text appearance="label.long.semiBold">Arguments</Text>}
          colon={false}
          marginBottom={0}
        >
          <Text
            appearance="caption.longText"
            color="neutral.dark"
            style={{ marginBottom: 15 }}
          >
            Enter the arguments that you want to pass to the Subflow.
          </Text>
        </FormItem>

        <Arguments form={form} />

        {/**
         * <FormItem
          label={<Text appearance="label.long.semiBold">Return value</Text>}
          colon={false}
          marginBottom={0}
        >
          <Text
            appearance="caption.longText"
            color="neutral.dark"
            style={{ marginBottom: 15 }}
          >
            Enter the return values that you will receive from Subflow. These
            are not intended for validating your output
          </Text>
        </FormItem>

        <ReturnType />
         */}

        <Divider spread={24} />
        <ButtonContainer>
          <Button
            onClick={cancelSubflowCreation}
            appearance="transparent"
            htmlType="button"
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            htmlType="submit"
            loading={createSubflowMutation.isLoading}
          >
            Create
          </Button>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};

export default CreateSubflow;
