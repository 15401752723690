import { Menu, MenuProps } from '@comet/blocks';
import { useNavigate, useParams } from '@comet/router';
import { useProjectMenuItems } from './Sidebar.project.menu';
import { get } from 'lodash';
import { SideBarType, SidebarMenuItem } from './types';
import { useMemo } from 'react';
import { ConfigProvider } from 'antd';
import { useTheme } from '@comet/styled';
import { ThemeConfig } from 'antd/es/config-provider/context';
import { useOrgMenuItems } from './Sidebar.org.menu';

const SIDEBAR_WIDTH = '240px';

interface SideBarProps {
  type: SideBarType;
}

export const SideBar = ({ type }: SideBarProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const OrgMenuItems = useOrgMenuItems();
  const ProjectMenuItems = useProjectMenuItems();
  const items = useMemo(
    () => (type === 'ORG' ? OrgMenuItems : ProjectMenuItems),
    [OrgMenuItems, ProjectMenuItems, type]
  );

  const onMenuItemClick: MenuProps['onClick'] = ({ key }) => {
    const menuItem = get(items, key) as SidebarMenuItem;
    if (menuItem.route) navigate(menuItem.route, params);

    if (menuItem.href) window.open(menuItem.href, '_blank');
  };

  const { theme } = useTheme();
  const themeConfig: ThemeConfig = {
    token: {
      controlItemBgActive: '#EEEEEE',
      colorText: theme.palette.neutral.darkest,
      colorPrimary: theme.palette.neutral.darkest,
    },
  };

  return (
    <ConfigProvider theme={themeConfig}>
      <Menu
        items={items}
        style={{ height: '100%', width: SIDEBAR_WIDTH }}
        mode="inline"
        onClick={onMenuItemClick}
        defaultOpenKeys={['1']}
        defaultSelectedKeys={['0']}
      />
    </ConfigProvider>
  );
};
