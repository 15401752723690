import { Paper } from '@comet/blocks';
import { useLocation, useNavigate } from '@comet/router';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { CreateDatabaseForm } from './CreateDatabase.Form';

export const CreateDatabase = () => {
  const location = useLocation();
  const stateData = location.state;

  const { hasManageProjectSettingsAccess } = useProjectPermissions();

  if (!hasManageProjectSettingsAccess) {
    return <ErrorPage />;
  }

  return (
    <Paper size="medium">
      <CreateDatabaseForm environmentId={stateData.envId} />
    </Paper>
  );
};
