import { Flex, Show, Text } from '@comet/blocks';
import { useOrgQuery } from '@comet/pages/Organisation/service';
import { MdApartment } from 'react-icons/md';
import { ErrorIcon, Shimmer, StyledLink } from './Header.common';

interface Props {
  organisationId: string;
}

export const HeaderBreadcrumbOrg = ({ organisationId }: Props) => {
  const { data, isLoading, isError } = useOrgQuery(organisationId);

  return (
    <Flex alignItems="center">
      <StyledLink
        routeId="organisations.organisationId.projects"
        pathParam={{ organisationId }}
      >
        <Flex alignItems="center" gap={4}>
          <Show if={!isError}>
            <MdApartment />
          </Show>
          <Show if={isLoading}>
            <Shimmer />
          </Show>
          <Show if={!!data}>
            <Text appearance="label.short.regular">{data?.name}</Text>
          </Show>
        </Flex>
      </StyledLink>
      <Show if={isError}>
        <Flex alignItems="center" gap={4}>
          <ErrorIcon />
          <Text appearance="label.short.regular">Can't fetch Organisation</Text>
        </Flex>
      </Show>
    </Flex>
  );
};
