import { Input, InputNumber, InputUrl, Select, Switch } from '@comet/blocks';

import { AsyncSelect } from './NodeEditor.AsyncSelect';
import { ConditionQuery, ConditionQueryEditor } from '../ConditionQuery';
import { DynamicFields } from '@comet/components/DynamicFields';
import { EditorNode } from '../FlowEngine/FlowEngine.store';
import { FieldTypeEnum } from '../FlowEngine/Nodes/BusinessNode';
import { InputList } from 'src/blocks/Input/InputList';
import { JSONSnippet } from 'src/blocks/JSONSnippet';
import { ListStateValue } from '../StateValueField/ListStateValue';
import { StateValue } from '../StateValueField';
import StateValue2 from 'src/FlowEngine2/Components/StateValue2';
import { convertToOptions } from './NodeEditor.utils';

export interface NodeFormFieldProps {
  type: FieldTypeEnum;
  value: any;
  values?: Record<string, any>;
  onChange: (key: string, value: NodeFormFieldProps['value']) => void;
  fieldKey: string;
  disabled: boolean;
  options?: string[];
  resolver?: string;
  setNextStepEnabled?: (enabled: boolean) => void;
  placeholder?: string;
}

export const NodeFormField = ({
  type,
  value,
  values,
  onChange,
  fieldKey,
  disabled,
  options,
  resolver,
  editorNode,
  setNextStepEnabled,
  placeholder,
}: NodeFormFieldProps & { editorNode?: EditorNode | null }) => {
  switch (type) {
    case 'INPUT_URL': {
      return (
        <InputUrl
          disabled={disabled}
          value={value}
          onChange={(s) => onChange(fieldKey, s)}
        />
      );
    }
    case 'INPUT':
    case 'INPUT_NUMBER': {
      return (
        <Input
          disabled={disabled}
          value={value}
          type={type === 'INPUT_NUMBER' ? 'number' : 'text'}
          onChange={(event) => {
            onChange(fieldKey, event.target.value);
          }}
          placeholder={placeholder}
        />
      );
    }
    case FieldTypeEnum.SELECT:
    case FieldTypeEnum.MULTI_SELECT: {
      if (resolver) {
        return (
          <AsyncSelect
            type={type}
            value={value}
            values={values}
            onChange={(value) => onChange(fieldKey, value)}
            fieldKey={fieldKey}
            disabled={disabled}
            options={options}
            resolver={resolver}
            placeholder={placeholder}
          />
        );
      }
      return (
        <Select
          disabled={disabled}
          style={{ width: '100%' }}
          defaultValue={value}
          onChange={(value) => onChange(fieldKey, value)}
          options={convertToOptions(options)}
          placeholder={placeholder}
          {...(type === 'MULTI_SELECT' && { mode: 'multiple' })}
        />
      );
    }
    case FieldTypeEnum.SELECT_SEARCHABLE:
    case FieldTypeEnum.MULTI_SELECT_SEARCHABLE: {
      if (resolver) {
        return (
          <AsyncSelect
            type={type}
            value={value}
            values={values}
            onChange={onChange}
            fieldKey={fieldKey}
            disabled={disabled}
            options={options}
            resolver={resolver}
            placeholder={placeholder}
            {...(type === 'MULTI_SELECT_SEARCHABLE' && { mode: 'multiple' })}
          />
        );
      }
      return (
        <Select
          disabled={disabled}
          style={{ width: 240 }}
          defaultValue={value}
          showSearch
          onChange={(value) => onChange(fieldKey, value)}
          options={convertToOptions(options)}
          placeholder={placeholder}
          {...(type === 'MULTI_SELECT_SEARCHABLE' && { mode: 'multiple' })}
        />
      );
    }
    case 'BOOLEAN': {
      return (
        <Switch
          disabled={disabled}
          checked={value}
          onChange={(checked) => {
            onChange(fieldKey, checked);
          }}
        />
      );
    }
    case FieldTypeEnum.INPUT_NUMBER: {
      return (
        <InputNumber
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(fieldKey, event.target.value)}
        />
      );
    }
    case FieldTypeEnum.LIST_INPUT: {
      let metadata;

      if (
        ['SWITCH_CASE', 'SWITCH_CASE_V2'].includes(editorNode?.data.type ?? '')
      ) {
        metadata = editorNode?.data.metadata?.cases ?? [];
      } else {
        metadata = editorNode?.data.metadata;
      }

      return (
        <InputList
          metadata={metadata}
          onChange={(values: string[]) => onChange(fieldKey, values)}
          node={editorNode}
          state={value}
        />
      );
    }

    case FieldTypeEnum.JSON_CODE_SNIPPET: {
      return (
        <JSONSnippet
          value={value}
          onChange={(value) => onChange(fieldKey, value)}
        />
      );
    }

    case FieldTypeEnum.STATE_VALUE: {
      return (
        <StateValue2
          key={editorNode?.id + fieldKey}
          onChange={(value) => onChange(fieldKey, value)}
          value={value}
          placeholder={placeholder}
        />
      );
    }

    case FieldTypeEnum.LIST_STATE_VALUE: {
      return (
        <ListStateValue
          value={value}
          onChange={(value) => onChange(fieldKey, value)}
        />
      );
    }

    case FieldTypeEnum.QUERY_MODEL: {
      return (
        <ConditionQueryEditor
          onComplete={(value) => onChange(fieldKey, value)}
          value={value}
        />
      );
    }

    case FieldTypeEnum.DYNAMIC_FIELDS: {
      return (
        <DynamicFields
          value={value}
          values={values}
          onChange={onChange}
          fieldKey={fieldKey}
          disabled={disabled}
          options={options}
          resolver={resolver}
          setNextStepEnabled={setNextStepEnabled}
        />
      );
    }

    default: {
      return null;
    }
  }
};
