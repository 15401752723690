import { useParams } from '@comet/router';
import { useQuery } from '@comet/query';
import { getAxios } from '@comet/axios';
import {
  GetSearchIndexesResponse,
  SearchIndex,
  SearchIndexRequestData,
} from '../types';

export const useGetSearchIndexesQuery = (environmentId: string) => {
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['useGetSearchIndexesQuery', projectId, environmentId],
    queryFn: () => getSearchIndexes(projectId, environmentId),
    enabled: !!environmentId,
  });
};

export const getSearchIndexes = async (
  projectId: string,
  environmentId: string
) => {
  const url = `/resources/${projectId}/search-indexes`;
  const { data } = await getAxios().get<GetSearchIndexesResponse>(url, {
    params: {
      type: 'search',
      environmentId,
    },
  });

  return data;
};

export const useGetSearchIndexQuery = () => {
  const { projectId, searchIndexId } = useParams();

  return useQuery({
    queryKey: ['useGetSearchIndexQuery', projectId, searchIndexId],
    queryFn: () => getSearchIndex(projectId, searchIndexId),
  });
};

export const getSearchIndex = async (
  projectId: string,
  searchIndexId: string
) => {
  const url = `/resources/${projectId}/search-indexes/${searchIndexId}`;
  const { data } = await getAxios().get<SearchIndex>(url);

  return data;
};

export const updateSearchIndex = (
  projectId: string,
  searchIndexId: string,
  searchIndexRequestData: SearchIndexRequestData
) => {
  const url = `/resources/${projectId}/search-indexes/${searchIndexId}`;

  return getAxios()
    .put<{ id: string }>(url, searchIndexRequestData)
    .then(({ data }) => data);
};

export const deleteSearchIndex = (projectId: string, id: string) => {
  const url = `/resources/${projectId}/search-indexes/${id}`;

  return getAxios().delete(url);
};

export const createSearchIndex = (
  projectId: string,
  searchIndexRequestData: SearchIndexRequestData
) => {
  const url = `/resources/${projectId}/search-indexes`;

  return getAxios()
    .post<{ id: string }>(url, searchIndexRequestData)
    .then(({ data }) => data);
};

export const useSearchIndexExistsQuery = (
  name: string,
  environmentId: string
) => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/search-indexes/exists`;

  return useQuery({
    enabled: !!name && !!environmentId,
    queryKey: ['useSearchIndexExistsQuery', name, projectId, environmentId],
    queryFn: async () => {
      const { data } = await getAxios().get(url, {
        params: { name, environmentId },
      });

      return data;
    },
  });
};
