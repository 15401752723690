import {
  Button,
  Center,
  Flex,
  FullCenter,
  Select,
  Show,
  Spin,
  Table,
  Text,
  Tooltip,
  usePagination,
} from '@comet/blocks';
import { TableContainer } from '../../Project.styled';
import { Empty, Result } from 'antd';
import { useNavigate, useParams } from '@comet/router';
import { useGetEnvironmentsOptions } from '../../Environments/utils';
import { useGetSecretsQuery } from '../services/Secrets.service';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { useSecretsListingData } from '../utils/SecretListing.utils';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';

export const SecretList = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { currentPageIndex, fetchData } = usePagination();
  const { hasManageProjectSettingsAccess } = useProjectPermissions();

  const {
    isEnvironmentsLoading,
    environmentsOptions,
    selectedEnvironment,
    setSelectedEnvironment,
  } = useGetEnvironmentsOptions();

  const {
    data: secretsData,
    isLoading,
    error: isError,
    refetch,
  } = useGetSecretsQuery(projectId, selectedEnvironment);

  const showList = !isLoading && !isError && secretsData?.data?.length !== 0;

  const { tableColumns, tableData } = useSecretsListingData({
    secretsData,
    refetch,
  });

  if (isEnvironmentsLoading || isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }
  return (
    <Flex width="100%" direction="column" gap={12} padding={16}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: 10 }}
      >
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">Secrets</Text>
          <Select
            options={environmentsOptions}
            defaultValue={selectedEnvironment}
            style={{ width: '200px' }}
            onSelect={setSelectedEnvironment}
          />
        </Flex>
        <Tooltip
          title={
            hasManageProjectSettingsAccess
              ? ''
              : 'Please check if you have enough permissions to access this page.'
          }
        >
          <Button
            type="primary"
            onClick={() => {
              navigate(
                'organisations.organisationId.projects.projectId.secrets.new',
                {},
                { state: { envId: selectedEnvironment } }
              );
            }}
            disabled={!hasManageProjectSettingsAccess}
          >
            Create Secret
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isError && !isLoading && secretsData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No secrets found</span>}
            />
          </FullCenter>
        </Show>

        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="APIs"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil((secretsData?.page.total || 0) / 10)}
            totalRows={secretsData?.page?.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
