import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { useGetSwaggerSpecQuery } from './Swagger.service';
import { useParams } from '@comet/router';
import { Button, Center, FullCenter, Spin } from '@comet/blocks';
import { Result } from 'antd';

export const SwaggerDocumentation = () => {
  const { projectId, version } = useParams();

  const {
    data: swaggerData,
    isLoading,
    isError,
    refetch,
  } = useGetSwaggerSpecQuery(projectId, version);

  if (isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError || !swaggerData) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <div>
      <SwaggerUI spec={swaggerData.data} />
    </div>
  );
};
