import { Edge, Node } from 'reactflow';
import { NODE_HEIGHT, NODE_WIDTH } from './constant';

import Dagre from '@dagrejs/dagre';

export const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ nodesep: 100, edgesep: 100, ranksep: 80 });

  nodes.forEach((node) =>
    g.setNode(node.id, {
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
    })
  );

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  Dagre.layout(g);

  nodes.forEach((node) => {
    const nodeWithPosition = g.node(node.id);
    node.position = {
      x: nodeWithPosition.x - NODE_WIDTH / 2,
      y: nodeWithPosition.y - NODE_HEIGHT / 2,
    };
  });

  return { nodes, edges };
};
