import { Link as RRDLink, LinkProps as RRDLinkProps } from 'react-router-dom';
import routes, { RoutesIdType } from './routes';
import { compile } from 'path-to-regexp';
import { styled } from '@comet/styled';

interface LinkProps extends Omit<RRDLinkProps, 'to'> {
  routeId: RoutesIdType;
  // TODO: make this more type safe & dev friendly using automatic type generation form links
  pathParam?: Record<string, number | string | null>;
}

const StyledLink = styled(RRDLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};
  font-size: ${({ theme }) =>
    theme.constants.font['label.short.semiBold'].style.fontSize};
  font-weight: ${({ theme }) =>
    theme.constants.font['label.short.semiBold'].style.fontWeight};
  line-height: ${({ theme }) =>
    theme.constants.font['label.short.semiBold'].style.lineHeight};
`;

const Link = ({
  routeId,
  pathParam = {},
  reloadDocument,
  ...rest
}: LinkProps) => {
  const { path, type } = routes[routeId];
  if (process.env.NODE_ENV === 'production' && type === 'DEVELOPMENT') {
    return null;
  }

  const link = compile(path, { encode: encodeURIComponent })(pathParam);

  return (
    <StyledLink
      {...rest}
      to={link}
      id={routeId}
      reloadDocument={reloadDocument}
    />
  );
};

export type { LinkProps };
export default Link;
