import { ChangeEvent, useEffect, useState } from 'react';
import { Flex, FormItem, Input, Show, Text } from '@comet/blocks';
import { RxCheck, RxCross2 } from 'react-icons/rx';

import { MdOutlineModeEditOutline } from 'react-icons/md';
import { NamePath } from 'antd/es/form/interface';
import { styled } from '@comet/styled';

interface IEditableTextProps {
  value: string | number;
  type: 'text' | 'number';
  onSave?: (value: any) => void;
  name: NamePath;
  disabled?: boolean;
  validator?: Array<object>;
}

const StyledEditIcon = styled(MdOutlineModeEditOutline)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.neutral.darker};
`;

const StyledCrossIcon = styled(RxCross2)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  cursor: pointer;
`;

const StyledCheckIcon = styled(RxCheck)`
  color: ${({ theme }) => theme.palette.accent.green.dark};
  cursor: pointer;
`;

export const EditableText = ({
  value,
  onSave,
  name,
  disabled,
  type,
  validator,
}: IEditableTextProps) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const toggleEdit = () => {
    setEditing((editing) => !editing);
  };

  const onSaveChanges = () => {
    onSave?.(text);
    toggleEdit();
  };

  const onDiscardChanges = () => {
    setText(value);
    toggleEdit();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  return (
    <Flex gap={6} alignItems="center">
      <Show if={!editing}>
        <Text appearance="label.long.regular">{value}</Text>
        <Flex hidden={!!disabled}>
          <StyledEditIcon onClick={toggleEdit} />
        </Flex>
      </Show>
      <Flex alignItems="center" hidden={!editing}>
        <FormItem name={name} marginBottom={0} rules={validator}>
          <Input value={text} onChange={handleInputChange} type={type} />
        </FormItem>
        <Flex>
          <StyledCrossIcon onClick={onDiscardChanges} />
          <StyledCheckIcon onClick={onSaveChanges} />
        </Flex>
      </Flex>
    </Flex>
  );
};
