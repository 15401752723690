import './style.css';

import { useCallback } from 'react';
import { useFlowEngineStore } from '../store';
import { useNodeId } from 'reactflow';

export function RootNode() {
  const clickNode = useFlowEngineStore(useCallback((s) => s.clickNode, []));
  const nodeId = useNodeId();

  function handleNodeClick() {
    clickNode(nodeId);
  }

  return (
    <div className="extra-node-style" onClick={handleNodeClick}>
      <div className="extra-node-content">
        <p>Root Node </p>
      </div>
    </div>
  );
}
