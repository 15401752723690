import { styled } from '@comet/styled';
import { MdPerson, MdOpenInNew } from 'react-icons/md';
import { Avatar, Dropdown, Flex } from '@comet/blocks';
import CosmoCloudLogoImg from '@comet/assets/svg/cosmocloud_logo.svg';
import { useAuth } from '../auth/AuthProvider';
import { Link } from '@comet/router';
import { HeaderBreadcrumb } from './Header.Breadcrumb';
import { ExternalLink } from '../ExternalLink';
import { queryClient } from '@comet/query';

//TODO - Move this to theme config

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.light};
`;

const Top = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  background: #ffffff;
  padding: 8px 24px;
`;

const CosmoCloudLogo = styled.img`
  height: 20px;
  border-radius: 0px;
`;

const LeftSection = styled.div`
  display: flex;
  flex: start;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  flex: end;
  align-items: center;
  gap: 8px;
`;

export const Header = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    queryClient.clear();
  };

  const actionItems = [
    {
      label: 'Logout',
      key: 'logout',
      onClick: handleLogout,
    },
  ];

  return (
    <StyledHeader>
      <Top justifyContent="space-between" alignItems="center">
        <LeftSection>
          <Link routeId="organisations">
            <Flex alignItems="center">
              <CosmoCloudLogo src={CosmoCloudLogoImg} alt="logo" />
            </Flex>
          </Link>
        </LeftSection>
        <RightSection>
          <ExternalLink
            href="https://docs.cosmocloud.io"
            appearance="caption.semibold"
          >
            <MdOpenInNew />
            Documentation
          </ExternalLink>
          <Dropdown menu={{ items: actionItems }} arrow>
            <Avatar
              shape="square"
              size={28}
              appearance="success"
              icon={<MdPerson />}
            />
          </Dropdown>
        </RightSection>
      </Top>
      <HeaderBreadcrumb />
    </StyledHeader>
  );
};
