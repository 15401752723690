import {
  Button,
  Flex,
  FormItem,
  InputUrl,
  Label,
  Paper,
  Show,
  Switch,
  Text,
  Tooltip,
} from '@comet/blocks';
import { styled, useTheme } from '@comet/styled';
import { useState } from 'react';
import { MdInfo, MdOpenInNew } from 'react-icons/md';
import { RequestMethodComponent } from '../utils';
import { Editor } from '@monaco-editor/react';
import ApiDetailsSchema from './ApiDetails.Schema';
import { CopyToClipboard } from '@comet/components/CopyToClipboard';
import { EditableTextArea } from '@comet/components/Editable/TextArea';
import { EditableText } from '@comet/components/Editable/Text';
import { notification } from 'antd';
import { useGetApiBaseUrl } from '@comet/pages/Project/utils';
import { Link, useParams } from '@comet/router';
import { useDeploymentsVersion } from '@comet/pages/Project/Deployments/Deployments.utils';

const StyledInfoIcon = styled(MdInfo)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.neutral.darker};
`;

interface ApiDetailsPageProps {
  name: string | undefined;
  requestMethod: string | undefined;
  description: string | undefined;
  endPoint: string | undefined;
  queryModelId: string | undefined;
  requestModelId: string | undefined;
  authenticated: boolean | undefined;
  modelVersion: string | null;
  hasEditAccess: boolean;
}

export const ApiDetailsPage = (props: ApiDetailsPageProps) => {
  const { theme } = useTheme();
  const { projectId } = useParams();
  const { latest } = useDeploymentsVersion(projectId);
  const [name, setName] = useState(props.name || '');
  const [description, setDescription] = useState(props.description || '');
  const [toggle, setToggle] = useState(props.authenticated);

  const { url: baseUrl } = useGetApiBaseUrl();
  const endPoint = `${baseUrl}/development/api${props.endPoint}`;

  // Todo: change this curl request with api data
  const curlRequest = `curl https://reqbin.com/echo
  -H "Cache-Control: must-revalidate"
  -H "Pragma: no-cache"
  -H "Expires: 0"`;

  const onEndpointCopy = () => {
    navigator.clipboard.writeText(endPoint);
    notification.success({
      message: 'Success',
      description: 'Endpoint copied successfully',
    });
  };

  const onCurlRequestCopy = () => {
    navigator.clipboard.writeText(curlRequest);
    notification.success({
      message: 'Success',
      description: 'Curl request copied successfully',
    });
  };

  return (
    <Paper size="medium">
      <Flex direction="column" gap={24}>
        <Flex justifyContent="space-between">
          <Flex direction="column" gap={10}>
            <Label required={true}>API name</Label>
            <EditableText
              type="text"
              name="name"
              {...{ value: name, onSave: setName }}
              disabled={!props.hasEditAccess}
            />
          </Flex>
          <Show if={!!latest}>
            <Link
              routeId="projects.projectId.docs.version"
              pathParam={{ projectId, version: latest! }}
              target="_blank"
            >
              <Button appearance="transparent">Open Swagger Docs</Button>
            </Link>
          </Show>
        </Flex>
        <Flex direction="column" gap={10}>
          <Label required={true}>Request method</Label>
          <RequestMethodComponent requestMethod={props.requestMethod || ''}>
            {props.requestMethod}
          </RequestMethodComponent>
        </Flex>
        <Flex direction="column" gap={10}>
          <Label>Description</Label>
          <EditableTextArea
            name="description"
            {...{ value: description, onSave: setDescription }}
            disabled={!props.hasEditAccess}
          />
        </Flex>
        <Flex direction="column" gap={10} hidden>
          <Flex gap={10}>
            <Label required={true}>Curl Request</Label>
            <Tooltip title={<a href="#">Learn More</a>} color="white">
              <StyledInfoIcon />
            </Tooltip>
            <CopyToClipboard onClick={onCurlRequestCopy} />
          </Flex>
          <Flex
            direction="column"
            style={{
              width: '100%',
              minHeight: 100,
              height: 100,
              border: `1px solid ${theme.palette.neutral.light}`,
              borderRadius: theme.constants.borderRadius.lg,
            }}
          >
            <Editor
              language="plaintext"
              defaultValue={curlRequest}
              theme="vs"
              className="text_editor"
              line={15}
              options={{
                hideCursorInOverviewRuler: false,
                minimap: { enabled: false },
                scrollBeyondLastLine: false,
                fontSize: 16,
                domReadOnly: true,
                readOnly: true,
              }}
            />
          </Flex>
        </Flex>
        <Flex direction="column" gap={10}>
          <Flex gap={10}>
            <Label required={true}>Development endpoint</Label>
            <Tooltip
              title={
                <a href="https://docs.cosmocloud.io/cosmocloud-documentation/setup/7.-testing-free-tier-apis">
                  Learn More
                </a>
              }
              color="white"
            >
              <StyledInfoIcon />
            </Tooltip>
            <CopyToClipboard onClick={onEndpointCopy} />
          </Flex>
          <Flex>
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 0 }}
            >
              Ensure that the API call includes the necessary `projectId` and
              `environmentId` in the headers.
            </Text>
          </Flex>
          <InputUrl
            value={endPoint}
            style={{ width: '100%' }}
            disabled={true}
          />
        </Flex>
        <Flex direction="column" gap={15}>
          <Label>Query Params</Label>
          <ApiDetailsSchema
            modelId={props.queryModelId}
            modelVersion={props.modelVersion}
          />
        </Flex>
        <Flex direction="column" gap={15}>
          <Label>Request Body</Label>
          <ApiDetailsSchema
            modelId={props.requestModelId}
            modelVersion={props.modelVersion}
          />
        </Flex>
        <Flex direction="column" gap={10}>
          <Label required>Authentication</Label>
          <FormItem name={['flowMetadata', 'authenticated']}>
            <Switch defaultChecked={toggle} onChange={(e) => setToggle(e)} />
          </FormItem>
        </Flex>
      </Flex>
    </Paper>
  );
};
