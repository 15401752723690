import { useEffect, useRef, useState } from 'react';
import FlexItem from 'src/blocks/FlexItem';
import {
  Button,
  ButtonContainer,
  Divider,
  Flex,
  FullCenter,
  Input,
  InputHelpText,
  Label,
  Show,
  Spin,
  Typography,
} from '@comet/blocks';
import { styled } from '@comet/styled';
import { createOrg, useOrgExistsQuery } from '../CreateOrganization.service';
import debounce from 'lodash.debounce';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@comet/query';
import { useOrgsQuery } from '../../service';
import { useNavigate } from '@comet/router';
import { useOrgCreatedBy } from '@comet/hooks/useOrgCreatedBy';

const Title = styled(Typography.Text)`
  font-size: 15px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.neutral.darker};
  margin: 10px 0;
  line-height: 24px;
`;

interface IProps {
  initialValues?: { name?: string };
  onSuccess: (orgId: string) => void;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  onCancel: () => void;
}

const CreateOrganizationForm = ({
  initialValues,
  onSuccess,
  onCancel,
  primaryButtonText = 'Create',
  secondaryButtonText = 'Cancel',
}: IProps) => {
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState(initialValues?.name);
  const [orgError, setOrgError] = useState('');

  const orgExistsQuery = useOrgExistsQuery(orgName || '');
  const createOrgMutation = useMutation({
    mutationFn: createOrg,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['useOrgsQuery'] });
      queryClient.invalidateQueries({
        queryKey: ['useOrgExistsQuery', orgName],
      });
      onSuccess(data.data.id);
    },
    onError: () => {
      setOrgError('Organisation creation failed');
    },
  });

  const { data: orgsData, isLoading } = useOrgsQuery({});
  const { isCreateOrgEnabled } = useOrgCreatedBy();
  useEffect(() => {
    if (orgsData && orgsData?.data?.length > 0 && !isCreateOrgEnabled) {
      navigate('organisations');
    }
  }, [navigate, orgsData, isCreateOrgEnabled]);

  useEffect(() => {
    setOrgError(
      orgExistsQuery.data?.exists ? 'Organisation already exists' : ''
    );
  }, [orgExistsQuery.data]);

  const debouncedOrgNameCheck = useRef(debounce(orgExistsQuery.refetch, 500));

  const createOrganization = () => {
    setOrgError('');
    if (!orgName?.trim()) {
      setOrgError('Organisation Name is required');
      return;
    }

    createOrgMutation.mutate({ name: orgName });
  };

  if (isLoading) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  return (
    <>
      <Title>Organisation is the home for all your projects.</Title>
      <Flex gap={5} direction="column" width="60%">
        <FlexItem>
          <Label required>Organisation name</Label>
        </FlexItem>
        <Input
          value={orgName}
          onChange={(event) => {
            setOrgName(event.target.value);
            if (event.target.value) {
              debouncedOrgNameCheck.current();
            }
          }}
          width="60%"
          placeholder="Example: Cosmocloud org"
        />
        <Show if={!!orgName}>
          <InputHelpText
            error={orgError}
            success={
              orgExistsQuery.data?.exists === false &&
              !orgExistsQuery.isFetching &&
              'Organisation Name is available.'
            }
          >
            {orgExistsQuery.isFetching && 'Checking if organisation exists...'}
          </InputHelpText>
        </Show>
      </Flex>
      <Divider />
      <Flex justifyContent="flex-end">
        <Button
          type="primary"
          onClick={createOrganization}
          loading={createOrgMutation.isLoading}
          disabled={!!orgError}
        >
          {primaryButtonText}
        </Button>
      </Flex>
    </>
  );
};

export default CreateOrganizationForm;
