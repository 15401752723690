import { RuleRender } from 'antd/es/form';
import { useCallback } from 'react';
import { ModelTypesEnum } from '../types';
import { MODEL_NAME_REGEX } from '../Model.constants';

export const useModelNameValidator = () => {
  const validatorRule: RuleRender = useCallback(
    ({ getFieldValue }) => ({
      validator(_, value) {
        const modelType = getFieldValue('modelType');
        if (
          !value ||
          modelType !== ModelTypesEnum.DATABASE_COLLECTION ||
          MODEL_NAME_REGEX.test(value)
        ) {
          return Promise.resolve();
        }

        return Promise.reject(new Error('Invalid model name!'));
      },
      message: "Modelname should't contain special characters or whitespaces!",
    }),
    []
  );

  return validatorRule;
};
