import {
  Button,
  Center,
  Flex,
  Form,
  FullCenter,
  PageHeader,
  Paper,
  Show,
} from '@comet/blocks';
import {
  EnvironmentUpdateFormData,
  EnvironmentUpdateRawData,
  GetEnvironmentMetadata,
} from '../types';
import { Result, Spin } from 'antd';
import { useEnvironmentMutation, useEnvironmentQuery } from '../service';

import { UpdateEnvironmentForm } from './UpdateEnvironmentForm';
import { getResourceMetadata } from '../EnvironmentList/EnvironmentList.util';
import { getUpdateEventType } from '../utils/environmentDetails.util';
import { useMemo } from 'react';
import { useParams } from '@comet/router';

export const EnvironmentDetails = () => {
  const { projectId, environmentId } = useParams();
  const [form] = Form.useForm();

  const {
    data: environment,
    isLoading: isEnvironmentLoading,
    isError: isEnvironmentError,
    refetch: refetchEnvironment,
  } = useEnvironmentQuery(projectId, environmentId);

  const error = isEnvironmentError || !environment;

  const updateEnvironmentMutation = useEnvironmentMutation();

  const metadata = useMemo(() => {
    if (!environment) return {} as GetEnvironmentMetadata;

    const result = getResourceMetadata(environment);

    return result;
  }, [environment]);

  if (isEnvironmentLoading) {
    return (
      <FullCenter>
        <Flex direction="column">
          <Spin size="large" />
        </Flex>
      </FullCenter>
    );
  }

  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchEnvironment();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  const handleSave = (data: EnvironmentUpdateFormData) => {
    const updateEventType = getUpdateEventType(metadata, data.metadata);

    if (!updateEventType) {
      return;
    }
    const updatedMetadata = {
      sharedEnvId: data.metadata.sharedEnvId || null,
      tier: data.metadata.tier || null,
      isShared: data.metadata.isShared,
    };

    const updateData: EnvironmentUpdateRawData = {
      metadata: updatedMetadata,
      type: data.type,
      updateEventType,
    };

    updateEnvironmentMutation.mutate(updateData);
  };

  return (
    <Flex direction="column">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={environment}
        onFinish={handleSave}
      >
        <PageHeader
          title="Environment Details"
          primaryAction={
            <Button appearance="primary" htmlType="submit">
              Save
            </Button>
          }
        />
        <Paper size="medium">
          <Show if={!!environment && !error && !!metadata}>
            <UpdateEnvironmentForm
              name={environment?.name || ''}
              type={environment?.type || ''}
              metadata={metadata}
              form={form}
            />
          </Show>
        </Paper>
      </Form>
    </Flex>
  );
};
