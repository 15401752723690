import { GrowthBook } from '@growthbook/growthbook-react';

const gb = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOK_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    console.log('Experiment Viewed', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

export { gb };
