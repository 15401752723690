import styled from 'styled-components';
import { Header } from '../Header/Header';
import { SideBar, SideBarType, SideBarEnum } from '../Sidebar';
import { Flex, FullCenter, Show, Spin } from '@comet/blocks';
import FlexItem from 'src/blocks/FlexItem';
import { Outlet, useLocation } from '@comet/router';
import { useOrgAndProjectExists } from '@comet/hooks/useOrgAndProjectExists';
import { usePlanExpired } from '@comet/hooks/usePlanExpired';
import { PlanExpired } from '../PlanExpired';

interface AppContainerProps {
  sideNav?: SideBarType;
}

const Container = styled(Flex)`
  overflow: hidden;
  background-color: ${({ theme }) => `${theme.palette.neutral.lightest}`};
`;

const Body = styled(Flex)`
  flex-grow: 2;
  max-height: 100%;
  overflow: hidden;
`;

const Main = styled(FlexItem)`
  overflow: auto;
`;

export const AppContainer = ({ sideNav }: AppContainerProps) => {
  const loading = useOrgAndProjectExists();
  const isExpired = usePlanExpired();
  const { pathname } = useLocation();

  const isUpgradePlanPage = pathname.split('/').pop() === 'upgrade';

  const showExpired =
    sideNav === SideBarEnum.PROJECT &&
    !!isExpired &&
    pathname &&
    !isUpgradePlanPage;

  if (loading)
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );

  return (
    <Container direction="column" height="100%" width="100%">
      <Header />
      <Body id="body">
        <FlexItem shrink={0} id="side-nav" style={{ overflow: 'auto' }}>
          <Show if={!!sideNav}>
            <SideBar type={sideNav!} />
          </Show>
        </FlexItem>
        <Main grow={2} id="main">
          <Show if={true}>
            <Outlet />
          </Show>
          <Show if={false}>
            <PlanExpired />
          </Show>
        </Main>
      </Body>
    </Container>
  );
};
