import { User } from '@comet/pages/Organisation/Organisation.types';

export interface SecretDetailsResponse {
  name: string;
  type: 'AUTHENTICATION_SECRET' | 'DATABASE_SECRET' | 'CUSTOM_SECRET';
  data: SecretsFormDataType;
  projectId: string;
  environmentId: string;
  updatedBy: User;
  id: string;
  updatedOn: number;
}

export interface GetSecretsDetailsResponse {
  data: SecretDetailsResponse[];
  page: {
    limit: number;
    total: number;
  };
}

export interface AUTHENTICATION_DATA {
  jwksURI?: string;
  audience?: string;
  authType?: string;
  accessKeySecret?: string;
  enabled: boolean;
  userKey: string;
  tokenData?: { key: string; tokenType: string }[];
}

export interface DATABASE_DATA {
  mode: 'MONGODB_ATLAS' | 'DATABASE_ONLY';
  mongodbURI: string;
  name: string;
  publicKey: string;
  privateKey: string;
  projectId: string;
  clusterName: string;
}

export type SecretsFormDataType =
  | AUTHENTICATION_DATA
  | DATABASE_DATA
  | Record<string, string>;

export interface SecretsRawData {
  name: string;
  type: 'AUTHENTICATION_SECRET' | 'DATABASE_SECRET' | 'CUSTOM_SECRET';
  data: SecretsFormDataType;
}

export interface SecretRawData {
  id: string;
  projectId: string;
  environmentId: string;
  name: string;
  type: string;
  data: SecretsFormDataType;
  updatedBy: User;
  updatedOn: number;
  environment?: { name: string; id: string };
  status?: string;
}

export enum AuthTypesEnum {
  JWKS_URI = 'JWKS_URI',
  ACCESS_KEY_SECRET = 'ACCESS_KEY_SECRET',
}
