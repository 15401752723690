import { IAnalyzer, SearchIndex } from '../types';
import { useEffect, useState } from 'react';
import {
  MODEL_TO_MONGODB_ATLAS_MAP,
  useAnalyzerOptions,
  useGetFieldsOptions,
} from '../utils';
import { FormInstance, notification } from 'antd';
import { sendErrorNotification } from 'src/blocks/Notification';

export const useDynamicFields = (
  searchIndexData: SearchIndex | undefined,
  modelId: string,
  form: FormInstance
) => {
  const [myfields, setFields] = useState<
    {
      name: string;
      type: string;
      searchAnalyzer: IAnalyzer;
      analyzer: IAnalyzer;
      multi?: {
        name: string;
        searchAnalyzer: IAnalyzer;
        analyzer: IAnalyzer;
      }[];
    }[]
  >([]);

  const { analyzerOptions, analyzerType } = useAnalyzerOptions();
  const { fieldOptions, fieldMappings } = useGetFieldsOptions(modelId);

  const canCreateMoreFields =
    fieldOptions && myfields.length < fieldOptions?.length;

  const [disabledFields, setDisabledFields] = useState<boolean[]>([]);
  useEffect(() => {
    setFields(searchIndexData?.mappings.fields || []);
    setDisabledFields(
      Array(searchIndexData?.mappings.fields.length).fill(false)
    );
  }, [searchIndexData]);

  const handleSelectField = (name: number, value: string, index: number) => {
    setFields((fields) => {
      fields[index] = {
        name: value,
        type: MODEL_TO_MONGODB_ATLAS_MAP[fieldMappings[value]],
        analyzer: fields[index]?.analyzer,
        searchAnalyzer: fields[index]?.searchAnalyzer,
        multi: fields[index]?.multi,
      };
      return fields;
    });

    form.setFieldValue(
      ['fields', name, 'type'],
      MODEL_TO_MONGODB_ATLAS_MAP[fieldMappings[value]]
    );
    form.setFieldValue(['fields', name, 'name'], value);
    setDisabledFields((disabledFields) => {
      const newDisabledFields = [...disabledFields];
      newDisabledFields[index] = false;
      return newDisabledFields;
    });
  };
  const handleSelectFieldType = (
    name: number,
    value: string,
    index: number
  ) => {
    setFields((fields) => {
      fields[index] = {
        ...fields[index],
        type: value,
      };
      return fields;
    });
    form.setFieldValue(['fields', name, 'type'], value);
  };
  const handleSelectAnalyzer = (
    name: number,
    value: string,
    index: number,
    analyzerName: string
  ) => {
    setFields((fields) => {
      fields[index] = {
        ...fields[index],
        [analyzerName]: {
          type: analyzerType[value],
          value,
        },
      };
      return fields;
    });

    form.setFieldValue(['fields', name, analyzerName], {
      type: analyzerType[value],
      value,
    });
  };
  const addField = () => {
    if (!canCreateMoreFields) {
      sendErrorNotification({
        message: 'Error',
        description:
          'You have reached maximum number of fields for this search index.',
      });
      return;
    }

    setFields((fields) => [
      ...fields,
      {
        name: '',
        type: '',
        analyzer: {
          type: '',
          value: '',
        },
        searchAnalyzer: {
          type: '',
          value: '',
        },
      },
    ]);

    setDisabledFields((disabledFields) => [...disabledFields, true]);
  };
  const removeField = (index: number) => {
    setFields((fields) => fields.filter((_, i) => i !== index));

    setDisabledFields((disabledFields) =>
      disabledFields.filter((_, i) => i !== index)
    );
  };
  const addMultiField = (index: number) => {
    setFields((fields) =>
      fields.map((field, i) => {
        if (i !== index) {
          return field;
        }
        const fieldWithMulti = {
          ...field,
          multi: [
            ...(field.multi ?? []),
            {
              name: '',
              analyzer: {
                type: '',
                value: '',
              } as IAnalyzer,
              searchAnalyzer: {
                type: '',
                value: '',
              } as IAnalyzer,
            },
          ],
        };
        return fieldWithMulti;
      })
    );
  };
  const removeMultiField = (index: number, multiFieldIndex: number) => {
    setFields((fields) =>
      fields.map((field, i) => {
        if (i !== index) {
          return field;
        }
        const { multi, ...rest } = field;
        if (multi?.length === 1 && multiFieldIndex === 0) {
          return rest;
        }
        const newMulti = multi?.filter((_, i) => i !== multiFieldIndex);
        return { multi: newMulti, ...rest };
      })
    );
  };

  const handleMultiFieldNameChange = (
    name: string,
    index: number,
    multiIndex: number
  ) => {
    form.setFieldValue(['fields', index, 'multi', multiIndex, 'name'], name);
  };
  const handleMultiFieldAnalyzerChange = (
    value: string,
    index: number,
    multiIndex: number,
    analyzerName: string
  ) => {
    form.setFieldValue(['fields', index, 'multi', multiIndex, analyzerName], {
      type: analyzerType[value],
      value,
    });
  };

  return {
    analyzerOptions,
    myfields,
    fieldOptions,
    fieldMappings,
    handleSelectField,
    handleSelectAnalyzer,
    addField,
    removeField,
    disabledFields,
    canCreateMoreFields,
    addMultiField,
    removeMultiField,
    handleMultiFieldNameChange,
    handleMultiFieldAnalyzerChange,
    handleSelectFieldType,
  };
};
