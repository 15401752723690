export const customerTypesOptions = [
  {
    label: 'Business with GST',
    value: 'business_gst',
  },
  {
    label: 'Business without GST',
    value: 'business_none',
  },
  {
    label: 'Individual',
    value: 'consumer',
  },
  {
    label: 'Overseas',
    value: 'overseas',
  },
];
