import { FiMoreHorizontal } from 'react-icons/fi';
import { Column } from 'react-table';
import { RiAlertLine } from 'react-icons/ri';
import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
  Tooltip,
} from '@comet/blocks';
import { Link, useParams } from '@comet/router';
import { queryClient } from '@comet/query';
import { styled, useTheme } from '@comet/styled';
import {
  Environment,
  EnvironmentTypes,
  GetEnvironmentResponse,
} from '../types/Environment.types';
import { deleteEnvironment, reactivateEnvironment } from '../service';
import { CenterText } from '../Environment.styled';
import {
  EnvironmentChangeMapping,
  EnvironmentStateMapping,
  EnvironmentTiersOptions,
} from '../constants';
import { StatusComponent } from '@comet/components/TableComponents';
import { UrlContainer } from '@comet/components/UrlContainer';
import { notification } from 'antd';
import _ from 'lodash';
import { useGetTiersOptions } from '../utils';
import { DeleteEnvironmentModal } from './EnvironmentDeleteModal';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

//delete environment listing data
export const ModifyEnvironmentActiveState = ({
  active,
  id,
  refetch,
  disabled,
  envData,
}: {
  id: string;
  active: boolean;
  disabled: boolean;
  refetch: () => void;
  envData?: Environment;
}) => {
  const { organisationId, projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const refetchProjectInformation = useCallback(() => {
    queryClient.refetchQueries({
      queryKey: ['useGetSubscriptionStatus', organisationId, projectId],
    });
  }, [organisationId, projectId]);

  const onMenuClick = async () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await deleteEnvironment(projectId, id);
    setIsConfirm(false);
    refetch();
    refetchProjectInformation();
  }, [id, projectId, refetch, refetchProjectInformation]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: active ? 'Delete' : '',
              key: active ? 'deactivate' : 'reactivate',
              label: (
                <Tooltip
                  title={
                    disabled ? "Development environment can't be deleted." : ''
                  }
                >
                  {active ? 'Delete' : ''}
                </Tooltip>
              ),
              onClick: onMenuClick,
              disabled,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      {isConfirm && !!envData && (
        <DeleteEnvironmentModal envData={envData} toggleModal={toggleModal} />
      )}
    </>
  );
};

export function getResourceMetadata(resource: Environment) {
  const metadata = resource.metadata;
  const draftState = resource.draftState;

  if (draftState && Object.keys(draftState || {}).length > 0) {
    return {
      isShared: draftState.isShared,
      sharedEnvId: draftState.sharedEnvId || null,
      sharedEnvName: null,
      tier: draftState.tier || null,
    };
  }

  return {
    isShared: !!metadata?.isShared,
    sharedEnvId: metadata?.sharedEnvId || null,
    sharedEnvName: metadata?.sharedEnvName || null,
    tier: metadata?.tier || null,
  };
}

export const useEnvironmentsListingData = (
  environmentsData: GetEnvironmentResponse | undefined,
  refetch: () => void
) => {
  const { theme } = useTheme();
  const TiersOption = useGetTiersOptions();

  const tableData = useMemo(() => {
    if (!environmentsData) return [];

    const orgsList = environmentsData.data;

    return orgsList.map((environment) => {
      const metadata = getResourceMetadata(environment);
      const resourceSharing = metadata?.isShared;

      const onUrlCopy = () => {
        const endPoint = environment.url;
        navigator.clipboard.writeText(endPoint);
        notification.success({
          message: 'Success',
          description: 'URL copied successfully',
        });
      };
      const onEnvIdCopy = () => {
        navigator.clipboard.writeText(environment.id);
        notification.success({
          message: 'Success',
          description: 'Environment ID copied successfully',
        });
      };
      let plan = metadata?.tier;
      TiersOption.map((tier: any) => {
        if (tier.value === plan) {
          plan = tier.label;
        }
      });

      const statusText = getStatus(environment.active);
      const statusColor =
        _.get(theme, EnvironmentStateMapping[statusText][1]) ||
        EnvironmentStateMapping[statusText][1];

      return {
        environmentId: (
          <UrlContainer width="240px" onClick={onEnvIdCopy}>
            <Text appearance="caption.regular">{environment.id}</Text>
          </UrlContainer>
        ),
        name: environment.name,
        resources: resourceSharing ? 'Shared' : 'Dedicated',
        subscriptionPlan: plan ? plan : <CenterText>-</CenterText>,
        baseUrl: (
          <UrlContainer width="240px" onClick={onUrlCopy}>
            <Text appearance="caption.regular">
              {environment.url.substring(0, 29)}...
            </Text>
          </UrlContainer>
        ),
        id: environment.id,
        active: (
          <StatusComponent color={statusColor}>{statusText}</StatusComponent>
        ),
        draftUpdate: environment.draftState ? (
          EnvironmentChangeMapping[environment.draftState.type] || (
            <CenterText>-</CenterText>
          )
        ) : (
          <></>
        ),
        deployedVersion: environment.version,
        action: (
          <ModifyEnvironmentActiveState
            id={environment.id}
            refetch={refetch}
            active={environment.active}
            disabled={environment.type === EnvironmentTypes.DEVELOPMENT}
            envData={environment}
          />
        ),
      };
    });
  }, [environmentsData, refetch, theme]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        Cell: (data) => {
          const currentRow = data.row.original;
          const { projectId, organisationId } = useParams();
          return (
            <Link
              routeId="organisations.organisationId.projects.projectId.environments.environmentId.details"
              pathParam={{
                environmentId: currentRow.id,
                organisationId,
                projectId,
              }}
            >
              {currentRow.name}
            </Link>
          );
        },
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Environment ID" />,
        accessor: 'environmentId',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Tier" />,
        accessor: 'subscriptionPlan',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Status" />,
        accessor: 'active',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Change type" />,
        accessor: 'draftUpdate',
        disableSortBy: true,
      },

      {
        Header: <TableHeader text="Base URL" />,
        accessor: 'baseUrl',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
      },
    ];
  }, []);

  return { tableColumns, tableData };
};

function getStatus(active: boolean) {
  return active ? 'DEPLOYED' : 'INACTIVE';
}
