import { Button, Flex, Form, Input } from '@comet/blocks';
import { useFilterFieldValidator } from '@comet/components/DynamicFields/utils';
import { useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';

export const ConfigureCustom = () => {
  const [nextStepEnabled, setNextStepEnabled] = useState(false);

  //Todo: refactor useFilterFieldValidator for using it evrywhere
  const fieldValidator = useFilterFieldValidator(
    setNextStepEnabled,
    'secretData',
    (field, value) => (field as any).key === value
  );
  return (
    <div>
      <Form.List name="secretData">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flex key={field.key} justifyContent="space-between">
                <Form.Item
                  name={[field.name, 'key']}
                  style={{ width: '45%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input key or delete this field.',
                    },
                    fieldValidator,
                  ]}
                >
                  <Input placeholder="Enter key" />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'value']}
                  style={{ width: '45%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: 'Please input value or delete this field.',
                    },
                  ]}
                >
                  <Input placeholder="Enter value" />
                </Form.Item>
                <Flex justifyContent="center">
                  <AiOutlineMinusCircle
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Flex>
              </Flex>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={add} style={{ width: '100%' }}>
                <AiOutlinePlusCircle /> Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};
