import { Tag as AntTag } from 'antd';
import { styled } from '@comet/styled';
export type { TagProps } from 'antd';

export const Tag = styled(AntTag)`
  border-radius: 4px;
  padding: 2px;
  min-width: 20px;
  span {
    text-align: center;
  }
`;
