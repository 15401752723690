import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';
import { IoCopySharp } from 'react-icons/io5';

interface UrlContainerProps {
  width?: string;
  onClick: () => void;
  children: React.ReactNode;
}

export const StyledUrlContainer = styled(Flex)<{
  width?: string;
}>`
  background: ${({ theme }) => theme.palette.neutral.lighter};
  min-width: ${({ theme, width }) =>
    width ? width : theme.constants.paperSize.small};
  width: max-content;
  border-radius: ${({ theme }) => theme.constants.borderRadius.lg};
  padding: 10px;
`;

export const StyledCopyIcon = styled(IoCopySharp)`
  cursor: pointer;
  fill: ${({ theme }) => theme.palette.neutral.dark};
`;

export const UrlContainer = ({
  width,
  children,
  onClick,
}: UrlContainerProps) => {
  return (
    <StyledUrlContainer justifyContent="space-between" gap={4} width={width}>
      {children}
      <StyledCopyIcon onClick={onClick} />
    </StyledUrlContainer>
  );
};
