import { createContext, useCallback, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { darkTheme, defaultTheme, lightTheme } from './palette';

const ThemeContext = createContext({
  mode: 'light',
  theme: defaultTheme,
  toggleTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export type ThemeType = typeof defaultTheme;

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const toggleTheme = useCallback(() => {
    setMode((currentMode) => (currentMode === 'dark' ? 'light' : 'dark'));
  }, []);
  const theme = {
    ...defaultTheme,
    ...(mode === 'dark' ? darkTheme : lightTheme),
  };
  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, theme }}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
