import React, { useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, MenuProps } from 'antd';
import {
  SchemaKeyBasicType,
  SchemaKeySubType,
  SchemaKeyType,
} from '@comet/components/SchemaTree/SchemaTree.types';
import { styled } from '@comet/styled';
import { useSchemaStore } from './ModelSchemaStore';
import { Show } from '@comet/blocks';
import { capitalize } from 'lodash';

interface IProps {
  type: SchemaKeyType;
  path: string;
  subType?: SchemaKeySubType;
  disabled?: boolean;
  showBasic?: boolean;
}

const DropdownContainer = styled(Dropdown)`
  background: #eeeeee;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
`;

const KeyTypeDropdown = (props: IProps) => {
  const items: MenuProps['items'] = useMemo(() => {
    if (props.showBasic) {
      return Object.keys(SchemaKeyBasicType).map((key) => ({
        key: key === 'INTEGER' ? 'NUMBER' : key,
        label: capitalize(key.split('_').join('')),
      }));
    }

    return Object.keys(SchemaKeyType).map((key) => {
      if (key === 'ARRAY') {
        return {
          key: 'LIST',
          label: capitalize(key.split('_').join('')),
          children: Object.keys(SchemaKeySubType).map((subKey) => {
            const value =
              subKey === 'INTEGER' ? SchemaKeySubType.INTEGER : subKey;
            return {
              key: `LIST:${value}`,
              label: capitalize(subKey.split('_').join('')),
            };
          }),
        };
      }

      return {
        key: key === 'INTEGER' ? 'NUMBER' : key,
        label: capitalize(key.split('_').join('')),
      };
    });
  }, [props.showBasic]);

  const { type, subType, path, disabled } = props;

  const updatePropertyType = useSchemaStore(
    (state) => state.updatePropertyType
  );

  const handleTypeSelect = (e: any) => {
    const allTypes = e.key.split(':');

    const type = allTypes[0];

    let subType;

    if (allTypes.length === 2) {
      subType = allTypes[1];
    }

    updatePropertyType(path, type, subType);
  };

  const typeToRender = subType
    ? `Array: ${capitalize(
        subType === 'NUMBER' ? 'INTEGER' : subType.split('_').join('')
      )}`
    : `${capitalize(type.split('_').join(''))}`;

  return (
    <DropdownContainer
      // Some bug in antd type definitions not allowing to use trigger as string
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trigger="click"
      menu={{
        items,
        onClick: handleTypeSelect,
        selectable: true,
        defaultSelectedKeys: [typeToRender],
      }}
    >
      <Space>
        <Text>{typeToRender}</Text>
        <Show if={!disabled}>
          <DownOutlined />
        </Show>
      </Space>
    </DropdownContainer>
  );
};

export default KeyTypeDropdown;
