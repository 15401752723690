export enum ProjectPermissions {
  VIEW_APIS = 1,
  EDIT_APIS = 2,
  VIEW_MODELS = 4,
  EDIT_MODELS = 8,
  VIEW_RELEASES = 16,
  MANAGE_RELEASES = 32,
  EDIT_CONFIGS = 64,
  MANAGE_USER_PERMISSIONS = 128,
  MANAGE_PROJECT_SETTINGS = 32768,
}
