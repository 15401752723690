import './style.css';

import { Handle, Position, useNodeId } from 'reactflow';

import { clickAddCaseNode } from '../Operations/clickOperatons';
import { getNodeById } from '../store';

export function AddCaseNode() {
  const nodeId = useNodeId();

  if (!nodeId) return null;

  function handleNodeClick() {
    const addCaseNode = getNodeById(nodeId);
    const switchNodeId = addCaseNode?.data.metadata.switchNodeId;
    clickAddCaseNode(switchNodeId);
  }

  return (
    <div className="extra-node-style" onClick={handleNodeClick}>
      <div className="extra-node-content">
        <Handle type="target" position={Position.Top} />
        <p>Add Case +</p>
      </div>
    </div>
  );
}
