import { Button as AntDButton } from 'antd';
import styled, { css } from 'styled-components';
import { Flex } from '../Flex';
export { type ButtonProps } from 'antd/lib/button';

export type Appearance = 'primary' | 'alert' | 'success' | 'transparent';

const getAppearanceStyles = (appearance?: Appearance) => {
  switch (appearance) {
    case 'primary':
      return css`
        background-color: ${({ theme }) => `${theme.palette.primary}`};
        border-color: ${({ theme }) => `${theme.palette.primary}`} !important;
        color: ${({ theme }) => `${theme.palette.neutral.white}`} !important;
      `;
    case 'alert':
      return css`
        background-color: ${({ theme }) =>
          `${theme.palette.accent.red.default}`};
        border-color: ${({ theme }) =>
          `${theme.palette.accent.red.default}`} !important;
        color: ${({ theme }) => `${theme.palette.neutral.white}`} !important;
      `;
    case 'success':
      return css`
        background-color: ${({ theme }) =>
          `${theme.palette.accent.green.dark}`};
        border-color: ${({ theme }) =>
          `${theme.palette.accent.green.dark}`} !important;
        color: ${({ theme }) => `${theme.palette.neutral.white}`} !important;
      `;
    case 'transparent':
      return css`
        background-color: ${({ theme }) => `${theme.palette.neutral.lighter}`};
        border-color: ${({ theme }) =>
          `${theme.palette.neutral.lighter}`} !important;
        color: ${({ theme }) => `${theme.palette.neutral.darkest}`} !important;
      `;
    default:
      return css``;
  }
};

export const Button = styled(AntDButton)<{
  appearance?: Appearance;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => getAppearanceStyles(props.appearance)}
  ${(props) =>
    props.disabled
      ? css`
          border-color: ${props.theme.palette.neutral.light} !important;
          color: ${props.theme.palette.neutral.dark} !important;
        `
      : css`
          &:hover,
          &:focus {
            opacity: ${props.appearance === 'transparent' ? 0.7 : 0.5};
            outline: none !important;
          }
        `}
`;

export type ButtonContainerProps = React.HTMLAttributes<HTMLDivElement>;
export const ButtonContainer = (rest: ButtonContainerProps) => (
  <Flex justifyContent="space-between" alignItems="center" {...rest} />
);
