import { Paper } from '@comet/blocks';
import { styled } from '@comet/styled';

export const FormContainer = styled(Paper)`
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px 24px 16px 24px;
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  border-radius: 6px;
`;
