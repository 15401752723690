import {
  Button,
  ButtonContainer,
  Flex,
  Form,
  FormItem,
  Input,
  InputHelpText,
  Label,
  Paper,
  Select,
  Show,
  Text,
} from '@comet/blocks';
import { queryClient, useMutation } from '@comet/query';
import { useGoBack, useNavigate, useParams } from '@comet/router';
import { styled } from '@comet/styled';
import { notification } from 'antd';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { sendErrorNotification } from 'src/blocks/Notification';
import {
  AnalyzerTypesOptions,
  DynamicTokenFilterInputMapping,
  DynamicTokenizerInputMapping,
} from '../CustomTokenizerInput';
import { createAnalyzer, useAnalyzerExistsQuery } from '../service';
import {
  AnalyzerRequestData,
  AnalyzerTypes,
  CreateAnalyzerFormData,
} from '../types';
import CharacterFiltersInput from './CharacterFilterInput';
import TokenFilterInput from './TokenFilterInput';

const FooterDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  margin-bottom: 16px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 24px;
`;

export const AnalyzerForm = () => {
  const { organisationId, projectId } = useParams();
  const navigate = useNavigate();
  const createModelMutation = useMutation({
    mutationFn: (analyzerData: AnalyzerRequestData) =>
      createAnalyzer(projectId, analyzerData),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: ['useGetAnalyzersQuery', projectId],
      });
      notification.success({
        message: 'Success',
        description: 'Analyzer created successfully',
      });
      navigate(
        'organisations.organisationId.projects.projectId.analyzers.analyzerId',
        { organisationId, projectId, analyzerId: id }
      );
    },
  });

  // Analyzer exists check logic start
  const [analyzerName, setAnalyzerName] = useState('');
  const [analyzerExistsError, setAnalyzerExistsError] = useState('');
  const analyzerExistsQuery = useAnalyzerExistsQuery(analyzerName || '');

  useEffect(() => {
    setAnalyzerExistsError(
      analyzerExistsQuery.data?.exists ? 'Analyzer already exists' : ''
    );
  }, [analyzerExistsQuery.data]);

  const debouncedOrgNameCheck = useRef(
    debounce(analyzerExistsQuery.refetch, 500)
  );
  // Analyzer exists check logic end

  const [form] = Form.useForm();
  const goBack = useGoBack();

  const [tokenizerType, setTokenizerType] = useState('standard');
  const handleCreate = (formData: CreateAnalyzerFormData) => {
    const {
      minGram,
      maxGram,
      pattern,
      group,
      maxTokenLength,
      charFilters,
      tokenFilters,
    } = formData;

    setAnalyzerExistsError('');
    if (!analyzerName?.trim()) {
      setAnalyzerExistsError('Analyzer Name is required');
      return;
    }

    if (minGram && maxGram && Number(minGram) > Number(maxGram)) {
      sendErrorNotification({
        message: 'Error',
        description: 'min gram cannot be greater than max gram',
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/full-text-search/creating-a-custom-analyzer',
      });
      return;
    }

    const createAnalyzerRequestData: AnalyzerRequestData = {
      name: analyzerName,
      tokenizer: {
        type: tokenizerType as AnalyzerTypes,
      },
    };

    if (minGram) {
      createAnalyzerRequestData.tokenizer.minGram = minGram;
    }

    if (maxGram) {
      createAnalyzerRequestData.tokenizer.maxGram = maxGram;
    }

    if (pattern) {
      createAnalyzerRequestData.tokenizer.pattern = pattern;
    }

    if (group) {
      createAnalyzerRequestData.tokenizer.group = group;
    }

    if (maxTokenLength) {
      createAnalyzerRequestData.tokenizer.maxTokenLength = maxTokenLength;
    }
    if (charFilters?.length > 0) {
      createAnalyzerRequestData.charFilters = charFilters.map((filter) => {
        const { mappings, type, ignoredTags } = filter;
        if (type === 'htmlStrip') {
          return { type, ignoredTags };
        }
        if (type === 'mapping') {
          return {
            type,
            mappings: mappings?.reduce(
              (allMappings, mapping) => ({
                ...allMappings,
                [mapping[0]]: mapping[1],
              }),
              {}
            ) as Record<string, string>,
          };
        }

        return { type };
      });
    }
    if (tokenFilters?.length > 0) {
      (createAnalyzerRequestData as any).tokenFilters = tokenFilters.map(
        (filter) => {
          const filterAttributes = DynamicTokenFilterInputMapping[filter.type];
          if (filterAttributes?.length === 0) {
            return { type: filter.type };
          }
          const filterWithAttributes = {
            type: filter.type,
          };
          for (const attribute of filterAttributes) {
            (filterWithAttributes as any)[attribute.name] = (filter as any)[
              attribute.name
            ];
          }
          return filterWithAttributes;
        }
      );
    }

    createModelMutation.mutate(createAnalyzerRequestData);
  };

  const onCancel = () => {
    form.resetFields();
    goBack();
  };

  return (
    <Flex direction="column">
      <Paper size="medium">
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={handleCreate}
        >
          <FormItem
            name="name"
            label="Analyzer Name"
            required
            style={{ width: 256 }}
            marginBottom={8}
          >
            <Input
              placeholder="CustomAnalyzer..."
              name="name"
              value={analyzerName}
              onChange={(event) => {
                setAnalyzerName(event.target.value);
                if (event.target.value) {
                  debouncedOrgNameCheck.current();
                }
              }}
            />
            <InputHelpText
              error={analyzerExistsError}
              success={
                !!analyzerName &&
                analyzerExistsQuery.data?.exists === false &&
                !analyzerExistsQuery.isFetching &&
                'Analyzer Name is available.'
              }
            >
              {analyzerExistsQuery.isFetching &&
                'Checking if Analyzer exists...'}
            </InputHelpText>
          </FormItem>
          <CharacterFiltersInput form={form} />
          <Flex direction="column" gap={16}>
            <Text appearance="label.long.semiBold">Tokenizer</Text>
            <Flex direction="column" gap={10}>
              <FormItem
                label={'Tokenizer type'}
                name="type"
                required
                marginBottom={0}
              >
                <Select
                  options={AnalyzerTypesOptions}
                  value={tokenizerType}
                  defaultValue={'standard'}
                  onChange={(value: string) => {
                    setTokenizerType(value);
                    form.setFieldsValue({
                      minGram: 0,
                      maxGram: 0,
                      pattern: '',
                      group: 0,
                      maxTokenLength: 0,
                    });
                  }}
                  style={{ width: 200 }}
                />
              </FormItem>
            </Flex>
            <Flex direction="column" gap={16} style={{ width: '50%' }}>
              <Show
                if={
                  DynamicTokenizerInputMapping[tokenizerType as AnalyzerTypes]
                    ?.length > 0
                }
              >
                <Label>Tokenizer properties</Label>
              </Show>
              {DynamicTokenizerInputMapping[
                tokenizerType as AnalyzerTypes
              ]?.map((field) => (
                <Flex direction="column" gap={10}>
                  <FormItem
                    label={field.displayName}
                    name={field.name}
                    marginBottom={0}
                    rules={[
                      {
                        required: field.required,
                        message: `${field.name} is required!`,
                      },
                    ]}
                  >
                    <Input
                      type={field.type}
                      name={field.name}
                      placeholder={`Enter ${field.name} here`}
                      min={field.min}
                      max={field.max}
                    />
                  </FormItem>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <TokenFilterInput form={form} />
          <FooterDivider />
          <ButtonContainer>
            <Button onClick={onCancel} appearance="transparent">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </ButtonContainer>
        </Form>
      </Paper>
    </Flex>
  );
};
