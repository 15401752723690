import { AddTeamMemberInvite } from '@comet/components/Members/AddTeamMemberInvite';
import { QuickStartGuide } from '@comet/components/QuickStartGuide';
import WelcomeHeaderImage from '@comet/assets/png/welcomePageHeader.png';
import styled from 'styled-components';

const Container = styled.div`
  width: 60%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div<{
  src: string;
}>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  margin: auto;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

interface WelcomePageProps {
  stepsStatus: boolean[];
}

const WelcomePage = ({ stepsStatus }: WelcomePageProps) => {
  return (
    <Container>
      <Header src={WelcomeHeaderImage}>Welcome to Cosmocloud</Header>
      <QuickStartGuide
        stepsStatus={stepsStatus}
        showDescription={true}
        title="Your Quick Start Guide"
      />
      <AddTeamMemberInvite />
    </Container>
  );
};

export default WelcomePage;
