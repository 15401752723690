import { FC, useState } from 'react';
import { Link, Navigate, useLocation, RRDUseNavigate } from '@comet/router';
import { FiArrowLeft } from 'react-icons/fi';
import { Button, Form, FormItem, Input, Show, Typography } from '@comet/blocks';
import { confirmSignUp, resendConfirmationCode } from '@comet/amplify';
import { useAuth } from '@comet/components/auth/AuthProvider';
import { styled, useTheme } from '@comet/styled';
import { getLastVisitedPage } from '../User/User.service';
import { Header } from './common';
import { ReactComponent as CosmoCloud } from '@comet/assets/svg/cosmocloud_logo.svg';

interface IFormValues {
  code: string;
}

const PageContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => `${theme.palette.neutral.lightest}`};

  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    align-items: center;
  }`}
`;

const FormContainer = styled.div`
  padding: 32px;
  margin: 0 20px;
  margin-top: 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};

  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    width: 480px;
    margin-top: 200px;
  }`}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  color: ${({ theme }) => `${theme.palette.neutral.darker}`};
`;

const ConfirmUser: FC = () => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const { login, user } = useAuth();
  const {
    theme: { palette },
  } = useTheme();

  const [isCodeSent, setIsCodeSent] = useState(false);
  const [error, setError] = useState('');
  const navigate = RRDUseNavigate();

  const handleOnSubmit = async (formValues: IFormValues) => {
    const { code } = formValues;

    try {
      await confirmSignUp(state.username, code);

      login(state.username, state.password);
    } catch (error: any) {
      form.setFields([{ name: 'code', errors: [error.message] }]);
    }
  };

  const handleResendCode = () => {
    resendConfirmationCode(state.username);

    setIsCodeSent(true);

    setTimeout(() => {
      setIsCodeSent(false);
    }, 10 * 1000);
  };

  if (user) {
    // Navigate to dashboard if user is present
    // replace=true to clear state values
    const page = getLastVisitedPage();
    if (page) {
      navigate(page);
      return null;
    }
    return <Navigate routeId="home" replace={true} />;
  }

  if (!state?.username || !state?.password) {
    return <Navigate routeId="signIn" />;
  }

  return (
    <PageContainer>
      <Header>
        <CosmoCloud />
      </Header>
      <FormContainer>
        <StyledLink routeId={'signUp'}>
          <FiArrowLeft color={palette.neutral.darker} />
          <Typography.Text
            style={{ marginLeft: '6px' }}
            color={palette.neutral.darker}
          >
            Back to sign up
          </Typography.Text>
        </StyledLink>
        <Typography>
          <Typography.Title level={2}>Verify your email</Typography.Title>
          <Typography.Paragraph>
            A {'  '}
            <Typography.Text strong>6-digit</Typography.Text> {'  '}
            code has been sent to verify your email.
          </Typography.Paragraph>
        </Typography>
        <Form form={form} onFinish={handleOnSubmit}>
          <FormItem name="code" marginBottom={36}>
            <Input
              placeholder="Confirmation code"
              // status={error !== '' ? 'error' : ''}
              onChange={() => setError('')}
            />
          </FormItem>
          <FormItem marginBottom={32}>
            <Button type="primary" htmlType="submit" block>
              Verify
            </Button>
          </FormItem>
        </Form>
        <Typography.Text style={{ marginTop: '32px' }}>
          Didn't receive the email? {'   '}
          <Show if={!isCodeSent}>
            <Typography.Link
              onClick={handleResendCode}
              strong
              style={{ color: palette.primary }}
            >
              Resend
            </Typography.Link>
          </Show>
          <Show if={isCodeSent}>
            <Typography.Text strong style={{ color: palette.primary }}>
              Code Sent
            </Typography.Text>
          </Show>
        </Typography.Text>
      </FormContainer>
    </PageContainer>
  );
};

export default ConfirmUser;
