import {
  getEdgeById,
  getEdgeId,
} from 'src/FlowEngine2/store/flow-engine-store/utils';
import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

import { NodeRaw } from 'src/FlowEngine2/types';
import { addNodeFromAddNode } from './addNodeFromNode';
import { addNodeFromEdge } from './addNodeFromEdge';

export function addSwitchV2Node(
  type: 'ADD_NODE' | 'EDGE', // A Node can be added either from add_node or edge
  id: string | null, // id can be of node or edge
  nodeToAdd: NodeRaw | null,
  branch?: 'Default' | 'Other'
) {
  if (!id || !nodeToAdd) return;

  switch (type) {
    case 'ADD_NODE': {
      const { createNode, addEdge } = useFlowEngineStore.getState();

      // Switch Node Tree
      const switchV2Node = getNodeById(createNode(nodeToAdd));
      if (!switchV2Node) return new Error(`Error in creating switch V2 node`);

      const addCaseNode = getNodeById(
        createNode('ADD_CASE_NODE', { switchNodeId: switchV2Node?.id })
      );

      if (!addCaseNode) return new Error(`Error in creating switch V2 node`);
      addEdge(switchV2Node.id, addCaseNode.id);

      const defaultExecutorNode = getNodeById(createNode('EXECUTOR_NODE'));
      const nextNode = createNode('ADD_NODE'); // node which will be connected after switch case

      if (!defaultExecutorNode || !nextNode)
        return new Error(`Error in creating switch V2 node`);

      addEdge(switchV2Node.id, defaultExecutorNode.id);
      addEdge(defaultExecutorNode.id, nextNode);

      switchV2Node.data.metadata = {
        cases: [
          {
            key: 'default',
            nodeId: defaultExecutorNode.id,
            edgeId: getEdgeId(switchV2Node.id, defaultExecutorNode.id),
          },
        ],
      };
      switchV2Node.data.values = {
        cases: [{ value: 'default' }],
      };

      addNodeFromAddNode(id, switchV2Node, []);

      break;
    }
    case 'EDGE': {
      if (!id) return;

      const { createNode, addEdge, switchToStackTop } =
        useFlowEngineStore.getState();

      const edge1 = getEdgeById(id);
      if (!edge1) return new Error(`Error in creating switch node`);

      const sourceNode = getNodeById(edge1.source);
      const targetNode = getNodeById(edge1.target);

      // create a new switch v2 node
      const switchV2Node = getNodeById(createNode(nodeToAdd));
      if (!sourceNode || !targetNode || !switchV2Node) {
        return new Error(`Error in creating switch v2 node`);
      }

      //create add case node for newly created switch v2 node
      const addCaseNode = getNodeById(
        createNode('ADD_CASE_NODE', { switchNodeId: switchV2Node.id })
      );
      if (!addCaseNode) return new Error(`Error in creating switch node`);
      addEdge(switchV2Node.id, addCaseNode?.id);

      // create default executor node
      const defaultExecutorNode = getNodeById(createNode('EXECUTOR_NODE'));
      if (!defaultExecutorNode)
        return new Error(`Error in creating switch node`);

      addEdge(sourceNode.id, switchV2Node.id); // source node connects with newly created switch v2 node
      addEdge(switchV2Node.id, defaultExecutorNode.id); // switch v2 node connnects with default executor node
      addEdge(defaultExecutorNode.id, targetNode.id); // default executor node connects with target node

      addNodeFromEdge(id, switchV2Node, []);

      switchV2Node.data.metadata.cases = [
        {
          key: 'default',
          nodeId: defaultExecutorNode.id,
          edgeId: getEdgeId(switchV2Node.id, defaultExecutorNode.id),
        },
      ];
      switchV2Node.data.values = {
        cases: [{ value: 'default' }],
      };

      switchToStackTop();
      break;
    }
    default:
  }
}
