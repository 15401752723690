import { NodeRaw, NodeTypeEnum } from 'src/FlowEngine2/types';
import {
  getEdgeById,
  getEdgeId,
  getNodesAttachedWithTargetHandle,
  updateMetaDataOfIfNode,
  updateMetadataOfSwitchNode,
} from 'src/FlowEngine2/store/flow-engine-store/utils';
import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

export function addNodeFromEdge(
  id: string | null,
  node: NodeRaw,
  extraNodes: NodeRaw[]
) {
  const { removeEdge, addEdge } = useFlowEngineStore.getState();

  const edge1 = getEdgeById(id);
  if (!edge1) return new Error(`Edge does not exist`);

  const node1 = getNodeById(edge1.source); //source node
  const node2 = getNodeById(edge1.target); //target node

  if (!node1 || !node2)
    return new Error(`Either source node or target node do not exist`);

  switch (node1.type) {
    case NodeTypeEnum.LoopNode:
    case NodeTypeEnum.BaseNode: {
      addEdge(node1.id, node.id);
      extraNodes.forEach((extraNode) => {
        addEdge(extraNode.id, node2.id);
      });

      // removing original edge
      removeEdge(edge1.id);
      break;
    }

    case NodeTypeEnum.IfElse: {
      updateMetaDataOfIfNode(node1.id, node2.id, node.id);

      addEdge(node1.id, node.id);
      extraNodes.forEach((extraNode) => {
        addEdge(extraNode.id, node2.id);
      });

      // removing original edge
      removeEdge(edge1.id);
      break;
    }
    case NodeTypeEnum.IfElseV2: {
      // Node1 can never directly be ifv2
      // It will be an executor node and then ifV2 node connected to executor node
      // So,please check code for executor node
      break;
    }
    case NodeTypeEnum.ExecutorNode: {
      const nodeAboveNode1Id = getNodesAttachedWithTargetHandle(node1.id)![0];
      const nodeAboveNode1 = getNodeById(nodeAboveNode1Id);

      if (nodeAboveNode1 && nodeAboveNode1.type === NodeTypeEnum.IfElseV2) {
        const { true: trueId, false: falseId } = nodeAboveNode1.data.metadata;
        const trueExecutorNode = getNodeById(trueId);
        const falseExecutorNode = getNodeById(falseId);

        if (!trueExecutorNode || !falseExecutorNode)
          return new Error(
            `Either true executor or false executor does not exist`
          );

        addEdge(trueExecutorNode.id, node.id);
        addEdge(falseExecutorNode.id, node.id);

        extraNodes.forEach((extraNode) => {
          addEdge(extraNode.id, node2.id);
        });

        removeEdge(getEdgeId(trueExecutorNode.id, node2.id));
        removeEdge(getEdgeId(falseExecutorNode.id, node2.id));
      }

      if (nodeAboveNode1 && nodeAboveNode1.data.type === 'SWITCH_CASE_V2') {
        const { cases } = nodeAboveNode1.data.metadata;
        const updatedCases: { key: string; nodeId: string; edgeId: string }[] =
          [];
        cases.forEach(
          ({
            key,
            nodeId,
            edgeId,
          }: {
            key: string;
            nodeId: string;
            edgeId: string;
          }) => {
            const caseNode = getNodeById(nodeId);
            if (!caseNode) return;

            //break connection between caseNode and target node
            const edgeToRemove = getEdgeId(caseNode.id, node2.id);
            removeEdge(edgeToRemove);

            //make connection between caseNode and new node
            addEdge(caseNode.id, node.id);

            //make connection between new node and target node
            addEdge(node.id, node2.id);

            //update metadata of caseNode
            const updatedCase = {
              key,
              nodeId,
              edgeId: getEdgeId(caseNode.id, node.id) || '',
            };
            updatedCases.push(updatedCase);
          }
        );
        nodeAboveNode1.data.metadata.cases = updatedCases;
      }
      break;
    }
    case NodeTypeEnum.SwitchNode: {
      addEdge(node1.id, node.id);
      addEdge(node.id, node2.id);
      updateMetadataOfSwitchNode(node1.id, node2.id, node.id);
      removeEdge(id);
      break;
    }
  }
}
