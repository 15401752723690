import { useCallback, useEffect, useMemo, useState } from 'react';
import { format } from '@comet/date';
import { Dropdown, TableHeader, Text } from '@comet/blocks';
import {
  DeploymentInfo,
  GetDeploymentsResponse,
  ReleaseInfo,
} from '../types/Projects.types';
import { VersionContainer } from './Deployments.styled';
import { Release } from './ReleaseModal';
import { User } from '@comet/components/User';
import {
  rollbackDeployment,
  useDeploymentReleasesQuery,
} from './Deployments.service';
import { Column } from 'react-table';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useParams } from '@comet/router';
import { PromoteModal } from './PromoteModal';
import { Avatar, Tooltip } from 'antd';

const formatUpdatedTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

export const useDeploymentsVersion = (projectId: string) => {
  const {
    data: releaseData,
    isLoading,
    isSuccess,
    isError,
  } = useDeploymentReleasesQuery(projectId, {});

  const [latest, setLatest] = useState<string | null>(null);
  const versions = useMemo(
    () =>
      (releaseData?.data || [])
        .filter((deployment: ReleaseInfo) => deployment.status !== 'PENDING')
        .map((deployment: ReleaseInfo) => {
          if (deployment.status === 'DRAFT') {
            return { label: 'Draft', value: null };
          } else if (deployment.status === 'ACTIVE') {
            setLatest(deployment.version);
            return {
              label: `Latest (${deployment.version})`,
              value: deployment.version,
            };
          } else {
            return { label: deployment.version, value: deployment.version };
          }
        }),
    [releaseData]
  );

  const currentVersion = versions[0];

  const [version, setVersion] = useState<string | null>(
    currentVersion?.value || null
  );

  useEffect(() => {
    if (!isLoading && currentVersion) {
      setVersion(currentVersion.value);
    }
  }, [isLoading, currentVersion]);

  return {
    isLoading,
    isSuccess,
    isError,
    versions,
    version,
    setVersion,
    latest,
  };
};

export const DeploymentsActions = ({
  version,
  deployedEnvironmentIds,
  refetchDeployments,
}: {
  deployedEnvironmentIds: string[];
  version: string;
  refetchDeployments: () => void;
}) => {
  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Promote to Environment',
              key: 'promote',
              label: (
                <Tooltip
                  title={`This will release ${version} to selected environment`}
                >
                  Promote
                </Tooltip>
              ),
              onClick: toggleModal,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <PromoteModal
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
        version={version}
        deployedEnvironmentIds={deployedEnvironmentIds}
        onComplete={refetchDeployments}
      />
    </>
  );
};

export const useDeploymentListData = (
  deployments: GetDeploymentsResponse | undefined,
  refetchDeployments: () => void
) => {
  const { projectId } = useParams();
  const onRollback = useCallback(
    async (version: string) => {
      await rollbackDeployment(projectId, version);
      refetchDeployments();
    },
    [projectId, refetchDeployments]
  );

  const tableColumns = useMemo(
    (): Column<(typeof tableData)[number]>[] => [
      {
        Header: <TableHeader text="Version" sortable />,
        accessor: 'version',
      },
      // {
      //   Header: <TableHeader text="Status" />,
      //   accessor: 'status',
      // },
      {
        Header: <TableHeader text="Deployed On" />,
        accessor: 'environments',
        Cell: (data) => {
          const environments = data.row.original.environments;

          if (environments.length === 0) {
            return <>--</>;
          }

          return (
            <Avatar.Group maxCount={2}>
              {environments.map((env) => {
                return (
                  <Avatar
                    style={{ color: '#D4D1CE', backgroundColor: '#5869D6' }}
                    size={'small'}
                    alt={env.name}
                    key={env.environmentId}
                  >
                    <Tooltip title={env.name}>
                      {env.name[0].toUpperCase()}
                    </Tooltip>
                  </Avatar>
                );
              })}
            </Avatar.Group>
          );
        },
      },
      {
        Header: <TableHeader text="Description" sortable />,
        accessor: 'description',
      },
      {
        Header: <TableHeader text="Time of Deployment" sortable />,
        accessor: 'updatedOn',
      },
      {
        Header: <TableHeader text="Deployed by" sortable />,
        accessor: 'updatedBy',
      },
      {
        Header: '',
        accessor: 'id',
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    if (!deployments?.data) return [];

    return (
      deployments.data?.map((deployment: DeploymentInfo) => {
        const {
          status,
          version,
          description,
          updatedBy,
          updatedOn,
          environments,
        } = deployment;

        const deployedEnvironmentIds = environments.map(
          (env) => env.environmentId
        );

        if (status === 'DRAFT') {
          return {
            // status: <Status status={status}> {status} </Status>,
            version: <Text appearance="body.regular">-</Text>,
            environments: [],
            description: <Text appearance="body.regular">-</Text>,
            updatedOn: <Text appearance="body.regular">-</Text>,
            updatedBy: <Text appearance="body.regular">-</Text>,
            id: <Release />,
          };
        }

        return {
          // status: <Status status={status}> {status} </Status>,
          version: <VersionContainer>{version}</VersionContainer>,
          description: <Text appearance="body.regular">{description}</Text>,
          environments: environments,
          updatedOn: (
            <Text appearance="label.long.regular">
              {formatUpdatedTime(updatedOn)}
            </Text>
          ),
          updatedBy: <User {...(updatedBy || {})} />,
          id: (
            <DeploymentsActions
              version={version}
              deployedEnvironmentIds={deployedEnvironmentIds}
              refetchDeployments={refetchDeployments}
            />
          ),
        };
      }) || []
    );
  }, [deployments, onRollback, refetchDeployments]);

  return { tableData, tableColumns };
};
