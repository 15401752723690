import { Form } from '@comet/blocks';
import {
  BulkEnvironmentType,
  FieldType,
  FieldTypeKeys,
} from '../types/AddEnvironments.types';
import { useEffect, useState } from 'react';
import { useGetTiersPricing } from '@comet/pages/Project/Environments/utils';
import { useInitialEnvironmentsFields } from './useInitialEnvironmentsFields';

export const useAddBulkEnvironmentForm = () => {
  const [form] = Form.useForm();

  const { initialEnvironmentsFields, environmentsFields } =
    useInitialEnvironmentsFields();

  const [environments, setEnvironments] =
    useState<FieldType[]>(environmentsFields);

  // updating the environments fields
  useEffect(() => {
    if (!environmentsFields) return;

    setEnvironments(environmentsFields);
  }, [environmentsFields]);

  // updating the form fields
  useEffect(() => {
    if (!initialEnvironmentsFields) return;
    form.setFieldValue('fields', initialEnvironmentsFields);
  }, [form, initialEnvironmentsFields]);

  const [selectedCurrency, setSelectedCurrency] = useState<'INR' | 'USD'>(
    'INR'
  );

  const addField = () => {
    const newEnvironments = [...environments];
    // TODO: refactor this function
    newEnvironments.push({
      type: {
        value: '',
        disabled: false,
      },
      name: {
        value: '',
        disabled: true,
      },
      tier: {
        value: '',
        disabled: true,
      },
      isShared: {
        value: false,
        disabled: true,
      },
      sharedEnvName: {
        value: null,
        disabled: true,
      },
    });

    setEnvironments(newEnvironments);
  };

  const removeField = (fieldIndex: number) => {
    const newEnvironments = [...environments];
    newEnvironments.splice(fieldIndex, 1);
    setEnvironments(newEnvironments);
  };

  const updateEnvironments = (
    environmentIndex: number,
    name: FieldTypeKeys,
    value: string | boolean,
    disabled: boolean
  ) => {
    const newEnvironments = [...environments];
    newEnvironments[environmentIndex][name].value = value;
    newEnvironments[environmentIndex][name].disabled = disabled;
    setEnvironments(newEnvironments);
  };

  const [envNames, setEnvNames] = useState([]);

  const updateEnvNamesOptions = () => {
    const { fields } = form.getFieldsValue();
    const envNamesOptions = fields
      .filter((f: BulkEnvironmentType) => !f.metadata.isShared)
      .map((f: BulkEnvironmentType) => ({ label: f.name, value: f.name }));

    setEnvNames(envNamesOptions);
  };

  const disableField = (fieldIndex: number, fieldName: FieldTypeKeys) => {
    const newEnvironments = [...environments];
    newEnvironments[fieldIndex][fieldName].disabled = true;
    setEnvironments(newEnvironments);
  };

  const enableField = (fieldIndex: number, fieldName: FieldTypeKeys) => {
    const newEnvironments = [...environments];
    newEnvironments[fieldIndex][fieldName].disabled = false;
    setEnvironments(newEnvironments);
  };

  const onEnvironmentTypeChange = (environmentIndex: number, value: string) => {
    if (value === 'STAGING') {
      disableField(environmentIndex, 'sharedEnvName');
      updateEnvironments(environmentIndex, 'name', 'staging', true);
      form.setFieldValue(['fields', environmentIndex, 'name'], 'staging');
      form.setFieldValue(
        ['fields', environmentIndex, 'metadata', 'isShared'],
        false
      );
      enableField(environmentIndex, 'tier');
    } else if (value === 'DEVELOPMENT') {
      disableField(environmentIndex, 'isShared');
      disableField(environmentIndex, 'sharedEnvName');
      updateEnvironments(environmentIndex, 'name', 'development', true);
      form.setFieldValue(['fields', environmentIndex, 'name'], 'development');
      form.setFieldValue(
        ['fields', environmentIndex, 'metadata', 'isShared'],
        false
      );
      enableField(environmentIndex, 'tier');
    } else if (value === 'PRODUCTION') {
      disableField(environmentIndex, 'isShared');
      disableField(environmentIndex, 'sharedEnvName');
      updateEnvironments(environmentIndex, 'name', 'production', true);
      form.setFieldValue(['fields', environmentIndex, 'name'], 'production');
      form.setFieldValue(
        ['fields', environmentIndex, 'metadata', 'isShared'],
        false
      );
      enableField(environmentIndex, 'tier');
    } else {
      enableField(environmentIndex, 'name');
      enableField(environmentIndex, 'tier');
      disableField(environmentIndex, 'isShared');
      updateEnvironments(environmentIndex, 'type', value, false);
      form.setFieldValue(['fields', environmentIndex, 'name'], '');
    }

    updateEnvNamesOptions();
  };

  const tiersPricing = useGetTiersPricing();

  const [totalPrice, setTotalPrice] = useState(0);

  const onEnvironmentTierChange = (index: number, value: string) => {
    updateEnvironments(index, 'tier', value, false);
  };

  const onResourceTypeChange = (index: number, value: boolean) => {
    updateEnvironments(index, 'isShared', value, false);

    if (value) {
      enableField(index, 'sharedEnvName');
      updateEnvironments(index, 'tier', '', true);
      form.setFieldValue(['fields', index, 'metadata', 'tier'], null);
    } else {
      enableField(index, 'tier');
      updateEnvironments(index, 'sharedEnvName', '', true);
      form.setFieldValue(['fields', index, 'metadata', 'sharedEnvName'], null);
    }

    updateEnvNamesOptions();
  };

  useEffect(() => {
    if (!environments || !selectedCurrency) return;

    let calculatedPrice = 0;

    environments?.forEach((environment) => {
      if (environment?.tier?.value)
        calculatedPrice +=
          tiersPricing[environment.tier.value]?.[selectedCurrency];
    });

    setTotalPrice(calculatedPrice);
  }, [tiersPricing, environments, selectedCurrency]);

  const onEnvironmentNameChange = (
    fieldIndex: number,
    value: string | boolean
  ) => {
    updateEnvironments(fieldIndex, 'name', value, false);

    updateEnvNamesOptions();
  };

  return {
    addField,
    envNames,
    environments,
    form,
    onCurrencyChange: setSelectedCurrency,
    onEnvironmentTierChange,
    onEnvironmentTypeChange,
    onEnvironmentNameChange,
    onResourceTypeChange,
    removeField,
    selectedCurrency,
    setSelectedCurrency,
    totalPrice,
  };
};
