import React from 'react';
import { styled } from '@comet/styled';
import { Flex } from '../Flex';
import { Show } from '../Show';
import { MdSwapVert } from 'react-icons/md';

interface IProps {
  text: string;
  sortable?: boolean;
}

const Text = styled.div`
  color: ${({ theme }) => theme.palette.neutral.darker};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

const SortIcon = styled(MdSwapVert)`
  cursor: pointer;
`;

export const TableHeader = (props: IProps) => {
  const { text, sortable } = props;

  return (
    <Flex gap={6}>
      <Text>{text}</Text>
      <Show if={Boolean(sortable)}>
        <SortIcon />
      </Show>
    </Flex>
  );
};
