import TreeLayout from './tree.layout';
import { EdgeGroups, NodeGroups, NodeMap } from './tree.types';
import * as nodeUtils from '../../Nodes';
import { Graph } from 'src/types/common';
import { NodeTypeEnum } from '../../Nodes';

export type xGaptionMap = Record<string, number>;
export type borderMap = Record<number, number>;

const xGap = 50;
const yGap = 85;

export const getTreeGroupLayout = (
  id: string,
  nodeGroups: NodeGroups,
  edgeGroups: EdgeGroups,
  nodeMap: NodeMap
) => {
  const nodes = nodeGroups[id];
  const edges = edgeGroups[id] || [];
  const childTree: Record<string, TreeLayout> = {};
  const childGraph: Graph = { nodes: [], edges: [] };
  nodes.forEach(({ id: nodeId }) => {
    if (nodeGroups[nodeId]) {
      const { layout, graph } = getTreeGroupLayout(
        nodeId,
        nodeGroups,
        edgeGroups,
        nodeMap
      );
      const treeBorder = layout.getTreeBorders();
      const diff = xGap - treeBorder.xMin;
      layout.moveTree(diff, yGap);
      childTree[nodeId] = layout;
      childGraph.nodes = childGraph.nodes.concat(layout.getNodes());
      childGraph.edges = childGraph.edges.concat(layout.edges);
      childGraph.nodes = childGraph.nodes.concat(graph.nodes);
      childGraph.edges = childGraph.edges.concat(graph.edges);
    }
  });

  const getHeight = (treeId: string) => {
    if (childTree[treeId]) {
      return childTree[treeId].getTreeDimension().height + xGap * 2;
    }

    if (!nodeMap[treeId]?.type) {
      throw Error(`type not available for ${treeId}`);
    }

    return nodeUtils.getHeight(nodeMap[treeId].type as NodeTypeEnum);
  };

  const getWidth = (treeId: string) => {
    if (childTree[treeId]) {
      return childTree[treeId].getTreeDimension().width + xGap * 2;
    }

    if (!nodeMap[treeId]?.type) {
      throw Error(`type not available for ${treeId}`);
    }

    return nodeUtils.getWidth(nodeMap[treeId].type as NodeTypeEnum);
  };

  const layout = new TreeLayout(nodes, edges, {
    getHeight,
    getWidth,
  });

  const graph = layout.getLayout();
  layout.moveTreeToCenter();
  const graphNodes = graph.nodes;

  graphNodes.forEach(({ id: nodeId }, index) => {
    if (nodeGroups[nodeId]) {
      graphNodes[index].style = {
        ...nodes[index].style,
        width: getWidth(nodeId),
        height: getHeight(nodeId) + 10,
        //TODO: replace this with a class
        // backgroundColor: 'white',
        zIndex: -1,
        border: 'none',
        // borderRadius: 6,
      };
    }
  });

  return { layout, graph: childGraph };
};
