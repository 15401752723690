import { Paper } from '@comet/blocks';
import { ProjectForm } from './ProjectForm/ProjectForm';
import { useParams, useNavigate } from '@comet/router';

export default function NewProject() {
  const navigate = useNavigate();

  const { organisationId } = useParams();

  const onNextClick = (id: string) => {
    navigate('organisations.organisationId.projects.projectId.dashboard', {
      organisationId,
      projectId: id,
    });
  };

  const onCancelProjectCreation = () => {
    navigate(`organisations.organisationId.projects`, { organisationId });
  };

  // const onGoBackClick = () => {
  //   setCurrentStep(NewProjectSteps.AddProjectDetails);
  // };

  return (
    <Paper size="medium">
      {/* <div> */}
      {/* <Text appearance="heading.card">Create a new Project</Text> */}
      {/* <ContainerSteps current={currentStep} size="small" items={StepsItems} /> */}
      {/* </div> */}
      <ProjectForm
        organisationId={organisationId}
        onSuccess={onNextClick}
        onCancel={onCancelProjectCreation}
      />
      {/* <Show if={currentStep === NewProjectSteps.AddProjectDetails}>
        <ProjectForm
          organisationId={organisationId}
          primaryButtonText="Next"
          secondaryButtonText="Cancel"
          onSuccess={onNextClick}
          onCancel={onCancelProjectCreation}
        />
      </Show> */}
      {/* <ContainerFooter justifyContent="space-between" alignItems="center">
        {currentStep === NewProjectSteps.InviteMembers && (
          <>
            <Button type="default" onClick={onGoBackClick}>
              Go Back
            </Button>
            <Button type="primary">Submit</Button>
          </>
        )}
      </ContainerFooter> */}
    </Paper>
  );
}
