import { ReactNode } from 'react';
import { RoutesIdType } from 'src/service/router/routes';

export interface SidebarMenuItem {
  label: ReactNode;
  key: string;
  route?: RoutesIdType;
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
}

export interface SidebarMenuItemGroup {
  label: ReactNode;
  key: string;
  route?: RoutesIdType;
  children?: SidebarMenuItem[];
  disabled?: boolean;
  hidden?: boolean;
}

export interface SidebarMenuItemGroupWithoutKey
  extends Omit<SidebarMenuItemGroup, 'key' | 'children'> {
  children?: SidebarMenuItemGroupWithoutKey[];
  href?: string;
}

export interface SidebarMenuItemKey {
  key: string;
  childrenKeys?: SidebarMenuItemKey[];
}

export type SideBarType = 'PROJECT' | 'ORG';

export enum SideBarEnum {
  PROJECT = 'PROJECT',
  ORG = 'ORG',
}
