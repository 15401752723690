import { Show, Text } from '@comet/blocks';
import { styled } from '@comet/styled';
import { BusinessNodeProps } from '.';

const SubTitle = styled.p`
  color: ${({ theme }) => theme.palette.neutral.darker};
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.palette.neutral.darkest};
  font-size: 14px;
  margin: 0;
  font-weight: 600;
`;

const getSubTitle = (type: BusinessNodeProps['type']) => {
  switch (type) {
    case 'HTTP_TRIGGER': {
      return 'Trigger';
    }

    case 'DB':
    case 'LIST_RECORDS':
    case 'CUSTOM_QUERY_RECORDS':
    case 'DELETE_MULTIPLE_RECORDS':
    case 'DELETE_RECORD':
    case 'FETCH_RECORD':
    case 'INSERT_MULTIPLE_RECORDS':
    case 'INSERT_RECORD':
    case 'UPDATE_MULTIPLE_RECORDS':
    case 'UPDATE_RECORD':
    case 'UPDATE_ONE_RECORD': {
      return 'Database';
    }
    case 'ACTION': {
      return 'Action';
    }

    case 'FUNCTION_RESPONSE':
    case 'HTTP_RESPONSE': {
      return 'Response';
    }

    case 'ADD_VARIABLE':
    case 'APPEND_TO_STRING':
    case 'APPEND_TO_ARRAY':
    case 'BUILD_JSON':
    case 'BUILD_MAP':
    case 'COMPLEX_MATHS_EXPR':
    case 'CONCAT_STRINGS':
    case 'CONTAINS':
    case 'DECREMENT_VARIABLE':
    case 'DIVIDE_VARIABLE':
    case 'EMPTY_ARRAY':
    case 'INCREMENT_VARIABLE':
    case 'LENGTH_ARRAY':
    case 'MERGE_JSON':
    case 'MULTIPLY_VARIABLE':
    case 'REVERSE_ARRAY':
    case 'SET_VARIABLE':
    case 'SLICE_STRING':
    case 'SUBTRACT_VARIABLE':
    case 'TO_LOWER':
    case 'TO_UPPER':
    case 'VALIDATE_MODEL':
    case 'TRIM_STRING':
      return 'General';

    default: {
      return type;
    }
  }
};

export const BusinessNodeInfo = (props: BusinessNodeProps) => {
  const subTitle = getSubTitle(props.type);

  return (
    <>
      <Text appearance="caption.allCaps" color="neutral.dark">
        {subTitle}
      </Text>
      <Show if={!!props.displayName}>
        <Text appearance="heading.paragraph">{props.displayName}</Text>
      </Show>
      <Text appearance="caption.regular">{props.nodeName}</Text>
    </>
  );
};
