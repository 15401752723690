import { MdContentCopy } from 'react-icons/md';
import { styled } from '@comet/styled';

const StyledCopyIcon = styled(MdContentCopy)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.neutral.dark};
`;

interface CopyToClipboardProps {
  onClick: () => void;
}
export const CopyToClipboard = ({ onClick }: CopyToClipboardProps) => {
  return (
    <>
      <StyledCopyIcon onClick={onClick} />
    </>
  );
};
