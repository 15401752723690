import { SidebarMenuItemGroup, SidebarMenuItemGroupWithoutKey } from './types';
import { useMemo } from 'react';

import { attachKeys, generateKeys } from './Sidebar.utils';
import { useGetProjectMenuItemsWithoutKey } from './useGetProjectMenuItemsWithoutKey';

export const useProjectMenuItems = () => {
  const projectMenuItemsWithoutKey: SidebarMenuItemGroupWithoutKey[] =
    useGetProjectMenuItemsWithoutKey();

  const keys = generateKeys('', projectMenuItemsWithoutKey);
  const result = useMemo(() => {
    const projectMenuItems: SidebarMenuItemGroup[] = attachKeys(
      projectMenuItemsWithoutKey,
      keys
    );
    return projectMenuItems;
  }, [projectMenuItemsWithoutKey, keys]);

  return result;
};
