import { VscError, VscInfo } from 'react-icons/vsc';

import { ActionModalTypesEnum } from './types';
import { AiOutlineWarning } from 'react-icons/ai';
import { GiConfirmed } from 'react-icons/gi';
import { GrCircleQuestion } from 'react-icons/gr';

export function getIcon(type: ActionModalTypesEnum) {
  let icon, iconColor;

  if (type === ActionModalTypesEnum.Error) {
    icon = VscError;
    iconColor = 'blue';
  } else if (type === ActionModalTypesEnum.Question) {
    icon = GrCircleQuestion;
    iconColor = 'blue';
  } else if (type === ActionModalTypesEnum.Confirm) {
    icon = GiConfirmed;
    iconColor = 'blue';
  } else if (type === ActionModalTypesEnum.Info) {
    icon = VscInfo;
    iconColor = 'blue';
  } else {
    icon = AiOutlineWarning;
    iconColor = 'blue';
  }

  return { icon, iconColor };
}
