import {
  Button,
  ButtonContainer,
  Divider,
  Input,
  Paper,
  Text,
} from '@comet/blocks';
import { Form, notification } from 'antd';
import { Background } from '../CreateOrganization/CreateOrganization.utils';
import { useCreateStorageAccount } from './storage.service';
import { useNavigate, useParams } from '@comet/router';
import { AxiosError } from 'axios';
import { sendErrorNotification } from 'src/blocks/Notification';

export const CreateStorageAccount = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const { organisationId } = useParams();

  const { mutateAsync, isLoading: loading } =
    useCreateStorageAccount(organisationId);

  const onFinish = async () => {
    try {
      await form.validateFields();
      await mutateAsync({ name: form.getFieldValue('name') });
      navigate(
        'organisations.organisationId.storage',
        { organisationId },
        { replace: true }
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        form.setFields([{ name: 'name', errors: [e.response?.data.message] }]);
        return;
      }
      sendErrorNotification({
        message: 'Error',
        description: (e as any)?.message || 'Unable to create storage account',
        reportBug: true,
      });
    }
  };

  return (
    <Paper size="medium">
      <Form
        name="form"
        layout="vertical"
        style={{ width: '60%' }}
        initialValues={{ name: '' }}
        autoComplete="off"
        form={form}
        onFinish={onFinish}
      >
        <Text appearance="heading.card" style={{ marginBottom: 8 }}>
          Create a New Bucket
        </Text>
        <Form.Item
          name={'name'}
          label="Bucket name"
          rules={[{ required: true, message: 'Please enter bucket name' }]}
        >
          <Input placeholder="user-avatars" />
        </Form.Item>
      </Form>
      <Divider spread={24} />
      <ButtonContainer style={{ justifyContent: 'flex-end' }}>
        <Button htmlType="submit" appearance="primary" loading={loading}>
          Create
        </Button>
      </ButtonContainer>
    </Paper>
  );
};
