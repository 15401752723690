import {
  Button,
  Flex,
  FullCenter,
  Show,
  Select,
  Spin,
  Table,
  Text,
  Tooltip,
  usePagination,
} from '@comet/blocks';
import { Empty } from 'antd';
import { useNavigate, useParams } from '@comet/router';
import { PAGE_SIZE, GetSubflowsParams } from '../types';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useGetSubflowsQuery } from '@comet/pages/Project/Subflows/service';
import { useSubflowListingData } from '@comet/pages/Project/Subflows/utils';
import { useDeploymentsVersion } from '@comet/pages/Project/Deployments/Deployments.utils';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

export const Subflows = () => {
  const navigate = useNavigate();
  const { organisationId, projectId } = useParams();
  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const { pageOffset, currentPageIndex, fetchData } = usePagination();

  const {
    latest,
    versions,
    version,
    setVersion,
    isLoading: isVersionLoading,
    isError: isVersionError,
  } = useDeploymentsVersion(projectId);

  const {
    data: subflowsData,
    isLoading: isLoadingSubflows,
    refetch: refetchSubflows,
  } = useGetSubflowsQuery(projectId, {
    flow_type: 'FUNCTION',
    version: version,
    offset: pageOffset,
    active: true,
  });

  const { tableColumns, tableData } = useSubflowListingData(
    subflowsData,
    version,
    latest,
    refetchSubflows
  );

  const isError = !subflowsData;
  const showList =
    !isLoadingSubflows && !isError && subflowsData?.data.length > 0;

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);

  const isCreateSubflowDisabled =
    !orgSubscriptionData?.active ||
    (orgSubscriptionData?.resourceLimits.maxSubFlows || 0) <=
      (subflowsData?.page.total || 0);

  return (
    <Flex
      width="100%"
      direction="column"
      style={{ minHeight: '100%' }}
      gap={14}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">SubFlows</Text>
          <Show if={versions.length > 0}>
            <Select
              options={versions}
              value={version}
              onChange={setVersion}
              style={{ minWidth: 100 }}
            />
          </Show>
        </Flex>
        <Tooltip
          title={
            hasManageProjectSettingsAccess
              ? ''
              : 'Please check if you have enough permissions to create environment.'
          }
        >
          <Button
            type="primary"
            onClick={() =>
              navigate(
                'organisations.organisationId.projects.projectId.subflows.new'
              )
            }
            disabled={
              isCreateSubflowDisabled || !hasManageProjectSettingsAccess
            }
          >
            Create SubFlow
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Show if={isLoadingSubflows}>
          <FullCenter>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoadingSubflows && subflowsData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No SubFlows found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="SubFlows"
            currentPageIndex={currentPageIndex}
            totalRows={subflowsData?.page.total || 0}
            totalPageCount={Math.ceil(
              (subflowsData?.page.total || 0) / PAGE_SIZE
            )}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
