import { styled } from '@comet/styled';
import { Dropdown } from 'antd';
import { Button } from '../Button';
import { Flex } from '../Flex';
import FlexItem from '../FlexItem';
import { Show } from '../Show';
import { Text } from '../Text/Text';
import { MdMoreHoriz, MdOpenInNew } from 'react-icons/md';
import { ReactNode } from 'react';

const TabButton = styled.div<{ active: boolean }>`
  border: none;
  background: ${({ theme }) => theme.palette.neutral.white};
  color: ${({ theme }) => theme.palette.neutral.darker};
  padding: 8px 16px;
  border-radius: 16px;
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.palette.neutral.darkest};
    border: 1px solid ${theme.palette.neutral.light};
  `}

  &:hover {
    color: ${({ theme }) => theme.palette.neutral.darkest};
  }
`;

const Container = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: 12px 24px;
`;

export interface PageHeaderProps {
  title: string;
  primaryAction?: ReactNode;
  tabs?: Array<{
    active: boolean;
    title: string;
    id?: string;
    onClick: (id?: string) => void;
  }>;
  menu?: Array<{
    title: string;
    id?: string;
    onClick: (id?: string) => void;
  }>;
  redirectUrl?: string;
}

export const PageHeader = ({
  title,
  menu = [],
  tabs,
  primaryAction,
  redirectUrl,
}: PageHeaderProps) => {
  return (
    <Container alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Text appearance="heading.card">{title}</Text>
        {redirectUrl && (
          <Flex justifyContent="flex-end">
            <Button
              type="link"
              onClick={() => window.open(redirectUrl, '_blank')}
            >
              <Flex gap={10} alignItems="center" justifyContent="center">
                <MdOpenInNew />
                <div>Learn more</div>
              </Flex>
            </Button>
          </Flex>
        )}
      </Flex>
      <FlexItem flex shrink={0}>
        {tabs?.map(({ onClick, title, id, active }, index) => (
          <TabButton key={title} active={active} onClick={() => onClick(id)}>
            <Text
              key={index}
              appearance={
                active ? 'label.short.semiBold' : 'label.short.regular'
              }
            >
              {title}
            </Text>
          </TabButton>
        ))}
      </FlexItem>
      <FlexItem shrink={0}>
        <Flex gap={8} alignItems="center">
          <Show if={!!primaryAction}>{primaryAction}</Show>
          <Show if={menu.length !== 0}>
            <Dropdown
              trigger={['click']}
              menu={{
                items: menu.map(({ title, id, onClick }) => ({
                  onClick: () => onClick(id),
                  label: title,
                  key: title,
                })),
              }}
            >
              <Button icon={<MdMoreHoriz />} />
            </Dropdown>
          </Show>
        </Flex>
      </FlexItem>
    </Container>
  );
};
