import {
  SchemaKeySubType,
  SchemaKeyType,
  TSchema,
} from '@comet/components/SchemaTree/SchemaTree.types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { get, set as lodashSet, unset } from 'lodash';

interface SchemaState {
  schema: TSchema;
  updatePropertyName: (path: string, updatedName: string) => void;
  updatePropertyType: (
    path: string,
    updatedType: SchemaKeyType,
    updatedSubType: SchemaKeySubType
  ) => void;
  addProperty: (path: string) => void;
  removeProperty: (path: string) => void;
  updatePropertyRequire: (path: string, isRequired: boolean) => void;
  updateSchema: (schema: TSchema) => void;
}

const schema: TSchema = {
  type: SchemaKeyType.NESTED,
  name: 'root',
  properties: {},
  order: [],
};

const cloneObj = (obj: object) => {
  return JSON.parse(JSON.stringify(obj));
};

const getInfoFromPath = (path: string) => {
  const pathToProperty = path
      ? ['properties', ...path.split('/').join('/properties/').split('/')]
      : [],
    propertyId = pathToProperty[pathToProperty.length - 1],
    pathToOrder = [...pathToProperty, 'order'],
    pathToName = [...pathToProperty, 'name'],
    pathToType = [...pathToProperty, 'type'],
    pathToSubType = [...pathToProperty, 'subType'],
    pathToRequired = [...pathToProperty, 'required'],
    pathToObjectProperties = [...pathToProperty, 'properties'],
    pathToParentOrder = [...pathToProperty];

  pathToParentOrder.pop();
  pathToParentOrder.pop();
  pathToParentOrder.push('order');

  return {
    pathToProperty,
    propertyId,
    pathToOrder,
    pathToName,
    pathToType,
    pathToSubType,
    pathToRequired,
    pathToParentOrder,
    pathToObjectProperties,
  };
};

const useSchemaStore = create<SchemaState>()(
  devtools((set) => ({
    schema: schema,

    updatePropertyName: (path, updatedName) => {
      return set((state) => {
        const updatedSchema = cloneObj(state.schema),
          { pathToName } = getInfoFromPath(path);

        lodashSet(updatedSchema, pathToName, updatedName);

        return {
          schema: updatedSchema,
        };
      });
    },

    addProperty: (pathToParent) => {
      return set((state) => {
        const updatedSchema = cloneObj(state.schema),
          { pathToProperty: pathToParentProperty, pathToOrder } =
            getInfoFromPath(pathToParent),
          newId = crypto.randomUUID(),
          orderArray = get(updatedSchema, pathToOrder, []);

        orderArray.push(newId);

        lodashSet(updatedSchema, pathToOrder, orderArray);

        pathToParentProperty.push('properties', newId);

        lodashSet(updatedSchema, pathToParentProperty, {
          name: '',
          type: SchemaKeyType.STRING,
        });

        return {
          schema: updatedSchema,
        };
      });
    },

    removeProperty: (path) => {
      return set((state) => {
        const updatedSchema = cloneObj(state.schema),
          { pathToProperty, propertyId, pathToParentOrder } =
            getInfoFromPath(path),
          parentOrderArray = get(updatedSchema, pathToParentOrder, []),
          updatedParentOrderArray = parentOrderArray.filter((id: string) => {
            return id !== propertyId;
          });

        unset(updatedSchema, pathToProperty);
        lodashSet(updatedSchema, pathToParentOrder, updatedParentOrderArray);

        return {
          schema: updatedSchema,
        };
      });
    },

    updatePropertyType: (path, updatedType, updatedSubType) => {
      return set((state) => {
        const updatedSchema = cloneObj(state.schema),
          { pathToType, pathToSubType, pathToOrder, pathToObjectProperties } =
            getInfoFromPath(path);

        lodashSet(updatedSchema, pathToType, updatedType);
        lodashSet(updatedSchema, pathToSubType, updatedSubType);

        if (updatedType === SchemaKeyType.NESTED) {
          lodashSet(updatedSchema, pathToOrder, []);
          lodashSet(updatedSchema, pathToObjectProperties, {});
        }

        return {
          schema: updatedSchema,
        };
      });
    },

    updatePropertyRequire: (path, isRequired) => {
      return set((state) => {
        const updatedSchema = cloneObj(state.schema),
          { pathToRequired } = getInfoFromPath(path);

        lodashSet(updatedSchema, pathToRequired, isRequired);

        return {
          schema: updatedSchema,
        };
      });
    },

    updateSchema: (schema: TSchema) => {
      return set((state) => {
        return {
          schema,
        };
      });
    },
  }))
);

export { useSchemaStore };
