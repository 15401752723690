import { TableHeader, Text } from '@comet/blocks';
import { format } from '@comet/date';
import { useMemo } from 'react';
import { GetOrgsResponse, OrgMember } from '../Organisation.types';
import { User } from '@comet/components/User';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Column } from 'react-table';
import { Link, useParams } from '@comet/router';
import { useOrgAccessQuery, useOrgQuery } from '../service';
import { capitalize } from 'lodash';
import { usePlanMetadata } from '@comet/hooks/usePlanMetadata';
import { UrlContainer } from '@comet/components/UrlContainer';
import { notification } from 'antd';

export const getDisplayName = (str: string) => {
  return str
    .split('_')
    .map((s) => capitalize(s))
    .join(' ');
};

const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

export const useOrgListingData = (orgsData: GetOrgsResponse | undefined) => {
  const tableData = useMemo(() => {
    if (!orgsData) return [];

    const orgsList = orgsData.data;

    return orgsList.map((item) => {
      const onOrgIdCopy = () => {
        navigator.clipboard.writeText(item.id);
        notification.success({
          message: 'Success',
          description: 'Organisation ID copied successfully',
        });
      };
      return {
        orgId: (
          <UrlContainer width="240px" onClick={onOrgIdCopy}>
            <Text appearance="caption.regular">{item.id}</Text>
          </UrlContainer>
        ),
        name: item.name,
        dateCreated: formatTime(item.createdOn),
        createdBy: <User {...(item.createdBy || {})} />,
        id: item.id,
        action: <FiMoreHorizontal />,
      };
    });
  }, [orgsData]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        Cell: (data) => {
          const currentRow = data.row.original;

          return (
            <Link
              routeId="organisations.organisationId.projects"
              pathParam={{ organisationId: currentRow.id }}
            >
              {currentRow.name}
            </Link>
          );
        },
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Organisation ID" />,
        accessor: 'orgId',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Date created" sortable />,
        accessor: 'dateCreated',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Created by" />,
        accessor: 'createdBy',
        disableSortBy: true,
      },
    ];
  }, []);

  return { tableColumns, tableData };
};

export const useOrgMembersOptions = (organisationId: string) => {
  const { data: orgMembers } = useOrgAccessQuery(organisationId);

  const formattedOrgMembers = useMemo(
    () =>
      (orgMembers?.data || []).map((member: OrgMember) => {
        return {
          label: member.name,
          value: member.id,
          role: member.role,
        };
      }),
    [orgMembers?.data]
  );

  const orgMembersOptions = useMemo(
    () => [
      {
        label: 'Any',
        value: '',
        role: '',
      },
      ...formattedOrgMembers,
    ],
    [formattedOrgMembers]
  );

  return orgMembersOptions;
};

export const usePlanInfo = () => {
  const { organisationId } = useParams();

  const { data: orgData, isLoading: isOrgLoading } =
    useOrgQuery(organisationId);
  const { data: plans } = usePlanMetadata();
  const { data: orgMembers, isLoading: isOrgAccessLoading } = useOrgAccessQuery(
    organisationId,
    {
      user_name_query: null,
    }
  );

  const currentPlan = orgData?.planDetails.planType;

  if (
    !currentPlan ||
    isOrgLoading ||
    !plans ||
    isOrgAccessLoading ||
    !orgMembers
  ) {
    return {
      isLoading: true,
      isUsersLimitReached: false,
    };
  }

  const currentPlanDetails = plans[currentPlan];

  const isUsersLimitReached =
    currentPlanDetails?.resources.numUsers === orgMembers.data.length;

  return {
    isLoading: false,
    isUsersLimitReached,
  };
};
