import { styled } from '@comet/styled';
import { Tag as ArcoTag } from '@comet/blocks';
import { Handle as ReactFlowHandle } from 'reactflow';

export const Wrapper = styled.div<{ width?: number; height?: number }>`
  border: 1px solid #0847a6;
  padding: 8px;
  border-radius: 4px;
  ${({ width }) => (width ? `width: ${width}px` : 'auto')};
  ${({ height }) => (height ? `height: ${height}px` : 'auto')};
`;

export const Tag = styled(ArcoTag)`
  border-radius: 4px;
  padding: 2px;
  min-width: 20px;
  span {
    text-align: center;
  }
`;

const getPx = (p: string | number) => (typeof p === 'number' ? `${p}px` : p);

export const StyledHandle = styled(ReactFlowHandle)<{
  left?: string | number;
  right?: string | number;
  top?: string | number;
  bottom?: string | number;
  color?: string;
}>`
  ${({ left }) => (left ? `left: ${getPx(left)};` : '')}
  ${({ right }) => (right ? `right: ${getPx(right)}; left: auto;` : '')}
  ${({ bottom }) => (bottom ? `bottom: ${getPx(bottom)};` : '')}
  ${({ top }) => (top ? `top: ${getPx(top)};` : '')}
  ${({ color }) => (color ? `background: ${color}` : '')}
`;

export const IconWrapper = styled.div`
  background: #165dff;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
