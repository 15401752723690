import { styled } from '@comet/styled';
import {
  Button,
  ButtonContainer,
  Flex,
  Form,
  FormItem,
  Input,
  Select,
  Text,
} from '@comet/blocks';
import { ModelTypesEnum, RawModel } from '../types/ModelDetails.types';
import { createModel } from '@comet/pages/Model/service';
import { useMutation } from '@comet/query';
import { useModelNameValidator } from '../utils/modelNameValidator';
import { useMemo } from 'react';
import { sendErrorNotification } from 'src/blocks/Notification';

const FooterDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  margin-bottom: 16px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 24px;
`;

interface CreateModelFormProps {
  projectId: string;
  onSuccess: (id: string) => void;
  onCancel: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  initialValues?: RawModel;
  databaseModels?: boolean;
}

export const CreateModelForm = ({
  primaryButtonText = 'Create',
  secondaryButtonText = 'Cancel',
  onSuccess,
  onCancel,
  projectId,
  initialValues,
  databaseModels,
}: CreateModelFormProps) => {
  const createModelMutation = useMutation({
    mutationFn: (modelData: RawModel) => createModel(projectId, modelData),
    onSuccess: ({ id }) => onSuccess(id),
    onError: (e: any) =>
      sendErrorNotification({
        message: 'Error',
        description: e?.response?.data?.message,
      }),
  });

  const handleFinish = (data: RawModel) => {
    createModelMutation.mutate(data);
  };

  const modelNameValidatorRule = useModelNameValidator();

  const modelTypeOptions = useMemo(
    () =>
      databaseModels
        ? [
            {
              value: ModelTypesEnum.DATABASE_COLLECTION,
              label: 'Database Collection',
            },
          ]
        : [
            {
              value: ModelTypesEnum.QUERY_PARAMS,
              label: 'Query Params',
            },
            {
              value: ModelTypesEnum.REQUEST_BODY,
              label: 'Request Body',
            },
          ],
    [databaseModels]
  );

  return (
    <Flex direction="column" gap={16}>
      <Text appearance="heading.card">Create a new model</Text>
      <Form
        autoComplete="off"
        onFinish={handleFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <FormItem
          name="name"
          label="Model Name"
          required
          rules={[
            { required: true, message: 'Model Name is required!' },
            modelNameValidatorRule,
          ]}
        >
          <Input placeholder="Student..." />
        </FormItem>
        <FormItem
          name="description"
          label="Description"
          required
          rules={[
            { required: true, message: 'Model Description is required!' },
          ]}
        >
          <Input.TextArea
            showCount
            maxLength={240}
            placeholder="Add a description to make it easier for your teammates to discover and use the right version of your model..."
          />
        </FormItem>

        <FormItem
          name="modelType"
          label="Model Type"
          initialValue={
            databaseModels
              ? ModelTypesEnum.DATABASE_COLLECTION
              : ModelTypesEnum.QUERY_PARAMS
          }
          required
        >
          <Select style={{ width: 200 }} options={modelTypeOptions} />
        </FormItem>
        <FooterDivider />
        <ButtonContainer>
          <Button onClick={onCancel} appearance="transparent">
            {secondaryButtonText}
          </Button>
          <Button type="primary" htmlType="submit">
            {primaryButtonText}
          </Button>
        </ButtonContainer>
      </Form>
    </Flex>
  );
};
