import { Edge, Node } from 'reactflow';

export enum RequestMethodEnum {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum UrlParamsEnum {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
}

export interface APIParamType {
  [key: string]: {
    type: UrlParamsEnum;
  };
}

export interface CreateApiFormData {
  name: string;
  description?: string;
  requestMethod: RequestMethodEnum;
  endpoint: string;
  // query model id
  queryParams?: string;
  // body model id
  requestBody?: string;
  authenticated: boolean;
}

export interface ExtendedNode extends Node {
  parentId?: string;
}
export interface ApiDetails {
  id: string;
  name: string;
  active: boolean;
  description: string;
  draft: boolean;
  flowType: 'API';
  flowMetadata: {
    authenticated: boolean;
    endpoint: string;
    // query model id
    queryParams?: string;
    // body model id
    requestBody?: string;
    requestMethod: RequestMethodEnum;
  };
  projectId: string;
  updatedBy: {
    name: string;
    picture: string | null;
    id: string;
  };
  updatedOn: number;
  version: string;
  nodes: Node[] | ExtendedNode[];
  edges: Edge[];
  rootNode: string;
}

export type ApiDetailsRaw = Pick<
  ApiDetails,
  'name' | 'description' | 'flowType' | 'flowMetadata'
>;

export interface GetApisResponse {
  data: ApiDetails[];
  page: {
    limit: number;
    total: number;
  };
}

export interface GetApiListParams {
  flow_type: 'API';
  version: string | null;
  draft?: boolean | null;
  sort_by?: string | null;
  sort_order?: string | null;
  limit?: number;
  offset?: number;
  query?: string;
  request_method?: string | null;
  active?: boolean | null;
  updatedBy?: string | null;
}

export interface UrlParam {
  parameter: string;
  paramType: string;
}

export interface GetApiDetailsParams {
  version?: string | null;
  draft?: boolean;
}

export interface GetApiDetailsResponse extends Omit<ApiDetails, 'id'> {
  flowId: string;
}
