import { styled } from '@comet/styled';
import { TbError404 } from 'react-icons/tb';
import { Typography } from '@comet/blocks';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from '@comet/router';

const Background = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const COUNTDOWN_TIME_IN_SECOND = 5;

export const InvalidRoute = () => {
  const { organisationId, projectId } = useParams();
  const [countDown, setCountDown] = useState(COUNTDOWN_TIME_IN_SECOND);
  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown((value) => Math.max(value - 1, 0));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  if (countDown === 0) {
    if (projectId && organisationId) {
      return (
        <Navigate
          routeId="organisations.organisationId.projects.projectId.dashboard"
          pathParam={{ organisationId, projectId }}
        />
      );
    }
    if (organisationId) {
      return (
        <Navigate
          routeId="organisations.organisationId.projects"
          pathParam={{ organisationId }}
        />
      );
    }
    return <Navigate routeId="organisations" />;
  }

  return (
    <Background>
      <Container>
        <TbError404 size={64} />
        <Typography.Title style={{ marginTop: '12px' }} level={3}>
          Hmm, this route does not exist, you should not here.
        </Typography.Title>
        <Typography.Text style={{ textAlign: 'center' }}>
          Don't worry, we escort you to the nearest possible starting point,
          redirecting in {countDown} seconds.
        </Typography.Text>
      </Container>
    </Background>
  );
};
