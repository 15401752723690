import { EnvironmentTypes } from '@comet/pages/Project/Environments/types';
import { FieldType } from '../types';
import { getResourceMetadata } from '@comet/pages/Project/Environments/EnvironmentList/EnvironmentList.util';
import { useGetEnvironmentsQuery } from '@comet/pages/Project/Environments';
import { useMemo } from 'react';
import { useParams } from '@comet/router';

export const useInitialEnvironmentsFields = () => {
  const { projectId } = useParams();
  const { data: environmentsData } = useGetEnvironmentsQuery(projectId);

  const environmentsFields: FieldType[] = useMemo(() => {
    return (environmentsData?.data || []).map((environment) => {
      const metadata = getResourceMetadata(environment);
      return {
        type: {
          value: environment.type,
          disabled: environment.type === EnvironmentTypes.DEVELOPMENT,
        },
        name: {
          value: environment.name,
          disabled: environment.type !== EnvironmentTypes.CUSTOM,
        },
        tier: {
          value: metadata?.tier || null,
          disabled: environment.type === EnvironmentTypes.DEVELOPMENT,
        },
        isShared: {
          value: metadata?.isShared,
          disabled: true,
        },
        sharedEnvName: {
          value: metadata?.sharedEnvName || null,
          disabled: true,
        },
      };
    });
  }, [environmentsData]);

  const initialEnvironmentsFields = useMemo(() => {
    return (environmentsData?.data || []).map((environment) => {
      const metadata = getResourceMetadata(environment);
      return {
        type: environment.type,
        name: environment.name,
        metadata: {
          tier: metadata?.tier || null,
          isShared: metadata?.isShared,
          sharedEnvName: metadata?.sharedEnvName || null,
        },
      };
    });
  }, [environmentsData]);

  return { environmentsFields, initialEnvironmentsFields };
};
