import { useGoBack, useLocation, useNavigate, useParams } from '@comet/router';
import { queryClient, useMutation } from '@comet/query';
import { useCallback, useEffect, useState } from 'react';
import { createSearchIndex } from '../service';
import { Flex, Paper, Form } from '@comet/blocks';
import { Steps, notification } from 'antd';

import {
  SearchIndexRequestData,
  CreateSearchIndexFormData,
  STEPS,
} from '../types';
import { SearchIndexDetails } from './SearchIndex.Details.Form';
import { SearchIndexMappings } from './SearchIndex.Mappings.Form';
import { FormButtons } from './FormButtons';
import { AxiosError } from 'axios';
import { sendErrorNotification } from 'src/blocks/Notification';

export const SearchIndexForm = () => {
  const { organisationId, projectId } = useParams();
  const [selectedModel, setSelectedModel] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [dynamic, setDynamic] = useState(true);

  const navigate = useNavigate();
  const goBack = useGoBack();
  const [form] = Form.useForm();

  const location = useLocation();
  const environmentId = location.state.envId as string;

  useEffect(() => {
    form.setFieldValue('environmentId', environmentId);
  }, [environmentId, form]);

  const createSearchIndexMutation = useMutation({
    mutationFn: (searchIndexData: SearchIndexRequestData) =>
      createSearchIndex(projectId, searchIndexData),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: ['useGetSearchIndexesQuery', projectId],
      });
      notification.success({
        message: 'Success',
        description: 'Search Index created successfully',
      });
      navigate(
        'organisations.organisationId.projects.projectId.search-indexes.searchIndexId',
        { organisationId, projectId, searchIndexId: id }
      );
    },
    onError: (error: AxiosError) => {
      const description =
        error.response?.status === 404
          ? 'For Full Text Search, you need to have a Mongodb Atlas Database Secret present for the environment you are trying to create the Search Index'
          : `Your MongoDB Org doesn't allow API access. Please visit this link to know more`;
      sendErrorNotification({
        message: 'Error',
        description,
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/full-text-search/create-a-search-index',
      });
    },
  });

  const onToggelDynamic = () => {
    setDynamic((dynamic) => !dynamic);
  };

  const handleCreate = (formData: CreateSearchIndexFormData) => {
    const { environmentId, fields } = formData;

    if (!environmentId) {
      // TODO: add a field for environment in the form
      sendErrorNotification({
        message: 'Error',
        description: 'Environment is required!',
      });

      return;
    }

    const data = {
      name,
      modelId: selectedModel,
      environmentId,
      mappings: {
        type: 'search',
        dynamic,
        fields:
          fields?.map((field) => ({
            ...field,
            multi: field.multi?.map((multi) => ({
              ...multi,
              type: field.type,
            })),
          })) || [],
      },
    };

    createSearchIndexMutation.mutate(data);
  };

  const onNext = useCallback(async () => {
    if (!name) {
      setNameError('Search index name is required!');
      return;
    }

    setStep(STEPS.MAPPINGS);
  }, [name]);

  const [step, setStep] = useState<STEPS>(STEPS.DETAILS);
  const onCancel = () => {
    if (step === STEPS.DETAILS) {
      navigate(
        'organisations.organisationId.projects.projectId.search-indexes',
        {
          organisationId,
          projectId,
        }
      );
    }

    if (step === STEPS.MAPPINGS) {
      setStep(STEPS.DETAILS);
    }
  };

  const onSubmit = () => {
    if (step === STEPS.MAPPINGS) {
      const formData: CreateSearchIndexFormData = form.getFieldsValue();
      handleCreate(formData);
      return;
    }

    setStep(STEPS.MAPPINGS);
  };

  return (
    <Flex direction="column">
      <Paper size="medium">
        <Steps
          size="small"
          current={step}
          items={[
            {
              title: 'Add Details',
            },
            {
              title: 'Define mappings',
            },
          ]}
          style={{ marginBottom: 20 }}
        />
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          onFinish={onSubmit}
        >
          <Flex gap={10} direction="column">
            <Flex direction="column" gap={10} hidden={step !== STEPS.DETAILS}>
              <SearchIndexDetails
                environmentId={environmentId}
                name={name}
                setName={setName}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                nameError={nameError}
                setNameError={setNameError}
              />
              <FormButtons
                onCancel={goBack}
                onNext={onNext}
                disabled={!!nameError}
                cancelButtonText="Cancel"
                nextButtonText="Next"
              />
            </Flex>

            <Flex direction="column" gap={10} hidden={step !== STEPS.MAPPINGS}>
              <SearchIndexMappings
                modelId={selectedModel}
                form={form}
                dynamic={dynamic}
                onToggleDynamic={onToggelDynamic}
              />
              <FormButtons
                onCancel={onCancel}
                onNext={onSubmit}
                disabled={!!nameError}
                cancelButtonText="Back"
                nextButtonText="Create"
                isLoading={createSearchIndexMutation.isLoading}
              />
            </Flex>
          </Flex>
        </Form>
      </Paper>
    </Flex>
  );
};
