import { styled } from '@comet/styled';

export const FooterDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  margin-bottom: 16px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 24px;
`;

export const CenterText = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
`;
