import { useFilterFieldValidator } from '@comet/components/DynamicFields/utils';
import { useState } from 'react';
import { Button, Flex, Form, Input, Show } from '@comet/blocks';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';

export const UpdateCustom = ({ data }: { data: Record<string, string> }) => {
  const [_, setNextStepEnabled] = useState(false);
  const { hasManageProjectSettingsAccess } = useProjectPermissions();

  //Todo: refactor useFilterFieldValidator for using it evrywhere
  const fieldValidator = useFilterFieldValidator(
    setNextStepEnabled,
    'secretData',
    (field, value) => (field as any).key === value
  );
  return (
    <div>
      <Form.List
        name="secretData"
        initialValue={Object.entries(data ?? {}).map(([key, value]) => ({
          key,
          value,
        }))}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flex key={field.key} justifyContent="space-between">
                <Form.Item
                  name={[field.name, 'key']}
                  style={{ width: '45%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input key or delete this field.',
                    },
                    fieldValidator,
                  ]}
                >
                  <Input
                    placeholder="Enter key"
                    disabled={!hasManageProjectSettingsAccess}
                  />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'value']}
                  style={{ width: '45%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: 'Please input value or delete this field.',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter value"
                    disabled={!hasManageProjectSettingsAccess}
                  />
                </Form.Item>
                <Flex justifyContent="center">
                  <Button
                    icon={<AiOutlineMinusCircle />}
                    onClick={() => remove(field.name)}
                    disabled={!hasManageProjectSettingsAccess}
                  />
                </Flex>
              </Flex>
            ))}
            <Show if={hasManageProjectSettingsAccess}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={add}
                  style={{ width: '100%' }}
                  disabled={!hasManageProjectSettingsAccess}
                >
                  <AiOutlinePlusCircle /> Add field
                </Button>
              </Form.Item>
            </Show>
          </>
        )}
      </Form.List>
    </div>
  );
};
