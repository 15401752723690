import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';

export const FooterDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  margin-bottom: 16px;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-top: 24px;
`;

export const StyledFieldType = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.lighter};
  padding: 8px;
  border-radius: ${({ theme }) => theme.constants.borderRadius.lg};
`;
