import {
  Button,
  Flex,
  Modal,
  Text,
  Dropdown,
  TableHeader,
  Tooltip,
} from '@comet/blocks';
import { useCallback, useMemo, useState } from 'react';
import { styled } from '@comet/styled';
import { Column } from 'react-table';
import { GetAnalyzersResponse } from '../types';
import { Link, useParams } from '@comet/router';
import { FiMoreHorizontal } from 'react-icons/fi';
import { deleteAnalyzer } from '../service';
import { RiAlertLine } from 'react-icons/ri';
import { format } from 'date-fns';
import { StatusComponent } from '@comet/components/TableComponents';
import { AnalyzerListingStateMapping } from '../constants';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

export const DeleteAnalyzer = ({
  id,
  refetch,
  disabled,
}: {
  id: string;
  disabled: boolean;
  refetch: () => void;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await deleteAnalyzer(projectId, id);
    refetch();
  }, [id, projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip
                  title={
                    disabled
                      ? "This analyzer is used in some Search Index, therefore it can't be deleted"
                      : ''
                  }
                >
                  Delete
                </Tooltip>
              ),
              onClick: toggleModal,
              disabled,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this analyzer?{' '}
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this analyzer.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useAnalyzersListingData = (
  analyzersData: GetAnalyzersResponse | undefined,
  refetch: () => void
) => {
  const { organisationId, projectId } = useParams();

  const tableData = useMemo(() => {
    if (!analyzersData) return [];

    return analyzersData.data.map((item) => {
      const statusText = getStatus(item.inUse);
      const statusColor = AnalyzerListingStateMapping[statusText][1];

      return {
        name: (
          <Link
            routeId="organisations.organisationId.projects.projectId.analyzers.analyzerId"
            pathParam={{ organisationId, projectId, analyzerId: item.id }}
          >
            {item.name}
          </Link>
        ),
        tokenizerType: item.tokenizer.type,
        status: (
          <StatusComponent color={statusColor}>
            {item.inUse ? (
              <Tooltip title={`This analyzer is used in some Search Index.`}>
                In use
              </Tooltip>
            ) : (
              <Tooltip
                title={`This analyzer is not being used in any Search Index.`}
              >
                Not in use
              </Tooltip>
            )}
          </StatusComponent>
        ),
        updatedBy: item.updatedBy.name,
        updatedOn: formatTime(item.updatedOn),
        id: (
          <DeleteAnalyzer
            id={item.id}
            refetch={refetch}
            disabled={item.inUse}
          />
        ),
      };
    });
  }, [analyzersData, organisationId, projectId, refetch]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" />,
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Type" />,
        accessor: 'tokenizerType',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Status" />,
        accessor: 'status',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated by" />,
        accessor: 'updatedBy',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated on" />,
        accessor: 'updatedOn',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
      },
    ];
  }, []);

  return {
    tableData,
    tableColumns,
  };
};

function getStatus(inUse: boolean) {
  if (inUse) return 'TRUE';
  else return 'FALSE';
}

export const mergeFormItemName = (
  parentName?: string | number | Array<string | number>,
  ...args: Array<string | number>
) => {
  let finalName: string | number | Array<string | number> = [];
  if (Array.isArray(parentName)) {
    finalName = [...parentName];
  } else if (parentName !== undefined) {
    finalName = [parentName];
  }
  return [...finalName, ...args];
};
