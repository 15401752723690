import { Button, Flex, Modal, Text } from '@comet/blocks';
import { RiAlertLine } from 'react-icons/ri';
import { styled } from '@comet/styled';
import { useState } from 'react';
import { StyledCrossIcon } from './MemberList.styled';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;
interface DeleteMemberProps {
  type: 'organisation' | 'project';
  onDelete: () => void;
  visible?: boolean;
}

const DeleteMember = ({ onDelete, visible, type }: DeleteMemberProps) => {
  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    if (!visible) return;

    setIsConfirm((isConfirm) => !isConfirm);
  };

  return (
    <>
      <StyledCrossIcon
        visibility={!visible ? 'hidden' : ''}
        onClick={toggleModal}
      />
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this user?{' '}
              </Text>
              <Text appearance="label.short.regular">
                Deleting this user will also remove their access from the {type}{' '}
                and they will not be able to view or modify the {type}.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default DeleteMember;
