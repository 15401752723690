import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { useParams } from '@comet/router';
import { GetEnvironmentTiersResponse } from '../types';

const getEnvironmentTiers = async (projectId: string) => {
  const url = `/resources/${projectId}/environments/tiers`;
  const { data } = await getAxios().get<GetEnvironmentTiersResponse>(url);

  return data;
};

export const useGetEnvironmentTiers = () => {
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['useGetEnvironmentTiers', projectId, 'tiers'],
    queryFn: () => getEnvironmentTiers(projectId),
  });
};
