export const AmplifyAuthConfig = {
  region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
  userPoolWebClientId:
    process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_AMPLIFY_AUTH_COGNITO_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: process.env.REACT_APP_COMET_BASE_URL,
    redirectSignOut: process.env.REACT_APP_COMET_BASE_URL,
    clientId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    responseType: 'code',
  },
};
