import { Model } from '@comet/pages/Model/types';
import { APIParamType } from '../Types';
import { useEffect, useState } from 'react';

export const getParamsFromEndpoint = (endpoint: string): string[] => {
  const paths = endpoint.split('/');
  return paths
    .filter(
      (path) => path.length > 2 && path[0] === '{' && path.slice(-1) === '}'
    )
    .map((pathWithBrackets) => pathWithBrackets.slice(1, -1));
};

export const validateEndPoint = (value: string) => {
  const ENDPOINT_REGEX = /^\/(([\w-]+)(\/(([\w-]+)|(\{[\w-]+\})))*)?$/i;

  if (value && !value?.match(ENDPOINT_REGEX)) {
    return false;
  }

  return true;
};

export const useEndpointValidator = () => {
  const [endpoint, setEndpoint] = useState('');
  const [isValidEndpoint, setValidEndpoint] = useState(true);

  useEffect(() => {
    setValidEndpoint(validateEndPoint(endpoint));
  }, [endpoint]);

  return { endpoint, isValidEndpoint, setEndpoint };
};

export const getUrlParams = (parameter: APIParamType) => {
  const urlParams = [];

  for (const key in parameter) {
    urlParams.push({
      parameter: key,
      paramType: parameter[key].type,
    });
  }

  return urlParams;
};

export const getModelOptions = (models: Model[]) => {
  return models.map((model) => ({
    label: model.name,
    value: model.id,
  }));
};
