import { useFlowEngineStore } from '@comet/components/FlowEngine/FlowEngine.store';
import { useGetEnvironmentsOptions } from '@comet/pages/Project/Environments/utils';
import { GetSecretsDetailsResponse } from '@comet/pages/Project/Secrets/Types';
import {
  getSecret,
  useGetSecretByNameQuery,
  useGetSecretsQuery,
} from '@comet/pages/Project/Secrets/services/Secrets.service';
import { useParams } from '@comet/router';
import { AutoComplete } from 'antd';
import debounce from 'lodash.debounce';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useFlowEngineStore as useFlowEngineStoreV2 } from '../store';
import { createStateValueData } from '../store/flow-engine-store/utils';
import { getModel, useModelQuery } from '@comet/pages/Model/service';

export default function StateValue({
  onChange,
  value,
  placeholder,
}: {
  onChange: (value: any) => void;
  value: any;
  placeholder?: string;
}): ReactElement {
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const { projectId } = useParams();
  const { selectedEnvironment } = useGetEnvironmentsOptions();

  const { editorNode } = useFlowEngineStore();
  const [secretQuery, setSecretQuery] = useState<string | null>(null);
  const [secretSearchQuery, setSecretSearchQuery] = useState<string | null>(
    null
  );

  const onGetSecretsSuccess = useCallback((data: GetSecretsDetailsResponse) => {
    const res: { value: string; label: string }[] = [];
    data.data.forEach((secret) => {
      res.push({ value: `$.secrets.${secret.name}`, label: secret.name });
    });
    setOptions(res);
  }, []);

  const { data: secretsData } = useGetSecretsQuery(
    projectId,
    secretQuery !== null ? selectedEnvironment : undefined,
    secretSearchQuery ?? '',
    'CUSTOM_SECRET',
    onGetSecretsSuccess
  );

  const { data: authSecretDetails } = useGetSecretByNameQuery(
    selectedEnvironment ?? '',
    'AUTHENTICATION_SECRET'
  );

  const getModelData = async (modelId: string) => {
    const params = {
      draft: true,
    };
    // get model data
    const modelData = await getModel(projectId, modelId, params);
    return modelData;
  };

  const onChangeSecretQuery = useCallback((newQuery: string) => {
    setSecretSearchQuery(newQuery);
  }, []);
  const debouncedQueryChange = useMemo(
    () =>
      debounce(onChangeSecretQuery, 500, {
        leading: true,
      }),
    [onChangeSecretQuery]
  );
  if (!editorNode) return <></>;

  const nodeData: { nodes: any; variables: any; secrets: any } =
    useFlowEngineStoreV2.getState().nodeStateValueObjMap.get(editorNode.id);

  const handleSearch = async (value: string) => {
    let res: { value: string; label: string }[] = [];

    /**
     * Stage 1 - If value starts with '$.' then we need to show the variables and nodes
     * Stage 2 - If value starts with '$.variables.' then we need to show the variables
     * Stage 3 - If value starts with '$.nodeName.' then we need to show the return objects
     */
    if (value.startsWith('$.')) {
      const terms = value.split('.');

      // Stage 1 : "$.<anything>"
      if (terms.length === 2) {
        res =
          terms[1] === ''
            ? [
                { value: '$.variables', label: 'variables' },
                { value: '$.secrets', label: 'secrets' },
                { value: '$.tokenData', label: 'tokenData' },
              ]
            : 'variables'.startsWith(terms[1])
            ? [{ value: '$.variables', label: 'variables' }]
            : 'tokenData'.startsWith(terms[1])
            ? [{ value: '$.tokenData', label: 'tokenData' }]
            : 'secrets'.startsWith(terms[1])
            ? [{ value: '$.secrets', label: 'secrets' }]
            : [];

        Object.keys(nodeData.nodes).forEach((node) => {
          if (terms[1] === '' || node.startsWith(terms[1])) {
            res.push({ value: `$.${node}`, label: node });
          }
        });
      }
      // Stage 2 :  "$.variables.<anything>"
      else if (terms.length === 3 && terms[1] === 'variables') {
        Object.keys(nodeData.variables).forEach((variable) => {
          if (terms[2] === '' || variable.startsWith(terms[2])) {
            res.push({ value: `$.variables.${variable}`, label: variable });
          }
        });
      }

      // Stage 3: "$.secrets.<anything>"
      else if (terms.length === 3 && terms[1] === 'secrets') {
        setOptions([]);
        if (terms[2] !== secretQuery) {
          setSecretQuery(terms[2]);
          debouncedQueryChange(terms[2]);
        } else if (secretsData) {
          onGetSecretsSuccess(secretsData);
        }
        return;
      }

      // Stage 4: "$.secrets.<secretName>.<anything>"
      else if (
        terms.length === 4 &&
        terms[1] === 'secrets' &&
        selectedEnvironment
      ) {
        const matchingSecret = secretsData?.data.find((secret) =>
          secret.name.startsWith(terms[2])
        );
        if (matchingSecret?.id) {
          const secretDetails = await getSecret(
            projectId,
            selectedEnvironment,
            matchingSecret.id
          );
          if (secretDetails?.data) {
            Object.keys(secretDetails.data).forEach((secretKey) => {
              if (terms[3] === '' || secretKey.startsWith(terms[3])) {
                res.push({
                  value: `$.secrets.${terms[2]}.${secretKey}`,
                  label: secretKey,
                });
              }
            });
          }
        }
      }
      // stage 6 : "$.tokenName.<anything>"
      else if (
        terms.length == 3 &&
        terms[1] === 'tokenData' &&
        selectedEnvironment
      ) {
        if (authSecretDetails?.data) {
          const authSecretSchemaArray = authSecretDetails.data.tokenData;
          // default raw token option
          res.push({
            value: `$.tokenData.rawToken`,
            label: 'rawToken',
          });
          authSecretSchemaArray.map(
            (secretValue: { key: 'string'; tokenType: 'string' }) => {
              if (terms[2] === '' || secretValue.key.startsWith(terms[2])) {
                res.push({
                  value: `$.tokenData.${secretValue.key}`,
                  label: secretValue.key,
                });
              }
            }
          );
        }
      }

      //Stage 5 : "$.nodeName.<anything>"
      else {
        const nodeName = terms[1];
        const nodeReturnSchema = nodeData.nodes[nodeName];

        //case $.nodeName.<value>
        if (terms.length === 3) {
          nodeReturnSchema.forEach((returnObj: any) => {
            if (terms[2] === '' || returnObj.key.startsWith(terms[2])) {
              res.push({
                value: `$.${nodeName}.${returnObj.key}`,
                label: returnObj.displayName,
              });
            }
          });
        }
        // case $.nodeName.<returnObj>.<value>
        else if (terms.length === 4) {
          setOptions([]);

          //get object from array where key is equal to terms[2]  e.g. key = "queryParams"
          const returnObj = nodeReturnSchema.find(
            (returnObj: any) => returnObj.key === terms[2]
          );

          // this should only work if any field have schema specified or have model id assigned to it
          if (returnObj.type === 'MODEL_ID') {
            if (returnObj.modelId) {
              const modelData = await getModelData(returnObj.modelId);

              modelData?.fields?.forEach((field: any) => {
                if (terms[3] === '' || field.name.startsWith(terms[3])) {
                  res.push({
                    value: `$.${nodeName}.${returnObj.key}.${field.name}`,
                    label: field.name,
                  });
                }
              });
            }
          }
        }
      }
    }
    setOptions(res);
  };

  return (
    <AutoComplete
      style={{ width: '100%' }}
      value={value}
      onSearch={handleSearch}
      onChange={(value) => onChange(value)}
      onBlur={createStateValueData}
      placeholder={placeholder || 'Enter value'}
      options={options}
    />
  );
}
