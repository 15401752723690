import { Edge, ReactFlowProvider } from 'reactflow';

import App from './App';
import { ExtendedNode } from '@comet/pages/Api/Types';

interface FlowEngineProps {
  nodes: ExtendedNode[];
  edges: Edge[];
  hasEditAccess: boolean;
  flowId: string;
  rootNode: string;
}

export default function FlowEngineV2(props: FlowEngineProps) {
  return (
    <ReactFlowProvider>
      <App {...props} />
    </ReactFlowProvider>
  );
}
