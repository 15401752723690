import { NodeProps, Node, Dimensions } from 'reactflow';
import { NodeAction } from '../services/Operations';

import AddNode, { AddNodeDimension } from './LogicNodes/AddNode';
import IfNode, { IfNodeDimension } from './LogicNodes/IfNode';
import SwitchNode, { SwitchNodeDimension } from './LogicNodes/SwitchNode';
import GroupNode, { GroupNodeDimension } from './LogicNodes/GroupNode';
import ConcurrentNode, {
  ConcurrentNodeDimension,
} from './LogicNodes/ConcurrentNode';
import LoopNode, { LoopNodeDimension } from './LogicNodes/LoopNode';
import BaseNode, { BaseNodeDimension } from './LogicNodes/BaseNode/BaseNode';
import { BusinessNodeProps, Schema } from './BusinessNode';

export enum NodeTypeEnum {
  AddNode = 'ADD_NODE',
  IfNode = 'IF_NODE',
  SwitchNode = 'SWITCH_NODE',
  GroupNode = 'GROUP_NODE',
  ConcurrentNode = 'CONCURRENT_NODE',
  LoopNode = 'LOOP_NODE',
  BaseNode = 'BASE_NODE',
  ExecutorNode = 'EXECUTOR_NODE',
}

export interface NodeRaw {
  type: NodeTypeEnum;
  data: {
    displayName: string;
    schema: Schema;
    type?: BusinessNodeProps['type'];
    values: BusinessNodeProps['values'];
    metadata?: any;
    nodeName?: string;
  };
}

export const nodeTypes = {
  [NodeTypeEnum.AddNode]: AddNode,
  [NodeTypeEnum.IfNode]: IfNode,
  [NodeTypeEnum.SwitchNode]: SwitchNode,
  [NodeTypeEnum.GroupNode]: GroupNode,
  [NodeTypeEnum.ConcurrentNode]: ConcurrentNode,
  [NodeTypeEnum.LoopNode]: LoopNode,
  [NodeTypeEnum.BaseNode]: BaseNode,
  [NodeTypeEnum.ExecutorNode]: LoopNode,
};

export interface AdditionalNodeProps extends BusinessNodeProps {
  nodeOperations: (
    currentNode: Node,
    newNodeType: NodeRaw,
    actions: NodeAction,
    extraData?: Record<string, any>
  ) => void;
  preview?: boolean;
  metadata: any;
}
export type CometNodeBaseProps<T = object> = NodeProps<AdditionalNodeProps & T>;

export const dimensions: Record<NodeTypeEnum, Dimensions> = {
  [NodeTypeEnum.AddNode]: AddNodeDimension,
  [NodeTypeEnum.IfNode]: IfNodeDimension,
  [NodeTypeEnum.SwitchNode]: SwitchNodeDimension,
  [NodeTypeEnum.GroupNode]: GroupNodeDimension,
  [NodeTypeEnum.ConcurrentNode]: ConcurrentNodeDimension,
  [NodeTypeEnum.LoopNode]: LoopNodeDimension,
  [NodeTypeEnum.BaseNode]: BaseNodeDimension,
  [NodeTypeEnum.ExecutorNode]: { height: 50, width: 300 },
};

export const getWidth = (nodeType: NodeTypeEnum) =>
  dimensions[nodeType]?.width ?? 100;
export const getHeight = (nodeType: NodeTypeEnum) => {
  return dimensions[nodeType]?.height ?? 100;
};

export { AddNode, IfNode };
