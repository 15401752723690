import { Button, Form, FormItem, Input, Select, TextArea } from '@comet/blocks';
import { FormButtonContainer } from './Release.Styled';
import {
  formatReleaseOptionData,
  useGetEnvironmentsOptions,
} from '../../Environments/utils';
import { BiChevronDown } from 'react-icons/bi';
import { format } from 'path';
import { useGetEnvironmentsQuery } from '../../Environments';
import { useParams } from '@comet/router';
interface ReleaseDescriptionProps {
  onBack: () => void;
  onDeploy: (data: {
    version: string;
    description: string;
    // environmentIds: string[];
  }) => void;
  isCreatingDeployment: boolean;
}

export const ReleaseDescription = ({
  onBack,
  onDeploy,
  isCreatingDeployment,
}: ReleaseDescriptionProps) => {
  const handleFinish = (formData: {
    version: string;
    description: string;
    environmentId: string;
  }) => {
    onDeploy(formData);
  };
  const [form] = Form.useForm();
  const { projectId } = useParams();

  const { data } = useGetEnvironmentsQuery(projectId);
  const releaseOption = formatReleaseOptionData(data?.data);

  return (
    <Form
      autoComplete="off"
      onFinish={handleFinish}
      layout="vertical"
      form={form}
    >
      <FormItem
        name="environmentId"
        label="Environment"
        required
        rules={[{ required: true, message: 'Environments is required!' }]}
      >
        <Select
          options={releaseOption.data}
          placeholder="Select environments"
          style={{ width: '200px' }}
          suffixIcon={<BiChevronDown />}
          showArrow
        />
      </FormItem>

      <FormItem
        name="version"
        label="Version"
        required
        rules={[{ required: true, message: 'Version is required!' }]}
      >
        <Input placeholder="v.x.y.z" />
      </FormItem>
      <FormItem
        name="description"
        label="Description"
        required
        rules={[{ required: true, message: 'Description is required!' }]}
      >
        <TextArea
          showCount
          maxLength={240}
          placeholder="Describe the features being deployed in this version."
          style={{ resize: 'none', height: 76 }}
        />
      </FormItem>
      <FormButtonContainer>
        <FormItem marginBottom={'0px'}>
          <Button onClick={onBack} appearance="transparent" htmlType="button">
            Back
          </Button>
        </FormItem>
        <FormItem marginBottom={'0px'}>
          <Button
            appearance="primary"
            htmlType="submit"
            loading={isCreatingDeployment}
          >
            Deploy
          </Button>
        </FormItem>
      </FormButtonContainer>
    </Form>
  );
};
