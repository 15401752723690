import { getAxios } from '@comet/axios';
import { IGetLogsResponse, ILogsQueryPropsType } from '../types';
import { useQuery } from '@tanstack/react-query';

export const useLogsQuery = (
  projectId: string,
  params: ILogsQueryPropsType,
  enabled: boolean,
  onSuccess: (logs: IGetLogsResponse) => void
) => {
  return useQuery({
    queryKey: ['useLogsQuery', projectId, params],
    queryFn: async () => {
      return getLogs(projectId, params);
    },
    cacheTime: 0,
    enabled,
    onSuccess,
  });
};

export const getLogs = (projectId: string, params: ILogsQueryPropsType) => {
  const url = `/resources/${projectId}/logs`;
  return getAxios()
    .get<IGetLogsResponse>(url, { params })
    .then(({ data }) => data);
};
