import { Flex, FormItem, Label, Select, Show, Text } from '@comet/blocks';
import { EditableText } from '@comet/components/Editable/Text';
import { useState } from 'react';
import { DetailsTag } from '@comet/components/common';
import { Database } from '../types';
import { UrlContainer } from '@comet/components/UrlContainer';
import { notification } from 'antd';

export const UpdateDatabaseInstancesForm = (props: Database) => {
  const [type, setType] = useState(props.type);
  const [typeOptions, setTypeOptions] = useState([
    {
      label: 'Shared',
      value: 'SHARED',
    },
    {
      label: 'Dedicated',
      value: 'DEDICATED',
    },
  ]);
  const onUriCopy = () => {
    navigator.clipboard.writeText(props.connectionString);
    notification.success({
      message: 'Success',
      description: 'Uri copied successfully',
    });
  };
  return (
    <>
      <Flex direction="column" gap={16}>
        <Flex direction="column" gap={10}>
          <Label required={true}>Database instance name</Label>
          <EditableText
            name="name"
            type="text"
            value={props.name}
            disabled={true}
          />
        </Flex>
        <Flex direction="column" gap={10}>
          <Label required={true}>Connection string</Label>
          <UrlContainer onClick={onUriCopy}>
            <Text appearance="caption.regular">{props.connectionString}</Text>
          </UrlContainer>
        </Flex>
        <Flex direction="column" gap={10}>
          <Label required={true}>Username</Label>
          <EditableText
            name="username"
            type="text"
            value={props.username}
            disabled={true}
          />
        </Flex>
        <Flex direction="column" gap={10}>
          <Label required={true}>Password</Label>
          <EditableText
            name="password"
            type="text"
            value={props.password}
            disabled={true}
          />
        </Flex>
        <FormItem
          name="type"
          label="Share resources with"
          required
          initialValue={props.type}
          rules={[
            { required: true, message: 'Resource sharing type is required' },
          ]}
        >
          <Select
            style={{ width: 200 }}
            placeholder="select type"
            disabled={true}
            options={typeOptions}
          />
        </FormItem>
      </Flex>
    </>
  );
};
