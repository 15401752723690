import { styled } from '@comet/styled';
import StarsImg from '@comet/assets/svg/stars.svg';
import ellipseImg1 from '@comet/assets/svg/ellipse1.svg';
import ellipseImg2 from '@comet/assets/svg/ellipse2.svg';
import ellipseImg3 from '@comet/assets/svg/ellipse3.svg';
import ellipseImg4 from '@comet/assets/svg/ellipse4.svg';
import ellipseImg5 from '@comet/assets/svg/ellipse5.svg';
import { Footer } from '../Footer/Footer';
import { Flex, Text } from '@comet/blocks';

const Container = styled.div`
  background: linear-gradient(38.66deg, #171f55 0%, #474b67 124.05%);
  display: none;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => `@media (min-width: ${theme.devices.tablet}){
    display: flex
  }`}
`;

const ImageContainer = styled(Flex)`
  position: relative;
  height: 48px;
  width: 180px;
`;

const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
  margin: 0;
  color: ${({ theme }) => theme.palette.neutral.white};
`;

const SubTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  margin: 0;
  color: ${({ theme }) => theme.palette.neutral.lighter};
`;

const StyledImg = styled.img<{
  stackPosition: number;
}>`
  width: 48px;
  height: 48px;
  position: absolute;
  left: ${({ stackPosition }) => `${(stackPosition - 1) * 30}px`};
  z-index: ${({ stackPosition }) => stackPosition};
`;

export const TryProductBanner = () => {
  return (
    <Container>
      <Flex padding={96} direction="column">
        <Flex direction="column" gap={32} width={528}>
          <Flex>
            <img src={StarsImg} alt="stars" />
          </Flex>
          <Flex direction="column" gap={10}>
            <Title>Start turning your ideas into reality.</Title>
            <SubTitle>
              Create a free account. No credit card needed. Get started in 2
              minutes.
            </SubTitle>
          </Flex>
          <Flex gap={16} alignItems="center">
            <ImageContainer>
              <StyledImg src={ellipseImg1} alt="userImage1" stackPosition={1} />
              <StyledImg src={ellipseImg2} alt="userImage2" stackPosition={2} />
              <StyledImg src={ellipseImg3} alt="userImage3" stackPosition={3} />
              <StyledImg src={ellipseImg4} alt="userImage4" stackPosition={4} />
              <StyledImg src={ellipseImg5} alt="userImage5" stackPosition={5} />
            </ImageContainer>
          </Flex>
        </Flex>
        <Footer />
      </Flex>
    </Container>
  );
};
