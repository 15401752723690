import { Flex, Label, Text } from '@comet/blocks';
import { EditableText } from '@comet/components/Editable/Text';
import { useState } from 'react';
import { EditableTextArea } from '@comet/components/Editable/TextArea';
import { DetailsTag } from '@comet/components/common';

interface ModelDetailsPageProps {
  name: string;
  description: string;
  modelType: string;
  hasEditAccess: boolean;
}

export const UpdateModelForm = (props: ModelDetailsPageProps) => {
  const [name, setName] = useState(props.name || '');
  const [description, setDescription] = useState(props.description || '');

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={10}>
        <Label required={true}>Model name</Label>
        <EditableText
          name="name"
          type="text"
          {...{ value: name, onSave: setName }}
          disabled={true}
        />
      </Flex>
      <Flex direction="column" gap={10}>
        <Label>Description</Label>
        <EditableTextArea
          name="description"
          {...{ value: description, onSave: setDescription }}
          disabled={!props.hasEditAccess}
        />
      </Flex>
      <Flex direction="column" gap={10}>
        <Label>Model Type</Label>
        <Flex>
          <DetailsTag>
            <Text appearance="caption.allCaps">{props.modelType}</Text>
          </DetailsTag>
        </Flex>
      </Flex>
    </Flex>
  );
};
