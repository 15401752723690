import { Flex } from '@comet/blocks';
import { styled, css } from '@comet/styled';

export const getPillColorStyles = (title: string) => {
  switch (title) {
    case 'GET':
    case 'REQUEST_BODY':
      return css`
        color: #5b533a;
        background-color: #f8f5ec;
      `;
    case 'POST':
    case 'QUERY_PARAMS':
      return css`
        color: #425e37;
        background-color: #f1f9ee;
      `;
    case 'PUT':
    case 'DATABASE_COLLECTION':
      return css`
        color: #326060;
        background-color: #ecf8f8;
      `;
    case 'DELETE':
      return css`
        color: #80535e;
        background-color: #fcf4f6;
      `;
    case 'PATCH':
      return css`
        color: #5d6687;
        background-color: #f3f5fc;
      `;
    case 'FUNCTION':
      return css`
        color: #425e37;
        background-color: #f1f9ee;
      `;
    default:
      return css``;
  }
};

export const RequestMethodComponent = styled(Flex)<{
  requestMethod: string;
}>`
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: max-content;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  border-radius: ${(props) => props.theme.constants.borderRadius.md};
  height: 20px;
  ${({ requestMethod }) => getPillColorStyles(requestMethod)}
`;

export const FlowTypeComponent = styled(Flex)<{
  type: string;
}>`
  justify-content: center;
  align-items: center;
  font-weight: 700;
  width: max-content;
  font-size: 12px;
  line-height: 16px;
  padding: 2px 4px;
  margin-left: 10px;
  border-radius: ${(props) => props.theme.constants.borderRadius.md};
  height: 20px;
  ${({ type }) => getPillColorStyles(type)}
`;
