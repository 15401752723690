import { Flex, FormItem, Label, Select, Show, Text, Spin } from '@comet/blocks';
import { EditableText } from '@comet/components/Editable/Text';
import { useEffect, useState } from 'react';
import { EnvironmentTiersOptions, ResourceTypesOptions } from '../constants';
import { EnvironmentTypesEnum, GetEnvironmentMetadata } from '../types';
import {
  useGetEnvironmentsOptions,
  useGetTiersOptions,
  useGetTiersPricing,
} from '../utils';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { DetailsTag } from '@comet/components/common';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { FormInstance } from 'antd';
import { Pricing } from '@comet/pages/UpgradePlan/AddBulkEnvironment/AddBulkEnvironment.Pricing';

interface EnvironmentDetailsProps {
  name: string;
  type: string;
  metadata: GetEnvironmentMetadata;
  form: FormInstance;
}

enum CurrencyTypes {
  INR = 'INR',
  USD = 'USD',
}

export const UpdateEnvironmentForm = ({
  name: envName,
  type: envType,
  metadata,
  form,
}: EnvironmentDetailsProps) => {
  const tiersPricing = useGetTiersPricing();
  const { isShared: isEnvShared, sharedEnvId, tier } = metadata;
  const [currency, setCurrency] = useState<CurrencyTypes>(CurrencyTypes.INR);
  const [calculatedPrice, setCalculatedPrice] = useState<number>(0);

  useEffect(() => {
    if (tiersPricing['SHARED_0'] && tier) {
      setCalculatedPrice(tiersPricing[tier][currency]);
    }
  }, [tiersPricing]);

  const [type, setType] = useState(envType || '');
  const [isShared, setIsShared] = useState(isEnvShared);

  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const TierOptions = useGetTiersOptions();

  const { environmentsOptions } = useGetEnvironmentsOptions();

  useEffect(() => {
    form.setFieldValue(['metadata', 'tier'], metadata.tier);
    form.setFieldValue(['metadata', 'isShared'], metadata.isShared);
    form.setFieldValue(['metadata', 'sharedEnvId'], metadata.sharedEnvId);
  }, [form, metadata]);

  if (!hasManageProjectSettingsAccess) {
    return <ErrorPage />;
  }

  if (!tiersPricing && tier) {
    return <Spin></Spin>;
  }

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={10}>
        <Label required={true}>Environment name</Label>
        <Text appearance="label.short.regular">{envName}</Text>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Environment type</Label>
        <Flex hidden={true}>
          <EditableText
            name="type"
            type="text"
            {...{ value: type, onSave: setType }}
            disabled={true}
          />
        </Flex>
        <Flex>
          <DetailsTag>
            <Text appearance="caption.allCaps">{type}</Text>
          </DetailsTag>
        </Flex>
      </Flex>

      <FormItem
        name={['metadata', 'isShared']}
        label="Resource Type"
        required
        initialValue={isEnvShared}
        marginBottom={0}
        rules={[{ required: true, message: 'Resource type is required' }]}
      >
        <Select
          disabled={
            envType !== EnvironmentTypesEnum.CUSTOM ||
            !hasManageProjectSettingsAccess ||
            true
          }
          onChange={(e) => setIsShared(!isShared)}
          style={{ width: 200 }}
          value={isShared}
          options={ResourceTypesOptions}
        />
      </FormItem>

      <Show
        if={
          isShared &&
          envType !== EnvironmentTypesEnum.PRODUCTION &&
          envType !== EnvironmentTypesEnum.DEVELOPMENT
        }
      >
        <FormItem
          name={['metadata', 'sharedEnvId']}
          label="Share resources with"
          required
          initialValue={sharedEnvId}
          rules={[
            { required: true, message: 'Resource sharing type is required' },
          ]}
        >
          <Select
            style={{ width: 200 }}
            placeholder="share with"
            disabled={!hasManageProjectSettingsAccess}
            options={environmentsOptions}
          />
        </FormItem>
      </Show>
      <Show if={!isShared}>
        <FormItem
          name={['metadata', 'tier']}
          label="Select Tier"
          required
          initialValue={tier}
          rules={[{ required: true, message: 'Tier is required' }]}
        >
          <Select
            style={{ width: 200 }}
            options={TierOptions}
            onChange={(e) => setCalculatedPrice(tiersPricing[e][currency])}
            disabled={
              !hasManageProjectSettingsAccess ||
              envType === EnvironmentTypesEnum.DEVELOPMENT
            }
          />
        </FormItem>
        <Label>
          <Pricing
            currency={currency}
            setCurrency={setCurrency}
            totalPrice={calculatedPrice}
          />
        </Label>
      </Show>
    </Flex>
  );
};
