import { getAxios } from '@comet/axios';
import { useQuery } from '@tanstack/react-query';
import {
  Database,
  DatabaseRawModel,
  GetDatabasesParams,
  GetDatabasesResponse,
} from '../types';
import { useParams } from '@comet/router';

export const useGetdatabasesQuery = (
  projectId: string,
  environmentId: string | undefined,
  params?: GetDatabasesParams
) => {
  return useQuery({
    queryKey: ['useGetDatabasesQuery', projectId, environmentId],
    queryFn: async () => {
      const data = await getDatabases(projectId, environmentId, params);
      return data;
    },
    enabled: !!environmentId,
  });
};

export const getDatabases = async (
  projectId: string,
  environmentId: string | undefined,
  params?: GetDatabasesParams
) => {
  const url = `resources/${projectId}/environments/${environmentId}/database-instances`;
  const { data } = await getAxios().get<GetDatabasesResponse>(url, {
    params,
  });
  return data;
};

export const createDatabaseInstance = (
  projectId: string,
  environmentId: string,
  data: DatabaseRawModel
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/database-instances`;

  return getAxios()
    .post<{ id: string }>(url, data)
    .then(({ data }) => data);
};

export const deleteDatabaseInstance = (
  projectId: string,
  environmentId: string,
  databaseId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/databases/${databaseId}`;
  return getAxios().delete(url);
};

export const updateDatabaseInstance = (
  projectId: string,
  environmentId: string,
  instanceId: string,
  type: 'SHARED' | 'DEDICATED'
) => {
  const reqBody = {
    type: type,
    status: 'DELETED',
  };

  const url = `/resources/${projectId}/environments/${environmentId}/database-instances/${instanceId}`;
  return getAxios().patch(url, reqBody);
};

export const useDbInstanceExistsQuery = (
  name: string,
  environmentId: string
) => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/environments/${environmentId}/database-instances/exists`;

  return useQuery({
    enabled: false,
    queryKey: ['useDatabaseExistsQuery', name, projectId],
    queryFn: async () => {
      const { data } = await getAxios().get(url, {
        params: { name },
      });
      return data;
    },
  });
};

export const useGetDatabaseQuery = (
  projectId: string,
  environmentId: string,
  databaseId: string
) => {
  return useQuery({
    queryKey: ['useGetDatabaseQuery', projectId],
    queryFn: async () => {
      return getDatabase(projectId, environmentId, databaseId);
    },
  });
};

export const getDatabase = (
  projectId: string,
  environmentId: string,
  databaseId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/database-instances/${databaseId}`;

  return getAxios()
    .get<Database>(url)
    .then(({ data }) => data);
};
