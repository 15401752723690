import { Paper } from '@comet/blocks';
import { CreateEnvironmentForm } from './CreateEnvironment.Form';
import { useGoBack, useNavigate, useParams } from '@comet/router';
import { useGetEnvironmentsQuery } from '../service';
import { queryClient } from '@comet/query';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';

export const CreateEnvironment = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const goBack = useGoBack();

  const onSuccess = () => {
    queryClient.refetchQueries({
      queryKey: ['useGetEnvironmentsQuery', projectId],
    });
    navigate('organisations.organisationId.projects.projectId.environments');
  };
  const { hasManageProjectSettingsAccess } = useProjectPermissions();

  if (!hasManageProjectSettingsAccess) {
    return <ErrorPage />;
  }

  return (
    <Paper size="medium">
      <CreateEnvironmentForm
        onCancel={goBack}
        onSuccess={onSuccess}
        projectId={projectId}
      />
    </Paper>
  );
};
