import { BsCode, BsPlayFill, BsQuestionLg, BsStopFill } from 'react-icons/bs';
import {
  TbArrowIteration,
  TbArrowsSplit,
  TbVariable,
  TbWorld,
} from 'react-icons/tb';
import { css, styled } from '@comet/styled';

import { BiCodeCurly } from 'react-icons/bi';
import { BusinessNodeProps } from '.';
import { IoMdTime } from 'react-icons/io';
import { RiDatabase2Fill } from 'react-icons/ri';
import { TiFlowParallel } from 'react-icons/ti';

const getIconStyles = (type: BusinessNodeProps['type']) => {
  switch (type) {
    case 'HTTP_TRIGGER':
    case 'HTTP_RESPONSE':
    case 'FUNCTION_RESPONSE': {
      return css`
        background-color: ${({ theme }) => theme.palette.accent.green.light};
        color: ${({ theme }) => theme.palette.accent.green.dark};
        border-radius: 4px;
      `;
    }
    case 'ACTION': {
      return css`
        background-color: ${({ theme }) => theme.palette.accent.yellow.light};
        color: ${({ theme }) => theme.palette.accent.yellow.lighter};
        border-radius: 4px;
      `;
    }
    case 'DB':
    case 'CUSTOM_QUERY_RECORDS':
    case 'DELETE_MULTIPLE_RECORDS':
    case 'DELETE_RECORD':
    case 'FETCH_RECORD':
    case 'INSERT_MULTIPLE_RECORDS':
    case 'INSERT_RECORD':
    case 'LIST_RECORDS':
    case 'UPDATE_MULTIPLE_RECORDS':
    case 'UPDATE_RECORD':
    case 'UPDATE_ONE_RECORD': {
      return css`
        background-color: ${({ theme }) => theme.palette.accent.red.light};
        color: ${({ theme }) => theme.palette.accent.red.dark};
        border-radius: 4px;
      `;
    }
    case 'START': {
      return css`
        background-color: ${({ theme }) => theme.palette.success.lightest};
        color: ${({ theme }) => theme.palette.success.dark};
        border-radius: 50%;
      `;
    }
    case 'STOP': {
      return css`
        background-color: ${({ theme }) => theme.palette.accent.red.lightest};
        color: ${({ theme }) => theme.palette.accent.red.darker};
        border-radius: 50%;
      `;
    }
    default: {
      return css`
        background-color: ${({ theme }) => theme.palette.primary};
        color: ${({ theme }) => theme.palette.neutral.lightest};
        border-radius: 4px;
      `;
    }
  }
};

const IconContainer = styled.div<{
  type: BusinessNodeProps['type'];
}>`
  ${({ type }) => getIconStyles(type)};
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  inset: 0;
  top: -12px;
`;

export const getIcon = (type: BusinessNodeProps['type']) => {
  switch (type) {
    case 'HTTP_TRIGGER': {
      return BiCodeCurly;
    }
    case 'ACTION': {
      return BsCode;
    }
    case 'DB':
    case 'CUSTOM_QUERY_RECORDS':
    case 'DELETE_MULTIPLE_RECORDS':
    case 'DELETE_RECORD':
    case 'FETCH_RECORD':
    case 'INSERT_MULTIPLE_RECORDS':
    case 'INSERT_RECORD':
    case 'LIST_RECORDS':
    case 'UPDATE_MULTIPLE_RECORDS':
    case 'UPDATE_RECORD':
    case 'UPDATE_ONE_RECORD':
    case 'EXISTS_NODE': {
      return RiDatabase2Fill;
    }
    case 'START': {
      return BsPlayFill;
    }
    case 'STOP':
    case 'FUNCTION_RESPONSE':
    case 'HTTP_RESPONSE': {
      return BsStopFill;
    }

    case 'API_CALL': {
      return TbWorld;
    }

    case 'IF_ELSE':
    case 'IF_ELSE_V2': {
      return TbArrowsSplit;
    }

    case 'WHILE_LOOP':
    case 'FOR_LOOP': {
      return TbArrowIteration;
    }

    case 'ADD_VARIABLE':
    case 'APPEND_TO_STRING':
    case 'APPEND_TO_ARRAY':
    case 'BUILD_JSON':
    case 'BUILD_MAP':
    case 'COMPLEX_MATHS_EXPR':
    case 'CONCAT_STRINGS':
    case 'CONTAINS':
    case 'DECREMENT_VARIABLE':
    case 'DIVIDE_VARIABLE':
    case 'EMPTY_ARRAY':
    case 'INCREMENT_VARIABLE':
    case 'LENGTH_ARRAY':
    case 'MERGE_JSON':
    case 'MULTIPLY_VARIABLE':
    case 'REVERSE_ARRAY':
    case 'SET_VARIABLE':
    case 'SLICE_STRING':
    case 'SUBTRACT_VARIABLE':
    case 'TO_LOWER':
    case 'TO_UPPER':
    case 'ARRAY_ITEM_AT':
    case 'TRIM_STRING':
    case 'VALIDATE_MODEL':
    case 'ARRAY_EXTEND': {
      return TbVariable;
    }

    case 'CURRENT_DATETIME': {
      return IoMdTime;
    }

    case 'SWITCH_CASE': {
      return TiFlowParallel;
    }

    // TODO: Later PR
    // case 'POST_FILE':
    // case 'GET_FILE': {
    //   return TbArchive;
    // }

    default: {
      return BsQuestionLg;
    }
  }
};

export const BusinessNodeIcon = ({ type }: BusinessNodeProps) => {
  const Icon = getIcon(type);

  return (
    <IconWrapper>
      <IconContainer type={type}>
        <Icon />
      </IconContainer>
    </IconWrapper>
  );
};
