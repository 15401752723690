import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { useParams } from '@comet/router';

interface GetUserPermissionResponse {
  role: {
    name: string;
    value: number;
  };
}

export const useGetOrgPermissionQuery = () => {
  const { organisationId } = useParams();
  const url = `organisations/${organisationId}/access/me`;

  return useQuery({
    queryKey: ['useGetOrgPermissionQuery', organisationId],
    queryFn: async () => {
      const { data } = await getAxios().get<GetUserPermissionResponse>(url);

      return data;
    },
  });
};
