import { useGetOrgPermissionQuery } from '@comet/pages/Organisation/service/UserPermission.service';
import { OrgPermissions } from '@comet/pages/Organisation/types/UserPermission.types';
import { useMemo } from 'react';

export const useOrgPermissions = () => {
  const { data: userPermissionData } = useGetOrgPermissionQuery();

  const hasViewProjectAccess =
    !!userPermissionData &&
    (userPermissionData.role.value & OrgPermissions.VIEW_PROJECTS) >= 1;
  const hasEditProjectAccess =
    !!userPermissionData &&
    (userPermissionData.role.value & OrgPermissions.EDIT_PROJECTS) >= 1;
  const hasViewBillingsAccess =
    !!userPermissionData &&
    (userPermissionData.role.value & OrgPermissions.BILLING) >= 1;
  const hasManageOrgUserPermissionsAccess =
    !!userPermissionData &&
    (userPermissionData.role.value & OrgPermissions.MANAGE_USER_PERMISSIONS) >=
      1;
  const hasManageOrgSettingsAccess =
    !!userPermissionData &&
    (userPermissionData.role.value &
      OrgPermissions.MANAGE_ORGANISATION_SETTINGS) >=
      1;

  return useMemo(() => {
    return {
      hasEditProjectAccess,
      hasManageOrgSettingsAccess,
      hasManageOrgUserPermissionsAccess,
      hasViewBillingsAccess,
      hasViewProjectAccess,
    };
  }, [
    hasEditProjectAccess,
    hasManageOrgSettingsAccess,
    hasManageOrgUserPermissionsAccess,
    hasViewBillingsAccess,
    hasViewProjectAccess,
  ]);
};
