import { useParams } from '@comet/router';
import { useCallback, useEffect, useState } from 'react';
import { updateAnalyzer, useGetAnalyzerQuery } from './service';
import {
  Button,
  Center,
  Flex,
  FullCenter,
  Label,
  Paper,
  Spin,
  Form,
  PageHeader,
  Show,
  Text,
} from '@comet/blocks';
import { styled } from '@comet/styled';
import { Result, notification } from 'antd';
import { EditableText } from '@comet/components/Editable/Text';
import {
  DynamicCharacterFilterInputMapping,
  DynamicTokenFilterInputMapping,
  DynamicTokenizerInputMapping,
} from './CustomTokenizerInput';
import {
  AnalyzerRequestData,
  AnalyzerTypes,
  CharFiltersBody,
  CreateAnalyzerFormData,
  DynamicFieldsType,
  TokenFilterFormData,
  TokenFilterInputFieldType,
  TokenFilterInputTpe,
  TokenFilterTypes,
} from './types';
import { queryClient, useMutation } from '@comet/query';
import { sendErrorNotification } from 'src/blocks/Notification';

const StyledAnalyzerType = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.lighter};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.constants.borderRadius.lg};
`;

export const AnalyzerDetails = () => {
  const { projectId, analyzerId } = useParams();
  const [form] = Form.useForm();

  const {
    data: analyzerData,
    isLoading: isAnalyzerLoading,
    error,
    refetch: refetchAnalyzer,
  } = useGetAnalyzerQuery(analyzerId);

  const updateModelMutation = useMutation({
    mutationFn: (analyzerData: AnalyzerRequestData) =>
      updateAnalyzer(projectId, analyzerId, analyzerData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useGetAnalyzerQuery', projectId, analyzerId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetAnalyzersQuery', projectId],
      });

      notification.success({
        message: 'Success',
        description: 'Model updated successfully',
      });
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'There was an error in updating the model.',
      });
    },
  });

  const [name, setName] = useState(analyzerData?.name || '');
  const [type, setType] = useState(analyzerData?.tokenizer.type || '');
  const [dynamicFields, setDynamicFields] = useState<DynamicFieldsType>({
    minGram: 0,
    maxGram: 0,
    pattern: '',
    group: 0,
    maxTokenLength: 0,
  });

  const updateDynamicField = useCallback(
    (name: string, value: string | number) => {
      setDynamicFields((fields) => ({
        ...fields,
        [name]: value,
      }));
    },
    []
  );

  const resetDynamicFields = useCallback(() => {
    if (analyzerData?.tokenizer.minGram) {
      updateDynamicField('minGram', analyzerData?.tokenizer.minGram);
    }
    if (analyzerData?.tokenizer.maxGram) {
      updateDynamicField('maxGram', analyzerData?.tokenizer.maxGram);
    }
    if (analyzerData?.tokenizer.pattern) {
      updateDynamicField('pattern', analyzerData?.tokenizer.pattern);
    }
    if (analyzerData?.tokenizer.group) {
      updateDynamicField('group', analyzerData?.tokenizer.group);
    }
    if (analyzerData?.tokenizer.maxTokenLength) {
      updateDynamicField(
        'maxTokenLength',
        analyzerData?.tokenizer.maxTokenLength
      );
    }
  }, [analyzerData, updateDynamicField]);

  const onSave = (name: string, value: string | number) => {
    setDynamicFields((fields) => ({
      ...fields,
      [name]: value,
    }));
  };

  const handleSave = (formData: CreateAnalyzerFormData) => {
    const { name, minGram, maxGram, pattern, group, maxTokenLength } = formData;

    if (minGram && maxGram && minGram > maxGram) {
      sendErrorNotification({
        message: 'Error',
        description: 'Min gram cannot be greater than max gram',
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/full-text-search/creating-a-custom-analyzer',
      });
      return;
    }

    const createAnalyzerRequestData: AnalyzerRequestData = {
      name,
      tokenizer: {
        type: type as AnalyzerTypes,
      },
    };

    if (minGram) {
      createAnalyzerRequestData.tokenizer.minGram = minGram;
    }

    if (maxGram) {
      createAnalyzerRequestData.tokenizer.maxGram = maxGram;
    }

    if (pattern) {
      createAnalyzerRequestData.tokenizer.pattern = pattern;
    }

    if (group) {
      createAnalyzerRequestData.tokenizer.group = group;
    }

    if (maxTokenLength) {
      createAnalyzerRequestData.tokenizer.maxTokenLength = maxTokenLength;
    }

    updateModelMutation.mutate(createAnalyzerRequestData);
  };

  useEffect(() => {
    if (analyzerData) {
      setName(analyzerData?.name);
      setType(analyzerData?.tokenizer.type);
      resetDynamicFields();
    }
  }, [analyzerData, resetDynamicFields]);
  const renderCharFilterAttributes = (filter: CharFiltersBody) => {
    if (filter.type === 'htmlStrip') {
      return (
        <Flex direction="column" gap={10}>
          <Text appearance="label.short.regular">Ignored Tags</Text>
          <Text appearance="label.short.semiBold">
            "{filter.ignoredTags?.join('", "')}"
          </Text>
        </Flex>
      );
    }
    if (filter.type === 'mapping') {
      return (
        <Flex direction="column" gap={10}>
          <Text appearance="label.short.regular">Mappings</Text>
          {Object.entries(filter.mappings).map(([key, value]) => (
            <Text appearance="label.short.semiBold" key={key}>
              "{key}"{'\t\t'}:{'\t\t'}"{value}"
            </Text>
          ))}
        </Flex>
      );
    }
    return null;
  };
  const renderCharFilterDetails = (
    filter: CharFiltersBody,
    index: number
  ): React.ReactNode => {
    return (
      <Flex direction="column" gap={10} key={index}>
        <Text appearance="label.short.regular">Filter Type</Text>
        <StyledAnalyzerType>
          <Text appearance="label.short.regular">{filter.type}</Text>
        </StyledAnalyzerType>
        {renderCharFilterAttributes(filter)}
      </Flex>
    );
  };
  const renderTokenFilterAttribute = (
    name: string,
    value:
      | string
      | number
      | boolean
      | string[]
      | Record<string, string | number | boolean | string[]>
  ) => {
    if (Array.isArray(value) || typeof value !== 'object') {
      return (
        <Flex direction="row" alignItems="center" key={name} gap={8}>
          <Text appearance="label.short.regular">{name}</Text>
          <Text appearance="label.short.semiBold">
            {Array.isArray(value)
              ? (value as string[]).join(', ')
              : String(value)}
          </Text>
        </Flex>
      );
    }
    if (typeof value == 'object') {
      return (
        <Flex direction="column" gap={10}>
          <Text appearance="label.short.regular">{name}</Text>
          {Object.entries(value).map(([name, value]) =>
            renderTokenFilterAttribute(name, value)
          )}
        </Flex>
      );
    }
    return null;
  };
  const renderTokenFilterDetails = (
    filter: TokenFilterFormData,
    index: number
  ) => {
    const { type, ...rest } = filter;
    return (
      <Flex key={index} direction="column" gap={10}>
        <Text appearance="label.short.regular">Filter Type</Text>
        <StyledAnalyzerType>
          <Text appearance="caption.allCaps">{filter.type}</Text>
        </StyledAnalyzerType>
        {Object.entries(rest).map(([key, value]) =>
          renderTokenFilterAttribute(key, value)
        )}
      </Flex>
    );
  };

  if (isAnalyzerLoading) {
    return (
      <Flex direction="column">
        <Spin />
      </Flex>
    );
  }

  if (error || !analyzerData) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchAnalyzer();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex direction="column">
      <PageHeader
        title="Analyzer details"
        primaryAction={
          <Button
            appearance="primary"
            htmlType="submit"
            onClick={(e) => {
              handleSave(form.getFieldsValue());
            }}
          >
            Save
          </Button>
        }
        tabs={[
          {
            title: 'Details',
            active: true,
            id: 'details',
            onClick: () => {
              // handleSave(form.getFieldsValue());
            },
          },
        ]}
      />

      <Paper size="medium">
        <Form
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={analyzerData}
        >
          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={10}>
              <Label required={true}>Analyzer name</Label>
              <EditableText
                type="text"
                name="name"
                {...{ value: name, onSave: setName }}
              />
            </Flex>
            <Flex direction="column" gap={10}>
              <Label>Character Filters</Label>
              {analyzerData.charFilters?.map((filter, index) => (
                <Flex>
                  <Label style={{ marginRight: 10, marginLeft: 4 }}>
                    {index + 1}.
                  </Label>
                  {renderCharFilterDetails(filter, index)}
                </Flex>
              ))}
            </Flex>
            <Flex direction="column" gap={10}>
              <Label required={true}>Tokenizer type</Label>
              <Flex>
                <StyledAnalyzerType>
                  <Text appearance="caption.allCaps">{type}</Text>
                </StyledAnalyzerType>
              </Flex>
            </Flex>
            <Flex direction="column" gap={16}>
              <Show
                if={
                  DynamicTokenizerInputMapping[type as AnalyzerTypes]?.length >
                  0
                }
              >
                <Label>Tokenizer properties</Label>
              </Show>
              {DynamicTokenizerInputMapping[type as AnalyzerTypes]?.map(
                (field) => (
                  <Flex direction="column" gap={10}>
                    <Label required={field.required}>{field.displayName}</Label>
                    <EditableText
                      type={field.type}
                      onSave={(value: string) => {
                        onSave(field.name, value);
                      }}
                      value={
                        dynamicFields[
                          field.name as keyof DynamicFieldsType
                        ] as string
                      }
                      name={field.name}
                      disabled
                    />
                  </Flex>
                )
              )}
            </Flex>
            <Flex direction="column" gap={10}>
              <Label>Token Filters</Label>
              {analyzerData.tokenFilters?.map((filter, index) => (
                <Flex>
                  <Label style={{ marginRight: 10, marginLeft: 4 }}>
                    {index + 1}.
                  </Label>
                  {renderTokenFilterDetails(filter, index)}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Form>
      </Paper>
    </Flex>
  );
};
