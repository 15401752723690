import SchemaRow from './SchemaRow';
import { SchemaKeySubType, SchemaKeyType, TSchema } from './SchemaTree.types';
import { styled } from '@comet/styled';
import { Flex, Show } from '@comet/blocks';

interface IProps {
  schema: TSchema;
  level: number;
  initialPath: string;
  disabled?: boolean;
  showBasic?: boolean;
}

const Container = styled(Flex)`
  width: 100%;
`;

const RowIndex = styled(Flex)`
  color: ${({ theme }) => theme.palette.neutral.light};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-right: 12px;
`;

const Shifter = styled(Flex)`
  padding-left: 16px;
`;

const Border = styled.div`
  width: 8px;
  margin: 16px 0;
  border: 1px solid ${({ theme }) => theme.palette.neutral.lighter};
  border-right: none;
`;

export default function SchemaObject(props: IProps) {
  const { schema, level, initialPath, disabled, showBasic } = props;

  const getCurrentPath = (initialPath: string, currentPath: string) => {
    if (!initialPath) {
      return currentPath;
    }

    return initialPath + '/' + currentPath;
  };

  const renderObject = (object: TSchema) => {
    if (
      (object.type !== SchemaKeyType.NESTED &&
        !(
          object.type === SchemaKeyType.ARRAY &&
          object.subType === SchemaKeySubType.NESTED
        )) ||
      !object.properties
    ) {
      return <></>;
    }

    const objectProperties = object.properties,
      objectPropertiesArray = object.order || [],
      elements = objectPropertiesArray.map((property, index) => {
        const propertyInfo = objectProperties[property],
          currentPath = getCurrentPath(initialPath, property);

        if (
          propertyInfo.type === SchemaKeyType.NESTED ||
          (propertyInfo.type === SchemaKeyType.ARRAY &&
            propertyInfo.subType === SchemaKeySubType.NESTED)
        ) {
          return (
            <Container alignItems="flex-start">
              {!level && (
                <RowIndex style={{ paddingTop: 10 }}>{index + 1}.</RowIndex>
              )}
              <div style={{ width: '100%' }}>
                <SchemaRow
                  name={propertyInfo.name}
                  type={propertyInfo.type}
                  level={level}
                  subType={
                    propertyInfo.type === SchemaKeyType.ARRAY
                      ? propertyInfo.subType
                      : undefined
                  }
                  path={currentPath}
                  required={propertyInfo.required}
                  disabled={disabled}
                  showBasic={showBasic}
                />
                <Shifter>
                  <Show
                    if={Object.keys(propertyInfo.properties || {}).length > 1}
                  >
                    <Border />
                  </Show>
                  <SchemaObject
                    schema={propertyInfo}
                    level={level + 1}
                    initialPath={currentPath}
                    disabled={disabled}
                    showBasic={showBasic}
                  />
                </Shifter>
              </div>
            </Container>
          );
        }

        return (
          <Container alignItems="center">
            {!level && <RowIndex>{index + 1}.</RowIndex>}
            <SchemaRow
              name={propertyInfo.name}
              type={propertyInfo.type}
              level={level}
              subType={
                propertyInfo.type === SchemaKeyType.ARRAY
                  ? propertyInfo.subType
                  : undefined
              }
              path={currentPath}
              required={propertyInfo.required}
              disabled={disabled}
              showBasic={showBasic}
            />
          </Container>
        );
      });

    return elements;
  };

  return <div style={{ width: '100%' }}>{renderObject(schema)}</div>;
}
