import {
  EnvironmentMetadata,
  EnvironmentTierEnum,
  EnvironmentTypesEnum,
} from './EnvironmentDetails.types';

export type EnvironmentChangeType =
  | 'CREATED'
  | 'SWITCHED_TO_SHARED'
  | 'SWITCHED_TO_DEDICATED'
  | 'UPDATED_TIER'
  | 'UPDATED_SHARED_ENV_ID'
  | 'DELETED';

export interface DraftStateType extends EnvironmentMetadata {
  type: EnvironmentChangeType;
}

export interface GetEnvironmentMetadata extends EnvironmentMetadata {
  sharedEnvName: string | null;
}

export interface Environment {
  id: string;
  name: string;
  projectId: string;
  type: EnvironmentTypes;
  draftState: DraftStateType | null;
  metadata: GetEnvironmentMetadata;
  active: boolean;
  createdBy: User;
  createdOn: number;
  updatedBy: User;
  updatedOn: number;
  version: string;
  url: string;
}

export enum EnvironmentTypes {
  CUSTOM = 'CUSTOM',
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
}
export interface GetEnvironmentResponse {
  data: Environment[];
  page: {
    limit: number;
    total: number;
  };
}

export interface User {
  name: string;
  id: string;
  picture: string | null;
}

export interface EnvironmentRequestData {
  name?: string;
  type?: EnvironmentTypesEnum;
  isShared?: boolean;
  sharedEnvId?: string;
  tier?: EnvironmentTierEnum;
}

export interface FormData {
  name: string;
  type: string;
  isShared: boolean;
  sharedEnvId: string | null;
  tier: EnvironmentTierEnum | null;
}
export interface Options {
  data?: {
    label: string;
    value: string;
  }[];
  developmentId?: string;
}

export enum SUBSCRIPTION_STATUS {
  NEW_PROJECT = 'NEW_PROJECT',
  NEW_DRAFTS = 'NEW_DRAFTS',
  UNPAID = 'UNPAID',
  UPDATING = 'UPDATING',
  ACTIVE = 'ACTIVE',
}

export interface GetEnvironmentsParams {
  limit?: number;
  offset?: number;
  sort_by?: string;
  sort_order?: string;
}
