import { getAxios } from '@comet/axios';
import { useMutation, useQuery } from '@comet/query';
import {
  DeploymentsDataRaw,
  GetDeploymentReleasesResponse,
  GetDeploymentsResponse,
} from '../types/Projects.types';
import { useParams } from '@comet/router';

interface DeploymentListParams {
  offset?: number;
  query?: string;
  updatedBy?: string | null;
  sortBy?: string | null;
}

export const useDeploymentsQuery = (
  projectId: string,
  params: DeploymentListParams
) => {
  const url = `resources/${projectId}/deployments`;

  return useQuery({
    queryKey: ['useDeploymentsQuery', projectId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetDeploymentsResponse>(url, {
        params,
      });

      return data;
    },
  });
};

export const useDeploymentReleasesQuery = (
  projectId: string,
  params: DeploymentListParams
) => {
  const url = `resources/${projectId}/deployments/releases`;

  return useQuery({
    queryKey: ['useDeploymentReleasesQuery', projectId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetDeploymentReleasesResponse>(
        url,
        {
          params,
        }
      );
      return data;
    },
  });
};

export const promoteDeployment = async (
  projectId: string,
  version: string,
  environmentId: string
) => {
  const url = `resources/${projectId}/deployments/${version}/promote`;
  const { data } = await getAxios().put<{ message: string }>(url, {
    environmentId,
  });
  return data;
};

export const usePromoteEnvironment = () => {
  const { projectId } = useParams();

  return useMutation({
    mutationKey: ['/promote', projectId],
    mutationFn: async ({
      version,
      environmentId,
    }: {
      version: string;
      environmentId: string;
    }) => {
      return await promoteDeployment(projectId, version, environmentId);
    },
  });
};

export const createDeployment = async (
  projectId: string,
  DeploymentData: DeploymentsDataRaw
) => {
  const url = `resources/${projectId}/deployments`;

  const { data } = await getAxios().post(url, DeploymentData);

  return data;
};

export const rollbackDeployment = async (
  projectId: string,
  version: string
) => {
  const url = `/resources/${projectId}/deployments/${version}`;

  const { data } = await getAxios().put(url, {});
  return data;
};
