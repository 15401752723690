import { Center, Flex, Text } from '@comet/blocks';
import { ReactComponent as ProgressSVG } from '@comet/assets/svg/payment_progress.svg';
import styled from 'styled-components';
import { Progress } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from '@comet/router';
import { intervalToDuration } from 'date-fns';

const Container = styled.div`
  background-color: white;
  width: 60%;
  margin: auto;
  margin-top: 40px;
  border-radius: 8px;
  padding: 24px;
  position: relative;
`;

const FIVE_MINUTES = 5 * 60 * 1000;

const useInterval = (seconds: number) => {
  const interval = useRef<Duration>();

  useEffect(() => {
    interval.current = intervalToDuration({ start: 9, end: seconds });
  }, [seconds]);

  return interval;
};

interface PaymentLoadingProps {
  onTimerEnd: () => void;
}

export const PaymentLoading = ({ onTimerEnd }: PaymentLoadingProps) => {
  const { organisationId } = useParams();

  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(FIVE_MINUTES);
  const [progress, setProgress] = useState(0);

  const interval = useInterval(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds === 0) {
        onTimerEnd();
      }
      const percentagePerSecond = 100.0 / (5 * 60);
      setProgress((curr) => curr + percentagePerSecond);
      setSeconds((sec) => sec - 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate, onTimerEnd, organisationId, seconds]);

  return (
    <Container>
      <Progress
        style={{ position: 'absolute', top: -10, left: 0, borderRadius: 1 }}
        percent={progress}
        showInfo={false}
      />
      <Flex direction="column" style={{ marginTop: 10 }}>
        <Text appearance="heading.card">Waiting for payment confirmation.</Text>
        <Text appearance="body.regular">
          Your purchase is being processed and we're waiting for payment
          confirmation.
        </Text>
        <Text
          appearance="label.short.regular"
          color="neutral.darker"
          style={{ marginTop: 15 }}
        >
          Redirecting you in {interval.current?.minutes}m:
          {interval.current?.seconds}s
        </Text>
        <Center style={{ marginTop: 40 }}>
          <ProgressSVG />
        </Center>
      </Flex>
    </Container>
  );
};
