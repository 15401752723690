import SchemaTree from '@comet/components/SchemaTree/SchemaTree';
import { useSchemaStore } from './ModelSchemaStore';
import { useEffect } from 'react';
import { createSchemaFromResponseData } from './ModelSchema.util';

import { Model, ModelRequestData } from '../types';

interface ModelSchemaProps {
  model: Model | undefined;
  updateJsonSchema: React.Dispatch<React.SetStateAction<object | undefined>>;
}
export const ModelSchema = ({ model, updateJsonSchema }: ModelSchemaProps) => {
  const { schema, updateSchema } = useSchemaStore((state) => state);

  useEffect(() => {
    if (model?.fields) {
      updateJsonSchema(model.fields);
      const modelSchema = createSchemaFromResponseData(
        model as ModelRequestData
      );

      updateSchema(modelSchema);
    }
  }, [model, updateSchema]);

  return <SchemaTree schema={schema} modelType={model?.modelType} />;
};
