import { NodeFormFieldProps } from './NodeFormField';
import { Select, Spin } from '@comet/blocks';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { useResolver } from './NodeEditor.resolver';
import { useModelQuery } from '@comet/pages/Model/service';
import { useParams } from '@comet/router';
import { DefaultOptionType } from 'antd/es/select';

export const AsyncSelect = ({
  disabled,
  value,
  values,
  resolver,
  fieldKey,
  onChange,
  placeholder,
  ...props
}: NodeFormFieldProps) => {
  const { isLoading, data, fetch, isFetched, isError } = useResolver(
    resolver || '',
    '',
    values
  );

  const { projectId } = useParams();
  const { data: model } = useModelQuery(
    projectId,
    value,
    { draft: false },
    !!value && resolver === 'DATABASE_COLLECTION_RESOLVER'
  );

  const [currentValue, setCurrentValue] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    fetch('');
  }, []);

  useEffect(() => {
    if (!model || resolver !== 'DATABASE_COLLECTION_RESOLVER') return;
    setCurrentValue([{ value: model.modelId, label: model.name }]);
  }, [model, resolver]);

  useEffect(() => {
    if (data) {
      setCurrentValue(data);
    }
  }, [data]);

  const debouncedFetch = useRef(debounce(fetch, 500));

  const notFoundContent = () => {
    if (!isFetched) {
      return null;
    }
    if (isLoading) {
      return <Spin size="small" />;
    }
    if (isError) {
      return <p>Something went wrong</p>;
    }

    if (data?.length === 0) {
      return <p>No results</p>;
    }
  };

  const handleChange = (value: string) => {
    onChange(fieldKey, value);
  };

  return (
    <Select
      {...props}
      placeholder={placeholder || 'None'}
      disabled={disabled || (currentValue.length === 0 && value)}
      style={{ width: '100%' }}
      // defaultValue={search}
      onSearch={debouncedFetch.current}
      showSearch
      value={value}
      onChange={handleChange}
      options={currentValue}
      notFoundContent={notFoundContent()}
      onFocus={() => fetch('')}
      optionFilterProp="label"
    />
  );
};
