import { NodeTypeEnum } from '@comet/components/FlowEngine/Nodes';
import { useCallback } from 'react';
import { Node, getIncomers, getOutgoers, useReactFlow } from 'reactflow';

export const useReplaceAddNode = (
  onDelete?: (node: Node) => void | boolean
) => {
  const { getEdges, getNodes, getNode, deleteElements } = useReactFlow();

  return useCallback(
    (node: Node) => {
      const isParentExists = node.parentNode;
      const childExists = getOutgoers(node, getNodes(), getEdges());
      const incomingNode = getIncomers(node, getNodes(), getEdges());
      const isLogicNode = ['IF_NODE'].includes(node.type ?? '');

      if (isParentExists && isLogicNode) {
        // if the deleted node have a parent node and the node is logical node, re-create the node placeholder
        const parentNode = getNode(isParentExists);
        if (!parentNode) return false;
        if (onDelete) {
          // Delete the IF_NODE using hook (contains the logic to attach the child nodes to upper nodes for IF_ELSE_V2)
          onDelete(node);
        } else {
          deleteElements({ nodes: [node] });
        }
        parentNode.data.nodeOperations(
          {
            id: parentNode.id,
            type: parentNode.type,
            position: { x: parentNode.position.x, y: parentNode.position.y },
            data: parentNode.data,
          },
          {
            type: NodeTypeEnum.LoopNode,
            data: {
              displayName: 'Add Node',
              schema: [],
              values: {},
              type: NodeTypeEnum.AddNode,
            },
          },
          'updateChild'
        );
        return true;
      }

      if (
        incomingNode.length > 0 &&
        ['IF_NODE', 'LOOP_NODE', 'EXECUTOR_NODE'].includes(
          incomingNode[0]?.type ?? ''
        )
      ) {
        // if the node is connected node is a if_node and we're deleting the child node replace with a placeholder
        // if the child of if_node does have the child nodes or it's switch node break here
        let nodeData = node;
        let action = 'replaceNode';
        let newNodeType = NodeTypeEnum.AddNode;

        if (node.type === 'SWITCH_NODE') {
          nodeData = incomingNode[0];
          deleteElements({ nodes: [node] });
          action = 'addSibiling';
          newNodeType = NodeTypeEnum.IfNode; // use the if_node to add the place-holder
        }

        if (node.type === 'IF_NODE') {
          nodeData = incomingNode[0];
          deleteElements({ nodes: [node] });
          newNodeType = NodeTypeEnum.IfNode; // use the if_node to add the place-holder
          action = 'addSibiling';
        }

        if (
          incomingNode[0]?.type === 'EXECUTOR_NODE' &&
          childExists.length > 0
        ) {
          return;
        }

        incomingNode[0]?.data.nodeOperations(
          // Replace with the placeholder node
          {
            ...nodeData,
            id: nodeData.id,
            type: nodeData.type,
            position: { x: nodeData.position.x, y: nodeData.position.y },
            data: nodeData.data,
          },
          {
            type: newNodeType,
            data: {
              displayName: 'Add Node',
              schema: [],
              values: {},
              type: NodeTypeEnum.AddNode,
            },
          },
          action
        );

        return true;
      }

      if (
        isParentExists &&
        incomingNode.length === 0 &&
        childExists.length === 0
      ) {
        // Add a placeholder node if the deleted node is base node
        node.data.nodeOperations(
          {
            id: node.id,
            type: node.type,
            position: { x: node.position.x, y: node.position.y },
            data: node.data,
          },
          {
            type: NodeTypeEnum.AddNode,
            data: {
              displayName: 'Add Node',
              schema: [],
              values: {},
              type: NodeTypeEnum.AddNode,
            },
          },
          'replaceNode'
        );

        return true;
      }

      return false;
    },
    [deleteElements, getEdges, getNode, getNodes, onDelete]
  );
};
