import { Flex, FullCenter, Spin } from '@comet/blocks';
import { AddEnvironments } from './AddBulkEnvironment';
import { useGetSubscriptionStatus } from '../Project/Environments';

export const UpgradePlan = () => {
  const { data, isLoading } = useGetSubscriptionStatus();

  if (isLoading || !data) {
    return (
      <FullCenter>
        <Spin />
      </FullCenter>
    );
  }

  return (
    <Flex>
      <AddEnvironments />
    </Flex>
  );
};
