import { MdDeleteForever } from 'react-icons/md';
import { styled } from '@comet/styled';

const StyledDeleteIcon = styled.div`
  color: ${({ theme }) => theme.palette.neutral.dark};
`;

export const DeleteIcon = ({ ...props }) => {
  return (
    <StyledDeleteIcon style={{ cursor: 'pointer' }}>
      <MdDeleteForever {...props} />
    </StyledDeleteIcon>
  );
};
