import {
  Button,
  Center,
  CustomSelect,
  Flex,
  FullCenter,
  Show,
  Spin,
  Select,
  Table,
  Text,
  usePagination,
  Search,
  Alert,
} from '@comet/blocks';
import { Empty, Result, Tooltip } from 'antd';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate, useParams } from '@comet/router';
import { useModelsQuery } from '../service';
import { ModelTypesEnum } from '../types';
import debounce from 'lodash.debounce';
import { REQUEST_MODELS_PARAM, modelTypeOptions } from '../Model.constants';
import { useDeploymentsVersion } from '@comet/pages/Project/Deployments/Deployments.utils';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import { Release } from '@comet/pages/Project/Deployments/ReleaseModal';
import { useModelListingData } from '../utils';
import _ from 'lodash';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { useGetProjectQuery } from '@comet/pages/Project/service';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

const PAGE_SIZE = 10;

export const ModelList = () => {
  const { organisationId, projectId } = useParams();
  const navigate = useNavigate();
  const { pageOffset, currentPageIndex, fetchData } = usePagination();
  const { versions, version, latest, setVersion } =
    useDeploymentsVersion(projectId);
  const { pathname } = useLocation();

  const databaseModels = pathname.includes('dbModels');

  const { hasViewModelAccess, hasEditModelAccess } = useProjectPermissions();

  const initialValues = {
    offset: 0,
    version: versions[0]?.value || null,
    model_type: databaseModels ? modelTypeOptions.at(-1)?.value ?? '' : '',
    query: '',
  };

  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState(initialValues.query);
  const [modelType, setModelType] = useState(initialValues.model_type);

  const modelTypeQueryValue = useMemo(
    () => (modelType.length === 0 ? REQUEST_MODELS_PARAM : modelType),
    [modelType]
  );

  useEffect(() => {
    setModelType(databaseModels ? modelTypeOptions.at(-1)?.value ?? '' : '');
  }, [databaseModels]);

  const handleModelTypeChange = (value: string) => {
    setModelType(value as ModelTypesEnum);
  };

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const currentValues = {
    offset: pageOffset,
    version,
    model_type: modelType,
    query,
  };

  const {
    data: modelsData,
    isLoading,
    error,
    refetch,
  } = useModelsQuery(projectId, {
    offset: pageOffset,
    version,
    model_type: modelTypeQueryValue || null,
    query,
  });

  const { data: activeModelsData, refetch: refetchActiveModel } =
    useModelsQuery(projectId, {
      offset: 0,
      limit: 1,
      version,
      model_type: modelTypeQueryValue || null,
      active: true,
    });

  const activeModels = activeModelsData?.page.total || 0;
  const { tableColumns, tableData } = useModelListingData(
    modelsData,
    version,
    latest,
    refetch,
    refetchActiveModel,
    databaseModels
  );

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);

  const modelLimit = orgSubscriptionData?.resourceLimits.maxNumModels || 0;
  const isCreateModelDisabled =
    !orgSubscriptionData?.active || modelLimit <= activeModels;

  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !modelsData;
  const showList = !isLoading && !isError && modelsData.data.length > 0;
  const modelTypeFilerOptions = useMemo(
    () =>
      modelTypeOptions.filter(
        (options) =>
          options.value.toLowerCase().includes('database') === databaseModels
      ),
    [databaseModels]
  );

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!hasViewModelAccess) {
    return <ErrorPage />;
  }

  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Show if={versions && versions[0]?.label === 'Draft'}>
        <Flex direction="column" style={{ background: '#FFFFFF' }}>
          <Alert
            message="Changes has been made to your project since last deploy."
            type="info"
            showIcon={true}
            action={<Release />}
            closable={true}
          />
        </Flex>
      </Show>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">
            {databaseModels ? 'DB' : 'Request'} Models
          </Text>
          <Show if={versions && versions.length > 0}>
            <Select options={versions} value={version} onChange={setVersion} />
          </Show>
        </Flex>
        <Tooltip
          title={
            isCreateModelDisabled
              ? 'You are on Free Tier,Update plan to create more'
              : ''
          }
        >
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `organisations.organisationId.projects.projectId.${
                  databaseModels ? 'dbModels' : 'models'
                }.new`,
                {
                  organisationId,
                  projectId,
                }
              )
            }
            disabled={!hasEditModelAccess || isCreateModelDisabled}
          >
            Create Model
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Flex alignItems="center" gap={10} padding={10}>
          <Flex>
            <Search
              placeholder="Search by name"
              value={searchValue}
              onChange={onSearch}
            />
          </Flex>
          <CustomSelect
            label="ModelType"
            options={modelTypeFilerOptions}
            value={modelType}
            onChange={handleModelTypeChange}
          />
        </Flex>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && modelsData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No models found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="models"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil(
              (modelsData?.page.total || 0) / PAGE_SIZE
            )}
            totalRows={modelsData?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
