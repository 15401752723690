import { Button, Modal, Flex } from '@comet/blocks';
import { useState } from 'react';
import { Editor } from '@monaco-editor/react';
import { transformJSONtoCosmocloudModel, getJson } from './utils';
import { queryClient, useMutation } from '@comet/query';
import { updateModel } from '@comet/pages/Model/service';
import { Model } from '@comet/pages/Model/types';
import { notification } from 'antd';
import { sendErrorNotification } from 'src/blocks/Notification';
import { useParams } from '@comet/router';

type CosmoModelBuilderProps = {
  jsonSchema: object | undefined;
  model: Model | undefined;
};

export const CosmoModelBuilder = ({
  model,
  jsonSchema,
}: CosmoModelBuilderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, updateValue] = useState('');
  const [error, updateError] = useState('');
  const [disableSaveModal, updateDisableSaveModal] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    try {
      const obj = JSON.parse(value);
      const fieldsData = transformJSONtoCosmocloudModel(obj, updateError);
      if (!error) {
        const requestData = {
          modelType: model?.modelType,
          active: model?.active,
          fields: fieldsData,
        };

        updateModelMutation.mutate(requestData);
      }
    } catch (ex) {
      sendErrorNotification({
        message: 'Invalid JSON',
        description: 'Please check your JSON and try again',
      });
      updateDisableSaveModal(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function handleEditorChange(text: any) {
    updateValue(text);
    if (disableSaveModal) {
      updateDisableSaveModal(false);
      updateError('');
    }
  }

  const { projectId, modelId } = useParams();

  const updateModelMutation = useMutation({
    mutationFn: (modelData: any | null) =>
      updateModel(projectId, modelId, modelData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useModelQuery', projectId, modelId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      notification.success({
        message: 'Success',
        description: 'Model updated successfully',
      });
      setIsModalOpen(false);
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to update model',
        reportBug: true,
      });
    },
  });

  return (
    <>
      <Modal
        title="Create from sample JSON"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        cancelText="Discard"
        okText="Save"
        footer={[
          <Flex justifyContent="space-between">
            <span style={{ color: 'red' }}>{error && error}</span>
            <Button
              type="primary"
              disabled={disableSaveModal}
              onClick={handleOk}
            >
              Save
            </Button>
          </Flex>,
        ]}
      >
        <Editor
          height={'60vh'}
          value={JSON.stringify(getJson(jsonSchema), null, '\t')}
          defaultLanguage="json"
          language="json"
          defaultValue={JSON.stringify(getJson(jsonSchema), null, '\t')}
          className="json_editor"
          onChange={handleEditorChange}
          options={{
            hideCursorInOverviewRuler: false,
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            fontSize: 16,
          }}
        />
      </Modal>
      <Button type="primary" onClick={showModal}>
        JSON to Schema
      </Button>
    </>
  );
};
