import {
  getSearchIndex,
  getSearchIndexes,
} from '@comet/pages/FullTextSearch/SearchIndex/service';
import { getFieldOptions } from '@comet/pages/FullTextSearch/SearchIndex/utils';
import {
  getVectorSearchIndex,
  getVectorSearchIndexes,
} from '@comet/pages/FullTextSearch/VectorSearch/service';
import { getModel, getModels } from '@comet/pages/Model/service/model.service';
import { ModelTypesEnum } from '@comet/pages/Model/types';
import { getOrgs } from '@comet/pages/Organisation/service';
import { getEnvironments } from '@comet/pages/Project/Environments';
import { getStorageAccounts } from '@comet/pages/Project/StorageAccounts/service';
import { getSubflows } from '@comet/pages/Project/Subflows/service';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useResolver = (
  resolver: string,
  searchValue = '',
  values: Record<string, any> = {}
) => {
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<DefaultOptionType[]>();
  const [isFetched, setIsFetched] = useState(false);

  const service = useMemo(() => {
    return (searchValue: string) => {
      switch (resolver) {
        case 'ORG_LISTING': {
          return getOrgs({ query: searchValue }).then(({ data }) =>
            data.map(({ id, name }) => ({ value: id, label: name }))
          );
        }
        case 'QUERY_PARAMS_RESOLVER': {
          if (!projectId) return;
          return getModels(projectId, {
            model_type: ModelTypesEnum.QUERY_PARAMS,
            query: searchValue,
            version: null,
          }).then((data) =>
            data.data.map(({ id, name }) => ({ value: id, label: name }))
          );
        }

        case 'DATABASE_COLLECTION_RESOLVER': {
          if (!projectId) return;
          return getModels(projectId, {
            model_type: ModelTypesEnum.DATABASE_COLLECTION,
            query: searchValue,
            version: null,
          }).then((data) =>
            data.data.map(({ id, name }) => ({ value: id, label: name }))
          );
        }

        case 'ENVIRONMENTS_RESOLVER': {
          if (!projectId) return;
          return getEnvironments(projectId).then((data) =>
            data.data.map(({ id, name }) => ({ value: id, label: name }))
          );
        }

        case 'REQUEST_BODY_RESOLVER': {
          if (!projectId) return;
          return getModels(projectId, {
            model_type: ModelTypesEnum.REQUEST_BODY,
            query: searchValue,
            version: null,
          }).then((data) =>
            data.data.map(({ id, name }) => ({ value: id, label: name }))
          );
        }

        case 'SEARCH_INDEX_RESOLVER': {
          if (!projectId || !values?.['environmentId']) return;
          return getSearchIndexes(projectId, values['environmentId']).then(
            (data) => {
              return (data?.data || []).map((o) => ({
                value: o.id,
                label: o.name,
              }));
            }
          );
        }
        case 'SEARCH_QUERY_FIELDS_RESOLVER': {
          if (!projectId || !values?.['searchIndex']) return;

          return getSearchIndex(projectId, values['searchIndex'])
            .then((data) => {
              const options = (data.mappings.fields || []).map((field) => ({
                value: field.name,
                label: field.name,
              }));

              const modelId = data.modelId;

              return { options, modelId };
            })
            .then(async (result) => {
              if (result.options?.length > 0) return result.options;

              const fields = await getModel(projectId, result.modelId, {}).then(
                (modelData) => {
                  return modelData.fields;
                }
              );

              return getFieldOptions(fields || []).fieldOptions;
            });
        }

        case 'VECTOR_SEARCH_INDEX_RESOLVER': {
          if (!projectId || !values?.['environmentId']) return;
          return getVectorSearchIndexes(
            projectId,
            values['environmentId']
          ).then((data) => {
            return (data?.data || []).map((o) => ({
              value: o.id,
              label: o.name,
            }));
          });
        }

        // resolver to get all fields of a vector search index

        case 'VECTOR_SEARCH_QUERY_FIELDS_RESOLVER': {
          if (!projectId || !values?.['vectorSearchIndex']) return;

          return getVectorSearchIndex(projectId, values['vectorSearchIndex'])
            .then((data) => {
              const modelId = data.modelId;
              return { modelId };
            })
            .then(async (result) => {
              const fields = await getModel(projectId, result.modelId, {}).then(
                (modelData) => {
                  return modelData.fields;
                }
              );

              return getFieldOptions(fields || []).fieldOptions;
            });
        }

        // resolver to get fields which can store array of float values of a vector search index
        case 'VECTOR_SEARCH_EMBEDDING_FIELDS_RESOLVER': {
          if (!projectId || !values?.['vectorSearchIndex']) return;

          return getVectorSearchIndex(projectId, values['vectorSearchIndex'])
            .then((data) => {
              const modelId = data.modelId;
              return { modelId };
            })
            .then(async (result) => {
              const fields = await getModel(projectId, result.modelId, {}).then(
                (modelData) => {
                  return modelData.fields;
                }
              );
              const permissableFields = fields?.filter((field) => {
                return field.type === 'LIST' && field.subType === 'FLOAT';
              });

              return getFieldOptions(permissableFields || []).fieldOptions;
            });
        }

        case 'SEARCH_FILTER_FIELDS_RESOLVER': {
          if (!projectId || !values?.['searchIndex']) return;

          return getSearchIndex(projectId, values['searchIndex'])
            .then((data) => {
              return data.modelId;
            })
            .then(async (modelId) => {
              const fields = await getModel(projectId, modelId, {}).then(
                (modelData) => {
                  return modelData.fields;
                }
              );

              return getFieldOptions(fields || []).fieldOptions;
            });
        }

        case 'FUNCTION_RESOLVER': {
          if (!projectId) return;
          return getSubflows(projectId, {
            flow_type: 'FUNCTION',
            version: null,
          }).then(({ data }) =>
            data.map(({ id, name }) => ({ value: id, label: name }))
          );
        }

        case 'STORAGE_ACCOUNT_RESOLVER': {
          if (!projectId) return;
          return getStorageAccounts(projectId, { limit: 10000 }).then(
            ({ data }) =>
              data.map(({ id, name }) => ({ value: name, label: name }))
          );
        }
      }
    };
  }, [resolver, projectId, values]);

  const fetch = useCallback(
    (searchValue: string) => {
      setIsLoading(true);
      setIsError(false);
      setIsFetched(true);
      service(searchValue)
        ?.then(setData)
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [service]
  );

  return { isError, isLoading, data, fetch, isFetched };
};
