import {
  Flex,
  Form,
  PageHeader,
  Paper,
  Show,
  Spin,
  Button,
  FullCenter,
  Center,
} from '@comet/blocks';
import { useLocation, useParams, useSearchParams } from '@comet/router';
import { UpdateModelForm } from './UpdateModalForm';
import { queryClient, useMutation } from '@comet/query';
import { ModelSchema } from '../ModelSchema/ModelSchema';
import { updateModel, useModelQuery } from '../service';
import { ModelRequestData } from '../types';
import { createRequestDataFromSchema } from '../ModelSchema/ModelSchema.util';
import { useSchemaStore } from '../ModelSchema/ModelSchemaStore';
import { Result, notification } from 'antd';
import { validateFieldData } from '../utils';
import { useEffect, useState } from 'react';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { sendErrorNotification } from 'src/blocks/Notification';
import { MdOpenInNew } from 'react-icons/md';
import { CosmoModelBuilder } from '@comet/components/CosmoModelBuilder';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

enum ViewEnum {
  Details = 'details',
  Schema = 'schema',
}

export const ModelDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [version, setVersion] = useState(searchParams.get('version') || null);
  const [draft, setDraft] = useState(
    searchParams.get('draft') === 'true' || false
  );

  const { hasViewModelAccess, hasEditModelAccess } = useProjectPermissions();

  const { organisationId, projectId, modelId } = useParams();
  const [form] = Form.useForm();
  const [jsonSchema, updateJsonSchema] = useState<object>();

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);

  const isSaveModelDisabled = !orgSubscriptionData?.active;

  const { state } = useLocation();
  const redirectUrl =
    'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/models/building-models';
  // This useEffect should be called at once on component loading
  useEffect(() => {
    if (state) {
      setSearchParams((params) => ({
        ...params,
        version: state.version,
        draft: state.draft,
        view: ViewEnum.Details,
      }));
      setVersion(state.version);
      setDraft(state.draft);
    } else {
      setSearchParams((params) => ({
        ...params,
        version: version || null,
        draft: typeof draft === 'boolean' ? draft : null,
        view: ViewEnum.Details,
      }));
    }
  }, []);

  const view = searchParams.get('view') || ViewEnum.Details;
  const updateModelMutation = useMutation({
    mutationFn: (modelData: ModelRequestData | null) =>
      updateModel(projectId, modelId, modelData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useModelQuery', projectId, modelId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      setSearchParams((params) => ({
        ...params,
        version: null,
        draft: true,
        view,
      }));
      setVersion(null);
      setDraft(true);
      notification.success({
        message: 'Success',
        description: 'Model updated successfully',
      });
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to update model',
        reportBug: true,
      });
    },
  });

  const {
    data: model,
    isLoading: isModelLoading,
    isError: isModelError,
    refetch: refetchDraftModel,
  } = useModelQuery(projectId, modelId, {
    version: version === 'null' ? null : version,
    draft,
  });

  const schema = useSchemaStore((state) => state.schema);

  const handleSave = (formData: ModelRequestData) => {
    const fieldsData = createRequestDataFromSchema(schema);

    if (fieldsData?.fields && !validateFieldData(fieldsData?.fields)) {
      sendErrorNotification({
        message: 'Error',
        description: 'Empty field names are not allowed',
      });
      return;
    }

    const requestData = {
      modelType: model?.modelType,
      active: model?.active,
      fields: fieldsData?.fields,
      description: formData['description'],
    };

    updateModelMutation.mutate(requestData);
  };

  const error = isModelError || !model;

  if (isModelLoading) {
    return (
      <Flex direction="column">
        <Spin />
      </Flex>
    );
  }

  if (!hasViewModelAccess) {
    return <ErrorPage />;
  }

  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchDraftModel();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex direction="column">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={model}
      >
        <PageHeader
          title={`Model - ${model?.name || 'Model details'}`}
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              onClick={(e) => {
                handleSave(form.getFieldsValue());
              }}
              disabled={!hasEditModelAccess || isSaveModelDisabled}
            >
              Save
            </Button>
          }
          redirectUrl={redirectUrl}
          tabs={[
            {
              title: 'Details',
              active: view === ViewEnum.Details,
              onClick: () =>
                setSearchParams((params) => ({
                  ...params,
                  version,
                  draft,
                  view: ViewEnum.Details,
                })),
              id: 'details',
            },
            {
              title: 'Schema',
              active: view === ViewEnum.Schema,
              onClick: () =>
                setSearchParams((params) => ({
                  ...params,
                  version,
                  draft,
                  view: ViewEnum.Schema,
                })),
              id: 'schema',
            },
          ]}
        />
        <Paper size="medium">
          <Show if={!!model && !error && view === ViewEnum.Details}>
            <UpdateModelForm
              name={model?.name || ''}
              description={model?.description || ''}
              modelType={model?.modelType || ''}
              hasEditAccess={!!hasEditModelAccess}
            />
          </Show>
          <Show if={!!model && view === ViewEnum.Schema}>
            <Flex
              justifyContent="flex-end"
              gap={10}
              style={{ marginBottom: 15 }}
            >
              <Button
                type="link"
                onClick={() =>
                  window.open(
                    'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/models/building-models#supported-data-types-in-schema-builder',
                    '_blank'
                  )
                }
              >
                <Flex gap={10} alignItems="center" justifyContent="center">
                  <div>Learn more</div>
                  <MdOpenInNew />
                </Flex>
              </Button>
              {hasEditModelAccess && (
                <CosmoModelBuilder jsonSchema={jsonSchema} model={model} />
              )}
            </Flex>
            <ModelSchema model={model} updateJsonSchema={updateJsonSchema} />
          </Show>
        </Paper>
      </Form>
    </Flex>
  );
};
