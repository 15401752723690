import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
  Tooltip,
} from '@comet/blocks';
import {
  EndpointComponent,
  StatusComponent,
} from '@comet/components/TableComponents';
import { User } from '@comet/components/User';
import { Link, useParams } from '@comet/router';
import { styled, useTheme } from '@comet/styled';
import { useCallback, useMemo, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Column } from 'react-table';
import { deleteApi, updateApi } from '../Service';
import { ApiDetails } from '../Types';
import { RequestMethodComponent } from '../utils';

import { notification } from 'antd';
import _ from 'lodash';
import { RiAlertLine } from 'react-icons/ri';
import { APIListingStateMapping } from './ApiListing.constants';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

export const ApiActions = ({
  id,
  refetch,
  active,
  version,
}: {
  id: string;
  refetch: () => void;
  active: boolean;
  version: string | null;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onToggleStatus = useCallback(
    async (apiId: string, currentStatus: boolean) => {
      await updateApi(projectId, apiId, {
        active: !currentStatus,
        flowType: 'API',
      });
      refetch();
    },
    [projectId, refetch]
  );

  const handleApiDelete = useCallback(async () => {
    try {
      await deleteApi(projectId, id);
      refetch();
      notification.success({
        message: 'Success',
        description: 'API deleted successfully',
      });
    } catch (e: any) {
      notification.error({
        message: 'Error',
        description: e?.response?.data?.message,
      });
    }
    toggleModal();
  }, [projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              key: 'statusEnable',
              label: active ? 'Disable' : 'Enable',
              onClick: () => onToggleStatus(id, active),
              disabled: !!version,
            },
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip title={'This will delete the model'}>Delete</Tooltip>
              ),
              onClick: toggleModal,
              disabled: !!version,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this API?
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this API.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button
              onClick={handleApiDelete}
              appearance="primary"
              htmlType="button"
            >
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useApiListingData = (
  ApiList: ApiDetails[] | undefined,
  version: string | null,
  latest: string | null,
  refetchApiList: () => void
) => {
  const { organisationId, projectId } = useParams();
  const { theme } = useTheme();
  const tableData = useMemo(() => {
    if (!ApiList) return [];

    return ApiList.map((item) => {
      let apiVersion = null;

      if (item.draft) apiVersion = null;
      else if (version) apiVersion = version;
      else apiVersion = latest;

      const statusText = getStatus(item);
      const statusColor =
        _.get(theme, APIListingStateMapping[statusText][1]) ||
        APIListingStateMapping[statusText][1];

      return {
        name: (
          <Link
            routeId="organisations.organisationId.projects.projectId.api.apiId.details"
            pathParam={{
              apiId: item.id,
              organisationId,
              projectId,
            }}
            state={{
              version: apiVersion,
              draft: item.draft,
              modelVersion: version,
            }}
            // reloadDocument={true}
          >
            {item.name}
          </Link>
        ),
        requestMethod: (
          <RequestMethodComponent
            style={{ width: 'max-content' }}
            requestMethod={item?.flowMetadata?.requestMethod}
          >
            {item?.flowMetadata?.requestMethod}
          </RequestMethodComponent>
        ),
        endpoint: <EndpointComponent endpoint={item?.flowMetadata?.endpoint} />,
        updatedOn: item.updatedOn.toString(),
        updatedBy: <User {...(item.updatedBy || {})} />,
        id: (
          <ApiActions
            id={item.id}
            refetch={refetchApiList}
            active={item.active}
            version={apiVersion}
          />
        ),
        status: (
          <StatusComponent color={statusColor}>{statusText}</StatusComponent>
        ),
      };
    });
  }, [ApiList, latest, organisationId, projectId, theme, version]);

  const tableColumns = useMemo(
    (): Column<(typeof tableData)[number]>[] => [
      {
        Header: <TableHeader text="Name" />,
        accessor: 'name',
      },
      {
        Header: <TableHeader text="Request Method" sortable />,
        accessor: 'requestMethod',
      },
      {
        Header: <TableHeader text="Endpoint" sortable />,
        accessor: 'endpoint',
      },
      {
        Header: <TableHeader text="Status" sortable />,
        accessor: 'status',
      },
      {
        Header: <TableHeader text="Created By" sortable />,
        accessor: 'updatedBy',
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
      },
    ],
    []
  );

  return { tableColumns, tableData };
};

type StatusType = 'DRAFT' | 'INACTIVE' | 'DEPLOYED' | 'DRAFT_INACTIVE';

export const getStatus = (item: {
  draft: boolean;
  active: boolean;
}): StatusType => {
  if (item.draft && !item.active) {
    return 'DRAFT_INACTIVE';
  }
  if (item.draft && item.active) {
    return 'DRAFT';
  }

  if (item.active) {
    return 'DEPLOYED';
  }

  return 'INACTIVE';
};
