import { NodeTypeEnum } from '@comet/components/FlowEngine/Nodes';
import { BusinessNodeProps } from '@comet/components/FlowEngine/Nodes/BusinessNode';
import { getGraph } from '@comet/components/FlowEngine/services/tree';
import { useCallback } from 'react';
import { useReactFlow, updateEdge } from 'reactflow';

type InputType = { key: string; value: string }[];
type SwitchNode =
  | {
      id: string;
      data: BusinessNodeProps;
    }
  | null
  | undefined;

export const useOnClickAdd = (node?: SwitchNode) => {
  const { getNode } = useReactFlow();
  return useCallback(() => {
    const mainNode = getNode(node?.id ?? '');

    if (!mainNode) return;

    mainNode.data.nodeOperations(
      {
        id: mainNode.id,
        type: mainNode.type,
        position: { x: mainNode.position.x, y: mainNode.position.y },
        data: mainNode?.data,
      },
      {
        type: NodeTypeEnum.SwitchNode,
        data: {
          ...mainNode?.data,
          displayName: 'ADD_NODE',
        },
      },
      'addNode'
    );
  }, [getNode, node]);
};

export const useOnremove = (inputs: InputType, node: SwitchNode) => {
  const { getNode, deleteElements, getNodes, getEdges } = useReactFlow();
  return useCallback(
    (index: number, onComplete: any) => {
      const value = inputs[index];
      let nodeToRemove;
      const mainNode = getNode(node?.id ?? '');
      if (!mainNode) return;
      if (!value.key) {
        const meta = mainNode.data.metadata?.cases?.[index];
        nodeToRemove = getNode(meta.nodeId);
      } else {
        nodeToRemove = getNode(value.key);
      }

      const toDelete: number[] = [];
      (mainNode.data?.metadata as SwitchNodeMetaData)?.cases.forEach(
        (item, index) => {
          if (item.deleted) {
            toDelete.push(index);
          }
        }
      );

      toDelete.forEach((i) => mainNode.data.metadata?.cases.splice(i, 1));

      if (!nodeToRemove) return;
      deleteElements({ nodes: [nodeToRemove] });

      setTimeout(() => {
        const graph = getGraph(getNodes(), getEdges());
        onComplete?.(() => graph);
      }, 500);
    },
    [deleteElements, getEdges, getNode, getNodes, inputs, node?.id]
  );
};

export const useUpdateMetadata = (node: SwitchNode) => {
  const { getNode } = useReactFlow();
  return useCallback(
    (inputs: InputType, index: number, value: string) => {
      const mainNode = getNode(node?.id ?? '');
      const data = mainNode?.data;

      if (!mainNode) return;

      const metadata: SwitchNodeMetaData['cases'] = data.metadata.cases;
      const _value = inputs?.[index];

      if (!_value?.key) {
        metadata[index].key = value;
      } else {
        const item = metadata.find((i) => i.nodeId === _value.key);

        if (!item) return;
        item.key = value;
      }
      mainNode.data = { ...data };
    },
    [getNode, node]
  );
};

export const useUpdateEdges = (metadata: SwitchNodeMetaData['cases']) => {
  const { getEdge, setEdges, getEdges } = useReactFlow();
  return useCallback(
    (inputs: InputType, index: number, value: string) => {
      const _value = inputs?.[index];
      if (!_value?.key) return;

      const item = metadata.find((i) => i.nodeId === _value.key);
      if (!item) return;

      const edge = getEdge(item.edgeId);
      if (!edge) return;

      edge.data.label = value;
      edge.data = { ...edge.data };
      const allEdges = getEdges();
      const edgeToUpdate = allEdges.find((_edge) => _edge.id === edge.id);
      if (!edgeToUpdate) return;
      edgeToUpdate.source = edge.source;
      edgeToUpdate.target = edge.target;
      setEdges(allEdges);
    },
    [getEdge, getEdges, metadata, setEdges]
  );
};
