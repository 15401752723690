import { Flex, Text, Modal, Button, Tooltip } from '@comet/blocks';
import { useNavigate, useParams } from '@comet/router';
import { useState } from 'react';
import { CgMenuGridO } from 'react-icons/cg';
import { IoAdd } from 'react-icons/io5';
import { BsCheckCircleFill } from 'react-icons/bs';
import { CardContainer } from '../../styled';

interface CreateApiModalProps {
  disabled: boolean;
  name?: string;
}
export const CreateApiModal = ({
  disabled,
  name = 'Create API',
}: CreateApiModalProps) => {
  const { organisationId, projectId } = useParams();
  const navigate = useNavigate();
  const [createApiModal, setCreateApiModal] = useState(false);
  const [selectedType, setSelectedType] = useState<'custom' | 'template' | ''>(
    ''
  );

  return (
    <>
      <Tooltip
        title={
          disabled ? 'You are on free tier,Update plan to create more' : ''
        }
      >
        <Button
          type="primary"
          onClick={() => setCreateApiModal(true)}
          disabled={disabled}
        >
          {name}
        </Button>
      </Tooltip>
      <Modal
        title="API"
        width={500}
        open={createApiModal}
        okText="Next"
        onOk={() => {
          if (selectedType === 'custom') {
            navigate(
              'organisations.organisationId.projects.projectId.api.new',
              {
                projectId,
                organisationId,
              }
            );
          } else {
            navigate(
              'organisations.organisationId.projects.projectId.api.templates',
              { projectId, organisationId }
            );
          }
        }}
        onCancel={() => setCreateApiModal(false)}
      >
        <Flex alignItems="flex-start" style={{ margin: '14px 0' }}>
          <CardContainer
            selected={selectedType === 'template'}
            onClick={() => setSelectedType('template')}
          >
            {selectedType === 'template' && (
              <BsCheckCircleFill className="selected" size={16} />
            )}
            <CgMenuGridO size={30} style={{ marginBottom: 10 }} />
            <Text style={{ marginBottom: 10 }} appearance="heading.paragraph">
              Browse Templates
            </Text>
            <Text appearance="caption.regular" color="neutral.darker">
              Templates defined by use-case and created with an empty public
              interface
            </Text>
          </CardContainer>
          <CardContainer
            selected={selectedType === 'custom'}
            onClick={() => setSelectedType('custom')}
          >
            {selectedType === 'custom' && (
              <BsCheckCircleFill className="selected" size={16} />
            )}
            <IoAdd size={30} style={{ marginBottom: 10 }} />
            <Text style={{ marginBottom: 10 }} appearance="heading.paragraph">
              Start from Scratch
            </Text>
            <Text appearance="caption.regular" color="neutral.darker">
              Create a REST API by scratch
            </Text>
          </CardContainer>
        </Flex>
      </Modal>
    </>
  );
};
