import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';
import { getPillColorStyles } from '../utils';

export const Container = styled(Flex)`
  flex-direction: column;
  width: '100%';
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  border-radius: ${({ theme }) => theme.constants.borderRadius.lg};
  width: 100%;

  & .flowItem:last-child {
    border: none;
  }

  & .flowItem {
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
    width: 100%;
    padding: 10px;
  }
`;

export const Pill = styled.div<{ type: string }>`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
  padding: 2px 4px;
  ${({ type }) => getPillColorStyles(type)}
`;

export const IconBase = styled.div`
  padding: 4px 6px;
  background-color: #d9d9d9;
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
`;
