import { Show, Text } from '@comet/blocks';
import { styled } from '@comet/styled';
import { CometNodeBaseProps } from '../../Nodes';

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.palette.neutral.darker};
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 600;
`;

const Title = styled.p`
  color: ${({ theme }) => theme.palette.neutral.darkest};
  font-size: 14px;
  margin: 0;
  font-weight: 600;
`;

const getSubTitle = (type: CometNodeBaseProps['type']) => {
  // TODO: Add subtitle format
  switch (type) {
    case 'SWITCH_NODE':
    case 'IF_NODE':
    case 'LOOP_NODE':
    case 'IF_NODE_V2':
      return 'General';
  }
  return type;
};

export const ActionNodeInfo = (props: CometNodeBaseProps) => {
  const subTitle = getSubTitle(props.type);

  return (
    <>
      <Text appearance="caption.allCaps" color="neutral.dark">
        {subTitle}
      </Text>
      <Text appearance="heading.paragraph">{props.data.nodeName}</Text>
      <Show if={!!props.data.displayName}>
        <Text appearance="caption.regular">{props.data.displayName}</Text>
      </Show>
    </>
  );
};
