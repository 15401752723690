import { Edge, Node } from 'reactflow';
import TreeBase from './tree.base';
import { IOptions } from './tree.types';

const VERTICAL_HEIGHT_PADDING = 100;
const SWITCH_NODE_HEIGHT_BUFFER = 20;

class TreeLayout extends TreeBase {
  constructor(nodes: Node[], edges: Edge[], options?: IOptions) {
    super(nodes, edges, options);
    this.createAdjacencyList();
  }

  // move the root node to center of plane (0,0)
  // and other nodes as well
  moveTreeToCenter = () => {
    const diffX =
      -this.xPosition[this.rootId!] - this.getWidth(this.rootId!) / 2;
    const diffY = -this.yPosition[this.rootId!];

    this.moveTree(diffX, diffY);
  };

  moveTree = (diffX: number, diffY: number) => {
    Object.keys(this.xPosition).forEach((id) => {
      this.xPosition[id] += diffX;
    });
    Object.keys(this.yPosition).forEach((id) => {
      this.yPosition[id] += diffY;
    });
  };

  assignLevel = (nodeId: string, level = 0) => {
    if (this.levelMap[nodeId] ?? -1 >= level) {
      return;
    }
    this.levelMap[nodeId] = level;
    this.levelHeight[level] = Math.max(
      this.getHeight(nodeId),
      this.levelHeight[level] ?? 0
    );

    const children = this.getChildren(nodeId);
    children.forEach((childId) => {
      this.assignLevel(childId, level + 1);
    });
  };

  assignXPosition = (nodeId: string, position: number) => {
    const level = this.levelMap[nodeId];
    const nodeWidth = this.getWidth(nodeId);
    let xPosition = 0;
    const border = this.getBorder(level);

    if (nodeId === this.rootId) {
      xPosition = -nodeWidth / 2;
    } else {
      if (border < position) {
        xPosition = position;
      } else {
        xPosition = border;
      }
    }

    const children = this.getChildren(nodeId);
    const childrenWidth = this.getTotalChildrenWidth(nodeId);
    const width = nodeWidth;
    let estimatedChildXPosition = xPosition + width / 2 - childrenWidth / 2;
    children.forEach((childId, childIndex) => {
      if (childIndex) {
        estimatedChildXPosition += this.xGap;
      }
      const actualChildXPosition = this.assignXPosition(
        childId,
        estimatedChildXPosition
      );
      const diff = actualChildXPosition - estimatedChildXPosition;
      if (diff) {
        xPosition += diff;
      }

      estimatedChildXPosition = actualChildXPosition + this.getWidth(childId);
      return actualChildXPosition;
    });

    // TODO:  probable fix center aligning all nodes wrt children
    // if (childrenPosition.length) {
    //   const l1 = childrenPosition[0] + this.getWidth(children[0]) / 2;
    //   const l2 =
    //     childrenPosition[childrenPosition.length - 1] +
    //     this.getWidth(children.pop()!) / 2;
    //   const w = (l1 + l2) / 2;
    //   const p = w - nodeWidth / 2;
    //   xPosition = p;
    // }

    this.setBorder(level, xPosition + nodeWidth + this.xGap);
    this.xPosition[nodeId] = xPosition;
    return xPosition;
  };

  assignYPosition = () => {
    const accumulatedLevelHeight: number[] = [];

    this.levelHeight.forEach((height, index) => {
      accumulatedLevelHeight[index] =
        height + (accumulatedLevelHeight[index - 1] || 0);
    });

    Object.keys(this.nodeMap).forEach((id) => {
      const level = this.levelMap[id];
      const node = this.nodeMap[id];
      this.yPosition[id] =
        (accumulatedLevelHeight[level - 1] || 0) +
        VERTICAL_HEIGHT_PADDING * level;

      // If node is switch node, then adjust height of the node to accomodate delete icon
      const parentNode = this.nodeMap[node?.data?.metadata?.['sourceId']];
      if (parentNode?.type === 'SWITCH_NODE') {
        this.yPosition[id] = this.yPosition[id] + SWITCH_NODE_HEIGHT_BUFFER; // If
      }
    });
  };

  centerAlignNode = (nodeId: string) => {
    const children = this.getChildren(nodeId);
    if (!children.length) return;

    const l1 = this.xPosition[children[0]] + this.getWidth(children[0]) / 2;
    const lastChild = children.pop()!;
    const l2 = this.xPosition[lastChild] + this.getWidth(lastChild) / 2;
    const w = (l1 + l2) / 2;
    const p = w - this.getWidth(nodeId) / 2;

    this.xPosition[nodeId] = p;
  };

  getNodes = () => {
    this.nodes.forEach((node, index) => {
      this.nodes[index].position = {
        x: this.xPosition[node.id],
        y: this.yPosition[node.id],
      };
    });

    return this.nodes;
  };

  getLayout = () => {
    this.assignLevel(this.rootId!);
    this.assignXPosition(this.rootId!, 0);
    this.assignYPosition();

    // quick fix for root node as easy and most evident
    this.centerAlignNode(this.rootId!);
    this.moveTreeToCenter();

    return {
      nodes: this.getNodes(),
      edges: this.edges,
    };
  };
}

export default TreeLayout;
