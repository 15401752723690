import { useLocation, useParams, useSearchParams } from '@comet/router';
import FlexItem from 'src/blocks/FlexItem';
import { SubflowDetailsForm } from './SubflowDetails.Form';
import { SubflowDetailsFlow } from './SubflowDetails.Flow';
import { Button, Flex, Form, PageHeader, Show } from '@comet/blocks';
import {
  updateSubflow,
  useGetSubflowQuery,
} from '@comet/pages/Project/Subflows/service';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useEffect, useRef, useState } from 'react';
import { NodeRaw, NodeTypeEnum } from 'src/FlowEngine2/types';
import { useFlowEngineStore as useFlowEngineV2Store } from 'src/FlowEngine2/store';
import { Subflow } from '@comet/pages/Project/Subflows/types';
import { queryClient, useMutation } from '@comet/query';
import { sendErrorNotification } from 'src/blocks/Notification';
import { notification } from 'antd';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

enum ViewEnum {
  Details = 'details',
  Flow = 'flow',
}

const SubflowDetails = () => {
  const [form] = Form.useForm();
  const { state } = useLocation();
  const { organisationId, projectId, subflowId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [version, setVersion] = useState(searchParams.get('version') || null);
  const [draft, setDraft] = useState(
    searchParams.get('draft') === 'true' || false
  );

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);

  const isSaveSubflowDisabled = !orgSubscriptionData?.active;

  const { hasEditApiAccess } = useProjectPermissions();
  const { resetNodesAndEdges } = useFlowEngineV2Store.getState();

  // This useEffect should be called at once on component loading
  useEffect(() => {
    // This will reset the graph state
    resetNodesAndEdges();

    // this is to initialize search params
    if (state) {
      setSearchParams((params) => ({
        ...params,
        version: state.version,
        draft: state.draft,
        view: ViewEnum.Details,
      }));
      setVersion(state.version);
      setDraft(state.draft);
    } else {
      setSearchParams((params) => ({
        ...params,
        version: version || null,
        draft: typeof draft === 'boolean' ? draft : null,
        view: ViewEnum.Details,
      }));
    }
  }, []);

  const {
    data: subflowData,
    isLoading: isSubflowLoading,
    error: subflowError,
    refetch: refetchSubflow,
  } = useGetSubflowQuery(projectId, subflowId, {
    version: version === 'null' ? null : version,
    draft,
  });

  const view = searchParams.get('view');
  const error = subflowError || !subflowData;

  const patch = useMutation({
    mutationFn: (subflowData: Partial<Subflow>) =>
      updateSubflow(projectId, subflowId, subflowData),
    onError: () =>
      sendErrorNotification({
        message: 'Error',
        description: 'There was an error in updating the Subflow.',
        reportBug: true,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useSubflowQuery', projectId, subflowId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      setSearchParams((params) => ({
        ...params,
        version: null,
        draft: true,
        view,
      }));
      setVersion(null);
      setDraft(true);
      refetchSubflow();
      notification.success({
        message: 'Success',
        description: 'Subflow Saved!',
      });
    },
  });

  const handleSave = () => {
    const formData = form.getFieldsValue();
    let metadata = {};
    const { allNodes, allEdges, rootNode } = useFlowEngineV2Store.getState();
    const nodesToSave = allNodes;
    const edgesToSave = allEdges;

    const finalNodes = [...nodesToSave].map((node: NodeRaw) => {
      const {
        id,
        type,
        data: { schema, returns, ...rest },
        parentNode,
        extent,
        position,
        parentId,
      } = node;

      const updatedType =
        type === NodeTypeEnum.IfElse || type === NodeTypeEnum.IfElseV2
          ? 'IF_NODE'
          : type;

      return {
        id,
        type: updatedType,
        data: rest,
        position: position ? position : { x: 0, y: 0 },
        parentNode,
        extent: extent ? extent : undefined,
        parentId,
      };
    });
    if (formData.functionArguments) {
      metadata = {
        ...metadata,
        functionArguments: formData.functionArguments.map((item: any) => {
          return {
            key: item.key,
            type: item.keyType,
            required: item.required,
            default: item.required ? null : item.default || null,
          };
        }),
      };
    }
    if (formData.functionReturnTypeFields) {
      metadata = {
        ...metadata,
        functionReturnTypeFields: formData.functionReturnTypeFields.map(
          (item: any) => {
            return {
              key: item.key,
              type: item.keyType,
              displayName: item.displayName,
            };
          }
        ),
      };
    }
    let modifiedData: any = {
      name: formData.name,
      description: formData.description,
      flowType: 'FUNCTION',
      flowMetadata: metadata,
    };
    if (finalNodes.length !== 0) {
      modifiedData = {
        ...modifiedData,
        nodes: finalNodes,
      };
    }
    if (edgesToSave.length !== 0) {
      modifiedData = {
        ...modifiedData,
        edges: edgesToSave,
      };
    }
    if (rootNode) {
      modifiedData = {
        ...modifiedData,
        rootNode,
      };
    }
    patch.mutate(modifiedData);
  };

  return (
    <Flex direction="column" height="100%">
      <Form
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={subflowData}
      >
        <PageHeader
          title={`Subflow - ${subflowData?.name || 'Subflow details'}`}
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              loading={patch.isLoading}
              onClick={handleSave}
              disabled={!hasEditApiAccess || isSaveSubflowDisabled}
            >
              Save
            </Button>
          }
          tabs={[
            {
              title: 'Details',
              active: view === ViewEnum.Details,
              onClick: () =>
                setSearchParams((params) => ({
                  ...params,
                  version,
                  draft,
                  view: ViewEnum.Details,
                })),
              id: 'details',
            },
            {
              title: 'Flow',
              active: view === ViewEnum.Flow,
              onClick: () =>
                setSearchParams((params) => ({
                  ...params,
                  version,
                  draft,
                  view: ViewEnum.Flow,
                })),
              id: 'flow',
            },
          ]}
        />
        <Show if={!!subflowData && !error && view === ViewEnum.Details}>
          <SubflowDetailsForm
            data={subflowData}
            form={form}
            hasEditAccess={!!hasEditApiAccess}
          />
        </Show>
        <Show if={!!subflowData && view === ViewEnum.Flow}>
          <FlexItem grow={2}>
            <SubflowDetailsFlow
              edges={subflowData?.edges || []}
              nodes={subflowData?.nodes || []}
              hasEditAccess={!!hasEditApiAccess}
              flowId={subflowId}
              rootNode={subflowData?.rootNode || ''}
            />
          </FlexItem>
        </Show>
      </Form>
    </Flex>
  );
};

export default SubflowDetails;
