import {
  Button,
  ButtonContainer,
  Divider,
  Flex,
  Form,
  FormItem,
  Input,
  InputHelpText,
  InputUrl,
  Paper,
  Select,
  Switch,
  Text,
} from '@comet/blocks';
import { styled } from '@comet/styled';
import { TextArea } from 'src/blocks/TextArea';

import { getModelOptions, useEndpointValidator } from './CreateApiForm.utils';

import { useParams, useGoBack, useNavigate } from '@comet/router';
import { ApiDetailsRaw, CreateApiFormData, RequestMethodEnum } from '../Types';
import { useModelsQuery } from '@comet/pages/Model/service/model.service';
import { ModelTypesEnum } from '@comet/pages/Model/types';
import { queryClient, useMutation } from '@comet/query';
import { createApi } from '../Service';
import { useState } from 'react';
import debounce from 'lodash.debounce';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { ExternalLink } from '@comet/components/ExternalLink';
import { MdOpenInNew } from 'react-icons/md';
import { AxiosError } from 'axios';
import { sendErrorNotification } from 'src/blocks/Notification';

const FormContainer = styled(Paper)`
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px 24px 16px 24px;
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  border-radius: 6px;
`;

const initialFormValue: {
  requestMethod: RequestMethodEnum;
} = {
  requestMethod: RequestMethodEnum.GET,
};

export const CreateApi = () => {
  const [form] = Form.useForm();
  const { organisationId, projectId } = useParams();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const { hasEditApiAccess } = useProjectPermissions();

  const createApiMutation = useMutation({
    mutationFn: (ApiData: ApiDetailsRaw) => createApi(projectId, ApiData),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: ['useGetProjectQuery', organisationId, projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      navigate(
        'organisations.organisationId.projects.projectId.api.apiId.details',
        {
          apiId: id,
          version: 'draft',
        },
        {
          state: {
            draft: true,
            version: null,
          },
        }
      );
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          sendErrorNotification({
            message: 'Error',
            description:
              'API with the same endpoint and request method already exists',
          });
          return;
        }
      }

      sendErrorNotification({
        message: 'Error',
        description: 'There was an error in creating the API',
        reportBug: true,
      });
    },
  });

  const [requestBodyParameters, setRequestBodyParameters] = useState({
    model_type: ModelTypesEnum.REQUEST_BODY,
    version: null,
    query: '',
  });

  const [queryParamsParameters, setQueryParamsParameters] = useState({
    model_type: ModelTypesEnum.QUERY_PARAMS,
    version: null,
    query: '',
  });

  const onQueryParamsSearch = debounce((value: string) => {
    setQueryParamsParameters((queryParamsParameters) => ({
      ...queryParamsParameters,
      query: value,
    }));
  }, 500);

  const onRequestBodySearch = debounce((value: string) => {
    setRequestBodyParameters((requestBodyParameters) => ({
      ...requestBodyParameters,
      query: value,
    }));
  }, 500);

  const { data: queryParamsResponse, isLoading: isQueryParamasLoading } =
    useModelsQuery(projectId, queryParamsParameters);

  const { data: requestBodyResponse, isLoading: isRequestBodyLoading } =
    useModelsQuery(projectId, requestBodyParameters);

  const queryModelOptions = getModelOptions(queryParamsResponse?.data || []);
  const requestBodyOptions = getModelOptions(requestBodyResponse?.data || []);
  const { endpoint, isValidEndpoint, setEndpoint } = useEndpointValidator();
  const [fieldErrors, setFieldErrors] = useState({
    api: '',
    endpoint: '',
  });

  const onCreateApi = async (formData: CreateApiFormData) => {
    const {
      name,
      description,
      endpoint,
      queryParams,
      requestBody,
      requestMethod,
      authenticated,
    } = formData;

    if (!endpoint) {
      setFieldErrors((errors) => ({
        ...errors,
        endpoint: 'Please enter the endpoint',
      }));
    }

    if (!name) {
      setFieldErrors((errors) => ({
        ...errors,
        api: 'Please enter the API name',
      }));
    }

    if (!endpoint || !name) return;

    const ApiParams: ApiDetailsRaw = {
      name,
      description: description || '',
      flowType: 'API',
      flowMetadata: {
        endpoint,
        queryParams,
        requestBody,
        requestMethod,
        authenticated,
      },
    };

    createApiMutation.mutate(ApiParams);
  };

  const cancelApiCreation = () => {
    goBack();
  };

  if (!hasEditApiAccess) {
    return <ErrorPage />;
  }

  return (
    <FormContainer size="medium">
      <Form
        initialValues={initialFormValue}
        form={form}
        layout="vertical"
        onFinish={onCreateApi}
      >
        <Flex alignItems="baseline" gap={15} justifyContent="space-between">
          <Text style={{ marginBottom: 24 }} appearance="heading.card">
            Create a new API
          </Text>
          <ExternalLink
            href="https://docs.cosmocloud.io/cosmocloud-documentation/setup/6.-create-apis"
            appearance="caption.semibold"
          >
            <MdOpenInNew />
            Learn More
          </ExternalLink>
        </Flex>
        <FormItem
          name="name"
          label="API name"
          extra={<InputHelpText error={fieldErrors.api} />}
          required
        >
          <Input
            placeholder="Type here..."
            onChange={() =>
              setFieldErrors((errors) => ({ ...errors, api: '' }))
            }
          />
        </FormItem>
        <FormItem name="description" label="Description">
          <TextArea
            placeholder="Add a description to make it easier for your teammates to discover and use the right version of your API..."
            maxLength={240}
            rows={4}
            showCount
          />
        </FormItem>
        <FormItem
          labelWidth={200}
          name="requestMethod"
          label="Request method"
          required
        >
          <Select
            placeholder="Select a method"
            style={{ width: 200 }}
            options={Object.values(RequestMethodEnum).map((v) => ({
              value: v,
              label: v,
            }))}
          />
        </FormItem>
        <FormItem
          name="endpoint"
          label="End point"
          extra={
            <InputHelpText
              error={
                fieldErrors.endpoint ||
                (!isValidEndpoint &&
                  'Syntax error: please enter correct endpoint')
              }
              success={isValidEndpoint && endpoint && 'Endpoint is correct'}
            >
              {'Syntax example: /order/{orderID}/...'}
            </InputHelpText>
          }
          required
        >
          <InputUrl
            value={endpoint}
            onChange={(value) => {
              setEndpoint(value);
              setFieldErrors((errors) => ({ ...errors, endpoint: '' }));
            }}
          />
        </FormItem>
        <FormItem name="queryParams" label="Query Model">
          <Select
            showSearch={true}
            loading={isQueryParamasLoading}
            placeholder="Select a model"
            style={{ width: 200 }}
            onSearch={onQueryParamsSearch}
            options={queryModelOptions}
            allowClear
          />
        </FormItem>
        <FormItem labelWidth={200} name="requestBody" label="Request Body">
          <Select
            showSearch={true}
            loading={isRequestBodyLoading}
            placeholder="Select"
            style={{ width: 200 }}
            onSearch={onRequestBodySearch}
            options={requestBodyOptions}
            allowClear
          />
        </FormItem>
        <FormItem
          name="authenticated"
          label="Authenticated"
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </FormItem>
        <Divider spread={24} />
        <ButtonContainer>
          <Button
            onClick={cancelApiCreation}
            appearance="transparent"
            htmlType="button"
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            htmlType="submit"
            loading={createApiMutation.isLoading}
          >
            Create
          </Button>
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};
