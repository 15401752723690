import {
  Card as AntdCard,
  Button,
  Flex,
  Form,
  FormItem,
  Input,
} from '@comet/blocks';
import { styled } from '@comet/styled';

import {
  useGetOrgQuoteQuery,
  useGetOrgSubscriptionQuery,
  useHandleCouponMutation,
} from '@comet/pages/Organisation/service';

import {
  useGetSubscriptionFeatures,
  useGetUsedCouponsTable,
  useGetEnvData,
} from '@comet/pages/Payments/payment.utils';

import { Table as AntdTable } from 'antd';
import { useParams } from '@comet/router';
import SubscriptionAlert from '@comet/pages/Payments/PlanExpiredBanner';

const Payments = () => {
  const [form] = Form.useForm();
  const { organisationId } = useParams();
  const { data } = useGetOrgSubscriptionQuery(organisationId);
  const {
    data: quoteData,
    isError,
    error,
  } = useGetOrgQuoteQuery(organisationId);

  const { couponsColumns, couponsData } = useGetUsedCouponsTable();
  const { featureColumns, featureData } = useGetSubscriptionFeatures();
  const { envFeatures, envData } = useGetEnvData();

  const couponMutation = useHandleCouponMutation();

  const applyCoupon = () => {
    const couponCode = form.getFieldValue('couponCode');
    if (!couponCode) {
      return;
    }
    couponMutation.mutate(couponCode);
  };

  couponMutation.isSuccess && form.resetFields();

  return (
    <Container>
      {/* if payment is overdue  */}
      {!data?.active && <SubscriptionAlert />}

      {/* if user have requested an upgrade  */}
      {!!quoteData && (
        <SubscriptionAlert
          title="Complete your Payment"
          content="Pay you dues to activate reuqested resources"
        />
      )}
      <Flex gap={10} direction="column">
        <Flex gap={10}>
          <Flex direction="column" gap={10}>
            <Card size="default">
              <Title>Upgrade Your Project</Title>
              <Text>
                Contact our sales team to learn about additional features and
                pricing options.
              </Text>
              <Button size="middle">Contact Sales</Button>
            </Card>
            <Card style={{ height: '100%' }}>
              <div>
                <Title>Your Plan Benefits</Title>
                <Text>
                  See what's included in your current subscription plan.
                </Text>
              </div>
              <div>
                <Table
                  columns={featureColumns}
                  dataSource={featureData}
                  pagination={false}
                />
              </div>
            </Card>
          </Flex>
          <Flex direction="column" gap={10}>
            <Card>
              <div className="space-y-4">
                <Title>Redeem Your Coupon</Title>
                <Text>
                  Enter your coupon code below to apply a discount to your
                  project.
                </Text>
                <Form form={form} onFinish={applyCoupon}>
                  <FormItem
                    name="couponCode"
                    marginBottom={0}
                    rules={[
                      { required: true, message: 'It is required' },
                      { min: 5, message: 'Minimum 5 characters required' },
                    ]}
                  >
                    <Flex gap={2}>
                      <Input
                        type="text"
                        placeholder="Enter coupon code"
                        style={{ width: 'auto', fontSize: '15px' }}
                      />
                      <Button type="primary" htmlType="submit">
                        Apply
                      </Button>
                    </Flex>
                  </FormItem>
                </Form>
              </div>
            </Card>
            <Card size="default" style={{ height: '100%' }}>
              <div>
                <Title>Recently Applied Coupons</Title>
                <Text>View a list of all the coupons that you have used</Text>
              </div>
              <div>
                <Table
                  columns={couponsColumns}
                  dataSource={couponsData}
                  pagination={false}
                  style={{ width: '100%' }}
                />
              </div>
            </Card>
          </Flex>
        </Flex>

        <Flex>
          <Card style={{ width: '100%' }}>
            <div className="space-y-4">
              <Title>Environment specific access</Title>
              <Text>
                Checkout the access level of your environment and mangage your
                services accordingly
              </Text>
            </div>
            <div>
              <Table
                columns={envFeatures}
                dataSource={envData}
                pagination={false}
                style={{ width: '100%' }}
              />
            </div>
          </Card>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Payments;

const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
  color: #1f2937;
`;

const Text = styled.p`
  color: #9ca3af;
  font-size: 1rem; /* text-base */
`;

const Table = styled(AntdTable)`
  .ant-table-thead > tr > th {
    background-color: #f9fafb;
    color: #6b7280;
    font-size: 1rem;
  }
`;

const Container = styled.div`
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
`;

const Card = styled(AntdCard)`
  border-color: #e5e7eb;
`;
