import {
  Button,
  Flex,
  Modal,
  Text,
  Dropdown,
  TableHeader,
  Tooltip,
} from '@comet/blocks';
import { useCallback, useMemo, useState } from 'react';
import { styled, useTheme } from '@comet/styled';
import { Column } from 'react-table';
import { GetSearchIndexesResponse, IAnalyzer, ICustomAnalyzer } from '../types';
import { Link, useParams } from '@comet/router';
import { FiMoreHorizontal } from 'react-icons/fi';
import { deleteSearchIndex } from '../service';
import { RiAlertLine } from 'react-icons/ri';
import { format } from 'date-fns';
import { useModelQuery, useModelsQuery } from '@comet/pages/Model/service';
import { Field } from '@comet/pages/Model/types';
import { useGetAnalyzersQuery } from '../../Analyzer/service';
import {
  InbuiltAnalyzers,
  SearchIndexStatusMapping,
} from '../SearchIndex.constants';
import { useConfigQuery } from '@comet/pages/Project/Config/service';
import { DatabaseConfigData } from '@comet/pages/Project/Config/types';
import { StatusComponent } from '@comet/components/TableComponents';
import _ from 'lodash';
import set from 'lodash.set';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

export const DeleteSearchIndex = ({
  id,
  refetch,
  disabled,
}: {
  id: string;
  disabled: boolean;
  refetch: () => void;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await deleteSearchIndex(projectId, id);
    refetch();
    toggleModal();
  }, [id, projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip
                  title={disabled ? 'This search index is getting used' : ''}
                >
                  Delete
                </Tooltip>
              ),
              onClick: toggleModal,
              disabled,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this search index?{' '}
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this search index.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useSearchIndexListingData = (
  searchIndexData: GetSearchIndexesResponse | undefined,
  refetch: () => void
) => {
  const { organisationId, projectId } = useParams();
  const { theme } = useTheme();

  const tableData = useMemo(() => {
    if (!searchIndexData) return [];

    return searchIndexData.data.map((item) => {
      const statusText = getStatus(item.dynamic);
      const statusColor =
        _.get(theme, SearchIndexStatusMapping[statusText][1]) ||
        SearchIndexStatusMapping[statusText][1];

      return {
        name: (
          <Link
            routeId="organisations.organisationId.projects.projectId.search-indexes.searchIndexId"
            pathParam={{ organisationId, projectId, searchIndexId: item.id }}
          >
            {item.name}
          </Link>
        ),
        model: item.modelData.name,
        mappingType: (
          <StatusComponent color={statusColor}>
            {item.dynamic ? (
              <Tooltip
                title={`Field mappings are dynamic in this Search Index.`}
              >
                Dynamic
              </Tooltip>
            ) : (
              <Tooltip
                title={`Field mappings are static in this Search Index.`}
              >
                Static
              </Tooltip>
            )}
          </StatusComponent>
        ),
        updatedBy: item.updatedBy.name,
        updatedOn: formatTime(item.updatedOn),
        id: (
          <DeleteSearchIndex id={item.id} disabled={false} refetch={refetch} />
        ),
      };
    });
  }, [searchIndexData, organisationId, projectId, refetch, theme]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" />,
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Model" />,
        accessor: 'model',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Mapping Type" />,
        accessor: 'mappingType',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated by" />,
        accessor: 'updatedBy',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated on" />,
        accessor: 'updatedOn',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
      },
    ];
  }, []);

  return {
    tableData,
    tableColumns,
  };
};

function getStatus(dynamic: boolean) {
  return dynamic ? 'TRUE' : 'FALSE';
}

export const useGetDatabseCollectionOptions = () => {
  const { projectId } = useParams();

  const { data } = useModelsQuery(projectId, {
    version: null,
    model_type: 'DATABASE_COLLECTION',
  });

  return useMemo(() => {
    if (!data) return [];

    return data.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [data]);
};

function extractFieldInfo(field: Field, prefix = '', subType = false) {
  const fieldName = prefix + field.name;
  const fieldType = field.type;
  const fieldSubtype = field.subType as string;

  return {
    name: fieldName,
    type: !subType && !fieldSubtype ? fieldType : fieldSubtype,
  };
}

function extractFields(fields: Field[], prefix = '') {
  let fieldInfo: { name: string; type: string }[] = [];
  for (const field of fields) {
    if (
      (field.type === 'NESTED' || field.type === 'LIST') &&
      !!field.properties
    ) {
      const nestedFields = field.properties;
      const nestedPrefix = prefix + field.name + '.';
      fieldInfo = fieldInfo.concat(extractFields(nestedFields, nestedPrefix));
    } else if (field.type === 'LIST' && !field.properties) {
      fieldInfo.push(extractFieldInfo(field, prefix, true));
    } else {
      fieldInfo.push(extractFieldInfo(field, prefix));
    }
  }
  return fieldInfo;
}

export const getFieldOptions = (fields: Field[] = []) => {
  const extractedFields = extractFields(fields);
  const fieldOptions = extractedFields.map((field) => {
    return {
      label: field.name,
      value: field.name,
    };
  });

  const fieldMappings: Record<string, string> = extractedFields.reduce(
    (acc, field) => {
      acc[field.name] = field.type;
      return acc;
    },
    {} as Record<string, string>
  );

  return { fieldMappings, fieldOptions };
};

export const useGetFieldsOptions = (modelId: string) => {
  const { projectId } = useParams();

  const { data } = useModelQuery(
    projectId,
    modelId,
    {
      version: null,
    },
    !!modelId
  );

  return useMemo(() => {
    if (!data)
      return { fieldOptions: [], fieldMappings: {} as Record<string, string> };

    return getFieldOptions(data.fields || []);
  }, [data]);
};

export const useAnalyzerOptions = () => {
  const { data } = useGetAnalyzersQuery();

  const analyzerOptions = useMemo(() => {
    if (!data) return InbuiltAnalyzers;

    return [
      ...InbuiltAnalyzers,
      ...data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      }),
    ];
  }, [data]);

  const analyzerType: Record<string, string> = useMemo(() => {
    const map = InbuiltAnalyzers.reduce((acc, item) => {
      acc[item.value] = 'BUILT_IN';
      return acc;
    }, {} as Record<string, string>);

    return (
      data?.data?.reduce((acc, item) => {
        acc[item.id] = 'CUSTOM';
        return acc;
      }, map) || map
    );
  }, [data?.data]);

  return { analyzerOptions, analyzerType };
};

// Todo remove this if not used
export const useShowSplashScreen = () => {
  const { projectId } = useParams();

  const { data, isLoading, isError } = useConfigQuery(
    projectId,
    'DATABASE_CONFIG'
  );

  return {
    isLoading,
    showSplashScreen:
      isError || !data || (data.data as DatabaseConfigData).mode === 'db_only',
  };
};

export const MODEL_TO_MONGODB_ATLAS_MAP: Record<string, string> = {
  OBJECT_ID: 'objectId',
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  FLOAT: 'number',
};
const MONGODB_ATLAS_TO_MODEL_MAP: Record<string, string> = {
  objectId: 'OBJECT_ID',
  string: 'STRING',
  number: 'NUMBER',
  boolean: 'BOOLEAN',
};
const TEXT_ANALYZERS = ['searchAnalyzer', 'analyzer'];

const getAnalyzer = (
  analyzerObj: IAnalyzer,
  analyzerAdded: Record<string, ICustomAnalyzer>,
  customAnalyzersData: Array<Record<string, any>>
) => {
  // # analyzer is present only for STRING type fields
  let analyzerName;
  if (analyzerObj['type'] == 'BUILT_IN') {
    analyzerName = analyzerObj['value'];
  } else if (analyzerObj['type'] == 'CUSTOM') {
    const customAnalyzerData = customAnalyzersData.find(
      (analyzer) => analyzer.id === analyzerObj['value']
    );
    if (customAnalyzerData) {
      analyzerName = customAnalyzerData?.['name'];

      analyzerAdded[analyzerName] = {
        name: analyzerName,
        charFilters: customAnalyzerData['charFilters'] ?? [],
        tokenizer: customAnalyzerData['tokenizer'],
        tokenFilters: customAnalyzerData['tokenFilters'] ?? [],
      };
    }
  }
  return { analyzerName, analyzerAdded };
};

const getFieldData = (
  field: Record<string, any>,
  analyzerAdded: Record<string, ICustomAnalyzer>,
  customAnalyzersData: Array<Record<string, any>>,
  separatedByDots = true
) => {
  const fieldName = field['name'];
  const terms = separatedByDots ? fieldName.split('.') : [fieldName];
  let analyzerAddedFinal = analyzerAdded;

  let fieldData: Record<string, any> = {
    type: field['type'],
  };

  let analyzerName, searchAnalyzerName;
  if (
    (field['type'] == 'autocomplete' || field['type'] == 'string') &&
    field['analyzer']
  ) {
    const analyzerDetails = getAnalyzer(
      field['analyzer'],
      analyzerAddedFinal,
      customAnalyzersData
    );
    analyzerName = analyzerDetails.analyzerName;
    analyzerAddedFinal = analyzerDetails.analyzerAdded;
  }
  if (field['type'] == 'string' && field['searchAnalyzer']) {
    const searchAnalyzerDetails = getAnalyzer(
      field['searchAnalyzer'],
      analyzerAddedFinal,
      customAnalyzersData
    );
    searchAnalyzerName = searchAnalyzerDetails.analyzerName;
    analyzerAddedFinal = searchAnalyzerDetails.analyzerAdded;
  }
  fieldData = {
    ...fieldData,
    ...(analyzerName ? { analyzer: analyzerName } : {}),
    ...(searchAnalyzerName ? { searchAnalyzer: searchAnalyzerName } : {}),
  };

  if (
    (field['type'] == 'autocomplete' || field['type'] == 'string') &&
    field['multi']
  ) {
    fieldData = {
      ...fieldData,
      multi: {},
    };
    for (const multiField of field['multi']) {
      const {
        fieldName: multiFieldName,
        fieldData: multiFieldData,
        analyzerAdded: analyzerAddedNew,
      } = getFieldData(
        { ...multiField, type: field['type'] },
        analyzerAdded,
        customAnalyzersData,
        false
      );
      analyzerAdded = analyzerAddedNew;
      fieldData['multi'] = {
        ...fieldData['multi'],
        [multiFieldName]: multiFieldData[multiFieldName],
      };
    }
  }
  let root: Record<string, any> = {};
  if (terms.length > 1) {
    let currentPath = undefined;
    for (const term of terms.slice(0, -1)) {
      currentPath = currentPath ? `${currentPath}.fields.${term}` : term;
      set(root, currentPath, {
        type: 'document',
        fields: {},
      });
    }
    currentPath = currentPath
      ? `${currentPath}.fields.${terms.at(-1)}`
      : terms.at(-1);
    set(root, currentPath, fieldData);
  } else {
    root = { [fieldName]: fieldData };
  }

  return { fieldName, fieldData: root, analyzerAdded: analyzerAddedFinal };
};

export const formatFieldMappingsAndAnalyzers = (
  mappings: Record<string, any>,
  customAnalyzersData: Array<Record<string, any>>
) => {
  if (!mappings?.fields) {
    return undefined;
  }
  let mappingFields: Record<string, any> = {};
  let analyzerAdded: Record<string, ICustomAnalyzer> = {};
  for (const item of mappings['fields']) {
    if (!item) {
      continue;
    }
    const { fieldData, analyzerAdded: analyzerAddedNew } = getFieldData(
      item,
      analyzerAdded,
      customAnalyzersData
    );
    analyzerAdded = analyzerAddedNew;
    mappingFields = _.merge(mappingFields, fieldData);
  }
  const analyzers = Object.values(analyzerAdded);
  const updatedMappings: Record<string, any> = { dynamic: mappings['dynamic'] };

  if (!mappings['dynamic']) updatedMappings['fields'] = mappingFields;

  return { mappings: updatedMappings, analyzers }; //[updatedMappings, analyzers, fieldNames];
};

const parseMappingRecursively = (
  formattedMapping: Record<string, any>,
  parsedMapping: Record<string, any>,
  currentPath = ''
) => {
  const fields = Object.keys(formattedMapping);
  for (const field of fields) {
    const currentField = formattedMapping[field];
    if (currentField.type === 'document') {
      const { type, ...rest } = currentField;
      parseMappingRecursively(
        currentField.fields,
        parsedMapping,
        currentPath + field + '.'
      );
    } else {
      let multi = undefined;
      if (currentField.multi) {
        const multiValue = Object.entries(currentField.multi)[0] as Array<
          Record<string, any>
        >;
        multi = {
          name: multiValue[0],
          searchAnalyzer: {
            type: InbuiltAnalyzers.find(
              (analyzer) => multiValue[1].searchAnalyzer === analyzer.value
            )
              ? 'BUILT_IN'
              : 'CUSTOM',
            value: multiValue[1].searchAnalyzer,
          },
          analyzer: {
            type: InbuiltAnalyzers.find(
              (analyzer) => multiValue[1].analyzer === analyzer.value
            )
              ? 'BUILT_IN'
              : 'CUSTOM',
            value: multiValue[1].analyzer,
          },
        };
      }
      parsedMapping.fields.push({
        name: currentPath + field,
        type: currentField.type,
        searchAnalyzer: {
          type: InbuiltAnalyzers.find(
            (analyzer) => currentField.searchAnalyzer === analyzer.value
          )
            ? 'BUILT_IN'
            : 'CUSTOM',
          value: currentField.searchAnalyzer,
        },
        analyzer: {
          type: InbuiltAnalyzers.find(
            (analyzer) => currentField.analyzer === analyzer.value
          )
            ? 'BUILT_IN'
            : 'CUSTOM',
          value: currentField.analyzer,
        },
        multi,
      });
      currentPath = '';
    }
  }
};

export const parseFieldMappingAndAnalyzers = (
  fieldMapping: Record<string, any>
) => {
  const parsedMapping = {
    dynamic: fieldMapping.dynamic,
    fields: [] as Array<Record<string, any>>,
  };
  if (!parsedMapping.dynamic) {
    parseMappingRecursively(fieldMapping.fields, parsedMapping, '');
    /* if (fieldNames.length === 0) {
    } else {
      for (let fieldName of fieldNames) {
        const currentFieldMapping = get(fieldMapping.fields, fieldName);
        let fieldDetails: Record<string, any> = {
          name: fieldName.split('.').filter(name => name !== 'fields').join('.'),
          type: currentFieldMapping.type,
        };
        if (
          (currentFieldMapping.type === 'string' ||
            currentFieldMapping.type === 'autocomplete') &&
          currentFieldMapping.analyzer
        ) {
          fieldDetails = {
            ...fieldDetails,
            analyzer: {
              type: InbuiltAnalyzers.find(
                (analyzer) => currentFieldMapping.analyzer === analyzer.value
              )
                ? 'BUILT_IN'
                : 'CUSTOM',
              value: currentFieldMapping.analyzer,
            },
          };
        }
        if (
          currentFieldMapping.type === 'string' &&
          currentFieldMapping.searchAnalyzer
        ) {
          fieldDetails = {
            ...fieldDetails,
            searchAnalyzer: {
              type: InbuiltAnalyzers.find(
                (analyzer) =>
                  currentFieldMapping.searchAnalyzer === analyzer.value
              )
                ? 'BUILT_IN'
                : 'CUSTOM',
              value: currentFieldMapping.searchAnalyzer,
            },
          };
        }
        if (
          (currentFieldMapping.type === 'string' ||
            currentFieldMapping.type === 'autocomplete') &&
          currentFieldMapping.multi
        ) {
          fieldDetails = {
            ...fieldDetails,
            multi: Object.entries(
              currentFieldMapping.multi as Record<string, any>
            ).map(([multiKey, multiDetail]) => ({
              name: multiKey,
              searchAnalyzer: {
                type: InbuiltAnalyzers.find(
                  (analyzer) => multiDetail.searchAnalyzer === analyzer.value
                )
                  ? 'BUILT_IN'
                  : 'CUSTOM',
                value: multiDetail.searchAnalyzer,
              },
              analyzer: {
                type: InbuiltAnalyzers.find(
                  (analyzer) => multiDetail.analyzer === analyzer.value
                )
                  ? 'BUILT_IN'
                  : 'CUSTOM',
                value: multiDetail.analyzer,
              },
            })),
          };
        }
        parsedMapping.fields.push(fieldDetails);
      }
    } */
  }
  return parsedMapping;
};
