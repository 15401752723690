import { ChangeEvent, useState } from 'react';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { RxCross2, RxCheck } from 'react-icons/rx';
import { styled } from '@comet/styled';
import { Flex, FormItem, Show, Text, TextArea } from '@comet/blocks';
interface IEditableTextAreaProps {
  value: string;
  onSave: (value: string) => void;
  name: string;
  disabled?: boolean;
}

const StyledText = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const StyledEditIcon = styled(MdOutlineModeEditOutline)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.neutral.darker};
`;

const StyledCrossIcon = styled(RxCross2)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  cursor: pointer;
`;

const StyledCheckIcon = styled(RxCheck)`
  color: ${({ theme }) => theme.palette.accent.green.dark};
  cursor: pointer;
`;

export const EditableTextArea = ({
  value,
  onSave,
  name,
  disabled,
}: IEditableTextAreaProps) => {
  const [text, setText] = useState(value);
  const [editing, setEditing] = useState(false);

  const toggleEdit = () => {
    setEditing((editing) => !editing);
  };

  const onSaveChanges = () => {
    onSave(text);
    toggleEdit();
  };

  const onDiscardChanges = () => {
    setText(value);
    toggleEdit();
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  return (
    <Flex gap={6} justifyContent="space-between">
      <Show if={!editing}>
        <StyledText appearance="body.regular" color="neutral.darkest">
          {value}
        </StyledText>
        <Flex hidden={!!disabled}>
          <StyledEditIcon onClick={toggleEdit} />
        </Flex>
      </Show>
      <Flex hidden={!editing} width="100%">
        <FormItem name={name} marginBottom={0} style={{ width: '100%' }}>
          <TextArea
            value={text}
            rows={4}
            style={{ maxWidth: '100%', resize: 'none' }}
            onChange={handleInputChange}
          />
        </FormItem>
        <Flex>
          <StyledCrossIcon onClick={onDiscardChanges} />
          <StyledCheckIcon onClick={onSaveChanges} />
        </Flex>
      </Flex>
    </Flex>
  );
};
