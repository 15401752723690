import { useState } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams } from '@comet/router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getAxios } from '@comet/axios';
import { STEPS } from '../Types';
import { queryClient } from '@comet/query';
import { sendErrorNotification } from 'src/blocks/Notification';

export const useApiTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [step, setStep] = useState<STEPS>(STEPS.SELECT);

  const navigate = useNavigate();
  const { projectId, organisationId } = useParams();

  const [template, setTemplate] = useState<ITemplate>();

  const [values, setValues] = useState<Record<string, any>>({});

  const [apiCreateData, setApiCreateData] = useState<{
    modelItemTypes: string[];
    apiItemTypes: string[];
  }>({
    modelItemTypes: [],
    apiItemTypes: [],
  });

  const { data: templates = [], isLoading } = useQuery({
    queryKey: ['/metadata/templates'],
    queryFn: async () => {
      try {
        const request = await getAxios().get<{ data: ITemplate[] }>(
          '/metadata/templates'
        );
        return request.data?.data;
      } catch (error) {
        if (error instanceof AxiosError) {
          sendErrorNotification({
            message: 'Error',
            description: error.message,
          });
        }
      }
    },
  });

  const {
    mutateAsync,
    data,
    isLoading: reviewLoading,
  } = useMutation({
    mutationKey: ['review', step],
    mutationFn: async ({
      values,
      type,
    }: {
      values: Record<string, any>;
      type: string;
    }): Promise<IReviewTemplate> => {
      try {
        const request = await getAxios().post<IReviewTemplate>(
          `/resources/${projectId}/templates/review`,
          {
            type,
            values,
          }
        );
        return request.data;
      } catch {
        return { flows: [], models: [] };
      }
    },
    onSuccess: (data) => {
      const _flows = data.flows.map((flow) => flow.itemType);
      const _models = data.models.map((model) => model.itemType);
      setApiCreateData((prev) => ({
        ...prev,
        modelItemTypes: _models,
        apiItemTypes: _flows,
      }));
    },
  });

  const { mutate: completeReview } = useMutation({
    mutationKey: ['/create', selectedTemplate],
    mutationFn: async () => {
      const request = await getAxios().post(
        `/resources/${projectId}/templates`,
        { type: selectedTemplate, values, ...apiCreateData }
      );

      if (request.status === 409) {
        sendErrorNotification({
          message: 'Error',
          description: 'Selected APIs/Models already exist',
        });
        return;
      }

      return request.data;
    },
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Apis/Models created successfully',
      });

      queryClient.invalidateQueries({
        queryKey: ['useGetProjectQuery', organisationId, projectId],
      });

      navigate('organisations.organisationId.projects.projectId.api', {
        projectId,
        organisationId,
      });
    },
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          sendErrorNotification({
            message: 'Error',
            description:
              error.response.data.message || 'Unable to create Model',
          });
          return;
        }
      }

      sendErrorNotification({
        message: 'Error',
        description: 'There was an error in creating the APIs/Models',
        reportBug: true,
      });
    },
  });

  const onCancel = () => {
    if (step === STEPS.SELECT) {
      navigate('organisations.organisationId.projects.projectId.api', {
        organisationId,
        projectId,
      });
    }
    if (step === STEPS.CONFIGURE) {
      setStep(STEPS.SELECT);
    }

    if (step === STEPS.REVIEW) {
      setStep(STEPS.CONFIGURE);
    }
  };

  const onNext = async () => {
    if (step === STEPS.SELECT) {
      setStep(STEPS.CONFIGURE);
      const _template = templates.find(
        (item) => item.type === selectedTemplate
      );
      if (!_template) return;
      setTemplate(_template);
    }

    if (step === STEPS.CONFIGURE) {
      setStep(STEPS.REVIEW);
      await mutateAsync({ type: selectedTemplate, values: values });
    }

    if (step === STEPS.REVIEW) {
      completeReview();
    }
  };

  const onNodeFormFieldChange = (key: string, changed: any) => {
    const _value = values;
    if (!_value) return;
    _value[key] = changed;
    setValues(_value);
  };

  const addOrRemove = (item: string, array: string[]) => {
    if (array.includes(item)) {
      array.splice(array.indexOf(item), 1);
      return;
    }
    array.push(item);
  };

  const onCheckItem = (item: string, type: 'flow' | 'model') => {
    const _apidata = { ...apiCreateData };
    if (type === 'flow') {
      addOrRemove(item, _apidata.apiItemTypes);
    } else if (type === 'model') {
      addOrRemove(item, _apidata.modelItemTypes);
    }

    setApiCreateData(_apidata);
  };

  const loading = isLoading || reviewLoading;

  return {
    apiCreateData,
    data,
    selectedTemplate,
    setSelectedTemplate,
    step,
    template,
    templates,
    loading,
    onNext,
    onNodeFormFieldChange,
    onCancel,
    onCheckItem,
    values,
  };
};
