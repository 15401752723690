import { EnvironmentTierEnum, EnvironmentTypesEnum } from '../types';

export const PAGE_SIZE = 10;

export const EnvironmentTypesOptions = [
  { value: EnvironmentTypesEnum.PRODUCTION, label: 'Production' },
  { value: EnvironmentTypesEnum.STAGING, label: 'Staging' },
  // { value: EnvironmentTypesEnum.CUSTOM, label: 'Custom' },
];

export const ResourceTypesOptions = [
  {
    value: false,
    label: 'Dedicated',
  },
  { value: true, label: 'Shared' },
];

export const EnvironmentTiersOptions = [
  {
    value: EnvironmentTierEnum.MICRO_0,
    label: 'Micro 0',
  },
  { value: EnvironmentTierEnum.MICRO_1, label: 'Micro 1' },
  {
    value: EnvironmentTierEnum.MINI_0,
    label: 'Mini 0',
  },
  { value: EnvironmentTierEnum.MINI_1, label: 'Mini 1' },

  {
    value: EnvironmentTierEnum.SMALL_0,
    label: 'Small 0',
  },
  { value: EnvironmentTierEnum.SMALL_1, label: 'Small 1' },
  {
    value: EnvironmentTierEnum.MEDIUM_0,
    label: 'Medium 0',
  },
  { value: EnvironmentTierEnum.MEDIUM_1, label: 'Medium 1' },
  { value: EnvironmentTierEnum.ENTERPRISE, label: 'Enterprise' },
];

export const DraftStateOption = [
  { label: 'Created', value: 'CREATED' },
  { label: 'Switched to Shared', value: 'SWITCHED_TO_SHARED' },
  { label: 'Switched to Dedicated', value: 'SWITCHED_TO_DEDICATED' },
  { label: 'Updated Tier', value: 'UPDATED_TIER' },
];

export const EnvironmentChangeMapping = {
  CREATED: 'Created',
  SWITCHED_TO_SHARED: 'Switched to Shared',
  SWITCHED_TO_DEDICATED: 'Switched to Dedicated',
  UPDATED_TIER: 'Updated Tier',
  UPDATED_SHARED_ENV_ID: 'Updated Shared Environment ID',
  DELETED: 'Deleted',
};

export const EnvironmentStateMapping = {
  DEPLOYED: ['Deployed', 'palette.accent.blue.light'],
  INACTIVE: ['Inactive', '#D16E4E'],
};

export const ENVIRONMENT_NAME_REGEX = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
