import {
  Button,
  Center,
  Checkbox,
  Flex,
  FullCenter,
  Show,
  Text,
} from '@comet/blocks';
import { styled, useTheme } from '@comet/styled';
import { UseQueryResult } from '@tanstack/react-query';
import { Collapse, Result, Skeleton } from 'antd';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { GetDraftsResponse } from '../../types/Projects.types';

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

type SelectedValues = {
  [key: string]: boolean;
};
interface ReleaseElementProps {
  elementName: string;
  draftsQueryResult: UseQueryResult<GetDraftsResponse>;
  selectedValues: SelectedValues;
  setSelectedValues: (
    fn: (currentSelectedValues: SelectedValues) => SelectedValues
  ) => void;
  isActive?: boolean;
}

const ReleaseElement = ({
  elementName,
  draftsQueryResult,
  selectedValues,
  setSelectedValues,
  isActive,
}: ReleaseElementProps) => {
  const { theme } = useTheme();
  const { isLoading, error, refetch, data: drafts } = draftsQueryResult;

  const handleCheckboxChange = (id: string) => {
    setSelectedValues((currentSelectedValues: SelectedValues) => {
      if (currentSelectedValues[id]) {
        const { [id]: _, ...restIds } = currentSelectedValues;
        return restIds;
      } else {
        return { ...currentSelectedValues, [id]: true };
      }
    });
  };

  if (isLoading)
    return (
      <Skeleton
        title={isLoading}
        paragraph={{ rows: 1, width: '100%' }}
        loading={true}
        active
      />
    );

  if (error || !drafts) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Collapse
      expandIcon={(props) => {
        return props.isActive ? <IoIosArrowUp /> : <IoIosArrowDown />;
      }}
      {...(isActive && { defaultActiveKey: ['1'] })}
      expandIconPosition="end"
    >
      <StyledPanel
        header={<Text appearance="caption.allCaps">{elementName}</Text>}
        key="1"
        style={{ padding: '0px !important' }}
      >
        <Show if={drafts.data.length === 0}>
          <Center>
            <Text appearance="body.semiBold" style={{ margin: '16px' }}>
              {`There are no draft ${elementName.toLowerCase()}`}
            </Text>
          </Center>
        </Show>
        {drafts.data?.map((draft) => {
          return (
            <Flex
              gap={10}
              style={{
                padding: '12px 14px',
                cursor: 'pointer',
                borderBottom: '1px solid #EEEEEE',
              }}
              key={draft.id}
              alignItems="start"
              onClick={() => handleCheckboxChange(draft.id)}
            >
              <Checkbox checked={Boolean(selectedValues[draft.id])} />
              <Flex direction="column" gap={5} style={{ paddingTop: 2 }}>
                <Text appearance="label.long.semiBold">{draft.name}</Text>
                <Text
                  appearance="caption.semibold"
                  style={{ color: theme.palette.neutral.darker }}
                >
                  {draft.description}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </StyledPanel>
    </Collapse>
  );
};

export default ReleaseElement;
