import { Button, Flex, Modal, Paper, Text } from '@comet/blocks';
import { RiAlertLine } from 'react-icons/ri';
import { styled } from '@comet/styled';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { checkDeletePossible, deleteOrg, useOrgQuery } from '../service';
import { useNavigate, useParams } from '@comet/router';
import { notification } from 'antd';
import { queryClient } from '@comet/query';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';
import { sendErrorNotification } from 'src/blocks/Notification';
import DeleteOrganisationModal from './DeleteOrganisationModal';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

export const Settings = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const navigate = useNavigate();
  const { organisationId } = useParams();

  const { data: organisationData } = useOrgQuery(organisationId);

  const { hasManageOrgSettingsAccess } = useOrgPermissions();
  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };
  const orgDeleteMutation = useMutation({
    mutationFn: (organisationId: string) => deleteOrg(organisationId),
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Organisation is deleted successfully',
      });
      queryClient.invalidateQueries({
        queryKey: ['useOrgsQuery'],
      });
      queryClient.invalidateQueries({
        queryKey: ['useOrgQuery', organisationId],
      });
      navigate('organisations.new');
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'There was an error while deleting the organisation!',
        reportBug: true,
      });
    },
  });

  const handleOnClick = async () => {
    const canBeDeleted = await checkDeletePossible(organisationId);
    if (canBeDeleted) {
      toggleModal();
    } else {
      sendErrorNotification({
        message: 'Pending bills',
        description:
          'You have active subscription, contact support to delete the organisation',
        redirectUrl: 'https://cal.com/shreybatra/upgrading-cosmocloud',
        duration: 7,
      });
    }
  };

  return (
    <>
      <Paper size="medium">
        <Flex direction="column" gap={20}>
          <Flex gap={10} direction="column">
            <Text appearance="heading.card">Delete Organisation </Text>
            <Text appearance="label.short.regular">
              Remove this organisation from your account in a single click - no
              hassle, no fuss.
            </Text>
          </Flex>
          <Flex justifyContent="end">
            <Button
              onClick={handleOnClick}
              appearance="alert"
              disabled={!hasManageOrgSettingsAccess}
            >
              {' '}
              Delete
            </Button>
          </Flex>
        </Flex>
      </Paper>
      {isConfirm && !!organisationData && (
        <DeleteOrganisationModal
          organisationData={organisationData}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};
