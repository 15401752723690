import { styled } from '@comet/styled';
import { TbMail } from 'react-icons/tb';

const StyledFooter = styled.footer`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding-bottom: 84px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.neutral.white};
  font-size: 12px;
`;

const HelpItem = styled.a`
  display: flex;
  align-items: center;
  text-decoration: unset;
  color: ${({ theme }) => theme.palette.neutral.white};
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <HelpItem href="mailto:support@cosmocloud.io">
        <TbMail />
        &nbsp; support@cosmocloud.io
      </HelpItem>
    </StyledFooter>
  );
};
