import {
  Button,
  Flex,
  Form,
  FormItem,
  Input,
  Label,
  Select,
  Show,
  Switch,
  Text,
} from '@comet/blocks';
import { useState } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { AuthTypeOptions, SchemaTypeOption } from '../../../constants';
import { ScrollDiv } from '../../../secrets.styled';
import { useFilterFieldValidator } from '@comet/components/DynamicFields/utils';
import { ExternalLink } from '@comet/components/ExternalLink';
import { ShareIcon } from '@comet/pages/Project/Config/Config.styled';
import { AuthTypesEnum } from '../../../Types';

export const ConfigureAuthentication = () => {
  const [authEnabled, setAuthEnabled] = useState(false);
  const [authType, setAuthType] = useState(AuthTypesEnum.JWKS_URI);
  const [nextStepEnabled, setNextStepEnabled] = useState(false);
  const fieldValidator = useFilterFieldValidator(
    setNextStepEnabled,
    'tokenData',
    (field, value) => (field as any).key === value
  );

  return (
    <ScrollDiv>
      <Text appearance="label.long.semiBold">Authentication</Text>

      <Flex alignItems="center" justifyContent="end" gap={6}>
        Enabled
        <FormItem
          name={['data', 'enabled']}
          marginBottom={0}
          initialValue={false}
        >
          <Switch
            onChange={(value: boolean) => {
              setAuthEnabled(value);
            }}
            checked={authEnabled}
          />
        </FormItem>
      </Flex>

      <Flex direction="column">
        <FormItem
          name={['data', 'authType']}
          label={<Text appearance="label.long.semiBold">Secret Auth Type</Text>}
          required
          rules={[{ required: true, message: 'Auth type is required' }]}
          marginBottom={20}
        >
          <Select
            placeholder="Select Auth Types"
            onSelect={(e) => setAuthType(e)}
            options={AuthTypeOptions}
            value={authType}
          />
        </FormItem>
      </Flex>

      <Show if={authType === AuthTypesEnum.JWKS_URI}>
        <Flex direction="column">
          <FormItem
            label={<Text appearance="label.long.semiBold">JWKS URI</Text>}
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 10 }}
            >
              The JWK URI should point to a JSON Web Key or JSON Web Key Set
              that will be used to verify the signature in a user’s JWT.
            </Text>
          </FormItem>
          <FormItem
            colon={false}
            name={['data', 'jwksURI']}
            rules={[
              {
                required: true,
                message: 'JWKS URI is required!',
              },
            ]}
          >
            <Input placeholder="Enter JWKS URI" name="jwksURI" type="url" />
          </FormItem>
        </Flex>
      </Show>
      <Show if={authType === AuthTypesEnum.ACCESS_KEY_SECRET}>
        <Flex direction="column">
          <FormItem
            label={
              <Text appearance="label.long.semiBold">Access Key Secret</Text>
            }
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 10 }}
            >
              The secret used to decode and validate the incoming JWT token.
            </Text>
          </FormItem>
          <FormItem
            colon={false}
            name={['data', 'accessKeySecret']}
            rules={[
              {
                required: true,
                message: 'Access key is required!',
              },
            ]}
          >
            <Input placeholder="Enter Access key" name="accessKeySecret" />
          </FormItem>
        </Flex>
      </Show>
      <Show if={authType === AuthTypesEnum.JWKS_URI}>
        <Flex direction="column">
          <FormItem
            label={<Text appearance="label.long.semiBold">Audience</Text>}
            colon={false}
            marginBottom={0}
            required
          >
            <Text
              appearance="caption.longText"
              color="neutral.dark"
              style={{ marginBottom: 10 }}
            >
              Specify which audience (aud) field is expected to be found in your
              JWT. Your app will validate that the ‘aud’ field is set to this
              value when validating the JWT instead of your app ID.
            </Text>
          </FormItem>
          <FormItem
            name={['data', 'audience']}
            required
            rules={[
              {
                message: 'Audience is required!',
              },
            ]}
          >
            <Input placeholder="Enter Audience" />
          </FormItem>
        </Flex>
      </Show>

      <Flex direction="column">
        <FormItem
          label={<Text appearance="label.long.semiBold">User ID Key</Text>}
          marginBottom={0}
          required
        >
          <Text
            appearance="caption.longText"
            color="neutral.dark"
            style={{ marginBottom: 10 }}
          >
            Specify the key in which the user's ID would be found after decoding
            the token (for eg. `userId`)
          </Text>
        </FormItem>
        <FormItem
          name={['data', 'userKey']}
          required
          rules={[
            {
              message: 'UserID Key is required!',
            },
          ]}
        >
          <Input placeholder="Enter UserId Key" />
        </FormItem>
      </Flex>

      <Label>
        <Text appearance="label.long.semiBold" style={{ marginBottom: 10 }}>
          Custom Attributes in Auth Token
        </Text>
        <Text
          appearance="caption.longText"
          color="neutral.dark"
          style={{ marginBottom: 10 }}
        >
          Define the custom attributes present in the Auth Token which could be
          reused and accessed inside an API.
          <ExternalLink
            href="https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/secrets#authentication-secret"
            appearance="caption.semibold"
          >
            <ShareIcon />
            <Text appearance="caption.semibold">
              Learn more about how to setup authentication.
            </Text>
          </ExternalLink>
        </Text>
      </Label>
      <Form.List name={'tokenData'}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flex key={field.key} justifyContent="space-between">
                <Form.Item
                  name={[field.name, 'key']}
                  style={{ width: '45%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input key or delete this field.',
                    },
                    fieldValidator,
                  ]}
                >
                  <Input placeholder="Enter key" />
                </Form.Item>
                <Form.Item
                  name={[field.name, 'value']}
                  style={{ width: '45%' }}
                  rules={[
                    {
                      required: true,
                      message: 'Please select a option',
                    },
                    fieldValidator,
                  ]}
                >
                  <Select
                    placeholder="Select param type"
                    options={SchemaTypeOption}
                  />
                </Form.Item>
                <Flex justifyContent="center">
                  <Button
                    icon={<AiOutlineMinusCircle />}
                    onClick={() => remove(field.name)}
                  />
                </Flex>
              </Flex>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={add} style={{ width: '100%' }}>
                <AiOutlinePlusCircle /> Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </ScrollDiv>
  );
};
