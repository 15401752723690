import { Flex, FormItem, Show, Switch, Text, Tabs } from '@comet/blocks';
import { FormInstance } from 'antd';
import { DynamicFields } from '../DynamicFields';
import { Editor, EditorProps } from '@monaco-editor/react';
import { useGetAnalyzersQuery } from '../../Analyzer/service';
import {
  formatFieldMappingsAndAnalyzers,
  parseFieldMappingAndAnalyzers,
} from '../utils';
import { useMemo, useRef, useState } from 'react';

interface SearchIndexMappingProps {
  modelId: string;
  form: FormInstance;
  dynamic: boolean;
  onToggleDynamic: () => void;
}

export const SearchIndexMappings = ({
  modelId,
  form,
  dynamic,
  onToggleDynamic,
}: SearchIndexMappingProps) => {
  const [analyzersLength, setAnalyzersLength] = useState(1);
  const editorRef = useRef<any>(null);
  const handleEditorDidMount: EditorProps['onMount'] = (editor, monaco) => {
    editorRef.current = editor;
    editor.createDecorationsCollection([
      {
        range: new monaco.Range(1, 0, analyzersLength, 0), // Block analyzers array lines
        options: {
          isWholeLine: true,
          className: 'editor-line-readonly',
        },
      },
    ]);
    // Disable line editing (handled from the CSS side but here as well to be safe)
    editor.onKeyDown((e) => {
      const isInBlockedRange =
        editor
          .getSelections()
          ?.findIndex((range) =>
            new monaco.Range(1, 0, analyzersLength + 1, 0).intersectRanges(
              range
            )
          ) !== -1; // Block analyzers array lines
      if (isInBlockedRange) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
  };
  const { data: analyzersData } = useGetAnalyzersQuery();

  const analyzers = useMemo(() => {
    if (!analyzersData?.data) {
      return [];
    }
    const analyzersArray = analyzersData?.data.map((analyzer) => ({
      name: analyzer.name,
      charFilters: analyzer['charFilters'] ?? [],
      tokenizer: analyzer['tokenizer'],
      tokenFilters: analyzer['tokenFilters'] ?? [],
    }));
    setAnalyzersLength(
      JSON.stringify(analyzersArray, null, '\t').split('\n').length
    );
    return analyzersArray;
  }, [analyzersData?.data]);

  const [formattedFieldMapping, setFormattedFieldMapping] = useState<
    undefined | Record<string, any>
  >(undefined);

  const formatFieldMapping = () => {
    if (!analyzersData?.data) {
      return undefined;
    }
    const formattedMappingAndAnalyzers = formatFieldMappingsAndAnalyzers(
      form.getFieldsValue(),
      analyzersData?.data
    );
    if (formattedMappingAndAnalyzers) {
      const { mappings } = formattedMappingAndAnalyzers;
      setFormattedFieldMapping({ analyzers, ...mappings });
    }
  };
  const parseFieldMapping = () => {
    if (formattedFieldMapping) {
      const parsedMapping = parseFieldMappingAndAnalyzers(
        formattedFieldMapping
      );
      // form.setFieldsValue({ ...formData, ...parsedMapping });
      setTimeout(() => {
        form.resetFields();
      }, 100);
    }
  };

  return (
    <Flex direction="column" gap={10}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" justifyContent="end" gap={6}>
          Dynamic
          <FormItem name="dynamic" marginBottom={0}>
            <Switch onChange={onToggleDynamic} checked={dynamic} />
          </FormItem>
        </Flex>
      </Flex>
      <Show if={!dynamic}>
        <Flex direction="column" gap={10}>
          <Text appearance="label.short.regular">Field Mappings</Text>
          <DynamicFields form={form} modelId={modelId} />
        </Flex>
        <Tabs
          hidden // will implement it later
          onChange={(activeKey) => {
            if (activeKey === 'json') {
              formatFieldMapping();
            } else {
              parseFieldMapping();
            }
          }}
          defaultActiveKey="visual"
          centered
          items={[
            {
              label: `Visual Editor`,
              key: 'visual',
              children: (
                <Flex direction="column" gap={10}>
                  <Text appearance="label.short.regular">Field Mappings</Text>
                  <DynamicFields form={form} modelId={modelId} />
                </Flex>
              ),
            },
            {
              label: `JSON Editor`,
              key: 'json',
              children: (
                <Flex direction="column" gap={10}>
                  <Editor
                    defaultLanguage="json"
                    language="json"
                    defaultValue={`{}`}
                    className="json_editor"
                    onChange={(value) => {
                      if (value) {
                        setFormattedFieldMapping(JSON.parse(value));
                      }
                    }}
                    options={{
                      hideCursorInOverviewRuler: false,
                      minimap: { enabled: false },
                      scrollBeyondLastLine: false,
                      fontSize: 16,
                    }}
                    value={JSON.stringify(
                      formattedFieldMapping ?? { analyzers },
                      null,
                      '\t'
                    )}
                    height={'60vh'}
                    onMount={handleEditorDidMount}
                  />
                </Flex>
              ),
            },
          ]}
        />
      </Show>
    </Flex>
  );
};
