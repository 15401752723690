import { BackendNode, BusinessNodeEnum, NodeRaw } from '../../types';

import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';

export const useGetFlowQuery = (
  projectId: string,
  flowId: string,
  dirtyParams?: any,
  enabled?: boolean
) => {
  const url = `/resources/${projectId}/flows/${flowId}`;

  const params: any = {};
  if (dirtyParams?.version === 'draft' || !dirtyParams?.version) {
    params.draft = true;
  } else {
    params.version = dirtyParams?.version;
  }

  return useQuery({
    queryKey: ['useGetflowQuery', projectId, flowId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<any>(url, {
        params,
      });
      return data;
    },
    enabled: enabled,
  });
};

export function useGetNodes(
  cloudProvider: string | undefined,
  flowType: string | undefined
) {
  const { data, error, status } = useQuery({
    queryKey: ['nodes'],
    queryFn: async () => {
      return getNodes(cloudProvider || 'AWS', flowType || 'API');
    },
    cacheTime: 0,
  });

  return { data, error, status };
}

async function getNodes(cloudProvider: string, flowType: string) {
  const url = '/metadata/nodes';
  const { data } = await getAxios().get<{ data: BackendNode[] }>(url, {
    params: { cloud: cloudProvider, scope: flowType, limit: 10000 },
  });

  const blocks = data.data.map((block: BackendNode) => {
    if (
      block.data.type === BusinessNodeEnum.IF_ELSE ||
      block.data.type === BusinessNodeEnum.IF_ELSE_V2
    ) {
      return { ...block, type: block.data.type, id: null, parentId: null };
    } else {
      if (!block.data.values) {
        block.data.values = {};
      }
      if (!block.data.metadata) {
        block.data.metadata = {};
      }

      return { ...block, id: null, parentId: null };
    }
  });

  return blocks as unknown as NodeRaw[];
}
