import { create } from 'zustand';
import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  addEdge,
  OnNodesChange,
  OnEdgesChange,
  OnConnect,
  applyNodeChanges,
  applyEdgeChanges,
  OnNodesDelete,
} from 'reactflow';
import { BusinessNodeProps } from './Nodes/BusinessNode';
import { Graph } from 'src/types/common';

export type EditorNode = { id: string; data: BusinessNodeProps };

export type FlowEngineState = {
  nodes: Node[];
  edges: Edge[];
  setGraph: (
    fn: (nodes: Node[], edges: Edge[]) => { nodes: Node[]; edges: Edge[] }
  ) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  editorNode: null | EditorNode;
  setEditorNode: (node: FlowEngineState['editorNode']) => void;
  getGraph: () => Graph;
  onDelete?: OnNodesDelete;
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
export const useFlowEngineStore = create<FlowEngineState>((set, get) => ({
  editorNode: null,
  setEditorNode: (editorNode) => {
    set({ editorNode });
  },
  nodes: [],
  edges: [],
  setGraph: (fn) => {
    const { nodes, edges } = fn(get().nodes, get().edges);
    set({ nodes, edges });
  },
  getGraph: () => {
    return { nodes: get().nodes, edges: get().edges };
  },
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    });
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    });
  },
}));
