import {
  Button,
  Center,
  Flex,
  Form,
  FullCenter,
  PageHeader,
  Paper,
  Show,
} from '@comet/blocks';
import { Result, Spin } from 'antd';
import { useStorageAccountMutation, useStorageAccountQuery } from '../service';

import { StorageAccountUpdateFormData } from '../types/StorageAccount.types';
import { UpdateStorageAccountForm } from './UpdateStoragAccountForm';
import { useParams } from '@comet/router';

export const StorageAccountDetails = () => {
  const [form] = Form.useForm();
  const { projectId, storageAccountId } = useParams();
  const {
    data: storageAccount,
    isLoading: isStorageAccountLoading,
    isError: isStorageAccountError,
    refetch,
  } = useStorageAccountQuery(projectId, storageAccountId);

  const error = isStorageAccountError || !storageAccount;
  const updateStorageAccountMutation = useStorageAccountMutation();

  if (isStorageAccountLoading) {
    return (
      <FullCenter>
        <Flex direction="column">
          <Spin size="large" />
        </Flex>
      </FullCenter>
    );
  }
  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetch();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  const handleSave = (data: StorageAccountUpdateFormData) => {
    const updateData: StorageAccountUpdateFormData = {
      spaceInBytes: data.spaceInBytes,
    };
    updateStorageAccountMutation.mutate(updateData);
  };

  return (
    <Flex direction="column">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={storageAccount}
        onFinish={handleSave}
      >
        <PageHeader
          title="Bucket details"
          primaryAction={
            <Button appearance="primary" htmlType="submit">
              Save
            </Button>
          }
        />
        <Paper size="medium">
          <Show if={!!storageAccount && !error}>
            <UpdateStorageAccountForm
              name={storageAccount.name}
              space={
                storageAccount.size
                  ? storageAccount.size
                  : storageAccount.draftState?.size
              }
              status={storageAccount.status}
              createdOn={storageAccount.createdOn}
              updatedOn={storageAccount.updatedOn}
              createdBy={storageAccount.createdBy.name}
              updatedBy={storageAccount.updatedBy.name}
              environment={storageAccount.environmentData.name}
              usedSize={storageAccount.usedSize}
              form={form}
            />
          </Show>
        </Paper>
      </Form>
    </Flex>
  );
};
