import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';

export const useGetSwaggerSpecQuery = (projectId: string, version: string) => {
  const url = `/resources/${projectId}/documentations/${version}`;

  return useQuery({
    queryKey: ['useGetSwaggerSpecQuery', projectId, version],
    queryFn: async () => {
      const data = await getAxios().get(url);

      return data.data;
    },
  });
};
