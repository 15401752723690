import { FooterTypeEnum } from './types';

export const defaultProps = {
  [FooterTypeEnum.Default]: {
    primaryButtonText: 'Save',
    secondaryButtonText: 'Cancel',
    onClickPrimaryButton: () => {},
    onClickSecondaryButton: () => {},
  },
  [FooterTypeEnum.Directional]: {
    primaryButtonText: 'Next',
    tertiaryButtonText: 'Go Back',
    onClickPrimaryButton: () => {},
    onClickTertiaryButton: () => {},
  },
  [FooterTypeEnum.ThreeButtons]: {
    primaryButtonText: 'Next',
    secondaryButtonText: 'Save as Draft',
    tertiaryButtonText: 'Cancel',
    onClickPrimaryButton: () => {},
    onClickSecondaryButton: () => {},
    onClickTertiaryButton: () => {},
  },
  [FooterTypeEnum.ThreeButtonsLink]: {
    primaryButtonText: 'Save',
    secondaryButtonText: 'Cancel',
    tertiaryLinkText: 'View Documentation',
    onClickPrimaryButton: () => {},
    onClickSecondaryButton: () => {},
    onClickTertiaryLink: () => {},
  },
};
