import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

import { NodeRaw } from 'src/FlowEngine2/types';
import { addNodeFromAddNode } from './addNodeFromNode';
import { addNodeFromEdge } from './addNodeFromEdge';

export function addLoopNode(
  type: 'ADD_NODE' | 'EDGE', // A Node can be added either from add_node or edge
  id: string | null, // id can be of node or edge
  nodeToAdd: NodeRaw | null
) {
  if (!id && !nodeToAdd) return;
  const { createNode } = useFlowEngineStore.getState();

  const loopNode = getNodeById(createNode(nodeToAdd));
  if (!loopNode) return new Error(`Error in creating For Node`);

  switch (type) {
    case 'ADD_NODE': {
      addNodeFromAddNode(id, loopNode, [loopNode]);
      break;
    }

    case 'EDGE': {
      addNodeFromEdge(id, loopNode, [loopNode]);
      break;
    }
  }
}
