import { memo } from 'react';
import { Wrapper } from '../../common/styled';
import { styled } from '@comet/styled';
import { Flex } from '@comet/blocks';
import { Handle, Position } from 'reactflow';
import { CometNodeBaseProps, NodeRaw, NodeTypeEnum } from '../..';
import NodeExplorerPopup from '../../../NodeExplorer/NodeExplorerPopup';

const DottedWrapper = styled(Wrapper)`
  border: 1px dashed gray;
  color: gray;
`;

type IProps = CometNodeBaseProps;

export const AddNodeDimension = {
  height: 40,
  width: 80,
};

const AddNode = ({ data, ...props }: IProps) => {
  const handleBlockClick = (rawNode: NodeRaw) => {
    data.nodeOperations(
      {
        id: props.id,
        type: props.type,
        position: { x: props.xPos, y: props.yPos },
        data: { ...data, metadata: null },
      },
      rawNode,
      'replaceNode'
    );
  };

  return (
    <NodeExplorerPopup onClick={handleBlockClick}>
      <DottedWrapper {...props} {...AddNodeDimension}>
        <Handle type="target" position={Position.Top} />
        <Handle type="source" position={Position.Bottom} />
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          +
        </Flex>
      </DottedWrapper>
    </NodeExplorerPopup>
  );
};

export default memo(AddNode);
