import { useLocation, useParams } from '@comet/router';
import {
  Center,
  Flex,
  FullCenter,
  Spin,
  Form,
  Button,
  PageHeader,
  Paper,
  Show,
} from '@comet/blocks';
import { Result } from 'antd';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { UpdateDatabaseInstancesForm } from './UpdateDatabase.Form';
import { Database } from '../types';
import { useGetDatabaseQuery } from '../service';

export const DatabaseDetails = () => {
  const { projectId, instanceId } = useParams();
  const location = useLocation();
  const stateData = location.state;
  const [form] = Form.useForm();

  const {
    data: instance,
    isLoading: isInstanceLoading,
    isError,
    refetch: refetchInstances,
  } = useGetDatabaseQuery(projectId, stateData.envId, instanceId);

  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const handleSave = (data: Database) => {
    return;
  };

  if (isInstanceLoading) {
    return (
      <FullCenter>
        <Flex direction="column">
          <Spin size="large" />
        </Flex>
      </FullCenter>
    );
  }

  if (isError) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchInstances();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex direction="column">
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={instance}
      >
        <PageHeader
          title="Secret Details"
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              onClick={() => {
                handleSave(form.getFieldsValue());
              }}
              disabled={!hasManageProjectSettingsAccess}
            >
              Save
            </Button>
          }
        />
        <Paper size="medium">
          <Show if={!!instance && !isError}>
            <UpdateDatabaseInstancesForm {...instance} />
          </Show>
        </Paper>
      </Form>
    </Flex>
  );
};
