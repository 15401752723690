import {
  FieldTypeEnum,
  Schema,
  Values,
} from '../FlowEngine/Nodes/BusinessNode';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { Flex, Show } from '@comet/blocks';
import { NodeFormField } from './NodeFormField';
import { getNodeById } from 'src/FlowEngine2/store';
import { useFlowEngineStore } from '../FlowEngine/FlowEngine.store';
import { useReactFlow } from 'reactflow';

interface IProps {
  schema: Schema;
  values: Values;
  onChange: (key: string, value: any) => void;
}

export const NodeEditor = ({
  schema,
  values,
  onChange,
}: IProps): ReactElement => {
  const { editorNode } = useFlowEngineStore();
  const [state, setState] = useState<Values>(values || {});
  const { getNode } = useReactFlow();
  const handleChange = useCallback(
    (key: string, value: any) => {
      setState((prevState) => ({ ...prevState, [key]: value }));

      onChange(key, value);
    },
    [onChange]
  );

  const dependsOnTrue = (
    dependsOn: { key: string; value: string[] } | undefined
  ) => {
    if (!dependsOn) return true;
    if (dependsOn.value.includes(state[dependsOn.key])) return true;
    return false;
  };

  useEffect(() => {
    if (!editorNode) return;
    const node = getNodeById(editorNode.id);
    if (node) setState(node.data.values!);
  }, [editorNode]);

  if (!editorNode) return <></>;
  const node = getNodeById(editorNode.id);

  return (
    <Flex key={editorNode?.id} direction="column" gap={20}>
      {schema.map((subSchema, index) => (
        <Flex key={index} direction="column" gap={16}>
          <h3>{subSchema.displayName}</h3>
          {subSchema.displayName === 'General' && (
            <Flex direction="column">
              <h4>Node name</h4>
              <div>
                <NodeFormField
                  fieldKey={'nodeName'}
                  type={FieldTypeEnum.INPUT}
                  value={node?.data.nodeName}
                  onChange={handleChange}
                  disabled={false}
                  editorNode={editorNode}
                />
              </div>
            </Flex>
          )}
          {subSchema.fields.map((field, itemIndex) => (
            <Show if={!!dependsOnTrue(field?.dependsOn)}>
              <Flex direction="column" key={itemIndex}>
                <h4>{field.displayName}</h4>
                <div>
                  <NodeFormField
                    resolver={field.resolver}
                    options={field.values}
                    fieldKey={field.key}
                    type={field.type}
                    value={state[field.key]}
                    onChange={handleChange}
                    disabled={field.immutable}
                    editorNode={editorNode}
                    placeholder={field.placeholder}
                  />
                </div>
              </Flex>
            </Show>
          ))}
        </Flex>
      ))}
    </Flex>
  );
};
