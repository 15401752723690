import {
  AnalyzerTypes,
  CharacterFilterInputType,
  CharacterFilterTypes,
  SnowBallStemmingType,
  TokenFilterInputFieldType,
  TokenFilterTypes,
  TokenizerInputType,
} from '../types';

export const DynamicTokenizerInputMapping: Record<
  AnalyzerTypes,
  TokenizerInputType[]
> = {
  edgeGram: [
    {
      name: 'minGram',
      displayName: 'Min gram',
      type: 'number',
      required: true,
      min: 1,
    },
    {
      name: 'maxGram',
      displayName: 'Max gram',
      type: 'number',
      required: true,
      min: 1,
    },
  ],
  keyword: [],
  nGram: [
    {
      name: 'minGram',
      displayName: 'Min gram',
      type: 'number',
      required: true,
      min: 1,
    },
    {
      name: 'maxGram',
      displayName: 'Max gram',
      type: 'number',
      required: true,
      min: 1,
    },
  ],
  regexCaptureGroup: [
    {
      name: 'pattern',
      displayName: 'Pattern',
      type: 'text',
      required: true,
    },
    {
      name: 'group',
      displayName: 'Group',
      type: 'number',
      required: true,
      min: 1,
    },
  ],
  regexSplit: [
    {
      name: 'pattern',
      displayName: 'Pattern',
      type: 'text',
      required: true,
    },
  ],
  standard: [
    {
      name: 'maxTokenLength',
      displayName: 'Max token length',
      type: 'number',
      required: false,
      max: 255,
      min: 1,
    },
  ],
  uaxUrlEmail: [
    {
      name: 'maxTokenLength',
      displayName: 'Max token length',
      type: 'number',
      required: false,
      max: 255,
      min: 1,
    },
  ],
  whitespace: [
    {
      name: 'maxTokenLength',
      displayName: 'Max token length',
      type: 'number',
      required: false,
      max: 255,
      min: 1,
    },
  ],
};

export const DynamicCharacterFilterInputMapping: Record<
  CharacterFilterTypes,
  CharacterFilterInputType
> = {
  htmlStrip: {
    displayName: 'Ignored Tags',
    name: 'ignoredTags',
    required: false,
    type: 'stringArray',
  },
  icuNormalize: undefined,
  mapping: {
    displayName: 'Mappings',
    name: 'mappings',
    required: true,
    type: 'object',
  },
  persian: undefined,
};

export const AnalyzerTypesOptions = [
  { label: 'Edge Gram', value: 'edgeGram' },
  { label: 'Keyword', value: 'keyword' },
  { label: 'NGram', value: 'nGram' },
  { label: 'Regex Capture Group', value: 'regexCaptureGroup' },
  { label: 'Regex Split', value: 'regexSplit' },
  { label: 'Standard', value: 'standard' },
  { label: 'Uax Url Email', value: 'uaxUrlEmail' },
  { label: 'Whitespace', value: 'whitespace' },
];

export const CharacterFilterTypesOptions = [
  { label: 'HTML Strip', value: 'htmlStrip' },
  { label: 'ICU Normalize', value: 'icuNormalize' },
  { label: 'Mapping', value: 'mapping' },
  { label: 'Persian', value: 'persian' },
];

export const TokenFilterTypesOptions = [
  {
    label: 'asciiFolding',
    value: 'asciiFolding',
  },
  {
    label: 'daitchMokotoffSoundex',
    value: 'daitchMokotoffSoundex',
  },
  {
    label: 'edgeGram',
    value: 'edgeGram',
  },
  {
    label: 'englishPossessive',
    value: 'englishPossessive',
  },
  {
    label: 'flattenGraph',
    value: 'flattenGraph',
  },
  {
    label: 'icuFolding',
    value: 'icuFolding',
  },
  {
    label: 'icuNormalizer',
    value: 'icuNormalizer',
  },
  {
    label: 'kStemming',
    value: 'kStemming',
  },
  {
    label: 'length',
    value: 'length',
  },
  {
    label: 'lowercase',
    value: 'lowercase',
  },
  {
    label: 'nGram',
    value: 'nGram',
  },
  {
    label: 'porterStemming',
    value: 'porterStemming',
  },
  {
    label: 'regex',
    value: 'regex',
  },
  {
    label: 'reverse',
    value: 'reverse',
  },
  {
    label: 'shingle',
    value: 'shingle',
  },
  {
    label: 'snowballStemming',
    value: 'snowballStemming',
  },
  {
    label: 'spanishPluralStemming',
    value: 'spanishPluralStemming',
  },
  {
    label: 'stempel',
    value: 'stempel',
  },
  {
    label: 'stopword',
    value: 'stopword',
  },
  {
    label: 'trim',
    value: 'trim',
  },
  {
    label: 'wordDelimiterGraph',
    value: 'wordDelimiterGraph',
  },
];

export const DynamicTokenFilterInputMapping: Record<TokenFilterTypes, TokenFilterInputFieldType[]> = {
  asciiFolding: [
    {
      name: 'daitchMokotoffSoundex',
      type: 'text',
      required: false,
    },
  ],
  englishPossessive: [],
  daitchMokotoffSoundex: [
    {
      name: 'daitchMokotoffSoundex',
      type: 'text',
      required: false,
    },
  ],
  edgeGram: [
    {
      name: 'minGram',
      type: 'number',
      required: true,
    },
    {
      name: 'maxGram',
      type: 'number',
      required: true,
    },
    {
      name: 'termNotInBounds',
      type: 'number',
      required: false,
    },
  ],
  flattenGraph: [],
  icuFolding: [],
  icuNormalizer: [
    {
      type: 'text',
      required: false,
      name: 'normalizationForm',
    },
  ],
  kStemming: [],
  length: [
    {
      name: 'max',
      required: false,
      type: 'number',
    },
  ],
  nGram: [
    {
      name: 'minGram',
      type: 'number',
      required: true,
    },
    {
      name: 'maxGram',
      type: 'number',
      required: true,
    },
    {
      name: 'termNotInBounds',
      type: 'number',
      required: false,
    },
  ],
  regex: [
    {
      name: 'pattern',
      type: 'text',
      required: true,
    },
    {
      name: 'replacement',
      type: 'text',
      required: true,
    },
  ],
  lowercase: [],
  porterStemming: [],
  reverse: [],
  shingle: [
    {
      name: 'minShingleSize',
      required: true,
      type: 'number',
    },
    {
      name: 'maxShingleSize',
      required: true,
      type: 'number',
    },
  ],
  snowballStemming: [
    {
      name: 'stemmerName',
      required: true,
      type: 'dropdown',
      options: Object.keys(SnowBallStemmingType).map((key) => ({
        label: key,
        value: key,
      })),
    },
  ],
  spanishPluralStemming: [],
  stempel: [],
  stopword: [
    {
      name: 'tokens',
      type: 'stringarray',
      required: false,
    },
    {
      name: 'ignoreCase',
      required: false,
      type: 'boolean',
    },
  ],
  trim: [],
  wordDelimiterGraph: [
    {
      name: 'delimiterOptions',
      type: 'object',
      required: false,
      fields: [
        { name: 'generateWordParts', type: 'boolean', required: false },
        { name: 'generateNumberParts', type: 'boolean', required: false },
        { name: 'concatenateWords', type: 'boolean', required: false },
        { name: 'concatenateNumbers', type: 'boolean', required: false },
        { name: 'concatenateAll', type: 'boolean', required: false },
        { name: 'preserveOriginal', type: 'boolean', required: false },
        { name: 'splitOnCaseChange', type: 'boolean', required: false },
        { name: 'splitOnNumerics', type: 'boolean', required: false },
        { name: 'stemEnglishPossessive', type: 'boolean', required: false },
        { name: 'ignoreKeywords', type: 'boolean', required: false },
      ],
    },
    {
      name: 'protectedWords',
      type: 'object',
      required: false,
      fields: [
        {
          name: 'words',
          required: true,
          type: 'stringarray',
        },
        {
          name: 'ignoreCase',
          required: false,
          type: 'boolean',
        },
      ],
    },
  ],
};
