export const InbuiltAnalyzers = [
  {
    label: 'lucene.standard',
    value: 'lucene.standard',
  },
  {
    label: 'lucene.simple',
    value: 'lucene.simple',
  },
  {
    label: 'lucene.whitespace',
    value: 'lucene.whitespace',
  },
  {
    label: 'lucene.keyword',
    value: 'lucene.keyword',
  },
];

export const SearchIndexStatusMapping = {
  TRUE: ['Dynamic', 'palette.accent.blue.light'],
  FALSE: ['Static', 'palette.accent.yellow.light'],
};
