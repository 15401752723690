import { Button, Center, Flex, FullCenter, Spin, Text } from '@comet/blocks';
import SchemaTree from '@comet/components/SchemaTree/SchemaTree';
import { TSchema } from '@comet/components/SchemaTree/SchemaTree.types';
import { createSchemaFromResponseData } from '@comet/pages/Model/ModelSchema/ModelSchema.util';
import { useModelQuery } from '@comet/pages/Model/service';
import { ModelRequestData } from '@comet/pages/Model/types';
import { useDeploymentsVersion } from '@comet/pages/Project/Deployments/Deployments.utils';
import { useParams } from '@comet/router';
import { styled } from '@comet/styled';
import { Result } from 'antd';

const StyledText = styled(Text)`
  border: 1px solid ${({ theme }) => theme.palette.neutral.light};
  background: ${({ theme }) => theme.palette.neutral.lightest};
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
  padding: 8px;
`;

interface ApiDetailsSchemaProps {
  modelId: string | undefined;
  modelVersion: string | null;
}

const ModelSchema = ({
  modelId,
  modelVersion,
}: {
  modelId: string;
  modelVersion: string | null;
}) => {
  const { projectId } = useParams();
  const { latest, isLoading: isVersionLoading } =
    useDeploymentsVersion(projectId);
  const {
    data: draftModel,
    isLoading: isDraftModelLoading,
    isError: isDraftModelErrror,
    refetch: refetchDraftModel,
  } = useModelQuery(projectId, modelId);

  const {
    data: model,
    isLoading: isLatestModelLoading,
    isError: isLatestModelError,
    refetch: refetchLatestModel,
  } = useModelQuery(projectId, modelId, { version: modelVersion });

  if (isDraftModelLoading || isLatestModelLoading || isVersionLoading) {
    return (
      <FullCenter>
        <Spin />
      </FullCenter>
    );
  }

  if ((isDraftModelErrror && isLatestModelError) || (!draftModel && !model)) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchDraftModel();
                  refetchLatestModel();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  let modelSchema = {} as TSchema;

  if (model) {
    modelSchema = createSchemaFromResponseData(model as ModelRequestData);
  } else {
    modelSchema = createSchemaFromResponseData(draftModel as ModelRequestData);
  }

  return (
    <Flex direction="column" gap={10}>
      <Text appearance="label.short.regular">
        Model - {model?.name || draftModel?.name}
      </Text>
      <SchemaTree schema={modelSchema} disabled={true} />
    </Flex>
  );
};

const ApiDetailsSchema = ({ modelId, modelVersion }: ApiDetailsSchemaProps) => {
  if (!modelId) {
    return (
      <Flex>
        <StyledText appearance="label.long.regular" color="neutral.darkest">
          Not Applicable
        </StyledText>
      </Flex>
    );
  }

  return <ModelSchema modelId={modelId} modelVersion={modelVersion} />;
};

export default ApiDetailsSchema;
