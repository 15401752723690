import { Select, SelectProps } from 'antd';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import styled from 'styled-components';
import { Text } from '../Text/Text';

export { Select, type SelectProps } from 'antd';

type Option = {
  label: string;
  value: string;
};

export type SelectOption = Option | Option[];

export interface CustomSelectOption {
  label: string;
  value: string;
}

export interface CustomSelectProps extends SelectProps {
  width?: string | number;
  suffix?: ReactElement;
  options: CustomSelectOption[];
  label: string;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  onChange?: (selectedOption: string) => void;
}

export const CustomSelect = ({
  width,
  suffix = <BiChevronDown />,
  options,
  label,
  ...rest
}: CustomSelectProps) => {
  const SelectContainer = styled.div`
    display: flex;
    gap: 2px;
    position: relative;
    cursor: pointer;
    align-items: center;
    padding: 0 12px;
    border: 1px solid #d5d1ce;
    border-radius: 4px;

    .ant-select-selector {
      border: 0 !important;
      box-shadow: none !important;
    }

    .ant-select-selection-item {
      font-size: 14px;
    }

    .ant-select-selection-placeholder {
      color: ${({ theme }) => theme.palette.neutral.darkest};
    }

    .custom-dropdown {
      left: 0 !important;
    }

    .ant-select-arrow {
      color: #bfbfbf;
    }
  `;

  const ref = useRef<HTMLDivElement | null>(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setDropdownWidth(ref.current.clientWidth);
    }
  }, [ref.current?.clientWidth]);

  return (
    <SelectContainer ref={ref}>
      <Text appearance="label.long.regular" color="neutral.light">
        {label} :
      </Text>
      <Select
        options={options}
        suffixIcon={suffix}
        popupClassName="custom-dropdown"
        {...rest}
        dropdownMatchSelectWidth={dropdownWidth}
        style={{ minWidth: 60 }}
        getPopupContainer={(trigger) => trigger.parentElement.parentElement}
      />
    </SelectContainer>
  );
};
