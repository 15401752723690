import { Flex, Grid, Text } from '@comet/blocks';

export const Heading = ({ title, width }: { title: string; width: number }) => {
  return (
    <Flex width={width}>
      <Text appearance="heading.paragraph">{title}</Text>
    </Flex>
  );
};

export const AddEnvironmentsFormHeader = () => {
  return (
    <Grid gap={8} templateColumns="repeat(6, 1fr)">
      <Heading title="Environment Type" width={180} />
      <Heading title="Name" width={180} />
      <Heading title="Tier" width={180} />
      <Heading title="Resource Type" width={180} />
      <Heading title="Shared Environment" width={180} />
    </Grid>
  );
};
