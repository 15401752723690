import { useParams as RRDUseParams } from 'react-router-dom';

export const useParams = () => {
  const params = RRDUseParams();
  return params as {
    organisationId: string;
    projectId: string;
    modelId: string;
    apiId: string;
    version: string;
    plan: string;
    currency: 'usd' | 'inr';
    analyzerId: string;
    searchIndexId: string;
    environmentId: string;
    secretId: string;
    storageAccountId: string;
    instanceId: string;
    subflowId: string;
    vectorSearchIndexId: string;
  };
};
