import React from 'react';
import { styled } from '@comet/styled';

const Container = styled.div`
  width: 560px;
  background: #ffffff;
  border: 1px solid #d4d1ce;
  border-radius: 6px;
  padding: 16px 24px;
`;

const FormHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #212121;
`;

const FormContent = styled.div<{ hasHeading: boolean }>`
  ${({ hasHeading }) => hasHeading && `padding-top: 24px;`}
`;

interface IProps {
  heading?: string;
  children: any;
}

export const FormContainer = (props: IProps) => {
  const { heading, children } = props;

  return (
    <Container>
      {heading && <FormHeader>{heading}</FormHeader>}
      <FormContent hasHeading={Boolean(heading)}>{children}</FormContent>
    </Container>
  );
};
