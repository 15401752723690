import { styled } from '@comet/styled';
import { ActionNodeInfo } from './ActionNodeHeader';
import { ActionNodeIcon } from './ActionNodeIcon';

import { Position } from 'reactflow';
import { Tag } from '@comet/blocks';
import { Wrapper, StyledHandle } from '../styled';
import { CometNodeBaseProps } from '../../Nodes';

export const IfNodeDimension = {
  height: 80,
  width: 220,
};

export const ActionNodePreview = (type: CometNodeBaseProps['type']) => {
  return (
    <Wrapper>
      <Tag.CheckableTag checked>{type}</Tag.CheckableTag>
    </Wrapper>
  );
};

const Container = styled.div<{
  width?: string;
  height?: string;
}>`
  border: 2px solid ${({ theme }) => theme.palette.neutral.lighter};
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.neutral.white};
  width: ${({ width }) => width || '220px'};
  ${({ height }) => `height: ${height};`}
`;

const InfoContainer = styled.div`
  padding: 12px;
`;

export const ActionNode = (
  props: CometNodeBaseProps & { width?: string; height?: string }
) => {
  return (
    <Container width={props.width} height={props.height}>
      <ActionNodeIcon type={props.type} />
      <InfoContainer style={{ marginBottom: 10 }}>
        <ActionNodeInfo {...props} />
      </InfoContainer>
      <StyledHandle type="target" id="target" position={Position.Top} />
      <StyledHandle type="source" id="source" position={Position.Bottom} />
    </Container>
  );
};

export default ActionNode;
