import { memo, useEffect, useRef, useState } from 'react';
import { Wrapper } from '../../common/styled';
import { Tag } from '@comet/blocks';
import { CometNodeBaseProps, NodeTypeEnum } from '../../Nodes';
import ActionNode from '../../common/ActionNode/ActionNode';
import { useNodeEditorOpener } from '@comet/components/FlowEngine/FlowEngine.Editor';
import { HandleWithExplorer } from '../../common/HandleWithExplorer';
import { Node, Position, useNodes, useReactFlow } from 'reactflow';
import { WarningModal } from 'src/blocks/WarningModal';
import { useReplaceAddNode } from '@comet/hooks/useReplaceAddNode';
import { DeleteIcon } from 'src/blocks/Icons';

export const SwitchNodeDimension = {
  height: 120,
  width: 220,
};

export const SwitchNodePreview = () => {
  return (
    <Wrapper>
      <Tag.CheckableTag checked>Switch Node</Tag.CheckableTag>
    </Wrapper>
  );
};

const SwitchNode = (props: CometNodeBaseProps) => {
  const handleNodeClick = useNodeEditorOpener(props.id, props?.data);

  const nodes = useNodes();

  const prevNodes = useRef<Node[]>();
  const [deleteModal, setDeleteModal] = useState(false);

  const { getNode, setEdges, getEdge, deleteElements } =
    useReactFlow<CometNodeBaseProps['data']>();

  useEffect(() => {
    // Logic to delete metadata from switchnode once any sub-node is deleted.
    /**
     * Do not go through all the logic if the nodes are same.
     */
    if (!prevNodes.current) {
      prevNodes.current = nodes;
    }
    if (prevNodes.current.length === nodes.length) {
      return;
    }

    prevNodes.current = nodes;

    const mainNode = getNode(props.id);
    // Calculate the nodes which are need to be deleted from metadata
    const metadata: SwitchNodeMetaData['cases'] =
      mainNode?.data.metadata?.cases;
    const metadataNodeIds: number[] = [];

    if (!Array.isArray(metadata)) return;

    metadata.forEach((item, index) => {
      const node = getNode(item.nodeId);

      if (!node) {
        // If no node is there, then we need to delete metadata for that node.
        metadataNodeIds.push(index);
        item.deleted = true;
      }
    });
    if (metadataNodeIds.length < 1) return;

    if (mainNode?.data) {
      mainNode.data = { ...mainNode.data, metadata: { cases: metadata } }; // Update the switchnode's metadata
    }
  }, [getEdge, getNode, nodes, props, setEdges]);

  const onClick = () => {
    // Add a new sub-node to switch node
    props?.data.nodeOperations(
      {
        id: props.id,
        type: props.type,
        position: { x: props.xPos, y: props.yPos },
        data: props?.data,
      },
      // Dummy Node this won't be considered, based on type we add AddNode to switchnode.
      {
        type: NodeTypeEnum.SwitchNode,
        data: {
          ...props?.data,
          displayName: 'ADD_NODE',
        },
      },
      'addNode'
    );
  };
  const replaceWithAddNode = useReplaceAddNode();

  const onClickDelete = async () => {
    const node = getNode(props.id);
    if (!node) return;
    if (replaceWithAddNode(node)) return;
    deleteElements({ nodes: [node] });
  };

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={handleNodeClick}>
        <ActionNode {...props} />
        <HandleWithExplorer
          id="a"
          handleExplorerBlockClick={onClick}
          position={Position.Bottom}
          type="source"
          // Switchnode doesn't need any popup, clicking on `+` should add the node type ADD_NODE.
          noPopup={true}
        />
      </div>
      <DeleteIcon
        style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
        onClick={() => setDeleteModal(true)}
      />
      <WarningModal
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        onOk={onClickDelete}
        title="This action will delete all the connected nodes too"
        subTitle={
          'Deleting this node will also remove all of its children nodes. Are you sure you want to proceed and delete the entire node?'
        }
      />
    </div>
  );
};

export default memo(SwitchNode);
