import {
  ActionModalPropsType,
  ActionModalTypesEnum,
} from '../../Components/ActionModal/types';

import { PropsWithChildren } from 'react';
import { create } from 'zustand';

export type ActionModalStoreState = {
  props: PropsWithChildren<ActionModalPropsType>;
  setProps: (props: PropsWithChildren<ActionModalPropsType>) => void;
  onClose: () => void;
};

const initialPropsValue = {
  open: false,
  type: ActionModalTypesEnum.Confirm,
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
export const useActionModalStore = create<ActionModalStoreState>((set) => ({
  props: initialPropsValue,
  setProps: (props) => {
    set({ props: props });
  },
  onClose: () => {
    set({ props: initialPropsValue });
  },
}));
