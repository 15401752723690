import { User } from '@comet/pages/Project/types/Projects.types';

export interface IAnalyzer {
  type: 'CUSTOM' | 'BUILT_IN' | '';
  value: string;
}

export interface ICustomAnalyzer {
  name: string;
  charFilters: string[];
  tokenizer: string;
  tokenFilters: string;
}
export interface Field {
  name: string;
  type: string;
  analyzer: IAnalyzer;
  searchAnalyzer: IAnalyzer;
  multi?: {
    name: string;
    analyzer: IAnalyzer;
    searchAnalyzer: IAnalyzer;
  }[];
}

export interface SearchIndexMapping {
  dynamic: boolean;
  fields: Field[];
}

export interface SearchIndex {
  name: string;
  modelId: string;
  environmentId: string;
  projectId: string;
  createdOn: number;
  updatedOn: number;
  createdBy: User;
  updatedBy: User;
  mappings: SearchIndexMapping;
  id: string;
  modelData: {
    name: string;
  };
}

export interface SearchIndexResponse extends Omit<SearchIndex, 'mappings'> {
  dynamic: boolean;
}

export interface GetSearchIndexesResponse {
  data: SearchIndexResponse[];
  page: {
    offset: number;
    limit: number;
    total: number;
  };
}

export interface CreateSearchIndexFormData {
  environmentId: string;
  dynamic: boolean;
  fields: Field[];
}

export interface SearchIndexRequestData {
  name: string;
  modelId: string;
  environmentId: string;
  mappings: {
    dynamic: boolean;
    fields: Field[];
  };
}

export enum STEPS {
  'DETAILS' = 0,
  'MAPPINGS' = 1,
}
