import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import {
  GetSubflowDetailsParams,
  GetSubflowDetailsResponse,
  GetSubflowsParams,
  GetSubflowsResponse,
  Subflow,
  SubflowDetailsRaw,
} from '../types';

export const useGetSubflowsQuery = (
  projectId: string,
  params: GetSubflowsParams
) => {
  return useQuery({
    queryKey: ['useGetSubflowsQuery', projectId, params],
    queryFn: async () => {
      const data = await getSubflows(projectId, params);
      return data;
    },
    cacheTime: 0, // disabled caching
  });
};

export const getSubflows = async (
  projectId: string,
  params: GetSubflowsParams
) => {
  const url = `resources/${projectId}/flows`;
  const { data } = await getAxios().get<GetSubflowsResponse>(url, {
    params,
  });
  return data;
};

export const createSubflow = (
  projectId: string,
  subflowData: SubflowDetailsRaw
) => {
  const url = `resources/${projectId}/flows`;
  return getAxios()
    .post(url, subflowData)
    .then(({ data }) => data);
};

export const useGetSubflowQuery = (
  projectId: string,
  subflowId: string,
  dirtyParams?: GetSubflowDetailsParams,
  enabled?: boolean
) => {
  const url = `/resources/${projectId}/flows/${subflowId}`;

  const params: GetSubflowDetailsParams = {};
  if (dirtyParams?.version === 'draft' || !dirtyParams?.version) {
    params.draft = true;
  } else {
    params.version = dirtyParams?.version;
  }

  return useQuery({
    queryKey: ['useGetSubflowQuery', projectId, subflowId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetSubflowDetailsResponse>(url, {
        params,
      });
      return data;
    },
    enabled: enabled,
  });
};

export const updateSubflow = (
  projectId: string,
  subflowId: string,
  subflowDetails: Partial<Subflow>
) => {
  const url = `/resources/${projectId}/flows/${subflowId}`;

  // Remove category from nodes before updating
  subflowDetails.nodes?.map((node) => {
    delete node.data.category;
  });
  return getAxios()
    .patch(url, subflowDetails)
    .then(({ data }) => data);
};

export const deleteSubflow = (projectId: string, subflowId: string) => {
  const url = `/resources/${projectId}/flows/${subflowId}`;

  return getAxios()
    .delete(url)
    .then(({ data }) => data);
};
