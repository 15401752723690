import styled from 'styled-components';

export const Container = styled.div`
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.lighter}`};
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
  padding: 14px;
  overflow: scroll;
  height: 400px;
`;

export const AddButton = styled.button`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.accent.green.light};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  position: absolute;
  bottom: -13px;
`;

export const Field = styled.div`
  width: 100%;
  border-bottom: 1px solid #d4d1ce;
  position: relative;
  z-index: 0;

  & .fields {
    position: absolute;
    top: -10px;
    left: 20px;
    display: flex;
    width: 100%;
  }

  & .fields > * {
    margin-right: 20px;
  }

  & .fields > *:not(:first-child) {
    width: 100%;
  }

  & .fields > :first-child {
    position: absolute;
    left: -30px;
  }
`;

export const QueryFields = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  padding-bottom: 25px;
  margin-bottom: 20px;
  min-height: 200px;
  position: relative;

  & > *:not(:first-child, :last-child) {
    margin: 40px 0;
    margin-bottom: 50px;
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    border-bottom: 1px solid #d4d1ce;
    border-radius: 4px;
    left: 0;
    width: 100px;
  }

  &::before {
    position: absolute;
    border-bottom: 4px;
    content: '';
    display: flex;
    /* width: 1px; */
    left: 0px;
    top: 0px;
    border-left: 1px solid #d4d1ce;
    height: 100%;
  }

  .addNew {
    position: absolute;
    left: 15%;
    bottom: 0px;
    z-index: 1;
  }
`;
