import {
  SchemaKeySubType,
  SchemaKeyType,
  TProperties,
  TSchema,
} from '@comet/components/SchemaTree/SchemaTree.types';
import { Field, ModelRequestData } from '../types/ModelSchema.types';

type RequestFieldDataType = Field & {
  properties?: Field[];
};

const createRequestDataHelper = (
  schema: TSchema,
  key: string
): RequestFieldDataType => {
  if (schema.type !== SchemaKeyType.NESTED) {
    if (schema.type === SchemaKeyType.ARRAY) {
      if (schema.subType === SchemaKeySubType.NESTED) {
        const order = schema.order!;
        const propertiesData = order.map((key) => {
          return createRequestDataHelper(schema.properties![key], key);
        });

        return {
          id: key,
          name: schema.name,
          type: schema.type,
          required: !!schema.required,
          subType: schema.subType,
          properties: propertiesData,
        };
      }

      return {
        id: key,
        name: schema.name,
        type: schema.type,
        required: !!schema.required,
        subType: schema.subType,
      };
    }

    return {
      id: key,
      name: schema.name,
      type: schema.type,
      required: !!schema.required,
      subType: null,
    };
  }

  const order = schema.order,
    propertiesData = order.map((key) => {
      return createRequestDataHelper(schema.properties[key], key);
    });

  return {
    id: key,
    name: schema.name,
    type: schema.type,
    subType: null,
    required: !!schema.required,
    properties: propertiesData,
  };
};

export const createRequestDataFromSchema = (schema: TSchema) => {
  if (
    schema.type !== SchemaKeyType.NESTED &&
    !(
      schema.type === SchemaKeyType.ARRAY &&
      schema.subType === SchemaKeySubType.NESTED
    )
  ) {
    return null;
  }

  const requestData = createRequestDataHelper(schema, 'root');

  return {
    fields: requestData.properties,
  };
};

export const createSchemaHelper = (
  fields: Field[] | undefined
): TProperties => {
  return (fields || []).reduce((acc: TProperties, field) => {
    if (field.type === SchemaKeyType.NESTED) {
      const propertiesData = createSchemaHelper(field.properties);

      acc[field.id] = {
        name: field.name,
        required: field.required,
        type: field.type,
        properties: propertiesData,
        order: Object.keys(propertiesData),
      };

      return acc;
    }

    if (field.type === SchemaKeyType.ARRAY) {
      const propertiesData = createSchemaHelper(field.properties);
      const order = Object.keys(propertiesData);

      if (order.length > 0) {
        acc[field.id] = {
          name: field.name,
          required: field.required,
          type: field.type,
          subType: field.subType,
          properties: propertiesData,
          order,
        };
      } else {
        acc[field.id] = {
          name: field.name,
          required: field.required,
          type: field.type,
          subType: field.subType,
        };
      }

      return acc;
    }

    acc[field.id] = {
      name: field.name,
      required: field.required,
      type: field.type,
    };

    return acc;
  }, {});
};

export const createSchemaFromResponseData = (
  responseData: ModelRequestData
): TSchema => {
  const propertiesData = createSchemaHelper(responseData?.fields);

  return {
    type: SchemaKeyType.NESTED,
    name: 'root',
    properties: propertiesData,
    order: Object.keys(propertiesData),
  };
};
