import { Alert as AntdAlert, type AlertProps } from 'antd';
import { ThemeType, styled } from '@comet/styled';

const getBorderColor = (type: AlertProps['type'], theme: ThemeType) => {
  switch (type) {
    case 'success':
      return theme.palette.accent.green.light;
    case 'info':
      return '#4E8AE4';
    case 'warning':
      return 'orange';
    case 'error':
      return theme.palette.accent.red.light;
    default:
      return 'transparent';
  }
};

const StyledAlert = styled(AntdAlert)<{
  type?: AlertProps['type'];
}>`
  &.ant-alert {
    border-left: 6px solid ${({ type, theme }) => getBorderColor(type, theme)};
  }
`;

export const Alert = (props: AlertProps) => {
  return <StyledAlert {...props} />;
};
