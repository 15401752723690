import { BusinessNodeProps, NodeTypeEnum } from '../types';

import { LogicNodePropsType } from './types';
import { NodeSkeleton } from './NodeSkeleton';
import { useFlowEngineStore } from '@comet/components/FlowEngine/FlowEngine.store';

export function LogicNode({ node, nodeSkeletonProp }: LogicNodePropsType) {
  const businessNodePropObject: BusinessNodeProps = {
    type: node.data.type!,
    displayName: node.data.displayName,
    schema: node.data.schema!,
    values: node.data.values || {},
    nodeName: node.data.nodeName || `node_${node.id}`,
    metadata: node.data.metadata,
    returns: node.data.returns!,
  };

  function handleNodeClick(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    if (node.type !== NodeTypeEnum.ExecutorNode) {
      useFlowEngineStore
        .getState()
        .setEditorNode({ data: businessNodePropObject, id: node.id });
    }
  }

  return (
    <div onClick={handleNodeClick}>
      <NodeSkeleton {...nodeSkeletonProp} />
    </div>
  );
}
