import { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Center,
  CustomSelect,
  Flex,
  FullCenter,
  Search,
  Show,
  Table,
  Text,
  usePagination,
} from '@comet/blocks';
import { Empty, Result, Spin, Tooltip } from 'antd';
import { useOrgsQuery } from '@comet/pages/Organisation/service';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import debounce from 'lodash.debounce';
import {
  PAGE_SIZE,
  SortByOptions,
  SubscriptionPlans,
} from './OrganisationList.constants';
import { useOrgListingData } from '../utils';
import EmptyImage from '@comet/assets/png/empty_list.png';
import _ from 'lodash';
import { useNavigate } from '@comet/router';
import { useOrgCreatedBy } from '@comet/hooks/useOrgCreatedBy';

export const OrganisationList = () => {
  const navigate = useNavigate();
  const { pageOffset, currentPageIndex, fetchData } = usePagination();

  const initialValues = {
    offset: 0,
    query: '',
    plan_type: SubscriptionPlans[0].value,
    sort_by: SortByOptions[0].value,
  };

  const [sortBy, setSortBy] = useState(initialValues.sort_by);
  const [subscriptionPlan, setSubscriptionPlan] = useState(
    initialValues.plan_type
  );
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState(initialValues.query);

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const currentValues = {
    offset: pageOffset,
    query,
    plan_type: subscriptionPlan,
    sort_by: sortBy,
  };

  const {
    data: orgsData,
    isLoading,
    error,
    refetch,
  } = useOrgsQuery({
    offset: pageOffset,
    query,
    plan_type: subscriptionPlan || null,
    sort_by: sortBy,
  });

  const { tableColumns, tableData } = useOrgListingData(orgsData);

  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !orgsData;
  const showList = !isLoading && !isError && orgsData.data.length > 0;
  const { isCreateOrgEnabled } = useOrgCreatedBy();

  useEffect(() => {
    if (orgsData?.data.length === 0 && !isFilterChanged) {
      navigate('organisations.new');
    }
  }, [isFilterChanged, navigate, orgsData?.data.length]);

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={14}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">Organisations</Text>
        </Flex>
        <Tooltip
          title={
            isCreateOrgEnabled ? '' : 'No more organisations can be created.'
          }
        >
          <Button
            type="primary"
            onClick={() => navigate('organisations.new')}
            disabled={!isCreateOrgEnabled}
          >
            Create Organisation
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Flex justifyContent="space-between" alignItems="center" padding={10}>
          <Flex alignItems="center" gap={10}>
            <Flex>
              <Search
                placeholder="Search by name"
                value={searchValue}
                onChange={onSearch}
              />
            </Flex>
            <CustomSelect
              label="Subscription plan"
              options={SubscriptionPlans}
              value={subscriptionPlan}
              onChange={setSubscriptionPlan}
            />
            <CustomSelect
              label="Sort by"
              options={SortByOptions}
              value={sortBy}
              onChange={setSortBy}
            />
          </Flex>
        </Flex>
        <Show if={isLoading}>
          <FullCenter>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && orgsData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Organisations found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="organisations"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil((orgsData?.page.total || 0) / PAGE_SIZE)}
            totalRows={orgsData?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
