import { FooterPropsType, FooterTypeEnum } from './types';

import { Button } from '../Button';
import { Divider } from '../Divider';
import _ from 'lodash';
import { defaultProps } from './Constant';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
`;

const LeftButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
`;

const RightButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 2;
  column-gap: 8px;
`;

export default function Footer(props: FooterPropsType | Record<string, never>) {
  if (_.isEmpty(props)) return <FooterHelper type={FooterTypeEnum.Default} />;
  return <FooterHelper {...(props as FooterPropsType)} />;
}

function FooterHelper(props: FooterPropsType): React.ReactElement {
  /* 
  Prop can not be destructured because while destructuring we can only
  provide values of common properties of DefaultFooterPropsType, DirectionalFooterPropsType,
  ThreeButtonsFooterPropsType, ThreeButtonsLinkFooterPropsType.
  In this way, we are providing props value or default value.
  */

  const specificProps = defaultProps[props.type];
  const { type, ...restProps } = props;
  const mergedProps = { ...specificProps, ...restProps };

  /*Primary Button will be displayed everytime, all other buttons are rendered based on FooterType*/
  const isShowSecondaryButton = type !== FooterTypeEnum.Directional;
  const isShowTertiaryButton =
    type === FooterTypeEnum.Directional || type === FooterTypeEnum.ThreeButtons;
  const isShowTertiaryLink = type === FooterTypeEnum.ThreeButtonsLink;

  return (
    <>
      <Divider />
      <FooterWrapper>
        <LeftButtonContainer>
          {isShowTertiaryButton && (
            <Button
              onClick={
                'onClickTertiaryButton' in mergedProps
                  ? mergedProps.onClickTertiaryButton
                  : () => {}
              }
            >
              {'tertiaryButtonText' in mergedProps &&
                mergedProps.tertiaryButtonText}
            </Button>
          )}
          {isShowTertiaryLink ? (
            <Button
              type="link"
              onClick={
                'onClickTertiaryLink' in mergedProps
                  ? mergedProps.onClickTertiaryLink
                  : () => {}
              }
            >
              {'tertiaryLinkText' in mergedProps &&
                mergedProps.tertiaryLinkText}
            </Button>
          ) : null}
        </LeftButtonContainer>
        <RightButtonContainer>
          {
            <Button
              type="primary"
              onClick={
                'onClickPrimaryButton' in mergedProps
                  ? mergedProps.onClickPrimaryButton
                  : () => {}
              }
            >
              {'primaryButtonText' in mergedProps &&
                mergedProps.primaryButtonText}
            </Button>
          }
          {isShowSecondaryButton ? (
            <Button
              onClick={
                'onClickSecondaryButton' in mergedProps
                  ? mergedProps.onClickSecondaryButton
                  : () => {}
              }
            >
              {'secondaryButtonText' in mergedProps &&
                mergedProps.secondaryButtonText}
            </Button>
          ) : null}
        </RightButtonContainer>
      </FooterWrapper>
    </>
  );
}
