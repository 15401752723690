import {
  Alert,
  Button,
  Center,
  CustomSelect,
  Flex,
  FullCenter,
  Search,
  Select,
  Show,
  Spin,
  Table,
  Text,
  usePagination,
  Tooltip,
} from '@comet/blocks';
import { ChangeEvent, useState } from 'react';
import { Empty, Result } from 'antd';
import {
  MethodOptions,
  SortByOptions,
  StatusOptions,
} from './ApiListing.constants';

import { ApiListingEmpty } from './ApiListing.Empty';
import { CreateApiModal } from '../CreateApi/CreateApiModal';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { GetApiListParams } from '../Types';
import { Release } from '@comet/pages/Project/Deployments/ReleaseModal';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import { useApiListingData } from './ApiListing.utils';
import { useApisQuery } from '../Service';
import { useDeploymentsVersion } from '@comet/pages/Project/Deployments/Deployments.utils';
import { useGetProjectQuery } from '@comet/pages/Project/service';
import {
  useGetOrgSubscriptionQuery,
  useOrgQuery,
} from '@comet/pages/Organisation/service';
import { useParams } from '@comet/router';
import { useProjectMembersOptions } from '@comet/pages/Project/utils';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';

const PAGE_SIZE = 10;
export const ApiListing = () => {
  const { organisationId, projectId } = useParams();

  // User permissions
  const { hasViewApiAccess, hasEditApiAccess } = useProjectPermissions();

  const { pageOffset, currentPageIndex, fetchData } = usePagination();

  const {
    latest,
    versions,
    version,
    setVersion,
    isLoading: isVersionLoading,
    isError: isVersionError,
  } = useDeploymentsVersion(projectId);
  const { data: orgData, isLoading: isOrgLoading } =
    useOrgQuery(organisationId);

  const projectMembersOptions = useProjectMembersOptions(
    organisationId,
    projectId
  );

  const initialValues = {
    flow_type: 'API',
    version,
    offset: 0,
    query: '',
    draft: null,
    active: null,
    sort_by: SortByOptions[0].value,
    request_method: MethodOptions[0].value,
    updatedBy: '',
  };

  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');
  const [requestMethod, setRequestMethod] = useState(MethodOptions[0].value);
  const [status, setStatus] = useState(StatusOptions[0].value);
  const [draft, setDraft] = useState<boolean | null>(null);
  const [active, setActive] = useState<boolean | null>(null);
  const [updatedBy, setUpdatedBy] = useState('');
  const [sortBy, setSortBy] = useState(SortByOptions[0].value);

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const onStatusChange = (status: string) => {
    setStatus(status);
    if (status === 'DRAFT') {
      setDraft(true);
    } else if (status === 'ACTIVE') {
      setDraft(false);
      setActive(true);
    } else if (status === 'INACTIVE') {
      setDraft(null);
      setActive(false);
    } else {
      setDraft(null);
      setActive(null);
    }
  };

  const currentValues = {
    flow_type: 'API',
    version,
    offset: pageOffset,
    query,
    draft,
    active,
    sort_by: sortBy,
    request_method: requestMethod,
    updatedBy,
  };

  const params: GetApiListParams = {
    flow_type: 'API',
    version,
    offset: pageOffset,
    query,
    draft,
    active,
    sort_by: sortBy,
    request_method: requestMethod || null,
    updatedBy: updatedBy || null,
  };

  // check if there's any api is present or not?
  const { data, isLoading: isEmptyApiCheckLoading } = useGetProjectQuery(
    organisationId,
    projectId
  );
  const isEmpty = !data?.onboarding.api;

  const {
    data: response,
    isLoading: isApisLoading,
    error,
    refetch: refecthApiList,
  } = useApisQuery(projectId, params, !!data && !isVersionLoading);

  const { data: activeApiData } = useApisQuery(projectId, {
    flow_type: 'API',
    version,
    offset: 0,
    limit: 1,
    active: true,
  });

  const { data: ApiList, page } = response || {};

  const { tableColumns, tableData } = useApiListingData(
    ApiList,
    version,
    latest,
    refecthApiList
  );

  const isLoading =
    isEmptyApiCheckLoading || isVersionLoading || isApisLoading || isOrgLoading;
  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !response || !ApiList || isVersionError || !orgData;
  const showList = !isLoading && !isError && ApiList.length > 0;

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);
  const activeApis = activeApiData?.page.total;

  const isCreateApiDisabled =
    !orgSubscriptionData?.active ||
    (orgSubscriptionData?.resourceLimits.maxAPIs || 0) <= (activeApis || 0);

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!hasViewApiAccess) {
    return <ErrorPage />;
  }

  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refecthApiList()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  if (isEmpty) {
    return <ApiListingEmpty />;
  }

  return (
    <Flex width="100%" direction="column" gap={12} padding={16}>
      <Show if={versions[0]?.label === 'Draft'}>
        <Flex direction="column" style={{ background: '#FFFFFF' }}>
          <Alert
            message="Changes has been made to your project since last deploy."
            type="info"
            showIcon={true}
            action={<Release />}
            closable={true}
          />
        </Flex>
      </Show>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: 10 }}
      >
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">API</Text>
          <Show if={versions.length > 0}>
            <Select
              options={versions}
              value={version}
              onChange={setVersion}
              style={{ minWidth: 100 }}
            />
          </Show>
        </Flex>

        <CreateApiModal disabled={isCreateApiDisabled || !hasEditApiAccess} />
      </Flex>
      <TableContainer>
        <Flex alignItems="center" padding={10} gap={10}>
          <Flex>
            <Search
              placeholder="Search by"
              value={searchValue}
              onChange={onSearch}
            />
          </Flex>
          <CustomSelect
            label="Method"
            value={requestMethod}
            onChange={setRequestMethod}
            options={MethodOptions}
          />
          <CustomSelect
            label="Status"
            value={status}
            onChange={onStatusChange}
            options={StatusOptions}
          />
          <CustomSelect
            label="Created by"
            value={updatedBy}
            onChange={setUpdatedBy}
            options={projectMembersOptions}
          />
          <CustomSelect
            label="Sort by"
            value={sortBy}
            onChange={setSortBy}
            options={SortByOptions}
          />
        </Flex>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isError && !isLoading && ApiList.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No APIs found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="APIs"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil((page?.total || 0) / PAGE_SIZE)}
            totalRows={page?.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
