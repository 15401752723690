import React from 'react';
import styled from 'styled-components';
import { MdOutlineGroupAdd } from 'react-icons/md';
import { Button } from '@comet/blocks';
import { useNavigate } from '@comet/router';

const Container = styled.div`
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: auto;
  margin-top: 20px;
  gap: 5px;
  width: 100%;
  border-radius: 6px;
  padding: 15px;
  background-color: #fff;
`;

const Description = styled.div`
  display: grid;
  grid-column: 1 / span 3;
  gap: 5px;
`;

const Title = styled.h1`
  font-size: 18px;
  grid-column: 1 / span 3;
  grid-row: 1/2;
  color: ${({ theme }) => `${theme.palette.neutral.darkest}`};
`;

const Subtitle = styled.p`
  font-size: 14px;
  grid-column: 1 / span 3;
  grid-row: 2/3;
  color: ${({ theme }) => `${theme.palette.neutral.darker}`};
`;

const AddTeamMemberButton = styled(Button)`
  line-height: 16px;
  grid-column: 4/5;
  align-self: center;
  background-color: inherit;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => `${theme.palette.primary}`};
`;

export const AddTeamMemberInvite = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('organisations.organisationId.projects.projectId.users');
  };
  return (
    <Container>
      <Description>
        <Title> Invite team members</Title>
        <Subtitle> Collaborate and build great products together</Subtitle>
      </Description>

      <AddTeamMemberButton onClick={onClick}>
        <MdOutlineGroupAdd size="24px" style={{ marginRight: 2 }} />
        Add Members
      </AddTeamMemberButton>
    </Container>
  );
};
