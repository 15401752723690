import { FlowEngineState, useFlowEngineStore } from './FlowEngine.store';

import { BusinessNodeProps } from './Nodes/BusinessNode';
import { Button, Drawer, Flex } from '@comet/blocks';
import { MouseEventHandler } from 'react';
import { NodeEditor } from '../NodeEditor/NodeForm';
import { shallow } from 'zustand/shallow';
import { useReactFlow } from 'reactflow';
import { MdOpenInNew } from 'react-icons/md';

const selector = (state: FlowEngineState) => ({
  editorNode: state.editorNode,
  setEditorNode: state.setEditorNode,
});

export const FlowEngineEditor = () => {
  const { editorNode, setEditorNode } = useFlowEngineStore(selector, shallow);
  const { setNodes } = useReactFlow();
  const redirectUrl =
    'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/apis/node-types';

  if (!editorNode) {
    return null;
  }

  const onChange = (key: string, values: any) => {
    if (key === 'nodeName') {
      setNodes((nodes) => {
        nodes.forEach((node) => {
          if (node.id === editorNode?.id) {
            node.data = {
              ...node.data,
              nodeName: values,
            };
          }
        });
        return nodes;
      });
      return;
    }
    editorNode.data.values[key] = values;
    editorNode.data = {
      ...editorNode.data,
    };
  };

  return (
    <Drawer
      title={editorNode.data.displayName}
      placement="right"
      width={350}
      open
      onClose={() => setEditorNode(null)}
      destroyOnClose={false}
      extra={
        <Button type="link" onClick={() => window.open(redirectUrl, '_blank')}>
          <Flex gap={10} alignItems="center" justifyContent="center">
            <MdOpenInNew />
            <div>Learn more</div>
          </Flex>
        </Button>
      }
    >
      <NodeEditor
        schema={editorNode.data.schema}
        values={editorNode.data.values}
        onChange={onChange}
      />
    </Drawer>
  );
};

export const useNodeEditorOpener = (id: string, data: BusinessNodeProps) => {
  const openEditor = useFlowEngineStore((state) => state.setEditorNode);

  const handleNodeClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (
      (event.target as HTMLElement).getAttribute('data-nodeexplorerhandler') ===
      'true'
    ) {
      return;
    }
    openEditor({ data, id });
  };

  return handleNodeClick;
};
