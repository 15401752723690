import AntdModal from 'antd/es/modal/Modal';
import Footer from '../Footer/Footer';
import { FooterTypeEnum } from '../Footer/types';
import { ModalPropsType } from './types';
import { PropsWithChildren } from 'react';

export default function Modal({
  open = false,
  title = <>Modal</>,
  primaryButtonText = 'Save',
  secondaryButtonText = 'Cancel',
  onClose = () => {}, // function for cross button
  onClickPrimaryButton = () => {},
  onClickSecondaryButton = () => {},
  canClose = true,
  width = 416,
  zIndex = 1000,
  style,
  children,
}: PropsWithChildren<ModalPropsType>) {
  return (
    <AntdModal
      zIndex={zIndex}
      width={width}
      open={open}
      title={title}
      style={style}
      footer={
        <Footer
          type={FooterTypeEnum.Default}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
        />
      }
      onCancel={onClose}
      {...(!canClose && { closeIcon: false })}
    >
      {children}
    </AntdModal>
  );
}
