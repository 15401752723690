import { getAxios } from '@comet/axios';
import { queryClient, useMutation, useQuery } from '@comet/query';
import {
  GetSecretsDetailsResponse,
  SecretRawData,
  SecretsRawData,
} from '../Types';
import { useNavigate, useParams } from '@comet/router';
import { notification } from 'antd';

export const useGetSecretsQuery = (
  projectId: string,
  environmentId: string | undefined,
  query?: string,
  type?: string,
  onSuccess?: (data: GetSecretsDetailsResponse) => void
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/secrets?query=${
    query ?? ''
  }&type=${type ?? ''}`;

  return useQuery({
    queryKey: ['useGetSecretsQuery', projectId, environmentId, query, type],
    queryFn: async () => {
      const { data } = await getAxios().get<GetSecretsDetailsResponse>(url);

      return data;
    },
    enabled: !!environmentId,
    onSuccess,
    cacheTime: 0,
  });
};

export const deleteSecret = (
  projectId: string,
  environmentId: string,
  secretId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/secrets/${secretId}`;
  return getAxios().delete(url);
};

export const createSecretQuery = async (
  projectId: string,
  environmentId: string,
  modelData: SecretsRawData
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/secrets`;
  const response = await getAxios().post<{ id: string; status: string }>(
    url,
    modelData
  );
  return response.data;
};

export const updateSecret = async (
  projectId: string,
  environmentId: string,
  secretData: SecretsRawData | null,
  secretId: string
) => {
  if (!secretData) return {};
  const url = `/resources/${projectId}/environments/${environmentId}/secrets/${secretId}`;
  const { data } = await getAxios().put(url, secretData);
  return data;
};

export const useGetSecretQuery = (environmentId: string, secretId: string) => {
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['useGetSecretQuery', projectId, secretId],
    queryFn: async () => {
      return getSecret(projectId, environmentId, secretId);
    },
    enabled: secretId !== '',
  });
};
export const useGetSecretByNameQuery = (
  environmentId: string,
  secretName: string
) => {
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['useGetSecretByNameQuery', projectId, secretName],
    queryFn: async () => {
      return getSecretByName(projectId, environmentId, secretName);
    },
    enabled: secretName !== '' && environmentId !== '',
  });
};

export const getSecret = (
  projectId: string,
  environmentId: string,
  secretId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/secrets/${secretId}`;

  return getAxios()
    .get<SecretRawData>(url)
    .then(({ data }) => data)
    .catch((err) => err);
};

export const getSecretByName = (
  projectId: string,
  environmentId: string,
  secretName: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/secrets/_search?secret_name=${secretName}`;

  return getAxios()
    .get<SecretRawData>(url)
    .then(({ data }) => data)
    .catch((err) => err);
};
