import { Button, Center, Flex, Text } from '@comet/blocks';
import { ReactComponent as Success } from '@comet/assets/svg/payment_success.svg';
import styled from 'styled-components';
import { useNavigate } from '@comet/router';

const Container = styled.div`
  background-color: white;
  width: 60%;
  margin: auto;
  margin-top: 40px;
  border-radius: 8px;
  padding: 24px;
`;

export const PaymentSuccess = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('organisations.organisationId.projects.projectId.environments');
  };

  return (
    <Container>
      <Flex direction="column">
        <Text appearance="heading.card">Payment Successful</Text>
        <Text appearance="body.regular">Your plan has been upgraded.</Text>
        <Center>
          <Flex direction="column" alignItems="center">
            <Success />
            <Button
              onClick={onClick}
              style={{ width: '50%' }}
              appearance="primary"
            >
              Continue
            </Button>
          </Flex>
        </Center>
      </Flex>
    </Container>
  );
};
