import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
  Tooltip,
} from '@comet/blocks';
import { GB_TO_BYTES, StorageAccountStateMapping } from '../constants';
import { Link, useParams } from '@comet/router';
import { styled, useTheme } from '@comet/styled';
import { useCallback, useMemo, useState } from 'react';

import { Column } from 'react-table';
import { FiMoreHorizontal } from 'react-icons/fi';
import {
  GetStorageAccountsResponse,
  StorageAccountInList,
} from '../types/StorageAccount.types';
import { RiAlertLine } from 'react-icons/ri';
import { StatusComponent } from '@comet/components/TableComponents';
import { User } from '@comet/components/User';
import _ from 'lodash';
import { deleteStorageAccount } from '../service';
import format from 'date-fns/format';
import { DeleteSAModal } from '@comet/pages/Project/StorageAccounts/StorageAccountList/DeleteSAModal';

export const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

export const DeleteAnalyzer = ({
  id,
  saData,
}: {
  id: string;
  saData: StorageAccountInList;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await deleteStorageAccount(projectId, id);
    toggleModal();
  }, [id, projectId]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip title={'This will delete the bucket'}>Delete</Tooltip>
              ),
              onClick: toggleModal,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      {isConfirm && !!saData && (
        <DeleteSAModal saData={saData} toggleModal={toggleModal} />
      )}
    </>
  );
};

function getUsedSize(size: number) {
  //for Gigabyte
  if (size > 1073741824) {
    return `${(size / 1073741824).toFixed(2)} GB(s)`;
  }
  //for Megabyte
  if (size > 1048576) {
    return `${(size / 1048576).toFixed(2)} MB(s)`;
  }
  //for Kilobyte
  if (size > 1024) {
    return `${(size / 1024).toFixed(2)} KB(s)`;
  }
  return `${size.toFixed(2)} B(s)`;
}

export const useStorageAccountsListingData = (
  storageAccountsData: GetStorageAccountsResponse | undefined
) => {
  //const { url: baseUrl } = useGetApiBaseUrl();

  const { theme } = useTheme();

  const tableData = useMemo(() => {
    if (!storageAccountsData) return [];

    const storageAccountList = storageAccountsData.data;

    return storageAccountList.map((item) => {
      const size = (item.draftState?.size || item.size) / GB_TO_BYTES;

      return {
        name: item.name,
        size: `${size.toFixed(2)} GiB(s)`,
        updatedBy: <User {...(item.updatedBy || {})} />,
        updatedOn: formatTime(item.updatedOn),
        id: item.id,
        usedSize: getUsedSize(item.usedSize),
        environment: item.environments.name,
        delete: <DeleteAnalyzer id={item.id} saData={item} />,
      };
    });
  }, [storageAccountsData, theme]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" />,
        Cell: (data) => {
          const currentRow = data.row.original;
          const { projectId, organisationId } = useParams();
          return (
            <Link
              routeId="organisations.organisationId.projects.projectId.storageAccounts.storageAccountId.details"
              pathParam={{
                storageAccountId: currentRow.id,
                organisationId,
                projectId,
              }}
            >
              {currentRow.name}
            </Link>
          );
        },
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Size" />,
        accessor: 'size',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Used size" />,
        accessor: 'usedSize',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Environment" />,
        accessor: 'environment',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Updated By" />,
        accessor: 'updatedBy',
        disableSortBy: true,
      },

      {
        Header: <TableHeader text="Updated On" />,
        accessor: 'updatedOn',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'delete',
        disableSortBy: true,
      },
    ];
  }, []);

  return { tableColumns, tableData };
};
