import { DatabaseMode, DatabaseModeOption } from '../../constants';
import { Flex, FormItem, Label, Select, Show } from '@comet/blocks';

import { DATABASE_DATA } from '../../Types';
import { EditableText } from '@comet/components/Editable/Text';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useState } from 'react';

export const UpdateDatabase = ({ data }: { data: DATABASE_DATA }) => {
  const [details, setDetails] = useState({
    mode: data.mode,
    dbName: data.name,
    mongoURI: data.mongodbURI,
    publicKey: data.publicKey,
    privateKey: data.privateKey,
    projectId: data.projectId,
    clusterName: data.clusterName,
  });
  const { hasManageProjectSettingsAccess } = useProjectPermissions();

  const handleChange = (key: string, value: string) => {
    setDetails({ ...details, [key]: value });
  };

  return (
    <>
      <Flex direction="column" gap={5}>
        <Label required={true}>Database Name</Label>
        <EditableText
          name={['data', 'name']}
          type="text"
          value={details.dbName}
          onSave={(value) => handleChange('dbName', value)}
          disabled={!hasManageProjectSettingsAccess}
        />
      </Flex>
      <FormItem
        name={['data', 'mode']}
        label="Database Mode"
        required
        initialValue={details.mode}
        marginBottom={0}
        rules={[{ required: true, message: 'Database mode is required' }]}
      >
        <Select
          onChange={(value) => handleChange('mode', value)}
          style={{ width: 200 }}
          value={details.mode}
          options={DatabaseModeOption}
          disabled={!hasManageProjectSettingsAccess}
        />
      </FormItem>

      <Show if={details.mode === DatabaseMode.DATABASE_ONLY}>
        <Flex direction="column" gap={5}>
          <Label required={true}>MongoDB URI</Label>
          <EditableText
            name={['data', 'mongodbURI']}
            type="text"
            value={details.mongoURI}
            onSave={(value) => handleChange('mongoURI', value)}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
      </Show>
      <Show if={details.mode === DatabaseMode.MONGODB_ATLAS}>
        <Flex direction="column" gap={5}>
          <Label required={true}>Public key</Label>
          <EditableText
            name={['data', 'publicKey']}
            type="text"
            value={details.publicKey}
            onSave={(value) => handleChange('publicKey', value)}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
        <Flex direction="column" gap={5}>
          <Label required={true}>Private Key</Label>
          <EditableText
            name={['data', 'privateKey']}
            type="text"
            value={details.privateKey}
            onSave={(value) => handleChange('privateKey', value)}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
        <Flex direction="column" gap={5}>
          <Label required={true}>Project ID</Label>
          <EditableText
            name={['data', 'projectId']}
            type="text"
            value={details.projectId}
            onSave={(value) => handleChange('projectId', value)}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
        <Flex direction="column" gap={5}>
          <Label required={true}>Cluster Name</Label>
          <EditableText
            name={['data', 'clusterName']}
            type="text"
            value={details.clusterName}
            onSave={(value) => handleChange('clusterName', value)}
            disabled={!hasManageProjectSettingsAccess}
          />
        </Flex>
      </Show>
    </>
  );
};
