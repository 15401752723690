import { useParams } from '@comet/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Center,
  Flex,
  FullCenter,
  Label,
  Paper,
  Spin,
  Form,
  PageHeader,
  Show,
  Text,
  FormItem,
  Tabs,
} from '@comet/blocks';
import { Result, Switch, notification } from 'antd';
import { EditableText } from '@comet/components/Editable/Text';
import { queryClient, useMutation } from '@comet/query';
import { updateSearchIndex, useGetSearchIndexQuery } from './service';
import { CreateSearchIndexFormData, SearchIndexRequestData } from './types';

import { StyledFieldType } from './SearchIndex.styled';
import { DynamicFields } from './DynamicFields';
import { sendErrorNotification } from 'src/blocks/Notification';

export const VectorSearchIndexDetails = () => {
  const { projectId, vectorSearchIndexId: searchIndexId } = useParams();

  const {
    data: searchIndexData,
    isLoading: isSearchIndexLoading,
    isError: isSearchIndexError,
    refetch: refetchSearchIndex,
  } = useGetSearchIndexQuery();
  const [name, setName] = useState(searchIndexData?.name || '');
  const [form] = Form.useForm();
  const updateSearchIndexMutation = useMutation({
    mutationFn: (analyzerData: SearchIndexRequestData) =>
      updateSearchIndex(projectId, searchIndexId, analyzerData),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['useGetVectorSearchIndexQuery', searchIndexId],
      });
      queryClient.refetchQueries({
        queryKey: ['useGetVectorSearchIndexesQuery'],
      });

      notification.success({
        message: 'Success',
        description: 'Vector search index updated successfully',
      });
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'there was a problem in updating the vector search index',
        reportBug: true,
      });
    },
  });

  useEffect(() => {
    if (searchIndexData) {
      setName(searchIndexData.name);
    }
  }, [searchIndexData]);

  const handleSave = (formData: CreateSearchIndexFormData) => {
    form.validateFields().then(() => {
      const { fields, defaultFields } = formData;
      const newFields = fields ? [defaultFields, ...fields] : [defaultFields];

      if (!searchIndexData) return;

      const data = {
        name,
        modelId: searchIndexData.modelId,
        environmentId: searchIndexData.environmentId,
        mappings: {
          type: 'vector',
          fields: newFields.map((field: any) => {
            const { searchType, similarityFunction, noOfDimensions } = field;
            const newField = {
              ...field,
              type: searchType,
              similarity: similarityFunction,
              numDimensions: noOfDimensions,
            };
            delete newField.searchType;
            delete newField.similarityFunction;
            delete newField.noOfDimensions;
            if (newField.type === 'filter') {
              delete newField.similarity;
              delete newField.numDimensions;
            }
            return newField;
          }),
        },

        type: 'vectorSearch',
      };

      updateSearchIndexMutation.mutate(data);
    });
  };

  const error = isSearchIndexError || !searchIndexData;

  if (isSearchIndexLoading) {
    return (
      <Flex direction="column">
        <Spin />
      </Flex>
    );
  }

  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchSearchIndex();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={handleSave}
      initialValues={searchIndexData}
    >
      <Flex direction="column">
        <PageHeader
          title="Vector Search Index details"
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              loading={updateSearchIndexMutation.isLoading}
            >
              Save
            </Button>
          }
          tabs={[
            {
              title: 'Details',
              active: true,
              id: 'details',
              onClick: () => {
                // handleSave(form.getFieldsValue());
              },
            },
          ]}
        />

        <Paper size="medium">
          <Flex direction="column" gap={16}>
            <Flex direction="column" gap={10}>
              <Label required={true}>Search Index name</Label>
              <EditableText
                type="text"
                name="name"
                {...{ value: name, onSave: setName }}
                disabled={true}
              />
            </Flex>
            <Flex direction="column" gap={10}>
              <Label required={true}>Model</Label>
              <Flex>
                <StyledFieldType>
                  <Text appearance="caption.semibold">
                    {searchIndexData?.modelData.name}
                  </Text>
                </StyledFieldType>
              </Flex>
            </Flex>

            <Flex direction="column" gap={10}>
              <Text appearance="label.short.regular">Field Mappings</Text>
            </Flex>
            <DynamicFields
              searchIndexData={searchIndexData}
              modelId={searchIndexData?.modelId}
              form={form}
            />
          </Flex>
        </Paper>
      </Flex>
    </Form>
  );
};
