import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { RawOrganisation } from '../Organisation.types';

export const useOrgExistsQuery = (orgName: string) => {
  return useQuery({
    enabled: false,
    queryKey: ['useOrgExistsQuery', orgName],
    queryFn: async () => {
      return await checkOrg(orgName);
    },
  });
};

export const checkOrg = (orgName: string) => {
  return getAxios()
    .get<{ exists: boolean }>('/organisations/exists', {
      params: { name: orgName },
    })
    .then(({ data }) => data);
};

export const createOrg = (orgData: RawOrganisation) => {
  const url = '/organisations';
  return getAxios().post<{ id: string }>(url, orgData);
};
