import { styled } from '@comet/styled';
import { Tooltip } from '@comet/blocks';

interface ActionComponentProps {
  onClick: () => void;
  actionTitle: string;
  disabled?: boolean;
  tooltip?: string;
}

export const Action = styled.div<{
  disabled: boolean;
}>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.neutral.dark : theme.palette.link};
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
`;

export const ActionComponent = ({
  onClick,
  actionTitle,
  disabled,
  tooltip,
}: ActionComponentProps) => {
  const isDisabled = !!disabled;
  return (
    <Action onClick={isDisabled ? () => null : onClick} disabled={isDisabled}>
      <Tooltip title={isDisabled ? tooltip : ''}>{actionTitle}</Tooltip>
    </Action>
  );
};
