import { Typography } from 'antd';
import { Modal } from '../Modal';
import { Flex } from '../Flex';
import { AiOutlineWarning } from 'react-icons/ai';
import { useTheme } from 'styled-components';
import { Text } from '../Text/Text';
interface WarningModalProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  title: React.ReactNode;
  subTitle: React.ReactNode;
  okText?: string;
  cancelText?: string;
  isDanger?: boolean;
  isLoading?: boolean;
}

export const WarningModal = ({
  open,
  onCancel,
  onOk,
  title,
  subTitle,
  okText = 'Yes',
  cancelText = 'No',
  isDanger,
  isLoading,
}: WarningModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okText={okText}
      okButtonProps={{ danger: isDanger && true, loading: isLoading }}
      cancelButtonProps={{ loading: isLoading }}
      cancelText={cancelText}
    >
      <Flex
        alignItems={'flex-start'}
        justifyContent="flex-start"
        style={{ padding: '10px' }}
      >
        <span>
          <AiOutlineWarning size={50} color={theme.palette.danger} />
        </span>
        <div style={{ marginLeft: 10 }}>
          <Typography.Title level={4}>{title}</Typography.Title>
          <Text appearance="body.regular">{subTitle}</Text>
        </div>
      </Flex>
    </Modal>
  );
};
