import { AddTeamMemberInvite } from '@comet/components/Members/AddTeamMemberInvite';
import { QuickStartGuide } from '@comet/components/QuickStartGuide';
import WelcomeHeaderImage from '@comet/assets/png/welcomePageHeader.png';
import styled from 'styled-components';
import { Flex, Paper, Text } from '@comet/blocks';
import FlexItem from 'src/blocks/FlexItem';
import ProjectInfoField from './ProjectDetails.Info';
import { GetProjectResponse } from '../../types/Projects.types';
import { notification } from 'antd';
import { useGetApiBaseUrl } from '../../utils';
import { UrlContainer } from '@comet/components/UrlContainer';

const Container = styled.div`
  width: 90%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div<{
  src: string;
}>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  margin: auto;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

interface ProjectDetailsProps {
  stepsStatus: boolean[];
  data: GetProjectResponse;
}

export const ProjectDetails = ({ stepsStatus, data }: ProjectDetailsProps) => {
  const { url: baseUrl } = useGetApiBaseUrl();

  const onUrlCopy = () => {
    navigator.clipboard.writeText(baseUrl);
    notification.success({
      message: 'Success',
      description: 'URL copied successfully',
    });
  };

  return (
    <Container>
      <Header src={WelcomeHeaderImage}>Welcome to Cosmocloud</Header>
      <Flex width="100%" justifyContent="space-between" gap={10}>
        <Paper size="medium" height={200}>
          <Flex direction="column" gap={16}>
            <Flex>
              <Text appearance="heading.card">Project details</Text>
            </Flex>
            <Flex>
              <ProjectInfoField name="Project Name" value={data?.name || ''} />
              <ProjectInfoField
                name="Cloud Provider"
                value={data?.deployment?.cloudProvider || ''}
              />
              <ProjectInfoField
                name="Region"
                value={data?.deployment?.region || ''}
              />
            </Flex>
            <Flex gap={8} direction="column">
              <Text appearance="label.long.regular" color="neutral.darker">
                API base URL
              </Text>
              <UrlContainer onClick={onUrlCopy}>
                <Text appearance="caption.regular">
                  {baseUrl}
                  {'/<'}env_name
                  {'>'}
                  /api
                  {'/<'}endpoints
                  {'>'}
                </Text>
              </UrlContainer>
            </Flex>
          </Flex>
        </Paper>
        <FlexItem direction="column">
          <QuickStartGuide
            stepsStatus={stepsStatus}
            showDescription={false}
            title="You are all set!"
          />
          <AddTeamMemberInvite />
        </FlexItem>
      </Flex>
    </Container>
  );
};
