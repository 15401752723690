import { Card, Input, Select, InputNumber } from 'antd';
import FormList from 'antd/es/form/FormList';
import { Flex, Form, FormItem } from '@comet/blocks';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { FieldCheckObject } from '../types';

type CustomCardProps = {
  restField: any;
  name: any;
  fieldOptions: any;
  form: any;
  remove: any;
  setNumberOfFields?: any;
};

export const CustomCard = ({
  restField,
  name,
  fieldOptions,
  form,
  remove,
  setNumberOfFields,
}: CustomCardProps) => {
  const [isFilterTrue, setIsFilterTrue] = useState(false);
  const { fieldOptionsFilter, fieldOptionsVector } = fieldOptions;

  useEffect(() => {
    if (
      form &&
      name >= 0 &&
      form.getFieldValue('fields')[name]?.searchType === 'filter'
    ) {
      setIsFilterTrue(true);
    }
  }, []);

  return (
    <Card title={null}>
      <>
        <Flex gap={8} alignItems="center" style={{ marginTop: 10 }}>
          <Flex>
            <FormItem
              label="Type"
              {...restField}
              name={[name, 'searchType']}
              rules={[{ required: true, message: 'Missing search type' }]}
            >
              <Select
                placeholder="Select the search type"
                options={[
                  { label: 'vector', value: 'vector' },
                  { label: 'filter', value: 'filter' },
                ]}
                style={{ width: 250 }}
                onChange={(value) => {
                  setIsFilterTrue(value === 'filter');
                }}
              />
            </FormItem>
          </Flex>
          <FormItem
            label="Field Name"
            {...restField}
            name={[name, 'path']}
            rules={[
              { required: true, message: 'Missing field name' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const defaultFields = getFieldValue('defaultFields');
                  const myfields = [...getFieldValue('fields'), defaultFields];

                  if (
                    !value ||
                    myfields.filter(
                      (f: { path: string }) =>
                        f?.path === value || value === defaultFields?.path
                    ).length <= 1
                  ) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error('Duplicate fields not allowed!')
                  );
                },
                message: 'Duplicate fields not allowed!',
              }),
            ]}
          >
            <Select
              options={isFilterTrue ? fieldOptionsFilter : fieldOptionsVector}
              placeholder="Select vector embeddings field"
              style={{ width: 250 }}
            />
          </FormItem>
          <MinusCircleOutlined
            onClick={() => {
              remove(name);
              setNumberOfFields((prev: FieldCheckObject) => {
                return {
                  initial: false,
                  value: prev.value + 1,
                };
              });
            }}
          />
        </Flex>
        {!isFilterTrue && (
          <Flex gap={8} alignItems="center" style={{ marginTop: 10 }}>
            <Flex>
              <FormItem
                label="Number Of Dimensions"
                {...restField}
                name={[name, 'noOfDimensions']}
                rules={[
                  {
                    required: !isFilterTrue,
                    message: 'Missing field name',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value > 0 && value < 4097) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Value should be between 1 and 4096')
                      );
                    },
                  }),
                ]}
              >
                <InputNumber
                  placeholder="Number of dimensions"
                  style={{ width: 250 }}
                />
              </FormItem>
            </Flex>

            <Flex>
              <FormItem
                label="Similarity Function"
                {...restField}
                name={[name, 'similarityFunction']}
                rules={[
                  {
                    required: !isFilterTrue,
                    message: 'Missing field name',
                  },
                ]}
              >
                <Select
                  placeholder="Select function type"
                  options={[
                    { label: 'euclidean', value: 'euclidean' },
                    { label: 'cosine', value: 'cosine' },
                    { label: 'dotProduct', value: 'dotProduct' },
                  ]}
                  style={{ width: 250 }}
                />
              </FormItem>
            </Flex>
          </Flex>
        )}
      </>
    </Card>
  );
};
