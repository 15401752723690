import { Flex, FormItem, InputNumber, Label, Text } from '@comet/blocks';
import { GB_TO_BYTES, StorageAccountStateMapping } from '../constants';
import { useEffect, useState } from 'react';

import { EditableText } from '@comet/components/Editable/Text';
import { FormInstance } from 'antd';
import { StatusComponent } from '@comet/components/TableComponents';
import _ from 'lodash';
import { formatTime } from '../StorageAccountList/StorageAccountList.util';
import { useTheme } from 'styled-components';
import { validateStorageAccountSize } from '../service';

interface StorageAccountDetailsProps {
  name: string;
  space: number;
  usedSize: number;
  status: string;
  environment: string;
  createdOn: number;
  updatedOn: number;
  createdBy: string;
  updatedBy: string;
  form: FormInstance;
}

export const UpdateStorageAccountForm = ({
  name,
  space,
  status,
  environment,
  createdOn,
  createdBy,
  updatedOn,
  updatedBy,
  usedSize,
  form,
}: StorageAccountDetailsProps) => {
  const theme = useTheme();
  const statusText = StorageAccountStateMapping['ACTIVE'][0];
  const statusColor =
    _.get(theme, StorageAccountStateMapping['ACTIVE'][1]) ||
    StorageAccountStateMapping[status][1];
  const handleChange = (key: string, value: number) => {
    form.setFieldValue(key, value);
    setSpaceInGb(value);
  };
  const [spaceInGB, setSpaceInGb] = useState(space / GB_TO_BYTES);

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={10}>
        <Label required={true}>Bucket name</Label>
        <Text appearance="label.short.regular">{name}</Text>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Status</Label>
        <Text appearance="label.short.regular">
          <StatusComponent color={statusColor}>{statusText}</StatusComponent>
        </Text>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Environment</Label>
        <Text appearance="label.short.regular">{environment}</Text>
      </Flex>

      <Flex direction="column" gap={10}>
        <Label required={true}>Storage size (in GB's)</Label>
        <EditableText
          type="number"
          name={'spaceInBytes'}
          onSave={(value) => handleChange('space', value)}
          value={spaceInGB}
          validator={[
            { required: true, message: 'Size is required' },
            { validator: validateStorageAccountSize },
          ]}
        />
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Used size</Label>
        <Text appearance="label.short.regular">{usedSize}</Text>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Created On</Label>
        <Text appearance="label.short.regular">{formatTime(createdOn)}</Text>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Created By</Label>
        <Text appearance="label.short.regular">{createdBy}</Text>
      </Flex>

      <Flex direction="column" gap={10}>
        <Label required={true}>Updated On</Label>
        <Text appearance="label.short.regular">{formatTime(updatedOn)}</Text>
      </Flex>
      <Flex direction="column" gap={10}>
        <Label required={true}>Updated By</Label>
        <Text appearance="label.short.regular">{updatedBy}</Text>
      </Flex>
    </Flex>
  );
};
