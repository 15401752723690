import {
  Environment,
  GetEnvironmentResponse,
  GetEnvironmentsParams,
  SUBSCRIPTION_STATUS,
} from '../types/Environment.types';
import {
  EnvironmentRawModel,
  EnvironmentUpdateRawData,
} from '../types/EnvironmentDetails.types';
import { queryClient, useMutation, useQuery } from '@comet/query';

import { getAxios } from '@comet/axios';
import { notification } from 'antd';
import { useParams } from '@comet/router';
import { sendErrorNotification } from 'src/blocks/Notification';
import { VerifyOTPResponse } from '@comet/pages/Project/Project.types';

export const createEnvironment = (
  projectId: string,
  modelData: EnvironmentRawModel
) => {
  const url = `/resources/${projectId}/environments`;

  return getAxios()
    .post<{ id: string }>(url, modelData)
    .then(({ data }) => data)
    .catch((error) => {
      const desc =
        error.response.data.message ||
        'There was an error creating the environment.';

      sendErrorNotification({
        message: 'Error',
        description: desc,
      });
      throw error;
    });
};

export const useGetEnvironmentsQuery = (
  projectId: string,
  params?: GetEnvironmentsParams
) => {
  return useQuery({
    queryKey: ['useGetEnvironmentsQuery', projectId, params],
    queryFn: async () => {
      const data = await getEnvironments(projectId, params);
      return data;
    },
  });
};

export const getEnvironments = async (
  projectId: string,
  params?: GetEnvironmentsParams
) => {
  const url = `resources/${projectId}/environments`;
  const { data } = await getAxios().get<GetEnvironmentResponse>(url, {
    params,
  });
  return data;
};

export const deleteEnvironment = (projectId: string, environmentId: string) => {
  const url = `/resources/${projectId}/environments/${environmentId}`;
  return getAxios().delete(url);
};

export const reactivateEnvironment = (
  projectId: string,
  environmentId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/reactivate`;
  return getAxios().put(url);
};

export const useEnvironmentExistsQuery = (name: string, type: string) => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/environments/exists`;

  return useQuery({
    enabled: false,
    queryKey: ['useEnvironmentExistsQuery', name, projectId],
    queryFn: async () => {
      const { data } = await getAxios().get(url, {
        params: { name, type },
      });

      return data;
    },
  });
};

export const useEnvironmentQuery = (
  projectId: string,
  environmentId: string
) => {
  return useQuery({
    queryKey: ['useEnvironmentQuery', projectId, environmentId],
    queryFn: async () => {
      return getEnvironment(projectId, environmentId);
    },
  });
};

export const getEnvironment = (projectId: string, environmentId: string) => {
  const url = `/resources/${projectId}/environments/${environmentId}`;

  return getAxios()
    .get<Environment>(url)
    .then(({ data }) => data);
};

//update environemnt
export const useEnvironmentMutation = () => {
  const { projectId, environmentId } = useParams();
  return useMutation({
    mutationFn: (environmentData: EnvironmentUpdateRawData | null) =>
      updateEnvironment(projectId, environmentId, environmentData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useEnvironmentQuery', projectId, environmentId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetEnvironmentsQuery', projectId],
      });
      notification.success({
        message: 'Success',
        description: 'Environement updated successfully',
      });
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'There was an error updating the environment.',
        reportBug: true,
      });
    },
  });
};

export const updateEnvironment = async (
  projectId: string,
  environmentId: string,
  environmentData: EnvironmentUpdateRawData | null
) => {
  const url = `/resources/${projectId}/environments/${environmentId}`;

  const { data } = await getAxios().patch(url, environmentData || {});

  return data;
};

export const useGetSubscriptionStatus = () => {
  const { organisationId, projectId } = useParams();

  const url = `/resources/${projectId}/billing/subscription/status`;

  return useQuery({
    queryKey: ['useGetSubscriptionStatus', organisationId, projectId],
    queryFn: async () => {
      const { data } = await getAxios().get<{
        status: SUBSCRIPTION_STATUS;
      }>(url);

      return data;
    },
    cacheTime: 0,
  });
};

interface PostSubscriptionResponse {
  hostedPageUrl: string;
}

export const updateSubscription = async (projectId: string) => {
  const url = `resources/${projectId}/billing`;

  const { data } = await getAxios().put<PostSubscriptionResponse>(url);

  return data;
};

export const useUpdateSubscriptionMutation = () => {
  const { organisationId, projectId } = useParams();

  return useMutation({
    mutationFn: (_: any) => {
      return updateSubscription(projectId);
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'There was an error updating the subscription.',
        reportBug: true,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useGetSubscriptionStatus', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetEnvironmentsQuery', projectId],
      });
      queryClient.refetchQueries({
        queryKey: ['useGetEnvironmentsQuery', projectId],
      });
      queryClient.refetchQueries({
        queryKey: ['useGetSubscriptionStatus', organisationId, projectId],
      });
    },
  });
};

export const sendOTPToDeleteEnvironment = async (
  projectId: string,
  environmentId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/delete/send-otp`;

  return getAxios().get(url);
};

export const resendOTPToDeleteEnvironment = async (
  projectId: string,
  environmentId: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/delete/resend-otp`;

  return getAxios().get(url);
};

export const verifyOTPToDeleteEnvironment = async (
  projectId: string,
  environmentId: string,
  otp: string
) => {
  const url = `/resources/${projectId}/environments/${environmentId}/delete/verify-otp?otp=${otp}`;

  return getAxios().get<VerifyOTPResponse>(url);
};
