import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';

export const StyledNodeName = styled.div`
  max-width: 90%;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  white-space: nowrap;
  color: #000000;
  opacity: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledNodeCard = styled(Flex)<{ isSelected?: boolean }>`
  width: 23%;
  height: 76px;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? '#F7F0FF' : 'white')};
  border: ${(props) =>
    props.isSelected ? '1px solid #6C3DD1' : '1px solid #D9D9D9'};
  box-shadow: ${(props) =>
    props.isSelected ? '' : '0px 2px 8px 0px #00000026'};
  &:hover {
    background-color: #f7f0ff;
  }
`;

export const ScrollbarHiddenFlex = styled(Flex)`
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: scroll;
  padding: 12px;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
