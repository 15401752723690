import { memo } from 'react';
import { Wrapper } from '../../common/styled';
import { Tag } from '@comet/blocks';
import { CometNodeBaseProps } from '../../Nodes';
import ActionNode from '../../common/ActionNode/ActionNode';

export const ConcurrentNodeDimension = {
  height: 80,
  width: 220,
};

export const ConcurrentNodePreview = () => {
  return (
    <Wrapper>
      <Tag.CheckableTag checked>Concurrent Node</Tag.CheckableTag>
    </Wrapper>
  );
};

const ConcurrentNode = (props: CometNodeBaseProps) => {
  return <ActionNode {...props} />;
};

export default memo(ConcurrentNode);
