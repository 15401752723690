import './style.css';

import { Handle, Position, useNodeId } from 'reactflow';

import { useFlowEngineStore } from '../store';

export function AddNode() {
  const nodeId = useNodeId();

  const clickNode = useFlowEngineStore((s) => s.clickNode);

  function handleNodeClick() {
    clickNode(nodeId);
  }

  if (!nodeId) return null;

  return (
    <div className="extra-node-style" onClick={handleNodeClick}>
      <div className="extra-node-content">
        <Handle type="target" position={Position.Top} />
        <div>Add Node +</div>
      </div>
    </div>
  );
}
