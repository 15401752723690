import { Form, FormItemProps as AntdFormItemProps } from 'antd';
import { styled } from '@comet/styled';

interface FormItemProps extends AntdFormItemProps {
  labelWidth?: React.CSSProperties['width'];
  marginBottom?: React.CSSProperties['marginBottom'];
}

const StyledFormItem = styled(Form.Item)`
  label {
    font-size: ${({ theme }) =>
      theme.constants.font['label.short.regular'].style.fontSize};
    font-weight: ${({ theme }) =>
      theme.constants.font['label.short.regular'].style.fontWeight};
    line-height: ${({ theme }) =>
      theme.constants.font['label.short.regular'].style.lineHeight};
  }
  label.ant-form-item-required {
    ::before {
      display: none !important;
    }
    ::after {
      content: '•' !important;
      display: block;
      top: -4px;
      margin-inline-end: 0px !important;
      position: relative;
      color: ${({ theme }) => theme.palette.danger} !important;
    }
  }
`;

const FormItem = ({
  labelWidth,
  wrapperCol,
  labelCol,
  marginBottom,
  colon,
  ...props
}: FormItemProps) => {
  return (
    <StyledFormItem
      colon={colon}
      {...props}
      style={{
        marginBottom: marginBottom,
        ...props.style,
      }}
      labelAlign="left"
      labelCol={{
        ...labelCol,
        style: {
          ...labelCol?.style,
          width: labelWidth,
        },
      }}
      wrapperCol={{
        ...wrapperCol,
        style: {
          ...wrapperCol?.style,
          flexGrow: 2,
          width: 'auto',
          flex: 'auto',
        },
      }}
    />
  );
};

export { FormItem };
export type { FormItemProps };
