import {
  Button,
  ButtonContainer,
  Flex,
  Form,
  Grid,
  Input,
  Paper,
  Select,
  Text,
} from '@comet/blocks';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ChangeEvent } from 'react';
import { EnvironmentTypesOptions } from '../../Project/Environments/constants';
import { useGoBack, useParams } from '@comet/router';
import {
  useCreateBulkEnvironmentsMutation,
  useStartSubscriptionMutation,
} from './AddEnvironment.service';
import {
  BulkEnvironmentType,
  BulkEnvironmentsRawData,
} from '../types/AddEnvironments.types';
import { useGetTiersOptions } from '@comet/pages/Project/Environments/utils';
import { useAddBulkEnvironmentForm } from '../utils/useAddBulkEnvironmentForm';
import { AddEnvironmentsFormHeader } from './AddEnvironments.FormHeader';
import { useEnvironmentFieldsValidator } from '../utils/environmentFieldsValidators';
import { ResourceTypesOptions } from '../constants';
import { Pricing } from './AddBulkEnvironment.Pricing';
import { UpdateAndContinue } from './UpdateAndContinue';
import { Space } from 'antd';
import { ExternalLink } from '@comet/components/ExternalLink';
import { MdOpenInNew } from 'react-icons/md';

export const AddEnvironments = () => {
  const TiersOptions = useGetTiersOptions();

  const { projectId, organisationId } = useParams();

  const {
    addField,
    envNames,
    environments,
    form,
    onCurrencyChange,
    onEnvironmentTypeChange,
    onEnvironmentTierChange,
    onEnvironmentNameChange,
    onResourceTypeChange,
    removeField,
    selectedCurrency,
    totalPrice,
  } = useAddBulkEnvironmentForm();

  const {
    environmentTypeFieldValidator,
    environmentNameFieldValidator,
    sharedEnvironmentFieldValidator,
  } = useEnvironmentFieldsValidator();

  const createBulkEnvironmentsMutation =
    useCreateBulkEnvironmentsMutation(selectedCurrency);

  const startSubscriptionMutation = useStartSubscriptionMutation();

  const goBack = useGoBack();

  const onSubmit = async (submitData: {
    customerType: string;
    placeOfContact: string | null;
  }) => {
    const formData: { fields: BulkEnvironmentType[] } = form.getFieldsValue();

    const environmentsData: BulkEnvironmentsRawData = {
      environments: formData.fields,
    };

    await createBulkEnvironmentsMutation.mutateAsync(environmentsData);

    const redirectUrl =
      (process.env.REACT_APP_COMET_BASE_URL ?? 'http://localhost:3000') +
      `/organisations/${organisationId}/projects/${projectId}/billing/status`;

    const startSubscriptionData = {
      currency: selectedCurrency,
      redirectUrl,
      ...submitData,
    };

    startSubscriptionMutation.mutate(startSubscriptionData);
  };

  const isSubmitButtonLoading =
    createBulkEnvironmentsMutation.isLoading ||
    startSubscriptionMutation.isLoading;

  return (
    <Flex justifyContent="center">
      <Paper size="large" parentWidth={'95%'}>
        <Form form={form}>
          <Flex direction="column" gap={10}>
            <Grid gap={8} templateColumns="1fr" templateRows="1fr 1fr 1fr">
              <Space>
                <Text appearance="heading.paragraph">
                  How many environments would you like to upgrade with?
                </Text>
                <ExternalLink
                  href="https://docs.cosmocloud.io/cosmocloud-documentation/upgrading-projects"
                  appearance="caption.semibold"
                >
                  <MdOpenInNew />
                  Learn More
                </ExternalLink>
              </Space>
              <AddEnvironmentsFormHeader />
              <Form.List name="fields">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => {
                      return (
                        <Grid
                          templateColumns="repeat(6, 1fr)"
                          gap={10}
                          key={key}
                          style={{ marginBottom: '8px' }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            rules={[
                              {
                                required: true,
                                message: 'Environment type is required',
                              },
                              environmentTypeFieldValidator,
                            ]}
                            style={{ marginBottom: 0, width: 180 }}
                          >
                            <Select
                              options={EnvironmentTypesOptions}
                              placeholder="Select Environment type"
                              onChange={(value: string) => {
                                onEnvironmentTypeChange(name, value);
                              }}
                              disabled={environments[index]?.type?.disabled}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Environment name is required!',
                              },
                              environmentNameFieldValidator,
                            ]}
                            style={{ marginBottom: 0, width: 180 }}
                          >
                            <Input
                              placeholder="Environment name"
                              value={environments[index]?.name?.value}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                onEnvironmentNameChange(name, e.target.value);
                              }}
                              disabled={environments[index]?.name?.disabled}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'metadata', 'tier']}
                            rules={[
                              {
                                required: !environments[index].isShared.value,
                                message:
                                  'Tier is required when resource is dedicated',
                              },
                            ]}
                            style={{ marginBottom: 0, width: 180 }}
                          >
                            <Select
                              placeholder="Select Tier"
                              options={TiersOptions}
                              onChange={(value) => {
                                onEnvironmentTierChange(name, value);
                              }}
                              disabled={environments[index]?.tier?.disabled}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'metadata', 'isShared']}
                            rules={[
                              {
                                required: true,
                                message: 'Resource type is required!',
                              },
                            ]}
                            initialValue={environments[index]?.isShared.value}
                            style={{ marginBottom: 0, width: 180 }}
                          >
                            <Select
                              placeholder="Select resource type"
                              options={ResourceTypesOptions}
                              onChange={(value) => {
                                onResourceTypeChange(name, value);
                              }}
                              style={{ width: 180 }}
                              disabled={environments[index]?.isShared?.disabled}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'metadata', 'sharedEnvName']}
                            rules={[
                              {
                                required: environments[index].isShared.value,
                                message:
                                  'Environment name is required when resource is shared',
                              },
                              sharedEnvironmentFieldValidator,
                            ]}
                            style={{ marginBottom: 0, width: 180 }}
                          >
                            <Select
                              placeholder="Select environment to share the resources"
                              options={envNames}
                              disabled={
                                environments[index]?.sharedEnvName?.disabled
                              }
                            />
                          </Form.Item>
                          <Flex alignItems="center">
                            <MinusCircleOutlined
                              style={{ fontSize: '1rem' }}
                              onClick={() => {
                                remove(name);
                                removeField(index);
                              }}
                              hidden={
                                environments[index].type.value === 'DEVELOPMENT'
                              }
                            />
                          </Flex>
                        </Grid>
                      );
                    })}
                    <Grid alignItems="center" justifyContent="center">
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                          addField();
                        }}
                        icon={<PlusOutlined />}
                        style={{ width: 250 }}
                      >
                        Add field
                      </Button>
                    </Grid>
                  </>
                )}
              </Form.List>
            </Grid>
            <ButtonContainer>
              <Button
                onClick={goBack}
                appearance="transparent"
                htmlType="button"
              >
                Cancel
              </Button>
              <Flex direction="column" gap={8}>
                <Pricing
                  currency={selectedCurrency}
                  setCurrency={onCurrencyChange}
                  totalPrice={totalPrice}
                />
                <UpdateAndContinue
                  isLoading={isSubmitButtonLoading}
                  onSubmit={onSubmit}
                  disabled={totalPrice == 0}
                />
              </Flex>
            </ButtonContainer>
          </Flex>
        </Form>
      </Paper>
    </Flex>
  );
};
