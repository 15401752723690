import './style.css';

import { Handle, Position, useNodeId } from 'reactflow';

import { useCallback } from 'react';
import { useFlowEngineStore } from '../store';

export function SwitchV2Node() {
  const onDelete = useFlowEngineStore(useCallback((s) => s.onDeleteNode, []));
  const nodeId = useNodeId();

  function handleNodeClick() {
    // Yet to implemented
  }

  if (!nodeId) return null;
  return (
    <div className="node-style drag" onClick={handleNodeClick}>
      <Handle type="target" position={Position.Top} />
      <p>{'This node is not implemented yet'}</p>
      <Handle type="source" position={Position.Bottom} />
      <button className="deleteBtn" onClick={() => onDelete(nodeId)}>
        X
      </button>
    </div>
  );
}
