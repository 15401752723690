import { useAuth } from '@comet/components/auth/AuthProvider';
import { useOrgsQuery } from '@comet/pages/Organisation/service';
import { useMemo } from 'react';

export const useOrgCreatedBy = () => {
  const { user } = useAuth();
  const { data: orgsData } = useOrgsQuery({});

  return useMemo(() => {
    const orgList = orgsData?.data.filter((org) => {
      return org.createdBy.id === user?.id;
    });
    const isCreateOrgEnabled = Array.isArray(orgList) && orgList.length === 0;
    return { isCreateOrgEnabled };
  }, [orgsData, user]);
};
