import { ApiDetails, ApiListing, CreateApi } from '@comet/pages/Api';
import { Route, Routes, useLocationChange, withPath } from '@comet/router';
import {
  AnalyzerDetails,
  AnalyzersList,
  SearchIndexList,
} from './FullTextSearch';
import { CreateModal, ModelDetails, ModelList } from './Model';
import { CreateOrganization, OrganisationList } from './Organisation';
import {
  CreateEnvironment,
  EnvironmentDetails,
  Environments,
} from './Project/Environments';

import { AppContainer } from '@comet/components/AppContainer/AppContainer';
import Authenticated from '@comet/components/auth/Authenticated';
import { Subflows } from '@comet/pages/Project/Subflows/SubflowsList';
import { ApiTemplates } from './Api/Templates';
import ConfirmUser from './Auth/ConfirmUser';
import ForgotPassword from './Auth/ForgotPassword';
import Login from './Auth/Login';
import SignUp from './Auth/SignUp';
import Dashboard from './Dashboard';
import FlowEngineTest from './FlowEngineTest';
import { AnalyzerForm } from './FullTextSearch/Analyzer/CreateAnalyzerForm';
import { SearchIndexForm } from './FullTextSearch/SearchIndex/CreateSearchIndexForm';
import { SearchIndexDetails } from './FullTextSearch/SearchIndex/SearchIndex.Details';
import { InvalidRoute } from './InvalidRoute/InvalidRoute';
import { Settings as OrgSettings } from './Organisation/Settings';
import { Storage } from './Organisation/Storage';
import { CreateStorageAccount } from './Organisation/Storage/CreateStorage';
import { OrgUserManagement } from './Organisation/UserManagement';
import { Dashboard as ProjectDashboard } from './Project/Dashboard';
import { ProjectDeployments } from './Project/Deployments';
import NewProject from './Project/NewProject/NewProject';
import { ProjectList } from './Project/ProjectList/ProjectList';
import { SecretList } from './Project/Secrets';
import { CreateSecret } from './Project/Secrets/CreateSecret';
import { SecretDetails } from './Project/Secrets/SecretDetails';
import { Settings as ProjectSettings } from './Project/Settings';
import { DatabasesList } from './Project/SpinUpDatabase';
import { CreateDatabase } from './Project/SpinUpDatabase/CreateDatabase/CreateDatabase';
import { DatabaseDetails } from './Project/SpinUpDatabase/DatabaseDetails';
import { StorageAccounts } from './Project/StorageAccounts';
import { CreateProjectStorageAccount } from './Project/StorageAccounts/CreateStorageAccount';
import { StorageAccountDetails } from './Project/StorageAccounts/StorageAccountDetails/StorageAccountDetails';
import { ProjectUserManagement } from './Project/UserManagement';
import { SwaggerDocumentation } from './Swagger';
import { UpgradePlan } from './UpgradePlan';
import { WaitingScreen } from './UpgradePlan/WaitingScreen';
import { saveLastVisitedPage } from './User/User.service';
import { Waitlist } from './Waitlist';
import CreateSubflow from '@comet/pages/Project/Subflows/CreateSubflow/CreateSubflow';
import SubflowDetails from '@comet/pages/Project/Subflows/SubflowDetails/SubflowDetails';
import { LogsList } from './Logs/LogsList/LogsList';
import { VectorSearchIndexList } from './FullTextSearch/VectorSearch';
import { VectorSearchIndexForm } from './FullTextSearch/VectorSearch/CreateVectorSearchIndexForm';
import { VectorSearchIndexDetails } from './FullTextSearch/VectorSearch/VectorSearchIndex.Details';
import Payments from '@comet/pages/Payments/Payments';

const Organisations = () => {
  return (
    <Routes>
      <Route path="/" element={<AppContainer />}>
        <Route index element={<OrganisationList />} />
        <Route path="new" element={<CreateOrganization />} />
      </Route>
      {/* Projects */}
      <Route
        path=":organisationId/projects/:projectId"
        element={<AppContainer sideNav="PROJECT" />}
      >
        <Route path="upgrade" element={<UpgradePlan />} />
        <Route path="environments">
          <Route index element={<Environments />} />
          <Route path="new" element={<CreateEnvironment />} />
          <Route
            path=":environmentId/details"
            element={<EnvironmentDetails />}
          />
        </Route>

        <Route path="subflows">
          <Route index element={<Subflows />} />
          <Route path="new" element={<CreateSubflow />} />
          <Route path=":subflowId/details" element={<SubflowDetails />} />
        </Route>

        <Route path="databases">
          <Route index element={<DatabasesList />} />
          <Route path="new" element={<CreateDatabase />} />
          <Route path=":instanceId" element={<DatabaseDetails />} />
        </Route>

        <Route path="storageAccounts">
          <Route index element={<StorageAccounts />} />
          <Route path="new" element={<CreateProjectStorageAccount />} />
          <Route
            path=":storageAccountId/details"
            element={<StorageAccountDetails />}
          />
        </Route>

        <Route path="secrets">
          <Route index element={<SecretList />} />
          <Route path="new" element={<CreateSecret />} />
          <Route path=":secretId/details" element={<SecretDetails />} />
        </Route>

        <Route path="dashboard" element={<ProjectDashboard />} />
        <Route path="api">
          <Route index element={<ApiListing />} />
          <Route path="new" element={<CreateApi />} />
          <Route path=":apiId/details" element={<ApiDetails />} />
          <Route path="templates" element={<ApiTemplates />} />
        </Route>
        <Route path="models">
          <Route index element={<ModelList />} />
          <Route path="new" element={<CreateModal />} />
          <Route path=":modelId/details" element={<ModelDetails />} />
        </Route>
        <Route path="dbModels">
          <Route index element={<ModelList />} />
          <Route path="new" element={<CreateModal />} />
          <Route path=":modelId/details" element={<ModelDetails />} />
        </Route>
        <Route path="releases" element={<ProjectDeployments />} />
        <Route path="logs" element={<LogsList />} />
        <Route path="users" element={<ProjectUserManagement />} />
        <Route path="settings" element={<ProjectSettings />} />
        <Route path="analyzers" element={<AnalyzersList />} />
        <Route path="analyzers/new" element={<AnalyzerForm />} />
        <Route path="analyzers/:analyzerId" element={<AnalyzerDetails />} />
        <Route path="search-indexes" element={<SearchIndexList />} />
        <Route path="search-indexes/new" element={<SearchIndexForm />} />
        <Route
          path="search-indexes/:searchIndexId"
          element={<SearchIndexDetails />}
        />
        <Route path="fullTextSearch">
          <Route path="searchIndex" element={<SearchIndexList />} />
        </Route>
        <Route
          path="vector-search-indexes"
          element={<VectorSearchIndexList />}
        />
        <Route
          path="vector-search-indexes/new"
          element={<VectorSearchIndexForm />}
        />
        <Route
          path="vector-search-indexes/:vectorSearchIndexId"
          element={<VectorSearchIndexDetails />}
        />

        <Route path="billing">
          <Route path="status" element={<WaitingScreen />} />
        </Route>
        <Route path="*" element={<InvalidRoute />} />
      </Route>
      {/* Organisation */}
      <Route path=":organisationId" element={<AppContainer sideNav="ORG" />}>
        <Route path="projects" element={<ProjectList />} />
        <Route path="projects/new" element={<NewProject />} />
        <Route path="users" element={<OrgUserManagement />} />
        <Route path="settings" element={<OrgSettings />} />
        <Route path="payments" element={<Payments />} />
        <Route path="storage" element={<Storage />} />
        <Route path="storage/create" element={<CreateStorageAccount />} />

        <Route path="*" element={<InvalidRoute />} />
      </Route>
    </Routes>
  );
};

const PrivatePages = () => {
  useLocationChange(saveLastVisitedPage);
  return (
    <Authenticated>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/organisations/*" element={<Organisations />} />
        <Route path="/welcome" element={<AppContainer />}>
          <Route index element={<Waitlist />} />
        </Route>
        {/* Swagger Documentation */}
        <Route
          path="projects/:projectId/docs/:version"
          element={<SwaggerDocumentation />}
        />
        <Route path="*" element={<InvalidRoute />} />
      </Routes>
    </Authenticated>
  );
};

const Pages = () => {
  return (
    <Routes>
      <Route {...withPath('signIn')} element={<Login />} />
      <Route {...withPath('signUp')} element={<SignUp />} />
      <Route {...withPath('confirmUser')} element={<ConfirmUser />} />
      <Route {...withPath('flowEngineTest')} element={<FlowEngineTest />} />
      <Route {...withPath('forgotPassword')} element={<ForgotPassword />} />
      {/* Private pages */}
      <Route {...withPath('*')} element={<PrivatePages />} />
    </Routes>
  );
};

export default Pages;
