import { Dropdown, TableHeader } from '@comet/blocks';
import { Link } from '@comet/router';
import { useMemo } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Column } from 'react-table';

export const useStorageTableData = (
  data: StorageAccount[],
  onDisable: (storageAccountId: string) => void
) => {
  const columns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" />,
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Created by" sortable />,
        accessor: 'createdBy',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Status" sortable={false} />,
        accessor: 'status',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Actions" sortable={false} />,
        accessor: 'action',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!data || !Array.isArray(data) || data.length === 0) return [];
    return data.map((_item) => {
      return {
        name: _item.name,
        createdBy: (_item.createdBy as any)?.name,
        id: _item.id,
        status: _item.status,
        action: (
          <Dropdown
            arrow={true}
            menu={{
              items: [
                {
                  key: 'statusEnable',
                  label: _item.status === 'ACTIVE' ? 'Delete' : 'Enable',
                  onClick: () => onDisable(_item.id),
                },
              ],
            }}
          >
            <FiMoreHorizontal />
          </Dropdown>
        ),
      };
    });
  }, [data, onDisable]);

  return { tableData, columns };
};
