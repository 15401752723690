import { Flex, Show, Text } from '@comet/blocks';

interface IUserDetailsProps {
  id: string;
  picture: string | null;
  name: string;
}

export const User = (props: IUserDetailsProps) => {
  if (!props) return null;

  return (
    <Flex>
      <Text appearance="label.short.regular">{props.name}</Text>
      <Show if={!!props.picture}>
        <img src={props.picture!} alt={props.name} />
      </Show>
    </Flex>
  );
};
