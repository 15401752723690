import { Button, Flex, Form, FormItem, Modal, Select } from '@comet/blocks';
import { useState } from 'react';
import { customerTypesOptions } from '../constants/CustomerTypes';
import { CustomerTypesEnum } from '../types/CustomerTypes';
import { PlaceOfContactOptions } from '../constants/States';

interface UpdateAndContinueProps {
  isLoading: boolean;
  onSubmit: (data: {
    customerType: string;
    placeOfContact: string | null;
  }) => void;
  disabled: boolean;
}

export const UpdateAndContinue = ({
  isLoading,
  onSubmit,
  disabled,
}: UpdateAndContinueProps) => {
  const [isVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [customerType, setCustomerType] = useState('');
  const [placeOfContact, setPlaceOfContact] = useState<string | null>(null);

  const onCustomerTypeChange = (value: string) => {
    setCustomerType(value);
    setPlaceOfContact(null);
    form.setFieldValue('placeOfContact', null);
  };

  const isPlaceOfContactRequired =
    customerType === CustomerTypesEnum.BUSINESS_GST;

  const toggleModal = () => {
    setModalVisible((isVisible: boolean) => !isVisible);
  };

  return (
    <div>
      <Button appearance="primary" onClick={toggleModal} disabled={disabled}>
        Save and Continue
      </Button>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={''}
        open={isVisible}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Form autoComplete="off" layout="vertical" form={form}>
          <Flex gap={16} direction="column">
            <FormItem
              name="customerType"
              label="Type of bussiness"
              required
              rules={[
                { required: true, message: 'Customer Type is required!' },
              ]}
              marginBottom={0}
            >
              <Select
                options={customerTypesOptions}
                value={customerType}
                onChange={onCustomerTypeChange}
                placeholder="Type of business"
              />
            </FormItem>
            <FormItem
              name="placeOfContact"
              label="Place of contact"
              required={isPlaceOfContactRequired}
              rules={[
                {
                  required: isPlaceOfContactRequired,
                  message: 'Place of contact is required!',
                },
              ]}
            >
              <Select
                placeholder="Place of business"
                options={PlaceOfContactOptions}
                onChange={setPlaceOfContact}
                value={placeOfContact}
                disabled={!isPlaceOfContactRequired}
              />
            </FormItem>
          </Flex>

          <Button
            appearance="primary"
            htmlType="submit"
            onClick={async () => {
              const updateData = {
                customerType,
                placeOfContact,
              };

              await onSubmit(updateData);
            }}
            loading={isLoading}
          >
            Save and Continue
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
