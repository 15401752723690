import Brackets from '@comet/assets/png/brackets.png';
import { GrShare } from 'react-icons/gr';
import { Flex, Text } from '@comet/blocks';
import { styled, useTheme } from '@comet/styled';
import { CreateApiModal } from '../CreateApi/CreateApiModal';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';

const BracketIcon = styled.img`
  width: 64px;
`;

const ShareIcon = styled(GrShare)`
  path {
    stroke: ${({ theme }) => theme.palette.primary};
  }
`;

const StyledText = styled(Text)`
  text-align: center;
`;

export const ApiListingEmpty = () => {
  const { theme } = useTheme();
  const { hasEditApiAccess } = useProjectPermissions();

  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <Flex direction="column" width={480} alignItems="center" gap={20}>
        <BracketIcon src={Brackets} />
        <Flex direction="column" gap={16}>
          <StyledText appearance="heading.page" color="neutral.darkest">
            Get started with APIs
          </StyledText>
          <StyledText appearance="body.regular" color="neutral.darker">
            Start with your goals and intended users. Design the API
            architecture. Develop your first API.
          </StyledText>
        </Flex>
        <CreateApiModal disabled={!hasEditApiAccess} name="New API" />
        <Flex
          gap={4}
          alignItems="center"
          style={{ color: theme.palette.primary }}
        >
          <ShareIcon />
          <Text appearance="caption.semibold">View documentation</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
