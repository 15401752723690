import styled from 'styled-components';
import { Flex } from '../Flex';

interface PaperProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'small' | 'medium' | 'large';
  height?: number;
  parentWidth?: number | string;
}

const Container = styled.div<{ size?: PaperProps['size']; height?: number }>`
  background: ${(props) => props.theme.palette.neutral.white};
  width: ${({ theme, size }) => theme.constants.paperSize[size || 'large']};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  margin-top: 24px;
  padding: 16px 24px;
  border-radius: ${({ theme }) => theme.constants.borderRadius.md};
  border: 1px solid ${(props) => props.theme.palette.neutral.light};

  @media screen and (max-width: ${({ theme }) => theme.devices.mobile}) {
    border: 0;
  }
`;

export const Paper = ({ parentWidth, ...props }: PaperProps) => {
  return (
    <Flex justifyContent="center" width={parentWidth}>
      <Container {...props} />
    </Flex>
  );
};

export const PaperFooter = styled.div`
  margin: 0 -24px;
  width: calc(100% + 48px);
  border-top: ${({ theme }) => theme.palette.neutral.light};
  padding: 16px 24px;
`;
