import React from 'react';
import { styled } from '@comet/styled';
import SchemaObject from './SchemaObject';
import { TSchema } from './SchemaTree.types';
import { Button, Flex, Show } from '@comet/blocks';
import { BsPlus } from 'react-icons/bs';
import { useSchemaStore } from '@comet/pages/Model/ModelSchema/ModelSchemaStore';
import { ModelTypesEnum } from '@comet/pages/Model/types';
import { MdOpenInNew } from 'react-icons/md';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.neutral.lightest};
  margin: 8px 0;
  padding: 8px;
  border-radius: 4px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.neutral.darker};
`;

const AddIcon = styled(BsPlus)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  border: 1px solid ${({ theme }) => theme.palette.neutral.lightest};
  border-radius: 4px;
  margin-left: 12px;
`;

interface IProps {
  schema: TSchema;
  disabled?: boolean;
  modelType?: ModelTypesEnum;
}

export default function SchemaTree(props: IProps) {
  const initialPath = '';

  const { schema, disabled, modelType } = props;

  const addProperty = useSchemaStore((state) => state.addProperty);

  const showBasic = modelType === ModelTypesEnum.QUERY_PARAMS;

  const handleAddTopLevelKey = () => {
    addProperty(initialPath);
  };

  return (
    <Container>
      <Flex justifyContent={'space-between'} style={{ padding: '0 30px' }}>
        <Title>Field name and type</Title>
        <Show if={!disabled}>
          <AddIcon onClick={handleAddTopLevelKey} />
        </Show>
      </Flex>
      <SchemaObject
        schema={schema}
        disabled={disabled}
        level={0}
        initialPath={initialPath}
        showBasic={showBasic}
      />
    </Container>
  );
}
