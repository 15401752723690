import { styled } from '@comet/styled';

export const EndpointComponent = ({ endpoint = '' }: { endpoint: string }) => {
  const endpointArray = endpoint.split('/');

  const Container = styled.div`
    color: ${(props) => props.theme.palette.neutral.darker};
    display: flex;
  `;

  const Param = styled.div`
    color: ${(props) => props.theme.palette.neutral.darkest};
  `;

  return (
    <Container>
      {endpointArray.map((str: string) => {
        if (!str) return null;

        if (str.startsWith('{')) {
          return <Param>/{str}</Param>;
        }

        return `/${str}`;
      })}
    </Container>
  );
};
