import { useEffect, useState } from 'react';

import { Button } from 'antd';
import { Flex } from '@comet/blocks';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { StateValue } from '.';
import StateValue2 from 'src/FlowEngine2/Components/StateValue2';

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
}

export const ListStateValue = ({ value = [''], onChange }: Props) => {
  const onChangeValue = (_value: string, index: number) => {
    const previous = [...value];
    previous[index] = _value;
    onChange(previous);
  };

  const onAddNew = () => {
    const previousValues = [...value];
    previousValues.push('');
    onChange(previousValues);
  };

  const onRemove = (index: number) => {
    const previousValues = [...value];
    previousValues.splice(index, 1);
    onChange(previousValues);
  };

  return (
    <>
      <Flex width={'100%'} direction="column">
        {value.map((_value, index) => {
          return (
            <Flex style={{ marginBottom: 10 }} alignItems="center" key={index}>
              <StateValue2
                value={_value ?? ''}
                onChange={(value) => onChangeValue(value, index)}
              />
              <IoIosRemoveCircleOutline
                onClick={() => onRemove(index)}
                style={{ marginLeft: 5 }}
                size={20}
              />
            </Flex>
          );
        })}
      </Flex>
      <Button onClick={onAddNew} style={{ marginTop: 10 }}>
        New Value
      </Button>
    </>
  );
};
