import {
  Alert,
  Button,
  Center,
  CustomSelect,
  Flex,
  FullCenter,
  Search,
  Show,
  Spin,
  Table,
  Text,
  usePagination,
} from '@comet/blocks';
import debounce from 'lodash.debounce';
import { useDeploymentsQuery } from './Deployments.service';
import { useParams } from '@comet/router';
import { Release } from './ReleaseModal';
import { ChangeEvent, useEffect, useState } from 'react';
import { Empty, Result } from 'antd';
import {
  useDeploymentListData,
  useDeploymentsVersion,
} from './Deployments.utils';
import { TableContainer } from '../Project.styled';
import { useProjectMembersOptions } from '../utils';
import _ from 'lodash';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import {
  useGetOrgSubscriptionQuery,
  useOrgQuery,
} from '@comet/pages/Organisation/service';
import { FreeTierSplash } from '@comet/components/FreeTierSplash';
import { useGetProjectQuery } from '../service';

export const ProjectDeployments = () => {
  const { organisationId, projectId } = useParams();
  const { pageOffset, currentPageIndex, fetchData } = usePagination();

  const { hasViewReleasesAccess } = useProjectPermissions();

  const {
    versions,
    isLoading: isVersionLoading,
    isError: isVersionError,
  } = useDeploymentsVersion(projectId);

  const initialValues = {
    offset: 0,
    updatedBy: '',
    query: '',
  };

  const [searchValue, setSearchValue] = useState('');
  const [updatedBy, setUpdatedBy] = useState(initialValues.updatedBy);
  const [query, setQuery] = useState(initialValues.query);

  const currentValues = {
    offset: pageOffset,
    updatedBy,
    query,
  };

  const {
    data: deployments,
    isLoading: isDeploymentsLoading,
    error,
    refetch,
  } = useDeploymentsQuery(projectId, {
    offset: pageOffset,
    query,
    updatedBy: updatedBy || null,
  });

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  // Polling when state is Pending
  const POLLING_INTERVAL = 2000;

  useEffect(() => {
    const pollRefetch = () => {
      if (deployments?.data[0]?.status === 'PENDING') {
        refetch();
      } else {
        return;
      }
    };

    const timerId = setInterval(pollRefetch, POLLING_INTERVAL);

    return () => {
      clearInterval(timerId);
    };
  }, [deployments?.data, refetch]);

  // Polling logic ends here

  const projectMembersOptions = useProjectMembersOptions(
    organisationId,
    projectId
  );

  const { tableColumns, tableData } = useDeploymentListData(
    deployments,
    refetch
  );

  const isLoading = isDeploymentsLoading || isVersionLoading;
  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !deployments || !deployments.data || isVersionError;
  const showList = !isLoading && !isError && deployments.data.length > 0;

  const { data: orgPlanData } = useGetOrgSubscriptionQuery(organisationId);
  const isOnFreeTier = orgPlanData?.planCode === 'FREE';

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isOnFreeTier)
    return (
      <FreeTierSplash message="This feature is not available on free tier, Upgrade your plan to use this service" />
    );

  if (!hasViewReleasesAccess) {
    return <ErrorPage />;
  }
  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Show if={versions[0]?.label === 'Draft'}>
        <Flex direction="column" style={{ background: '#FFFFFF' }}>
          <Alert
            message="Changes has been made to your project since last deploy."
            type="info"
            showIcon={true}
            action={<Release />}
            closable={true}
          />
        </Flex>
      </Show>

      <Flex direction="column" gap={10}>
        <Flex alignItems="center">
          <Text appearance="heading.card">Releases</Text>
        </Flex>
        <TableContainer>
          <Flex alignItems="center" padding={10} gap={10}>
            <Flex>
              <Search
                value={searchValue}
                onChange={onSearch}
                placeholder="Search by version"
              />
            </Flex>
            <CustomSelect
              label="Deployed by"
              width={'250px'}
              options={projectMembersOptions}
              value={updatedBy}
              onChange={setUpdatedBy}
            />
          </Flex>
          <Show if={isLoading}>
            <FullCenter padding={20}>
              <Spin size="large" />
            </FullCenter>
          </Show>
          <Show if={!isError && !isLoading && deployments?.data.length === 0}>
            <FullCenter padding={20}>
              <Empty
                image={EmptyImage}
                imageStyle={{ height: 225 }}
                description={<span>No releases found</span>}
              />
            </FullCenter>
          </Show>
          <Show if={showList}>
            <Table
              tableColumns={tableColumns}
              tableData={tableData}
              rowType="deployments"
              currentPageIndex={currentPageIndex}
              totalPageCount={Math.ceil((deployments?.page.total || 0) / 10)}
              totalRows={deployments?.page.total || 0}
              fetchData={fetchData}
            />
          </Show>
        </TableContainer>
      </Flex>
    </Flex>
  );
};
