import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';

export const FormButtonContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  align-items: center;
  border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
`;
