import { CSSProperties, useEffect } from 'react';
import { Column, useTable, usePagination, useSortBy } from 'react-table';
import { styled } from '@comet/styled';
import { Flex } from '../Flex';
import { Pagination } from './Pagination';
import FlexItem from '../FlexItem';

const TableContainer = styled(Flex)`
  background-color: ${({ theme }) => `${theme.palette.neutral.white}`};
  padding: 16px;
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.lighter}`};
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const StyledTable = styled.table`
  width: 100%;
  margin-top: 8px;
  color: ${({ theme }) => `${theme.palette.neutral.darkest}`};
`;

const THead = styled.thead`
  font-weight: 600;
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.lighter}`};
  border-left: none;
  border-right: none;
`;

const Th = styled.th`
  padding: 8px;
  text-align: left;
`;

const TBody = styled.tbody``;

const Tr = styled.tr`
  border: 1px solid ${({ theme }) => `${theme.palette.neutral.lighter}`};
  border-left: none;
  border-right: none;
  height: 40px;

  &:hover {
    background-color: #fafbfd;
    transition: 0.25s ease-out;
  }
`;

const Td = styled.td`
  padding: 10px 8px;
`;

interface TableProps<T extends object> {
  tableColumns: Column<T>[];
  tableData: Array<T>;
  rowType?: string;
  totalPageCount: number;
  totalRows: number;
  currentPageIndex: number;
  fetchData: (options: { pageIndex: number; pageSize: number }) => void;
  style?: CSSProperties;
}

export const Table = <T extends object>(props: TableProps<T>) => {
  const {
    tableColumns,
    tableData,
    rowType,
    totalRows,
    currentPageIndex: controlledPageIndex,
    totalPageCount: controlledPageCount,
    fetchData,
    style,
  } = props;

  const tableInstance = useTable<T>(
    {
      columns: tableColumns,
      data: tableData,
      initialState: { pageIndex: controlledPageIndex },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state,
    pageOptions,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  return (
    <TableContainer
      direction="column"
      justifyContent="space-between"
      id="table-container"
      style={style}
    >
      <FlexItem grow={2}>
        <div>
          {pageIndex * pageSize + 1}-
          {Math.min(pageIndex * pageSize + pageSize, totalRows)} of {totalRows}{' '}
          {rowType || 'rows'}
        </div>
        <StyledTable {...getTableProps()}>
          <THead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                  </Th>
                ))}
              </tr>
            ))}
          </THead>
          <TBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                    );
                  })}
                </Tr>
              );
            })}
          </TBody>
        </StyledTable>
      </FlexItem>
      <Pagination
        pageOptions={pageOptions}
        page={page}
        previousPage={previousPage}
        nextPage={nextPage}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        pageCount={pageCount}
        currentPage={pageIndex}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
      />
    </TableContainer>
  );
};
