import { Handle, HandleType, Position } from 'reactflow';

import styled from 'styled-components';

const StyledHandleDiv = styled(Handle)`
  background-color: ${({ theme }) => `${theme.palette.neutral.light}`};
  height: 20px;
  width: 20px;
  z-index: -1;

  &:hover {
    background-color: ${({ theme }) => `${theme.palette.primary}`};
  }
`;

export function StyledHandle({
  type,
  position,
}: {
  type: HandleType;
  position: Position;
}) {
  return <StyledHandleDiv type={type} position={position} />;
}
