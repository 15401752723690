import { Checkbox, Flex, Show, Text } from '@comet/blocks';
import { Container, Pill } from '../TemplateApis.styled';
import { getModelType } from '../TemplateApis.utils';

interface ReviewApisProps {
  models: ITemplateModel[];
  onChange: (item: string, type: 'flow' | 'model') => void;
  values: {
    modelItemTypes: string[];
    apiItemTypes: string[];
  };
}

export const ReviewModels = ({ models, values, onChange }: ReviewApisProps) => {
  return (
    <>
      <Show if={models.length > 0}>
        <Text style={{ marginTop: 20 }} appearance="heading.paragraph">
          Models to be created
        </Text>
        <Text style={{ marginTop: 10 }} appearance="body.regular">
          Initially, models will be created in ‘Draft’ state. You can edit them
          in Workflow builder to add more customizations.
        </Text>

        <Flex style={{ marginTop: 20 }}>
          <Container>
            {Object.values(models).map((model) => {
              const isSelected = values.modelItemTypes.includes(model.itemType);
              return (
                <Flex className="flowItem" alignItems="flex-start">
                  <Checkbox
                    checked={isSelected}
                    onChange={() => onChange(model.itemType, 'model')}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <Flex alignItems="center">
                      <Text appearance="label.short.regular">{model.name}</Text>
                      <Pill type={model.modelType} style={{ marginLeft: 5 }}>
                        <Text
                          appearance="caption.regular"
                          color="neutral.darker"
                        >
                          {getModelType(model.modelType)}
                        </Text>
                      </Pill>
                    </Flex>
                    <Text appearance="caption.regular">
                      {model.description}
                    </Text>
                  </div>
                </Flex>
              );
            })}
          </Container>
        </Flex>
      </Show>
      <Show if={models.length === 0}>
        <Text appearance="heading.paragraph">No Models to be created</Text>
      </Show>
    </>
  );
};
