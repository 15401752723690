import { RuleObject } from '@comet/blocks';

export const emailValidator = (_: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject('Please input your email!');
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return Promise.reject('Please input a valid email!');
  }

  return Promise.resolve();
};

export const usernameValidator = async (_: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject('Username is required!');
  }
  if (/\s/.test(value)) {
    return Promise.reject('Username should not contain spaces!');
  }
  return Promise.resolve();
};
