import { SUBSCRIPTION_STATUS } from '../types';

type AlertType = 'info' | 'warning' | 'error';

export const getAlertType = (
  status: SUBSCRIPTION_STATUS | undefined
): AlertType => {
  switch (status) {
    case SUBSCRIPTION_STATUS.NEW_PROJECT:
      return 'info';
    case SUBSCRIPTION_STATUS.NEW_DRAFTS:
      return 'warning';
    case SUBSCRIPTION_STATUS.UNPAID:
      return 'error';
    case SUBSCRIPTION_STATUS.UPDATING:
      return 'warning';
    default:
      return 'info';
  }
};
