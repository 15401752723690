import { useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  ButtonContainer,
  Center,
  Divider,
  Flex,
  FullCenter,
  Grid,
  Radio,
  Show,
  Text,
  Form,
  FormItem,
  Input,
  FormError,
  InputHelpText,
} from '@comet/blocks';
import {
  createProjectAPI,
  useGetCloudRegions,
  useProjectExistsQuery,
} from './ProjectForm.services';
import { CloudProviders } from './ProjectForm.constant';
import {
  CloudProviderRadioWrapper,
  RegionCode,
  RegionsGrid,
  RegionTabs,
} from './ProjectForm.utils';
import { Cloud, ContinentType, Project, RegionType } from '../../Project.types';
import { Result, Skeleton } from 'antd';
import { queryClient, useMutation } from '@comet/query';
import { getError } from '@comet/axios';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom';
import { useGetProjectsQuery } from '../../service';
import { useNavigate } from '@comet/router';

interface IProps {
  initialValue?: Partial<Project>;
  organisationId: string;
  onSuccess: (orgId: string) => void;
  secondaryButtonText?: string;
  primaryButtonText?: string;
  onCancel: () => void;
}

const DEFAULT_PROVIDER = 'AWS';
const DEFAULT_REGIONS: any = {
  AWS: 'ap-south-1',
  AZURE: 'centralindia',
};

export const ProjectForm = ({
  initialValue = {},
  onSuccess,
  onCancel,
  primaryButtonText = 'Create New Project',
  secondaryButtonText = 'Back',
  organisationId,
}: IProps) => {
  const [form] = Form.useForm();

  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: regionsInfo,
    isLoading: regionsLoading,
    isError: regionsError,
    refetch: regionsRefetch,
  } = useGetCloudRegions();

  useEffect(() => {
    regionsRefetch();
  }, [location.pathname, regionsRefetch]);

  const [projectName, setProjectName] = useState('');
  const [projectError, setProjectError] = useState('');
  const projectExistsQuery = useProjectExistsQuery(organisationId, projectName);

  const { data: freeProjectsData } = useGetProjectsQuery(organisationId, {
    offset: 0,
    limit: 1,
    type: 'FREE',
  });

  const isCreateProjectDisabled = freeProjectsData?.page.total === 1;
  useEffect(() => {
    if (isCreateProjectDisabled) {
      navigate('organisations.organisationId.projects');
    }
  }, [isCreateProjectDisabled, freeProjectsData]);

  useEffect(() => {
    if (projectName) {
      setProjectError(
        projectExistsQuery.data?.exists ? 'Project already exists' : ''
      );
    }
  }, [projectExistsQuery.data, projectName]);

  const debouncedProjectNameCheck = useRef(
    debounce(projectExistsQuery.refetch, 500)
  );

  const createProjectMutation = useMutation({
    mutationFn: (project: Project) => createProjectAPI(organisationId, project),
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries({
        queryKey: ['useGetProjectsQuery', organisationId],
      });
      onSuccess(id);
    },
  });

  const createProject = (formData: Project) => {
    setProjectError('');
    if (!projectName?.trim()) {
      setProjectError('Project Name is required');
      return;
    }

    createProjectMutation.mutate(formData);
  };

  const selectedCloudProvider = Form.useWatch('cloudProvider', form);
  const mutationError = getError(createProjectMutation.error);
  const cloudRegionsError = getError(regionsError);

  const selectedContinents = useMemo(() => {
    return regionsInfo?.find(
      ({ name }: Cloud) => name === selectedCloudProvider
    )?.continents;
  }, [regionsInfo, selectedCloudProvider]);

  useEffect(() => {
    form.setFieldValue('cloudRegion', DEFAULT_REGIONS[selectedCloudProvider]);
  }, [selectedCloudProvider]);

  return (
    <Flex direction="column" gap={24}>
      <Text appearance="heading.card">Create a new Project</Text>
      <Form
        autoComplete="off"
        onFinish={createProject}
        layout="vertical"
        form={form}
        initialValues={{
          name: initialValue.name || '',
          cloudProvider: initialValue.cloudProvider || DEFAULT_PROVIDER,
          cloudRegion:
            initialValue.cloudRegion || DEFAULT_REGIONS[DEFAULT_PROVIDER],
        }}
      >
        <FormItem
          label="Project name"
          name="name"
          required
          extra={
            <>
              <Show if={!!projectName}>
                <InputHelpText
                  error={projectError}
                  success={
                    projectExistsQuery.data?.exists === false &&
                    !projectExistsQuery.isFetching &&
                    'Project Name is available.'
                  }
                >
                  {projectExistsQuery.isFetching &&
                    'Checking if project exists...'}
                </InputHelpText>
              </Show>
              <Show if={!projectName}>
                <InputHelpText error={projectError}>
                  You can rename your project later
                </InputHelpText>
              </Show>
            </>
          }
        >
          <Input
            placeholder="General idea of what the project is about..."
            onChange={(event) => {
              setProjectName(event.target.value);
              if (event.target.value) {
                debouncedProjectNameCheck.current();
              }
            }}
          />
        </FormItem>

        <FormItem label="Cloud provider" required name="cloudProvider">
          <Radio.Group style={{ width: '100%' }}>
            <>
              <Show if={regionsLoading}>
                <Flex gap={8}>
                  <Skeleton.Input style={{ width: 160 }} active />
                  <Skeleton.Input style={{ width: 160 }} active />
                </Flex>
              </Show>
              <Show if={!regionsLoading}>
                <Flex alignItems="center" gap={30}>
                  {regionsInfo?.map((cloud: Cloud) => {
                    return (
                      // <CloudProviderRadioWrapper
                      //   selected={cloud.name == selectedCloudProvider}
                      //   key={cloud.name}
                      // >
                      // <>
                      // {/* {cloud.name} */}
                      <Radio key={cloud.name} value={cloud.name}>
                        {CloudProviders[cloud.name]}
                      </Radio>
                      // </>
                      /* </CloudProviderRadioWrapper> */
                    );
                  })}
                </Flex>
              </Show>
              <Show if={!!cloudRegionsError}>
                <FullCenter>
                  <Result
                    subTitle="Unable to fetch regions"
                    icon={null}
                    extra={
                      <Center>
                        <Button onClick={() => regionsRefetch()} type="primary">
                          Try Again
                        </Button>
                      </Center>
                    }
                  />
                </FullCenter>
              </Show>
            </>
          </Radio.Group>
        </FormItem>

        <FormItem label="Cloud region" required name="cloudRegion">
          <Radio.Group style={{ width: '100%' }}>
            <Show if={regionsLoading}>
              <Flex gap={8} direction="column">
                <Skeleton.Input style={{ width: 160 }} active />
                <Skeleton.Input style={{ width: 160 }} active />
              </Flex>
            </Show>
            <Show if={!cloudRegionsError && !regionsLoading}>
              <RegionTabs
                size="small"
                defaultActiveKey="1"
                items={(selectedContinents || []).map(
                  (continent: ContinentType, index: number) => {
                    return {
                      label: continent.name,
                      key: index.toString(),
                      children: (
                        <RegionsGrid>
                          {continent.regions.map((region: RegionType) => {
                            return (
                              <Flex
                                key={region.value}
                                style={{
                                  cursor: 'pointer',
                                  padding: '4px 0',
                                }}
                                alignItems="center"
                              >
                                <Radio value={region.value} />
                                <div>{region.name}</div>
                                <RegionCode>({region.value})</RegionCode>
                              </Flex>
                            );
                          })}
                        </RegionsGrid>
                      ),
                    };
                  }
                )}
              />
            </Show>
            <Show if={!!cloudRegionsError}>
              <FullCenter>
                <Result
                  subTitle="Unable to fetch regions"
                  icon={null}
                  extra={
                    <Center>
                      <Button onClick={() => regionsRefetch()} type="primary">
                        Try Again
                      </Button>
                    </Center>
                  }
                />
              </FullCenter>
              cloudRegionsError
            </Show>
          </Radio.Group>
        </FormItem>
        <FormError>{mutationError}</FormError>
        <Divider spread={24} />
        <ButtonContainer>
          <Button onClick={onCancel} appearance="transparent">
            {secondaryButtonText}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={regionsError}
            loading={createProjectMutation.isLoading}
          >
            {primaryButtonText}
          </Button>
        </ButtonContainer>
      </Form>
    </Flex>
  );
};
