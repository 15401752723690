import { getAxios } from '@comet/axios';
import { useQuery } from '@tanstack/react-query';
import { RawModel } from '../types/ModelDetails.types';
import { Model, ModelRequestData } from '../types';
import {
  GetModelsResponse,
  ModelsQueryPropsType,
} from '../types/ModelList.types';

export const createModel = (projectId: string, modelData: RawModel) => {
  const url = `/resources/${projectId}/models`;

  return getAxios()
    .post<{ id: string }>(url, modelData)
    .then(({ data }) => data);
};

export const useModelsQuery = (
  projectId: string,
  params: ModelsQueryPropsType
) => {
  return useQuery({
    queryKey: ['useModelsQuery', projectId, params],
    queryFn: async () => {
      return getModels(projectId, params);
    },
    cacheTime: 0, // disabled caching
  });
};

export const getModels = (projectId: string, params: ModelsQueryPropsType) => {
  const url = `/resources/${projectId}/models`;

  return getAxios()
    .get<GetModelsResponse>(url, {
      params,
    })
    .then(({ data }) => data);
};

interface ModelQueryPropsType {
  version?: string | null;
  draft?: boolean;
}
export const useModelQuery = (
  projectId: string,
  modelId: string,
  dirtyParams: ModelQueryPropsType = {},
  enabled?: boolean
) => {
  const params: ModelQueryPropsType = {};

  if (dirtyParams?.version === 'draft') {
    params.draft = true;
  } else {
    params.version = dirtyParams?.version;
  }

  return useQuery({
    queryKey: ['useModelQuery', projectId, modelId, params],
    queryFn: async () => {
      return getModel(projectId, modelId, params);
    },
    enabled: enabled,
  });
};

export const getModel = (
  projectId: string,
  modelId: string,
  params: ModelQueryPropsType
) => {
  const url = `/resources/${projectId}/models/${modelId}`;

  return getAxios()
    .get<Model>(url, { params })
    .then(({ data }) => data);
};

export const updateModel = async (
  projectId: string,
  modelId: string,
  modelData: ModelRequestData | null
) => {
  const url = `/resources/${projectId}/models/${modelId}`;

  const { data } = await getAxios().patch(url, modelData || {});

  return data;
};

export const deleteModel = (projectId: string, modelId: string) => {
  const url = `/resources/${projectId}/models/${modelId}`;

  return getAxios()
    .delete(url)
    .then(({ data }) => data);
};
