import { useParams } from '@comet/router';
import { queryClient, useQuery } from '@comet/query';
import { getAxios } from '@comet/axios';
import { AxiosError } from 'axios';

export const useGetPaymentStatus = (enabled: boolean) => {
  const { organisationId, projectId } = useParams();
  return useQuery<
    {
      paid: boolean;
    },
    AxiosError
  >({
    queryKey: [],
    queryFn: async () => {
      const url = `resources/${projectId}/billing/payment/status`;

      const { data } = await getAxios().get<{
        paid: boolean;
      }>(url);
      return data;
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['useGetProjectQuery', organisationId, projectId],
      });
    },
    refetchInterval: 1000,
    enabled: enabled,
  });
};
