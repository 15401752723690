import { Flex, Text } from '@comet/blocks';
import { RxCross2 } from 'react-icons/rx';

import { styled } from '@comet/styled';

export const MemberComponent = styled(Flex)`
  justify-content: space-between;
  padding: 12px 0;
  border-top: 1px solid #d9d9d9;
`;

export const StyledText = styled(Text)`
  color: #6a6a6a;
`;

export const MembersContainer = styled(Flex)`
  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 16px;
  }
`;

export const StyledCrossIcon = styled(RxCross2)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  cursor: pointer;
`;
