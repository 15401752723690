import {
  Button,
  Flex,
  Form,
  Show,
  Text,
  Select,
  Card,
  FormItem,
} from '@comet/blocks';
import { notification, Input, FormInstance } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { StyledFieldType } from '../SearchIndex.styled';
import { useDynamicFields } from '../DynamicFields/useDynamicFields';
import { SearchIndex } from '../types';
import { sendErrorNotification } from 'src/blocks/Notification';
import FormList from 'antd/es/form/FormList';

interface DynamicFieldsProps {
  searchIndexData?: SearchIndex;
  form: FormInstance;
  modelId: string;
}

export const DynamicFields = ({
  searchIndexData,
  form,
  modelId,
}: DynamicFieldsProps) => {
  const {
    analyzerOptions,
    myfields,
    fieldOptions,
    handleSelectField,
    handleSelectFieldType,
    handleSelectAnalyzer,
    addField,
    removeField,
    disabledFields,
    canCreateMoreFields,
    handleMultiFieldNameChange,
    handleMultiFieldAnalyzerChange,
    addMultiField,
    removeMultiField,
  } = useDynamicFields(searchIndexData, modelId || '', form);

  return (
    <Form.List name="fields" initialValue={searchIndexData?.mappings.fields}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => (
            <Card
              key={key}
              title={
                <Flex gap={8} alignItems="center" style={{ marginTop: 10 }}>
                  <FormItem
                    label="Field Name"
                    {...restField}
                    name={[name, 'name']}
                    rules={[
                      { required: true, message: 'Missing field name' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const myfields = getFieldValue('fields');
                          if (
                            !value ||
                            myfields.filter(
                              (f: { name: string }) => f?.name === value
                            ).length <= 1
                          ) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error('Duplicate fields not allowed!')
                          );
                        },
                        message: 'Duplicate fields not allowed!',
                      }),
                    ]}
                  >
                    <Select
                      options={fieldOptions}
                      placeholder="Select Field"
                      onChange={(value: string) =>
                        handleSelectField(name, value, index)
                      }
                      style={{ width: 250 }}
                    />
                  </FormItem>
                  <Flex>
                    <FormItem label="Type" {...restField} name={[name, 'type']}>
                      <Select
                        placeholder="Select field type"
                        options={[
                          { label: 'Array', value: 'array' },
                          { label: 'Autocomplete', value: 'autocomplete' },
                          { label: 'Boolean', value: 'boolean' },
                          { label: 'Date', value: 'dateFacet' },
                          {
                            label: 'Embeded Documents',
                            value: 'embeddedDocuments',
                          },
                          { label: 'KNN Vector', value: 'knnVector' },
                          { label: 'Number', value: 'number' },
                          { label: 'Number Facet', value: 'number Facet' },
                          { label: 'ObjectId', value: 'objectId' },
                          { label: 'String', value: 'string' },
                          { label: 'String Facet', value: 'stringFacet' },
                          { label: 'Token', value: 'token' },
                          { label: 'UUID', value: 'uuid' },
                        ]}
                        onChange={(value: string) =>
                          handleSelectFieldType(name, value, index)
                        }
                        style={{ width: 250 }}
                      />
                    </FormItem>
                  </Flex>
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                      removeField(index);
                    }}
                  />
                </Flex>
              }
            >
              <Flex
                key={key}
                gap={8}
                style={{ flexWrap: 'wrap' }}
                direction="column"
              >
                <Text appearance="label.short.regular">String Properties</Text>
                <Flex
                  gap={8}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormItem
                    label="Analyzer"
                    {...restField}
                    name={[name, 'analyzer']}
                    rules={[
                      {
                        required:
                          myfields[index]?.type === 'string' ||
                          myfields[index]?.type === 'autocomplete',
                        message: 'Missing Index Analyzer type',
                      },
                    ]}
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <Select
                      placeholder="Index Analyzer"
                      disabled={
                        disabledFields[index] ||
                        myfields[index]?.type !== 'string'
                      }
                      onChange={(value: string) =>
                        handleSelectAnalyzer(name, value, index, 'analyzer')
                      }
                      options={analyzerOptions}
                    />
                  </FormItem>
                  <FormItem
                    label="Search Analyzer"
                    {...restField}
                    name={[name, 'searchAnalyzer']}
                    rules={[
                      {
                        required: myfields[index]?.type === 'string',
                        message: 'Missing Search Analyzer type',
                      },
                    ]}
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <Select
                      placeholder="Search Analyzer"
                      disabled={
                        disabledFields[index] ||
                        myfields[index]?.type !== 'string'
                      }
                      onChange={(value: string) =>
                        handleSelectAnalyzer(
                          name,
                          value,
                          index,
                          'searchAnalyzer'
                        )
                      }
                      options={analyzerOptions}
                    />
                  </FormItem>
                </Flex>
                <Show
                  if={
                    !disabledFields[index] && myfields[index]?.type === 'string'
                  }
                >
                  <Flex
                    style={{
                      borderTop: '1px solid #f0f0f0',
                      marginTop: 16,
                      paddingTop: 12,
                    }}
                    direction="column"
                  >
                    <Text
                      style={{ marginTop: 12 }}
                      appearance="label.short.regular"
                    >
                      Multi Field
                    </Text>
                    <Form.List
                      name={[name, 'multi']}
                      initialValue={
                        searchIndexData?.mappings.fields[index]?.multi
                      }
                    >
                      {(
                        multiFields,
                        {
                          add: addMultiFieldInForm,
                          remove: removeMultiFieldFromForm,
                        }
                      ) => (
                        <>
                          {multiFields.map(
                            (
                              {
                                key: multiFieldKey,
                                name: multiFieldName,
                                ...restMultiFields
                              },
                              multiIndex
                            ) => (
                              <Flex
                                key={multiFieldKey}
                                direction="column"
                                gap={8}
                                style={{
                                  borderTop:
                                    multiIndex > 0
                                      ? '1px solid #f0f0f0'
                                      : undefined,
                                  marginTop: 16,
                                  paddingTop: 12,
                                }}
                              >
                                <Flex
                                  direction="row"
                                  gap={8}
                                  alignItems="flex-start"
                                >
                                  <FormItem
                                    {...restMultiFields}
                                    label="Multi Field Name"
                                    name={[multiFieldName, 'name']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing Multi Field Name',
                                      },
                                      ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          const myfields =
                                            getFieldValue('fields');
                                          if (
                                            !value ||
                                            myfields?.[index].multi.filter(
                                              (f: { name: string }) =>
                                                f?.name === value
                                            ).length <= 1
                                          ) {
                                            return Promise.resolve();
                                          }

                                          return Promise.reject(
                                            new Error(
                                              'Duplicate fields not allowed!'
                                            )
                                          );
                                        },
                                        message:
                                          'Duplicate fields not allowed!',
                                      }),
                                    ]}
                                    style={{ marginBottom: 8 }}
                                  >
                                    <Input
                                      placeholder="Multi Field Name"
                                      onChange={(e) => {
                                        handleMultiFieldNameChange(
                                          e.target.value,
                                          index,
                                          multiIndex
                                        );
                                      }}
                                      style={{
                                        width: 'min-content',
                                        minWidth: 250,
                                      }}
                                    />
                                  </FormItem>
                                  {multiIndex === multiFields.length - 1 && (
                                    <MinusCircleOutlined
                                      style={{ marginTop: 8 }}
                                      onClick={() => {
                                        removeMultiFieldFromForm(
                                          multiFieldName
                                        );
                                        removeMultiField(index, multiFieldName);
                                      }}
                                    />
                                  )}
                                </Flex>
                                <Flex
                                  gap={8}
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FormItem
                                    label="Analyzer"
                                    {...restMultiFields}
                                    name={[multiFieldName, 'analyzer']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing Analyzer type',
                                      },
                                    ]}
                                    style={{ flex: 1, marginBottom: 0 }}
                                  >
                                    <Select
                                      placeholder="Index Analyzer"
                                      disabled={
                                        disabledFields[index] ||
                                        myfields[index]?.type !== 'string'
                                      }
                                      onChange={(value: string) =>
                                        handleMultiFieldAnalyzerChange(
                                          value,
                                          index,
                                          multiIndex,
                                          'analyzer'
                                        )
                                      }
                                      options={analyzerOptions}
                                    />
                                  </FormItem>
                                  <FormItem
                                    hidden={myfields[index]?.type !== 'string'}
                                    label="Search Analyzer"
                                    {...restMultiFields}
                                    name={[multiFieldName, 'searchAnalyzer']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Missing Search Analyzer type',
                                      },
                                    ]}
                                    style={{ flex: 1, marginBottom: 0 }}
                                  >
                                    <Select
                                      placeholder="Search Analyzer"
                                      disabled={
                                        disabledFields[index] ||
                                        myfields[index]?.type !== 'string'
                                      }
                                      onChange={(value: string) =>
                                        handleMultiFieldAnalyzerChange(
                                          value,
                                          index,
                                          multiIndex,
                                          'searchAnalyzer'
                                        )
                                      }
                                      options={analyzerOptions}
                                    />
                                  </FormItem>
                                </Flex>
                              </Flex>
                            )
                          )}
                          <Form.Item>
                            <Button
                              style={{ width: 'min-content', marginTop: 24 }}
                              onClick={() => {
                                addMultiFieldInForm();
                                addMultiField(index);
                              }}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Multi Field
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Flex>
                </Show>
              </Flex>
            </Card>
          ))}
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              type="dashed"
              onClick={() => {
                if (!canCreateMoreFields) {
                  sendErrorNotification({
                    message: 'Error',
                    description:
                      'You have reached maximum number of fields for this vector search index.',
                  });
                  return;
                }
                add();
                addField();
              }}
              block
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
