import { useEffect, useState } from 'react';

const devices = {
  desktop: 1024,
  laptop: 992,
  tablet: 768,
  mobile: 480,
};

type DevicesInterface = keyof typeof devices;

export const useDevice = () => {
  const [deviceType, setDeviceType] = useState<DevicesInterface>('desktop');

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= devices.desktop) {
        setDeviceType('desktop');
      } else if (windowWidth >= devices.laptop) {
        setDeviceType('laptop');
      } else if (windowWidth >= devices.tablet) {
        setDeviceType('tablet');
      } else {
        setDeviceType('mobile');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { deviceType, deviceWidth: devices[deviceType] };
};
