import { useQuery } from '@comet/query';
import { Environment, Options } from '../types';
import { getAxios } from '@comet/axios';
import { useGetEnvironmentsQuery } from '../service';
import { useParams } from '@comet/router';
import { useEffect, useMemo, useState } from 'react';
import { CustomSelectOption } from '@comet/blocks';

interface GetEnvironmentParams {
  offset?: number;
}

// Todo: This should be moved to service
async function fetchEnvironments(
  params: GetEnvironmentParams,
  projectId: string | undefined
) {
  const data = await getAxios().get(`resources/${projectId}/environments`);
  return data;
}

export const useGetActiveEnvironments = (
  params: GetEnvironmentParams,
  projectId: string | undefined
) => {
  return useQuery({
    queryKey: ['useGetEnvironmentsQuery', params],
    queryFn: async () => {
      const data = await fetchEnvironments(params, projectId);
      return data;
    },
  });
};

export function formatEnvironmentsData(
  environmentsData: Environment[] | undefined
): Options {
  if (!environmentsData) return { data: [], developmentId: '' };

  let developmentId;
  const result = environmentsData.reduce(
    (result: CustomSelectOption[], environment: Environment) => {
      if (environment.type === 'DEVELOPMENT') {
        developmentId = environment.id;
      }

      return [
        ...result,
        {
          label: environment.name,
          value: environment.id,
        },
      ];
    },
    []
  );

  return {
    data: result,
    developmentId: developmentId,
  };
}

export function formatReleaseOptionData(
  environmentsData: Environment[] | undefined
): Options {
  if (!environmentsData) return { data: [], developmentId: '' };

  let developmentId;
  const result = environmentsData.reduce(
    (result: CustomSelectOption[], environment: Environment) => {
      if (environment.type === 'DEVELOPMENT') {
        developmentId = environment.id;
        return result;
      }

      return [
        ...result,
        {
          label: environment.name,
          value: environment.id,
        },
      ];
    },
    []
  );

  return {
    data: result,
    developmentId: developmentId,
  };
}

export const useGetEnvironmentsOptions = () => {
  const { projectId } = useParams();
  const { data, isLoading: isEnvironmentsLoading } =
    useGetEnvironmentsQuery(projectId);
  const result = useMemo(() => formatEnvironmentsData(data?.data), [data]);

  const [selectedEnvironment, setSelectedEnvironment] = useState(
    result.developmentId
  );

  useEffect(() => {
    setSelectedEnvironment(result.developmentId);
  }, [result.developmentId]);

  return {
    environmentsOptions: result.data,
    isEnvironmentsLoading,
    selectedEnvironment,
    setSelectedEnvironment,
  };
};
