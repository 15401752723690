import { memo } from 'react';
import { Position } from 'reactflow';
import { Tag } from '@comet/blocks';
import { Wrapper, StyledHandle } from '../../common/styled';
import { CometNodeBaseProps } from '../../Nodes';
import { styled } from '@comet/styled';

export const GroupNodeDimension = {
  height: 80,
  width: 220,
};

export const GroupNodePreview = () => {
  return (
    <Wrapper>
      <Tag.CheckableTag checked>Group</Tag.CheckableTag>
    </Wrapper>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const GroupNode = (props: CometNodeBaseProps) => {
  return (
    <>
      <Container>
        <StyledHandle type="target" id="target" position={Position.Top} />
        <StyledHandle type="source" id="0" position={Position.Bottom} />
      </Container>
    </>
  );
};

export default memo(GroupNode);
