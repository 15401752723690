import { getAxios } from '@comet/axios';
import { useQuery } from '@comet/query';
import { GetUserResponse, UpdateUserData, UserRawData } from './types';
const LOCAL_STORE_KEY = 'cosmo_last_visited_page';

export const saveLastVisitedPage = (location: string) => {
  localStorage.setItem(LOCAL_STORE_KEY, location);
};

export const getLastVisitedPage = () => {
  return localStorage.getItem(LOCAL_STORE_KEY);
};

export const useGetUserQuery = (
  userId: string | undefined,
  enabled: boolean
) => {
  const url = `/users/${userId}`;

  return useQuery({
    queryKey: ['useGetUserQuery', userId],
    queryFn: async () => {
      const user = await getAxios().get<GetUserResponse>(url);

      return user;
    },
    enabled: enabled,
  });
};

export const createUser = (userData: UserRawData) => {
  const url = '/users';

  return getAxios().post(url, userData);
};

export const updateUser = (userId: string, updatedUserData: UpdateUserData) => {
  const url = `/users/${userId}`;

  return getAxios().patch(url, updatedUserData);
};
