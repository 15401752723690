import {
  Button,
  Center,
  Flex,
  FullCenter,
  Paper,
  Spin,
  Text,
  Search,
} from '@comet/blocks';
import {
  UpsertUserProjectAccess,
  removeProjectAccess,
  useProjectAccessQuery,
  useProjectRolesQuery,
} from '../service';
import { useParams } from '@comet/router';
import { Result, notification } from 'antd';
import { ChangeEvent, useMemo, useState } from 'react';
import { MemberEmailInvite } from '@comet/components/Members';
import debounce from 'lodash.debounce';
import { MemberList } from '@comet/components/Members/MemberList';
import { useMutation } from '@tanstack/react-query';
import {
  RemoveUserAccessData,
  UpsertUserAccessData,
} from '@comet/components/Members/types';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { getDisplayName } from '../utils';
import { FreeTierSplash } from '@comet/components/FreeTierSplash';
import {
  useGetOrgSubscriptionQuery,
  useOrgQuery,
} from '@comet/pages/Organisation/service';
import { sendErrorNotification } from 'src/blocks/Notification';
import { queryClient } from '@comet/query';

export const ProjectUserManagement = () => {
  const { organisationId, projectId } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');

  const { hasManageUserPermissionsAccess } = useProjectPermissions();
  const { hasViewProjectAccess } = useOrgPermissions();

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }

    debouncedQueryUpdate(e.target.value);
  };

  const {
    data: projectMembers,
    isLoading: isProjectAccessLoading,
    error: projectAccessError,
    refetch: projectAccessRefetch,
    isError: isProjectAccessError,
  } = useProjectAccessQuery(organisationId, projectId, {
    user_name_query: query || null,
  });

  const {
    data,
    isLoading: isProjectRolesLoading,
    error: projectRolesError,
    refetch: projectRolesRefetch,
  } = useProjectRolesQuery();

  const debouncedProjectAccessRefetch = debounce(projectAccessRefetch, 200);
  const { data: subscriptionData } = useGetOrgSubscriptionQuery(organisationId);

  const userLimitReached =
    (projectMembers?.page.total || 0) >=
    (subscriptionData?.resourceLimits.maxUsers || 0);

  const updateProjectAccessMutation = useMutation({
    mutationFn: (orgAccessData: UpsertUserAccessData) =>
      UpsertUserProjectAccess(organisationId, projectId, orgAccessData),
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Project access updated successfully',
      });
      debouncedProjectAccessRefetch();
    },
    onError: (error: any) => {
      const errorMessage =
        error.response?.data?.message || 'Unable to update project access';
      sendErrorNotification({
        message: 'Error',
        description: errorMessage,
        reportBug: true,
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/user-management',
      });
    },
  });

  const removeProjectAccessMutation = useMutation({
    mutationFn: (orgAccessData: RemoveUserAccessData) =>
      removeProjectAccess(organisationId, projectId, orgAccessData),
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'User removed from the project successfully',
      });
      debouncedProjectAccessRefetch();
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to remove user from the project',
        reportBug: true,
      });
    },
  });

  const onUpdate = (data: UpsertUserAccessData) => {
    if (!hasManageUserPermissionsAccess) {
      sendErrorNotification({
        message: 'Error',
        description: "You don't have permission to manage user permissions!",
        redirectUrl:
          'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/user-management',
      });
      return;
    }

    updateProjectAccessMutation.mutate(data);
  };

  const onDelete = (data: RemoveUserAccessData) => {
    removeProjectAccessMutation.mutate(data);
  };

  const ProjectRolesOptions = useMemo(
    () =>
      (data?.roles || []).map((role) => ({
        label: getDisplayName(role.name),
        value: role.value,
      })),
    [data?.roles]
  );

  const isLoading = isProjectAccessLoading || isProjectRolesLoading;
  const isError = projectAccessError || projectRolesError || !projectMembers;
  const showEmptyScreen =
    !isLoading && !isError && projectMembers.data.length === 0;
  const showMemberList =
    !isLoading && !isError && projectMembers.data.length > 0;

  if (isLoading && !query) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!hasViewProjectAccess) {
    return <ErrorPage />;
  }

  if (isError && !query) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() =>
                  isProjectAccessError
                    ? projectAccessRefetch()
                    : projectRolesRefetch()
                }
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Paper size="medium">
      <Flex direction="column" gap={20}>
        <Text appearance="heading.card">Manage your team</Text>
        <Text appearance="heading.paragraph">
          Edit permissions for your existing members for this project
        </Text>
        <Flex width="100%">
          <Search
            placeholder="Search from project members..."
            value={searchValue}
            loading={false}
            suffix={null}
            onChange={onSearch}
          />
        </Flex>

        <MemberList
          type="project"
          showEmptyScreen={showEmptyScreen}
          showMemberList={showMemberList && !!projectMembers}
          isLoading={isLoading && !!query}
          roles={ProjectRolesOptions}
          members={projectMembers?.data || []}
          total={projectMembers?.page.total || 0}
          onUpdate={onUpdate}
          onDelete={onDelete}
          hasEditAccess={!!hasManageUserPermissionsAccess}
        />
        <MemberEmailInvite
          onInvite={onUpdate}
          description="Adding new members to the team will also add them to the organization."
          roles={ProjectRolesOptions}
          hasEditAccess={!!hasManageUserPermissionsAccess}
          userLimitReached={userLimitReached}
        />
      </Flex>
    </Paper>
  );
};
