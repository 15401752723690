export type TProperties = {
  [key: string]: TSchema;
};

export enum SchemaKeyBasicType {
  OBJECT_ID = 'OBJECT_ID',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'NUMBER',
  FLOAT = 'FLOAT',
}

export enum SchemaKeyType {
  OBJECT_ID = 'OBJECT_ID',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'NUMBER',
  FLOAT = 'FLOAT',
  DICT = 'DICT',
  NESTED = 'NESTED',
  ARRAY = 'LIST',
}

export enum SchemaKeySubType {
  OBJECT_ID = 'OBJECT_ID',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'NUMBER',
  FLOAT = 'FLOAT',
  DICT = 'DICT',
  NESTED = 'NESTED',
}

type TSubSchema =
  | {
      type:
        | SchemaKeyType.STRING
        | SchemaKeyType.BOOLEAN
        | SchemaKeyType.INTEGER
        | SchemaKeyType.FLOAT
        | SchemaKeyType.DICT;
    }
  | {
      type: SchemaKeyType.NESTED;
      properties: TProperties;
      order: string[];
    }
  | {
      type: SchemaKeyType.ARRAY;
      subType: SchemaKeySubType;
      properties?: TProperties;
      order?: string[];
    };

export type TSchema = { name: string; required?: boolean } & TSubSchema;
