import { Checkbox, Flex, Show, Text } from '@comet/blocks';
import { FlowTypeComponent, RequestMethodComponent } from '../../utils';
import { Container } from '../TemplateApis.styled';

interface ReviewApisProps {
  flows: ITemplateFlow[];
  onChange: (item: string, type: 'flow' | 'model') => void;
  values: {
    modelItemTypes: string[];
    apiItemTypes: string[];
  };
}

export const ReviewApis = ({ flows, values, onChange }: ReviewApisProps) => {
  return (
    <>
      <Show if={flows.length > 0}>
        <Text appearance="heading.paragraph">APIs to be created</Text>
        <Text style={{ marginTop: 10 }} appearance="body.regular">
          Initially, APIs will be created in ‘Draft’ state. You can edit them in
          Workflow builder to add more customizations.
        </Text>

        <Flex style={{ marginTop: 20 }}>
          <Container>
            {flows.map((flow) => {
              const isSelected = values.apiItemTypes.includes(flow.itemType);
              return (
                <Flex className="flowItem" alignItems="flex-start">
                  <Checkbox
                    checked={isSelected}
                    onChange={() => onChange(flow.itemType, 'flow')}
                  />
                  <div style={{ marginLeft: 10 }}>
                    <Flex alignItems="center">
                      <Text appearance="label.short.regular">
                        {flow.flowMetadata.endpoint || flow.name}
                      </Text>
                      <Show if={flow.flowType !== 'FUNCTION'}>
                        <RequestMethodComponent
                          style={{ marginLeft: 10 }}
                          requestMethod={flow.flowMetadata.requestMethod}
                        >
                          {flow.flowMetadata.requestMethod}
                        </RequestMethodComponent>
                      </Show>
                      <Show if={flow.flowType === 'FUNCTION'}>
                        <FlowTypeComponent type={flow.flowType}>
                          SUBFLOW
                        </FlowTypeComponent>
                      </Show>
                    </Flex>
                    <Text appearance="caption.regular">{flow.description}</Text>
                  </div>
                </Flex>
              );
            })}
          </Container>
        </Flex>
      </Show>
      <Show if={flows.length === 0}>
        <Text appearance="heading.paragraph">No APIs to be created</Text>
      </Show>
    </>
  );
};
