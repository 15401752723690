import { Dimensions, Edge, Node } from 'reactflow';
import { getHeight, getWidth, NodeTypeEnum } from '../../Nodes';
import {
  AdjacencyList,
  borderMap,
  IOptions,
  NodeMap,
  PositionMap,
  SubTreesNodes,
  TreeBorders,
} from './tree.types';

class TreeBase {
  edges: Edge[] = [];
  nodes: Node[] = [];
  nodeMap: NodeMap = {};
  rootId?: string = undefined;
  adjacencyList: AdjacencyList = {};
  xGap = 20;
  levelMap: PositionMap = {};
  levelHeight: number[] = [];
  borderMap: borderMap = {};
  xPosition: PositionMap = {};
  yPosition: PositionMap = {};
  options?: IOptions;
  treeBorders?: TreeBorders;
  dimensions?: Dimensions;
  // contains all the sub tree of a node
  // any node containing node with parent property will be found here.
  subTreesNodes: SubTreesNodes = {};

  constructor(nodes: Node[], edges: Edge[], options?: IOptions) {
    this.nodes = nodes;
    this.edges = edges;
    this.options = options;
    this.xGap = options?.xGap || 20;
    if (options?.subTreesNodes) {
      this.subTreesNodes = options.subTreesNodes;
    }
  }

  createAdjacencyList() {
    const targetMap: AdjacencyList = {};
    this.nodes.forEach((node) => {
      this.nodeMap[node.id] = node;
      targetMap[node.id] = [];
    });

    this.edges.forEach(({ source, target }) => {
      if (this.adjacencyList[source]) {
        this.adjacencyList[source].push(target);
      } else {
        this.adjacencyList[source] = [target];
      }

      // for target
      if (targetMap[target]) {
        targetMap[target].push(source);
      } else {
        targetMap[target] = [source];
      }
    });
    this.rootId = Object.keys(targetMap).filter(
      (x) => targetMap[x].length === 0
    )[0];
  }

  getTreeBorders = () => {
    this.getTreeDimension();
    // getTreeDimension sets the value for borders
    return this.treeBorders as TreeBorders;
  };

  getTreeDimension = () => {
    if (this.dimensions) {
      return this.dimensions as Dimensions;
    }

    let xMax = -Infinity,
      xMin = Infinity,
      yMax = -Infinity,
      yMin = Infinity;

    Object.keys(this.xPosition).forEach((id) => {
      const val = this.xPosition[id];
      const width = this.getWidth(id);
      xMax = Math.max(xMax, val + width);
      xMin = Math.min(xMin, val);
    });

    Object.keys(this.yPosition).forEach((id) => {
      const val = this.yPosition[id];
      const height = this.getHeight(id);
      yMax = Math.max(yMax, val + height);
      yMin = Math.min(yMin, val);
    });
    this.treeBorders = {
      xMax,
      xMin,
      yMax,
      yMin,
    };
    return { width: xMax - xMin, height: yMax - yMin };
  };

  getWidth = (id: string) => {
    if (this.options?.getWidth) {
      return this.options?.getWidth(id);
    }
    return getWidth(this.nodeMap[id].type as NodeTypeEnum);
  };

  getHeight = (id: string) => {
    if (this.options?.getHeight) {
      return this.options?.getHeight(id);
    }

    return getHeight(this.nodeMap[id].type as NodeTypeEnum);
  };

  getChildren = (id: string) => this.adjacencyList[id] || [];

  getBorder = (level: number) => this.borderMap[level] ?? -Infinity;
  setBorder = (level: number, border: number) =>
    (this.borderMap[level] = border);

  getTotalChildrenWidth = (nodeId: string) => {
    const children = this.getChildren(nodeId);
    let width = 0;
    children.forEach((childId) => {
      width += this.getWidth(childId);
    });

    return width;
  };
}

export default TreeBase;
