import styled from 'styled-components';

const SubscriptionAlert = ({
  title = 'Subscription is Inactive',
  content = 'Please make a payment to activate your services.',
}: {
  title?: string;
  content?: string;
}) => {
  return (
    <Wrapper>
      <Container>
        <AlertContent>
          <AlertIcon />
          <AlertTextContainer>
            <AlertTitle>{title}</AlertTitle>
            <AlertMessage>{content}</AlertMessage>
          </AlertTextContainer>
        </AlertContent>
      </Container>
    </Wrapper>
  );
};

export default SubscriptionAlert;

function TriangleAlertIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
      <path d="M12 9v4" />
      <path d="M12 17h.01" />
    </svg>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.neutral.white};
  padding: 1rem 0;
  border-radius: 10px;
  border: 1px solid #e5e7eb;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  @media (min-width: 768px) {
    padding: 0 1.5rem;
  }
`;

const AlertContent = styled.div`
  display: flex;
  align-items: start;
  gap: 1rem;
`;

const AlertIcon = styled(TriangleAlertIcon)`
  height: 3rem;
  width: 3rem;
  color: ${({ theme }) => theme.palette.accent.red.default};
`;

const AlertTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const AlertTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.accent.red.default};
`;

const AlertMessage = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.neutral.dark};
`;
