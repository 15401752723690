import { styled } from '@comet/styled';

import { Divider as AntDivider, DividerProps } from 'antd';

export const Divider = styled(AntDivider)<{ spread?: number }>`
  ${({ spread }) =>
    spread &&
    `
    margin:16px -${spread}px;
    width: calc(100% + ${spread * 2}px);
  `}

  background: ${({ theme }) => theme.palette.neutral.light};
`;

export type { DividerProps };
