import { Flex, Grid, Modal, Show, StyledSteps as Steps } from '@comet/blocks';
import { useState } from 'react';
import { ReleaseSelection } from './Release.Selection';
import { ReleaseDescription } from './Release.Description';
import { queryClient, useMutation } from '@comet/query';
import { DeploymentsDataRaw } from '../../types/Projects.types';
import { createDeployment } from '../Deployments.service';
import { useParams } from '@comet/router';
import { notification } from 'antd';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ActionComponent } from '@comet/components/Action';
import { sendErrorNotification } from 'src/blocks/Notification';
import { useGetProjectQuery } from '../../service';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

enum ReleaseSteps {
  SELECTION,
  DESCRIPTION,
}
type ReleaseData = Pick<DeploymentsDataRaw, 'apiIds' | 'modelIds'>;

export const Release = () => {
  const [step, setStep] = useState<ReleaseSteps>(ReleaseSteps.SELECTION);
  const [modalOpen, setModalOpen] = useState(false);
  const [releaseData, setReleaseData] = useState<ReleaseData>({
    apiIds: [],
    modelIds: [],
  });
  const { projectId, organisationId } = useParams();
  const { hasManageReleasesAccess } = useProjectPermissions();

  const { data: orgPlanData } = useGetOrgSubscriptionQuery(organisationId);
  const isOnFreeTier = orgPlanData?.planCode === 'FREE';

  const toggleModal = () => {
    if (!hasManageReleasesAccess) return;

    setModalOpen((isOpen) => !isOpen);
  };

  const createDeploymentMutation = useMutation({
    mutationFn: (DeploymentData: DeploymentsDataRaw) =>
      createDeployment(projectId, DeploymentData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useModelsQuery', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useApisQuery', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetProjectQuery', organisationId, projectId],
      });
      notification.success({
        message: 'Success',
        description: 'Deployed successfully',
      });
      toggleModal();
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to deploy changes',
        reportBug: true,
      });
    },
  });

  const toggleStep = () => {
    setStep(
      step === ReleaseSteps.SELECTION
        ? ReleaseSteps.DESCRIPTION
        : ReleaseSteps.SELECTION
    );
  };

  const onNext = (
    apiIds: string[],
    modelIds: string[],
    subflowIds: string[]
  ) => {
    setReleaseData({
      ...releaseData,
      apiIds: [...apiIds, ...subflowIds],
      modelIds,
    });
    toggleStep();
  };

  const deployChanges = (deploymentData: {
    version: string;
    description: string;
    // environmentIds: string[];
  }) => {
    if (releaseData.apiIds.length === 0 && releaseData.modelIds.length === 0) {
      sendErrorNotification({
        message: 'Error',
        description: 'Empty deployment is not allowed',
      });
    }

    const deploymentRawData = {
      ...deploymentData,
      ...releaseData,
    };

    createDeploymentMutation.mutate(deploymentRawData);
  };

  return (
    <>
      <Modal
        width={560}
        onCancel={toggleModal}
        title="Deploy"
        open={modalOpen}
        destroyOnClose={true}
        footer={null}
      >
        <Flex direction="column" gap={10}>
          <Grid style={{ width: 500 }}>
            <Steps
              prefixCls="step"
              size="small"
              current={step}
              items={[
                {
                  title: 'Select entities to deploy',
                },
                {
                  title: 'Add version and description',
                },
              ]}
            />
          </Grid>
          <Show if={step === ReleaseSteps.SELECTION}>
            <ReleaseSelection onCancel={toggleModal} onNext={onNext} />
          </Show>
          <Show if={step === ReleaseSteps.DESCRIPTION}>
            <ReleaseDescription
              onBack={toggleStep}
              onDeploy={deployChanges}
              isCreatingDeployment={createDeploymentMutation.isLoading}
            />
          </Show>
        </Flex>
      </Modal>
      <ActionComponent
        onClick={toggleModal}
        actionTitle="Review & Deploy"
        disabled={!hasManageReleasesAccess || isOnFreeTier}
        tooltip="Upgrade your project to create a release."
      />
    </>
  );
};
