import styled from 'styled-components';
import { useMemo } from 'react';
import { VerticalSteps as Steps, Step } from '@comet/blocks';

export const Container = styled.div`
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  display: grid;
  margin: auto;
  margin-top: 20px;
  gap: 5px;
  width: 100%;
  border-radius: 6px;
  padding: 15px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /*chrome*/
  background-color: #fff;
`;

const Heading = styled.h1`
  font-size: 18px;
  color: ${({ theme }) => `${theme.palette.neutral.darkest}`};
`;

interface QuickStartGuideProps {
  showDescription: boolean;
  title: string;
  stepsStatus: boolean[];
}

export const QuickStartGuide = ({
  showDescription,
  stepsStatus,
  title,
}: QuickStartGuideProps) => {
  const steps: Step[] = useMemo(() => {
    const StepsData: Step[] = [
      {
        title: 'Connect your database',
        description: 'Start by connecting your MongoDB Atlas cluster.',
        redirectUrl:
          'https://docs.cosmocloud.io/getting-started/3.-connect-your-database',
      },
      {
        title: 'Create your first Model',
        description:
          'Create a list of API methods from the steps, grouped into common resource groups.',
        route: 'organisations.organisationId.projects.projectId.models.new',
      },
      {
        title: 'Create your first API',
        description:
          'Start with your goals and intended users. Design the API architecture. Develop your API.',
        route: 'organisations.organisationId.projects.projectId.api',
      },

      {
        title: 'Deploy to production',
        route: 'organisations.organisationId.projects.projectId.releases',
      },
    ];

    return StepsData.map((step, index) => ({
      ...step,
      finish: stepsStatus[index],
    }));
  }, [stepsStatus]);

  const activeStep = stepsStatus.findIndex((element) => !element);

  return (
    <Container>
      <Heading>{title}</Heading>
      <Steps
        steps={steps}
        showDescription={showDescription}
        activeIndex={activeStep}
      />
    </Container>
  );
};
