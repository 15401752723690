import { styled } from '@comet/styled';

const StatusContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export function StatusComponent({
  color,
  children,
}: {
  color: string;
  children: React.ReactNode;
}) {
  const Circle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${color};
  `;

  return (
    <StatusContainer>
      <Circle />
      {children}
    </StatusContainer>
  );
}
