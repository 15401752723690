import { RuleRender } from 'antd/es/form';
import { useCallback } from 'react';
import { ENVIRONMENT_NAME_REGEX } from '../constants';

export const useEnvironmentNameValidator = () => {
  const validatorRule: RuleRender = useCallback(
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || ENVIRONMENT_NAME_REGEX.test(value)) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error(
            "Environment should't contain special characters or whitespaces!"
          )
        );
      },
    }),
    []
  );

  return validatorRule;
};
