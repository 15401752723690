import { getAxios } from '@comet/axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  GetOrgAccessResponse,
  GetOrgRolesResponse,
  GetOrgsResponse,
  Organisation,
  RedeemedCoupon,
  RedeemedCoupons,
  VerifyOTPResponse,
} from '../Organisation.types';
import {
  RemoveUserAccessData,
  UpsertUserAccessData,
} from '@comet/components/Members/types';
import { useParams } from '@comet/router';
import { queryClient } from '@comet/query';
import { notification } from 'antd';

interface GetOrgsParams {
  query?: string;
  offset?: number;
  limit?: number;
  sort_by?: string;
  sort_order?: string;
  plan_type?: string | null;
  created_by?: string;
}

export const useOrgsQuery = (params: GetOrgsParams) => {
  return useQuery({
    queryKey: ['useOrgsQuery', params],
    queryFn: async () => {
      const data = await getOrgs(params);

      return data;
    },
  });
};

export const getOrgs = async (params: GetOrgsParams) => {
  const url = '/organisations';
  const { data } = await getAxios().get<GetOrgsResponse>(url, {
    params,
  });
  return data;
};

export const useOrgQuery = (id: string) => {
  return useQuery({
    queryKey: ['useOrgQuery', id],
    queryFn: async () => await getOrg(id),
    enabled: !!id,
  });
};

export const useGetOrgSubscriptionQuery = (id: string) => {
  return useQuery({
    queryKey: ['useGetOrgSubscription', id],
    queryFn: async () => {
      const data = await getOrgSubscription(id);
      return data;
    },
  });
};

export const useGetOrgQuoteQuery = (id: string) => {
  return useQuery({
    queryKey: ['useGetOrgQuote', id],
    queryFn: async () => {
      const data = await getOrgQuote(id);
      return data;
    },
  });
};

export const getOrg = async (id: string) => {
  const url = `/organisations/${id}`;
  const { data } = await getAxios().get<Organisation>(url);
  return data;
};

export const getOrgSubscription = async (organisationId: string) => {
  const url = `/organisations/${organisationId}/plan`;
  const { data } = await getAxios().get<any>(url);
  return data;
};

export const getOrgQuote = async (organisationId: string) => {
  const url = `/organisations/${organisationId}/billing-quote`;
  const { data } = await getAxios().get<any>(url);
  return data;
};

export const useGetUsedCouponsQuery = (organisationId: string) => {
  return useQuery({
    queryKey: ['useGetUsedCoupons', organisationId],
    queryFn: async () => {
      const data = await getUsedCoupons(organisationId);
      return data;
    },
  });
};

export const getUsedCoupons = async (organisationId: string) => {
  const url = `/coupons/redeemed`;
  const params = { organisationId: organisationId };
  const { data } = await getAxios().get<RedeemedCoupons>(url, { params });
  return data;
};

export interface UserAccessQueryParams {
  user_name_query?: string | null;
}

export const useOrgAccessQuery = (
  organisationId: string,
  params: UserAccessQueryParams = {}
) => {
  const url = `/organisations/${organisationId}/access`;

  return useQuery({
    queryKey: ['useOrgAccessQuery', organisationId, params],
    queryFn: async () => {
      const { data } = await getAxios().get<GetOrgAccessResponse>(url, {
        params,
      });

      return data;
    },
  });
};

export const useOrgRolesQuery = () => {
  const url = `/metadata/roles`;

  return useQuery({
    queryKey: ['useOrgRolesQuery'],
    queryFn: async () => {
      const { data } = await getAxios().get<GetOrgRolesResponse>(url, {
        params: {
          role_type: 'ORGANISATION',
        },
      });

      return data;
    },
  });
};

export const UpsertUserOrgAccess = async (
  organisationId: string,
  OrgAccessData: UpsertUserAccessData
) => {
  const url = `/organisations/${organisationId}/access`;

  return getAxios()
    .patch(url, OrgAccessData)
    .then((data) => data);
};

export const deleteOrg = async (organisationId: string) => {
  const url = `/organisations/${organisationId}`;

  return getAxios().delete(url);
};

export const removeOrgAccess = async (
  organisationId: string,
  removeOrgAccessData: RemoveUserAccessData
) => {
  const url = `/organisations/${organisationId}/access`;

  return getAxios()
    .delete(url, { data: removeOrgAccessData })
    .then((data) => data);
};

export const checkDeletePossible = async (organisationId: string) => {
  const url = `/organisations/${organisationId}/delete-check`;
  const { data } = await getAxios().get<any>(url);
  return data.canBeDeleted;
};

export const sendOTPToDeleteOrg = async (organisationId: string) => {
  const url = `/organisations/${organisationId}/delete/send_otp`;

  return getAxios().get(url);
};

export const resendOTPToDeleteOrg = async (organisationId: string) => {
  const url = `/organisations/${organisationId}/delete/resend_otp`;

  return getAxios().get(url);
};

export const verifyOTPToDeleteOrg = async (
  organisationId: string,
  otp: string
) => {
  const url = `/organisations/${organisationId}/delete/verify_otp?otp=${otp}`;

  return getAxios().get<VerifyOTPResponse>(url);
};

export const useHandleCouponMutation = () => {
  const { organisationId } = useParams();
  return useMutation({
    mutationFn: (code: string) => handleCouponMutation(code, organisationId),
    onSuccess: () => {
      queryClient.refetchQueries(['useGetUsedCoupons']);
      queryClient.refetchQueries(['useGetOrgSubscription']);
      notification.success({
        message: 'Success',
        description: 'Coupon redeemed successfully',
      });
    },
    onError: () => {
      notification.error({
        message: 'Error',
        description: 'Coupon redemption failed',
      });
    },
  });
};

export const handleCouponMutation = (code: string, orgId: string) => {
  const url = `coupons/${code}/redeem`;
  const data = { organisationId: orgId };
  return getAxios().put(url, data);
};
