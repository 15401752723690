import { Text } from '@comet/blocks';
import { SidebarMenuItem } from './types';
import { useMemo } from 'react';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';

export const useOrgMenuItems = () => {
  const {
    hasManageOrgSettingsAccess,
    hasViewBillingsAccess,
    hasViewProjectAccess,
    hasManageOrgUserPermissionsAccess,
  } = useOrgPermissions();

  return useMemo(() => {
    const OrgMenuItems: SidebarMenuItem[] = [
      {
        label: (
          <Text appearance="label.long.semiBold" color="neutral.darkest">
            Projects
          </Text>
        ),
        key: '0',
        route: 'organisations.organisationId.projects',
        hidden: !hasViewProjectAccess,
      },
      {
        label: (
          <Text appearance="label.long.semiBold" color="neutral.darkest">
            User Management
          </Text>
        ),
        key: '1',
        route: 'organisations.organisationId.users',
        hidden: !hasManageOrgUserPermissionsAccess,
      },
      {
        label: (
          <Text appearance="label.long.semiBold" color="neutral.darkest">
            Payments
          </Text>
        ),
        key: '2',
        route: 'organisations.organisationId.payments',
        hidden: !hasManageOrgSettingsAccess,
      },
      {
        label: (
          <Text appearance="label.long.semiBold" color="neutral.darkest">
            Settings
          </Text>
        ),
        key: '3',
        route: 'organisations.organisationId.settings',
        hidden: !hasManageOrgSettingsAccess,
      },
    ];

    return OrgMenuItems;
  }, [
    hasManageOrgSettingsAccess,
    hasManageOrgUserPermissionsAccess,
    hasViewBillingsAccess,
    hasViewProjectAccess,
  ]);
};
