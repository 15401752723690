import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

import { NodeRaw } from 'src/FlowEngine2/types';
import { addNodeFromAddNode } from './addNodeFromNode';
import { addNodeFromEdge } from './addNodeFromEdge';

export function addIfElseV2Node(
  type: 'ADD_NODE' | 'EDGE', // A Node can be added either from add_node or edge
  id: string | null, // id can be of node or edge
  nodeToAdd: NodeRaw | null
) {
  const { createNode, addEdge } = useFlowEngineStore.getState();

  if (!id || !nodeToAdd) return;

  switch (type) {
    case 'ADD_NODE': {
      // IF_NODE_V2_TREE
      const executorNode1 = getNodeById(createNode('EXECUTOR_NODE'));
      const executorNode2 = getNodeById(createNode('EXECUTOR_NODE'));
      if (!executorNode1 || !executorNode2)
        return new Error(`Error in creating ifNodeV2tree`);
      const ifNodeV2 = getNodeById(
        createNode(nodeToAdd, {
          true: executorNode1.id,
          false: executorNode2.id,
        })
      );

      if (!ifNodeV2) return new Error(`Error in creating ifNodeV2tree`);

      addEdge(ifNodeV2.id, executorNode1.id); // Edge3
      addEdge(ifNodeV2.id, executorNode2.id); // Edge4

      addNodeFromAddNode(id, ifNodeV2, [executorNode1, executorNode2]);

      break;
    }

    case 'EDGE': {
      /* before -> node1--edge1--node2 
        after ->

        node1 --edge2-- ifTree -- edgeIf -- 
      */

      if (!id) return;

      const { createNode, addEdge } = useFlowEngineStore.getState();

      const executorNode1 = getNodeById(createNode('EXECUTOR_NODE'));
      const executorNode2 = getNodeById(createNode('EXECUTOR_NODE'));
      if (!executorNode1 || !executorNode2)
        return new Error(`Error in creating ifV2 node`);

      const ifNodeV2 = getNodeById(
        createNode(nodeToAdd, {
          true: executorNode1.id,
          false: executorNode2.id,
        })
      );
      if (!ifNodeV2) return new Error(`Error in creating ifV2 node`);
      addEdge(ifNodeV2.id, executorNode1.id);
      addEdge(ifNodeV2.id, executorNode2.id);

      addNodeFromEdge(id, ifNodeV2, [executorNode1, executorNode2]);
      break;
    }
  }
}
