import './style.css';

import { Position, useNodeId, useReactFlow } from 'reactflow';
import {
  getNodeById,
  useFlowEngineStore as useFlowEngineStoreV2,
} from '../store';

import { BusinessNodeEnum } from '../types';
import { LogicNode } from './LogicNode';
import { StyledHandle } from './StyledHandle';
import { shallow } from 'zustand/shallow';

export function ExecutorNode({
  data: { displayName, nodeName, businessNodeName },
}: {
  data: {
    displayName: string;
    nodeName: string;
    businessNodeName: BusinessNodeEnum;
  };
}) {
  const { deleteNode } = useFlowEngineStoreV2(
    (s) => ({ deleteNode: s.onDeleteNode }),
    shallow
  );

  const nodeId = useNodeId();
  const node = getNodeById(nodeId);
  const { fitView } = useReactFlow();

  if (!nodeId || !node) return null;

  const logicNodeProps = {
    node: node,
    nodeSkeletonProp: {
      displayName,
      nodeName,
      businessNodeName,
      canDelete: false,
      isEmbeddedFlow: true,
      handleDelete: () => {
        deleteNode(nodeId);
      },
      handleEmbeddedFlow: handleEmbeddedLayout,
    },
  };

  function handleEmbeddedLayout(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    const { addToStackTop } = useFlowEngineStoreV2.getState();
    addToStackTop(nodeId);
    fitView();
  }

  return (
    <>
      <StyledHandle type="target" position={Position.Top} />
      <LogicNode {...logicNodeProps} />
      <StyledHandle type="source" position={Position.Bottom} />
    </>
  );
}
