import { useGetUserProjectPermissionsQuery } from '@comet/pages/Project/service';
import { ProjectPermissions } from '@comet/pages/Project/types/UserPermission.types';
import { useMemo } from 'react';
import { useOrgPermissions } from './useOrgPermission';

export const useProjectPermissions = () => {
  const { data: userProjectPermissions } = useGetUserProjectPermissionsQuery();
  const { hasEditProjectAccess, hasViewProjectAccess } = useOrgPermissions();

  const hasViewApiAccess =
    !!userProjectPermissions &&
    hasViewProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.VIEW_APIS) >= 1;
  const hasEditApiAccess =
    !!userProjectPermissions &&
    hasEditProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.EDIT_APIS) >= 1;
  const hasViewModelAccess =
    !!userProjectPermissions &&
    hasViewProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.VIEW_MODELS) >= 1;
  const hasEditModelAccess =
    !!userProjectPermissions &&
    hasEditProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.EDIT_MODELS) >= 1;
  const hasViewReleasesAccess =
    !!userProjectPermissions &&
    hasViewProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.VIEW_RELEASES) >= 1;
  const hasManageReleasesAccess =
    !!userProjectPermissions &&
    hasEditProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.MANAGE_RELEASES) >=
      1;
  const hasEditConfigsAccess =
    !!userProjectPermissions &&
    hasEditProjectAccess &&
    (userProjectPermissions.role.value & ProjectPermissions.EDIT_CONFIGS) >= 1;
  const hasManageUserPermissionsAccess =
    !!userProjectPermissions &&
    hasEditProjectAccess &&
    (userProjectPermissions.role.value &
      ProjectPermissions.MANAGE_USER_PERMISSIONS) >=
      1;
  const hasManageProjectSettingsAccess =
    !!userProjectPermissions &&
    hasEditProjectAccess &&
    (userProjectPermissions.role.value &
      ProjectPermissions.MANAGE_PROJECT_SETTINGS) >=
      1;

  return useMemo(() => {
    return {
      hasViewApiAccess,
      hasEditApiAccess,
      hasViewModelAccess,
      hasEditModelAccess,
      hasViewReleasesAccess,
      hasManageReleasesAccess,
      hasEditConfigsAccess,
      hasManageUserPermissionsAccess,
      hasManageProjectSettingsAccess,
    };
  }, [
    hasEditApiAccess,
    hasEditConfigsAccess,
    hasEditModelAccess,
    hasManageProjectSettingsAccess,
    hasManageReleasesAccess,
    hasManageUserPermissionsAccess,
    hasViewApiAccess,
    hasViewModelAccess,
    hasViewReleasesAccess,
  ]);
};
