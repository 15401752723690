import {
  useGetOrgSubscriptionQuery,
  useGetUsedCouponsQuery,
} from '@comet/pages/Organisation/service';
import { useParams } from '@comet/router';

import { format } from 'date-fns';

export const useGetSubscriptionFeatures = () => {
  const { organisationId } = useParams();
  const { data } = useGetOrgSubscriptionQuery(organisationId);

  const featureColumns = [
    {
      title: 'Benefit',
      dataIndex: 'benefit',
      key: 'benefit',
    },
    {
      title: 'Access Level',
      dataIndex: 'accessLevel',
      key: 'accessLevel',
    },
  ];
  const featureData = [
    {
      benefit: 'Plan',
      accessLevel: data?.planCode,
    },
    {
      benefit: 'Total Models',
      accessLevel: data?.resourceLimits.maxNumModels,
    },
    {
      benefit: 'Total APIs',
      accessLevel: data?.resourceLimits.maxAPIs,
    },
    {
      benefit: 'Total Subflows',
      accessLevel: data?.resourceLimits.maxSubFlows,
    },
    {
      benefit: 'Total Users',
      accessLevel: data?.resourceLimits.maxUsers,
    },
    {
      benefit: 'Total Projects',
      accessLevel: data?.resourceLimits.maxProjects,
    },
  ];
  return { featureColumns, featureData };
};

export const useGetUsedCouponsTable = () => {
  const { organisationId } = useParams();
  const { data } = useGetUsedCouponsQuery(organisationId);

  const formatTime = (timestamp: number) => {
    return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
  };

  const couponsColumns = [
    {
      title: 'Code',
      dataIndex: 'couponCode',
      key: 'couponCode',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Applied On',
      dataIndex: 'appliedOn',
      key: 'appliedOn',
    },
  ];

  const couponsData = data?.data.map((coupon: any) => ({
    key: coupon.code,
    couponCode: coupon.code,
    description: coupon.description,
    appliedOn: formatTime(coupon.redeemedAt),
  }));
  return { couponsColumns, couponsData };
};

export const useGetEnvData = () => {
  const { organisationId } = useParams();
  const { data } = useGetOrgSubscriptionQuery(organisationId);

  const envFeatures = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
    },
    // {
    //   title: 'Total Data Bandwidth',
    //   dataIndex: 'totalDataBandwidth',
    //   key: 'totalDataBandwidth',
    // },
    {
      title: 'Max Search Indexes',
      dataIndex: 'maxSearchIndexes',
      key: 'maxSearchIndexes',
    },
    {
      title: 'Max API Calls',
      dataIndex: 'maxAPICalls',
      key: 'maxAPICalls',
    },
    {
      title: 'Max Object Storage',
      dataIndex: 'maxObjectStorage',
      key: 'maxObjectStorage',
    },
    // {
    //   title: 'Max Queues',
    //   dataIndex: 'maxQueues',
    //   key: 'maxQueues',
    // },
    {
      title: 'Log Retention',
      dataIndex: 'logRetention',
      key: 'logRetention',
    },
  ];
  const envData = data?.resourceLimits.environments.map((env: any) => ({
    key: env.type,
    type: env.type,
    units: `${env.units} units`,
    totalDataBandwidth: env.totalDataBandwidth,
    maxSearchIndexes: `${env.maxSearchIndexes} [vector search included]`,
    maxAPICalls: `${env.maxAPICalls} per day`,
    maxObjectStorage: `${env.maxObjectStorage} GBs`,
    maxQueues: env.maxQueues,
    logRetention: `${env.logRetention} Day`,
  }));

  return { envFeatures, envData };
};
