import { Button, Center, Flex, Text } from '@comet/blocks';
import Failed from '@comet/assets/png/payment_failed.png';
import styled from 'styled-components';
import { useNavigate, useParams } from '@comet/router';

const Container = styled.div`
  background-color: white;
  width: 60%;
  margin: auto;
  margin-top: 40px;
  border-radius: 8px;
  padding: 24px;
`;

export const PaymentFailed = () => {
  const navigate = useNavigate();
  const params = useParams();
  const onClick = () => {
    navigate(
      'organisations.organisationId.projects.projectId.billing.status',
      params,
      {
        state: {
          showDraftsEnvironments: true,
        },
      }
    );
  };

  return (
    <Container>
      <Flex direction="column">
        <Text appearance="heading.card">Payment Failed</Text>
        <Text appearance="body.regular">Something went wrong. Try again.</Text>
        <Center>
          <Flex direction="column" alignItems="center">
            <img src={Failed} alt="Payment Failed" />
            <Button
              onClick={onClick}
              style={{ width: '50%' }}
              appearance="primary"
            >
              Try Again
            </Button>
          </Flex>
        </Center>
      </Flex>
    </Container>
  );
};
