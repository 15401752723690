import {
  Alert,
  Button,
  Center,
  Flex,
  FullCenter,
  Search,
  Show,
  Spin,
  Table,
  Text,
  usePagination,
} from '@comet/blocks';
import { ChangeEvent, useState } from 'react';
import { Empty, Result, Tooltip } from 'antd';
import { useNavigate, useParams } from '@comet/router';

import { ActionComponent } from '@comet/components/Action';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { ExternalLink } from '@comet/components/ExternalLink';
import { GetStorageAccountParams } from '../types';
import { PAGE_SIZE } from './../constants';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import { queryClient } from '@comet/query';
import { useGetStorageAccountsQuery } from '../service';
import {
  useGetOrgSubscriptionQuery,
  useOrgQuery,
} from '@comet/pages/Organisation/service';
import { useStorageAccountsListingData } from './StorageAccountList.util';
import { useSubscriptionStatusPolling } from '../../Environments/utils/useSubscriptionStatusPolling';
import { useGetProjectQuery } from '../../service';

export const StorageAccounts = () => {
  const { pageOffset, currentPageIndex, fetchData } = usePagination();

  const initialValues = {
    query: '',
    offset: 0,
  };

  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState(initialValues.query);

  const currentValues = {
    query,
    offset: pageOffset,
  };

  const params: GetStorageAccountParams = {
    limit: PAGE_SIZE,
    offset: pageOffset,
  };

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const { organisationId, projectId } = useParams();

  const navigate = useNavigate();

  const {
    data: storageAccountsData,
    isLoading,
    error,
    refetch: refetchStorageAccount,
  } = useGetStorageAccountsQuery(projectId, params);

  const { tableColumns, tableData } =
    useStorageAccountsListingData(storageAccountsData);

  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !storageAccountsData;
  const showList =
    !isLoading && !isError && storageAccountsData.data.length > 0;

  const { data: subscriptionData } = useGetOrgSubscriptionQuery(organisationId);
  const isInactive = !subscriptionData?.active;

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() =>
                  queryClient.refetchQueries([
                    'useGetStorageAccountsQuery',
                    projectId,
                  ])
                }
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Text appearance="heading.card">Buckets</Text>
        <Tooltip title={isInactive ? 'Update plan to create more' : ''}>
          <Button
            type="primary"
            onClick={() =>
              navigate(
                'organisations.organisationId.projects.projectId.storageAccounts.new',
                {
                  organisationId,
                  projectId,
                }
              )
            }
            disabled={!!isInactive}
          >
            Create Bucket
          </Button>
        </Tooltip>
      </Flex>

      <TableContainer>
        <Flex alignItems="center" gap={10} padding={10}>
          <Flex>
            <Search
              placeholder="Search by name"
              value={searchValue}
              onChange={onSearch}
            />
          </Flex>
        </Flex>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show
          if={!isLoading && !error && storageAccountsData?.data.length === 0}
        >
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Bucket found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="buckets"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil(
              (storageAccountsData?.page.total || 0) / PAGE_SIZE
            )}
            totalRows={storageAccountsData?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
