import { Popover } from '@comet/blocks';
import React, { useEffect } from 'react';
import { NodeRaw } from '../Nodes';
import NodeExplorer from './NodeExplorer';

interface IProps {
  onClick: (node: NodeRaw) => void;
  children: React.ReactNode;
}

export const NodeExplorerPopup = ({ onClick, children }: IProps) => {
  const [popupVisible, setPopupVisible] = React.useState(false);
  const handleNodeClick = React.useCallback(
    (node: NodeRaw) => {
      setPopupVisible(false);
      onClick(node);
    },
    [onClick]
  );

  useEffect(() => {
    let listener: (event: MouseEvent) => void;
    if (popupVisible) {
      listener = (event) => {
        const targetId = (event.target as HTMLElement)?.id;
        if (targetId !== 'nodePopover' && popupVisible) {
          if ((event.target as HTMLElement).textContent === '+') {
            return;
          }
          setPopupVisible(false);
        }
      };

      document.addEventListener('click', listener);
    }

    return () => document.removeEventListener('click', listener);
  }, [popupVisible]);

  return (
    <Popover
      id="nodePopover"
      overlayClassName="node-explorer-popup"
      trigger="click"
      content={<NodeExplorer onClick={handleNodeClick} />}
      open={popupVisible}
      onOpenChange={setPopupVisible}
      placement="top"
      destroyTooltipOnHide={true}
    >
      {children}
    </Popover>
  );
};

export default NodeExplorerPopup;
