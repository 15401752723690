import { PaymentLoading } from '../Progress';
import { PaymentSuccess } from '../Success';
import { PaymentFailed } from '../Failed';
import { useGetPaymentStatus } from '../service';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from '@comet/router';

export const WaitingScreen = () => {
  // const { status } = usePollCheckPaymentStatus();
  const [enabled, setEnabled] = useState(true);
  const { data, error } = useGetPaymentStatus(enabled);
  const navigate = useNavigate();
  const [_, setHostedPageId] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const hostedPageId = searchParams.get('hostedpage_id');
    if (!hostedPageId) return;
    setHostedPageId(hostedPageId ?? '');
    setSearchParams({});
  }, [searchParams, setSearchParams]);

  const stopPolling = useCallback(() => {
    setEnabled(false);
  }, []);

  useEffect(() => {
    if (error?.response?.status === 404) {
      navigate('organisations.organisationId.projects.projectId.environments');
    }

    if (error?.response?.status === 400) {
      navigate('organisations.organisationId.projects.projectId.upgrade');
    }

    if (data?.paid) {
      stopPolling();
    }
  }, [data, error, navigate, stopPolling]);

  if (data?.paid) {
    return <PaymentSuccess />;
  }

  if (!data?.paid && !enabled) {
    return <PaymentFailed />;
  }

  return <PaymentLoading onTimerEnd={stopPolling} />;
};
