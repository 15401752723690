import {
  Button,
  Center,
  Flex,
  FullCenter,
  Show,
  Spin,
  Table,
  usePagination,
  Search,
  Text,
} from '@comet/blocks';
import { Empty, Result, Select, Tooltip } from 'antd';
import { ChangeEvent, useState } from 'react';

import { useNavigate, useParams } from '@comet/router';
import { useGetAnalyzersQuery } from './service';
import debounce from 'lodash.debounce';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import _ from 'lodash';
import EmptyImage from '@comet/assets/png/empty_list.png';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { ErrorPage } from '@comet/pages/ErrorPage';
import { useAnalyzersListingData } from './utils';
import { useGetEnvironmentsOptions } from '@comet/pages/Project/Environments/utils';

const PAGE_SIZE = 10;

export const AnalyzersList = () => {
  const { organisationId, projectId } = useParams();
  const navigate = useNavigate();
  const { pageOffset, currentPageIndex, fetchData } = usePagination();

  const { hasViewModelAccess, hasEditModelAccess } = useProjectPermissions();

  const initialValues = {
    offset: 0,
    model_type: '',
    query: '',
  };

  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState(initialValues.query);

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }
    debouncedQueryUpdate(e.target.value);
  };

  const currentValues = {
    offset: pageOffset,
    query,
  };

  const {
    data: analyzersData,
    isLoading,
    error,
    refetch,
  } = useGetAnalyzersQuery();

  const { tableColumns, tableData } = useAnalyzersListingData(
    analyzersData,
    refetch
  );

  const isFilterChanged = !_.isEqual(initialValues, currentValues);
  const isError = error || !analyzersData;
  const showList = !isLoading && !isError && analyzersData.data.length > 0;
  const {
    environmentsOptions,
    isEnvironmentsLoading,
    selectedEnvironment,
    setSelectedEnvironment,
  } = useGetEnvironmentsOptions();

  if (isLoading && !isFilterChanged) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!hasViewModelAccess) {
    return <ErrorPage />;
  }

  if (isError && !isFilterChanged) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">Analyzers</Text>
          <Show if={!isEnvironmentsLoading}>
            <Select
              options={environmentsOptions}
              defaultValue={selectedEnvironment}
              style={{ width: '200px' }}
              onSelect={setSelectedEnvironment}
            />
          </Show>
        </Flex>
        <Tooltip title={''}>
          <Button
            type="primary"
            onClick={() =>
              navigate(
                'organisations.organisationId.projects.projectId.analyzers.new',
                {
                  organisationId,
                  projectId,
                }
              )
            }
            disabled={!hasEditModelAccess}
          >
            Create Custom Analyzer
          </Button>
        </Tooltip>
      </Flex>
      <TableContainer>
        <Flex alignItems="center" gap={10} padding={10}>
          <Flex>
            <Search
              placeholder="Search by name"
              value={searchValue}
              onChange={onSearch}
            />
          </Flex>
        </Flex>
        <Show if={isLoading}>
          <FullCenter padding={20}>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show if={!isLoading && !error && analyzersData?.data.length === 0}>
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Custom Analyzers found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <Table
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="analyzers"
            currentPageIndex={currentPageIndex}
            totalPageCount={Math.ceil(
              (analyzersData?.page.total || 0) / PAGE_SIZE
            )}
            totalRows={analyzersData?.page.total || 0}
            fetchData={fetchData}
            style={{ flexGrow: 2 }}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
