import {
  MdOutlineAccountBalanceWallet,
  MdOutlineDesignServices,
} from 'react-icons/md';
import { TbGardenCart, TbListSearch } from 'react-icons/tb';
import { BiMovie } from 'react-icons/bi';

export const getModelType = (type: string) => {
  switch (type) {
    case 'REQUEST_BODY':
      return 'Request Body';
    case 'QUERY_PARAMS':
      return 'Query Params';
    case 'DATABASE_MODEL':
      return 'Database Collection';
  }
};

export const getIcon = (type: string) => {
  switch (type) {
    case 'CRUD_APIS': {
      return <MdOutlineDesignServices />;
    }
    case 'SEARCH_APIS': {
      return <TbListSearch />;
    }
    case 'FETCH_UPLOAD_MEDIA_APIS': {
      return <BiMovie />;
    }
    case 'CART_APIS': {
      return <TbGardenCart />;
    }
    case 'VECTOR_SEARCH_APIS': {
      return <TbListSearch />;
    }
    case 'PAYMENT_APIS': {
      return <MdOutlineAccountBalanceWallet />;
    }
  }
};
