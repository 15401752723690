import { Text } from '@comet/blocks';
import { styled } from '@comet/styled';
import { GrShare } from 'react-icons/gr';

export const StyledLink = styled.a<{
  disableTextDecoration?: boolean;
}>`
  ${({ disableTextDecoration }) =>
    disableTextDecoration ? 'text-decoration: none;' : ''}
  color: #2361be;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const ShareIcon = styled(GrShare)`
  width: 12px;
  height: 12px;
  margin-right: 5px;

  path {
    stroke: #2361be;
  }
`;

export const StyledText = styled(Text)`
  color: #8f8f8f;
`;

export const AlertMessageText = styled(Text)`
  color: ${({ theme }) => theme.palette.neutral.darkest};
  padding: 4px 12px;
`;
