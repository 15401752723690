export const PAGE_SIZE = 10;

export const SortByOptions = [
  {
    label: 'Updated On',
    value: 'updatedOn',
  },
  {
    label: 'Updated By',
    value: 'updatedBy',
  },
];
export const CloudProviders = [
  {
    label: 'Any',
    value: '',
  },
  {
    label: 'AWS',
    value: 'AWS',
  },
  {
    label: 'GCP',
    value: 'GCP',
  },
];
