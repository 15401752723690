import {
  Button,
  Center,
  Flex,
  Form,
  FullCenter,
  PageHeader,
  Show,
  Spin,
} from '@comet/blocks';
import {
  FlowEngineState,
  useFlowEngineStore,
} from '@comet/components/FlowEngine/FlowEngine.store';
import { NodeRaw, NodeTypeEnum } from 'src/FlowEngine2/types';
import { Result, notification } from 'antd';
import { updateApi, useApiQuery } from '../Service';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from '@comet/router';

import { APIDetailsFlow } from './ApiDetails.Flow';
import { ApiDetailsPage } from './ApiDetails';
import { ApiDetails as ApiDetailsType } from '../Types';
import { ErrorPage } from '@comet/pages/ErrorPage';
import FlexItem from 'src/blocks/FlexItem';
import { queryClient } from '@comet/query';
import { shallow } from 'zustand/shallow';
import { useFlowEngineStore as useFlowEngineV2Store } from 'src/FlowEngine2/store';
import { useMutation } from '@tanstack/react-query';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { sendErrorNotification } from 'src/blocks/Notification';
import { useGetOrgSubscriptionQuery } from '@comet/pages/Organisation/service';

const selector = (state: FlowEngineState) => ({
  // nodes: state.nodes,
  // edges: state.edges,
  getGraph: state.getGraph,
});

enum ViewEnum {
  Details = 'details',
  Flow = 'flow',
}

export const ApiDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [version, setVersion] = useState(searchParams.get('version') || null);
  const [modelVersion, setModelVersion] = useState(null);
  const [draft, setDraft] = useState(
    searchParams.get('draft') === 'true' || false
  );

  const { organisationId, projectId, apiId } = useParams();
  const { state } = useLocation();

  const { hasViewApiAccess, hasEditApiAccess } = useProjectPermissions();
  const { resetNodesAndEdges } = useFlowEngineV2Store.getState();

  // This useEffect should be called at once on component loading
  useEffect(() => {
    // This will reset the graph state
    resetNodesAndEdges();

    // this is to initialize search params
    if (state) {
      setSearchParams((params) => ({
        ...params,
        version: state.version,
        draft: state.draft,
        view: ViewEnum.Details,
      }));
      setVersion(state.version);
      setModelVersion(state.modelVersion);
      setDraft(state.draft);
    } else {
      setSearchParams((params) => ({
        ...params,
        version: version || null,
        draft: typeof draft === 'boolean' ? draft : null,
        view: ViewEnum.Details,
      }));
    }
  }, []);

  const {
    data: api,
    isLoading: isApiLoading,
    error: apiError,
    refetch: refetchApi,
  } = useApiQuery(projectId, apiId, {
    version: version === 'null' ? null : version,
    draft,
  });

  const { data: orgSubscriptionData } =
    useGetOrgSubscriptionQuery(organisationId);

  const isSaveApiDisabled = !orgSubscriptionData?.active;

  const [form] = Form.useForm();
  // alternatives are welcome,
  // this should not effect the performance
  // if you will access the state directly it will re-render the component
  // and that will re-render the flow engine and again the component
  const getGraphRef = useRef(useFlowEngineStore(selector, shallow));

  const patch = useMutation({
    mutationFn: (ApiData: Partial<ApiDetailsType>) =>
      updateApi(projectId, apiId, ApiData),
    onError: () =>
      sendErrorNotification({
        message: 'Error',
        description: 'There was an error in updating the API.',
        reportBug: true,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useApiQuery', projectId, apiId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useDeploymentsQuery', projectId],
      });
      setSearchParams((params) => ({
        ...params,
        version: null,
        draft: true,
        view,
      }));
      setVersion(null);
      setDraft(true);
      refetchApi();
      notification.success({ message: 'Success', description: 'Flow Saved!' });
    },
  });

  const view = searchParams.get('view');

  const error = apiError || !api;

  if (isApiLoading) {
    return (
      <Flex direction="column">
        <Spin />
      </Flex>
    );
  }

  if (!hasViewApiAccess) {
    return <ErrorPage />;
  }
  if (error) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() => {
                  refetchApi();
                }}
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  const handleSave = (formData: FormData) => {
    const { allNodes, allEdges, rootNode } = useFlowEngineV2Store.getState();
    const nodesToSave = allNodes;
    const edgesToSave = allEdges;

    const finalNodes = [...nodesToSave].map((node: NodeRaw) => {
      const {
        id,
        type,
        data: { schema, returns, ...rest },
        parentNode,
        extent,
        position,
        parentId,
      } = node;

      const updatedType =
        type === NodeTypeEnum.IfElse || type === NodeTypeEnum.IfElseV2
          ? 'IF_NODE'
          : type;

      return {
        id,
        type: updatedType,
        data: rest,
        position: position ? position : { x: 0, y: 0 },
        parentNode,
        extent: extent ? extent : undefined,
        parentId,
      };
    });

    let dataToUpdate: any = { ...formData, flowType: 'API' };
    if (finalNodes.length !== 0) {
      dataToUpdate = {
        ...dataToUpdate,
        nodes: finalNodes,
      };
    }
    if (edgesToSave.length !== 0) {
      dataToUpdate = {
        ...dataToUpdate,
        edges: edgesToSave,
      };
    }
    if (rootNode) {
      dataToUpdate = {
        ...dataToUpdate,
        rootNode,
      };
    }
    patch.mutate(dataToUpdate);
  };

  const data = api;
  const redirectUrl =
    'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/apis/creating-flows';

  return (
    <Flex direction="column" height="100%">
      <Form
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={data}
      >
        <PageHeader
          title={`API - ${data?.name || 'API Details'}`}
          primaryAction={
            <Button
              appearance="primary"
              htmlType="submit"
              loading={patch.isLoading}
              onClick={(e) => {
                handleSave(form.getFieldsValue());
              }}
              disabled={!hasEditApiAccess || isSaveApiDisabled}
            >
              Save
            </Button>
          }
          redirectUrl={redirectUrl}
          tabs={[
            {
              title: 'Details',
              active: view === ViewEnum.Details,
              onClick: () =>
                setSearchParams((params) => ({
                  ...params,
                  version,
                  draft,
                  view: ViewEnum.Details,
                })),
              id: 'details',
            },
            {
              title: 'Flow',
              active: view === ViewEnum.Flow,
              onClick: () =>
                setSearchParams((params) => ({
                  ...params,
                  version,
                  draft,
                  view: ViewEnum.Flow,
                })),
              id: 'flow',
            },
          ]}
        />
        <Show if={!!data && !error && view === ViewEnum.Details}>
          <ApiDetailsPage
            name={data?.name}
            modelVersion={modelVersion}
            description={data?.description}
            requestMethod={data?.flowMetadata.requestMethod}
            endPoint={data?.flowMetadata.endpoint}
            queryModelId={data?.flowMetadata.queryParams}
            requestModelId={data?.flowMetadata.requestBody}
            authenticated={data?.flowMetadata.authenticated}
            hasEditAccess={!!hasEditApiAccess}
          />
        </Show>
        <Show if={!!data && view === ViewEnum.Flow}>
          <FlexItem grow={2}>
            <APIDetailsFlow
              edges={data?.edges || []}
              nodes={data?.nodes || []}
              hasEditAccess={!!hasEditApiAccess}
              flowId={apiId}
              rootNode={data?.rootNode || ''}
            />
          </FlexItem>
        </Show>
      </Form>
    </Flex>
  );
};
