import { memo, useState } from 'react';
import { Show, Tag } from '@comet/blocks';
import { Wrapper } from '../../common/styled';
import { CometNodeBaseProps } from '../../Nodes';
import { useNodeEditorOpener } from '@comet/components/FlowEngine/FlowEngine.Editor';
import { ActionNode } from '../../common/ActionNode';
import { WarningModal } from 'src/blocks/WarningModal';
import { useReactFlow } from 'reactflow';
import { DeleteIcon } from 'src/blocks/Icons';
import { BusinessNodeEnum } from '../../BusinessNode';

export const LoopNodeDimension = {
  height: 10,
  width: 200,
};

export const LoopNodePreview = () => {
  return (
    <Wrapper>
      <Tag.CheckableTag checked>Loop</Tag.CheckableTag>
    </Wrapper>
  );
};

const LoopNode = (props: CometNodeBaseProps) => {
  const handleNodeClick = useNodeEditorOpener(props.id, props.data);
  const [deleteModal, setDeleteModal] = useState(false);
  const { getNode, deleteElements } = useReactFlow();

  const onClickDelete = () => {
    const node = getNode(props.id);
    if (!node) return;
    deleteElements({ nodes: [node] });
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <div onClick={handleNodeClick} style={{ width: '100%', height: '100%' }}>
        <ActionNode {...props} width={'100%'} height="100%" />
      </div>
      <Show if={props.data.type !== BusinessNodeEnum.EXECUTOR_NODE}>
        <DeleteIcon
          style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
          onClick={(e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteModal(true);
          }}
        />
      </Show>

      <WarningModal
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        onOk={onClickDelete}
        title="Are you sure you want to delete this node?"
        subTitle={
          'Deleting this node will also remove all of its children nodes. Are you sure you want to proceed and delete the entire node?'
        }
      />
    </div>
  );
};

export default memo(LoopNode);
