import {
  useNavigate as RRDUseNavigate,
  NavigateOptions,
} from 'react-router-dom';
import { compile } from 'path-to-regexp';
import routes, { RoutesIdType } from './routes';
import { useParams } from './useParams';

export const useNavigate = () => {
  const rrdNavigate = RRDUseNavigate();
  const params = useParams();

  return (
    routeId: RoutesIdType,
    pathParam?: Record<string, number | string | undefined>,
    options?: NavigateOptions
  ) => {
    const { path, type } = routes[routeId];

    if (process.env.NODE_ENV === 'production' && type === 'DEVELOPMENT') {
      return null;
    }

    const link = compile(path, { encode: encodeURIComponent })({
      ...params,
      ...pathParam,
    });
    rrdNavigate(link, options);
  };
};

export const useGoBack = () => {
  const rrdNavigate = RRDUseNavigate();

  return () => {
    rrdNavigate(-1);
  };
};

export { RRDUseNavigate };
