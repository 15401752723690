export enum FooterTypeEnum {
  Default = 'DEFAULT',
  Directional = 'DIRECTIONAL',
  ThreeButtonsLink = 'THREE_BUTTONS_WITH_LINK',
  ThreeButtons = 'THREE_BUTTONS',
}

export type FooterPropsType =
  | DefaultFooterPropsType
  | DirectionalFooterPropsType
  | ThreeButtonsFooterPropsType
  | ThreeButtonsLinkFooterPropsType;

export type DefaultFooterPropsType = {
  type: FooterTypeEnum.Default;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
};

export type DirectionalFooterPropsType = {
  type: FooterTypeEnum.Directional;
  primaryButtonText?: string;
  tertiaryButtonText?: string;
  onClickPrimaryButton?: () => void;
  onClickTertiaryButton?: () => void;
};

export type ThreeButtonsFooterPropsType = {
  type: FooterTypeEnum.ThreeButtons;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  tertiaryButtonText?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  onClickTertiaryButton?: () => void;
};

export type ThreeButtonsLinkFooterPropsType = {
  type: FooterTypeEnum.ThreeButtonsLink;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  tertiaryLinkText?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  onClickTertiaryLink?: () => void;
};
