import { Flex, FormItem, Show, Switch, Text, Tabs } from '@comet/blocks';
import { FormInstance } from 'antd';
import { DynamicFields } from '../DynamicFields';
interface SearchIndexMappingProps {
  modelId: string;
  form: FormInstance;
}

export const SearchIndexMappings = ({
  modelId,
  form,
}: SearchIndexMappingProps) => {
  return (
    <Flex direction="column" gap={10}>
      <Flex direction="column" gap={10}>
        <Text appearance="label.short.regular">Field Mappings</Text>
        <DynamicFields form={form} modelId={modelId} />
      </Flex>
    </Flex>
  );
};
