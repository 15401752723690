import { ModalPropsType } from '../Modal/types';

export enum ActionModalTypesEnum {
  Question = 'Question',
  Info = 'Info',
  Error = 'Error',
  Warning = 'Warning',
  Confirm = 'Confirm',
}

export type ActionModalPropsType = ModalPropsType & {
  type: ActionModalTypesEnum;
};
