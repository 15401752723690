import { FiMoreHorizontal } from 'react-icons/fi';
import { Column } from 'react-table';
import { RiAlertLine } from 'react-icons/ri';
import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
  Tooltip,
} from '@comet/blocks';
import { Link, useParams } from '@comet/router';
import { queryClient } from '@comet/query';
import { styled, useTheme } from '@comet/styled';
import _ from 'lodash';
import { GetDatabasesResponse } from '../types';
import { updateDatabaseInstance } from '../service';

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

//delete database listing data
export const DeleteDatabaseModal = ({
  id,
  environmentId,
  type,
  refetch,
  disabled,
}: {
  id: string;
  disabled?: boolean;
  environmentId: string;
  type: 'SHARED' | 'DEDICATED';
  refetch: () => void;
}) => {
  const { organisationId, projectId } = useParams();
  const [isConfirm, setIsConfirm] = useState(false);
  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    await updateDatabaseInstance(projectId, environmentId, id, type);
    setIsConfirm(false);
    refetch();
  }, [id, projectId, refetch]);

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              title: 'Delete',
              key: 'delete',
              label: <Tooltip title={'delete database'}>Delete</Tooltip>,
              onClick: toggleModal,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this database?{' '}
              </Text>
              <Text appearance="label.short.regular">
                Please check if this database is not in use, once deletd
                database cannot be recovered.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useDatabasesListingData = (
  databasesData: GetDatabasesResponse | undefined,
  refetch: () => void
) => {
  const { theme } = useTheme();
  const tableData = useMemo(() => {
    if (!databasesData) return [];
    const databaseList = databasesData.data;

    return databaseList.map((database) => {
      return {
        name: database.name,
        resources: database.type,
        id: database.id,
        createdBy: database.createdBy.name,
        environmentId: database.environmentId,
        action: (
          <DeleteDatabaseModal
            id={database.id}
            environmentId={database.environmentId}
            type={database.type}
            refetch={refetch}
          />
        ),
      };
    });
  }, [databasesData, refetch, theme]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        Cell: (data) => {
          const currentRow = data.row.original;
          const { projectId, organisationId } = useParams();
          return (
            <Link
              routeId="organisations.organisationId.projects.projectId.databases.instanceId"
              pathParam={{
                instanceId: currentRow.id,
                organisationId,
                projectId,
              }}
              state={{ envId: currentRow.environmentId }}
            >
              {currentRow.name}
            </Link>
          );
        },
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Resources" />,
        accessor: 'resources',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Created by" />,
        accessor: 'createdBy',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
      },
    ];
  }, []);

  return { tableColumns, tableData };
};
