import { memo, useState } from 'react';
import { Tag } from '@comet/blocks';
import { Wrapper } from '../../common/styled';
import { CometNodeBaseProps } from '../../Nodes';

import ActionNode from '../../common/ActionNode/ActionNode';
import { useNodeEditorOpener } from '@comet/components/FlowEngine/FlowEngine.Editor';

import { WarningModal } from 'src/blocks/WarningModal';
import { useReactFlow } from 'reactflow';
import { useReplaceAddNode } from '@comet/hooks/useReplaceAddNode';
import { DeleteIcon } from 'src/blocks/Icons';
import { useDeleteIfNode } from '@comet/hooks/useDeleteIfNodeV2';

export const IfNodeDimension = {
  height: 80,
  width: 220,
};

export const IfNodePreview = () => {
  return (
    <Wrapper>
      <Tag.CheckableTag checked>If</Tag.CheckableTag>
    </Wrapper>
  );
};

const IfNode = (props: CometNodeBaseProps) => {
  const handleNodeClick = useNodeEditorOpener(props.id, props.data);
  const [deleteModal, setDeleteModal] = useState(false);
  const { getNode } = useReactFlow();

  const deleteIfNode = useDeleteIfNode();
  const replaceWithAddNode = useReplaceAddNode(deleteIfNode);

  const onClickDelete = () => {
    const node = getNode(props.id);
    if (!node) return;
    if (replaceWithAddNode(node)) return;
    deleteIfNode(node);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={handleNodeClick}>
        <ActionNode {...props} />
      </div>
      <DeleteIcon
        style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}
        onClick={() => setDeleteModal(true)}
      />

      <WarningModal
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        onOk={onClickDelete}
        title="This action will delete all the connected nodes too"
        subTitle={
          'Deleting this node will also remove all of its children nodes. Are you sure you want to proceed and delete the entire node?'
        }
      />
    </div>
  );
};

export default memo(IfNode);
