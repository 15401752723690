import { EnvironmentMetadata, GetEnvironmentMetadata } from '../types';

export const getUpdateEventType = (
  currentMetadata: GetEnvironmentMetadata,
  updatedMetadata: EnvironmentMetadata
) => {
  if (currentMetadata.isShared === updatedMetadata.isShared) {
    if (
      currentMetadata.sharedEnvId &&
      updatedMetadata.sharedEnvId &&
      currentMetadata.sharedEnvId !== updatedMetadata.sharedEnvId
    ) {
      return 'UPDATED_SHARED_ENV_ID';
    }

    if (
      currentMetadata.tier &&
      updatedMetadata.tier &&
      currentMetadata.tier !== updatedMetadata.tier
    ) {
      return 'UPDATED_TIER';
    }
  }

  if (currentMetadata.isShared && !updatedMetadata.isShared) {
    return 'SWITCHED_TO_DEDICATED';
  }

  if (!currentMetadata.isShared && updatedMetadata.isShared) {
    return 'SWITCHED_TO_SHARED';
  }

  return null;
};
