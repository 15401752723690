import { useMemo } from 'react';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { Flex, Text } from '@comet/blocks';
import { SidebarMenuItemGroupWithoutKey } from './types';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';

export const useGetProjectMenuItemsWithoutKey =
  (): SidebarMenuItemGroupWithoutKey[] => {
    const {
      hasViewApiAccess,
      hasViewModelAccess,
      hasViewReleasesAccess,
      hasManageProjectSettingsAccess,
      hasManageUserPermissionsAccess,
    } = useProjectPermissions();

    const { hasViewBillingsAccess } = useOrgPermissions();

    return useMemo(() => {
      const result: SidebarMenuItemGroupWithoutKey[] = [
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Dashboard
            </Text>
          ),
          route: 'organisations.organisationId.projects.projectId.dashboard',
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Environments
            </Text>
          ),
          route: 'organisations.organisationId.projects.projectId.environments',
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Database Instances
            </Text>
          ),
          route: 'organisations.organisationId.projects.projectId.databases',
          hidden: true,
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Object Storage
            </Text>
          ),
          route:
            'organisations.organisationId.projects.projectId.storageAccounts',
          hidden: false,
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Secrets
            </Text>
          ),
          route: 'organisations.organisationId.projects.projectId.secrets',
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Application Layer
            </Text>
          ),
          children: [
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  APIs
                </Text>
              ),
              route: 'organisations.organisationId.projects.projectId.api',
              hidden: !hasViewApiAccess,
            },
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  SubFlows
                </Text>
              ),
              route: 'organisations.organisationId.projects.projectId.subflows',
              hidden: !hasViewApiAccess,
            },
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  Request Models
                </Text>
              ),
              route: 'organisations.organisationId.projects.projectId.models',
              hidden: !hasViewModelAccess,
            },
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  DB Models
                </Text>
              ),
              route: 'organisations.organisationId.projects.projectId.dbModels',
              hidden: !hasViewModelAccess,
            },
            {
              label: (
                <Flex gap={4} alignItems="center">
                  <Text appearance="label.long.regular" color="neutral.darker">
                    Sub-flows
                  </Text>
                  <Text appearance="caption.regular" color="neutral.darker">
                    (Coming Soon)
                  </Text>
                </Flex>
              ),
              route: '*',
              disabled: true,
              hidden: true,
            },
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  Releases
                </Text>
              ),
              route: 'organisations.organisationId.projects.projectId.releases',
              hidden: !hasViewReleasesAccess,
            },
            {
              label: (
                <Flex gap={4} alignItems="center">
                  <Text appearance="label.long.regular" color="neutral.darker">
                    Logs
                  </Text>
                </Flex>
              ),
              route: 'organisations.organisationId.projects.projectId.logs',
            },
          ],
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Full Text Search
            </Text>
          ),
          children: [
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  Search Indexes
                </Text>
              ),
              route:
                'organisations.organisationId.projects.projectId.search-indexes',
              hidden: !hasViewApiAccess,
            },
            {
              label: (
                <Text appearance="label.long.regular" color="neutral.darker">
                  Analyzers
                </Text>
              ),
              route:
                'organisations.organisationId.projects.projectId.analyzers',
              hidden: !hasViewApiAccess,
            },
          ],
          hidden: !hasManageProjectSettingsAccess,
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Vector Search Indexes
            </Text>
          ),
          route:
            'organisations.organisationId.projects.projectId.vector-search-indexes',
          hidden: !hasViewApiAccess,
        },
        {
          label: (
            <Flex gap={4} alignItems="center">
              <Text appearance="label.long.semiBold" color="neutral.darker">
                Event streams
              </Text>
              <Text appearance="caption.regular" color="neutral.darker">
                (Coming Soon)
              </Text>
            </Flex>
          ),
          route: '*',
          disabled: true,
          hidden: true,
        },
        {
          label: (
            <Flex gap={4} alignItems="center">
              <Text appearance="label.long.semiBold" color="neutral.darker">
                Caches
              </Text>
              <Text appearance="caption.regular" color="neutral.darker">
                (Coming Soon)
              </Text>
            </Flex>
          ),
          route: '*',
          disabled: true,
          hidden: true,
        },
        {
          label: (
            <Flex gap={4} alignItems="center">
              <Text appearance="label.long.semiBold" color="neutral.darker">
                Background jobs
              </Text>
              <Text appearance="caption.regular" color="neutral.darker">
                (Coming Soon)
              </Text>
            </Flex>
          ),
          route: '*',
          disabled: true,
          hidden: true,
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              User Management
            </Text>
          ),
          route: 'organisations.organisationId.projects.projectId.users',
          hidden: !hasManageUserPermissionsAccess,
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Billing and Payments
            </Text>
          ),
          href: process.env.REACT_APP_ZOHO_CUSTOMER_PORTAL_URL,
          hidden: true,
        },
        {
          label: (
            <Text appearance="label.long.semiBold" color="neutral.darkest">
              Settings
            </Text>
          ),
          route: 'organisations.organisationId.projects.projectId.settings',
          hidden: !hasManageProjectSettingsAccess,
        },
      ];

      return result;
    }, [
      hasManageProjectSettingsAccess,
      hasManageUserPermissionsAccess,
      hasViewApiAccess,
      hasViewModelAccess,
      hasViewReleasesAccess,
    ]);
  };
