import { User } from '@comet/pages/Project/types/Projects.types';

export interface Analyzer {
  name: string;
  charFilters?: CharFiltersBody[];
  tokenFilters?: TokenFilterFormData[];
  tokenizer: {
    type: string;
    minGram?: number;
    maxGram?: number;
    group?: number;
    pattern?: string;
    maxTokenLength?: number;
  };
  id: string;
  inUse: boolean;
  updatedBy: User;
  updatedOn: number;
  createdOn: number;
}

export interface GetAnalyzersResponse {
  data: Analyzer[];
  page: {
    offset: number;
    limit: number;
    total: number;
  };
}

export type AnalyzerTypes =
  | 'edgeGram'
  | 'keyword'
  | 'nGram'
  | 'regexCaptureGroup'
  | 'regexSplit'
  | 'standard'
  | 'uaxUrlEmail'
  | 'whitespace';

export type CharacterFilterTypes =
  | 'htmlStrip'
  | 'icuNormalize'
  | 'mapping'
  | 'persian';

export type TokenFilterTypes =
  | 'asciiFolding'
  | 'daitchMokotoffSoundex'
  | 'edgeGram'
  | 'englishPossessive'
  | 'flattenGraph'
  | 'icuFolding'
  | 'icuNormalizer'
  | 'kStemming'
  | 'length'
  | 'lowercase'
  | 'nGram'
  | 'porterStemming'
  | 'regex'
  | 'reverse'
  | 'shingle'
  | 'snowballStemming'
  | 'spanishPluralStemming'
  | 'stempel'
  | 'stopword'
  | 'trim'
  | 'wordDelimiterGraph';

export interface TokenizerInputType {
  type: 'number' | 'text';
  required: boolean;
  name: string;
  displayName: string;
  max?: number;
  min?: number;
}
export type CharacterFilterInputType =
  | undefined
  | {
      type: 'number' | 'text' | 'stringArray' | 'object';
      required: boolean;
      name: string;
      displayName: string;
    };

export interface DynamicFieldsType {
  minGram: number;
  maxGram: number;
  pattern: string;
  group: number;
  maxTokenLength: number;
}

export interface CreateAnalyzerFormData {
  name: string;
  type: AnalyzerTypes;
  minGram?: number;
  maxGram?: number;
  pattern?: string;
  group?: number;
  maxTokenLength?: number;
  charFilters: CharFilterFormData[];
  tokenFilters: TokenFilterFormData[];
}

export interface AnalyzerRequestData {
  name: string;
  tokenizer: {
    type: AnalyzerTypes;
    minGram?: number;
    maxGram?: number;
    pattern?: string;
    group?: number;
    maxTokenLength?: number;
  };
  tokenFilters?: TokenFilterFormData[];
  charFilters?: CharFiltersBody[];
}

export type CharFiltersBody =
  | {
      type: 'icuNormalize' | 'persian';
    }
  | {
      type: 'htmlStrip';
      ignoredTags?: string[];
    }
  | {
      type: 'mapping';
      mappings: Record<string, string>;
    };
export interface CharFilterFormData {
  type: CharacterFilterTypes;
  ignoredTags?: string[];
  mappings?: string[][];
}

export enum SnowBallStemmingType {
  arabic = 'arabic',
  armenian = 'armenian',
  basque = 'basque',
  catalan = 'catalan',
  danish = 'danish',
  dutch = 'dutch',
  english = 'english',
  estonian = 'estonian',
  finnish = 'finnish',
  french = 'french',
  german = 'german',
  german2 = 'german2',
  hungarian = 'hungarian',
  irish = 'irish',
  italian = 'italian',
  kp = 'kp',
  lithuanian = 'lithuanian',
  lovins = 'lovins',
  norwegian = 'norwegian',
  porter = 'porter',
  portuguese = 'portuguese',
  romanian = 'romanian',
  russian = 'russian',
  spanish = 'spanish',
  swedish = 'swedish',
  turkish = 'turkish',
}
export type TokenFilterFormData =
  | {
      type:
        | 'englishPossessive'
        | 'flattenGraph'
        | 'icuFolding'
        | 'kStemming'
        | 'lowercase'
        | 'porterStemming'
        | 'reverse'
        | 'spanishPluralStemming'
        | 'stempel'
        | 'trim';
    }
  | {
      type: 'daitchMokotoffSoundex' | 'asciiFolding';
      originalTokens?: string;
    }
  | {
      type: 'edgeGram' | 'nGram';
      minGram: number;
      maxGram: number;
      termNotInBounds?: string;
    }
  | {
      type: 'icuNormalizer';
      normalizationForm?: string;
    }
  | {
      type: 'length';
      max?: number;
    }
  | {
      type: 'regex';
      pattern: string;
      replacement: string;
    }
  | {
      type: 'shingle';
      minShingleSize: number;
      maxShingleSize: number;
    }
  | {
      type: 'snowballStemming';
      stemmerName: SnowBallStemmingType;
    }
  | {
      type: 'stopword';
      tokens: string[];
      ignoreCase?: boolean;
    }
  | {
      type: 'wordDelimiterGraph';
      delimiterOptions?: {
        generateWordParts?: boolean;
        generateNumberParts?: boolean;
        concatenateWords?: boolean;
        concatenateNumbers?: boolean;
        concatenateAll?: boolean;
        preserveOriginal?: boolean;
        splitOnCaseChange?: boolean;
        splitOnNumerics?: boolean;
        stemEnglishPossessive?: boolean;
        ignoreKeywords?: boolean;
      };
      protectedWords?: {
        words: string[];
        ignoreCase?: boolean;
      };
    };

export type TokenFilterInputTpe =
  | {
      type: 'object';
      fields?: {
        name: string;
        required: boolean;
        type: 'stringarray' | 'text' | 'number' | 'boolean';
      }[];
    }
  | {
      type: 'dropdown';
      options: { label: string; value: string }[];
    }
  | {
      type: 'text' | 'stringarray' | 'number' | 'boolean';
    };

export type TokenFilterInputFieldType = {
  name: string;
  required: boolean;
} & TokenFilterInputTpe;
