import { Flex, Show, Text } from '@comet/blocks';
import { useParams } from '@comet/router';
import { styled } from '@comet/styled';
import { HeaderBreadcrumbOrg } from './Header.Breadcrumb.Org';
import { HeaderBreadcrumbProject } from './Header.Breadcrumb.Project';

const Container = styled(Flex)`
  background: ${({ theme }) => theme.palette.neutral.white};
  padding: 8px 24px;
`;

const Item = styled.span<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.8 : 1)};
  &:hover {
    opacity: 1;
  }
`;

export const HeaderBreadcrumb = () => {
  const { organisationId, projectId } = useParams();
  const step = organisationId ? (projectId ? 2 : 1) : 0;

  if (step === 0) return null;

  return (
    <Container alignItems="center" gap={8}>
      <Show if={!!organisationId}>
        <Item isDisabled={step !== 1}>
          <HeaderBreadcrumbOrg organisationId={organisationId!} />
        </Item>
      </Show>
      <Show if={!!projectId}>
        <Text appearance="label.short.regular">/</Text>
        <Item isDisabled={step !== 2}>
          <HeaderBreadcrumbProject
            projectId={projectId!}
            organisationId={organisationId!}
          />
        </Item>
      </Show>
    </Container>
  );
};
