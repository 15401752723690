import {
  getEdgeById,
  getEdgeId,
} from 'src/FlowEngine2/store/flow-engine-store/utils';
import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

import { NodeRaw } from 'src/FlowEngine2/types';
import { addNodeFromAddNode } from './addNodeFromNode';
import { addNodeFromEdge } from './addNodeFromEdge';

export function addSwitchNode(
  type: 'ADD_NODE' | 'EDGE', // A Node can be added either from add_node or edge
  id: string | null, // id can be of node or edge
  nodeToAdd: NodeRaw | null,
  branch?: 'Default' | 'Other'
) {
  if (!id || !nodeToAdd) return;

  switch (type) {
    case 'ADD_NODE': {
      const { createNode, addEdge } = useFlowEngineStore.getState();

      // Switch Node Tree
      const switchNode = getNodeById(createNode(nodeToAdd));
      if (!switchNode) return new Error(`Error in creating switch node`);

      const addCaseNode = getNodeById(
        createNode('ADD_CASE_NODE', { switchNodeId: switchNode?.id })
      );

      if (!addCaseNode) return new Error(`Error in creating switch node`);

      addEdge(switchNode.id, addCaseNode.id);

      const defaultNodeId = createNode('ADD_NODE');
      addEdge(switchNode.id, defaultNodeId);
      switchNode.data.metadata = {
        cases: [
          {
            key: 'default',
            nodeId: defaultNodeId,
            edgeId: getEdgeId(switchNode.id, defaultNodeId),
          },
        ],
      };
      switchNode.data.values = {
        cases: [{ value: 'default' }],
      };

      addNodeFromAddNode(id, switchNode, []);

      break;
    }
    case 'EDGE': {
      if (!id) return;

      const { createNode, addEdge, switchToStackTop } =
        useFlowEngineStore.getState();

      const edge1 = getEdgeById(id);

      if (!edge1) return new Error(`Error in creating switch node`);

      const node1 = getNodeById(edge1.source);
      const node2 = getNodeById(edge1.target);
      const switchNode = getNodeById(createNode(nodeToAdd));

      if (!node1 || !node2 || !switchNode)
        return new Error(`Error in creating switch node`);

      const addCaseNode = getNodeById(
        createNode('ADD_CASE_NODE', { switchNodeId: switchNode.id })
      );

      if (!addCaseNode) return new Error(`Error in creating switch node`);

      addEdge(node1.id, switchNode.id);
      addEdge(switchNode.id, addCaseNode.id);

      addNodeFromEdge(id, switchNode, [switchNode]);

      if (branch === 'Default') {
        switchNode.data.metadata.cases = [
          {
            key: 'default',
            nodeId: node2.id,
            edgeId: getEdgeId(switchNode.id, node2.id),
          },
        ];
        switchNode.data.values = {
          cases: [{ value: 'default' }],
        };
      } else {
        const defaultNode = getNodeById(createNode('ADD_NODE')!)!;
        addEdge(switchNode.id, defaultNode.id);
        switchNode.data.metadata.cases = [
          {
            key: 'default',
            nodeId: defaultNode.id,
            edgeId: getEdgeId(switchNode.id, defaultNode.id),
          },
          {
            key: 'label',
            nodeId: node2.id,
            edgeId: getEdgeId(switchNode.id, node2.id),
          },
        ];
        switchNode.data.values = {
          cases: [{ value: 'default' }, { value: 'label' }],
        };
      }
      switchToStackTop();

      break;
    }
    default:
  }
}
