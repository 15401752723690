export const ResourceTypesOptions = [
  {
    label: 'Dedicated',
    value: false,
  },
  {
    label: 'Shared',
    value: true,
  },
];
