import {
  Button,
  Center,
  CustomSelect,
  Flex,
  FullCenter,
  InfiniteScrollTable,
  Show,
  Spin,
  Text,
} from '@comet/blocks';
import { Empty, Result } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import EmptyImage from '@comet/assets/png/empty_list.png';
import { useGetEnvironmentsQuery } from '@comet/pages/Project/Environments';
import { TableContainer } from '@comet/pages/Project/Project.styled';
import { useParams } from '@comet/router';
import { useLogsQuery } from '../service';
import { IGetLogsResponse, ILog, ILogResult } from '../types';
import { useLogListingData } from '../utils';

const PAGE_SIZE = 100;

export const LogsList = () => {
  const { projectId } = useParams();

  const [currentEnv, setCurrentEnv] = useState<string | undefined>(undefined);
  const { data: environmentsData } = useGetEnvironmentsQuery(projectId, {
    offset: 0,
  });
  const [allLogs, setAllLogs] = useState<ILog[]>([]);
  const [allLogsEndTimeStamp, setAllLogsEndTimeStamp] = useState<
    number | undefined
  >();
  const [moreDataAvailable, setMoreDataAvailable] = useState(false);

  const envOptions = useMemo(
    () =>
      environmentsData?.data?.map((env) => ({
        label: env.name,
        value: env.name,
      })),
    [environmentsData]
  );

  useEffect(() => {
    if (envOptions?.length) {
      setCurrentEnv(envOptions[0].value);
    }
  }, [envOptions]);

  useEffect(() => {
    setAllLogs([]);
  }, [currentEnv]);

  const {
    isLoading,
    isFetching: fetchingLogs,
    error,
    refetch,
  } = useLogsQuery(
    projectId,
    {
      environment_name: currentEnv!,
      end: allLogsEndTimeStamp,
      limit: 100,
    },
    !!currentEnv,
    (logs: IGetLogsResponse) => {
      {
        setMoreDataAvailable(logs.data.length >= 100);
        setAllLogs((prev) => Array.from(new Set([...prev, ...logs.data])));
      }
    }
  );

  const refreshLogs = () => {
    setAllLogs([]);
    setAllLogsEndTimeStamp(undefined);
    refetch();
  };

  const { tableColumns, tableData } = useLogListingData(allLogs);
  const onFetchMore = () => {
    if (moreDataAvailable) {
      setAllLogsEndTimeStamp((allLogs[allLogs.length - 1].timestamp - 1) * 1e6);
    }
  };

  const isError = error;
  const showList = !!currentEnv && !isError && allLogs.length > 0;

  if (isError) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Flex
      width="100%"
      direction="column"
      gap={12}
      style={{ minHeight: '100%' }}
      padding={16}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex gap={8} alignItems="center">
          <Text appearance="heading.card">Logs</Text>
        </Flex>
      </Flex>
      <TableContainer>
        <Flex alignItems="center" gap={10} padding={10}>
          <CustomSelect
            label="Select Environment"
            onChange={setCurrentEnv}
            options={envOptions ?? []}
            value={currentEnv}
          />
          <Button type="primary" onClick={refreshLogs}>
            Refresh
          </Button>
        </Flex>
        <Show if={fetchingLogs && allLogs.length === 0 && !!currentEnv}>
          <FullCenter>
            <Spin size="large" />
          </FullCenter>
        </Show>
        <Show
          if={!isLoading && !fetchingLogs && !error && allLogs.length === 0}
        >
          <FullCenter padding={20}>
            <Empty
              image={EmptyImage}
              imageStyle={{ height: 225 }}
              description={<span>No Logs found</span>}
            />
          </FullCenter>
        </Show>
        <Show if={showList}>
          <InfiniteScrollTable
            moreDataAvailable={moreDataAvailable}
            onFetchMore={onFetchMore}
            tableColumns={tableColumns}
            tableData={tableData}
            rowType="logs"
            style={{ flexGrow: 2 }}
            fetching={fetchingLogs}
          />
        </Show>
      </TableContainer>
    </Flex>
  );
};
