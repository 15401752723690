import { useEffect, useState } from 'react';
import { Button } from '../Button';
import { Modal, notification } from 'antd';
import { Flex } from '../Flex';
import { Editor } from '@monaco-editor/react';
import { useTheme } from 'styled-components';
import { sendErrorNotification } from '../Notification';
import { MdOpenInNew } from 'react-icons/md';

interface JSONInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const JSONSnippet = ({ value, onChange }: JSONInputProps) => {
  const [modal, setModal] = useState(false);
  const [text, setText] = useState('');
  const redirectUrl =
    'https://docs.cosmocloud.io/cosmocloud-documentation/concepts/resources-and-services/apis/magical-autocomplete';

  useEffect(() => {
    setText(value);
  }, [value]);

  const theme = useTheme();

  return (
    <div>
      <Button onClick={() => setModal(true)}>Edit</Button>
      <Modal
        open={modal}
        onCancel={() => setModal(false)}
        onOk={() => {
          try {
            JSON.parse(text);
            onChange(text);
            setModal(false);
          } catch {
            sendErrorNotification({
              message: 'Invalid JSON',
              description: 'Please check your JSON and try again',
            });
          }
        }}
        closable={false}
        cancelText="Discard"
        okText="Save"
        // cancelButtonProps={{ type: 'ghost' }}
        width={600}
      >
        <Flex justifyContent="space-between">
          <h3>JSON Editor</h3>
          <Button
            type="link"
            onClick={() => window.open(redirectUrl, '_blank')}
          >
            <Flex gap={10} alignItems="center" justifyContent="center">
              <MdOpenInNew />
              <div>Learn more</div>
            </Flex>
          </Button>
        </Flex>
        <Flex
          style={{
            marginTop: 10,
            border: `1px solid ${theme.palette.neutral.light}`,
            borderRadius: theme.constants.borderRadius.lg,
          }}
        >
          <Editor
            defaultLanguage="json"
            language="json"
            defaultValue={`{}`}
            className="json_editor"
            onChange={(value) => setText(value ?? '')}
            options={{
              hideCursorInOverviewRuler: false,
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              fontSize: 16,
            }}
            value={value}
            height={'60vh'}
          />
        </Flex>
      </Modal>
    </div>
  );
};
