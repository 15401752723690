import { styled } from '@comet/styled';

export interface IGridProps extends React.HTMLAttributes<HTMLDivElement> {
  templateColumns?: string;
  templateRows?: string;
  columnGap?: string;
  rowGap?: string;
  gap?: number;
  alignItems?: string;
  justifyContent?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode | undefined;
}

const Div = styled.div``;

export const Grid = ({
  templateColumns,
  templateRows,
  columnGap,
  rowGap,
  gap,
  alignItems,
  justifyContent,
  style,
  ...props
}: IGridProps) => {
  return (
    <Div
      {...props}
      style={{
        display: 'grid',
        gridTemplateColumns: templateColumns,
        gridTemplateRows: templateRows,
        columnGap,
        rowGap,
        gap,
        alignItems,
        justifyContent,
        ...style,
      }}
    />
  );
};
