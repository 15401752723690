import { styled } from '@comet/styled';
import WelcomeHeaderImage from '@comet/assets/png/welcomePageHeader.png';
import WaitlistImage from '@comet/assets/png/waitlist.png';

import {
  Button,
  Center,
  Flex,
  Form,
  FormItem,
  FullCenter,
  Input,
  Paper,
  Select,
  Text,
} from '@comet/blocks';
import { Image, Result, Space, Spin, notification } from 'antd';
import { useAuth } from '@comet/components/auth/AuthProvider';
import { updateUser, useGetUserQuery } from '../User/User.service';
import { rolesOptions } from './Waitlist.constants';
import { useMutation } from '@tanstack/react-query';
import { UpdateUserData, UpdateUserDataReq } from '../User/types';
import { useEffect } from 'react';
import { useNavigate } from '@comet/router';
import { useDevice } from '@comet/hooks/useDevice';
import { sendErrorNotification } from 'src/blocks/Notification';

const Container = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div<{
  src: string;
}>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  margin: auto;
  display: grid;
  place-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
`;

const ResponsiveFlex = styled(Flex)`
  @media screen and (max-width: ${({ theme }) => theme.devices.mobile}) {
    flex-direction: column-reverse;
    gap: 0;
  }
`;

export const Waitlist = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const { data, error, isError, isLoading, refetch } = useGetUserQuery(
    user?.id,
    !!user?.id
  );

  const userData = data?.data;
  const updateUserMutation = useMutation({
    mutationFn: (updatedUserData: UpdateUserDataReq) =>
      updateUser(userData?.id as string, updatedUserData),
    onSuccess: () => {
      refetch();
      navigate('organisations');
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description:
          'There was an error in updating user details, please try again!',
        reportBug: true,
      });
    },
  });

  const onUpdateUser = (formData: UpdateUserData) => {
    if (!userData?.id) {
      return;
    }

    const { name } = formData;
    const updatedUserData: UpdateUserDataReq = {
      name: name,
      picture: null,
      extraInfo: {
        designation: formData.designation,
        companyName: formData.companyName,
        linkedInProfileURL: formData.linkedInProfileURL,
      },
    };

    updateUserMutation.mutate(updatedUserData);
  };

  const { deviceType } = useDevice();

  if (isLoading || loading || isError || error || !userData) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError || error || !userData) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button onClick={() => refetch()} type="primary">
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Container>
      <Header src={WelcomeHeaderImage} hidden={deviceType === 'mobile'}>
        Welcome to Cosmocloud
      </Header>
      <ResponsiveFlex width="100%" gap={deviceType === 'mobile' ? 0 : 16}>
        <Flex width={deviceType === 'mobile' ? '100%' : 640}>
          <Paper size="large" parentWidth="100%">
            <Form
              layout="vertical"
              initialValues={userData}
              onFinish={onUpdateUser}
              form={form}
            >
              <Flex direction="column" gap={26}>
                <Flex direction="column" gap={16}>
                  <Text
                    appearance="heading.page"
                    color="neutral.darkest"
                    hidden={deviceType === 'mobile'}
                  >
                    Tell us a bit about yourself
                  </Text>
                  <FormItem
                    label={<Text appearance="label.long.regular">Name</Text>}
                    name="name"
                    marginBottom={0}
                    colon={false}
                  >
                    <Input placeholder="Enter your name" />
                  </FormItem>
                  <FormItem
                    label={
                      <Space>
                        <Text appearance="label.long.regular">
                          LinkedIn Profile Link
                        </Text>
                        <Text
                          appearance="label.long.regular"
                          color="neutral.dark"
                        >
                          (Optional)
                        </Text>
                      </Space>
                    }
                    name="linkedInProfileURL"
                    marginBottom={0}
                    colon={false}
                  >
                    <Input placeholder="LinkedIn profile link" />
                  </FormItem>
                  <FormItem
                    label={
                      <Space>
                        <Text appearance="label.long.regular">Company</Text>
                      </Space>
                    }
                    marginBottom={0}
                    colon={false}
                    name="companyName"
                  >
                    <Input
                      placeholder="Enter company name"
                      name="companyName"
                    />
                  </FormItem>
                  <FormItem
                    label={
                      <Space>
                        <Text appearance="label.long.regular">Designation</Text>
                      </Space>
                    }
                    marginBottom={20}
                    colon={false}
                    name="designation"
                  >
                    <Select placeholder="Select Role" options={rolesOptions} />
                  </FormItem>
                  <Flex justifyContent="flex-end" gap={10}>
                    <Button
                      appearance="transparent"
                      onClick={() => navigate('organisations.new')}
                    >
                      Skip
                    </Button>
                    <Button appearance="primary" htmlType="submit">
                      Let's get started
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Form>
          </Paper>
        </Flex>
        <Flex width={deviceType === 'mobile' ? '100%' : 368}>
          <Paper size="large">
            <Flex direction="column" alignItems="center">
              <Flex width={240}>
                <Text
                  appearance="heading.page"
                  color="neutral.darkest"
                  hidden={deviceType !== 'mobile'}
                >
                  Tell us a bit about yourself
                </Text>
                <Text
                  appearance="heading.paragraph"
                  style={{ textAlign: 'center' }}
                  hidden={deviceType === 'mobile'}
                >
                  Complete your onboarding journey.
                </Text>
              </Flex>
              <Image src={WaitlistImage} preview={false} />
              <Text
                appearance="heading.paragraph"
                style={{ textAlign: 'center' }}
                hidden={deviceType !== 'mobile'}
              >
                Complete your onboarding journey.
              </Text>
            </Flex>
          </Paper>
        </Flex>
      </ResponsiveFlex>
    </Container>
  );
};
