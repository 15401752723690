import {
  Button,
  Dropdown,
  Flex,
  Modal,
  TableHeader,
  Text,
} from '@comet/blocks';
import { StatusComponent } from '@comet/components/TableComponents';
import { User } from '@comet/components/User';
import { format } from '@comet/date';
import { Link, useParams } from '@comet/router';
import { styled } from '@comet/styled';
import { Tooltip, notification } from 'antd';
import { capitalize } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { RiAlertLine } from 'react-icons/ri';
import { Column } from 'react-table';
import { ModelStateMapping } from '../Model.constants';
import { deleteModel, updateModel } from '../service';
import { Field } from '../types';
import { GetModelsResponse } from '../types/ModelList.types';
import { sendErrorNotification } from 'src/blocks/Notification';

const formatTime = (timestamp: number) => {
  return format(new Date(timestamp), 'h:mm a, d MMM yyyy');
};

const StyledAlertIcon = styled(RiAlertLine)`
  color: ${({ theme }) => theme.palette.accent.red.default};
  font-size: 28px;
`;

const getDisplayName = (str: string) => {
  return str
    .split('_')
    .map((s) => capitalize(s))
    .join(' ');
};

export const ModelActions = ({
  id,
  refetch,
  active,
  version,
  refetchActiveModels,
}: {
  id: string;
  refetch: () => void;
  active: boolean;
  version: string | null;
  refetchActiveModels: () => void;
}) => {
  const { projectId } = useParams();

  const [isConfirm, setIsConfirm] = useState(false);

  const toggleModal = () => {
    setIsConfirm((isConfirm) => !isConfirm);
  };

  const onDelete = useCallback(async () => {
    try {
      await deleteModel(projectId, id);
      refetch();
      refetchActiveModels();
      notification.success({
        message: 'Success',
        description: 'Model deleted successfully',
      });
    } catch (e: any) {
      sendErrorNotification({
        message: 'Error',
        description: e?.response?.data?.message,
      });
    }

    toggleModal();
  }, [projectId, refetch]);

  const onToggleStatus = useCallback(
    async (apiId: string, currentStatus: boolean) => {
      await updateModel(projectId, apiId, { active: !currentStatus });
      refetch();
    },
    [projectId, refetch]
  );

  return (
    <>
      <Dropdown
        arrow={true}
        menu={{
          items: [
            {
              key: 'statusEnable',
              label: active ? 'Disable' : 'Enable',
              onClick: () => onToggleStatus(id, active),
              disabled: !!version,
            },
            {
              title: 'Delete',
              key: 'delete',
              label: (
                <Tooltip title={'This will delete the model'}>Delete</Tooltip>
              ),
              onClick: toggleModal,
              disabled: !!version,
            },
          ],
        }}
      >
        <FiMoreHorizontal />
      </Dropdown>
      <Modal
        width={560}
        onCancel={toggleModal}
        title={null}
        open={isConfirm}
        destroyOnClose={true}
        footer={null}
        okButtonProps={{
          type: 'primary',
        }}
      >
        <Flex gap={16} direction="column">
          <Flex gap={16}>
            <Flex>
              <StyledAlertIcon style={{ height: 50, width: 50 }} />
            </Flex>
            <Flex gap={10} direction="column">
              <Text appearance="heading.card">
                Are you sure you want to delete this model?
              </Text>
              <Text appearance="label.short.regular">
                This is an irreversible change, you would not be able to restore
                this Model.
              </Text>
            </Flex>
          </Flex>
          <Flex gap={8} justifyContent="end">
            <Button
              onClick={toggleModal}
              appearance="transparent"
              htmlType="button"
            >
              No
            </Button>
            <Button onClick={onDelete} appearance="primary" htmlType="button">
              Yes
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export const useModelListingData = (
  modelsData: GetModelsResponse | undefined,
  version: string | null,
  latest: string | null,
  refetchModelList: () => void,
  refetchActiveModels: () => void,
  db?: boolean
) => {
  const { projectId, organisationId } = useParams();

  const tableData = useMemo(() => {
    if (!modelsData) return [];

    return modelsData.data.map((item) => {
      let modelVersion = null;

      if (item.draft) modelVersion = null;
      else if (version) modelVersion = version;
      else modelVersion = latest;

      const statusText = getStatus(item);
      const status = ModelListingStateMapping[statusText]['title'];
      const statusColor = ModelListingStateMapping[statusText]['color'];

      return {
        name: (
          <Link
            routeId={`organisations.organisationId.projects.projectId.${
              db ? 'dbModels' : 'models'
            }.modelId.details`}
            pathParam={{
              modelId: item.id,
              organisationId,
              projectId,
            }}
            state={{ version: modelVersion, draft: item.draft }}
          >
            {item.name}
          </Link>
        ),
        description: item.description,
        updatedOn: formatTime(item.updatedOn),
        updatedBy: <User {...(item.updatedBy || {})} />,
        id: item.id,
        modelType: getDisplayName(item.modelType),
        action: (
          <ModelActions
            id={item.id}
            refetch={refetchModelList}
            active={item.active}
            version={modelVersion}
            refetchActiveModels={refetchActiveModels}
          />
        ),
        status: <StatusComponent color={statusColor}>{status}</StatusComponent>,
      };
    });
  }, [latest, modelsData, organisationId, projectId, version, db]);

  const tableColumns = useMemo((): Column<(typeof tableData)[number]>[] => {
    return [
      {
        Header: <TableHeader text="Name" sortable />,
        accessor: 'name',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Description" />,
        accessor: 'description',
        disableSortBy: true,
      },
      ...(db
        ? []
        : [
            {
              Header: <TableHeader text="Model Type" />,
              accessor: 'modelType',
              disableSortBy: true,
            } as Column<(typeof tableData)[number]>,
          ]),
      {
        Header: <TableHeader text="Status" sortable />,
        accessor: 'status',
        disableSortBy: true,
      },
      {
        Header: <TableHeader text="Created on" sortable />,
        accessor: 'updatedOn',
        sortType: 'basic',
      },
      {
        Header: <TableHeader text="Created by" sortable />,
        accessor: 'updatedBy',
        sortType: 'basic',
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
      },
    ];
  }, [db]);

  return { tableColumns, tableData };
};

export const validateFieldData = (fieldsData: Field[]) => {
  let valid = true;

  fieldsData.forEach((field: Field) => {
    if (!field.name) {
      valid = false;
      return;
    }

    if (
      field.type === 'NESTED' ||
      (field.type === 'LIST' && field.subType === 'NESTED')
    ) {
      if (!validateFieldData(field.properties || [])) {
        valid = false;
        return;
      }
    }
  });

  return valid;
};

type StatusType = 'DRAFT' | 'INACTIVE' | 'DEPLOYED' | 'DRAFT_INACTIVE';

function getStatus(item: { draft: boolean; active: boolean }): StatusType {
  if (item.draft && !item.active) {
    return 'DRAFT_INACTIVE';
  }
  if (item.draft && item.active) {
    return 'DRAFT';
  }

  if (item.active) {
    return 'DEPLOYED';
  }

  return 'INACTIVE';
}

export const ModelListingStateMapping: any = {
  DRAFT_INACTIVE: {
    title: 'Draft as Inactive',
    color: '#D4D1CE',
  },
  DRAFT: { title: 'Saved as draft', color: '#D4D1CE' },
  DEPLOYED: { title: 'Deployed', color: '#6AD1B2' },
  INACTIVE: { title: 'Inactive', color: '#D16E4E' },
};
