import { notification } from 'antd';
import { useMutation } from '@comet/query';
import { getAxios } from '@comet/axios';
import { useParams } from '@comet/router';
import { BulkEnvironmentsRawData } from '../types';
import { sendErrorNotification } from 'src/blocks/Notification';

const addBulkEnvironments = (
  projectId: string,
  environmentsRawData: BulkEnvironmentsRawData
) => {
  const url = `resources/${projectId}/environments/bulk`;

  return getAxios().post(url, environmentsRawData);
};

interface PostSubscriptionResponse {
  hostedPageUrl: string;
}

interface CreateSubscriptionRequestBody {
  redirectUrl: string;
  currency: 'INR' | 'USD';
  customerType: string;
  placeOfContact: string | null;
}

export const startSubscription = async (
  projectId: string,
  createSubscriptionData: CreateSubscriptionRequestBody
) => {
  const url = `resources/${projectId}/billing`;

  const { data } = await getAxios().post<PostSubscriptionResponse>(
    url,
    createSubscriptionData
  );

  return data;
};

export const useStartSubscriptionMutation = () => {
  const { projectId } = useParams();

  return useMutation({
    mutationFn: (startSubscriptionData: CreateSubscriptionRequestBody) => {
      return startSubscription(projectId, startSubscriptionData);
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to create subscription',
        reportBug: true,
      });
    },
    onSuccess: (data) => {
      window.location.href = data.hostedPageUrl;
      notification.success({
        message: 'Success',
        description: 'Subscription created successfully',
      });
    },
  });
};

export const useCreateBulkEnvironmentsMutation = (currency: 'INR' | 'USD') => {
  const { projectId } = useParams();

  return useMutation({
    mutationFn: (rawEnvironments: BulkEnvironmentsRawData) => {
      return addBulkEnvironments(projectId, rawEnvironments);
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to create environments',
        reportBug: true,
      });
    },
  });
};
