import { useParams } from '@comet/router';
import { useQuery } from '@comet/query';
import { getAxios } from '@comet/axios';
import {
  Field,
  FieldRequestData,
  FieldResponseData,
  GetSearchIndexesResponse,
  SearchIndex,
  SearchIndexRequestData,
} from '../types';

export const useGetSearchIndexesQuery = (environmentId: string) => {
  const { projectId } = useParams();

  return useQuery({
    queryKey: ['useGetVectorSearchIndexesQuery', projectId, environmentId],
    queryFn: () => getVectorSearchIndexes(projectId, environmentId),
    enabled: !!environmentId,
  });
};

export const getVectorSearchIndexes = async (
  projectId: string,
  environmentId: string
) => {
  const url = `/resources/${projectId}/search-indexes?type=vectorSearch`;
  const { data } = await getAxios().get<GetSearchIndexesResponse>(url, {
    params: {
      environmentId,
    },
  });

  return data;
};

export const useGetSearchIndexQuery = () => {
  const { projectId, vectorSearchIndexId: searchIndexId } = useParams();

  return useQuery({
    queryKey: ['useGetVectorSearchIndexQuery', projectId, searchIndexId],
    queryFn: () => getVectorSearchIndex(projectId, searchIndexId),
  });
};

export const getVectorSearchIndex = async (
  projectId: string,
  searchIndexId: string
) => {
  const url = `/resources/${projectId}/search-indexes/${searchIndexId}`;
  const { data } = await getAxios().get<SearchIndex>(url);

  const fields = data?.mappings?.fields;

  let defaultFields: any = false;
  const modifiedFields: Field[] = [];
  fields?.map((field: FieldResponseData) => {
    const newField: any = {
      ...field,
      searchType: field?.type,
      similarityFunction: field?.similarity,
      noOfDimensions: field?.numDimensions,
    };

    delete newField.similarity;
    delete newField.numDimensions;
    delete newField.type;

    if (newField.searchType === 'vector' && !defaultFields) {
      defaultFields = newField;
    } else {
      modifiedFields.push(newField);
    }
  });

  const modifiedData = {
    ...data,
    mappings: {
      fields: modifiedFields,
      defaultFields,
    },
  };

  return modifiedData;
};

export const updateSearchIndex = (
  projectId: string,
  searchIndexId: string,
  searchIndexRequestData: SearchIndexRequestData
) => {
  const url = `/resources/${projectId}/search-indexes/${searchIndexId}`;

  return getAxios()
    .put<{ id: string }>(url, searchIndexRequestData)
    .then(({ data }) => data);
};

export const deleteSearchIndex = (projectId: string, id: string) => {
  const url = `/resources/${projectId}/search-indexes/${id}`;

  return getAxios().delete(url);
};

export const createSearchIndex = (
  projectId: string,
  searchIndexRequestData: any
) => {
  const url = `/resources/${projectId}/search-indexes`;

  return getAxios()
    .post<{ id: string }>(url, searchIndexRequestData)
    .then(({ data }) => data);
};

export const useSearchIndexExistsQuery = (
  name: string,
  environmentId: string
) => {
  const { projectId } = useParams();
  const url = `/resources/${projectId}/search-indexes/exists`;

  return useQuery({
    enabled: !!name && !!environmentId,
    queryKey: [
      'useVectorSearchIndexExistsQuery',
      name,
      projectId,
      environmentId,
    ],
    queryFn: async () => {
      const { data } = await getAxios().get(url, {
        params: { name, environmentId },
      });

      return data;
    },
  });
};
