import { styled } from '@comet/styled';
import { Flex, InputNumber, Text } from '@comet/blocks';
import { CgChevronLeft, CgChevronRight } from 'react-icons/cg';
import { UsePaginationInstanceProps } from 'react-table';

const PaginationItem = styled(Flex)<{ disabled?: boolean }>`
  height: 32px;
  width: 24px;
  cursor: pointer;
  border-radius: 4px;
  flex-shrink: 0;

  &:hover {
    background-color: #d4d1ce;
    transition: 0.25s ease-out;
  }

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.5;
    pointer-events: none;`}
`;

const PageInput = styled(InputNumber<number>)`
  width: 56px;
  .ant-input-number-handler-wrap {
    display: none !important;
  }
  input {
    text-align: center !important;
  }
`;

interface PaginationProps<T extends object>
  extends UsePaginationInstanceProps<T> {
  currentPage: number;
}

export const Pagination = <T extends object>(props: PaginationProps<T>) => {
  const {
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    previousPage,
    nextPage,
    currentPage,
  } = props;

  return (
    <Flex style={{ marginTop: '16px', width: '100%' }} justifyContent="center">
      <Flex gap={14} alignItems="center">
        <PaginationItem
          justifyContent="center"
          alignItems="center"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <CgChevronLeft size={20} />
        </PaginationItem>
        <Flex gap={14} alignItems="center">
          <PageInput
            onChange={(value) => {
              gotoPage((value || 1) - 1);
            }}
            value={currentPage + 1}
            min={1}
            max={pageCount}
          />
          <Text appearance="body.regular">/</Text>
          <Text appearance="body.regular">{pageCount}</Text>
        </Flex>
        <PaginationItem
          justifyContent="center"
          alignItems="center"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          <CgChevronRight size={20} />
        </PaginationItem>
      </Flex>
    </Flex>
  );
};
