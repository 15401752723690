import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useLocationChange = (func: (location: string) => void) => {
  const location = useLocation();

  useEffect(() => {
    func(location.pathname);
  }, [location, func]);
};
