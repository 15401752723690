import {
  Button,
  ButtonContainer,
  Flex,
  Form,
  FormItem,
  Input,
  InputHelpText,
  InputNumber,
  Select,
  Text,
} from '@comet/blocks';
import {
  CreateStorageAccountFormPropsType,
  StorageRawModel,
} from '@comet/pages/Project/StorageAccounts/types';
import {
  useCreateStorageMutation,
  useStorageAccountExistsQuery,
  validateStorageAccountSize,
} from '../service';
import { useRef, useState } from 'react';

import debounce from 'lodash.debounce';
import { useGetEnvironmentsOptions } from '@comet/pages/Project/Environments/utils';

export const CreateStorageAccountForm = ({
  onCancel,
  projectId,
}: CreateStorageAccountFormPropsType) => {
  const [form] = Form.useForm();
  const [storageAccountName, setStorageAccountName] = useState<string>('');
  const { environmentsOptions, selectedEnvironment, setSelectedEnvironment } =
    useGetEnvironmentsOptions();

  const createStorageAccountMutation = useCreateStorageMutation();

  const handleFinish = (data: StorageRawModel) => {
    createStorageAccountMutation.mutate(data);
  };

  const storageExistsQuery = useStorageAccountExistsQuery(
    storageAccountName,
    selectedEnvironment
  );

  const debouncedStorageNameCheck = useRef(
    debounce(storageExistsQuery.refetch, 500)
  );

  if (!selectedEnvironment) return null;

  return (
    <Flex direction="column" gap={16}>
      <Text appearance="heading.card">Create a new Bucket</Text>
      <Form
        autoComplete="off"
        onFinish={handleFinish}
        layout="vertical"
        form={form}
        initialValues={{
          environmentId: selectedEnvironment,
        }}
      >
        <FormItem
          name="environmentId"
          label="Environment"
          required
          rules={[{ required: true, message: 'Environment is required!' }]}
          marginBottom={20}
        >
          <Select
            placeholder="Select environment"
            options={environmentsOptions}
            onSelect={(e) => {
              setSelectedEnvironment(e);
              debouncedStorageNameCheck.current();
            }}
          />
        </FormItem>

        <FormItem
          name="name"
          label="Bucket Name"
          required
          rules={[
            {
              required: true,
              message: 'Bucket Name is required!',
            },
          ]}
          marginBottom={0}
        >
          <Input
            placeholder="Enter Bucket name..."
            value={storageAccountName}
            onChange={(event) => {
              if (event.target.value.trim().length >= 1) {
                setStorageAccountName(event.target.value.trim());
                debouncedStorageNameCheck.current();
              }
            }}
          />
        </FormItem>
        <InputHelpText
          error={
            storageExistsQuery.data?.exists === true &&
            !storageExistsQuery.isFetching &&
            'Bucket exists'
          }
          success={
            storageAccountName.length > 0 &&
            storageExistsQuery.data?.exists === false &&
            !storageExistsQuery.isFetching &&
            'Bucket is available.'
          }
        >
          {storageExistsQuery.isFetching && 'Checking if project exists...'}
        </InputHelpText>
        <FormItem
          name="spaceInBytes"
          label="Space (in GiBs)"
          initialValue={1}
          required
          rules={[
            {
              required: true,
              message: 'Space is required!',
            },
            {
              validator: validateStorageAccountSize,
            },
          ]}
        >
          <InputNumber />
        </FormItem>
        <ButtonContainer>
          <Button onClick={onCancel} appearance="transparent">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createStorageAccountMutation.isLoading}
          >
            Create
          </Button>
        </ButtonContainer>
      </Form>
    </Flex>
  );
};
