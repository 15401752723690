import { styled } from '@comet/styled';
import { Handle as ReactFlowHandle, HandleProps, Position } from 'reactflow';
import NodeExplorerPopup from '../../NodeExplorer/NodeExplorerPopup';
import { NodeRaw } from '../Nodes';

const AddIconWrapper = styled.div`
  border-radius: 100%;
  height: 15px;
  width: 15px;
  background-color: ${({ theme }) => theme.palette.primary};
`;

const StyledAddHandle = styled(ReactFlowHandle)`
  min-width: 15px;
  height: 15px;
  border-radius: 100%;
  place-items: center;
  display: grid;
  color: ${({ theme }) => theme.palette.neutral.lighter};
  z-index: 2;
  bottom: -5px;
`;

const Icon = styled.span`
  position: relative;
  cursor: pointer;
  left: 3px;
  top: -4px;
`;

type EmptyFunction = () => void;

interface HandleWithExplorerProps {
  handleExplorerBlockClick: (nodeType: NodeRaw) => void | EmptyFunction;
  id: string;
  position: Position;
  type: HandleProps['type'];
  isEnd?: boolean;
  noPopup?: boolean;
}

export const HandleWithExplorer = ({
  handleExplorerBlockClick,
  position,
  id,
  type,
  isEnd = false,
  noPopup = false,
}: HandleWithExplorerProps) => {
  if (isEnd) return null;

  return (
    <StyledAddHandle
      type={type}
      position={position}
      isConnectable
      id={id}
      data-nodeexplorerhandler="true"
    >
      {!noPopup && (
        <NodeExplorerPopup
          onClick={handleExplorerBlockClick}
          data-nodeexplorerhandler="true"
        >
          <AddIconWrapper
            className={type}
            data-handleid={id}
            data-handlepos={position}
            data-nodeexplorerhandler="true"
          >
            <Icon
              data-nodeexplorerhandler="true"
              className={type}
              data-handleid={id}
              data-handlepos={position}
              id="addNode"
            >
              +
            </Icon>
          </AddIconWrapper>
        </NodeExplorerPopup>
      )}
      {noPopup && (
        <AddIconWrapper
          className={type}
          data-handleid={id}
          data-handlepos={position}
          data-nodeexplorerhandler="true"
        >
          <Icon
            onClick={handleExplorerBlockClick as EmptyFunction}
            data-nodeexplorerhandler="true"
            className={type}
            data-handleid={id}
            data-handlepos={position}
          >
            +
          </Icon>
        </AddIconWrapper>
      )}
    </StyledAddHandle>
  );
};
