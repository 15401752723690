import { useEffect, useState } from 'react';

import { isAfter } from 'date-fns';
import { useOrgQuery } from '@comet/pages/Organisation/service';
import { useParams } from '@comet/router';

export const usePlanExpired = () => {
  const { organisationId } = useParams();

  const [isExpired, setIsExpired] = useState<boolean>();

  const { data } = useOrgQuery(organisationId);

  useEffect(() => {
    if (!data) return;
    if (data.planDetails.planType !== 'FREE') return;
    const _isExpired = isAfter(new Date(), data.freeTierExpireAt);
    setIsExpired(_isExpired);
  }, [data]);

  return isExpired;
};
