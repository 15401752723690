import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useNavigate } from '@comet/router';
import { useAuth } from '@comet/components/auth/AuthProvider';
import { useMutation } from '@comet/query';
import { Spin, notification } from 'antd';
import { createUser, useGetUserQuery } from '@comet/pages/User/User.service';
import { UserRawData } from '@comet/pages/User/types';
import { FullCenter } from '@comet/blocks';
import { sendErrorNotification } from 'src/blocks/Notification';
import { useFeatureIsOn, useGrowthBook } from '@growthbook/growthbook-react';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = ({ children }) => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [enabled, setEnabled] = useState(true);
  const gb = useGrowthBook();

  const {
    error,
    isError,
    refetch: refetchUser,
  } = useGetUserQuery(user?.id, !!user?.id);

  const createUserMutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      refetchUser();
      navigate('welcome');
    },
    onError: (error: any) => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to create your user!',
        reportBug: true,
      });
    },
  });

  useEffect(() => {
    if (!isError || !user || !enabled) {
      return;
    }

    if (error || createUserMutation.error) {
      const getUserQueryStatus = (error as any)?.response?.status;

      if (createUserMutation.error) {
        // TODO: error screen should be shown if there's any mutation error
        sendErrorNotification({
          message: 'Error',
          description: 'There was a problem in creating the user',
          reportBug: true,
        });
      } else if (getUserQueryStatus === 404) {
        // Create user if user is not found
        const userData: UserRawData = {
          username: user.username,
          email: user.email,
          user_id: user.id,
          name: user.name,
          picture: null,
          extraInfo: {},
        };

        createUserMutation.mutate(userData);
        setEnabled(false);
      }
    }
  }, [isError, error, user, createUserMutation, navigate, enabled]);

  useEffect(() => {
    if (user?.id) {
      gb.setAttributes({
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
        username: user.username,
      });
    }
  }, [user]);

  if (loading || (error && enabled)) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (!user) {
    return <Navigate routeId="signIn" />;
  }

  return <>{children}</>;
};

export default Authenticated;
