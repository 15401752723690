import {
  Button,
  Flex,
  Form,
  FormItem,
  Input,
  Select,
  Switch,
} from '@comet/blocks';
import { useState } from 'react';
import { FormInstance } from 'antd';
import { KeyTypeOptions } from '@comet/pages/Project/Subflows/types';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';

const Arguments = ({ form }: { form: FormInstance }) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <Form.List name="functionArguments">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Flex key={field.key} justifyContent="space-between">
              <FormItem
                name={[field.name, 'required']}
                valuePropName="checked"
                initialValue={false}
              >
                <Switch
                  onChange={() => {
                    setDisabled(
                      form.getFieldValue([
                        'functionArguments',
                        field.name,
                        'required',
                      ])
                    );
                  }}
                />
              </FormItem>
              <FormItem
                name={[field.name, 'key']}
                style={{ width: '27%' }}
                rules={[
                  {
                    required: true,
                    whitespace: false,
                    message: 'Please input key or delete this field.',
                  },
                  {
                    pattern: /^[a-zA-Z0-9_]+$/,
                    message:
                      'Only letters, numbers, and underscores are allowed.',
                  },
                ]}
              >
                <Input placeholder="Enter argument key" />
              </FormItem>
              <FormItem
                name={[field.name, 'keyType']}
                style={{ width: '27%' }}
                rules={[
                  {
                    required: true,
                    whitespace: false,
                    message: 'Select key type or delete this field.',
                  },
                ]}
              >
                <Select
                  placeholder="Select argument type"
                  options={KeyTypeOptions}
                />
              </FormItem>
              <FormItem name={[field.name, 'default']} style={{ width: '27%' }}>
                <Input disabled={disabled} placeholder="Enter default value" />
              </FormItem>
              <Flex justifyContent="center">
                <Button onClick={() => remove(field.name)}>
                  <AiOutlineMinusCircle />
                </Button>
              </Flex>
            </Flex>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={add} style={{ width: '100%' }}>
              <AiOutlinePlusCircle /> Add Argument
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default Arguments;
