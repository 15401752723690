import { Space, notification } from 'antd';
import { Button } from '../Button';
import { MdOpenInNew, MdOutlineBugReport } from 'react-icons/md';
import { Flex } from '../Flex';

type ErrorNotificationProps = {
  message: string;
  description: string;
  redirectUrl?: string;
  duration?: number;
  reportBug?: boolean;
};

type RedirectBtnProps = {
  redirectUrl: string;
};

const redirectBtns = (redirectUrl?: string, reportBug?: boolean) => {
  return (
    <Space>
      {redirectUrl && (
        <Button type="link" onClick={() => window.open(redirectUrl, '_blank')}>
          <Flex gap={10} alignItems="center" justifyContent="center">
            <MdOpenInNew />
            <div>Learn more</div>
          </Flex>
        </Button>
      )}
      {reportBug && (
        <Button
          type="link"
          onClick={() =>
            window.open(
              'https://docs.cosmocloud.io/cosmocloud-documentation/help-and-support',
              '_blank'
            )
          }
        >
          <Flex gap={10} alignItems="center" justifyContent="center">
            <MdOutlineBugReport />
            <div>Report bug</div>
          </Flex>
        </Button>
      )}
    </Space>
  );
};

export const sendErrorNotification = ({
  message,
  description,
  redirectUrl,
  reportBug,
  duration = 4.5,
}: ErrorNotificationProps) => {
  let notificationProps: any = {
    message,
    description,
    duration,
  };
  if (redirectUrl || reportBug) {
    notificationProps = {
      ...notificationProps,
      btn: redirectBtns(redirectUrl, reportBug),
    };
  }
  notification.error(notificationProps);
};
