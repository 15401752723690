import { Center, FullCenter, Button } from '@comet/blocks';
import { Result } from 'antd';

export const FreeTierSplash = ({ message }: { message?: string }) => {
  const defaultMessage =
    'Please upgrade your plan to make sure your services run seamlessly.';
  const bookCall = () => {
    window.open('https://cal.com/shreybatra/upgrading-cosmocloud', '_blank');
  };
  return (
    <FullCenter>
      <Result
        // icon={<BiSad size={40} />}
        status="error"
        title="You are on Free Tier"
        subTitle={message ? message : defaultMessage}
        extra={
          <Center>
            <Button type="primary" onClick={bookCall}>
              Book a call
            </Button>
          </Center>
        }
      />
    </FullCenter>
  );
};
