import { NodeFormFieldProps } from '../NodeEditor/NodeFormField';
import { useResolver } from '../NodeEditor/NodeEditor.resolver';
import { Button, Flex, Form, Select } from '@comet/blocks';
import { notification, Spin } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DefaultOptionType } from 'antd/es/select';
import debounce from 'lodash.debounce';
import { filterTypesOptions } from './DynamicFields.constants';
import { useFilterFieldValidator } from './utils';
import { sendErrorNotification } from 'src/blocks/Notification';

export const DynamicFields = ({
  values,
  resolver,
  fieldKey,
  onChange,
  setNextStepEnabled,
}: Omit<NodeFormFieldProps, 'type'>) => {
  const { isLoading, data, fetch, isFetched, isError } = useResolver(
    resolver || '',
    '',
    values
  );
  const toggleNextStepEnable = (enabled: boolean) => {
    if (!setNextStepEnabled) return;

    setNextStepEnabled(enabled);
  };

  const fieldValidator = useFilterFieldValidator(toggleNextStepEnable);

  const [fieldOptions, setFieldOptions] = useState<DefaultOptionType[]>([]);

  const debouncedFetch = useRef(debounce(fetch, 500));

  useEffect(() => {
    if (!data) return;

    setFieldOptions(data);
  }, [data]);

  const [form] = Form.useForm();

  const notFoundContent = () => {
    if (!isFetched) {
      return null;
    }
    if (isLoading) {
      return <Spin size="small" />;
    }
    if (isError) {
      return <p>Something went wrong</p>;
    }

    if (data?.length === 0) {
      return <p>No results</p>;
    }
  };

  const handleStateChange = () => {
    form
      .validateFields()
      .then(() => {
        const fields = form
          .getFieldValue('fields')
          .map((field: { fieldName: string; fieldType: string }) => ({
            name: field.fieldName,
            type: field.fieldType,
          }));
        toggleNextStepEnable(true);
        onChange(fieldKey, fields);
      })
      .catch(() => {
        toggleNextStepEnable(false);
      });
  };

  const initialValue = useMemo(() => {
    if (!values) return [];

    return (values[fieldKey] || []).map(
      (field: { name: string; type: string }) => ({
        fieldName: field.name,
        fieldType: field.type,
      })
    );
  }, [fieldKey, values]);

  useEffect(() => {
    form.setFieldValue('fields', initialValue);
  }, [form, initialValue]);

  return (
    <Form form={form} initialValues={initialValue}>
      <Form.List name="fields">
        {(fields, { add, remove }) => (
          <Flex direction="column" gap={10}>
            {fields.map(({ key, name, ...restField }) => (
              <Flex key={key} gap={8}>
                <Form.Item
                  {...restField}
                  name={[name, 'fieldName']}
                  rules={[
                    { required: true, message: 'Missing field name' },
                    fieldValidator,
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    options={fieldOptions}
                    placeholder="Select Field"
                    showSearch
                    onSearch={debouncedFetch.current}
                    onChange={handleStateChange}
                    notFoundContent={notFoundContent()}
                    optionFilterProp="label"
                    style={{ width: 250 }}
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'fieldType']}
                  rules={[{ required: true, message: 'Missing filter type' }]}
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    options={filterTypesOptions}
                    placeholder="Select Filter Type"
                    onChange={handleStateChange}
                    style={{ width: 250 }}
                  />
                </Form.Item>

                <Flex alignItems="center">
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(name);
                    }}
                  />
                </Flex>
              </Flex>
            ))}
            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="dashed" onClick={add} block icon={<PlusOutlined />}>
                Add field
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
    </Form>
  );
};
