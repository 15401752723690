import { useCallback, useState } from 'react';

export const usePagination = () => {
  const [pageOffset, setPageOffset] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const fetchData = useCallback(
    (data: { pageIndex: number; pageSize: number }) => {
      setCurrentPageIndex(data.pageIndex);
      setPageOffset(data.pageIndex * data.pageSize);
    },
    []
  );

  return { pageOffset, currentPageIndex, fetchData };
};
