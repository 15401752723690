import { useMemo } from 'react';
import { useGetEnvironmentTiers } from '../service/environmentTiers.service';
import { EnvironmentTier } from '../types';
import { SelectOption } from '@comet/blocks';

export const useGetTiersOptions = (): SelectOption[] => {
  const { data } = useGetEnvironmentTiers();

  return useMemo(() => {
    return (data?.tiers || [])?.map((tier: EnvironmentTier) => ({
      label: tier.name,
      value: tier.type,
    }));
  }, [data?.tiers]);
};

export const useGetTiersPricing = () => {
  const { data } = useGetEnvironmentTiers();

  return useMemo(() => {
    return (data?.tiers || [])?.reduce((acc, curr) => {
      acc[curr.type] = curr.pricing;
      return acc;
    }, {} as Record<string, { INR: number; USD: number }>);
  }, [data?.tiers]);
};
