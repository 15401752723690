import { Auth } from 'aws-amplify';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const getOrgIdFromUrl = () => {
  const url = window.location.href;
  const urlParts = url.split('/');

  const orgIndex = urlParts.findIndex((part) => part === 'organisations');
  if (orgIndex === -1) {
    return null;
  }
  if (orgIndex + 1 === urlParts.length) {
    return null;
  }
  if (urlParts[orgIndex + 1] === 'new') {
    return null;
  }
  return urlParts[orgIndex + 1];
};

const axiosRequestInterceptor = async (config: AxiosRequestConfig) => {
  const session = await Auth.currentSession();

  const token = session?.getIdToken().getJwtToken();
  const orgId = getOrgIdFromUrl();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (orgId) {
    config.headers['X-Cosmocloud-Org-Id'] = orgId;
  }

  return config;
};

const axios = Axios.create({
  baseURL: process.env.REACT_APP_UNIVERSE_BASE_URL,
  timeout: 5000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

const axiosMock = Axios.create({
  baseURL: 'http://localhost:4000',
  timeout: 5000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

[axios, axiosMock].forEach((instance) => {
  instance.interceptors.request.use(axiosRequestInterceptor, (e) =>
    Promise.reject(e)
  );
});

export const getAxios = (mock?: boolean) => (mock ? axiosMock : axios);

export const getError = (response: unknown) => {
  if (!response) {
    return null;
  }

  const errorMessage = (response as AxiosResponse)?.data?.message;
  if (typeof errorMessage !== 'string') {
    return 'Something went wrong!';
  }

  return errorMessage;
};
