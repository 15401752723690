import {
  Button,
  Flex,
  Form,
  FormItem,
  Input,
  Select,
  Switch,
  Show,
  Text,
} from '@comet/blocks';
import { useState } from 'react';
import { FormInstance } from 'antd';
import { KeyTypeOptions } from '../types';
import { useProjectPermissions } from '@comet/hooks/useProjectPermission';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';

const UpdateArguments = ({
  initialArgData,
  form,
}: {
  initialArgData: {
    key: string;
    keyType: string;
    required: boolean;
    default: null | any;
  }[];
  form: FormInstance;
}) => {
  const { hasManageProjectSettingsAccess } = useProjectPermissions();
  const [disabled, setDisabled] = useState(false);
  return (
    <Flex direction="column" gap={10}>
      <FormItem
        label={<Text appearance="label.long.semiBold">Arguments</Text>}
        colon={false}
        marginBottom={0}
      >
        <Text
          appearance="caption.longText"
          color="neutral.dark"
          style={{ marginBottom: 0 }}
        >
          These are the required arguments that you have to pass to the Subflow.
        </Text>
      </FormItem>
      <Form.List name="functionArguments" initialValue={initialArgData}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Flex key={field.key} justifyContent="space-between">
                <FormItem
                  name={[field.name, 'required']}
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch
                    onChange={() => {
                      setDisabled(
                        form.getFieldValue([
                          'functionArguments',
                          field.name,
                          'required',
                        ])
                      );
                    }}
                  />
                </FormItem>
                <FormItem
                  name={[field.name, 'key']}
                  style={{ width: '27%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: 'Please input key or delete this field.',
                    },
                    {
                      pattern: /^[a-zA-Z0-9_]+$/,
                      message:
                        'Only letters, numbers, and underscores are allowed.',
                    },
                  ]}
                  marginBottom={0}
                >
                  <Input
                    placeholder="Enter key"
                    disabled={!hasManageProjectSettingsAccess}
                  />
                </FormItem>
                <FormItem
                  name={[field.name, 'keyType']}
                  style={{ width: '27%' }}
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: 'Please input value or delete this field.',
                    },
                  ]}
                  marginBottom={0}
                >
                  <Select
                    placeholder="Select argument type"
                    options={KeyTypeOptions}
                  />
                </FormItem>

                <FormItem
                  name={[field.name, 'default']}
                  style={{ width: '27%' }}
                >
                  <Input
                    disabled={disabled}
                    placeholder="Enter default value"
                  />
                </FormItem>
                <Flex justifyContent="center">
                  <Button
                    icon={<AiOutlineMinusCircle />}
                    onClick={() => remove(field.name)}
                    disabled={!hasManageProjectSettingsAccess}
                  />
                </Flex>
              </Flex>
            ))}
            <Show if={!!hasManageProjectSettingsAccess}>
              <FormItem marginBottom={0}>
                <Button
                  type="dashed"
                  onClick={add}
                  style={{ width: '100%' }}
                  disabled={!hasManageProjectSettingsAccess}
                >
                  <AiOutlinePlusCircle /> Add Argument
                </Button>
              </FormItem>
            </Show>
          </>
        )}
      </Form.List>
    </Flex>
  );
};

export default UpdateArguments;
