import { Edge, Node } from 'reactflow';
import { getTreeGroupLayout } from './tree.group';
import { EdgeGroups, NodeGroups, NodeMap } from './tree.types';

export const getGraph = (nodes: Node[], edges: Edge[]) => {
  const nodeGroups: NodeGroups = {};
  const edgeGroups: EdgeGroups = {};
  const nodeMap: NodeMap = {};

  const childToParentMap: Record<string, string> = {};

  // collect all the nodes
  nodes.forEach((node) => {
    const parentNodeId = node.parentNode ?? 'root';
    if (!nodeGroups[parentNodeId]) {
      nodeGroups[parentNodeId] = [];
    }
    nodeGroups[parentNodeId].push(node);

    childToParentMap[node.id] = parentNodeId;
    nodeMap[node.id] = node;
  });

  // collet edges group wise
  edges.forEach((edge) => {
    if (!edgeGroups[childToParentMap[edge.source]]) {
      edgeGroups[childToParentMap[edge.source]] = [];
    }
    edgeGroups[childToParentMap[edge.source]].push(edge);
  });

  const { layout, graph } = getTreeGroupLayout(
    'root',
    nodeGroups,
    edgeGroups,
    nodeMap
  );

  return {
    nodes: layout.getNodes().concat(graph.nodes),
    edges: layout.edges.concat(graph.edges),
  };
};
