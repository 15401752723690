import { styled } from '@comet/styled';

export const Background = styled.div`
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  display: grid;
  margin: auto;
  margin-top: 20px;
  gap: 5px;
  width: 600px;
  border-radius: 6px;
  padding: 15px;
  background-color: #fff;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: ${({ theme }) => `${theme.palette.neutral.darkest}`};
`;

export const StepsContainer = styled.div`
  width: 500px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  bordertop: ${({ theme }) => `1px solid ${theme.palette.neutral.light}`};
  paddingtop: 24px;
`;
