import { useNodeEditorOpener } from '@comet/components/FlowEngine/FlowEngine.Editor';
import { memo } from 'react';
import { CometNodeBaseProps, NodeRaw } from '../..';
import { BusinessNode, BusinessNodeEnum } from '../../BusinessNode';
import { HandleWithExplorer } from '../../common/HandleWithExplorer';
import { notification } from 'antd';
import { Handle, Position, useReactFlow } from 'reactflow';
import { useUpdateEdgeBeforeDelete } from '@comet/hooks/useDeleteANode';
import { useUpdateGraph } from '@comet/hooks/useUpdateGraph';
import { useReplaceAddNode } from '@comet/hooks/useReplaceAddNode';
import { DeleteIcon } from 'src/blocks/Icons';
import { sendErrorNotification } from 'src/blocks/Notification';

type IProps = CometNodeBaseProps;

export const BaseNodeDimension = {
  height: 80,
  width: 220,
};

const BaseNode = ({ data, ...props }: IProps) => {
  const { getNode, deleteElements } = useReactFlow();

  const handleNodeClick = useNodeEditorOpener(props.id, data);
  const handleBlockClick = (newNode: NodeRaw) => {
    if (newNode.data.type === BusinessNodeEnum.HTTP_TRIGGER) {
      sendErrorNotification({
        message: 'Error',
        description: 'HTTP Trigger already exists in this flow.',
      });
      return;
    }
    data.nodeOperations(
      {
        id: props.id,
        type: props.type,
        position: { x: props.xPos, y: props.yPos },
        data: data,
      },
      newNode,
      'addNode'
    );
  };

  const updateEdges = useUpdateEdgeBeforeDelete();
  const updateGraph = useUpdateGraph();
  const replaceWithAddNode = useReplaceAddNode();

  const onClickDelete = async () => {
    // Maybe update the edges first then delete the node,
    // using in-case of deleting first node of switch case. Not the whole sub-tree delete
    await updateEdges(props.id);
    const node = getNode(props.id);

    if (!node) return;
    if (replaceWithAddNode(node)) return;

    deleteElements({ nodes: [node] });

    setTimeout(() => updateGraph(), 200);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={handleNodeClick}>
        <Handle type="target" position={Position.Top} />
        <BusinessNode {...data} />
        <HandleWithExplorer
          id="a"
          handleExplorerBlockClick={handleBlockClick}
          position={Position.Bottom}
          type="source"
          isEnd={['HTTP_RESPONSE', 'FUNCTION_RESPONSE'].includes(data?.type)}
        />
      </div>
      {data.type !== 'HTTP_TRIGGER' && (
        <DeleteIcon
          style={{ position: 'absolute', right: 10, top: 5 }}
          onClick={onClickDelete}
        />
      )}
    </div>
  );
};

export default memo(BaseNode);
