import { useState } from 'react';

import { StyledSteps as Steps, Show } from '@comet/blocks';
import { Background, StepsContainer, Title } from './CreateOrganization.utils';
import { ProjectForm } from '@comet/pages/Project/NewProject/ProjectForm/ProjectForm';
import CreateOrganizationForm from './CreateOrganizationForm/CreateOrganizationForm';
import { useGoBack, useNavigate } from '@comet/router';

export const CreateOrganization = () => {
  const [organisationId, setOrganisationId] = useState('');
  const [, setProjectId] = useState('');

  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const navigateBack = useGoBack();

  const cancelOrgCreation = () => {
    navigateBack();
  };

  const skipProjectCreation = () => {
    navigate('organisations');
  };

  return (
    <>
      <Show if={step !== 2}>
        <Background>
          <Title>Welcome to Cosmocloud</Title>
          <StepsContainer>
            <Steps
              prefixCls="step"
              size="small"
              current={step}
              items={[
                {
                  title: 'Create organisation',
                },
                {
                  title: 'Create Project',
                },
              ]}
            />
          </StepsContainer>
          <Show if={step === 0}>
            <CreateOrganizationForm
              onSuccess={(id) => {
                setOrganisationId(id);
                setStep(1);
              }}
              onCancel={cancelOrgCreation}
            />
          </Show>
          <Show if={step === 1}>
            <ProjectForm
              onSuccess={(id) => {
                setProjectId(id);
                setStep(2);
                navigate(
                  'organisations.organisationId.projects.projectId.dashboard',

                  {
                    organisationId,
                    projectId: id,
                  }
                );
              }}
              onCancel={skipProjectCreation}
              organisationId={organisationId}
              primaryButtonText={"Let's get started"}
              secondaryButtonText="Skip"
            />
          </Show>
        </Background>
      </Show>
    </>
  );
};
