import { Edge, Node } from 'reactflow';

export const PAGE_SIZE = 10;

export interface GetSubflowsParams {
  flow_type: 'FUNCTION';
  version: string | null;
  draft?: boolean | null;
  active?: boolean | null;
  limit?: number;
  offset?: number;
  sort_by?: string;
  sort_order?: string;
}

export interface ExtendedNode extends Node {
  parentId?: string;
}

export interface Subflow {
  id: string;
  name: string;
  active: boolean;
  description: string;
  flowId: string;
  draft: boolean;
  flowType: string;
  flowMetadata?: {
    functionArguments?: [
      { key: string; type: string; default: any | null; required: boolean }
    ];
    functionReturnTypeFields?: [
      { key: string; type: string; displayName: string }
    ];
  };
  projectId: string;
  updatedBy: {
    name: string;
    picture: string | null;
    id: string;
  };
  updatedOn: number;
  version: string;
  nodes: Node[] | ExtendedNode[];
  edges: Edge[];
  rootNode: string;
}

export interface GetSubflowsResponse {
  data: Subflow[];
  page: {
    limit: number;
    total: number;
  };
}

export interface GetSubflowDetailsParams {
  version?: string | null;
  draft?: boolean;
}

export type SubflowDetailsRaw = Pick<
  Subflow,
  'name' | 'description' | 'flowType' | 'flowMetadata'
>;

export interface GetSubflowDetailsResponse extends Omit<Subflow, 'id'> {
  flowId: string;
}

export enum KeyType {
  OBJECT_ID = 'OBJECT_ID',
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  INTEGER = 'NUMBER',
  FLOAT = 'FLOAT',
  DICT = 'DICT',
  ARRAY = 'LIST',
}
export const KeyTypeOptions = [
  { label: 'Object ID', value: KeyType.OBJECT_ID },
  { label: 'Boolean', value: KeyType.BOOLEAN },
  { label: 'String', value: KeyType.STRING },
  { label: 'Integer', value: KeyType.INTEGER },
  { label: 'Float', value: KeyType.FLOAT },
  { label: 'Dictionary', value: KeyType.DICT },
  { label: 'Array', value: KeyType.ARRAY },
];
