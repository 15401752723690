import { Flex } from '@comet/blocks';
import { styled } from '@comet/styled';

export const CardContainer = styled(Flex)<{
  selected: boolean;
  disabled?: boolean;
}>`
  flex-direction: column;
  width: 50%;
  height: 200px;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  border: ${({ theme, selected }) =>
    `1px solid ${
      selected ? theme.palette.accent.blue.darker : theme.palette.neutral.light
    }`};
  border-radius: ${({ theme }) => theme.constants.borderRadius.lg};
  margin-right: 12px;
  padding: 0 12px;
  padding-top: 30px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.neutral.lighter : ''};
  & > svg {
    color: ${({ theme }) => theme.palette.neutral.darker};
  }

  & .selected {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    color: ${({ theme }) => theme.palette.link};
  }
`;
