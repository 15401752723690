import {
  Button,
  Center,
  Flex,
  FullCenter,
  Paper,
  Spin,
  Text,
  Search,
  Alert,
  Show,
} from '@comet/blocks';
import {
  UpsertUserOrgAccess,
  removeOrgAccess,
  useGetOrgSubscriptionQuery,
  useOrgAccessQuery,
  useOrgQuery,
  useOrgRolesQuery,
} from '../service';
import { useParams } from '@comet/router';
import { Result, notification } from 'antd';
import { ChangeEvent, useMemo, useState } from 'react';
import { MemberEmailInvite } from '@comet/components/Members';
import debounce from 'lodash.debounce';
import { MemberList } from '@comet/components/Members/MemberList';
import { useMutation } from '@tanstack/react-query';
import {
  RemoveUserAccessData,
  UpsertUserAccessData,
} from '@comet/components/Members/types';
import { useOrgPermissions } from '@comet/hooks/useOrgPermission';
import { getDisplayName, usePlanInfo } from '../utils';
import { FreeTierSplash } from '@comet/components/FreeTierSplash';
import { sendErrorNotification } from 'src/blocks/Notification';
import { queryClient } from '@comet/query';

export const OrgUserManagement = () => {
  const { organisationId } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');

  const debouncedQueryUpdate = debounce((value: string) => {
    setQuery(value);
  }, 500);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value.length < 3) {
      debouncedQueryUpdate('');
      return;
    }

    debouncedQueryUpdate(e.target.value);
  };

  const { isLoading: isPlanInfoLoading } = usePlanInfo();

  const { data: subscriptionData } = useGetOrgSubscriptionQuery(organisationId);

  const {
    data: orgMembers,
    isLoading: isOrgAccessLoading,
    error: orgAccessError,
    refetch: orgAccessRefetch,
    isError: isOrgAccessError,
  } = useOrgAccessQuery(organisationId, {
    user_name_query: query || null,
  });

  const {
    data,
    isLoading: isOrgRolesLoading,
    error: orgRolesError,
    refetch: orgRolesRefetch,
  } = useOrgRolesQuery();

  const debouncedOrgAccessRefetch = debounce(orgAccessRefetch, 200);

  const isUsersLimitReached =
    (subscriptionData?.resourceLimits.maxUsers || 0) <=
    (orgMembers?.page.total || 0);

  const updateOrgAccessMutation = useMutation({
    mutationFn: (orgAccessData: UpsertUserAccessData) =>
      UpsertUserOrgAccess(organisationId, orgAccessData),
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'Organisation access updated sucessfully',
      });
      debouncedOrgAccessRefetch();
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description: 'Unable to update organisation access',
        reportBug: true,
      });
    },
  });

  const removeOrgAccessMutation = useMutation({
    mutationFn: (orgAccessData: RemoveUserAccessData) =>
      removeOrgAccess(organisationId, orgAccessData),
    onSuccess: () => {
      notification.success({
        message: 'Success',
        description: 'User removed from the organisation successfully',
      });
      debouncedOrgAccessRefetch();
    },
    onError: () => {
      sendErrorNotification({
        message: 'Error',
        description:
          'There was an error removing the user from the organisation',
        reportBug: true,
      });
    },
  });

  const { hasManageOrgUserPermissionsAccess } = useOrgPermissions();

  const OrgRolesOptions = useMemo(
    () =>
      (data?.roles || []).map((role) => ({
        label: getDisplayName(role.name),
        value: role.value,
      })),
    [data?.roles]
  );

  const onUpdate = (data: UpsertUserAccessData) => {
    updateOrgAccessMutation.mutate(data);
  };

  const onDelete = (data: RemoveUserAccessData) => {
    removeOrgAccessMutation.mutate(data);
  };

  const isLoading = isOrgAccessLoading || isOrgRolesLoading;
  const isError = orgAccessError || orgRolesError || !orgMembers;
  const showEmptyScreen =
    !isLoading && !isError && orgMembers.data.length === 0;
  const showMemberList = !isLoading && !isError && orgMembers.data.length > 0;

  if (isLoading && !query) {
    return (
      <FullCenter>
        <Spin size="large" />
      </FullCenter>
    );
  }

  if (isError && !query) {
    return (
      <FullCenter>
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={
            <Center>
              <Button
                onClick={() =>
                  isOrgAccessError ? orgAccessRefetch() : orgRolesRefetch()
                }
                type="primary"
              >
                Try Again
              </Button>
            </Center>
          }
        />
      </FullCenter>
    );
  }

  return (
    <Paper size="medium">
      <Flex direction="column" gap={20}>
        <Text appearance="heading.card">Manage your organization</Text>
        <Show if={!isPlanInfoLoading}>
          <Alert
            message={
              <Text
                appearance="label.long.regular"
                color="neutral.darkest"
                style={{ padding: '4px 12px' }}
              >
                {isUsersLimitReached
                  ? 'You need to upgrade your plan to add more users in your organisation.'
                  : 'You need to give explicit permissions to the users in the project.'}
              </Text>
            }
            type={isUsersLimitReached ? 'warning' : 'info'}
            showIcon={false}
            action={null}
            closable={true}
          />
        </Show>
        <Text appearance="heading.paragraph">
          Edit permissions for your existing members for this project
        </Text>
        <Flex width="100%">
          <Search
            placeholder="Search from org members..."
            value={searchValue}
            loading={false}
            suffix={null}
            onChange={onSearch}
          />
        </Flex>
        <MemberList
          type="organisation"
          isLoading={isLoading && !!query}
          showMemberList={showMemberList}
          showEmptyScreen={showEmptyScreen}
          roles={OrgRolesOptions}
          members={orgMembers?.data || []}
          total={orgMembers?.page.total || 0}
          onUpdate={onUpdate}
          onDelete={onDelete}
          hasEditAccess={!!hasManageOrgUserPermissionsAccess}
        />
        <MemberEmailInvite
          onInvite={onUpdate}
          roles={OrgRolesOptions}
          hasEditAccess={!!hasManageOrgUserPermissionsAccess}
          userLimitReached={isUsersLimitReached}
        />
      </Flex>
    </Paper>
  );
};
