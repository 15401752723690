import { Flex, Text } from '@comet/blocks';
import FlexItem from 'src/blocks/FlexItem';

interface ProjectInfoFieldProps {
  name: string;
  value: string;
}

const ProjectInfoField = ({ name, value }: ProjectInfoFieldProps) => {
  return (
    <FlexItem grow={1}>
      <Flex gap={8} direction="column">
        <Text appearance="label.long.regular" color="neutral.darker">
          {name}
        </Text>
        <Text appearance="label.long.semiBold" color="neutral.darkest">
          {value}
        </Text>
      </Flex>
    </FlexItem>
  );
};

export default ProjectInfoField;
