import {
  getEdgeById,
  setMetadataOfIfNode,
} from 'src/FlowEngine2/store/flow-engine-store/utils';
import { getNodeById, useFlowEngineStore } from 'src/FlowEngine2/store';

import { NodeRaw } from 'src/FlowEngine2/types';
import { addNodeFromAddNode } from './addNodeFromNode';
import { addNodeFromEdge } from './addNodeFromEdge';

export function addIfElseNode(
  type: 'ADD_NODE' | 'EDGE', // A Node can be added either from add_node or edge
  id: string | null, // id can be of node or edge
  nodeToAdd: NodeRaw | null,
  branch?: 'TRUE_BRANCH' | 'FALSE_BRANCH'
) {
  const { createNode, addEdge, switchToStackTop } =
    useFlowEngineStore.getState();

  if (!id || !nodeToAdd) return;

  switch (type) {
    case 'ADD_NODE': {
      // IF_NODE_TREE
      const ifNode = getNodeById(createNode(nodeToAdd));
      const addNode1 = getNodeById(createNode('ADD_NODE'));
      const addNode2 = getNodeById(createNode('ADD_NODE'));

      if (!ifNode || !addNode1 || !addNode2)
        return new Error(`Error in creating If Node tree`);

      setMetadataOfIfNode(ifNode.id, addNode1.id, addNode2.id);

      addEdge(ifNode.id, addNode1.id);
      addEdge(ifNode.id, addNode2.id);

      addNodeFromAddNode(id, ifNode, []);
      break;
    }

    case 'EDGE': {
      const edge1 = getEdgeById(id);
      if (!edge1) return new Error(`Error while adding if node`);
      const node2 = getNodeById(edge1.target);

      const ifV1Node = getNodeById(createNode(nodeToAdd));
      const addNode = getNodeById(createNode('ADD_NODE'));

      if (!node2 || !ifV1Node || !addNode)
        return new Error(`Error while adding if node`);

      if (branch === 'TRUE_BRANCH') {
        setMetadataOfIfNode(ifV1Node.id, node2.id, addNode.id);
      } else {
        setMetadataOfIfNode(ifV1Node.id, addNode.id, node2.id);
      }
      addEdge(ifV1Node.id, addNode.id); //It is necessary to set metadata of if before adding

      addNodeFromEdge(id, ifV1Node, [ifV1Node]);

      break;
    }
    default:
  }

  switchToStackTop();
}
